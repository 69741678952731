import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {useReferralEntryContext} from '@contexts/ReferralEntryContext';
import useScreenInfo from '@/hooks/app/useScreenInfo';

const ReferralStepInfo: React.FC = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    const [readyForNext, setReadyForNext] = useState<boolean>(false);
    const maxLength = 1500;

    const valueKeys = {
        patientHistoryKey: 'patientHistory',
        mainDescriptionKey: 'mainDescription',
    };
    const {patientHistoryKey, mainDescriptionKey} = valueKeys;
    const {patientHistory, mainDescription, updateRxInfo, handleNext} =
        useReferralEntryContext();

    useEffect(() => {
        setReadyForNext(
            patientHistory !== '' &&
                mainDescription !== '' &&
                patientHistory.length < maxLength &&
                mainDescription.length < maxLength
        );
    }, [patientHistory, mainDescription]);

    const checkNext = () => {
        if (!readyForNext) return;
        handleNext();
    };

    const fullLabel = (label, length) => (
        <h4>
            {label} *{' '}
            <small style={{fontSize: '0.5em'}}>
                {length} of {maxLength} characters used
            </small>
        </h4>
    );
    const mobileLabel = (label, length) => (
        <>
            <h4>{label} *</h4>
            <small style={{fontSize: '0.75em'}}>
                {length} of {maxLength} characters used
            </small>
        </>
    );

    const {isNarrow} = useScreenInfo();

    return (
        <div
            className="medr-layout text-center ref-info-page"
            style={{
                maxWidth: '700px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <Row>
                <Col className="py-1">
                    <h4>Enter referral information</h4>
                </Col>
            </Row>

            <Form className="text-left">
                <Form.Group controlId="patientHistoryForm" className="mt-3">
                    <Form.Label className="my-0">
                        {isNarrow
                            ? mobileLabel(
                                  'Patient history',
                                  patientHistory.length
                              )
                            : fullLabel(
                                  'Patient history',
                                  patientHistory.length
                              )}
                    </Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        placeholder="Enter patient history"
                        rows={4}
                        defaultValue={patientHistory}
                        onChange={(e) =>
                            updateRxInfo({
                                [patientHistoryKey]: e.target.value,
                            })
                        }
                        isInvalid={patientHistory.length > maxLength}
                    />
                    <Form.Control.Feedback type="invalid">
                        Max length of {maxLength} characters exceeded
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="mainDescriptionForm" className="mt-3">
                    <Form.Label className="my-0">
                        {isNarrow
                            ? mobileLabel(
                                  'Reason for referral',
                                  mainDescription.length
                              )
                            : fullLabel(
                                  'Reason for referral',
                                  mainDescription.length
                              )}
                    </Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        placeholder="Enter referral description"
                        rows={4}
                        defaultValue={mainDescription}
                        onChange={(e) =>
                            updateRxInfo({
                                [mainDescriptionKey]: e.target.value,
                            })
                        }
                        isInvalid={mainDescription.length > maxLength}
                    />
                    <Form.Control.Feedback type="invalid">
                        Max length of {maxLength} characters exceeded
                    </Form.Control.Feedback>
                </Form.Group>

                <Row className="text-center mt-4">
                    <Col>
                        <Button
                            data-testid="next-button"
                            className="sel-button w80"
                            onClick={checkNext}
                            disabled={!readyForNext}
                        >
                            Next
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ReferralStepInfo;
