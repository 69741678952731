import {
    User,
    UserProfile,
    JobRole,
    Patient,
    InsuranceAgreement,
    Address,
    ClinicianRoleProfile,
    NotificationsSettings,
    Referral,
    DelegateUserLink,
    GpsFromPostcodeResponse,
} from '@/generated-client';

export function createDefaultGpsFromPostcodeResponse(): GpsFromPostcodeResponse {
    return {
        postcode: '',
        lat: 0,
        lng: 0,
    };
}

export function createDefaultJobRole(): JobRole {
    return {
        id: 0,
        specialty: undefined,
        subSpecialty: undefined,
        jobRole: undefined,
        registrationBody: undefined,
        jobTitle: undefined,
        isVerified: false,
        isDoctor: false,
    };
}

export function createDefaultAddress(): Address {
    return {
        id: 0,
        postcode: '',
        longitude: 0,
        latitude: 0,
        addressType: undefined,
        firstLine: undefined,
        city: undefined,
    };
}

export function createDefaultUser(): User {
    return {
        id: 0,
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        isStaff: false,
    };
}

export function createDefaultUserProfile(): UserProfile {
    return {
        id: 0,
        user: createDefaultUser(),
        jobRole: [],
        appointmentsContact: [],
        accountCreatedOn: new Date(),
        title: undefined,
        firstName: null,
        lastName: null,
        dob: null,
        profilePicture: null,
        isPatient: false,
        clinicianDescription: null,
        registrationNumber: undefined,
        isVerified: false,
        isDeactivated: false,
    };
}

export function createDefaultClinicianRoleProfile(): ClinicianRoleProfile {
    return {
        id: 0,
        userProfile: createDefaultUserProfile(), // Assuming you have this function from earlier
        insuranceAgreements: [],
        clinic: null,
        isFavourite: false,
        isDoctor: false,
        distance: null,
        accountCreatedOn: new Date(),
        isDeactivated: false,
    };
}

export function createDefaultDelegateUserLink(): DelegateUserLink {
    return {
        id: 0,
        delegateUserProfile: createDefaultUserProfile(), // Assuming you have this function from earlier
        clinicianUserProfile: createDefaultUserProfile(), // Assuming you have this function from earlier
        hasRegistered: false,
        accountCreatedOn: new Date(),
        isVerified: false,
        permissionViewReferrals: false,
        permissionCreateReferrals: false,
        permissionManageReferrals: false,
    };
}

export function createDefaultNotificationSettings(): NotificationsSettings {
    return {
        id: 0,
        createdOn: new Date(),
        updatedOn: new Date(),
        userProfile: 0,
        userCreatedReferral: false,
        userReceivedReferral: false,
        targetHasToggledReferral: false,
        userHasToggledReferral: false,
        newOpenReferral: false,
    };
}

export function createDefaultPatient(): Patient {
    return {
        id: 0,
        createdBy: createDefaultUserProfile(), // Assuming you have this function from earlier
        address: {} as Address, // Assuming Address is another interface
        insurancePolicy: {} as InsuranceAgreement, // Assuming InsuranceAgreement is another interface
        currentAge: 0,
        displayType: '',
        viewType: '',
        firstName: '',
        lastName: '',
        createdOn: new Date(),
        detailsInImage: false,
        title: undefined,
        patientReference: undefined,
        sex: undefined,
        dob: null,
        primaryEmail: undefined,
        primaryPhoneNumber: undefined,
        isSelfPayer: false,
        detailsImage: null,
        patientActive: true,
        consentGiven: false,
        consentGivenOn: null,
    };
}

export function createDefaultReferral(): Referral {
    return {
        id: 0,
        patient: createDefaultPatient(),
        referrer: createDefaultClinicianRoleProfile(),
        jobRole: createDefaultJobRole(),
        specifiedClinician: false,
        targetClinician: createDefaultClinicianRoleProfile(),
        mainDescription: '',
        displayType: '',
        isPrivateView: false,
        createdOn: new Date(),
        targetHasAccepted: false,
        history: undefined,
        completed: false,
        acceptedOn: null,
        completedOn: null,
    };
}

// interface User {
//     id: number | null;
//     username: string;
//     email: string;
//     first_name: string;
//     last_name: string;
//     is_staff: boolean;
// }

// interface JobRole {
//     id: number | null;
//     jobRole: string;
//     specialty: string;
//     registrationBody: string;
// }

// interface UserProfile {
//     user: User;
//     jobRole: JobRole;
//     title: string;
//     firstName: string;
//     lastName: string;
//     profilePicture: string;
//     isPatient: boolean;
//     registrationNumber: string;
// }

// interface Address {
//     firstLine: string;
//     city: string;
//     postcode: string;
//     longitude: number | null;
//     latitude: number | null;
// }

// interface Patient {
//     id: number | null;
//     createdBy: UserProfile;
//     currentAge: number | null;
//     viewType: string;
//     detailsInImage: boolean;
//     title: string;
//     firstName: string;
//     lastName: string;
//     patientReference: string;
//     sex: string;
//     dob: string;
//     primaryEmail: string;
//     primaryPhoneNumber: string;
//     address: Address;
//     detailsImage: string;
//     patientActive: boolean;
//     createdOn: string;
//     isSelfPayer: boolean;
//     insurancePolicy: any | null;
// }

// interface CRP {
//     id: number | null;
//     userProfile: UserProfile;
//     centreName: string;
//     addressFirstLine: string;
//     addressCity: string;
//     addressPostcode: string;
//     addressLongitude: number | null;
//     addressLatitude: number | null;
// }

// interface Referral {
//     id: number | null;
//     patient: Patient;
//     referrer: CRP;
//     specifiedClinician: boolean;
//     targetClinician: CRP;
//     mainDescription: string;
//     histoy: string;
//     isPrivateView: boolean;
//     targetHasAccepted: boolean;
//     createdOn: string;
// }

// export const initUser: User = {
//     id: null,
//     username: "",
//     email: "",
//     first_name: "",
//     last_name: "",
//     is_staff: false,
// };

// export const initJobRole: JobRole = {
//     id: null,
//     jobRole: "",
//     specialty: "",
//     registrationBody: "",
// };

// export const initUserProfile: UserProfile = {
//     user: initUser,
//     jobRole: initJobRole,
//     title: "",
//     firstName: "",
//     lastName: "",
//     profilePicture: "",
//     isPatient: false,
//     registrationNumber: "",
// };

// export const initPatient: Patient = {
//     id: null,
//     createdBy: initUserProfile,
//     currentAge: null,
//     viewType: "",
//     detailsInImage: false,
//     title: "",
//     firstName: "",
//     lastName: "",
//     patientReference: "",
//     sex: "",
//     dob: "",
//     primaryEmail: "",
//     primaryPhoneNumber: "",
//     address: {
//         firstLine: "",
//         city: "",
//         postcode: "",
//         longitude: null,
//         latitude: null,
//     },
//     detailsImage: "",
//     patientActive: false,
//     createdOn: "",
//     isSelfPayer: true,
//     insurancePolicy: null
// };

// export const initCRP: CRP = {
//     id: null,
//     userProfile: initUserProfile,
//     centreName: "",
//     addressFirstLine: "",
//     addressCity: "",
//     addressPostcode: "",
//     addressLongitude: null,
//     addressLatitude: null,
// }

// export const initReferral: Referral = {
//     id: null,
//     patient: initPatient,
//     referrer: initCRP,
//     specifiedClinician: false,
//     targetClinician: initCRP,
//     mainDescription: "",
//     histoy: "",
//     isPrivateView: true,
//     targetHasAccepted: false,
//     createdOn: ""
// }

interface CrpFormData {
    centreName: string;
    addressFirstLine: string;
    addressCity: string;
    addressPostcode: string;
}

interface Postcode {
    lat: number;
    lng: number;
    zoom: number;
}

export const initCrpFormData: CrpFormData = {
    centreName: '',
    addressFirstLine: '',
    addressCity: '',
    addressPostcode: '',
};

export const initPostcode: Postcode = {
    lat: 51.5074,
    lng: 0.1278,
    zoom: 11,
};
