/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
    PatientToJSONTyped,
} from './Patient';

/**
 * 
 * @export
 * @interface PatientsListForAdminResponse
 */
export interface PatientsListForAdminResponse {
    /**
     * 
     * @type {Array<Patient>}
     * @memberof PatientsListForAdminResponse
     */
    patientsList: Array<Patient>;
}

/**
 * Check if a given object implements the PatientsListForAdminResponse interface.
 */
export function instanceOfPatientsListForAdminResponse(value: object): value is PatientsListForAdminResponse {
    if (!('patientsList' in value) || value['patientsList'] === undefined) return false;
    return true;
}

export function PatientsListForAdminResponseFromJSON(json: any): PatientsListForAdminResponse {
    return PatientsListForAdminResponseFromJSONTyped(json, false);
}

export function PatientsListForAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientsListForAdminResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'patientsList': ((json['patientsList'] as Array<any>).map(PatientFromJSON)),
    };
}

export function PatientsListForAdminResponseToJSON(json: any): PatientsListForAdminResponse {
    return PatientsListForAdminResponseToJSONTyped(json, false);
}

export function PatientsListForAdminResponseToJSONTyped(value?: PatientsListForAdminResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'patientsList': ((value['patientsList'] as Array<any>).map(PatientToJSON)),
    };
}

