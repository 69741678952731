import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import 'react-phone-number-input/style.css';

export type EmailFieldChangeValues = {[key: string]: string} & {
    isValid: boolean;
};

interface EmailFormFieldProps {
    email: string;
    emailKey: string;
    emailLabel?: string | null;
    emailPlaceholder?: string;
    changeValues: (values: EmailFieldChangeValues) => void;
    failedRegistration?: boolean;
}

const EmailFormField: React.FC<EmailFormFieldProps> = ({
    email,
    emailKey,
    emailLabel = null,
    emailPlaceholder = 'Enter email',
    changeValues,
    failedRegistration = false,
}) => {
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

    const setEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = e.target.value.toLowerCase();
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = emailPattern.test(emailValue);
        setIsEmailValid(isValid);
        changeValues({
            [emailKey]: emailValue,
            isValid,
        } as EmailFieldChangeValues);
    };

    return (
        <Form.Group controlId="email" className="mt-2">
            {emailLabel && (
                <Form.Label className="my-0">{emailLabel}</Form.Label>
            )}
            <Form.Control
                required
                type="email"
                placeholder={emailPlaceholder}
                value={email}
                onChange={setEmail}
                isInvalid={!isEmailValid || failedRegistration}
            />
            <Form.Control.Feedback type="invalid">
                {!isEmailValid && 'Please enter a valid email address.'}
                {failedRegistration &&
                    'Email address has already been used by another user.'}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default EmailFormField;
