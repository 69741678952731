import React, {useState, useEffect, useMemo} from 'react';
import {Form, Button} from 'react-bootstrap';
import TitleDropdown from '../TitleDropdown/TitleDropdown';
import EmailFormField, {
    EmailFieldChangeValues,
} from '../../app/parts/FormFields/EmailFormField';
import {useAuthService} from '@/actions';
import PasswordField from '@components/app/parts/PasswordField';
import {useRegistrationContext} from '@contexts/RegistrationContext';
import {debounce} from 'lodash';

const RegisterPersonalDetailsForm = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const {registrationData, updateRegistrationData, setNextStep} =
        useRegistrationContext();

    const authService = useAuthService();

    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
    const [dupEmail, setDupEmail] = useState<boolean>(false);

    const checkFormReady = (): boolean => {
        const ready =
            registrationData.title !== '' &&
            registrationData.firstName !== '' &&
            registrationData.lastName !== '' &&
            registrationData.email !== '' &&
            registrationData.password !== '' &&
            registrationData.rePassword !== '';
        return ready;
    };

    const handleEmailCheck = async (email: string): Promise<void> => {
        const emailValid = await authService.registerCheckEmail({
            email,
        });
        setDupEmail(!emailValid.emailValid);
    };

    const debouncedEmailCheck = useMemo(
        () => debounce((email: string) => handleEmailCheck(email), 1000),
        [registrationData.email]
    );

    const handleEmailChange = (e: EmailFieldChangeValues) => {
        updateRegistrationData({email: e.email});
        debouncedEmailCheck(e.email);
    };

    useEffect(() => {
        setPasswordsMatch(
            registrationData.password === registrationData.rePassword
        );
    }, [registrationData.password, registrationData.rePassword]);

    const handleNextStep = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formComplete = checkFormReady();

        if (!passwordsMatch) {
            return;
        }
        if (!formComplete) {
            return;
        }
        if (dupEmail) {
            return;
        }
        setNextStep();
    };

    return (
        <div className="register-user-page">
            <Form onSubmit={handleNextStep}>
                <div className="row">
                    <div
                        className="col-md-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Form.Group
                            controlId="title"
                            className="mt-2 dropdown-tile-group"
                        >
                            <Form.Label className="my-0">Title</Form.Label>
                            <TitleDropdown
                                title={registrationData.title}
                                sendTitle={updateRegistrationData}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-8 py-1">
                        <Form.Group controlId="firstName" className="mt-2">
                            <Form.Label className="my-0">First name</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter first name"
                                defaultValue={registrationData.firstName}
                                onChange={(e) =>
                                    updateRegistrationData({
                                        firstName: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </div>
                </div>

                <Form.Group controlId="lastName" className="mt-2">
                    <Form.Label className="my-0">Last name</Form.Label>
                    <Form.Control
                        required
                        type="name"
                        placeholder="Enter last name"
                        defaultValue={registrationData.lastName}
                        onChange={(e) =>
                            updateRegistrationData({lastName: e.target.value})
                        }
                    />
                </Form.Group>

                <EmailFormField
                    email={registrationData.email}
                    emailKey="email"
                    emailLabel="User contact email"
                    changeValues={handleEmailChange}
                    failedRegistration={dupEmail}
                />
                <PasswordField
                    controlId="password"
                    label="Password"
                    password={registrationData.password}
                    keyLabel="password"
                    changeValues={updateRegistrationData}
                />
                <PasswordField
                    controlId="rePassword"
                    label="Confirm Password"
                    password={registrationData.rePassword}
                    keyLabel="rePassword"
                    changeValues={updateRegistrationData}
                    isInvalid={!passwordsMatch}
                    invalidString="Passwords do not match!"
                />
                <Button
                    className="sel-button mt-4 w100"
                    type="submit"
                    variant="primary"
                >
                    Next
                </Button>
            </Form>
        </div>
    );
};

export default RegisterPersonalDetailsForm;
