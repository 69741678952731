/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PX` - Patient
 * * `CLINIC` - Clinic
 * * `DOCTOR CLINIC` - Doctors
 * @export
 */
export const AddressTypeEnum = {
    Px: 'PX',
    Clinic: 'CLINIC',
    DoctorClinic: 'DOCTOR CLINIC'
} as const;
export type AddressTypeEnum = typeof AddressTypeEnum[keyof typeof AddressTypeEnum];


export function instanceOfAddressTypeEnum(value: any): boolean {
    for (const key in AddressTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(AddressTypeEnum, key)) {
            if (AddressTypeEnum[key as keyof typeof AddressTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AddressTypeEnumFromJSON(json: any): AddressTypeEnum {
    return AddressTypeEnumFromJSONTyped(json, false);
}

export function AddressTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressTypeEnum {
    return json as AddressTypeEnum;
}

export function AddressTypeEnumToJSON(value?: AddressTypeEnum | null): any {
    return value as any;
}

export function AddressTypeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): AddressTypeEnum {
    return value as AddressTypeEnum;
}

