import React, {useState, ChangeEvent, FormEvent} from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {
    EmailFormField,
    EmailFieldChangeValues,
} from '@components/app/parts/FormFields';
import HelpRequestPopup from '@/components/app/HelpRequestPopup';
import {useUserService} from '@/actions';

interface FormData {
    email: string;
    name: string;
    subject: string;
    message: string;
}

interface HelpScreenProps {
    authenticated: boolean;
}

function createInitFormData(): FormData {
    return {
        email: '',
        name: '',
        subject: '',
        message: '',
    };
}

const HelpScreen: React.FC<HelpScreenProps> = ({authenticated}) => {
    const userService = useUserService();

    const [formData, setFormData] = useState<FormData>(createInitFormData());
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const {email, name, subject, message} = formData;

    const changeValues = (e: {key: keyof FormData; value: string}) => {
        setFormData({...formData, [e.key]: e.value});
    };

    const handleRequestHelp = async () => {
        const requestSuccess = await userService.requestHelp(formData);
        if (!requestSuccess) return;
        setFormData(createInitFormData());
        setShowSuccess(true);
    };

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleRequestHelp();
    };

    return (
        <div className="medr-layout text-center register-user-page">
            <HelpRequestPopup
                showModal={showSuccess}
                handleClose={() => setShowSuccess(false)}
            />
            <Row className="medr-layout text-center">
                <Col>
                    <h4>Contact the MedR Team</h4>
                </Col>
            </Row>

            <Form onSubmit={onSubmit} className="text-left">
                {!authenticated && (
                    <>
                        <Form.Group controlId="firstName" className="mt-2">
                            <Form.Label className="my-0">Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter first name"
                                value={name}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    changeValues({
                                        key: 'name',
                                        value: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <EmailFormField
                            email={email}
                            emailKey="email"
                            emailLabel="User contact email"
                            changeValues={(e: EmailFieldChangeValues) =>
                                changeValues({key: 'email', value: e.email})
                            }
                        />
                    </>
                )}
                <Form.Group controlId="subject" className="mt-2">
                    <Form.Label className="my-0">
                        Help request subject
                    </Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter topic"
                        value={subject}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            changeValues({
                                key: 'subject',
                                value: e.target.value,
                            })
                        }
                    />
                </Form.Group>
                <Form.Group controlId="message" className="mt-2">
                    <Form.Label className="my-0">Request details</Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        placeholder="Enter request details"
                        rows={6}
                        value={message}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            changeValues({
                                key: 'message',
                                value: e.target.value,
                            })
                        }
                    />
                </Form.Group>
                <Button
                    className="sel-button mt-4 w100"
                    type="submit"
                    variant="primary"
                >
                    Send Request
                </Button>
            </Form>
        </div>
    );
};

export default HelpScreen;
