import {useState, useEffect, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import useJobRoles from '@hooks/clinicians/useJobRoles';
import {useJobRoleService} from '@/actions';
import {RootState} from '@/reducers';
import {JobRoleFullParameters} from '@hooks/clinicians/interfaces';
import {ViewOption} from '@/constants/styles';
import {SelectedJobrole} from '@/contexts/RegistrationContext';

export interface JobRoleInfo {
    jobRoleId: number;
    selectedJobRole: string;
    selectedJobSpecialty: string;
    selectedJobSubSpecialty: string;
}
interface JobSubSpecialtyOption {
    subSpecialty: string;
    id: number;
}

interface UseJobRoleFilterProps {
    initialValues: JobRoleInfo;
    filtered: boolean;
    changeValues: (values: SelectedJobrole) => void;
    clearAfterSelect: boolean;
}

interface UseJobRoleFilterReturn {
    jobRoles: ViewOption[];
    jobSpecialties: ViewOption[];
    jobSubSpecialties: ViewOption[];
    selectedJobRole: ViewOption;
    selectedJobSpecialty: ViewOption;
    selectedJobSubSpecialty: ViewOption;
    showSecondBar: boolean;
    showThirdBar: boolean;
    updateSelectedJobRole: (opt: ViewOption) => void;
    updateSelectedJobSpecialty: (opt: ViewOption) => void;
    updateSelectedJobSubSpecialty: (opt: ViewOption) => void;
    resetState: () => void;
}

const useJobRoleFilter = ({
    initialValues,
    filtered,
    changeValues,
    clearAfterSelect,
}: UseJobRoleFilterProps): UseJobRoleFilterReturn => {
    const jobRoleService = useJobRoleService();

    const {getJobRoleById} = useJobRoles();

    const specialties = useSelector(
        (state: RootState) => state.clinicians.specialties
    );
    const [parameters, setParameters] = useState<JobRoleFullParameters>(() =>
        initValues(initialValues)
    );

    const [jobRoles, setJobRoles] = useState<ViewOption[]>([]);
    const [jobSpecialties, setJobSpecialties] = useState<ViewOption[]>([]);
    const [jobSubSpecialties, setJobSubSpecialties] = useState<ViewOption[]>(
        []
    );

    // Use a ref to track if this is the initial render
    const isInitialRender = useRef(true);

    const {
        jobRoleId,
        selectedJobRole,
        selectedJobSpecialty,
        selectedJobSubSpecialty,
        showSecondBar,
        showThirdBar,
    } = parameters;

    useEffect(() => {
        const getFilteredJobRoles = async () => {
            if (filtered) {
                await jobRoleService.getFilteredJobRoles();
            } else {
                await jobRoleService.getJobRoles();
            }
        };
        getFilteredJobRoles();
    }, [filtered]);

    useEffect(() => {
        setJobRolesList();
    }, [specialties]);

    useEffect(() => {
        updateSearchParameters();
    }, [
        jobRoleId,
        selectedJobRole,
        selectedJobSpecialty,
        selectedJobSubSpecialty,
    ]);

    useEffect(() => {
        if (selectedJobRole && specialties) {
            updateJobSpecialtiesList(selectedJobRole.value);
        }
    }, [selectedJobRole, specialties]);

    useEffect(() => {
        if (selectedJobRole && selectedJobSpecialty && specialties) {
            updateJobSubSpecialtiesList(
                selectedJobRole.value,
                selectedJobSpecialty.value
            );
        }
    }, [selectedJobRole, selectedJobSpecialty, specialties]);

    const setJobRolesList = useCallback(() => {
        if (!specialties) {
            resetState();
            return;
        }
        const jrList = Object.keys(specialties).sort();
        setJobRoles(jrList.map((item) => ({label: item, value: item})));
    }, [specialties]);

    const updateJobSpecialtiesList = useCallback(
        (jobRole: string) => {
            if (!specialties || !specialties[jobRole]) return;

            const specialtiesList = Object.keys(specialties[jobRole]).sort();
            setJobSpecialties(
                specialtiesList.map((item) => ({label: item, value: item}))
            );

            // If there's only one specialty and we don't have a selected specialty, select it automatically
            const isEndOfDepth =
                specialtiesList.length === 1 &&
                (!selectedJobSpecialty ||
                    selectedJobSpecialty.value === '' ||
                    selectedJobSpecialty.value === 'nan');
            if (isEndOfDepth) {
                const spec = specialtiesList[0];
                updateParameters({
                    selectedJobSpecialty: null, // {label: spec, value: spec},
                    showSecondBar: false,
                });
                updateJobSubSpecialtiesList(jobRole, spec);
            }
        },
        [specialties, selectedJobSpecialty]
    );

    const updateJobSubSpecialtiesList = useCallback(
        (jobRole: string, specialty: string) => {
            if (
                !specialties ||
                !specialties[jobRole] ||
                !specialties[jobRole][specialty]
            )
                return;

            const subSpecialtiesList = specialties[jobRole][specialty];
            // @ts-expect-error TYPING TO BE SORTED
            const subSpecOptions = subSpecialtiesList.map(
                (item: JobSubSpecialtyOption) => ({
                    label: item.subSpecialty,
                    value: item.subSpecialty,
                })
            );
            setJobSubSpecialties(subSpecOptions);

            // Only update if it's not the initial render
            if (!isInitialRender.current) {
                // If there's only one sub-specialty and we don't have a selected sub-specialty, select it automatically
                const isEndOfDepth =
                    subSpecOptions.length === 1 &&
                    (!selectedJobSubSpecialty ||
                        selectedJobSubSpecialty.value === '' ||
                        selectedJobSubSpecialty.value === 'nan');
                if (isEndOfDepth) {
                    // @ts-expect-error TYPING TO BE SORTED
                    const roleId = subSpecialtiesList[0].id;
                    updateParameters({
                        jobRoleId: roleId,
                        selectedJobSubSpecialty: null, // subSpec,
                        showThirdBar: false,
                    });
                } else if (subSpecOptions.length > 1) {
                    if (
                        !selectedJobSubSpecialty ||
                        !selectedJobSubSpecialty.value
                    ) {
                        updateParameters({
                            jobRoleId: 0,
                            showThirdBar: true,
                        });
                    } else {
                        const isSubSpec = specialties[selectedJobRole.value][
                            selectedJobSpecialty.value
                            // @ts-expect-error TYPING TO BE SORTED
                        ].filter(
                            (spec: JobSubSpecialtyOption) =>
                                spec.subSpecialty ===
                                    selectedJobSubSpecialty.value &&
                                spec.id === jobRoleId
                        );
                        if (isSubSpec.length > 0) {
                            updateParameters({
                                jobRoleId: jobRoleId,
                                showThirdBar: true,
                            });
                        } else {
                            updateParameters({
                                jobRoleId: 0,
                                showThirdBar: true,
                            });
                        }
                    }
                }
            } else {
                // It's the initial render, so we just set the flag to false for future renders
                isInitialRender.current = false;
            }
        },
        [specialties, selectedJobSubSpecialty]
    );

    const updateSelectedJobRole = useCallback(
        (opt: ViewOption) => {
            if (!opt?.value || !specialties?.[opt.value]) return;

            updateParameters({
                jobRoleId: 0,
                selectedJobRole: opt,
                selectedJobSpecialty: null,
                selectedJobSubSpecialty: null,
                showSecondBar: true,
                showThirdBar: false,
            });

            updateJobSpecialtiesList(opt.value);
        },
        [specialties, updateJobSpecialtiesList]
    );

    const updateSelectedJobSpecialty = useCallback(
        (opt: ViewOption) => {
            if (
                !opt?.value ||
                !specialties?.[selectedJobRole.value]?.[opt.value]
            )
                return;

            updateParameters({
                jobRoleId: 0,
                selectedJobSpecialty: opt,
                selectedJobSubSpecialty: null,
            });

            updateJobSubSpecialtiesList(selectedJobRole.value, opt.value);
        },
        [selectedJobRole, specialties, updateJobSubSpecialtiesList]
    );

    const updateSelectedJobSubSpecialty = useCallback(
        (opt: ViewOption) => {
            if (!opt?.value) return;

            const roles = specialties[selectedJobRole.value][
                selectedJobSpecialty.value
                //@ts-expect-error  TYPING TO BE SORTED
            ].filter(
                (item: JobSubSpecialtyOption) => item.subSpecialty === opt.value
            );

            if (roles?.length) {
                updateParameters({
                    jobRoleId: roles[0].id,
                    selectedJobSubSpecialty: opt,
                });
            }
        },
        [selectedJobRole, selectedJobSpecialty, specialties]
    );

    const updateParameters = (newParams: Partial<JobRoleFullParameters>) => {
        setParameters((prev: JobRoleFullParameters) => ({
            ...prev,
            ...newParams,
        }));
    };

    const resetState = () => {
        setParameters({
            jobRoleId: 0,
            selectedJobRole: null,
            selectedJobSpecialty: null,
            selectedJobSubSpecialty: null,
            showSecondBar: false,
            showThirdBar: false,
        });
        setJobSpecialties([]);
        setJobSubSpecialties([]);
    };

    const updateSearchParameters = useCallback(() => {
        const changeData: SelectedJobrole = {
            jobRole: jobRoleId && getJobRoleById(jobRoleId),
            jobRoleId,
            selectedJobRole: selectedJobRole?.value || '',
            selectedJobSpecialty: selectedJobSpecialty?.value || '',
            selectedJobSubSpecialty: selectedJobSubSpecialty?.value || '',
        };
        changeValues(changeData);
        if (clearAfterSelect && jobRoleId && jobRoleId !== 0) {
            setParameters(initValues(initialValues));
        }
    }, [
        jobRoleId,
        selectedJobRole,
        selectedJobSpecialty,
        selectedJobSubSpecialty,
        getJobRoleById,
    ]);

    return {
        jobRoles,
        jobSpecialties,
        jobSubSpecialties,
        selectedJobRole,
        selectedJobSpecialty,
        selectedJobSubSpecialty,
        showSecondBar,
        showThirdBar,
        updateSelectedJobRole,
        updateSelectedJobSpecialty,
        updateSelectedJobSubSpecialty,
        resetState,
    };
};

function initValues(values: JobRoleInfo | null): JobRoleFullParameters {
    if (!values) {
        return {
            jobRoleId: 0,
            selectedJobRole: null,
            selectedJobSpecialty: null,
            selectedJobSubSpecialty: null,
            showSecondBar: false,
            showThirdBar: false,
        };
    }

    const createOption = (value: string): ViewOption =>
        value ? {label: value, value} : null;

    return {
        ...values,
        jobRoleId: values.jobRoleId,
        selectedJobRole: createOption(values.selectedJobRole),
        selectedJobSpecialty: createOption(values.selectedJobSpecialty),
        selectedJobSubSpecialty: createOption(values.selectedJobSubSpecialty),
        showSecondBar: !!values.selectedJobSpecialty,
        showThirdBar: !!values.selectedJobSubSpecialty,
    };
}

export default useJobRoleFilter;
