/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobRole
 */
export interface JobRole {
    /**
     * 
     * @type {number}
     * @memberof JobRole
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    specialty?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    subSpecialty?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    jobRole?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    registrationBody?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRole
     */
    jobTitle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobRole
     */
    isVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobRole
     */
    isDoctor?: boolean;
}

/**
 * Check if a given object implements the JobRole interface.
 */
export function instanceOfJobRole(value: object): value is JobRole {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function JobRoleFromJSON(json: any): JobRole {
    return JobRoleFromJSONTyped(json, false);
}

export function JobRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'specialty': json['specialty'] == null ? undefined : json['specialty'],
        'subSpecialty': json['subSpecialty'] == null ? undefined : json['subSpecialty'],
        'jobRole': json['jobRole'] == null ? undefined : json['jobRole'],
        'registrationBody': json['registrationBody'] == null ? undefined : json['registrationBody'],
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'isVerified': json['isVerified'] == null ? undefined : json['isVerified'],
        'isDoctor': json['isDoctor'] == null ? undefined : json['isDoctor'],
    };
}

export function JobRoleToJSON(json: any): JobRole {
    return JobRoleToJSONTyped(json, false);
}

export function JobRoleToJSONTyped(value?: Omit<JobRole, 'id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'specialty': value['specialty'],
        'subSpecialty': value['subSpecialty'],
        'jobRole': value['jobRole'],
        'registrationBody': value['registrationBody'],
        'jobTitle': value['jobTitle'],
        'isVerified': value['isVerified'],
        'isDoctor': value['isDoctor'],
    };
}

