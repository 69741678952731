/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicCenter } from './ClinicCenter';
import {
    ClinicCenterFromJSON,
    ClinicCenterFromJSONTyped,
    ClinicCenterToJSON,
    ClinicCenterToJSONTyped,
} from './ClinicCenter';

/**
 * 
 * @export
 * @interface ListClinicsResponse
 */
export interface ListClinicsResponse {
    /**
     * 
     * @type {Array<ClinicCenter>}
     * @memberof ListClinicsResponse
     */
    clinics: Array<ClinicCenter>;
    /**
     * 
     * @type {Array<ClinicCenter>}
     * @memberof ListClinicsResponse
     */
    doctorClinics: Array<ClinicCenter>;
}

/**
 * Check if a given object implements the ListClinicsResponse interface.
 */
export function instanceOfListClinicsResponse(value: object): value is ListClinicsResponse {
    if (!('clinics' in value) || value['clinics'] === undefined) return false;
    if (!('doctorClinics' in value) || value['doctorClinics'] === undefined) return false;
    return true;
}

export function ListClinicsResponseFromJSON(json: any): ListClinicsResponse {
    return ListClinicsResponseFromJSONTyped(json, false);
}

export function ListClinicsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListClinicsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'clinics': ((json['clinics'] as Array<any>).map(ClinicCenterFromJSON)),
        'doctorClinics': ((json['doctorClinics'] as Array<any>).map(ClinicCenterFromJSON)),
    };
}

export function ListClinicsResponseToJSON(json: any): ListClinicsResponse {
    return ListClinicsResponseToJSONTyped(json, false);
}

export function ListClinicsResponseToJSONTyped(value?: ListClinicsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinics': ((value['clinics'] as Array<any>).map(ClinicCenterToJSON)),
        'doctorClinics': ((value['doctorClinics'] as Array<any>).map(ClinicCenterToJSON)),
    };
}

