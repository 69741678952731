import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {termsPolicyPath} from '@/constants/appConstants';

interface TermsAndConditionsPopupProps {
    showModal: boolean;
    handleClose: () => void;
}

const TermsAndConditionsPopup: React.FC<TermsAndConditionsPopupProps> = ({
    showModal,
    handleClose,
}) => {
    return (
        <Modal show={showModal} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv">
                    MedR terms and conditions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="medr">
                <embed
                    src={termsPolicyPath}
                    type="application/pdf"
                    height="500px"
                    width="100%"
                    className="responsive"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TermsAndConditionsPopup;
