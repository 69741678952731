import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';
import {MapAddress} from '@/generated-client';
import {
    FullClinicianRoleProfile,
    MapViewCoords,
    HoveredClinicianProps,
} from './interfaces';

interface UseCliniciansListReturn {
    clinicianList: FullClinicianRoleProfile[];
    clinicAddresses: MapAddress[];
    getClinicianById: (id: number) => FullClinicianRoleProfile | null;
    setFilterString: (value: string) => void;
    showOnlyFavourites: boolean;
    toggleShowOnlyFavourites: (value: boolean) => void;
    hoveredClinicianUserProfileIds: number[];
    hoveredAddressIds: number[];
    updateHovered: (data: HoveredClinicianProps) => void;
    updateMapViewCoords: (coords: MapViewCoords) => void;
    hoveredClinicianId: number | null;
}

const useCliniciansList = (): UseCliniciansListReturn => {
    const fullClinicianList = useSelector(
        (state: RootState) => state.clinicians.clinicianList
    );
    const fullClinicAddresses = useSelector(
        (state: RootState) => state.clinicians.addressesList
    );
    const [clinicianList, setClinicianList] = useState<
        FullClinicianRoleProfile[]
    >([]);
    const [clinicAddresses, setClinicAddresses] = useState<MapAddress[]>([]);
    const [showOnlyFavourites, setShowOnlyFavourites] =
        useState<boolean>(false);
    const [filterString, setFilterString] = useState<string>('');
    const [hoveredClinicianUserProfileIds, setHoveredClinicianUserProfileIds] =
        useState<number[]>([]);
    const [hoveredAddressIds, setHoveredAddressIds] = useState<number[]>([]);
    const [hoveredClinicianId, setHoveredClinicianId] = useState<number | null>( // eslint-disable-line @typescript-eslint/no-unused-vars
        null
    );

    const [filterViewCoords, setFilterViewCoords] =
        useState<MapViewCoords | null>(null);

    const updateMapViewCoords = (coords: MapViewCoords) => {
        if (!coords) return;
        setFilterViewCoords(coords);
    };

    const filterCliniciansViewCoords = (
        clinicians: FullClinicianRoleProfile[]
    ): FullClinicianRoleProfile[] => {
        if (!filterViewCoords) return clinicians;
        return clinicians.filter((clinician: FullClinicianRoleProfile) => {
            const lat = clinician.clinic.address.latitude;
            const lng = clinician.clinic.address.longitude;
            const mainInView =
                lat >= filterViewCoords.sw.lat &&
                lat <= filterViewCoords.ne.lat &&
                lng >= filterViewCoords.sw.lng &&
                lng <= filterViewCoords.ne.lng;
            if (mainInView) return true;

            if (
                clinician.otherClinics &&
                Array.isArray(clinician.otherClinics)
            ) {
                return clinician.otherClinics.some((otherClinic) => {
                    return (
                        otherClinic.clinic.address.latitude >=
                            filterViewCoords.sw.lat &&
                        otherClinic.clinic.address.latitude <=
                            filterViewCoords.ne.lat &&
                        otherClinic.clinic.address.longitude >=
                            filterViewCoords.sw.lng &&
                        otherClinic.clinic.address.longitude <=
                            filterViewCoords.ne.lng
                    );
                });
            }
            return false;
        });
    };

    const filterCliniciansFavourites = (
        clinicians: FullClinicianRoleProfile[]
    ): FullClinicianRoleProfile[] => {
        if (!showOnlyFavourites) return clinicians;
        return clinicians.filter((clinician) => clinician.isFavourite);
    };

    const filterCliniciansByString = (
        clinicians: FullClinicianRoleProfile[]
    ): FullClinicianRoleProfile[] => {
        if (!filterString || filterString === '') return clinicians;
        return clinicians.filter((clinician: FullClinicianRoleProfile) =>
            `${clinician.userProfile.title} ${clinician.userProfile.firstName} ${clinician.userProfile.lastName}`
                .toLowerCase()
                .includes(filterString.toLowerCase())
        );
    };

    const filterClinicians = () => {
        const favLevel = filterCliniciansFavourites(fullClinicianList);
        const strLevel = filterCliniciansByString(favLevel);
        const coordLevel = filterCliniciansViewCoords(strLevel);
        return coordLevel;
    };

    const filterClinicAddresses = (clinicianUserProfileIds: number[]) => {
        if (!fullClinicAddresses) return [];
        const newAddresses = fullClinicAddresses.filter((address: MapAddress) =>
            address.userProfileIds.some((id: number) =>
                clinicianUserProfileIds.includes(id)
            )
        );
        return newAddresses;
    };

    const updateClinicianList = (): void => {
        if (!fullClinicianList) return;
        const clins = filterClinicians();
        const clinIds = clins.map((clinician) => clinician.userProfile.id);

        setClinicianList(clins);

        if (!fullClinicAddresses) return;
        const adds = filterClinicAddresses(clinIds);
        setClinicAddresses(adds);
    };

    useEffect(() => {
        updateClinicianList();
    }, [
        fullClinicianList,
        showOnlyFavourites,
        filterString,
        fullClinicAddresses,
        filterViewCoords,
    ]);

    const toggleShowOnlyFavourites = (value: boolean): void =>
        setShowOnlyFavourites(value);

    const updateHovered = (data: HoveredClinicianProps): void => {
        if (
            !data ||
            !data.hoveredClinicianUserProfileIds ||
            !data.hoveredAddressIds
        )
            return;

        /**
         * Theocharis:
         * Added this check so the hoveredAddressIds array is updated only if we have different count of hovered markers
         * Theoretically there is a chance of having two markers very close to each other, and a very fast cursor move could be fail to update the marker.
         * But I think it is ok for this use case.
         *
         */

        if (
            !hoveredClinicianUserProfileIds.some((r) =>
                data.hoveredClinicianUserProfileIds.includes(r)
            )
        ) {
            setHoveredClinicianUserProfileIds(
                data.hoveredClinicianUserProfileIds
            );
        }

        if (
            !hoveredAddressIds.some((r) => data.hoveredAddressIds.includes(r))
        ) {
            setHoveredAddressIds(data.hoveredAddressIds);
        }
    };

    const getClinicianById = (id: number): FullClinicianRoleProfile => {
        const clins = clinicianList.filter(
            (clinician: FullClinicianRoleProfile) => clinician.id === id
        );
        if (clins.length === 0) return null;
        return clins[0];
    };

    return {
        clinicianList,
        clinicAddresses,
        getClinicianById,
        setFilterString,
        showOnlyFavourites,
        toggleShowOnlyFavourites,
        hoveredClinicianUserProfileIds,
        hoveredAddressIds,
        updateHovered,
        updateMapViewCoords,
        hoveredClinicianId,
    };
};

export default useCliniciansList;
