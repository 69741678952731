/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InsuranceAgreement } from './InsuranceAgreement';
import {
    InsuranceAgreementFromJSON,
    InsuranceAgreementFromJSONTyped,
    InsuranceAgreementToJSON,
    InsuranceAgreementToJSONTyped,
} from './InsuranceAgreement';

/**
 * 
 * @export
 * @interface InsuranceAgreementByIdResponse
 */
export interface InsuranceAgreementByIdResponse {
    /**
     * 
     * @type {InsuranceAgreement}
     * @memberof InsuranceAgreementByIdResponse
     */
    insuranceAgreement: InsuranceAgreement;
}

/**
 * Check if a given object implements the InsuranceAgreementByIdResponse interface.
 */
export function instanceOfInsuranceAgreementByIdResponse(value: object): value is InsuranceAgreementByIdResponse {
    if (!('insuranceAgreement' in value) || value['insuranceAgreement'] === undefined) return false;
    return true;
}

export function InsuranceAgreementByIdResponseFromJSON(json: any): InsuranceAgreementByIdResponse {
    return InsuranceAgreementByIdResponseFromJSONTyped(json, false);
}

export function InsuranceAgreementByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceAgreementByIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'insuranceAgreement': InsuranceAgreementFromJSON(json['insuranceAgreement']),
    };
}

export function InsuranceAgreementByIdResponseToJSON(json: any): InsuranceAgreementByIdResponse {
    return InsuranceAgreementByIdResponseToJSONTyped(json, false);
}

export function InsuranceAgreementByIdResponseToJSONTyped(value?: InsuranceAgreementByIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'insuranceAgreement': InsuranceAgreementToJSON(value['insuranceAgreement']),
    };
}

