import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import useJobRoleFilter, {
    JobRoleInfo,
} from '@/hooks/clinicians/useJobRoleFilter';
import {customStyles} from '@constants/styles';

interface JobRoleSearchBarProps {
    values?: JobRoleInfo;
    changeValues: (values: JobRoleInfo) => void;
    filtered?: boolean;
    clearAfterSelect?: boolean;
}

const JobRoleSearchBar: React.FC<JobRoleSearchBarProps> = ({
    changeValues,
    values = null,
    filtered = false,
    clearAfterSelect = false,
}) => {
    const {
        jobRoles,
        jobSpecialties,
        jobSubSpecialties,
        selectedJobRole,
        selectedJobSpecialty,
        selectedJobSubSpecialty,
        showSecondBar,
        showThirdBar,
        updateSelectedJobRole,
        updateSelectedJobSpecialty,
        updateSelectedJobSubSpecialty,
    } = useJobRoleFilter({
        initialValues: values,
        filtered,
        changeValues,
        clearAfterSelect,
    });

    return (
        <>
            <Row className="mb-3 custom-dropdown">
                <Col>
                    <Select
                        name="clinicianType"
                        options={jobRoles}
                        value={selectedJobRole}
                        onChange={updateSelectedJobRole}
                        placeholder="Select clinician role"
                        styles={customStyles}
                        className="custom-dropdown-select"
                    />
                </Col>
            </Row>

            {showSecondBar && (
                <Row className="mb-3">
                    <Col>
                        <Select
                            name="specialty"
                            options={jobSpecialties}
                            value={selectedJobSpecialty}
                            onChange={updateSelectedJobSpecialty}
                            placeholder="Select specialty"
                            styles={customStyles}
                            className="custom-dropdown-select"
                        />
                    </Col>
                </Row>
            )}
            {showThirdBar && (
                <Row className="mb-3">
                    <Col>
                        <Select
                            name="subSpecialty"
                            options={jobSubSpecialties}
                            value={selectedJobSubSpecialty}
                            onChange={updateSelectedJobSubSpecialty}
                            placeholder="Select sub-specialty"
                            styles={customStyles}
                            className="custom-dropdown-select"
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default JobRoleSearchBar;
