import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
// import {MedrEmails} from '@/constants/variables';

const Footer: React.FC = () => {
    return (
        <footer id="footer" className="medr-light">
            <Container>
                <Row>
                    <Col className="text-center">
                        <Link to="/policy">Terms and conditions</Link>
                    </Col>
                    <Col className="text-center">
                        Copyright &copy; 2024 MedR
                    </Col>
                    <Col className="text-center">
                        <Link to="/faqs">FAQs</Link>
                    </Col>
                    {/* <Col className="text-center">
                        <a href={`mailto:${MedrEmails.ENQUIRIES.toString()}`}>
                            Contact us
                        </a>
                    </Col> */}
                </Row>
                {/* <Row>
                    <Col className="text-center"></Col>
                    <Col className="text-center"> </Col>
                    <Col className="text-center">
                        <Link to="/faqs">FAQs</Link>
                    </Col>
                </Row> */}
            </Container>
        </footer>
    );
};

export default Footer;
