import {
    InsuranceAgreement,
    JobRole,
    ClinicianRoleProfile,
    Patient,
    UserProfile,
    Address,
    ClinicCenter,
} from '@/generated-client';
import {SearchAddressResult} from '@/components/app/parts/FormFields/AddressSearch';
import {
    DelegateVerificationFormData,
    DelegateVerificationFormClinicianData,
} from '@/components/user/delegates/useDelegateVerifcationForm';

export function reformatDate(date: Date): string {
    if (!date || !(date instanceof Date)) {
        return null;
    }
    return date.toLocaleDateString('en-GB');
}

export function randomDateString(): string {
    const start = new Date('1950-01-01').getTime();
    const end = new Date('2000-01-01').getTime();
    const randomTime = start + Math.random() * (end - start);
    const date = new Date(randomTime);
    return reformatDate(date);
}

export function randomNameString(): string {
    const titles = ['Mr', 'Mrs', 'Ms'];
    const randomIndex = Math.floor(Math.random() * titles.length);
    return `${titles[randomIndex]} Exampleperson`;
}

export function randomPhoneNumber(): string {
    const randomNumber = Math.floor(Math.random() * 1000000000);
    return `+447${randomNumber}`;
}

export function reformatDateDash(date: Date): string {
    if (!date || !(date instanceof Date)) {
        return null;
    }
    return date.toISOString().slice(0, 10);
}

export function reformatDateTime(date: Date): string {
    if (!date || !(date instanceof Date)) {
        return null;
    }
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${hours}:${minutes} ${day}/${month}/${year}`;
}

export function getJobRoleString(jobRole: JobRole): string {
    if (!jobRole) {
        return 'Job role missing';
    }

    const isReady = jobRole && jobRole.jobRole;
    if (!isReady) return 'Job role missing';

    if (jobRole.subSpecialty !== 'nan' && jobRole.specialty !== 'nan') {
        return `${jobRole.subSpecialty} - ${jobRole.specialty} (${jobRole.jobRole})`;
    }
    if (jobRole.specialty !== 'nan') {
        return `${jobRole.specialty} (${jobRole.jobRole})`;
    }
    return `${jobRole.jobRole}`;
}

export function getInsuranceAgreementString(
    insuranceAgreement: InsuranceAgreement
): string {
    if (!insuranceAgreement) {
        return 'Insurance agreement missing';
    }
    if (!insuranceAgreement.policyName) {
        return 'Policy name  missing';
    }
    if (!insuranceAgreement.provider) {
        return 'Provider  missing';
    }
    return insuranceAgreement.provider;
}

interface Profile {
    centreName: string | null;
    addressFirstLine: string | null;
    addressPostcode: string | null;
}

export function getClinicianProfileString(profile: Profile | null): string {
    if (!profile) return 'Clinician profile missing';

    const {centreName, addressFirstLine, addressPostcode} = profile;
    let outStr = '';

    if (centreName !== null && centreName !== undefined) {
        outStr += centreName;
        outStr += ', ';
    }
    if (addressFirstLine !== null && addressFirstLine !== undefined) {
        outStr += addressFirstLine;
        outStr += ', ';
    }
    if (addressPostcode !== null && addressPostcode !== undefined) {
        outStr += addressPostcode;
    }

    return outStr;
}

interface ClinicianRoleProfileRank extends ClinicianRoleProfile {
    distanceRank: number | null;
    centreName: string | null;
}

export function getClinicianDistString(
    clinician: ClinicianRoleProfileRank | null
): string {
    if (!clinician) {
        return 'Clinician profile missing';
    }

    const {distanceRank, userProfile, centreName, distance} = clinician;
    const {title, firstName, lastName} = userProfile;

    let outStr = '';

    if (!distanceRank) {
        outStr += distanceRank;
        outStr += '. ';
    }
    if (!title) {
        outStr += title;
        outStr += ' ';
    }
    if (!firstName) {
        outStr += firstName;
        outStr += ' ';
    }
    if (!lastName) {
        outStr += lastName;
        outStr += ' - ';
    }
    if (!centreName) {
        outStr += centreName;
        outStr += ' (';
    }
    if (!distance) {
        outStr += distance;
        outStr += 'km)';
    }

    return outStr;
}

export function getPatientNameString(patient: Patient | null): string {
    if (!patient) {
        return 'Patient missing';
    }

    const {id, title, firstName, lastName, detailsInImage} = patient;

    let outStr: string;
    if (detailsInImage) {
        const ID = String(id).padStart(5, '0');
        outStr = `PX_IMG_${ID}`;
    } else {
        const fieldsPresent = !!title && !!firstName && !!lastName;
        if (!fieldsPresent) {
            return 'Patient data missing';
        }
        outStr = `${title} ${firstName} ${lastName}`;
    }
    return outStr;
}

export function getNameString(
    object:
        | UserProfile
        | Patient
        | DelegateVerificationFormData
        | DelegateVerificationFormClinicianData
        | null
): string {
    if (!object) {
        return 'Name missing';
    }

    const {title, firstName, lastName} = object;
    const varrs = [title, firstName, lastName];

    const lineData = [];
    for (let i = 0; i < varrs.length; i += 1) {
        if (varrs[i]) {
            lineData.push(varrs[i]);
        }
    }
    const outStr = lineData.join(' ');
    return outStr;
}

export function getAddressStr(object: Address | null): string {
    if (!object) {
        return 'Name missing';
    }
    const {firstLine, city, postcode} = object;

    const lineData = [];
    const vars = [firstLine, city, postcode];
    for (let i = 0; i < vars.length; i += 1) {
        if (vars[i]) {
            lineData.push(vars[i]);
        }
    }
    const outStr = lineData.join(', ');
    return outStr;
}

export function getAddressMultiline(
    object: Address | SearchAddressResult
): JSX.Element {
    const notReadyLabel = 'Address missing';

    if (!object) {
        return <p>{notReadyLabel}</p>;
    }

    // TODO: manage current entries where there is no firstLine
    const isReady = !!object.postcode;
    // const isReady = !!object.firstLine && !!object.city && !!object.postcode;

    if (!isReady) {
        return <p>{notReadyLabel}</p>;
    }

    const {firstLine, city, postcode} = object;

    return (
        <>
            <h6>{firstLine}</h6>
            <h6>{city}</h6>
            <h6>{postcode}</h6>
        </>
    );
}

export function getClinicCenterStr(object: ClinicCenter | null): string {
    if (!object || !object.clinicName || !object.address) {
        return 'Clinic info missing';
    }
    const lineData = [];
    if (object.centreName) {
        lineData.push(object.centreName);
    }

    if (object.clinicName) {
        lineData.push(object.clinicName);
    }
    lineData.push(getAddressStr(object.address));
    const outStr = lineData.join(', ');
    return outStr;
}

export function getShortClinicCenterStr(object: ClinicCenter | null): string {
    if (!object || !object.clinicName) {
        return 'Clinic info missing';
    }
    let outStr = '';
    if (object.centreName) {
        outStr += object.centreName;
    }
    return outStr;
}
