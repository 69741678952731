/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InsurerGroups } from './InsurerGroups';
import {
    InsurerGroupsFromJSON,
    InsurerGroupsFromJSONTyped,
    InsurerGroupsToJSON,
    InsurerGroupsToJSONTyped,
} from './InsurerGroups';

/**
 * 
 * @export
 * @interface InsurerListForAdminResponse
 */
export interface InsurerListForAdminResponse {
    /**
     * 
     * @type {InsurerGroups}
     * @memberof InsurerListForAdminResponse
     */
    insurers: InsurerGroups;
}

/**
 * Check if a given object implements the InsurerListForAdminResponse interface.
 */
export function instanceOfInsurerListForAdminResponse(value: object): value is InsurerListForAdminResponse {
    if (!('insurers' in value) || value['insurers'] === undefined) return false;
    return true;
}

export function InsurerListForAdminResponseFromJSON(json: any): InsurerListForAdminResponse {
    return InsurerListForAdminResponseFromJSONTyped(json, false);
}

export function InsurerListForAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurerListForAdminResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'insurers': InsurerGroupsFromJSON(json['insurers']),
    };
}

export function InsurerListForAdminResponseToJSON(json: any): InsurerListForAdminResponse {
    return InsurerListForAdminResponseToJSONTyped(json, false);
}

export function InsurerListForAdminResponseToJSONTyped(value?: InsurerListForAdminResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'insurers': InsurerGroupsToJSON(value['insurers']),
    };
}

