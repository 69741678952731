import React, {useEffect, useRef, useState} from 'react';
import {Marker, Popup, useMap} from 'react-leaflet'; // eslint-disable-line @typescript-eslint/no-unused-vars
import L from 'leaflet';
import './map.css';
import 'leaflet/dist/leaflet.css';
import useClinicMarker from './useClinicMarker';
import {useClinicianService} from '@/actions';
import {FaUserMd} from 'react-icons/fa';
import {MapAddress} from '@/generated-client';
import {HoveredClinicianProps} from '@/hooks/clinicians/interfaces';
interface ClinicMarkerProps {
    clinic: MapAddress;
    hoveredAddressIds: number[];
    updateHovered: (data: HoveredClinicianProps) => void;
    setIsMapFrozen: (value: boolean) => void;
    numberOfClinicians: number;
}

interface ClinicianDetails {
    id: number;
    name: string;
    email?: string;
    jobTitle?: string;
}

const ClinicMarker: React.FC<ClinicMarkerProps> = ({
    clinic,
    hoveredAddressIds,
    updateHovered,
    setIsMapFrozen,
    numberOfClinicians,
}) => {
    const {geocode, listOfClinicians, onMouseEnter, onMouseLeave} =
        useClinicMarker({clinic, hoveredAddressIds, updateHovered});

    const clinicianService = useClinicianService();
    const [clinicianNames, setClinicianNames] = useState<ClinicianDetails[]>(
        []
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const markerRef = useRef<L.Marker>(null);
    const popupRef = useRef<L.Popup>(null);

    const customMarkerIcon = L.divIcon({
        className: `custom-pin-icon ${isHovered ? 'hovered' : ''}`,
        html: `
            <div class="pin-container ${isHovered ? 'hovered' : ''}">
                <div class="pin">
                    <span class="pin-text">${numberOfClinicians}</span>
                </div>
                <div class="pin-shadow"></div>
            </div>
        `,
        iconSize: [30, 40],
        iconAnchor: [15, 40],
        popupAnchor: [0, -40],
    });

    const fetchClinicianInfo = async () => {
        if (popupOpen) return;
        setPopupOpen(true);
        setIsMapFrozen(true);

        if (isDataFetched || !listOfClinicians.length) return;
        setLoading(true);

        try {
            const clinicians =
                await clinicianService.getCliniciansByUserIds(listOfClinicians);

            if (!clinicians || clinicians.length === 0) {
                console.warn('No clinician data returned.');
                return;
            }

            const clinicianDetails: ClinicianDetails[] = clinicians.flatMap(
                (clinician, index) => {
                    if (
                        !clinician ||
                        !clinician.allCrps ||
                        clinician.allCrps.length === 0
                    ) {
                        return [
                            {
                                id: listOfClinicians[index],
                                name: 'Unknown Clinician',
                            },
                        ];
                    }

                    return clinician.allCrps.map((crp) => ({
                        id: crp.id,
                        name: crp.userProfile
                            ? `${crp.userProfile.firstName} ${crp.userProfile.lastName}`.trim()
                            : 'Unknown Clinician',
                        email:
                            crp.userProfile?.user?.email || 'No Email Provided',
                        jobTitle:
                            crp.userProfile?.jobRole?.[0]?.jobTitle ||
                            'No Job Title',
                    }));
                }
            );

            setClinicianNames(clinicianDetails);
            setIsDataFetched(true);
        } catch (e) {
            console.error('Error fetching clinician info:', e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (popupOpen && markerRef.current) {
            markerRef.current.openPopup();
        }
    }, [popupOpen]);

    const onPopupClose = () => {
        setPopupOpen(false);
        setIsMapFrozen(false);
    };

    return (
        <Marker
            position={geocode}
            icon={customMarkerIcon}
            ref={markerRef}
            eventHandlers={{
                mouseover: () => {
                    if (!popupOpen) onMouseEnter();
                    setIsHovered(true);
                },
                mouseout: () => {
                    if (!popupOpen) onMouseLeave();
                    setIsHovered(false);
                },
                click: fetchClinicianInfo,
            }}
        >
            <Popup
                ref={popupRef}
                closeButton={true}
                // @ts-expect-error Leaflet typings are incorrect
                onClose={onPopupClose}
                autoClose={false}
                closeOnEscapeKey={true}
                keepInView={true}
                className="fade-in-popup"
                offset={[0, 0]}
            >
                <div className="popup-container">
                    <div className="popup-header">
                        <h3>Clinicians</h3>
                    </div>
                    {loading ? (
                        <p className="loading-text">Loading...</p>
                    ) : (
                        <div className="popup-list">
                            {clinicianNames.map((clinician) => (
                                <div
                                    key={clinician.id}
                                    className="clinician-card"
                                >
                                    <div className="clinician-info">
                                        <FaUserMd className="clinician-icon" />
                                        <strong>{clinician.name}</strong>
                                        <p className="email">
                                            <span className="job-title">
                                                {clinician.jobTitle}
                                                {'\n'}
                                                {clinician.email}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Popup>
        </Marker>
    );
};

export default ClinicMarker;
