import {useState, useMemo} from 'react';
import {Referral} from '@/generated-client';

interface UseFilterReferralsListProps {
    referralsList: Referral[];
    start: number;
    end: number;
}

const useFilterReferralsList = ({
    referralsList,
    start,
    end,
}: UseFilterReferralsListProps): [
    Referral[],
    (startInd: number, endInd: number) => void,
    number,
] => {
    const [startSlice, setStartSlice] = useState<number>(start);
    const [endSlice, setEndSlice] = useState<number>(end);

    const referralsLength = useMemo(
        () => referralsList.length,
        [referralsList]
    );

    const updateSlice = (startInd: number, endInd: number): void => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    const referrals = useMemo(() => {
        if (!referralsList || referralsList.length === 0) {
            return [];
        }
        return referralsList.slice(startSlice, endSlice);
    }, [referralsList, startSlice, endSlice]);

    return [referrals, updateSlice, referralsLength];
};

export default useFilterReferralsList;
