import {Dispatch} from 'redux';
import {NavigateFunction} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {JobRolesApi, JobRole} from '@/generated-client';
import {RootState} from '@/reducers';
import {MedrService} from './authService';
import * as RED from '@/reducers/cliniciansReducer';

export class JobRoleService extends MedrService {
    public jobRoles: JobRole[];

    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
        this.jobRoles = useSelector(
            (state: RootState) => state.clinicians.jobRoles
        );
    }

    async getJobRoles(): Promise<boolean> {
        const api = new JobRolesApi(this.configuration);
        return this.executeApiCall(
            () => api.jobRolesList(),
            RED.dispatchGetJobRolesRequest,
            RED.dispatchGetJobRolesSuccess,
            RED.dispatchGetJobRolesFail
        );
    }

    async getFilteredJobRoles(): Promise<boolean> {
        const api = new JobRolesApi(this.configuration);
        return this.executeApiCall(
            () => api.jobRolesFilteredRetrieve(),
            RED.dispatchGetFilteredJobRolesRequest,
            RED.dispatchGetFilteredJobRolesSuccess,
            RED.dispatchGetFilteredJobRolesFail
        );
    }

    async getJobRoleById(id: number): Promise<boolean> {
        const payload = {id};
        const api = new JobRolesApi(this.configuration);
        return this.executeApiCall(
            () => api.jobRolesRetrieve(payload),
            RED.dispatchGetJobRoleByIdRequest,
            RED.dispatchGetJobRoleByIdSuccess,
            RED.dispatchGetJobRoleByIdFail
        );
    }
}
