import {useEffect, useState} from 'react';
import {EmailFieldChangeValues} from '@/components/app/parts/FormFields/EmailFormField';
import {PhoneFieldChangeValues} from '@/components/app/parts/FormFields';
import {RegistrationClinicianContact} from '@contexts/RegistrationContext';

interface UseReferralsContactsDisplayProps {
    contacts: RegistrationClinicianContact[];
    updateAppointmentContacts: (
        contacts: RegistrationClinicianContact[]
    ) => void;
    requiredEmails: string[];
}

interface UseReferralsContactsDisplayReturn {
    newContacts: RegistrationClinicianContact[] | null;
    newContactFormData: Partial<RegistrationClinicianContact>;
    showForm: string | null;
    setShowForm: (show: string) => void;
    updateNewContactEmail: (e: EmailFieldChangeValues) => void;
    updateNewContactPhoneNumber: (e: PhoneFieldChangeValues) => void;
    addNewContact: () => void;
    removeNewContact: (contact: RegistrationClinicianContact) => void;
}

interface NewContact {
    type: string;
    email: string;
    phoneNumber: string;
}

const useReferralsContactsDisplay = ({
    contacts,
    updateAppointmentContacts,
    requiredEmails,
}: UseReferralsContactsDisplayProps): UseReferralsContactsDisplayReturn => {
    const initNewContact = {
        type: 'email',
        email: '',
        phoneNumber: '',
    };
    const [newContacts, setNewContacts] = useState(null);
    const [showForm, setShowForm] = useState(null);
    const [newContactFormData, setNewContactFormData] =
        useState<NewContact>(initNewContact);

    useEffect(() => {
        if (contacts) {
            const nc = contacts.map((item) => {
                const isMain = requiredEmails.some((em) => em === item.email);
                return {...item, isMain};
            });
            setNewContacts(nc);
        } else {
            setNewContacts([]);
        }
    }, [contacts]);

    const updateNewContactEmail = (e: EmailFieldChangeValues) =>
        setNewContactFormData({
            type: 'email',
            email: e.email,
            phoneNumber: '',
        });
    const updateNewContactPhoneNumber = (e: PhoneFieldChangeValues) =>
        setNewContactFormData({
            type: 'phoneNumber',
            email: '',
            phoneNumber: e.phoneNumber,
        });
    const addNewContact = () => {
        if (!newContactFormData) return;
        if (
            newContactFormData.email === '' &&
            newContactFormData.phoneNumber === ''
        )
            return;
        if (
            newContacts.find(
                (item: RegistrationClinicianContact) =>
                    item.type === newContactFormData.type &&
                    item.email === newContactFormData.email &&
                    item.phoneNumber === newContactFormData.phoneNumber
            )
        ) {
            setNewContactFormData(initNewContact);
            return;
        }
        const newList = [...newContacts, newContactFormData];
        setNewContacts(newList);
        setNewContactFormData(initNewContact);
        updateAppointmentContacts(newList);
    };
    const removeNewContact = (contact: Partial<NewContact>) => {
        setTimeout(() => {
            const newList = newContacts.filter(
                (item: Partial<NewContact>) => item !== contact
            );
            setNewContacts(newList);
            updateAppointmentContacts(newList);
        }, 1000);
    };
    return {
        newContacts,
        newContactFormData,
        showForm,
        setShowForm,
        updateNewContactEmail,
        updateNewContactPhoneNumber,
        addNewContact,
        removeNewContact,
    };
};

export default useReferralsContactsDisplay;
