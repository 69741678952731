import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';
import ClinicsDisplayBox from '../../clinicians/ClinicsDisplayBox';
import {reformatDate} from '@/actions/general';
import TermsAndConditionsPopup from '../../app/alerts/TermsAndConditionsPopup';
import BetaVersionFreeAccountAlert from '../../app/alerts/BetaVersionFreeAccountAlert';
import {ReferralContact} from '../ReferralContacts/ReferralContactsDisplay';
import {useRegistrationContext} from '@contexts/RegistrationContext';
import {JobRole} from '@/generated-client';
import {RegistrationClinicianContact} from '@contexts/RegistrationContext';
import NotificationMessage, {
    Variant,
} from '@/components/app/NotificationMessage';

const RegisterReview: React.FC = () => {
    const {registrationData, onSubmit, showError, toggleShowError} =
        useRegistrationContext();

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const updateTermsAccepted = () =>
        setTermsAccepted((prevState) => !prevState);

    if (!registrationData) {
        return (
            <div className="medr-rounded">
                <Row>
                    <Col>
                        <h4>Error missing data</h4>
                    </Col>
                </Row>
            </div>
        );
    }
    const {
        email,
        title,
        firstName,
        lastName,
        jobRoles,
        registrationNumber,
        dob,
        clinics,
        appointmentsContacts,
    } = registrationData;

    const jobRoleDisplay = () => {
        if (!jobRoles) return null;
        return jobRoles.map((role: JobRole) => (
            <div key={role.id}>{role.jobTitle}</div>
        ));
    };

    const appContactList =
        appointmentsContacts &&
        appointmentsContacts.map((contact: RegistrationClinicianContact) => (
            <ReferralContact contact={contact} key={uuidv4()} />
        ));

    const appContactsBox = (
        <>
            <h5 className="bluegreen">Appointments contact information</h5>
            <div className="medr-rounded-inv">{appContactList}</div>
        </>
    );

    const name = `${title} ${firstName} ${lastName}`;
    const dobDate = new Date(dob);

    const rowData = [
        {label: 'Name:', value: name},
        {label: 'Clinician Role:', value: jobRoleDisplay()},
        {label: 'Registration Number:', value: registrationNumber},
        {label: 'DOB:', value: reformatDate(dobDate)},
        {label: 'Email:', value: email},
    ];

    return (
        <>
            <NotificationMessage
                show={showError}
                toggleShow={toggleShowError}
                variant={Variant.DANGER}
                message="There has been an error in the registration process. The MedR team has been notified."
            />
            <TermsAndConditionsPopup
                showModal={showTerms}
                handleClose={() => setShowTerms(false)}
            />
            <BetaVersionFreeAccountAlert />
            <Row>
                <Col>
                    <h4>Registration summary</h4>
                </Col>
            </Row>
            <div className="medr-rounded mb-4">
                <Row className="m-2">
                    <Col>
                        <h5 className="bluegreen">Clinician details</h5>
                    </Col>
                </Row>
                {rowData.map((row) => (
                    <Row className="m-2" key={row.label}>
                        <Col className="width-m45">{row.label}</Col>
                        <Col>{row.value}</Col>
                    </Row>
                ))}
                <Row className="mt-2">
                    <Col>
                        <p className="text-center">
                            Return to previous screen to see insurance providers
                            selected
                        </p>
                    </Col>
                </Row>
                {appointmentsContacts.length > 0 ? appContactsBox : null}
            </div>

            <div className="mt-4 mb-4">
                <ClinicsDisplayBox clinics={clinics} editable={false} />
            </div>
            <Row className="text-left mt-4">
                <Col className="my-0">
                    <Form.Group controlId="consent" className="mb-3">
                        <Form.Label className="my-0 custom-checkbox w100 text-left">
                            <Form.Check className="custom-hidden-checkbox">
                                <input
                                    type="checkbox"
                                    data-testid="consent-checkbox"
                                    id="consent"
                                    checked={termsAccepted}
                                    onChange={updateTermsAccepted}
                                />
                                <div className="d-flex align-items-center">
                                    <i className="fa-regular fa-square-check big-checkbox custom-unchecked" />
                                    <i className="fa-solid fa-square-check big-checkbox custom-checked" />
                                    I agree to the terms and conditions
                                    <span style={{marginRight: '0.5em'}}></span>
                                    <button
                                        type="button"
                                        onClick={() => setShowTerms(true)}
                                        className="btn btn-link p-0 m-0 align-baseline medr-text-inv"
                                        style={{fontWeight: 'bold'}}
                                    >
                                        (see terms and conditions)
                                    </button>
                                </div>
                            </Form.Check>
                        </Form.Label>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className="text-center">
                    <Button
                        className="sel-button w80 my-4"
                        variant="success"
                        type="submit"
                        disabled={!termsAccepted}
                        onClick={onSubmit}
                    >
                        Complete registration
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default RegisterReview;
