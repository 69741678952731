/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClinicianContact
 */
export interface ClinicianContact {
    /**
     * 
     * @type {number}
     * @memberof ClinicianContact
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicianContact
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicianContact
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicianContact
     */
    email: string;
}

/**
 * Check if a given object implements the ClinicianContact interface.
 */
export function instanceOfClinicianContact(value: object): value is ClinicianContact {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function ClinicianContactFromJSON(json: any): ClinicianContact {
    return ClinicianContactFromJSONTyped(json, false);
}

export function ClinicianContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianContact {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'] == null ? undefined : json['type'],
        'phoneNumber': json['phoneNumber'],
        'email': json['email'],
    };
}

export function ClinicianContactToJSON(json: any): ClinicianContact {
    return ClinicianContactToJSONTyped(json, false);
}

export function ClinicianContactToJSONTyped(value?: Omit<ClinicianContact, 'id'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': value['type'],
        'phoneNumber': value['phoneNumber'],
        'email': value['email'],
    };
}

