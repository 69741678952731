/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserToJSONTyped,
} from './User';
import type { PatientTitle } from './PatientTitle';
import {
    PatientTitleFromJSON,
    PatientTitleFromJSONTyped,
    PatientTitleToJSON,
    PatientTitleToJSONTyped,
} from './PatientTitle';
import type { ClinicianContact } from './ClinicianContact';
import {
    ClinicianContactFromJSON,
    ClinicianContactFromJSONTyped,
    ClinicianContactToJSON,
    ClinicianContactToJSONTyped,
} from './ClinicianContact';

/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    readonly id: number;
    /**
     * 
     * @type {User}
     * @memberof UserProfile
     */
    readonly user: User;
    /**
     * 
     * @type {Array<JobRole>}
     * @memberof UserProfile
     */
    readonly jobRole: Array<JobRole>;
    /**
     * 
     * @type {Array<ClinicianContact>}
     * @memberof UserProfile
     */
    readonly appointmentsContact: Array<ClinicianContact>;
    /**
     * 
     * @type {PatientTitle}
     * @memberof UserProfile
     */
    title?: PatientTitle;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    dob?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    profilePicture?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    isPatient?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    clinicianDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    registrationNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    isVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    isDeactivated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    readonly accountCreatedOn: Date;
}

/**
 * Check if a given object implements the UserProfile interface.
 */
export function instanceOfUserProfile(value: object): value is UserProfile {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('jobRole' in value) || value['jobRole'] === undefined) return false;
    if (!('appointmentsContact' in value) || value['appointmentsContact'] === undefined) return false;
    if (!('accountCreatedOn' in value) || value['accountCreatedOn'] === undefined) return false;
    return true;
}

export function UserProfileFromJSON(json: any): UserProfile {
    return UserProfileFromJSONTyped(json, false);
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': UserFromJSON(json['user']),
        'jobRole': ((json['jobRole'] as Array<any>).map(JobRoleFromJSON)),
        'appointmentsContact': ((json['appointmentsContact'] as Array<any>).map(ClinicianContactFromJSON)),
        'title': json['title'] == null ? undefined : PatientTitleFromJSON(json['title']),
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'dob': json['dob'] == null ? undefined : (new Date(json['dob'])),
        'profilePicture': json['profilePicture'] == null ? undefined : json['profilePicture'],
        'isPatient': json['isPatient'] == null ? undefined : json['isPatient'],
        'clinicianDescription': json['clinicianDescription'] == null ? undefined : json['clinicianDescription'],
        'registrationNumber': json['registrationNumber'] == null ? undefined : json['registrationNumber'],
        'isVerified': json['isVerified'] == null ? undefined : json['isVerified'],
        'isDeactivated': json['isDeactivated'] == null ? undefined : json['isDeactivated'],
        'accountCreatedOn': (new Date(json['accountCreatedOn'])),
    };
}

export function UserProfileToJSON(json: any): UserProfile {
    return UserProfileToJSONTyped(json, false);
}

export function UserProfileToJSONTyped(value?: Omit<UserProfile, 'id'|'user'|'jobRole'|'appointmentsContact'|'accountCreatedOn'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': PatientTitleToJSON(value['title']),
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'dob': value['dob'] == null ? undefined : ((value['dob'] as any).toISOString().substring(0,10)),
        'profilePicture': value['profilePicture'],
        'isPatient': value['isPatient'],
        'clinicianDescription': value['clinicianDescription'],
        'registrationNumber': value['registrationNumber'],
        'isVerified': value['isVerified'],
        'isDeactivated': value['isDeactivated'],
    };
}

