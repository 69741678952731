/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegistrationAddress } from './RegistrationAddress';
import {
    RegistrationAddressFromJSON,
    RegistrationAddressFromJSONTyped,
    RegistrationAddressToJSON,
    RegistrationAddressToJSONTyped,
} from './RegistrationAddress';

/**
 * 
 * @export
 * @interface RegistrationClinic
 */
export interface RegistrationClinic {
    /**
     * 
     * @type {string}
     * @memberof RegistrationClinic
     */
    clinicName: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationClinic
     */
    centreName?: string;
    /**
     * 
     * @type {RegistrationAddress}
     * @memberof RegistrationClinic
     */
    address: RegistrationAddress;
}

/**
 * Check if a given object implements the RegistrationClinic interface.
 */
export function instanceOfRegistrationClinic(value: object): value is RegistrationClinic {
    if (!('clinicName' in value) || value['clinicName'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    return true;
}

export function RegistrationClinicFromJSON(json: any): RegistrationClinic {
    return RegistrationClinicFromJSONTyped(json, false);
}

export function RegistrationClinicFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationClinic {
    if (json == null) {
        return json;
    }
    return {
        
        'clinicName': json['clinicName'],
        'centreName': json['centreName'] == null ? undefined : json['centreName'],
        'address': RegistrationAddressFromJSON(json['address']),
    };
}

export function RegistrationClinicToJSON(json: any): RegistrationClinic {
    return RegistrationClinicToJSONTyped(json, false);
}

export function RegistrationClinicToJSONTyped(value?: RegistrationClinic | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinicName': value['clinicName'],
        'centreName': value['centreName'],
        'address': RegistrationAddressToJSON(value['address']),
    };
}

