import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {useAdminService} from '@/actions';

interface FeatureSuggestionPopupProps {
    showModal: boolean;
    handleClose: () => void;
}

const FeatureSuggestionPopup: React.FC<FeatureSuggestionPopupProps> = ({
    showModal,
    handleClose,
}) => {
    const adminService = useAdminService();
    const initData = {suggestion: ''};
    const [formData, setFormData] = useState<{suggestion: string}>(initData);
    const {suggestion} = formData;

    const handleRequestNewFeature = async () => {
        const suggestionSuccess =
            await adminService.requestNewFeature(suggestion);
        if (!suggestionSuccess) return;
        setFormData(initData);
        handleClose();
    };

    const onSubmit = () => handleRequestNewFeature();

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv">
                    Suggest a new feature
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="medr">
                <Form
                    className="text-left w100"
                    style={{marginLeft: 'auto', marginRight: 'auto'}}
                >
                    <Form.Group controlId="suggestion" className="mb-3">
                        <Form.Label className="my-0v">
                            Please describe the feature or information you would
                            like to see in the app.
                        </Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            placeholder=""
                            rows={5}
                            value={suggestion}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    suggestion: e.target.value,
                                })
                            }
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onSubmit}>
                    Send suggestion
                </Button>
                <Button variant="danger" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FeatureSuggestionPopup;
