import React, {useState, useCallback} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {EmailFormField} from '../app/parts/FormFields';
import {useClinicianService} from '@/actions';

interface InviteUserFormProps {
    showModal: boolean;
    handleClose: () => void;
}

interface FormData {
    name: string;
    email: string;
}

const InviteUserForm: React.FC<InviteUserFormProps> = ({
    showModal,
    handleClose,
}) => {
    const clinicianService = useClinicianService();

    const initData: FormData = {name: '', email: ''};
    const [formData, setFormData] = useState<FormData>(initData);
    const {name, email} = formData;

    // Handle form input changes
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
    }, []);

    // Handle form submission
    const handleInviteClinician = useCallback(
        async (formData: FormData) => {
            const inviteSuccess =
                await clinicianService.inviteClinicianToMedr(formData);
            if (inviteSuccess) {
                setFormData(initData); // Reset form data
                handleClose(); // Close the modal
            }
        },
        [clinicianService, handleClose]
    );

    const onSubmit = useCallback(() => {
        handleInviteClinician(formData);
    }, [formData, handleInviteClinician]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv">
                    Invite a clinician to MedR
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="medr">
                <Form
                    className="text-left w80"
                    style={{marginLeft: 'auto', marginRight: 'auto'}}
                >
                    <Form.Group controlId="invitedUserName" className="mb-3">
                        <Form.Label className="my-0v">Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter name"
                            name="name"
                            value={name}
                            onChange={onChange}
                        />
                    </Form.Group>

                    <div className="mb-3">
                        <EmailFormField
                            email={email}
                            emailKey="email"
                            emailLabel="Email"
                            emailPlaceholder="Enter email"
                            changeValues={(e) =>
                                setFormData({...formData, ...e})
                            }
                        />
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onSubmit}>
                    Invite
                </Button>
                <Button variant="danger" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InviteUserForm;
