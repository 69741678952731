/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface LoginUserResponse
 */
export interface LoginUserResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginUserResponse
     */
    version: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof LoginUserResponse
     */
    user: UserProfile;
    /**
     * 
     * @type {string}
     * @memberof LoginUserResponse
     */
    token: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginUserResponse
     */
    isPatient: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginUserResponse
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginUserResponse
     */
    isAuthenticated: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginUserResponse
     */
    userGroup: string;
}

/**
 * Check if a given object implements the LoginUserResponse interface.
 */
export function instanceOfLoginUserResponse(value: object): value is LoginUserResponse {
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('isPatient' in value) || value['isPatient'] === undefined) return false;
    if (!('isAdmin' in value) || value['isAdmin'] === undefined) return false;
    if (!('isAuthenticated' in value) || value['isAuthenticated'] === undefined) return false;
    if (!('userGroup' in value) || value['userGroup'] === undefined) return false;
    return true;
}

export function LoginUserResponseFromJSON(json: any): LoginUserResponse {
    return LoginUserResponseFromJSONTyped(json, false);
}

export function LoginUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'version': json['version'],
        'user': UserProfileFromJSON(json['user']),
        'token': json['token'],
        'isPatient': json['isPatient'],
        'isAdmin': json['isAdmin'],
        'isAuthenticated': json['isAuthenticated'],
        'userGroup': json['userGroup'],
    };
}

export function LoginUserResponseToJSON(json: any): LoginUserResponse {
    return LoginUserResponseToJSONTyped(json, false);
}

export function LoginUserResponseToJSONTyped(value?: LoginUserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'version': value['version'],
        'user': UserProfileToJSON(value['user']),
        'token': value['token'],
        'isPatient': value['isPatient'],
        'isAdmin': value['isAdmin'],
        'isAuthenticated': value['isAuthenticated'],
        'userGroup': value['userGroup'],
    };
}

