import {Reducer} from 'react';
import * as CONSTS from '@constants/searchConstants';
import {Patient, ClinicianRoleProfile} from '@/generated-client';
import * as ITF from '@reducers/searchReducer/interfaces';
import {SearchState} from './interfaces';

const initialState: SearchState = {
    loading: false,
    errorMessage: '',
    referrerCrp: {} as ClinicianRoleProfile,
    targetCrp: {} as ClinicianRoleProfile,
    patient: {} as Patient,
    jobRole: {id: 0},
    postCode: '',
    lat: CONSTS.DEFAULT_LAT,
    lng: CONSTS.DEFAULT_LNG,
    mapsZoom: 10,
};

// INCOMING REQUESTS
function isSetGpsRequestAction(
    action: ITF.SearchActionTypes
): action is ITF.SearchActionRequests {
    return action.type == CONSTS.SET_GPS_ACTION.REQUEST;
}

function handleSetGpsRequestAction(
    state: SearchState,
    action: ITF.SetGpsRequestAction
): SearchState {
    return {
        ...state,
        postCode: action.payload.postCode,
        lat: action.payload.lat,
        lng: action.payload.lng,
    };
}

function isRequestAction(
    action: ITF.SearchActionTypes
): action is ITF.SearchActionRequests {
    return [
        CONSTS.SET_JOB_ROLE_NULL_ACTION.REQUEST,
        CONSTS.SET_PATIENT_ACTION.REQUEST,
        CONSTS.SET_PATIENT_NULL_ACTION.REQUEST,
        CONSTS.SET_CRP_ACTION.REQUEST,
        CONSTS.SET_CRP_NULL_ACTION.REQUEST,
        CONSTS.SET_CLINICIAN_ACTION.REQUEST,
        CONSTS.SET_CLINICIAN_NULL_ACTION.REQUEST,
        CONSTS.SET_POSTCODE_ACTION.REQUEST,
    ].includes(action.type);
}

function handleRequestAction(state: SearchState): SearchState {
    return {
        ...state,
        loading: true,
    };
}

// INCOMING FAILURES
function isFailureAction(
    action: ITF.SearchActionTypes
): action is ITF.SearchActionFailures {
    return [
        CONSTS.SET_JOB_ROLE_NULL_ACTION.FAIL,
        CONSTS.SET_PATIENT_ACTION.FAIL,
        CONSTS.SET_PATIENT_NULL_ACTION.FAIL,
        CONSTS.SET_CRP_ACTION.FAIL,
        CONSTS.SET_CRP_NULL_ACTION.FAIL,
        CONSTS.SET_POSTCODE_ACTION.FAIL,
    ].includes(action.type);
}

function handleFailureAction(state: SearchState): SearchState {
    return {
        ...state,
        loading: false,
    };
}

function isSetPostcodeFailAction(
    action: ITF.SearchActionTypes
): action is ITF.SetPostcodeFailureAction {
    return action.type === CONSTS.SET_POSTCODE_ACTION.FAIL;
}

function handleSetPostcodeFailAction(
    state: SearchState,
    action: ITF.SetPostcodeFailureAction
): SearchState {
    return {
        ...state,
        loading: false,
        errorMessage: action.payload,
    };
}

// INCOMING SUCCESS ACTIONS

function isSetJobRoleNullSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetJobRoleNullSuccessAction {
    return action.type === CONSTS.SET_JOB_ROLE_NULL_ACTION.SUCCESS;
}

function handleSetJobRoleNullSuccessAction(state: SearchState): SearchState {
    return {
        ...state,
        loading: false,
        jobRole: {id: 0},
    };
}

function isSetPatientSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetPatientSuccessAction {
    return action.type === CONSTS.SET_PATIENT_ACTION.SUCCESS;
}

function handleSetPatientSuccessAction(
    state: SearchState,
    action: ITF.SetPatientSuccessAction
): SearchState {
    return {
        ...state,
        loading: false,
        patient: action.payload.currentPatient,
        lat: action.payload.currentPatient.address.latitude,
        lng: action.payload.currentPatient.address.longitude,
    };
}

function isSetPatientNullSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetPatientNullSuccessAction {
    return action.type === CONSTS.SET_PATIENT_NULL_ACTION.SUCCESS;
}

function handleSetPatientNullSuccessAction(state: SearchState): SearchState {
    return {
        ...state,
        loading: false,
        patient: {} as Patient,
        lat: CONSTS.DEFAULT_LAT,
        lng: CONSTS.DEFAULT_LNG,
    };
}

function isSetCrpSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetCrpSuccessAction {
    return action.type === CONSTS.SET_CRP_ACTION.SUCCESS;
}

function handleSetCrpSuccessAction(
    state: SearchState,
    action: ITF.SetCrpSuccessAction
): SearchState {
    return {
        ...state,
        loading: false,
        referrerCrp: action.payload.crp,
    };
}

function isSetCrpNullSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetCrpNullSuccessAction {
    return action.type === CONSTS.SET_CRP_NULL_ACTION.SUCCESS;
}

function handleSetCrpNullSuccessAction(state: SearchState): SearchState {
    return {
        ...state,
        loading: false,
        referrerCrp: {} as ClinicianRoleProfile,
    };
}

function isSetClinicianSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetClinicianSuccessAction {
    return action.type === CONSTS.SET_CLINICIAN_ACTION.SUCCESS;
}

function handleSetClinicianSuccessAction(
    state: SearchState,
    action: ITF.SetClinicianSuccessAction
): SearchState {
    return {
        ...state,
        loading: false,
        targetCrp: action.payload.crp,
    };
}

function isSetClinicianNullSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetClinicianNullSuccessAction {
    return action.type === CONSTS.SET_CLINICIAN_NULL_ACTION.SUCCESS;
}

function handleSetClinicianNullSuccessAction(state: SearchState): SearchState {
    return {
        ...state,
        loading: false,
        targetCrp: {} as ClinicianRoleProfile,
    };
}

function isSetPostcodeSuccessAction(
    action: ITF.SearchActionTypes
): action is ITF.SetPostcodeSuccessAction {
    return action.type === CONSTS.SET_POSTCODE_ACTION.SUCCESS;
}

function handleSetPostcodeSuccessAction(
    state: SearchState,
    action: ITF.SetPostcodeSuccessAction
): SearchState {
    return {
        ...state,
        loading: false,
        errorMessage: '',
        postCode: action.payload.postcode,
        lat: parseFloat(action.payload.lat),
        lng: parseFloat(action.payload.lng),
    };
}

export const searchReducer: Reducer<SearchState, ITF.SearchActionTypes> = (
    state = initialState,
    action
) => {
    // INCOMING REQUEST ACTIONS
    if (isRequestAction(action)) {
        return handleRequestAction(state);
    }
    if (isSetGpsRequestAction(action)) {
        return handleSetGpsRequestAction(state, action);
    }
    // INCOMING SUCCESS ACTIONS
    if (isSetJobRoleNullSuccessAction(action)) {
        return handleSetJobRoleNullSuccessAction(state);
    }
    if (isSetPatientSuccessAction(action)) {
        return handleSetPatientSuccessAction(state, action);
    }
    if (isSetPatientNullSuccessAction(action)) {
        return handleSetPatientNullSuccessAction(state);
    }
    if (isSetCrpSuccessAction(action)) {
        return handleSetCrpSuccessAction(state, action);
    }
    if (isSetCrpNullSuccessAction(action)) {
        return handleSetCrpNullSuccessAction(state);
    }
    if (isSetClinicianSuccessAction(action)) {
        return handleSetClinicianSuccessAction(state, action);
    }
    if (isSetClinicianNullSuccessAction(action)) {
        return handleSetClinicianNullSuccessAction(state);
    }
    if (isSetPostcodeSuccessAction(action)) {
        return handleSetPostcodeSuccessAction(state, action);
    }

    // INCOMING FAIL ACTIONS
    if (isFailureAction(action)) {
        return handleFailureAction(state);
    }
    if (isSetPostcodeFailAction(action)) {
        return handleSetPostcodeFailAction(state, action);
    }
    return state;
};
