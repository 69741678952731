import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {DobFormField} from '../../app/parts/FormFields';
import JobRoleSearchBar from '../../clinicians/JobRoleSearchBar';
import ClinicsDisplayBox from '../../clinicians/ClinicsDisplayBox';
import ReferralContactsDisplay from '../ReferralContacts/ReferralContactsDisplay';
import ClinicianDescriptionField from '@components/user/UpdateUserDetails/ClinicianDescriptionField';
import {useRegistrationContext} from '@contexts/RegistrationContext';
import {JobRole} from '@/generated-client';

const RegisterClinicianDetailsForm: React.FC = () => {
    const {
        registrationData,
        updateRegistrationData,
        addJobRole,
        removeJobRole,
        addClinic,
        editClinic,
        removeClinic,
        updateAppointmentContacts,
        setNextStep,
    } = useRegistrationContext();

    const [disableNext, setDisableNext] = useState<boolean>(true);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const {registrationBody} = registrationData;

    useEffect(() => {
        if (!registrationData.clinics) {
            return;
        }
        const roleSelected = registrationData.jobRoles.length > 0;
        const clinicAdded = registrationData.clinics.length > 0;
        const registrationAdded = registrationData.registrationNumber !== '';
        const dobAdded = registrationData.dob !== '';
        const descriptionAdded = registrationData.clinicianDescription !== '';
        const ready =
            clinicAdded &&
            roleSelected &&
            registrationAdded &&
            dobAdded &&
            descriptionAdded;
        setDisableNext(!ready);
    }, [registrationData]);

    const SetJobRoles: React.FC = () => {
        if (registrationData.jobRoles.length === 0) return null;

        const roleList = registrationData.jobRoles.map((role: JobRole) => (
            <div
                key={`jr${role.id}`}
                className="mt-1 medr-rounded-white"
                style={{paddingTop: '0px', paddingBottom: '0px'}}
            >
                <Row className="w100">
                    <Col className="job-role-bar-left">
                        <p
                            className="medr-text-inv m-2 w100"
                            style={{color: '#247986'}}
                        >
                            {role.jobTitle}
                        </p>
                    </Col>
                    <Col className="mt-1 job-role-bar-right">
                        <Button
                            className="sel-button p-1"
                            variant="danger"
                            onClick={() => removeJobRole(role)}
                            style={{fontSize: '14px', maxHeight: '40px'}}
                        >
                            Remove
                        </Button>
                    </Col>
                </Row>
            </div>
        ));
        return roleList;
    };

    return (
        <>
            <div className="register-clinician-page">
                <Form>
                    <Form.Group controlId="selectedRole" className="mt-2">
                        <Form.Label className="my-0">
                            {registrationData.jobRoles.length === 0
                                ? 'Select a clinician role'
                                : 'Select additional clinician roles'}
                        </Form.Label>
                    </Form.Group>
                    <JobRoleSearchBar
                        changeValues={addJobRole}
                        clearAfterSelect
                    />

                    {registrationData.jobRoles.length > 0 && (
                        <Form.Group controlId="selectedRoles" className="mt-2">
                            <Form.Label className="my-0">
                                Selected clinician roles
                            </Form.Label>
                        </Form.Group>
                    )}
                    <SetJobRoles />

                    <div className="row">
                        <div
                            className="col-md-7"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Form.Group
                                controlId="registrationNumber"
                                className="mt-2"
                            >
                                <Form.Label className="my-0">
                                    {registrationBody} Registration number
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="name"
                                    placeholder="Enter registration no"
                                    defaultValue={
                                        registrationData.registrationNumber
                                    }
                                    onChange={(e) =>
                                        updateRegistrationData({
                                            registrationNumber: e.target.value,
                                        })
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-5 mt-2">
                            <DobFormField
                                dob={registrationData.dob}
                                dobKey="dob"
                                changeValues={updateRegistrationData}
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <ClinicianDescriptionField
                            clinicianDescription={
                                registrationData.clinicianDescription
                            }
                            updateFormData={updateRegistrationData}
                        />
                    </div>
                    <ReferralContactsDisplay
                        contacts={registrationData.appointmentsContacts}
                        updateAppointmentContacts={updateAppointmentContacts}
                        requiredEmails={[]}
                    />
                </Form>

                <Row className="mt-3">
                    <Col>
                        <ClinicsDisplayBox
                            clinics={registrationData.clinics}
                            editable
                            addClinic={addClinic}
                            removeClinic={removeClinic}
                            editClinic={editClinic}
                        />
                    </Col>
                </Row>
            </div>
            <Row>
                <Col className="text-center">
                    <Button
                        className="sel-button w80 my-4"
                        variant="primary"
                        onClick={setNextStep}
                        disabled={disableNext}
                    >
                        Next
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default RegisterClinicianDetailsForm;
