import {createContext, useContext} from 'react';
import {
    Patient,
    JobRole,
    ClinicianRoleProfile,
    InsuranceAgreement,
} from '@/generated-client';
import {
    ReferralJobInput,
    ReferralEntryData,
} from '@/hooks/referrals/ReferralFormHandler';
import {Coordinates} from '@/hooks/referrals/ReferralFormHandler';
// import { MapViewCoords } from '@/hooks/clinicians/interfaces';

interface ReferralEntryContextType {
    referralData: ReferralEntryData;
    activeStep: number;
    patientId: number;
    patientSelected: boolean;
    patient: Patient;
    sendPxId: (id: number) => void;
    clearPatient: () => void;
    addNewPatientSuccess: (results: {success: boolean}) => void;
    patientHistory: string;
    mainDescription: string;
    updateRxInfo: (value: Partial<ReferralEntryData>) => void;
    handleNext: () => void;
    handlePrevious: () => void;
    jobRole: JobRole;
    targetClinician: ClinicianRoleProfile;
    specifiedClinician: boolean;
    insuranceProvider: InsuranceAgreement;
    setJobRole: (e: ReferralJobInput) => void;
    setTargetClinicianOpen: () => void;
    setTargetClinician: (targetCrpId: number) => void;
    refreshClinicians: () => void;
    changeGPS: (coordinates: Coordinates) => void;
    validateClinicianProfiles: () => boolean;
    validateReferralData: () => boolean;
    onSubmit: () => void;
    referralMade: boolean;
    exitReferral: () => void;
}

export const ReferralEntryContext = createContext<
    ReferralEntryContextType | undefined
>(undefined);

export function useReferralEntryContext() {
    const context = useContext(ReferralEntryContext);

    if (!context) {
        throw new Error(
            'useReferralEntryContext must be used within a ReferralEntryProvider'
        );
    }

    return context;
}
