/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FavouriteClinician } from './FavouriteClinician';
import {
    FavouriteClinicianFromJSON,
    FavouriteClinicianFromJSONTyped,
    FavouriteClinicianToJSON,
    FavouriteClinicianToJSONTyped,
} from './FavouriteClinician';

/**
 * 
 * @export
 * @interface FavouriteClinicianGetResponse
 */
export interface FavouriteClinicianGetResponse {
    /**
     * 
     * @type {Array<FavouriteClinician>}
     * @memberof FavouriteClinicianGetResponse
     */
    favouriteClinicians: Array<FavouriteClinician>;
}

/**
 * Check if a given object implements the FavouriteClinicianGetResponse interface.
 */
export function instanceOfFavouriteClinicianGetResponse(value: object): value is FavouriteClinicianGetResponse {
    if (!('favouriteClinicians' in value) || value['favouriteClinicians'] === undefined) return false;
    return true;
}

export function FavouriteClinicianGetResponseFromJSON(json: any): FavouriteClinicianGetResponse {
    return FavouriteClinicianGetResponseFromJSONTyped(json, false);
}

export function FavouriteClinicianGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavouriteClinicianGetResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'favouriteClinicians': ((json['favouriteClinicians'] as Array<any>).map(FavouriteClinicianFromJSON)),
    };
}

export function FavouriteClinicianGetResponseToJSON(json: any): FavouriteClinicianGetResponse {
    return FavouriteClinicianGetResponseToJSONTyped(json, false);
}

export function FavouriteClinicianGetResponseToJSONTyped(value?: FavouriteClinicianGetResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'favouriteClinicians': ((value['favouriteClinicians'] as Array<any>).map(FavouriteClinicianToJSON)),
    };
}

