/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DelegateUserLink } from './DelegateUserLink';
import {
    DelegateUserLinkFromJSON,
    DelegateUserLinkFromJSONTyped,
    DelegateUserLinkToJSON,
    DelegateUserLinkToJSONTyped,
} from './DelegateUserLink';

/**
 * 
 * @export
 * @interface RestoreDelegateRequest
 */
export interface RestoreDelegateRequest {
    /**
     * 
     * @type {DelegateUserLink}
     * @memberof RestoreDelegateRequest
     */
    delegate: DelegateUserLink;
}

/**
 * Check if a given object implements the RestoreDelegateRequest interface.
 */
export function instanceOfRestoreDelegateRequest(value: object): value is RestoreDelegateRequest {
    if (!('delegate' in value) || value['delegate'] === undefined) return false;
    return true;
}

export function RestoreDelegateRequestFromJSON(json: any): RestoreDelegateRequest {
    return RestoreDelegateRequestFromJSONTyped(json, false);
}

export function RestoreDelegateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestoreDelegateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'delegate': DelegateUserLinkFromJSON(json['delegate']),
    };
}

export function RestoreDelegateRequestToJSON(json: any): RestoreDelegateRequest {
    return RestoreDelegateRequestToJSONTyped(json, false);
}

export function RestoreDelegateRequestToJSONTyped(value?: RestoreDelegateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'delegate': DelegateUserLinkToJSON(value['delegate']),
    };
}

