import React, {useState} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';
import FeatureSuggestionPopup from './FeatureSuggestionPoppup';

interface AppUpdateNotification {
    title: string;
    content: string;
}

const updates: AppUpdateNotification[] = [
    {
        title: 'Improved map',
        content:
            'Effortlessly match patients with specialists using our enhanced clinician finder.',
    },
    {
        title: 'Direct referrals',
        content:
            'Streamlining the referral process when you have already identified a specialist.',
    },
];

const AppUpdatesPanel: React.FC = () => {
    const [displaySuggestionsForm, setDisplaySuggestionsForm] =
        useState<boolean>(false);

    return (
        <>
            {displaySuggestionsForm && (
                <FeatureSuggestionPopup
                    showModal={displaySuggestionsForm}
                    handleClose={() => setDisplaySuggestionsForm(false)}
                />
            )}
            <div
                className="medr-layout text-center"
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Card
                    className="my-3 p-3 rounded d-flex flex-fill medr-card"
                    id="updatesCard"
                    style={{height: 'calc(100% - 46px)'}}
                >
                    <Card.Title>Recent Updates</Card.Title>
                    <Card.Body className="d-flex flex-column text-left">
                        {updates.map((update, index) => (
                            <Row key={index}>
                                <Col>
                                    <p className="updates-tab-title">
                                        {update.title}
                                    </p>
                                    <p className="updates-tab-content">
                                        {update.content}
                                    </p>
                                </Col>
                            </Row>
                        ))}
                        <Row className="mb-2">
                            <Button
                                className="insurance-check-btn"
                                variant="info"
                                onClick={() => setDisplaySuggestionsForm(true)}
                            >
                                Request a feature
                            </Button>
                        </Row>
                        <div className="mt-auto"> </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default AppUpdatesPanel;
