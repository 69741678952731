import React from 'react';
import {useParams} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';
import UserProfileCard from '@/components/user/UserProfileCard';

const ClinicianScreen: React.FC = () => {
    const {userProfileId} = useParams<{userProfileId: string}>();
    const userId = parseInt(userProfileId, 10);
    return (
        <div>
            <Row className="text-center py-1">
                <Col>
                    <h1>Clinician Information</h1>
                </Col>
            </Row>
            <div className="d-flex justify-content-center">
                <Row className="text-center py-1" key={`crp${userProfileId}`}>
                    <Col>
                        <UserProfileCard
                            userProfileId={userId}
                            isUser={false}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ClinicianScreen;
