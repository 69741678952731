import {StylesConfig} from 'react-select';

export interface ViewOption {
    label: string;
    value: string;
}

export const customStyles: StylesConfig<ViewOption, false> = {
    singleValue: (provided) => ({
        ...provided,
        color: '#247986',
    }),
};
