import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export type PhoneFieldChangeValues = {[key: string]: string} & {
    isValid: boolean;
};

interface PhoneFormFieldProps {
    currentPhoneNo: string;
    nameKey: string;
    nameLabel?: string;
    changeValues: (values: PhoneFieldChangeValues) => void;
}

const PhoneFormField: React.FC<PhoneFormFieldProps> = ({
    currentPhoneNo,
    nameKey,
    nameLabel,
    changeValues,
}) => {
    const [validPhone, setValidPhone] = useState<boolean>(true);

    const isValidPhoneNumber = (phoneNumber?: string) => {
        const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/; // Loosened regex to avoid immediate errors

        const isValid = !!phoneNumber && phoneRegex.test(phoneNumber);
        setValidPhone(isValid);
        changeValues({
            [nameKey]: phoneNumber,
            isValid: validPhone,
        } as {[key: string]: string} & {isValid: boolean});
    };

    return (
        <Form.Group controlId="phone_number" className="mb-3">
            {nameLabel && (
                <Form.Label className="my-0">{nameLabel} </Form.Label>
            )}
            <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GB"
                value={currentPhoneNo}
                onChange={isValidPhoneNumber}
                autoComplete="off"
            />
            {!validPhone && (
                <Form.Text className="text-danger text-left">
                    Please enter a valid phone number
                </Form.Text>
            )}
        </Form.Group>
    );
};

export default PhoneFormField;
