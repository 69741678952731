/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TopReferrer } from './TopReferrer';
import {
    TopReferrerFromJSON,
    TopReferrerFromJSONTyped,
    TopReferrerToJSON,
    TopReferrerToJSONTyped,
} from './TopReferrer';
import type { FavouriteClinician } from './FavouriteClinician';
import {
    FavouriteClinicianFromJSON,
    FavouriteClinicianFromJSONTyped,
    FavouriteClinicianToJSON,
    FavouriteClinicianToJSONTyped,
} from './FavouriteClinician';

/**
 * 
 * @export
 * @interface ClinicianAnalyticsResponse
 */
export interface ClinicianAnalyticsResponse {
    /**
     * 
     * @type {number}
     * @memberof ClinicianAnalyticsResponse
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicianAnalyticsResponse
     */
    noPatientsMade: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicianAnalyticsResponse
     */
    noPatientsReceived: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicianAnalyticsResponse
     */
    noReferralsMade: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicianAnalyticsResponse
     */
    noReferralsReceived: number;
    /**
     * 
     * @type {Array<TopReferrer>}
     * @memberof ClinicianAnalyticsResponse
     */
    topReferredTo: Array<TopReferrer>;
    /**
     * 
     * @type {Array<TopReferrer>}
     * @memberof ClinicianAnalyticsResponse
     */
    topReferralsFrom: Array<TopReferrer>;
    /**
     * 
     * @type {Array<FavouriteClinician>}
     * @memberof ClinicianAnalyticsResponse
     */
    favouriteClinicians: Array<FavouriteClinician>;
}

/**
 * Check if a given object implements the ClinicianAnalyticsResponse interface.
 */
export function instanceOfClinicianAnalyticsResponse(value: object): value is ClinicianAnalyticsResponse {
    if (!('number' in value) || value['number'] === undefined) return false;
    if (!('noPatientsMade' in value) || value['noPatientsMade'] === undefined) return false;
    if (!('noPatientsReceived' in value) || value['noPatientsReceived'] === undefined) return false;
    if (!('noReferralsMade' in value) || value['noReferralsMade'] === undefined) return false;
    if (!('noReferralsReceived' in value) || value['noReferralsReceived'] === undefined) return false;
    if (!('topReferredTo' in value) || value['topReferredTo'] === undefined) return false;
    if (!('topReferralsFrom' in value) || value['topReferralsFrom'] === undefined) return false;
    if (!('favouriteClinicians' in value) || value['favouriteClinicians'] === undefined) return false;
    return true;
}

export function ClinicianAnalyticsResponseFromJSON(json: any): ClinicianAnalyticsResponse {
    return ClinicianAnalyticsResponseFromJSONTyped(json, false);
}

export function ClinicianAnalyticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianAnalyticsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'number': json['number'],
        'noPatientsMade': json['noPatientsMade'],
        'noPatientsReceived': json['noPatientsReceived'],
        'noReferralsMade': json['noReferralsMade'],
        'noReferralsReceived': json['noReferralsReceived'],
        'topReferredTo': ((json['topReferredTo'] as Array<any>).map(TopReferrerFromJSON)),
        'topReferralsFrom': ((json['topReferralsFrom'] as Array<any>).map(TopReferrerFromJSON)),
        'favouriteClinicians': ((json['favouriteClinicians'] as Array<any>).map(FavouriteClinicianFromJSON)),
    };
}

export function ClinicianAnalyticsResponseToJSON(json: any): ClinicianAnalyticsResponse {
    return ClinicianAnalyticsResponseToJSONTyped(json, false);
}

export function ClinicianAnalyticsResponseToJSONTyped(value?: ClinicianAnalyticsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'number': value['number'],
        'noPatientsMade': value['noPatientsMade'],
        'noPatientsReceived': value['noPatientsReceived'],
        'noReferralsMade': value['noReferralsMade'],
        'noReferralsReceived': value['noReferralsReceived'],
        'topReferredTo': ((value['topReferredTo'] as Array<any>).map(TopReferrerToJSON)),
        'topReferralsFrom': ((value['topReferralsFrom'] as Array<any>).map(TopReferrerToJSON)),
        'favouriteClinicians': ((value['favouriteClinicians'] as Array<any>).map(FavouriteClinicianToJSON)),
    };
}

