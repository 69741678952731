import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import Map from './Map';
import ClinicianPanel from './ClinicianPanel';
import PostcodeSearch from '@components/clinicians/PostcodeSearch';
import {useMapViewContext} from './MapViewContext';

const MapView: React.FC = () => {
    const {location, updateShowOnlyFavourites, receivePostcodeSearch} =
        useMapViewContext();

    // const lat = location.searchLatitude;
    // const lng = location.searchLongitude;
    const lat = location.coordinates.lat;
    const lng = location.coordinates.lng;

    return (
        <div className="container-fluid">
            <div className="row mt-2 mb-5">
                <div
                    className="col-md-8 mb-5 mb-md-0"
                    style={{maxHeight: '60vh', paddingRight: '0px'}}
                >
                    <div className="row mb-3">
                        <div className="col-md-12 col-lg-10 clinicians-search-postcode">
                            <PostcodeSearch
                                changeValues={receivePostcodeSearch}
                                lat={lat}
                                lng={lng}
                            />
                        </div>
                    </div>

                    <Map />
                </div>
                <div className="col-md-4 mt-5 mt-md-0">
                    <div className="row">
                        <div className="col clinicians-search-postcode-tabs">
                            <Tabs
                                defaultActiveKey="allClinicians"
                                id="custom-tabs"
                                className="custom-tabs mb-3 clin-panel-div"
                                onSelect={updateShowOnlyFavourites}
                            >
                                <Tab
                                    eventKey="allClinicians"
                                    title="All clinicians"
                                    className="custom-tabs"
                                />
                                <Tab
                                    eventKey="favouriteClinicians"
                                    title="Favourites"
                                    className="custom-tabs "
                                />
                            </Tabs>
                        </div>
                    </div>
                    <div
                        className="row"
                        style={{maxHeight: '60vh', overflowX: 'auto'}}
                    >
                        <div className="col">
                            <ClinicianPanel />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapView;
