/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LogoutUserResponse,
} from '../models/index';
import {
    LogoutUserResponseFromJSON,
    LogoutUserResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class LogoutApi extends runtime.BaseAPI {

    /**
     * Logout for user.
     */
    async logoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LogoutUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/logout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LogoutUserResponseFromJSON(jsonValue));
    }

    /**
     * Logout for user.
     */
    async logoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LogoutUserResponse> {
        const response = await this.logoutCreateRaw(initOverrides);
        return await response.value();
    }

}
