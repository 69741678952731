/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';

/**
 * 
 * @export
 * @interface JobRoleByIdResponse
 */
export interface JobRoleByIdResponse {
    /**
     * 
     * @type {JobRole}
     * @memberof JobRoleByIdResponse
     */
    jobRole: JobRole;
}

/**
 * Check if a given object implements the JobRoleByIdResponse interface.
 */
export function instanceOfJobRoleByIdResponse(value: object): value is JobRoleByIdResponse {
    if (!('jobRole' in value) || value['jobRole'] === undefined) return false;
    return true;
}

export function JobRoleByIdResponseFromJSON(json: any): JobRoleByIdResponse {
    return JobRoleByIdResponseFromJSONTyped(json, false);
}

export function JobRoleByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobRoleByIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'jobRole': JobRoleFromJSON(json['jobRole']),
    };
}

export function JobRoleByIdResponseToJSON(json: any): JobRoleByIdResponse {
    return JobRoleByIdResponseToJSONTyped(json, false);
}

export function JobRoleByIdResponseToJSONTyped(value?: JobRoleByIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'jobRole': JobRoleToJSON(value['jobRole']),
    };
}

