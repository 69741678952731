import Cookies from 'js-cookie';
import {MedrPages} from '@/constants/variables';

export class DownloadService {
    authToken: string;
    constructor() {
        this.authToken = Cookies.get('authToken');
    }

    getDateString() {
        const now = new Date();
        return `D${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_T${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
    }

    downloadExcel(url: string, fileName: string) {
        fetch(url, {
            method: 'GET',
            headers: {Authorization: `Token ${this.authToken}`},
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Download error:', error);
            });
    }

    downloadAdminUsersList() {
        const fileName = `medr_admin_users_list(${this.getDateString()}).xlsx`;
        const url = `http://localhost:8000/api/v1${MedrPages.ADMIN_USERS_DOWNOAD}/`;
        this.downloadExcel(url, fileName);
    }

    downloadAdminCrpsList() {
        const fileName = `medr_admin_crps_list(${this.getDateString()}).xlsx`;
        const url = `http://localhost:8000/api/v1${MedrPages.ADMIN_CRP_DOWNLOAD}/`;
        this.downloadExcel(url, fileName);
    }

    downloadAdminPatientsList() {
        const fileName = `medr_admin_patients_list(${this.getDateString()}).xlsx`;
        const url = `http://localhost:8000/api/v1${MedrPages.ADMIN_PATIENTS_DOWNLOAD}/`;
        this.downloadExcel(url, fileName);
    }

    downloadAdminReferralsList() {
        const fileName = `medr_admin_referrals_list(${this.getDateString()}).xlsx`;
        const url = `http://localhost:8000/api/v1${MedrPages.ADMIN_REFERRALS_DOWNLOAD}/`;
        this.downloadExcel(url, fileName);
    }

    downloadAdminInsuranceAgreementsList() {
        const fileName = `medr_admin_insurance_agreements_list(${this.getDateString()}).xlsx`;
        const url = `http://localhost:8000/api/v1${MedrPages.ADMIN_INSURANCE_AGREEMENTS_DOWNLOAD}/`;
        this.downloadExcel(url, fileName);
    }
}
