/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicianRoleProfile } from './ClinicianRoleProfile';
import {
    ClinicianRoleProfileFromJSON,
    ClinicianRoleProfileFromJSONTyped,
    ClinicianRoleProfileToJSON,
    ClinicianRoleProfileToJSONTyped,
} from './ClinicianRoleProfile';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface UpdateReferralContactsResponse
 */
export interface UpdateReferralContactsResponse {
    /**
     * 
     * @type {UserProfile}
     * @memberof UpdateReferralContactsResponse
     */
    userProfile: UserProfile;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof UpdateReferralContactsResponse
     */
    clinicianProfiles: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Message}
     * @memberof UpdateReferralContactsResponse
     */
    message: Message;
}

/**
 * Check if a given object implements the UpdateReferralContactsResponse interface.
 */
export function instanceOfUpdateReferralContactsResponse(value: object): value is UpdateReferralContactsResponse {
    if (!('userProfile' in value) || value['userProfile'] === undefined) return false;
    if (!('clinicianProfiles' in value) || value['clinicianProfiles'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function UpdateReferralContactsResponseFromJSON(json: any): UpdateReferralContactsResponse {
    return UpdateReferralContactsResponseFromJSONTyped(json, false);
}

export function UpdateReferralContactsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateReferralContactsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'userProfile': UserProfileFromJSON(json['userProfile']),
        'clinicianProfiles': ((json['clinicianProfiles'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'message': MessageFromJSON(json['message']),
    };
}

export function UpdateReferralContactsResponseToJSON(json: any): UpdateReferralContactsResponse {
    return UpdateReferralContactsResponseToJSONTyped(json, false);
}

export function UpdateReferralContactsResponseToJSONTyped(value?: UpdateReferralContactsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userProfile': UserProfileToJSON(value['userProfile']),
        'clinicianProfiles': ((value['clinicianProfiles'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'message': MessageToJSON(value['message']),
    };
}

