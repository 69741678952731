/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicianRoleProfile } from './ClinicianRoleProfile';
import {
    ClinicianRoleProfileFromJSON,
    ClinicianRoleProfileFromJSONTyped,
    ClinicianRoleProfileToJSON,
    ClinicianRoleProfileToJSONTyped,
} from './ClinicianRoleProfile';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';
import type { DelegateUserLink } from './DelegateUserLink';
import {
    DelegateUserLinkFromJSON,
    DelegateUserLinkFromJSONTyped,
    DelegateUserLinkToJSON,
    DelegateUserLinkToJSONTyped,
} from './DelegateUserLink';
import type { NotificationsSettings } from './NotificationsSettings';
import {
    NotificationsSettingsFromJSON,
    NotificationsSettingsFromJSONTyped,
    NotificationsSettingsToJSON,
    NotificationsSettingsToJSONTyped,
} from './NotificationsSettings';
import type { ReferralPermissions } from './ReferralPermissions';
import {
    ReferralPermissionsFromJSON,
    ReferralPermissionsFromJSONTyped,
    ReferralPermissionsToJSON,
    ReferralPermissionsToJSONTyped,
} from './ReferralPermissions';

/**
 * 
 * @export
 * @interface ClinicianUserInfoResponse
 */
export interface ClinicianUserInfoResponse {
    /**
     * 
     * @type {UserProfile}
     * @memberof ClinicianUserInfoResponse
     */
    userProfile: UserProfile;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof ClinicianUserInfoResponse
     */
    clinicianProfiles: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Array<DelegateUserLink>}
     * @memberof ClinicianUserInfoResponse
     */
    delegates: Array<DelegateUserLink>;
    /**
     * 
     * @type {Array<DelegateUserLink>}
     * @memberof ClinicianUserInfoResponse
     */
    pendingDelegates: Array<DelegateUserLink>;
    /**
     * 
     * @type {NotificationsSettings}
     * @memberof ClinicianUserInfoResponse
     */
    notificationSettings: NotificationsSettings;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicianUserInfoResponse
     */
    isDelegate: boolean;
    /**
     * 
     * @type {ReferralPermissions}
     * @memberof ClinicianUserInfoResponse
     */
    referralPermissions: ReferralPermissions;
}

/**
 * Check if a given object implements the ClinicianUserInfoResponse interface.
 */
export function instanceOfClinicianUserInfoResponse(value: object): value is ClinicianUserInfoResponse {
    if (!('userProfile' in value) || value['userProfile'] === undefined) return false;
    if (!('clinicianProfiles' in value) || value['clinicianProfiles'] === undefined) return false;
    if (!('delegates' in value) || value['delegates'] === undefined) return false;
    if (!('pendingDelegates' in value) || value['pendingDelegates'] === undefined) return false;
    if (!('notificationSettings' in value) || value['notificationSettings'] === undefined) return false;
    if (!('isDelegate' in value) || value['isDelegate'] === undefined) return false;
    if (!('referralPermissions' in value) || value['referralPermissions'] === undefined) return false;
    return true;
}

export function ClinicianUserInfoResponseFromJSON(json: any): ClinicianUserInfoResponse {
    return ClinicianUserInfoResponseFromJSONTyped(json, false);
}

export function ClinicianUserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianUserInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'userProfile': UserProfileFromJSON(json['userProfile']),
        'clinicianProfiles': ((json['clinicianProfiles'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'delegates': ((json['delegates'] as Array<any>).map(DelegateUserLinkFromJSON)),
        'pendingDelegates': ((json['pendingDelegates'] as Array<any>).map(DelegateUserLinkFromJSON)),
        'notificationSettings': NotificationsSettingsFromJSON(json['notificationSettings']),
        'isDelegate': json['isDelegate'],
        'referralPermissions': ReferralPermissionsFromJSON(json['referralPermissions']),
    };
}

export function ClinicianUserInfoResponseToJSON(json: any): ClinicianUserInfoResponse {
    return ClinicianUserInfoResponseToJSONTyped(json, false);
}

export function ClinicianUserInfoResponseToJSONTyped(value?: ClinicianUserInfoResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userProfile': UserProfileToJSON(value['userProfile']),
        'clinicianProfiles': ((value['clinicianProfiles'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'delegates': ((value['delegates'] as Array<any>).map(DelegateUserLinkToJSON)),
        'pendingDelegates': ((value['pendingDelegates'] as Array<any>).map(DelegateUserLinkToJSON)),
        'notificationSettings': NotificationsSettingsToJSON(value['notificationSettings']),
        'isDelegate': value['isDelegate'],
        'referralPermissions': ReferralPermissionsToJSON(value['referralPermissions']),
    };
}

