import {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useDelegateService} from '@/actions';
import {RootState} from '@/reducers';
import {DelegateUserLink} from '@/generated-client';

interface UseRegisterDelegatesReturn {
    delegates: DelegateUserLink[];
    inactiveDelegates: DelegateUserLink[];
}

const useRegisterDelegates = (): UseRegisterDelegatesReturn => {
    const delegateService = useDelegateService();

    const loadDelegates = useCallback(async (): Promise<void> => {
        try {
            await delegateService.getUserDelegateList();
        } catch (error) {
            console.error('Failed to load delegates:', error);
        }
    }, [delegateService]);

    useEffect(() => {
        loadDelegates();
    }, [loadDelegates]);

    const delegates = useSelector(
        (state: RootState) => state.profile.delegates
    );
    const inactiveDelegates = useSelector(
        (state: RootState) => state.profile.pendingDelegates
    );
    return {delegates, inactiveDelegates};
};

export default useRegisterDelegates;
