export enum Variant {
    SUCCESS = 'success',
    INFO = 'info',
    DANGER = 'danger',
}

export interface NotificationMessageProps {
    show: boolean;
    toggleShow: () => void;
    variant: Variant;
    message: string;
}
