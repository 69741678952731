/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicianRoleProfile } from './ClinicianRoleProfile';
import {
    ClinicianRoleProfileFromJSON,
    ClinicianRoleProfileFromJSONTyped,
    ClinicianRoleProfileToJSON,
    ClinicianRoleProfileToJSONTyped,
} from './ClinicianRoleProfile';

/**
 * 
 * @export
 * @interface ClinicianInfoByIdResponse
 */
export interface ClinicianInfoByIdResponse {
    /**
     * 
     * @type {ClinicianRoleProfile}
     * @memberof ClinicianInfoByIdResponse
     */
    crp: ClinicianRoleProfile;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof ClinicianInfoByIdResponse
     */
    allCrps: Array<ClinicianRoleProfile>;
}

/**
 * Check if a given object implements the ClinicianInfoByIdResponse interface.
 */
export function instanceOfClinicianInfoByIdResponse(value: object): value is ClinicianInfoByIdResponse {
    if (!('crp' in value) || value['crp'] === undefined) return false;
    if (!('allCrps' in value) || value['allCrps'] === undefined) return false;
    return true;
}

export function ClinicianInfoByIdResponseFromJSON(json: any): ClinicianInfoByIdResponse {
    return ClinicianInfoByIdResponseFromJSONTyped(json, false);
}

export function ClinicianInfoByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianInfoByIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'crp': ClinicianRoleProfileFromJSON(json['crp']),
        'allCrps': ((json['allCrps'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
    };
}

export function ClinicianInfoByIdResponseToJSON(json: any): ClinicianInfoByIdResponse {
    return ClinicianInfoByIdResponseToJSONTyped(json, false);
}

export function ClinicianInfoByIdResponseToJSONTyped(value?: ClinicianInfoByIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'crp': ClinicianRoleProfileToJSON(value['crp']),
        'allCrps': ((value['allCrps'] as Array<any>).map(ClinicianRoleProfileToJSON)),
    };
}

