import {useState, ChangeEvent, useEffect} from 'react';
import {
    CheckClinicAddressRequest,
    PatientCreateCreateRequest,
    Patient,
} from '@/generated-client';
import {useAuthService, usePatientService} from '@/actions';
import {reformatDateDash} from '@/actions/general';
import CheckAgeOver18FromDob from '@services/AgeCheck';
import {
    EmailFieldChangeValues,
    PhoneFieldChangeValues,
} from '@components/app/parts/FormFields';
import {
    PatientEntryFormProps,
    PatientFormData,
    PatientFormChecks,
    UsePatientEntryFormReturn,
} from './types';

function createInitPatientDataForm(): PatientFormData {
    return {
        id: 0,
        title: '',
        firstName: '',
        lastName: '',
        patientReference: '',
        sex: '',
        dob: '',
        primaryEmail: '',
        primaryPhoneNumber: '',
        address: {
            firstLine: '',
            city: '',
            postcode: '',
        },
        insurancePolicy: {id: -1},
        insuranceProviderId: -1,
        consent: false,
    };
}

function createInitPatientFormChecks(): PatientFormChecks {
    return {
        title: true,
        firstName: true,
        lastName: true,
        sex: true,
        dob: true,
        email: true,
        phone: true,
        postcode: true,
        insuranceProviderId: true,
        consent: true,
    };
}

const usePatientEntryForm = (
    e: PatientEntryFormProps
): UsePatientEntryFormReturn => {
    const {patientId, isSuccessful} = e;

    const patientService = usePatientService();
    const authService = useAuthService();

    const [patientFormData, setPatientFormData] = useState<PatientFormData>(
        createInitPatientDataForm()
    );
    const [formChecks, setFormChecks] = useState<PatientFormChecks>(
        createInitPatientFormChecks()
    );
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isNewPatient, setIsNewPatient] = useState<boolean>(true);
    const [gpsError, setGpsError] = useState<boolean>(false);
    const [isDOBValid, setIsDOBValid] = useState<boolean>(true);
    const [formValid, setFormValid] = useState<boolean>(false);

    const toggleShowError = () => setShowError((prevState) => !prevState);

    const patientToFormData = (px: Patient): PatientFormData => {
        const insId = px.isSelfPayer ? 0 : px.insurancePolicy.id;
        return {
            id: px.id,
            title: px.title,
            firstName: px.firstName,
            lastName: px.lastName,
            patientReference: px.patientReference,
            sex: px.sex,
            dob: reformatDateDash(px.dob),
            primaryEmail: px.primaryEmail,
            primaryPhoneNumber: px.primaryPhoneNumber,
            address: {
                firstLine: px.address.firstLine,
                city: px.address.city,
                postcode: px.address.postcode,
            },
            insurancePolicy: {id: insId},
            insuranceProviderId: insId,
            consent: false,
        };
    };

    useEffect(() => {
        const initialisePatientForm = async (pxId: number) => {
            setIsNewPatient(!pxId);
            if (!pxId) {
                setPatientFormData(createInitPatientDataForm());
                return;
            }
            const data = await patientService.getPxDataById(pxId);
            const newFormData = patientToFormData(data.currentPatient);
            setPatientFormData(newFormData);
        };
        initialisePatientForm(patientId);
        return () => {};
    }, [patientId]);

    const updateFormFromTarget = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        updatePatientFormData({[name]: value});
    };

    const updatePatientFormData = (data: Partial<PatientFormData>): void => {
        setPatientFormData((prevState) => ({...prevState, ...data}));
    };

    const setDOB = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const {isValid} = CheckAgeOver18FromDob(e.target.value);
        setIsDOBValid(isValid);
        updatePatientFormData({dob: e.target.value});
    };

    const updateEmail = (emailData: EmailFieldChangeValues) => {
        const {primaryEmail} = emailData;
        updatePatientFormData({primaryEmail});
    };

    const updatePhone = (phoneData: PhoneFieldChangeValues) => {
        const {primaryPhoneNumber} = phoneData;
        updatePatientFormData({primaryPhoneNumber});
    };

    const checkAddress = async (addr: CheckClinicAddressRequest) => {
        const addressValid = await authService.registerCheckClinic(addr);
        setGpsError(!addressValid.addressValid);
        return addressValid;
    };

    const updateAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = {[e.target.name]: e.target.value};
        setPatientFormData((prevState) => {
            const addr = {...prevState.address, ...data};
            const newState = {...prevState, address: addr};
            checkAddress(addr);
            return newState;
        });
    };

    const checkFormValid = (): boolean => {
        const checks = {
            title: !!patientFormData.title,
            firstName: !!patientFormData.firstName,
            lastName: !!patientFormData.lastName,
            dob: !!patientFormData.dob,
            sex: !!patientFormData.sex,
            email: !!patientFormData.primaryEmail,
            phone: !!patientFormData.primaryPhoneNumber,
            postcode: !!patientFormData.address.postcode,
            insuranceProviderId: patientFormData.insuranceProviderId > -1,
        };
        setFormChecks((prevState) => ({...prevState, ...checks}));
        const ready = Object.values(checks).every(Boolean);
        setFormValid(ready);
        return ready;
    };

    const handleConsentChange = () => {
        setPatientFormData((prevState) => {
            const newConsent = !prevState.consent;
            if (newConsent) {
                const ready = checkFormValid();
                if (!ready) return {...prevState, consent: false};
            }
            return {...prevState, consent: newConsent};
        });
    };

    const getRequestData = (): PatientCreateCreateRequest => {
        const data = {
            title: patientFormData.title,
            firstName: patientFormData.firstName,
            lastName: patientFormData.lastName,
            dob: patientFormData.dob,
            sex: patientFormData.sex,
            primaryEmail: patientFormData.primaryEmail,
            primaryPhoneNumber: patientFormData.primaryPhoneNumber,
            postcode: patientFormData.address.postcode,
            isSelfPayer: patientFormData.insuranceProviderId < 1,
            consent: patientFormData.consent,
            patientReference: patientFormData.patientReference
                ? patientFormData.patientReference
                : '',
            firstLine: patientFormData.address.firstLine
                ? patientFormData.address.firstLine
                : '',
            city: patientFormData.address.city
                ? patientFormData.address.city
                : '',
            insuranceProviderId: patientFormData.insuranceProviderId
                ? patientFormData.insuranceProviderId
                : 0,
        };
        console.log(data);
        // @ts-expect-error Date vs date str issue
        return data; // TODO: Check typing of dob field only string works on request.
    };

    const createPatient = async () => {
        const requestFormData = getRequestData();
        const result = await patientService.postCreatePatient(requestFormData);
        isSuccessful(result);

        if (result.success) {
            setPatientFormData(createInitPatientDataForm());
            setFormChecks(createInitPatientFormChecks());
            return;
        }
        setErrorMessage(
            'There has been an error creating the patient. Please try again.'
        );
        toggleShowError();
    };

    const updatePatient = async () => {
        const requestFormData = getRequestData();
        const result = await patientService.updatePatientById(
            patientId,
            requestFormData
        );
        isSuccessful(result);
        if (result.success) return;

        setErrorMessage(
            'There has been an error update the patient information. Please try again.'
        );
        toggleShowError();
    };

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // Check form is valid
        if (!patientFormData.consent || !formValid) {
            // TODO: Add error message
            return;
        }

        // Create patient if new
        if (isNewPatient) {
            createPatient();
            return;
        }

        // Update patient if existing
        updatePatient();
    };

    return {
        patientFormData,
        isNewPatient,
        updateFormFromTarget,
        updatePatientFormData,
        setDOB,
        updateEmail,
        updatePhone,
        updateAddress,
        handleConsentChange,
        formChecks,
        gpsError,
        isDOBValid,
        onSubmit,
        showError,
        toggleShowError,
        errorMessage,
    };
};

export default usePatientEntryForm;
