import React, {useEffect, useRef} from 'react';
import {useJsApiLoader, Libraries} from '@react-google-maps/api';
import {Form} from 'react-bootstrap';

export type SearchAddressResult = {
    firstLine: string;
    city: string;
    postcode: string;
};

interface AddressAutoCompleteFieldProps {
    inputRef: React.RefObject<HTMLInputElement>;
    onPlaceSelected: (place: SearchAddressResult) => void;
}

interface AutocompleteResult {
    address_components: google.maps.GeocoderAddressComponent[];
    geometry: {
        location: google.maps.LatLng;
    };
}

interface ReducedAutocompleteResult {
    street_number?: string;
    route: string;
    postal_town: string;
    postal_code: string;
}

const libraries: Libraries = ['places'];

const AddressAutoCompleteField: React.FC<AddressAutoCompleteFieldProps> = ({
    inputRef,
    onPlaceSelected,
}) => {
    // @ts-expect-error Avoid error on env var import
    const apiKey = import.meta.env.VITE_GMAPS_API_KEY;
    if (apiKey === '') {
        console.error('API key not found');
        return null;
    }

    const {isLoaded, loadError} = useJsApiLoader({
        googleMapsApiKey: apiKey,
        libraries: libraries,
    });

    const autocomplete = useRef(null);
    const [address, setAddress] = React.useState<SearchAddressResult>({
        firstLine: '',
        city: '',
        postcode: '',
    });

    const formatAddress = (place: AutocompleteResult) => {
        if (!place || !place.address_components) return;
        const addressDict: ReducedAutocompleteResult =
            place.address_components.reduce(
                (acc, component) => {
                    component.types.forEach((type) => {
                        acc[type] = component.long_name;
                    });
                    return acc;
                },
                {
                    route: '',
                    postal_town: '',
                    postal_code: '',
                }
            );
        setAddress({
            firstLine: addressDict.street_number
                ? `${addressDict.street_number} ${addressDict.route}`
                : addressDict.route,
            city: addressDict.postal_town,
            postcode: addressDict.postal_code,
        });
    };

    const handleOnPlacesChanged = () => {
        if (isLoaded && inputRef.current) {
            autocomplete.current = new window.google.maps.places.Autocomplete(
                inputRef.current,
                {
                    componentRestrictions: {country: 'GB'},
                    fields: ['address_components', 'geometry'],
                    types: ['address'],
                }
            );

            autocomplete.current.addListener('place_changed', () => {
                const place = autocomplete.current.getPlace();
                formatAddress(place);
            });
        }
    };

    useEffect(() => handleOnPlacesChanged(), [isLoaded]);
    useEffect(() => {
        if (address.firstLine && address.city && address.postcode) {
            onPlaceSelected(address);
        }
    }, [address]);

    if (loadError) {
        return <div>Error loading Google Maps</div>;
    }

    return (
        <Form.Control
            type="name"
            placeholder="Start typing address here..."
            className="mb-3"
            ref={inputRef}
        />
    );
};

export default AddressAutoCompleteField;
