/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicCenter } from './ClinicCenter';
import {
    ClinicCenterFromJSON,
    ClinicCenterFromJSONTyped,
    ClinicCenterToJSON,
    ClinicCenterToJSONTyped,
} from './ClinicCenter';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';
import type { InsuranceAgreement } from './InsuranceAgreement';
import {
    InsuranceAgreementFromJSON,
    InsuranceAgreementFromJSONTyped,
    InsuranceAgreementToJSON,
    InsuranceAgreementToJSONTyped,
} from './InsuranceAgreement';

/**
 * 
 * @export
 * @interface ClinicianRoleProfile
 */
export interface ClinicianRoleProfile {
    /**
     * 
     * @type {number}
     * @memberof ClinicianRoleProfile
     */
    readonly id: number;
    /**
     * 
     * @type {UserProfile}
     * @memberof ClinicianRoleProfile
     */
    readonly userProfile: UserProfile;
    /**
     * 
     * @type {Array<InsuranceAgreement>}
     * @memberof ClinicianRoleProfile
     */
    readonly insuranceAgreements: Array<InsuranceAgreement>;
    /**
     * 
     * @type {ClinicCenter}
     * @memberof ClinicianRoleProfile
     */
    readonly clinic: ClinicCenter | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicianRoleProfile
     */
    readonly isFavourite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicianRoleProfile
     */
    readonly isDoctor: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClinicianRoleProfile
     */
    readonly distance: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicianRoleProfile
     */
    isDeactivated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ClinicianRoleProfile
     */
    readonly accountCreatedOn: Date;
}

/**
 * Check if a given object implements the ClinicianRoleProfile interface.
 */
export function instanceOfClinicianRoleProfile(value: object): value is ClinicianRoleProfile {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userProfile' in value) || value['userProfile'] === undefined) return false;
    if (!('insuranceAgreements' in value) || value['insuranceAgreements'] === undefined) return false;
    if (!('clinic' in value) || value['clinic'] === undefined) return false;
    if (!('isFavourite' in value) || value['isFavourite'] === undefined) return false;
    if (!('isDoctor' in value) || value['isDoctor'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    if (!('accountCreatedOn' in value) || value['accountCreatedOn'] === undefined) return false;
    return true;
}

export function ClinicianRoleProfileFromJSON(json: any): ClinicianRoleProfile {
    return ClinicianRoleProfileFromJSONTyped(json, false);
}

export function ClinicianRoleProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianRoleProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userProfile': UserProfileFromJSON(json['userProfile']),
        'insuranceAgreements': ((json['insuranceAgreements'] as Array<any>).map(InsuranceAgreementFromJSON)),
        'clinic': ClinicCenterFromJSON(json['clinic']),
        'isFavourite': json['isFavourite'],
        'isDoctor': json['isDoctor'],
        'distance': json['distance'],
        'isDeactivated': json['isDeactivated'] == null ? undefined : json['isDeactivated'],
        'accountCreatedOn': (new Date(json['accountCreatedOn'])),
    };
}

export function ClinicianRoleProfileToJSON(json: any): ClinicianRoleProfile {
    return ClinicianRoleProfileToJSONTyped(json, false);
}

export function ClinicianRoleProfileToJSONTyped(value?: Omit<ClinicianRoleProfile, 'id'|'userProfile'|'insuranceAgreements'|'clinic'|'isFavourite'|'isDoctor'|'distance'|'accountCreatedOn'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'isDeactivated': value['isDeactivated'],
    };
}

