import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useDelegateService} from '@/actions';
import {RootState} from '@/reducers';

export interface DelegateVerificationFormClinicianData {
    title: string;
    firstName: string;
    lastName: string;
}

export interface DelegateVerificationFormData {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    rePassword: string;
    clinician: DelegateVerificationFormClinicianData;
}

export interface UseDelegateVerificationFormReturn {
    userData: DelegateVerificationFormData;
    updateUserData: (data: Partial<DelegateVerificationFormData>) => void;
    termsAccepted: boolean;
    setTermsAccepted: (value: boolean) => void;
    showTerms: boolean;
    setShowTerms: (value: boolean) => void;
    passwordsMatch: boolean;
    updateTermsAccepted: () => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    showSuccess: boolean;
}

const useDelegateVerificationForm = () => {
    const params = useParams();
    const delegateService = useDelegateService();

    useEffect(() => {
        const getDelegateInfo = async () => {
            const {userHash, timeHash, verificationCode} = params;
            const success = await delegateService.getVerifyDelegateUser(
                verificationCode,
                timeHash,
                userHash
            );
            if (!success) delegateService.goToRegisterDelegateErrorPage();
        };
        window.scrollTo(0, 0);
        getDelegateInfo();
    }, []);

    const initData = {
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        rePassword: '',
        clinician: {
            title: '',
            firstName: '',
            lastName: '',
        },
    };
    const delegateRegistration = useSelector(
        (state: RootState) => state.auth.delegateRegistration
    );
    const [userData, setUserData] =
        useState<DelegateVerificationFormData>(initData);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (
            delegateRegistration &&
            delegateRegistration.clinician &&
            delegateRegistration.delegate
        ) {
            setUserData((prevState: DelegateVerificationFormData) => ({
                title: delegateRegistration.delegate.title,
                firstName: delegateRegistration.delegate.firstName,
                lastName: delegateRegistration.delegate.lastName,
                email: delegateRegistration.delegate.user.email,
                clinician: {
                    title: delegateRegistration.clinician.title,
                    firstName: delegateRegistration.clinician.firstName,
                    lastName: delegateRegistration.clinician.lastName,
                },
                password: prevState.password,
                rePassword: prevState.rePassword,
            }));
        }
    }, [delegateRegistration]);

    useEffect(() => {
        const match = userData.password === userData.rePassword;
        setPasswordsMatch(match);
        if (!match && termsAccepted) {
            setTermsAccepted(false);
        }
    }, [userData.password, userData.rePassword]);

    const updateUserData = (data: Partial<DelegateVerificationFormData>) =>
        setUserData((prevState) => ({...prevState, ...data}));

    const updateTermsAccepted = () => {
        const ready = passwordsMatch;
        if (!ready) {
            setTermsAccepted(false);
            return;
        }
        setTermsAccepted((prevState) => !prevState);
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const success = await delegateService.verifyDelegateUser(userData);
        setShowSuccess(success);
    };

    return {
        userData,
        updateUserData,
        termsAccepted,
        setTermsAccepted,
        showTerms,
        setShowTerms,
        passwordsMatch,
        updateTermsAccepted,
        onSubmit,
        showSuccess,
    };
};

export default useDelegateVerificationForm;
