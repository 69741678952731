// import { configureStore, Store } from '@reduxjs/toolkit'
// import rootReducer, { RootState } from '@/reducers/index';

// const store: Store<RootState> = configureStore({
//     reducer: rootReducer,
//     // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
//     devTools: process.env.NODE_ENV !== 'PRODUCTION'
//   })

//   export default store

import {createStore, applyMiddleware, Store} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer, {RootState} from '@/reducers/index';

const middleware = [thunk];

const store: Store<RootState> = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import reducer from './reducers/index';

// const middleware = [thunk];

// const store = createStore(
// 	reducer,
// 	composeWithDevTools(applyMiddleware(...middleware))
// );

// export default store;
