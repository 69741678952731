/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClinicianAnalyticsResponse,
  ClinicianInsuranceUpdateRequest,
  ClinicianInsuranceUpdateResponse,
  ClinicianProfileCreateRequest,
  ClinicianProfileCreateResponse,
  ClinicianProfileDeactivateResponse,
  ClinicianProfileUpdateRequest,
  ClinicianProfileUpdateResponse,
  ClinicianUserInfoResponse,
  DeactivateDelegateRequest,
  DeactivateUserAccountResponse,
  DelegatesListResponse,
  FavouriteClinicianCreateResponse,
  FavouriteClinicianDeleteResponse,
  FavouriteClinicianGetResponse,
  InviteClinicianRequest,
  InviteClinicianResponse,
  LoginUserResponse,
  RemoveClinicianProfileRequest,
  RemoveClinicianProfileResponse,
  RequestHelpRequest,
  RequestHelpResponse,
  RequestNewFeatureRequest,
  RequestNewFeatureResponse,
  ResetUserPasswordRequest,
  ResetUserPasswordResponse,
  ResetUserPasswordSetNewRequest,
  ResetUserPasswordSetNewResponse,
  RestoreDelegateRequest,
  UpdateDelegatePermissionRequest,
  UpdateReferralContactsRequest,
  UpdateReferralContactsResponse,
  UpdateUserNotificationsSettingsRequest,
  UpdateUserNotificationsSettingsResponse,
  UpdateUserPasswordRequest,
  UpdateUserPasswordResponse,
  UpdateUserProfilePictureResponse,
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
} from '../models/index';
import {
    ClinicianAnalyticsResponseFromJSON,
    ClinicianAnalyticsResponseToJSON,
    ClinicianInsuranceUpdateRequestFromJSON,
    ClinicianInsuranceUpdateRequestToJSON,
    ClinicianInsuranceUpdateResponseFromJSON,
    ClinicianInsuranceUpdateResponseToJSON,
    ClinicianProfileCreateRequestFromJSON,
    ClinicianProfileCreateRequestToJSON,
    ClinicianProfileCreateResponseFromJSON,
    ClinicianProfileCreateResponseToJSON,
    ClinicianProfileDeactivateResponseFromJSON,
    ClinicianProfileDeactivateResponseToJSON,
    ClinicianProfileUpdateRequestFromJSON,
    ClinicianProfileUpdateRequestToJSON,
    ClinicianProfileUpdateResponseFromJSON,
    ClinicianProfileUpdateResponseToJSON,
    ClinicianUserInfoResponseFromJSON,
    ClinicianUserInfoResponseToJSON,
    DeactivateDelegateRequestFromJSON,
    DeactivateDelegateRequestToJSON,
    DeactivateUserAccountResponseFromJSON,
    DeactivateUserAccountResponseToJSON,
    DelegatesListResponseFromJSON,
    DelegatesListResponseToJSON,
    FavouriteClinicianCreateResponseFromJSON,
    FavouriteClinicianCreateResponseToJSON,
    FavouriteClinicianDeleteResponseFromJSON,
    FavouriteClinicianDeleteResponseToJSON,
    FavouriteClinicianGetResponseFromJSON,
    FavouriteClinicianGetResponseToJSON,
    InviteClinicianRequestFromJSON,
    InviteClinicianRequestToJSON,
    InviteClinicianResponseFromJSON,
    InviteClinicianResponseToJSON,
    LoginUserResponseFromJSON,
    LoginUserResponseToJSON,
    RemoveClinicianProfileRequestFromJSON,
    RemoveClinicianProfileRequestToJSON,
    RemoveClinicianProfileResponseFromJSON,
    RemoveClinicianProfileResponseToJSON,
    RequestHelpRequestFromJSON,
    RequestHelpRequestToJSON,
    RequestHelpResponseFromJSON,
    RequestHelpResponseToJSON,
    RequestNewFeatureRequestFromJSON,
    RequestNewFeatureRequestToJSON,
    RequestNewFeatureResponseFromJSON,
    RequestNewFeatureResponseToJSON,
    ResetUserPasswordRequestFromJSON,
    ResetUserPasswordRequestToJSON,
    ResetUserPasswordResponseFromJSON,
    ResetUserPasswordResponseToJSON,
    ResetUserPasswordSetNewRequestFromJSON,
    ResetUserPasswordSetNewRequestToJSON,
    ResetUserPasswordSetNewResponseFromJSON,
    ResetUserPasswordSetNewResponseToJSON,
    RestoreDelegateRequestFromJSON,
    RestoreDelegateRequestToJSON,
    UpdateDelegatePermissionRequestFromJSON,
    UpdateDelegatePermissionRequestToJSON,
    UpdateReferralContactsRequestFromJSON,
    UpdateReferralContactsRequestToJSON,
    UpdateReferralContactsResponseFromJSON,
    UpdateReferralContactsResponseToJSON,
    UpdateUserNotificationsSettingsRequestFromJSON,
    UpdateUserNotificationsSettingsRequestToJSON,
    UpdateUserNotificationsSettingsResponseFromJSON,
    UpdateUserNotificationsSettingsResponseToJSON,
    UpdateUserPasswordRequestFromJSON,
    UpdateUserPasswordRequestToJSON,
    UpdateUserPasswordResponseFromJSON,
    UpdateUserPasswordResponseToJSON,
    UpdateUserProfilePictureResponseFromJSON,
    UpdateUserProfilePictureResponseToJSON,
    UpdateUserProfileRequestFromJSON,
    UpdateUserProfileRequestToJSON,
    UpdateUserProfileResponseFromJSON,
    UpdateUserProfileResponseToJSON,
} from '../models/index';

export interface UserAnalyticsDataRetrieveRequest {
    number: number;
}

export interface UserClinicianInformationCreateCreateRequest {
    clinicianProfileCreateRequest: ClinicianProfileCreateRequest;
}

export interface UserClinicianInformationDeactivateUpdateRequest {
    id: number;
}

export interface UserClinicianInformationRemoveClinicCreateRequest {
    removeClinicianProfileRequest: RemoveClinicianProfileRequest;
}

export interface UserClinicianInformationUpdateInsuranceAgreementsUpdateRequest {
    clinicianInsuranceUpdateRequest: ClinicianInsuranceUpdateRequest;
}

export interface UserClinicianInformationUpdateUpdateRequest {
    clinicId: number;
    clinicianProfileUpdateRequest: ClinicianProfileUpdateRequest;
}

export interface UserDeactivateAccountDestroyRequest {
    userProfileId: number;
}

export interface UserDelegateDeactivateCreateRequest {
    deactivateDelegateRequest: DeactivateDelegateRequest;
}

export interface UserDelegateRestoreCreateRequest {
    restoreDelegateRequest: RestoreDelegateRequest;
}

export interface UserDelegateUpdatePermisisonCreateRequest {
    updateDelegatePermissionRequest: UpdateDelegatePermissionRequest;
}

export interface UserFavouriteClinicianAddCreateRequest {
    clinicianUserProfileId: number;
}

export interface UserFavouriteClinicianRemoveDestroyRequest {
    clinicianUserProfileId: number;
}

export interface UserInviteClinicianCreateRequest {
    inviteClinicianRequest: InviteClinicianRequest;
}

export interface UserNotificationsUpdateUpdateRequest {
    updateUserNotificationsSettingsRequest: UpdateUserNotificationsSettingsRequest;
}

export interface UserPasswordResetCreateRequest {
    resetUserPasswordRequest: ResetUserPasswordRequest;
}

export interface UserPasswordResetSetNewCreateRequest {
    resetUserPasswordSetNewRequest: ResetUserPasswordSetNewRequest;
}

export interface UserPasswordUpdateUpdateRequest {
    updateUserPasswordRequest: UpdateUserPasswordRequest;
}

export interface UserProfilePicUploadCreateRequest {
    image: string;
}

export interface UserReferralContactsUpdateUpdateRequest {
    updateReferralContactsRequest?: UpdateReferralContactsRequest;
}

export interface UserRequestFeatureCreateRequest {
    requestNewFeatureRequest: RequestNewFeatureRequest;
}

export interface UserRequestHelpCreateRequest {
    requestHelpRequest: RequestHelpRequest;
}

export interface UserUpdateUpdateRequest {
    updateUserProfileRequest: UpdateUserProfileRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Return the analytics for the logged in user.
     */
    async userAnalyticsDataRetrieveRaw(requestParameters: UserAnalyticsDataRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicianAnalyticsResponse>> {
        if (requestParameters['number'] == null) {
            throw new runtime.RequiredError(
                'number',
                'Required parameter "number" was null or undefined when calling userAnalyticsDataRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/analytics-data/{number}/`.replace(`{${"number"}}`, encodeURIComponent(String(requestParameters['number']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicianAnalyticsResponseFromJSON(jsonValue));
    }

    /**
     * Return the analytics for the logged in user.
     */
    async userAnalyticsDataRetrieve(requestParameters: UserAnalyticsDataRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicianAnalyticsResponse> {
        const response = await this.userAnalyticsDataRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks current authentication token
     */
    async userCheckAuthRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/check-auth/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginUserResponseFromJSON(jsonValue));
    }

    /**
     * Checks current authentication token
     */
    async userCheckAuthRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginUserResponse> {
        const response = await this.userCheckAuthRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Creates a new clinician profile for the logged in user
     */
    async userClinicianInformationCreateCreateRaw(requestParameters: UserClinicianInformationCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicianProfileCreateResponse>> {
        if (requestParameters['clinicianProfileCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'clinicianProfileCreateRequest',
                'Required parameter "clinicianProfileCreateRequest" was null or undefined when calling userClinicianInformationCreateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/clinician-information/create/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicianProfileCreateRequestToJSON(requestParameters['clinicianProfileCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicianProfileCreateResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new clinician profile for the logged in user
     */
    async userClinicianInformationCreateCreate(requestParameters: UserClinicianInformationCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicianProfileCreateResponse> {
        const response = await this.userClinicianInformationCreateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivates the clinician account.
     */
    async userClinicianInformationDeactivateUpdateRaw(requestParameters: UserClinicianInformationDeactivateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicianProfileDeactivateResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userClinicianInformationDeactivateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/clinician-information/deactivate/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicianProfileDeactivateResponseFromJSON(jsonValue));
    }

    /**
     * Deactivates the clinician account.
     */
    async userClinicianInformationDeactivateUpdate(requestParameters: UserClinicianInformationDeactivateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicianProfileDeactivateResponse> {
        const response = await this.userClinicianInformationDeactivateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request to remove the clinician account
     */
    async userClinicianInformationRemoveClinicCreateRaw(requestParameters: UserClinicianInformationRemoveClinicCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RemoveClinicianProfileResponse>> {
        if (requestParameters['removeClinicianProfileRequest'] == null) {
            throw new runtime.RequiredError(
                'removeClinicianProfileRequest',
                'Required parameter "removeClinicianProfileRequest" was null or undefined when calling userClinicianInformationRemoveClinicCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/clinician-information/remove-clinic/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveClinicianProfileRequestToJSON(requestParameters['removeClinicianProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveClinicianProfileResponseFromJSON(jsonValue));
    }

    /**
     * Request to remove the clinician account
     */
    async userClinicianInformationRemoveClinicCreate(requestParameters: UserClinicianInformationRemoveClinicCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RemoveClinicianProfileResponse> {
        const response = await this.userClinicianInformationRemoveClinicCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns clinician profile information for the logged in user.
     */
    async userClinicianInformationRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicianUserInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/clinician-information/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicianUserInfoResponseFromJSON(jsonValue));
    }

    /**
     * Returns clinician profile information for the logged in user.
     */
    async userClinicianInformationRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicianUserInfoResponse> {
        const response = await this.userClinicianInformationRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates the list of accepted insurers for the logged in user account
     */
    async userClinicianInformationUpdateInsuranceAgreementsUpdateRaw(requestParameters: UserClinicianInformationUpdateInsuranceAgreementsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicianInsuranceUpdateResponse>> {
        if (requestParameters['clinicianInsuranceUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'clinicianInsuranceUpdateRequest',
                'Required parameter "clinicianInsuranceUpdateRequest" was null or undefined when calling userClinicianInformationUpdateInsuranceAgreementsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/clinician-information/update-insurance-agreements/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicianInsuranceUpdateRequestToJSON(requestParameters['clinicianInsuranceUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicianInsuranceUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Updates the list of accepted insurers for the logged in user account
     */
    async userClinicianInformationUpdateInsuranceAgreementsUpdate(requestParameters: UserClinicianInformationUpdateInsuranceAgreementsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicianInsuranceUpdateResponse> {
        const response = await this.userClinicianInformationUpdateInsuranceAgreementsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the clinician profile.
     */
    async userClinicianInformationUpdateUpdateRaw(requestParameters: UserClinicianInformationUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicianProfileUpdateResponse>> {
        if (requestParameters['clinicId'] == null) {
            throw new runtime.RequiredError(
                'clinicId',
                'Required parameter "clinicId" was null or undefined when calling userClinicianInformationUpdateUpdate().'
            );
        }

        if (requestParameters['clinicianProfileUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'clinicianProfileUpdateRequest',
                'Required parameter "clinicianProfileUpdateRequest" was null or undefined when calling userClinicianInformationUpdateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/clinician-information/update/{clinic_id}/`.replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters['clinicId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicianProfileUpdateRequestToJSON(requestParameters['clinicianProfileUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicianProfileUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Updates the clinician profile.
     */
    async userClinicianInformationUpdateUpdate(requestParameters: UserClinicianInformationUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicianProfileUpdateResponse> {
        const response = await this.userClinicianInformationUpdateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the users password while logged in
     */
    async userDeactivateAccountDestroyRaw(requestParameters: UserDeactivateAccountDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeactivateUserAccountResponse>> {
        if (requestParameters['userProfileId'] == null) {
            throw new runtime.RequiredError(
                'userProfileId',
                'Required parameter "userProfileId" was null or undefined when calling userDeactivateAccountDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/deactivate-account/{userProfileId}/`.replace(`{${"userProfileId"}}`, encodeURIComponent(String(requestParameters['userProfileId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeactivateUserAccountResponseFromJSON(jsonValue));
    }

    /**
     * Updates the users password while logged in
     */
    async userDeactivateAccountDestroy(requestParameters: UserDeactivateAccountDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeactivateUserAccountResponse> {
        const response = await this.userDeactivateAccountDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate user delegate.
     */
    async userDelegateDeactivateCreateRaw(requestParameters: UserDelegateDeactivateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DelegatesListResponse>> {
        if (requestParameters['deactivateDelegateRequest'] == null) {
            throw new runtime.RequiredError(
                'deactivateDelegateRequest',
                'Required parameter "deactivateDelegateRequest" was null or undefined when calling userDelegateDeactivateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/delegate/deactivate/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeactivateDelegateRequestToJSON(requestParameters['deactivateDelegateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DelegatesListResponseFromJSON(jsonValue));
    }

    /**
     * Deactivate user delegate.
     */
    async userDelegateDeactivateCreate(requestParameters: UserDelegateDeactivateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DelegatesListResponse> {
        const response = await this.userDelegateDeactivateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Restore user delegate.
     */
    async userDelegateRestoreCreateRaw(requestParameters: UserDelegateRestoreCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DelegatesListResponse>> {
        if (requestParameters['restoreDelegateRequest'] == null) {
            throw new runtime.RequiredError(
                'restoreDelegateRequest',
                'Required parameter "restoreDelegateRequest" was null or undefined when calling userDelegateRestoreCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/delegate/restore/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RestoreDelegateRequestToJSON(requestParameters['restoreDelegateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DelegatesListResponseFromJSON(jsonValue));
    }

    /**
     * Restore user delegate.
     */
    async userDelegateRestoreCreate(requestParameters: UserDelegateRestoreCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DelegatesListResponse> {
        const response = await this.userDelegateRestoreCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update delegate permission.
     */
    async userDelegateUpdatePermisisonCreateRaw(requestParameters: UserDelegateUpdatePermisisonCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DelegatesListResponse>> {
        if (requestParameters['updateDelegatePermissionRequest'] == null) {
            throw new runtime.RequiredError(
                'updateDelegatePermissionRequest',
                'Required parameter "updateDelegatePermissionRequest" was null or undefined when calling userDelegateUpdatePermisisonCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/delegate/update-permisison/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDelegatePermissionRequestToJSON(requestParameters['updateDelegatePermissionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DelegatesListResponseFromJSON(jsonValue));
    }

    /**
     * Update delegate permission.
     */
    async userDelegateUpdatePermisisonCreate(requestParameters: UserDelegateUpdatePermisisonCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DelegatesListResponse> {
        const response = await this.userDelegateUpdatePermisisonCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get delegate user.
     */
    async userDelegatesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DelegatesListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/delegates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DelegatesListResponseFromJSON(jsonValue));
    }

    /**
     * Get delegate user.
     */
    async userDelegatesRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DelegatesListResponse> {
        const response = await this.userDelegatesRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Creates a favourite clinician for the logged in user.
     */
    async userFavouriteClinicianAddCreateRaw(requestParameters: UserFavouriteClinicianAddCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavouriteClinicianCreateResponse>> {
        if (requestParameters['clinicianUserProfileId'] == null) {
            throw new runtime.RequiredError(
                'clinicianUserProfileId',
                'Required parameter "clinicianUserProfileId" was null or undefined when calling userFavouriteClinicianAddCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/favourite-clinician/add/{clinician_user_profile_id}/`.replace(`{${"clinician_user_profile_id"}}`, encodeURIComponent(String(requestParameters['clinicianUserProfileId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavouriteClinicianCreateResponseFromJSON(jsonValue));
    }

    /**
     * Creates a favourite clinician for the logged in user.
     */
    async userFavouriteClinicianAddCreate(requestParameters: UserFavouriteClinicianAddCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavouriteClinicianCreateResponse> {
        const response = await this.userFavouriteClinicianAddCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of favourite clinicians.
     */
    async userFavouriteClinicianGetRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavouriteClinicianGetResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/favourite-clinician/get/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavouriteClinicianGetResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of favourite clinicians.
     */
    async userFavouriteClinicianGetRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavouriteClinicianGetResponse> {
        const response = await this.userFavouriteClinicianGetRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Deletes the favourite clinician object for the logged in user.
     */
    async userFavouriteClinicianRemoveDestroyRaw(requestParameters: UserFavouriteClinicianRemoveDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavouriteClinicianDeleteResponse>> {
        if (requestParameters['clinicianUserProfileId'] == null) {
            throw new runtime.RequiredError(
                'clinicianUserProfileId',
                'Required parameter "clinicianUserProfileId" was null or undefined when calling userFavouriteClinicianRemoveDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/favourite-clinician/remove/{clinician_user_profile_id}/`.replace(`{${"clinician_user_profile_id"}}`, encodeURIComponent(String(requestParameters['clinicianUserProfileId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavouriteClinicianDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the favourite clinician object for the logged in user.
     */
    async userFavouriteClinicianRemoveDestroy(requestParameters: UserFavouriteClinicianRemoveDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavouriteClinicianDeleteResponse> {
        const response = await this.userFavouriteClinicianRemoveDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invites a clinician to join the platform.
     */
    async userInviteClinicianCreateRaw(requestParameters: UserInviteClinicianCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InviteClinicianResponse>> {
        if (requestParameters['inviteClinicianRequest'] == null) {
            throw new runtime.RequiredError(
                'inviteClinicianRequest',
                'Required parameter "inviteClinicianRequest" was null or undefined when calling userInviteClinicianCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/invite-clinician/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteClinicianRequestToJSON(requestParameters['inviteClinicianRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InviteClinicianResponseFromJSON(jsonValue));
    }

    /**
     * Invites a clinician to join the platform.
     */
    async userInviteClinicianCreate(requestParameters: UserInviteClinicianCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InviteClinicianResponse> {
        const response = await this.userInviteClinicianCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the user profile details of the logged in user.
     */
    async userNotificationsUpdateUpdateRaw(requestParameters: UserNotificationsUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserNotificationsSettingsResponse>> {
        if (requestParameters['updateUserNotificationsSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateUserNotificationsSettingsRequest',
                'Required parameter "updateUserNotificationsSettingsRequest" was null or undefined when calling userNotificationsUpdateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/notifications/update/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserNotificationsSettingsRequestToJSON(requestParameters['updateUserNotificationsSettingsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserNotificationsSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Update the user profile details of the logged in user.
     */
    async userNotificationsUpdateUpdate(requestParameters: UserNotificationsUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserNotificationsSettingsResponse> {
        const response = await this.userNotificationsUpdateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resets the users password
     */
    async userPasswordResetCreateRaw(requestParameters: UserPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResetUserPasswordResponse>> {
        if (requestParameters['resetUserPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'resetUserPasswordRequest',
                'Required parameter "resetUserPasswordRequest" was null or undefined when calling userPasswordResetCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/user/password/reset/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetUserPasswordRequestToJSON(requestParameters['resetUserPasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetUserPasswordResponseFromJSON(jsonValue));
    }

    /**
     * Resets the users password
     */
    async userPasswordResetCreate(requestParameters: UserPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResetUserPasswordResponse> {
        const response = await this.userPasswordResetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set new password for user, not logged in
     */
    async userPasswordResetSetNewCreateRaw(requestParameters: UserPasswordResetSetNewCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResetUserPasswordSetNewResponse>> {
        if (requestParameters['resetUserPasswordSetNewRequest'] == null) {
            throw new runtime.RequiredError(
                'resetUserPasswordSetNewRequest',
                'Required parameter "resetUserPasswordSetNewRequest" was null or undefined when calling userPasswordResetSetNewCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/user/password/reset/set-new/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetUserPasswordSetNewRequestToJSON(requestParameters['resetUserPasswordSetNewRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetUserPasswordSetNewResponseFromJSON(jsonValue));
    }

    /**
     * Set new password for user, not logged in
     */
    async userPasswordResetSetNewCreate(requestParameters: UserPasswordResetSetNewCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResetUserPasswordSetNewResponse> {
        const response = await this.userPasswordResetSetNewCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the users password while logged in
     */
    async userPasswordUpdateUpdateRaw(requestParameters: UserPasswordUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserPasswordResponse>> {
        if (requestParameters['updateUserPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'updateUserPasswordRequest',
                'Required parameter "updateUserPasswordRequest" was null or undefined when calling userPasswordUpdateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/password/update/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPasswordRequestToJSON(requestParameters['updateUserPasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserPasswordResponseFromJSON(jsonValue));
    }

    /**
     * Updates the users password while logged in
     */
    async userPasswordUpdateUpdate(requestParameters: UserPasswordUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserPasswordResponse> {
        const response = await this.userPasswordUpdateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a profile picture to the logged in users account.
     */
    async userProfilePicUploadCreateRaw(requestParameters: UserProfilePicUploadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserProfilePictureResponse>> {
        if (requestParameters['image'] == null) {
            throw new runtime.RequiredError(
                'image',
                'Required parameter "image" was null or undefined when calling userProfilePicUploadCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['image'] != null) {
            formParams.append('image', requestParameters['image'] as any);
        }

        const response = await this.request({
            path: `/api/v1/user/profile-pic/upload/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserProfilePictureResponseFromJSON(jsonValue));
    }

    /**
     * Upload a profile picture to the logged in users account.
     */
    async userProfilePicUploadCreate(requestParameters: UserProfilePicUploadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserProfilePictureResponse> {
        const response = await this.userProfilePicUploadCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update referral contacts for the logged in user.
     */
    async userReferralContactsUpdateUpdateRaw(requestParameters: UserReferralContactsUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateReferralContactsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/referral-contacts-update/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateReferralContactsRequestToJSON(requestParameters['updateReferralContactsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateReferralContactsResponseFromJSON(jsonValue));
    }

    /**
     * Update referral contacts for the logged in user.
     */
    async userReferralContactsUpdateUpdate(requestParameters: UserReferralContactsUpdateUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateReferralContactsResponse> {
        const response = await this.userReferralContactsUpdateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request new feature.
     */
    async userRequestFeatureCreateRaw(requestParameters: UserRequestFeatureCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestNewFeatureResponse>> {
        if (requestParameters['requestNewFeatureRequest'] == null) {
            throw new runtime.RequiredError(
                'requestNewFeatureRequest',
                'Required parameter "requestNewFeatureRequest" was null or undefined when calling userRequestFeatureCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/request-feature/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestNewFeatureRequestToJSON(requestParameters['requestNewFeatureRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestNewFeatureResponseFromJSON(jsonValue));
    }

    /**
     * Request new feature.
     */
    async userRequestFeatureCreate(requestParameters: UserRequestFeatureCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestNewFeatureResponse> {
        const response = await this.userRequestFeatureCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request help.
     */
    async userRequestHelpCreateRaw(requestParameters: UserRequestHelpCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RequestHelpResponse>> {
        if (requestParameters['requestHelpRequest'] == null) {
            throw new runtime.RequiredError(
                'requestHelpRequest',
                'Required parameter "requestHelpRequest" was null or undefined when calling userRequestHelpCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/request-help/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestHelpRequestToJSON(requestParameters['requestHelpRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestHelpResponseFromJSON(jsonValue));
    }

    /**
     * Request help.
     */
    async userRequestHelpCreate(requestParameters: UserRequestHelpCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RequestHelpResponse> {
        const response = await this.userRequestHelpCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the user profile details of the logged in user.
     */
    async userUpdateUpdateRaw(requestParameters: UserUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserProfileResponse>> {
        if (requestParameters['updateUserProfileRequest'] == null) {
            throw new runtime.RequiredError(
                'updateUserProfileRequest',
                'Required parameter "updateUserProfileRequest" was null or undefined when calling userUpdateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user/update/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserProfileRequestToJSON(requestParameters['updateUserProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserProfileResponseFromJSON(jsonValue));
    }

    /**
     * Update the user profile details of the logged in user.
     */
    async userUpdateUpdate(requestParameters: UserUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserProfileResponse> {
        const response = await this.userUpdateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
