/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralToggleDnaRequest
 */
export interface ReferralToggleDnaRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ReferralToggleDnaRequest
     */
    toDna: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralToggleDnaRequest
     */
    crpId: number;
}

/**
 * Check if a given object implements the ReferralToggleDnaRequest interface.
 */
export function instanceOfReferralToggleDnaRequest(value: object): value is ReferralToggleDnaRequest {
    if (!('toDna' in value) || value['toDna'] === undefined) return false;
    if (!('crpId' in value) || value['crpId'] === undefined) return false;
    return true;
}

export function ReferralToggleDnaRequestFromJSON(json: any): ReferralToggleDnaRequest {
    return ReferralToggleDnaRequestFromJSONTyped(json, false);
}

export function ReferralToggleDnaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralToggleDnaRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'toDna': json['toDna'],
        'crpId': json['crpId'],
    };
}

export function ReferralToggleDnaRequestToJSON(json: any): ReferralToggleDnaRequest {
    return ReferralToggleDnaRequestToJSONTyped(json, false);
}

export function ReferralToggleDnaRequestToJSONTyped(value?: ReferralToggleDnaRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'toDna': value['toDna'],
        'crpId': value['crpId'],
    };
}

