import React from 'react';
import {Modal} from 'react-bootstrap';
import {MdHelpOutline} from 'react-icons/md';
import {Tooltip} from 'react-tooltip';

export interface HelpIconProps {
    onClick: () => void;
}

const HelpIcon: React.FC<HelpIconProps> = ({onClick}) => {
    return (
        <>
            <MdHelpOutline
                size={24}
                color="white"
                data-tooltip-id="help-tooltip"
                data-tooltip-content="Click here for help."
                onClick={onClick}
                style={{cursor: 'pointer', margin: 0}}
            />
            <Tooltip id="help-tooltip" />
        </>
    );
};

interface GroupPoint {
    label: string;
    description: string;
}

interface GroupHelpModalProps {
    showHelp: boolean;
    toggleHelp: () => void;
    title: string;
    summary: string;
    groupPoints: GroupPoint[];
}

export const GroupsHelpModal: React.FC<GroupHelpModalProps> = ({
    showHelp,
    toggleHelp,
    title,
    summary,
    groupPoints,
}) => (
    <Modal
        show={showHelp}
        onHide={toggleHelp}
        dialogClassName="modal-90w"
        aria-labelledby="help-modal"
    >
        <Modal.Header closeButton>
            <Modal.Title className="medr-inv" id="help-modal">
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="medr-inv">
                <p>{summary}</p>
                <ul>
                    {groupPoints.map((point) => (
                        <li key={point.label}>
                            <strong className="medr-bold">
                                {point.label}:
                            </strong>{' '}
                            {point.description}
                        </li>
                    ))}
                </ul>
            </div>
        </Modal.Body>
    </Modal>
);

export default HelpIcon;
