import React, {useState, useEffect, useCallback} from 'react';
import {Form, Button, Row} from 'react-bootstrap';
import {useNavigate, Navigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PasswordField from '@components/app/parts/PasswordField';
import {useAuthService} from '@/actions';
import {RootState} from '@/reducers';
import {MedrPages} from '@/constants/variables';

interface ResetPasswordFormData {
    userHash: string;
    timeHash: string;
    verificationCode: string;
    password: string;
    rePassword: string;
}

const SendResetPasswordScreen: React.FC = () => {
    const isAuthenticated = useSelector(
        (state: RootState) => state.auth.isAuthenticated
    );
    const navigate = useNavigate();
    const authService = useAuthService();
    const {userHash, timeHash, verificationCode} = useParams();

    const [formData, setFormData] = useState<ResetPasswordFormData>({
        userHash: userHash || '',
        timeHash: timeHash || '',
        verificationCode: verificationCode || '',
        password: '',
        rePassword: '',
    });

    const [unsuccesfulRequest, setUnsuccesfulRequest] =
        useState<boolean>(false);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
    const {password, rePassword} = formData;

    // Redirect if user is already authenticated
    if (isAuthenticated) {
        return <Navigate to={MedrPages.DASHBOARD.toString()} />;
    }

    // Check if passwords match
    useEffect(() => {
        setPasswordsMatch(password === rePassword);
    }, [password, rePassword]);

    // Handle form input changes
    const updateChanges = useCallback(
        (newData: Partial<ResetPasswordFormData>) => {
            setFormData((prevData) => ({...prevData, ...newData}));
        },
        []
    );

    // Handle form submission
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!passwordsMatch) return;

        const success = await authService.resetPasswordSetNew(formData);
        if (success) {
            navigate(MedrPages.LOGIN.toString());
        } else {
            setUnsuccesfulRequest(true);
        }
    };

    return (
        <div
            className="medr-layout text-center dashboard-page"
            style={{
                maxWidth: '380px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <h1>Set new password</h1>
            <Form className="text-left" onSubmit={onSubmit}>
                <PasswordField
                    controlId="password"
                    label="Enter Password"
                    password={password}
                    keyLabel="password"
                    changeValues={updateChanges}
                />
                <PasswordField
                    controlId="rePassword"
                    label="Confirm Password"
                    password={rePassword}
                    keyLabel="rePassword"
                    changeValues={updateChanges}
                    isInvalid={!passwordsMatch}
                    invalidString="Passwords do not match!"
                />
                {unsuccesfulRequest && (
                    <p className="mt-2 text-danger">
                        Pasword reset code invalid. Please request password
                        reset again.
                    </p>
                )}
                <Row className="mt-4 text-center">
                    <div className="col-12">
                        <Button
                            type="submit"
                            variant="primary"
                            className="sel-button w80"
                        >
                            Set password
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    );
};

export default SendResetPasswordScreen;
