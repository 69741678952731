import {Reducer} from 'react';
import * as CONSTS from '@constants/adminConstants';
import {LOGOUT_ACTION} from '@constants/authConstants';

import * as ITF from '@reducers/adminReducer/interfaces';
import {AdminState} from './interfaces';
import {LogoutSuccessAction} from '../authReducer';

const initialState: AdminState = {
    loading: false,
    // @ts-expect-error TYPING TO BE SORTED
    platformStats: {},
    users: {
        users: [],
        unverifiedUsers: [],
        deactivatedUsers: [],
        operationsUsers: [],
    },
    clinicians: {
        staff: [],
        unverified: [],
        clinicians: [],
        deactivated: [],
        delegates: [],
    },
    insurers: {
        insurers: [],
        unverifiedInsurers: [],
    },
    patientsList: [],
    referrals: {
        open: [],
        pending: [],
        accepted: [],
        booked: [],
        dna: [],
    },
};

// INCOMING REQUESTS
function isRequestAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminActionRequests {
    return [
        CONSTS.PLATFORM_STATS_ACTION.REQUEST,
        CONSTS.ADMIN_USERS_LIST_ACTION.REQUEST,
        CONSTS.ADMIN_PATIENTS_LIST_ACTION.REQUEST,
        CONSTS.ADMIN_REFERRALS_LIST_ACTION.REQUEST,
        CONSTS.ADMIN_CLINICIANS_LIST_ACTION.REQUEST,
        CONSTS.ADMIN_INSURERS_LIST_ACTION.REQUEST,
        CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.REQUEST,
    ].includes(action.type);
}

function handleRequestAction(state: AdminState): AdminState {
    return {
        ...state,
        loading: true,
    };
}

// INCOMING FAILURES

function isFailureAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminActionFailures {
    return [
        CONSTS.PLATFORM_STATS_ACTION.FAIL,
        CONSTS.ADMIN_USERS_LIST_ACTION.FAIL,
        CONSTS.ADMIN_PATIENTS_LIST_ACTION.FAIL,
        CONSTS.ADMIN_REFERRALS_LIST_ACTION.FAIL,
        CONSTS.ADMIN_CLINICIANS_LIST_ACTION.FAIL,
        CONSTS.ADMIN_INSURERS_LIST_ACTION.FAIL,
        CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.FAIL,
    ].includes(action.type);
}

function handleFailureAction(state: AdminState): AdminState {
    return {
        ...state,
        loading: false,
    };
}

// INCOMING SUCCESS ACTIONS
function isLogoutSuccessAction(
    action: ITF.AdminActionTypes
): action is LogoutSuccessAction {
    return action.type === LOGOUT_ACTION.SUCCESS;
}

function handleLogoutSuccessAction(): AdminState {
    return {...initialState};
}

function isUsersListSuccessAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminUsersListSuccessAction {
    return action.type === CONSTS.ADMIN_USERS_LIST_ACTION.SUCCESS;
}

function handleUsersListSuccessAction(
    state: AdminState,
    action: ITF.AdminUsersListSuccessAction
): AdminState {
    return {
        ...state,
        users: action.payload.users,
        loading: false,
    };
}

function isPatientsListSuccessAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminPatientsListSuccessAction {
    return action.type === CONSTS.ADMIN_PATIENTS_LIST_ACTION.SUCCESS;
}

function handlePatientsListSuccessAction(
    state: AdminState,
    action: ITF.AdminPatientsListSuccessAction
): AdminState {
    return {
        ...state,
        patientsList: action.payload.patientsList,
        loading: false,
    };
}

function isReferralsListSuccessAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminReferralsListSuccessAction {
    return action.type === CONSTS.ADMIN_REFERRALS_LIST_ACTION.SUCCESS;
}

function handleReferralsListSuccessAction(
    state: AdminState,
    action: ITF.AdminReferralsListSuccessAction
): AdminState {
    return {
        ...state,
        referrals: action.payload.referrals,
        loading: false,
    };
}

function isCliniciansListSuccessAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminCliniciansListSuccessAction {
    return action.type === CONSTS.ADMIN_CLINICIANS_LIST_ACTION.SUCCESS;
}

function handleCliniciansListSuccessAction(
    state: AdminState,
    action: ITF.AdminCliniciansListSuccessAction
): AdminState {
    return {
        ...state,
        clinicians: action.payload.clinicians,
        loading: false,
    };
}

function isInsurersListSuccessAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminInsurersListSuccessAction {
    return action.type === CONSTS.ADMIN_INSURERS_LIST_ACTION.SUCCESS;
}

function handleInsurersListSuccessAction(
    state: AdminState,
    action: ITF.AdminInsurersListSuccessAction
): AdminState {
    return {
        ...state,
        insurers: action.payload.insurers,
        loading: false,
    };
}

function isPlatformStatsSuccessAction(
    action: ITF.AdminActionTypes
): action is ITF.PlatformStatsSuccessAction {
    return action.type === CONSTS.PLATFORM_STATS_ACTION.SUCCESS;
}

function handlePlatformStatsSuccessAction(
    state: AdminState,
    action: ITF.PlatformStatsSuccessAction
): AdminState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isSetUserVerifiedSuccessAction(
    action: ITF.AdminActionTypes
): action is ITF.AdminSetUserVerifiedSuccessAction {
    return action.type === CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.SUCCESS;
}

function handleSetUserVerifiedSuccessAction(
    state: AdminState,
    action: ITF.AdminSetUserVerifiedSuccessAction
): AdminState {
    return {
        ...state,
        users: action.payload.users,
        loading: false,
    };
}

export const adminReducer: Reducer<AdminState, ITF.AdminActionTypes> = (
    state = initialState,
    action
) => {
    // INCOMING REQUEST ACTIONS
    if (isRequestAction(action)) {
        return handleRequestAction(state);
    }

    // INCOMING SUCCESS ACTIONS
    if (isLogoutSuccessAction(action)) {
        return handleLogoutSuccessAction();
    }
    if (isUsersListSuccessAction(action)) {
        return handleUsersListSuccessAction(state, action);
    }
    if (isPatientsListSuccessAction(action)) {
        return handlePatientsListSuccessAction(state, action);
    }
    if (isReferralsListSuccessAction(action)) {
        return handleReferralsListSuccessAction(state, action);
    }
    if (isCliniciansListSuccessAction(action)) {
        return handleCliniciansListSuccessAction(state, action);
    }
    if (isInsurersListSuccessAction(action)) {
        return handleInsurersListSuccessAction(state, action);
    }
    if (isPlatformStatsSuccessAction(action)) {
        return handlePlatformStatsSuccessAction(state, action);
    }
    if (isSetUserVerifiedSuccessAction(action)) {
        return handleSetUserVerifiedSuccessAction(state, action);
    }
    // INCOMING FAIL ACTIONS
    if (isFailureAction(action)) {
        return handleFailureAction(state);
    }
    return state;
};
