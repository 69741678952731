/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralCreateRequest
 */
export interface ReferralCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof ReferralCreateRequest
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralCreateRequest
     */
    crpId: number;
    /**
     * 
     * @type {number}
     * @memberof ReferralCreateRequest
     */
    jobRoleId: number;
    /**
     * 
     * @type {string}
     * @memberof ReferralCreateRequest
     */
    mainDescription: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralCreateRequest
     */
    patientHistory: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralCreateRequest
     */
    specifiedClinician: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralCreateRequest
     */
    receiverCrpId?: number;
}

/**
 * Check if a given object implements the ReferralCreateRequest interface.
 */
export function instanceOfReferralCreateRequest(value: object): value is ReferralCreateRequest {
    if (!('patientId' in value) || value['patientId'] === undefined) return false;
    if (!('crpId' in value) || value['crpId'] === undefined) return false;
    if (!('jobRoleId' in value) || value['jobRoleId'] === undefined) return false;
    if (!('mainDescription' in value) || value['mainDescription'] === undefined) return false;
    if (!('patientHistory' in value) || value['patientHistory'] === undefined) return false;
    if (!('specifiedClinician' in value) || value['specifiedClinician'] === undefined) return false;
    return true;
}

export function ReferralCreateRequestFromJSON(json: any): ReferralCreateRequest {
    return ReferralCreateRequestFromJSONTyped(json, false);
}

export function ReferralCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'patientId': json['patientId'],
        'crpId': json['crpId'],
        'jobRoleId': json['jobRoleId'],
        'mainDescription': json['mainDescription'],
        'patientHistory': json['patientHistory'],
        'specifiedClinician': json['specifiedClinician'],
        'receiverCrpId': json['receiverCrpId'] == null ? undefined : json['receiverCrpId'],
    };
}

export function ReferralCreateRequestToJSON(json: any): ReferralCreateRequest {
    return ReferralCreateRequestToJSONTyped(json, false);
}

export function ReferralCreateRequestToJSONTyped(value?: ReferralCreateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'patientId': value['patientId'],
        'crpId': value['crpId'],
        'jobRoleId': value['jobRoleId'],
        'mainDescription': value['mainDescription'],
        'patientHistory': value['patientHistory'],
        'specifiedClinician': value['specifiedClinician'],
        'receiverCrpId': value['receiverCrpId'],
    };
}

