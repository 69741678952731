import React, {useState, useEffect} from 'react';
import {Modal, ProgressBar} from 'react-bootstrap';
import {Variant, NotificationMessageProps} from './interfaces';

const NotificationMessage: React.FC<NotificationMessageProps> = ({
    show,
    toggleShow,
    variant,
    message,
}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!show) {
            setProgress(0);
            return;
        }
        const startTime = Date.now();
        const duration = 5000; // 5 seconds

        const timer = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const newProgress = Math.min((elapsedTime / duration) * 100, 100);
            setProgress(newProgress);

            if (elapsedTime >= duration) {
                clearInterval(timer);
                toggleShow();
            }
        }, 50); // Update every 50ms for smoother animation

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [show, toggleShow]);

    const titleClass =
        variant === Variant.DANGER ? `text-${variant}` : 'medr-inv';
    return (
        <Modal show={show} onHide={toggleShow} style={{paddingBottom: '5px'}}>
            <Modal.Header closeButton style={{borderBottom: 'none'}}>
                <Modal.Title
                    className={titleClass}
                    id={`msg${variant}`}
                    style={{fontSize: '1rem'}}
                >
                    {' '}
                    {message}{' '}
                </Modal.Title>
            </Modal.Header>
            <div
                style={{
                    marginLeft: '30px',
                    marginRight: '30px',
                }}
            >
                <ProgressBar
                    now={progress}
                    min={0}
                    max={100}
                    style={{
                        height: '5px',
                        borderRadius: '0.5rem',
                    }}
                    variant={
                        variant === Variant.DANGER
                            ? Variant.DANGER
                            : Variant.INFO
                    }
                />
            </div>
        </Modal>
    );
};

export default NotificationMessage;
