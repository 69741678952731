/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
    PatientToJSONTyped,
} from './Patient';

/**
 * 
 * @export
 * @interface PatientsListForUserResponse
 */
export interface PatientsListForUserResponse {
    /**
     * 
     * @type {Array<Patient>}
     * @memberof PatientsListForUserResponse
     */
    addedPatients: Array<Patient>;
    /**
     * 
     * @type {Array<Patient>}
     * @memberof PatientsListForUserResponse
     */
    referredPatients: Array<Patient>;
    /**
     * 
     * @type {Array<Patient>}
     * @memberof PatientsListForUserResponse
     */
    pendingPatients: Array<Patient>;
    /**
     * 
     * @type {Array<Patient>}
     * @memberof PatientsListForUserResponse
     */
    acceptedPatients: Array<Patient>;
    /**
     * 
     * @type {Array<Patient>}
     * @memberof PatientsListForUserResponse
     */
    completedPatients: Array<Patient>;
}

/**
 * Check if a given object implements the PatientsListForUserResponse interface.
 */
export function instanceOfPatientsListForUserResponse(value: object): value is PatientsListForUserResponse {
    if (!('addedPatients' in value) || value['addedPatients'] === undefined) return false;
    if (!('referredPatients' in value) || value['referredPatients'] === undefined) return false;
    if (!('pendingPatients' in value) || value['pendingPatients'] === undefined) return false;
    if (!('acceptedPatients' in value) || value['acceptedPatients'] === undefined) return false;
    if (!('completedPatients' in value) || value['completedPatients'] === undefined) return false;
    return true;
}

export function PatientsListForUserResponseFromJSON(json: any): PatientsListForUserResponse {
    return PatientsListForUserResponseFromJSONTyped(json, false);
}

export function PatientsListForUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientsListForUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'addedPatients': ((json['addedPatients'] as Array<any>).map(PatientFromJSON)),
        'referredPatients': ((json['referredPatients'] as Array<any>).map(PatientFromJSON)),
        'pendingPatients': ((json['pendingPatients'] as Array<any>).map(PatientFromJSON)),
        'acceptedPatients': ((json['acceptedPatients'] as Array<any>).map(PatientFromJSON)),
        'completedPatients': ((json['completedPatients'] as Array<any>).map(PatientFromJSON)),
    };
}

export function PatientsListForUserResponseToJSON(json: any): PatientsListForUserResponse {
    return PatientsListForUserResponseToJSONTyped(json, false);
}

export function PatientsListForUserResponseToJSONTyped(value?: PatientsListForUserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'addedPatients': ((value['addedPatients'] as Array<any>).map(PatientToJSON)),
        'referredPatients': ((value['referredPatients'] as Array<any>).map(PatientToJSON)),
        'pendingPatients': ((value['pendingPatients'] as Array<any>).map(PatientToJSON)),
        'acceptedPatients': ((value['acceptedPatients'] as Array<any>).map(PatientToJSON)),
        'completedPatients': ((value['completedPatients'] as Array<any>).map(PatientToJSON)),
    };
}

