import React from 'react';
import AdminUsersTable from '@components/admin/AdminUsersTable';

const AdminUserListScreen: React.FC = () => {
    return (
        <div>
            <h2>Users</h2>
            <AdminUsersTable />
        </div>
    );
};

export default AdminUserListScreen;
