import {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useAuthService} from '@/actions';
import PasswordField from '@components/app/parts/PasswordField';
import {RootState} from '@/reducers';

interface PasswordformData {
    currentPassword: string;
    password: string;
    rePassword: string;
}

function createInitData(): PasswordformData {
    return {
        currentPassword: '',
        password: '',
        rePassword: '',
    };
}

const ProfileChangePassword: React.FC = () => {
    const authService = useAuthService();
    const [values, setValues] = useState<PasswordformData>(createInitData());
    const [wrongPassword, setWrongPassword] = useState<boolean>(false);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);
    const username = useSelector(
        (state: RootState) => state.auth.user.user.username
    );
    const {currentPassword, password, rePassword} = values;

    const updateChanges = (newData: Partial<PasswordformData>) => {
        setValues({...values, ...newData});
    };

    useEffect(() => {
        setPasswordsMatch(password === rePassword);
    }, [password, rePassword]);

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = {currentPassword, newPassword: password};

        const changeSuccess = await authService.sendResetPassword(formData);
        if (!changeSuccess) {
            setWrongPassword(true);
            return;
        }

        await authService.login(username, password);
        authService.goToDashboardPage();
    };

    return (
        <div className="d-flex justify-content-center">
            <Form
                onSubmit={onSubmit}
                className="text-left"
                style={{maxWidth: '500px', flexGrow: 1}}
            >
                <PasswordField
                    controlId="password"
                    label="Current Password"
                    password={currentPassword}
                    keyLabel="currentPassword"
                    changeValues={updateChanges}
                    autoComplete="password"
                    isInvalid={wrongPassword}
                    invalidString="Incorrect password!"
                />
                <PasswordField
                    controlId="newPassword"
                    label="Password"
                    password={password}
                    keyLabel="password"
                    changeValues={updateChanges}
                />
                <PasswordField
                    controlId="reNewPassword"
                    label="Confirm Password"
                    password={rePassword}
                    keyLabel="rePassword"
                    changeValues={updateChanges}
                    isInvalid={!passwordsMatch}
                    invalidString="Passwords do not match!"
                />
                <Row className="text-center mt-4">
                    <Col>
                        <Button
                            className="sel-button w80"
                            type="submit"
                            variant="primary"
                            disabled={!passwordsMatch}
                        >
                            Set password
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ProfileChangePassword;
