import React, {useState} from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap';
import {useSearchService} from '@/actions';
import {LocationSearchResponse} from '@/features/map/useMapView';
interface PostcodeSearchProps {
    lat: number;
    lng: number;
    currentPostcode?: string;
    changeValues: (values: LocationSearchResponse) => void;
}

const PostcodeSearch: React.FC<PostcodeSearchProps> = ({
    lat,
    lng,
    currentPostcode = '',
    changeValues,
}) => {
    const searchService = useSearchService();

    const handleSetSearchpostcode = async (postCode: string) => {
        const result = await searchService.getSearchPostcodeData({postCode});
        if (!result) {
            setError(true);
            return;
        }

        if (!result.lat || !result.lng) {
            setError(true);
            return;
        }

        changeValues({
            searchLatitude: result.lat,
            searchLongitude: result.lng,
            postcode: result.postcode,
        });
    };

    const [data, setData] = useState({postcode: currentPostcode, lat, lng});
    const [error, setError] = useState(false);

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setData((prevState) => ({
            ...prevState,
            postcode: e.target.value.toUpperCase(),
        }));
    };

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(false);
        await handleSetSearchpostcode(data.postcode);
    };

    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group controlId="postcode">
                        <Form.Control
                            required
                            type="name"
                            placeholder={
                                currentPostcode !== ''
                                    ? currentPostcode
                                    : 'Enter postcode'
                            }
                            name="postcode"
                            value={data.postcode}
                            onChange={onChange}
                            isInvalid={error}
                        />
                        <Form.Control.Feedback type="invalid">
                            Error locating postcode
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Button type="submit" variant="primary" className="mt-0">
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default PostcodeSearch;
