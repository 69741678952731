/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Male` - Male
 * * `Female` - Female
 * * `Other` - Other
 * * `Unknown` - Unknown
 * @export
 */
export const SexEnum = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other',
    Unknown: 'Unknown'
} as const;
export type SexEnum = typeof SexEnum[keyof typeof SexEnum];


export function instanceOfSexEnum(value: any): boolean {
    for (const key in SexEnum) {
        if (Object.prototype.hasOwnProperty.call(SexEnum, key)) {
            if (SexEnum[key as keyof typeof SexEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SexEnumFromJSON(json: any): SexEnum {
    return SexEnumFromJSONTyped(json, false);
}

export function SexEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SexEnum {
    return json as SexEnum;
}

export function SexEnumToJSON(value?: SexEnum | null): any {
    return value as any;
}

export function SexEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): SexEnum {
    return value as SexEnum;
}

