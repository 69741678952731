/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteClinicianResponse
 */
export interface InviteClinicianResponse {
    /**
     * 
     * @type {string}
     * @memberof InviteClinicianResponse
     */
    passed: string;
}

/**
 * Check if a given object implements the InviteClinicianResponse interface.
 */
export function instanceOfInviteClinicianResponse(value: object): value is InviteClinicianResponse {
    if (!('passed' in value) || value['passed'] === undefined) return false;
    return true;
}

export function InviteClinicianResponseFromJSON(json: any): InviteClinicianResponse {
    return InviteClinicianResponseFromJSONTyped(json, false);
}

export function InviteClinicianResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteClinicianResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'passed': json['passed'],
    };
}

export function InviteClinicianResponseToJSON(json: any): InviteClinicianResponse {
    return InviteClinicianResponseToJSONTyped(json, false);
}

export function InviteClinicianResponseToJSONTyped(value?: InviteClinicianResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'passed': value['passed'],
    };
}

