/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
    PatientToJSONTyped,
} from './Patient';

/**
 * 
 * @export
 * @interface PatientFormCreateResponse
 */
export interface PatientFormCreateResponse {
    /**
     * 
     * @type {Message}
     * @memberof PatientFormCreateResponse
     */
    message: Message;
    /**
     * 
     * @type {Patient}
     * @memberof PatientFormCreateResponse
     */
    currentPatient: Patient;
}

/**
 * Check if a given object implements the PatientFormCreateResponse interface.
 */
export function instanceOfPatientFormCreateResponse(value: object): value is PatientFormCreateResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('currentPatient' in value) || value['currentPatient'] === undefined) return false;
    return true;
}

export function PatientFormCreateResponseFromJSON(json: any): PatientFormCreateResponse {
    return PatientFormCreateResponseFromJSONTyped(json, false);
}

export function PatientFormCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientFormCreateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
        'currentPatient': PatientFromJSON(json['currentPatient']),
    };
}

export function PatientFormCreateResponseToJSON(json: any): PatientFormCreateResponse {
    return PatientFormCreateResponseToJSONTyped(json, false);
}

export function PatientFormCreateResponseToJSONTyped(value?: PatientFormCreateResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': MessageToJSON(value['message']),
        'currentPatient': PatientToJSON(value['currentPatient']),
    };
}

