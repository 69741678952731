import {Dispatch} from 'redux';
import {useSelector} from 'react-redux';
import {NavigateFunction} from 'react-router-dom';
import {redirectIfInvalidToken} from './authService';
import {
    Patient,
    PatientByIdResponse,
    PatientCreateCreateRequest,
    PatientApi,
} from '@/generated-client';
import {RootState} from '@/reducers';
import * as PXRED from '@/reducers/patientReducer';
import {MedrService} from './authService';

export type PatientRequestReturnedType = {
    success: boolean;
    patient: Patient;
};

export class PatientService extends MedrService {
    public currentPatient: Patient;

    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
        this.currentPatient = useSelector(
            (state: RootState) => state.patients.currentPatient
        );
    }

    async getClinicianPatientList(): Promise<boolean> {
        const api = new PatientApi(this.configuration);
        return this.executeApiCall(
            () => api.patientListRetrieve(),
            PXRED.dispatchGetClinicianPatientListRequest,
            PXRED.dispatchGetClinicianPatientListSuccess,
            PXRED.dispatchGetClinicianPatientListFail
        );
    }

    async getPxDataById(pxId: number): Promise<PatientByIdResponse> {
        const validPxId = this.checkValidPatientId(pxId);
        if (!validPxId) return null;
        const payload = {pxId};
        const api = new PatientApi(this.configuration);
        return this.executeApiCallReturnObject(
            () => api.patientRetrieve(payload),
            PXRED.dispatchGetPxDataByIdRequest,
            PXRED.dispatchGetPxDataByIdSuccess,
            PXRED.dispatchGetClinicianPatientListFail
        );
    }

    async getPatientById(pxId: number): Promise<boolean> {
        const validPxId = this.checkValidPatientId(pxId);
        if (!validPxId) return null;
        const payload = {pxId};
        const api = new PatientApi(this.configuration);
        return this.executeApiCall(
            () => api.patientRetrieve(payload),
            PXRED.dispatchGetPatientByIdRequest,
            PXRED.dispatchGetPatientByIdSuccess,
            PXRED.dispatchGetPatientByIdFail
        );
    }

    async getPatientReferralsById(pxId: number): Promise<boolean> {
        const payload = {pxId};
        const api = new PatientApi(this.configuration);
        return this.executeApiCall(
            () => api.patientReferralsRetrieve(payload),
            PXRED.dispatchGetPatientReferralsByIdRequest,
            PXRED.dispatchGetPatientReferralsByIdSuccess,
            PXRED.dispatchGetPatientReferralsByIdFail
        );
    }

    async postCreatePatient(
        inputData: PatientCreateCreateRequest
    ): Promise<PatientRequestReturnedType> {
        const payload = inputData;
        const api = new PatientApi(this.configuration);

        PXRED.dispatchPostCreatePatientRequest(this.dispatch);

        try {
            const data = await api.patientCreateCreate(payload);
            PXRED.dispatchPostCreatePatientSuccess(this.dispatch, data);
            return {success: true, patient: data.currentPatient};
        } catch (error) {
            redirectIfInvalidToken(error, this.navigate);
            PXRED.dispatchPostCreatePatientFail(this.dispatch, error);
            return {success: false, patient: null};
        }
    }

    async updatePatientById(
        pxId: number,
        inputData: PatientCreateCreateRequest
    ): Promise<PatientRequestReturnedType> {
        const payload = {pxId, ...inputData};

        // const validPxId = this.checkValidPatientId(pxId);
        PXRED.dispatchUpdatePatientByIdRequest(this.dispatch);

        const api = new PatientApi(this.configuration);

        try {
            const data = await api.patientUpdateUpdate(payload);
            PXRED.dispatchUpdatePatientByIdSuccess(this.dispatch, data);
            return {success: true, patient: data.patient};
        } catch (error) {
            redirectIfInvalidToken(error, this.navigate);
            PXRED.dispatchUpdatePatientByIdFail(this.dispatch, error);
            return {success: false, patient: null};
        }
    }

    async deletePatientById(pxId: number): Promise<boolean> {
        const payload = {pxId};
        const api = new PatientApi(this.configuration);
        return this.executeApiCall(
            () => api.patientDeleteDestroy(payload),
            PXRED.dispatchDeletePatientByIdRequest,
            PXRED.dispatchDeletePatientByIdSuccess,
            PXRED.dispatchDeletePatientByIdFail
        );
    }

    checkValidPatientId(pxId: number): boolean {
        return pxId && pxId !== 0;
    }

    postCreatePatientByImage(): void {}

    resetCurrentPatient(): void {
        PXRED.dispatchResetCurrentPatient(this.dispatch);
    }

    getPatientViaId(patients: Patient[], pxId: number): Patient {
        return patients.find((patient) => patient.id === pxId);
    }
}
