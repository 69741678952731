/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PatientByIdResponse,
  PatientFormCreateResponse,
  PatientUpdateByIdResponse,
  PatientsListForUserResponse,
  ReferralsListForPatientResponse,
} from '../models/index';
import {
    PatientByIdResponseFromJSON,
    PatientByIdResponseToJSON,
    PatientFormCreateResponseFromJSON,
    PatientFormCreateResponseToJSON,
    PatientUpdateByIdResponseFromJSON,
    PatientUpdateByIdResponseToJSON,
    PatientsListForUserResponseFromJSON,
    PatientsListForUserResponseToJSON,
    ReferralsListForPatientResponseFromJSON,
    ReferralsListForPatientResponseToJSON,
} from '../models/index';

export interface PatientCreateCreateRequest {
    title: string;
    firstName: string;
    lastName: string;
    dob: Date;
    sex: string;
    primaryEmail: string;
    primaryPhoneNumber: string;
    postcode: string;
    isSelfPayer: boolean;
    consent: boolean;
    patientReference?: string;
    firstLine?: string;
    city?: string;
    insuranceProviderId?: number;
}

export interface PatientDeleteDestroyRequest {
    pxId: number;
}

export interface PatientReferralsRetrieveRequest {
    pxId: number;
}

export interface PatientRetrieveRequest {
    pxId: number;
}

export interface PatientUpdateUpdateRequest {
    pxId: number;
    title: string;
    firstName: string;
    lastName: string;
    dob: Date;
    sex: string;
    primaryEmail: string;
    primaryPhoneNumber: string;
    postcode: string;
    isSelfPayer: boolean;
    consent: boolean;
    patientReference?: string;
    firstLine?: string;
    city?: string;
    insuranceProviderId?: number;
}

/**
 * 
 */
export class PatientApi extends runtime.BaseAPI {

    /**
     * Creates a new patient using form data to populate fields. A patient details image is not required
     */
    async patientCreateCreateRaw(requestParameters: PatientCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientFormCreateResponse>> {
        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError(
                'firstName',
                'Required parameter "firstName" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError(
                'lastName',
                'Required parameter "lastName" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['dob'] == null) {
            throw new runtime.RequiredError(
                'dob',
                'Required parameter "dob" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['sex'] == null) {
            throw new runtime.RequiredError(
                'sex',
                'Required parameter "sex" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['primaryEmail'] == null) {
            throw new runtime.RequiredError(
                'primaryEmail',
                'Required parameter "primaryEmail" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['primaryPhoneNumber'] == null) {
            throw new runtime.RequiredError(
                'primaryPhoneNumber',
                'Required parameter "primaryPhoneNumber" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['postcode'] == null) {
            throw new runtime.RequiredError(
                'postcode',
                'Required parameter "postcode" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['isSelfPayer'] == null) {
            throw new runtime.RequiredError(
                'isSelfPayer',
                'Required parameter "isSelfPayer" was null or undefined when calling patientCreateCreate().'
            );
        }

        if (requestParameters['consent'] == null) {
            throw new runtime.RequiredError(
                'consent',
                'Required parameter "consent" was null or undefined when calling patientCreateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['title'] != null) {
            formParams.append('title', requestParameters['title'] as any);
        }

        if (requestParameters['firstName'] != null) {
            formParams.append('firstName', requestParameters['firstName'] as any);
        }

        if (requestParameters['lastName'] != null) {
            formParams.append('lastName', requestParameters['lastName'] as any);
        }

        if (requestParameters['patientReference'] != null) {
            formParams.append('patientReference', requestParameters['patientReference'] as any);
        }

        if (requestParameters['dob'] != null) {
            formParams.append('dob', requestParameters['dob'] as any);
        }

        if (requestParameters['sex'] != null) {
            formParams.append('sex', requestParameters['sex'] as any);
        }

        if (requestParameters['primaryEmail'] != null) {
            formParams.append('primaryEmail', requestParameters['primaryEmail'] as any);
        }

        if (requestParameters['primaryPhoneNumber'] != null) {
            formParams.append('primaryPhoneNumber', requestParameters['primaryPhoneNumber'] as any);
        }

        if (requestParameters['firstLine'] != null) {
            formParams.append('firstLine', requestParameters['firstLine'] as any);
        }

        if (requestParameters['city'] != null) {
            formParams.append('city', requestParameters['city'] as any);
        }

        if (requestParameters['postcode'] != null) {
            formParams.append('postcode', requestParameters['postcode'] as any);
        }

        if (requestParameters['isSelfPayer'] != null) {
            formParams.append('isSelfPayer', requestParameters['isSelfPayer'] as any);
        }

        if (requestParameters['insuranceProviderId'] != null) {
            formParams.append('insuranceProviderId', requestParameters['insuranceProviderId'] as any);
        }

        if (requestParameters['consent'] != null) {
            formParams.append('consent', requestParameters['consent'] as any);
        }

        const response = await this.request({
            path: `/api/v1/patient/create/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFormCreateResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new patient using form data to populate fields. A patient details image is not required
     */
    async patientCreateCreate(requestParameters: PatientCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientFormCreateResponse> {
        const response = await this.patientCreateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes patient using uid
     */
    async patientDeleteDestroyRaw(requestParameters: PatientDeleteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientsListForUserResponse>> {
        if (requestParameters['pxId'] == null) {
            throw new runtime.RequiredError(
                'pxId',
                'Required parameter "pxId" was null or undefined when calling patientDeleteDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/patient/delete/{px_id}/`.replace(`{${"px_id"}}`, encodeURIComponent(String(requestParameters['pxId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientsListForUserResponseFromJSON(jsonValue));
    }

    /**
     * Deletes patient using uid
     */
    async patientDeleteDestroy(requestParameters: PatientDeleteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientsListForUserResponse> {
        const response = await this.patientDeleteDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns patients linked to the logged in user.
     */
    async patientListRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientsListForUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/patient/list/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientsListForUserResponseFromJSON(jsonValue));
    }

    /**
     * Returns patients linked to the logged in user.
     */
    async patientListRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientsListForUserResponse> {
        const response = await this.patientListRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns referrals linked to patient using the patients uid.
     */
    async patientReferralsRetrieveRaw(requestParameters: PatientReferralsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralsListForPatientResponse>> {
        if (requestParameters['pxId'] == null) {
            throw new runtime.RequiredError(
                'pxId',
                'Required parameter "pxId" was null or undefined when calling patientReferralsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/patient/referrals/{px_id}/`.replace(`{${"px_id"}}`, encodeURIComponent(String(requestParameters['pxId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralsListForPatientResponseFromJSON(jsonValue));
    }

    /**
     * Returns referrals linked to patient using the patients uid.
     */
    async patientReferralsRetrieve(requestParameters: PatientReferralsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralsListForPatientResponse> {
        const response = await this.patientReferralsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns patient information using uid
     */
    async patientRetrieveRaw(requestParameters: PatientRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientByIdResponse>> {
        if (requestParameters['pxId'] == null) {
            throw new runtime.RequiredError(
                'pxId',
                'Required parameter "pxId" was null or undefined when calling patientRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/patient/{px_id}/`.replace(`{${"px_id"}}`, encodeURIComponent(String(requestParameters['pxId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientByIdResponseFromJSON(jsonValue));
    }

    /**
     * Returns patient information using uid
     */
    async patientRetrieve(requestParameters: PatientRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientByIdResponse> {
        const response = await this.patientRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates patient information using uid.
     */
    async patientUpdateUpdateRaw(requestParameters: PatientUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientUpdateByIdResponse>> {
        if (requestParameters['pxId'] == null) {
            throw new runtime.RequiredError(
                'pxId',
                'Required parameter "pxId" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError(
                'firstName',
                'Required parameter "firstName" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError(
                'lastName',
                'Required parameter "lastName" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['dob'] == null) {
            throw new runtime.RequiredError(
                'dob',
                'Required parameter "dob" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['sex'] == null) {
            throw new runtime.RequiredError(
                'sex',
                'Required parameter "sex" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['primaryEmail'] == null) {
            throw new runtime.RequiredError(
                'primaryEmail',
                'Required parameter "primaryEmail" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['primaryPhoneNumber'] == null) {
            throw new runtime.RequiredError(
                'primaryPhoneNumber',
                'Required parameter "primaryPhoneNumber" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['postcode'] == null) {
            throw new runtime.RequiredError(
                'postcode',
                'Required parameter "postcode" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['isSelfPayer'] == null) {
            throw new runtime.RequiredError(
                'isSelfPayer',
                'Required parameter "isSelfPayer" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        if (requestParameters['consent'] == null) {
            throw new runtime.RequiredError(
                'consent',
                'Required parameter "consent" was null or undefined when calling patientUpdateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['title'] != null) {
            formParams.append('title', requestParameters['title'] as any);
        }

        if (requestParameters['firstName'] != null) {
            formParams.append('firstName', requestParameters['firstName'] as any);
        }

        if (requestParameters['lastName'] != null) {
            formParams.append('lastName', requestParameters['lastName'] as any);
        }

        if (requestParameters['patientReference'] != null) {
            formParams.append('patientReference', requestParameters['patientReference'] as any);
        }

        if (requestParameters['dob'] != null) {
            formParams.append('dob', requestParameters['dob'] as any);
        }

        if (requestParameters['sex'] != null) {
            formParams.append('sex', requestParameters['sex'] as any);
        }

        if (requestParameters['primaryEmail'] != null) {
            formParams.append('primaryEmail', requestParameters['primaryEmail'] as any);
        }

        if (requestParameters['primaryPhoneNumber'] != null) {
            formParams.append('primaryPhoneNumber', requestParameters['primaryPhoneNumber'] as any);
        }

        if (requestParameters['firstLine'] != null) {
            formParams.append('firstLine', requestParameters['firstLine'] as any);
        }

        if (requestParameters['city'] != null) {
            formParams.append('city', requestParameters['city'] as any);
        }

        if (requestParameters['postcode'] != null) {
            formParams.append('postcode', requestParameters['postcode'] as any);
        }

        if (requestParameters['isSelfPayer'] != null) {
            formParams.append('isSelfPayer', requestParameters['isSelfPayer'] as any);
        }

        if (requestParameters['insuranceProviderId'] != null) {
            formParams.append('insuranceProviderId', requestParameters['insuranceProviderId'] as any);
        }

        if (requestParameters['consent'] != null) {
            formParams.append('consent', requestParameters['consent'] as any);
        }

        const response = await this.request({
            path: `/api/v1/patient/update/{px_id}/`.replace(`{${"px_id"}}`, encodeURIComponent(String(requestParameters['pxId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientUpdateByIdResponseFromJSON(jsonValue));
    }

    /**
     * Updates patient information using uid.
     */
    async patientUpdateUpdate(requestParameters: PatientUpdateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientUpdateByIdResponse> {
        const response = await this.patientUpdateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
