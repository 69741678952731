/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    AddressToJSONTyped,
} from './Address';

/**
 * 
 * @export
 * @interface ClinicCenter
 */
export interface ClinicCenter {
    /**
     * 
     * @type {number}
     * @memberof ClinicCenter
     */
    readonly id: number;
    /**
     * 
     * @type {Address}
     * @memberof ClinicCenter
     */
    readonly address: Address;
    /**
     * 
     * @type {string}
     * @memberof ClinicCenter
     */
    clinicName: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCenter
     */
    centreName: string;
}

/**
 * Check if a given object implements the ClinicCenter interface.
 */
export function instanceOfClinicCenter(value: object): value is ClinicCenter {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('clinicName' in value) || value['clinicName'] === undefined) return false;
    if (!('centreName' in value) || value['centreName'] === undefined) return false;
    return true;
}

export function ClinicCenterFromJSON(json: any): ClinicCenter {
    return ClinicCenterFromJSONTyped(json, false);
}

export function ClinicCenterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicCenter {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'address': AddressFromJSON(json['address']),
        'clinicName': json['clinicName'],
        'centreName': json['centreName'],
    };
}

export function ClinicCenterToJSON(json: any): ClinicCenter {
    return ClinicCenterToJSONTyped(json, false);
}

export function ClinicCenterToJSONTyped(value?: Omit<ClinicCenter, 'id'|'address'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinicName': value['clinicName'],
        'centreName': value['centreName'],
    };
}

