import {Reducer} from 'redux';
import * as CONSTS from '@constants/clinicianConstants';
import {createDefaultClinicianRoleProfile} from '@constants/initDataConstants';

import * as ITF from '@reducers/cliniciansReducer/interfaces';
import {ClinicianState} from '@reducers/cliniciansReducer/interfaces';
import {FullClinicianRoleProfile} from '@/hooks/clinicians/interfaces';

const initialState: ClinicianState = {
    loading: false,
    loadingClinicians: false,
    clinicianCrps: [],
    clinician: createDefaultClinicianRoleProfile(),
    cliniciansData: {
        role: 'Any',
        targetCoords: {lat: 0, lng: 0},
        clinicianList: [],
    },
    jobRoles: [],
    doctorIds: [],
    clinicianTypes: [],
    specialties: {},
    insuranceAgreements: [],
    clinics: [],
    doctorClinics: [],
    search: {
        jobRoleId: 1,
        insuranceAgreementId: 0,
        lat: 0,
        lng: 0,
    },
    clinicianList: [],
    addressesList: [],
};

// INCOMING REQUESTS

function isClinicianListRequestAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicianActionRequests {
    return action.type === CONSTS.CLINICIAN_LIST_ACTION.REQUEST;
}

function handleClinicianListRequestAction(
    state: ClinicianState
): ClinicianState {
    return {
        ...state,
        loadingClinicians: true,
    };
}

function isRequestAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicianActionRequests {
    return [
        CONSTS.CLINICS_LIST_ACTION.REQUEST,
        CONSTS.CLINICIAN_BY_ID_ACTION.REQUEST,
        CONSTS.USER_CRPS_GET_ACTION.REQUEST,
        CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.REQUEST,
        CONSTS.JOB_ROLE_LIST_ACTION.REQUEST,
        CONSTS.JOB_ROLE_BY_ID_ACTION.REQUEST,
        CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.REQUEST,
        CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.REQUEST,
    ].includes(action.type);
}

function handleRequestAction(state: ClinicianState): ClinicianState {
    return {
        ...state,
        loading: true,
    };
}

// INCOMING FAILURES

function isClinicianListFailureAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicianActionFailures {
    return action.type === CONSTS.CLINICIAN_LIST_ACTION.FAIL;
}

function handleClinicianListFailureAction(
    state: ClinicianState
): ClinicianState {
    return {
        ...state,
        loadingClinicians: false,
    };
}

function isCrpsFailureAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicianActionFailures {
    return [
        CONSTS.USER_CRPS_GET_ACTION.FAIL,
        CONSTS.CLINICIAN_BY_ID_ACTION.FAIL,
    ].includes(action.type);
}

function handleCrpsFailureAction(state: ClinicianState): ClinicianState {
    return {
        ...state,
        loading: false,
        clinician: createDefaultClinicianRoleProfile(),
        clinicianCrps: [],
    };
}

function isFailureAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicianActionFailures {
    return [
        CONSTS.CLINICS_LIST_ACTION.FAIL,
        CONSTS.CLINICIAN_BY_ID_ACTION.FAIL,
        CONSTS.USER_CRPS_GET_ACTION.FAIL,
        CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.FAIL,
        CONSTS.JOB_ROLE_LIST_ACTION.FAIL,
        CONSTS.JOB_ROLE_BY_ID_ACTION.FAIL,
        CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.FAIL,
        CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.FAIL,
    ].includes(action.type);
}

function handleFailureAction(state: ClinicianState): ClinicianState {
    return {
        ...state,
        loading: false,
    };
}

// INCOMING SUCCESS ACTIONS
function isClinicianListSuccessAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicianListSuccessAction {
    return action.type === CONSTS.CLINICIAN_LIST_ACTION.SUCCESS;
}

function handleClinicianListSuccess(
    state: ClinicianState,
    action: ITF.ClinicianListSuccessAction
): ClinicianState {
    return {
        ...state,
        loadingClinicians: false,
        clinicianList: action.payload
            .clinicianList as FullClinicianRoleProfile[],
        addressesList: action.payload.addressesList,
    };
}

function isClinicianByIdSuccessAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicianByIdSuccessAction {
    return action.type === CONSTS.CLINICIAN_BY_ID_ACTION.SUCCESS;
}

function handleClinicianByIdSuccess(
    state: ClinicianState,
    action: ITF.ClinicianByIdSuccessAction
): ClinicianState {
    return {
        ...state,
        loading: false,
        clinician: action.payload.crp,
        clinicianCrps: action.payload.allCrps,
    };
}

function isUserCrpsGetSuccessAction(
    action: ITF.ClinicianActionTypes
): action is ITF.UserCrpsGetSuccessAction {
    return action.type === CONSTS.USER_CRPS_GET_ACTION.SUCCESS;
}

function handleUserCrpsGetSuccess(
    state: ClinicianState,
    action: ITF.UserCrpsGetSuccessAction
): ClinicianState {
    return {
        ...state,
        loading: false,
        clinician: action.payload.crp,
        clinicianCrps: action.payload.allCrps,
    };
}

function isClinicsListSuccessAction(
    action: ITF.ClinicianActionTypes
): action is ITF.ClinicsListSuccessAction {
    return action.type === CONSTS.CLINICS_LIST_ACTION.SUCCESS;
}

function handleClinicsListSuccess(
    state: ClinicianState,
    action: ITF.ClinicsListSuccessAction
): ClinicianState {
    return {
        ...state,
        loading: false,
        clinics: action.payload.clinics,
        doctorClinics: action.payload.doctorClinics,
    };
}

function isJobRoleListSuccessAction(
    action: ITF.ClinicianActionTypes
): action is ITF.JobRoleListSuccessAction {
    return action.type === CONSTS.JOB_ROLE_LIST_ACTION.SUCCESS;
}

function handleJobRoleListSuccess(
    state: ClinicianState,
    action: ITF.JobRoleListSuccessAction
): ClinicianState {
    return {
        ...state,
        loading: false,
        jobRoles: action.payload.jobRoles,
        doctorIds: action.payload.doctorIds,
        clinicianTypes: action.payload.clinicianTypes,
        // @ts-expect-error TYPING TO BE SORTED
        specialties: action.payload.specialties,
    };
}

function isJobRoleByIdSuccessAction(
    action: ITF.ClinicianActionTypes
): action is ITF.JobRoleByIdSuccessAction {
    return action.type === CONSTS.JOB_ROLE_BY_ID_ACTION.SUCCESS;
}

function handleJobRoleByIdSuccess(
    state: ClinicianState,
    action: ITF.JobRoleByIdSuccessAction
): ClinicianState {
    return {
        ...state,
        loading: false,
        search: {
            ...state.search,
            jobRoleId: action.payload.jobRole.id,
        },
    };
}

function isInsuranceAgreementListSuccessAction(
    action: ITF.ClinicianActionTypes
): action is ITF.InsuranceAgreementListSuccessAction {
    return action.type === CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.SUCCESS;
}

function handleInsuranceAgreementListSuccess(
    state: ClinicianState,
    action: ITF.InsuranceAgreementListSuccessAction
): ClinicianState {
    return {
        ...state,
        loading: false,
        insuranceAgreements: action.payload.insuranceAgreements,
    };
}

export const cliniciansReducer: Reducer<
    ClinicianState,
    ITF.ClinicianActionTypes
> = (state = initialState, action) => {
    // INCOMING REQUEST ACTIONS
    if (isClinicianListRequestAction(action)) {
        return handleClinicianListRequestAction(state);
    }

    if (isRequestAction(action)) {
        return handleRequestAction(state);
    }
    if (isClinicianListRequestAction(action)) {
        return handleClinicianListRequestAction(state);
    }

    // INCOMING SUCCESS ACTIONS
    if (isClinicianListSuccessAction(action)) {
        return handleClinicianListSuccess(state, action);
    }
    if (isClinicianByIdSuccessAction(action)) {
        return handleClinicianByIdSuccess(state, action);
    }
    if (isUserCrpsGetSuccessAction(action)) {
        return handleUserCrpsGetSuccess(state, action);
    }
    if (isClinicsListSuccessAction(action)) {
        return handleClinicsListSuccess(state, action);
    }
    if (isJobRoleListSuccessAction(action)) {
        return handleJobRoleListSuccess(state, action);
    }
    if (isJobRoleByIdSuccessAction(action)) {
        return handleJobRoleByIdSuccess(state, action);
    }
    if (isInsuranceAgreementListSuccessAction(action)) {
        return handleInsuranceAgreementListSuccess(state, action);
    }

    // INCOMING FAIL ACTIONS
    if (isClinicianListFailureAction(action)) {
        return handleClinicianListFailureAction(state);
    }
    if (isCrpsFailureAction(action)) {
        return handleCrpsFailureAction(state);
    }
    if (isFailureAction(action)) {
        return handleFailureAction(state);
    }
    return state;
};
