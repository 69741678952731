/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveClinicianProfileRequest
 */
export interface RemoveClinicianProfileRequest {
    /**
     * 
     * @type {number}
     * @memberof RemoveClinicianProfileRequest
     */
    clinicId: number;
    /**
     * 
     * @type {number}
     * @memberof RemoveClinicianProfileRequest
     */
    userProfileId: number;
}

/**
 * Check if a given object implements the RemoveClinicianProfileRequest interface.
 */
export function instanceOfRemoveClinicianProfileRequest(value: object): value is RemoveClinicianProfileRequest {
    if (!('clinicId' in value) || value['clinicId'] === undefined) return false;
    if (!('userProfileId' in value) || value['userProfileId'] === undefined) return false;
    return true;
}

export function RemoveClinicianProfileRequestFromJSON(json: any): RemoveClinicianProfileRequest {
    return RemoveClinicianProfileRequestFromJSONTyped(json, false);
}

export function RemoveClinicianProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveClinicianProfileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clinicId': json['clinicId'],
        'userProfileId': json['userProfileId'],
    };
}

export function RemoveClinicianProfileRequestToJSON(json: any): RemoveClinicianProfileRequest {
    return RemoveClinicianProfileRequestToJSONTyped(json, false);
}

export function RemoveClinicianProfileRequestToJSONTyped(value?: RemoveClinicianProfileRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinicId': value['clinicId'],
        'userProfileId': value['userProfileId'],
    };
}

