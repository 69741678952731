/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CliniciansListForAdminResponse,
  CreateAdminUserRequest,
  CreateAdminUserResponse,
  GetPlatformStatisticsResponse,
  InsurerListForAdminResponse,
  PatientsListForAdminResponse,
  ReferralsListForAdminResponse,
  SetUserVerificationRequest,
  SetUserVerificationResponse,
  UserListForAdminResponse,
} from '../models/index';
import {
    CliniciansListForAdminResponseFromJSON,
    CliniciansListForAdminResponseToJSON,
    CreateAdminUserRequestFromJSON,
    CreateAdminUserRequestToJSON,
    CreateAdminUserResponseFromJSON,
    CreateAdminUserResponseToJSON,
    GetPlatformStatisticsResponseFromJSON,
    GetPlatformStatisticsResponseToJSON,
    InsurerListForAdminResponseFromJSON,
    InsurerListForAdminResponseToJSON,
    PatientsListForAdminResponseFromJSON,
    PatientsListForAdminResponseToJSON,
    ReferralsListForAdminResponseFromJSON,
    ReferralsListForAdminResponseToJSON,
    SetUserVerificationRequestFromJSON,
    SetUserVerificationRequestToJSON,
    SetUserVerificationResponseFromJSON,
    SetUserVerificationResponseToJSON,
    UserListForAdminResponseFromJSON,
    UserListForAdminResponseToJSON,
} from '../models/index';

export interface AdminNewAdminUserCreateRequest {
    createAdminUserRequest: CreateAdminUserRequest;
}

export interface AdminSetVerificationUserProfileCreateRequest {
    userProfileId: number;
    setUserVerificationRequest: SetUserVerificationRequest;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     * Returns a list of clinician profiles for logged in admin users.
     */
    async adminClinicianRoleProfilesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CliniciansListForAdminResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/clinician-role-profiles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CliniciansListForAdminResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of clinician profiles for logged in admin users.
     */
    async adminClinicianRoleProfilesRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CliniciansListForAdminResponse> {
        const response = await this.adminClinicianRoleProfilesRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async adminCrpsListDownloadRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/crps-list-download/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async adminCrpsListDownloadRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.adminCrpsListDownloadRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Return a list of insurers.
     */
    async adminInsuranceAgreementsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InsurerListForAdminResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/insurance-agreements/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsurerListForAdminResponseFromJSON(jsonValue));
    }

    /**
     * Return a list of insurers.
     */
    async adminInsuranceAgreementsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InsurerListForAdminResponse> {
        const response = await this.adminInsuranceAgreementsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async adminInsurersListDownloadRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/insurers-list-download/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async adminInsurersListDownloadRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.adminInsurersListDownloadRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates the users password while logged in
     */
    async adminNewAdminUserCreateRaw(requestParameters: AdminNewAdminUserCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateAdminUserResponse>> {
        if (requestParameters['createAdminUserRequest'] == null) {
            throw new runtime.RequiredError(
                'createAdminUserRequest',
                'Required parameter "createAdminUserRequest" was null or undefined when calling adminNewAdminUserCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/new-admin-user/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAdminUserRequestToJSON(requestParameters['createAdminUserRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateAdminUserResponseFromJSON(jsonValue));
    }

    /**
     * Updates the users password while logged in
     */
    async adminNewAdminUserCreate(requestParameters: AdminNewAdminUserCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateAdminUserResponse> {
        const response = await this.adminNewAdminUserCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async adminPatientsListDownloadRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/patients-list-download/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async adminPatientsListDownloadRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.adminPatientsListDownloadRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns list of patients for logged in admin user
     */
    async adminPatientsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientsListForAdminResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/patients/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientsListForAdminResponseFromJSON(jsonValue));
    }

    /**
     * Returns list of patients for logged in admin user
     */
    async adminPatientsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientsListForAdminResponse> {
        const response = await this.adminPatientsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get platform statistics.
     */
    async adminPlatformStatsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPlatformStatisticsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/platform-stats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPlatformStatisticsResponseFromJSON(jsonValue));
    }

    /**
     * Get platform statistics.
     */
    async adminPlatformStatsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPlatformStatisticsResponse> {
        const response = await this.adminPlatformStatsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async adminReferralsListDownloadRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/referrals-list-download/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async adminReferralsListDownloadRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.adminReferralsListDownloadRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns referral lists for the logged in admin user
     */
    async adminReferralsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralsListForAdminResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/referrals/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralsListForAdminResponseFromJSON(jsonValue));
    }

    /**
     * Returns referral lists for the logged in admin user
     */
    async adminReferralsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralsListForAdminResponse> {
        const response = await this.adminReferralsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set user verification status.
     */
    async adminSetVerificationUserProfileCreateRaw(requestParameters: AdminSetVerificationUserProfileCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetUserVerificationResponse>> {
        if (requestParameters['userProfileId'] == null) {
            throw new runtime.RequiredError(
                'userProfileId',
                'Required parameter "userProfileId" was null or undefined when calling adminSetVerificationUserProfileCreate().'
            );
        }

        if (requestParameters['setUserVerificationRequest'] == null) {
            throw new runtime.RequiredError(
                'setUserVerificationRequest',
                'Required parameter "setUserVerificationRequest" was null or undefined when calling adminSetVerificationUserProfileCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/set-verification/user-profile/{user_profile_id}/`.replace(`{${"user_profile_id"}}`, encodeURIComponent(String(requestParameters['userProfileId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetUserVerificationRequestToJSON(requestParameters['setUserVerificationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetUserVerificationResponseFromJSON(jsonValue));
    }

    /**
     * Set user verification status.
     */
    async adminSetVerificationUserProfileCreate(requestParameters: AdminSetVerificationUserProfileCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetUserVerificationResponse> {
        const response = await this.adminSetVerificationUserProfileCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a list of users to logged in admin users.
     */
    async adminUserProfilesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserListForAdminResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/user-profiles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListForAdminResponseFromJSON(jsonValue));
    }

    /**
     * Return a list of users to logged in admin users.
     */
    async adminUserProfilesRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserListForAdminResponse> {
        const response = await this.adminUserProfilesRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async adminUsersListDownloadRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: any; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/admin/users-list-download/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async adminUsersListDownloadRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: any; }> {
        const response = await this.adminUsersListDownloadRetrieveRaw(initOverrides);
        return await response.value();
    }

}
