import {Referral} from '@/generated-client';

export enum ReferralInboundTabs {
    PENDING = 'inboundPending',
    ACCEPTED = 'inboundAccepted',
    BOOKED = 'inboundBooked',
    DNA = 'inboundDna',
    OPEN = 'openReferrals',
}

export const tabLabels: Record<ReferralInboundTabs, string> = {
    [ReferralInboundTabs.PENDING]: 'New Referrals',
    [ReferralInboundTabs.OPEN]: 'Open Referrals',
    [ReferralInboundTabs.ACCEPTED]: 'Accepted',
    [ReferralInboundTabs.BOOKED]: 'Booked',
    [ReferralInboundTabs.DNA]: 'DNA',
};

export const tabOptions = Object.entries(tabLabels).map(([value, label]) => ({
    value,
    label,
}));

export interface useReferralInboundScreenReturn {
    selectedTab: ReferralInboundTabs;
    setSelectedTab: (tab: ReferralInboundTabs) => void;
    inboundPending: Referral[];
    inboundAccepted: Referral[];
    inboundBooked: Referral[];
    inboundDna: Referral[];
    referralsOpen: Referral[];
}
