import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {Tabs, Tab} from 'react-bootstrap';
import ReferralListDisplay from '@/components/referrals/ReferralListDisplay';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import ReferralListTabsDropDown from '@/components/referrals/ReferralListTabsDropDown';
import {useReferralService} from '@/actions';
import {RootState} from '@/reducers';
import {Referral} from '@/generated-client';

enum MainTabs {
    OUTBOUND = 'outboundReferrals',
    INBOUND = 'inboundReferrals',
    OPEN = 'openReferrals',
}

const mainTabLabels: Record<MainTabs, string> = {
    [MainTabs.OUTBOUND]: 'Referrals Made',
    [MainTabs.INBOUND]: 'Referrals Received',
    [MainTabs.OPEN]: 'Open Referrals',
};

enum ReferralTabs {
    WAITING = 'rxWaiting',
    ACCEPTED = 'rxAccepted',
    BOOKED = 'rxBooked',
    DNA = 'rxDna',
}

const tabLabels: Record<ReferralTabs, string> = {
    [ReferralTabs.WAITING]: 'Pending',
    [ReferralTabs.ACCEPTED]: 'Accepted',
    [ReferralTabs.BOOKED]: 'Booked',
    [ReferralTabs.DNA]: 'DNA',
};

const rxListHeaders = {
    [ReferralTabs.WAITING]: ['Patient', 'DOB', 'Sex', 'Clinician', ''],
    [ReferralTabs.ACCEPTED]: ['Patient', 'DOB', 'Sex', 'Clinician', ''],
    [ReferralTabs.BOOKED]: ['Patient', 'DOB', 'Sex', 'Clinician', ''],
    [ReferralTabs.DNA]: ['Patient', 'DOB', 'Sex', 'Clinician', ''],
    [MainTabs.OPEN]: ['Patient', 'DOB', 'Sex', 'Clinician', ''],
};

interface ReferralSubsectionProps {
    subTab: ReferralTabs;
    updateTab: (tab: ReferralTabs) => void;
    waiting: Referral[];
    accepted: Referral[];
    booked: Referral[];
    dna: Referral[];
}

const ReferralSubsection: React.FC<ReferralSubsectionProps> = ({
    subTab,
    updateTab,
    waiting,
    accepted,
    booked,
    dna,
}) => {
    const {isNarrow} = useScreenInfo();

    const renderContent = useCallback(() => {
        switch (subTab) {
            case ReferralTabs.WAITING:
                return (
                    <ReferralListDisplay
                        referralsList={waiting}
                        headers={rxListHeaders[ReferralTabs.WAITING]}
                    />
                );
            case ReferralTabs.ACCEPTED:
                return (
                    <ReferralListDisplay
                        referralsList={accepted}
                        headers={rxListHeaders[ReferralTabs.ACCEPTED]}
                    />
                );
            case ReferralTabs.BOOKED:
                return (
                    <ReferralListDisplay
                        referralsList={booked}
                        headers={rxListHeaders[ReferralTabs.BOOKED]}
                    />
                );
            case ReferralTabs.DNA:
                return (
                    <ReferralListDisplay
                        referralsList={dna}
                        headers={rxListHeaders[ReferralTabs.DNA]}
                    />
                );
            default:
                return null;
        }
    }, [subTab, waiting, accepted, booked, dna]);

    const tabOptions = useMemo(
        () =>
            Object.entries(tabLabels).map(([value, label]) => ({
                value,
                label,
            })),
        []
    );
    const setDefaultTab = useCallback(
        (tab: ReferralTabs) => {
            updateTab(tab);
        },
        [updateTab]
    );

    if (isNarrow) {
        return (
            <div className="patient-list">
                <h6 className="text-left p-1">Select referral group</h6>
                <ReferralListTabsDropDown
                    viewName={tabLabels[subTab]}
                    updateView={setDefaultTab}
                    viewOptions={tabOptions}
                />
                {renderContent()}
            </div>
        );
    }

    return (
        <div className="referral-list">
            <Tabs
                activeKey={subTab}
                onSelect={(k) => setDefaultTab(k as ReferralTabs)}
                id="referrals-tab"
                className="custom-tabs mb-3"
            >
                {Object.entries(tabLabels).map(([key, label]) => (
                    <Tab
                        key={key}
                        eventKey={key}
                        title={label}
                        className="custom-tabs"
                    >
                        {key === subTab && renderContent()}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
};

interface SelectedTab {
    main: MainTabs;
    sub: ReferralTabs;
}

const ReferralsListScreen: React.FC = () => {
    const inboundWaiting = useSelector(
        (state: RootState) => state.referrals.referralsReceived.waiting
    );
    const inboundAccepted = useSelector(
        (state: RootState) => state.referrals.referralsReceived.accepted
    );
    const inboundBooked = useSelector(
        (state: RootState) => state.referrals.referralsReceived.booked
    );
    const inboundDna = useSelector(
        (state: RootState) => state.referrals.referralsReceived.dna
    );

    const outboundWaiting = useSelector(
        (state: RootState) => state.referrals.referralsMade.waiting
    );
    const outboundAccepted = useSelector(
        (state: RootState) => state.referrals.referralsMade.accepted
    );
    const outboundBooked = useSelector(
        (state: RootState) => state.referrals.referralsMade.booked
    );
    const outboundDna = useSelector(
        (state: RootState) => state.referrals.referralsMade.dna
    );
    const referralsOpen = useSelector(
        (state: RootState) => state.referrals.referralsOpen
    );

    const referralService = useReferralService();

    const [defaultTab, setDefaultTab] = useState<SelectedTab>({
        main: MainTabs.INBOUND,
        sub: ReferralTabs.WAITING,
    });
    const [searchParams] = useSearchParams();

    const searchKeys = {
        sent: {main: MainTabs.OUTBOUND, sub: ReferralTabs.WAITING},
        pending: {main: MainTabs.INBOUND, sub: ReferralTabs.WAITING},
        open: {main: MainTabs.OPEN, sub: ReferralTabs.WAITING},
        accepted: {main: MainTabs.INBOUND, sub: ReferralTabs.ACCEPTED},
    };

    useEffect(() => {
        const tab = searchParams.get('tab');
        if (tab in searchKeys) {
            setDefaultTab(searchKeys[tab as keyof typeof searchKeys]);
        }
    }, [searchParams]);

    const handleReferralsList = useCallback(async () => {
        await referralService.getClinicianReferralsList();
    }, []);

    useEffect(() => {
        handleReferralsList();
        window.scroll(0, 0);
    }, [handleReferralsList]);

    return (
        <div className="referral-list">
            <Tabs
                activeKey={defaultTab.main}
                onSelect={(k) =>
                    setDefaultTab({
                        main: k,
                        sub: ReferralTabs.WAITING,
                    } as SelectedTab)
                }
                id="referrals-tab"
                className="custom-tabs mb-3"
            >
                <Tab
                    key="mainInbound"
                    eventKey={MainTabs.INBOUND}
                    title={mainTabLabels[MainTabs.INBOUND]}
                    className="custom-tabs"
                >
                    <ReferralSubsection
                        subTab={defaultTab.sub}
                        updateTab={(tab) =>
                            setDefaultTab({main: MainTabs.INBOUND, sub: tab})
                        }
                        waiting={inboundWaiting}
                        accepted={inboundAccepted}
                        booked={inboundBooked}
                        dna={inboundDna}
                    />
                </Tab>
                <Tab
                    key="openReferrals"
                    eventKey={MainTabs.OPEN}
                    title={mainTabLabels[MainTabs.OPEN]}
                    className="custom-tabs"
                >
                    <ReferralListDisplay
                        referralsList={referralsOpen}
                        headers={rxListHeaders[MainTabs.OPEN]}
                    />
                </Tab>
                <Tab
                    key="mainOutbound"
                    eventKey={MainTabs.OUTBOUND}
                    title={mainTabLabels[MainTabs.OUTBOUND]}
                    className="custom-tabs"
                >
                    <ReferralSubsection
                        subTab={defaultTab.sub}
                        updateTab={(tab) =>
                            setDefaultTab({main: MainTabs.OUTBOUND, sub: tab})
                        }
                        waiting={outboundWaiting}
                        accepted={outboundAccepted}
                        booked={outboundBooked}
                        dna={outboundDna}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default ReferralsListScreen;
