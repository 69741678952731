import React, {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Tabs, Tab} from 'react-bootstrap';
import ReferralListDisplay from '@/components/referrals/ReferralListDisplay';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import ReferralListTabsDropDown from '@/components/referrals/ReferralListTabsDropDown';
import useReferralInboundScreen from './hooks';
import {ReferralOutboundTabs, tabLabels, tabOptions} from './interface';
import HelpIcon, {GroupsHelpModal} from '@components/app/parts/HelpIcon';

const ReferralOutboundScreen: React.FC = () => {
    const {isNarrow} = useScreenInfo();
    const [searchParams] = useSearchParams();
    const [showHelp, setShowHelp] = React.useState(false);
    const toggleHelp = () => setShowHelp((prev) => !prev);

    let searchTab = ReferralOutboundTabs.PENDING;
    const tab = searchParams.get('referralType');
    const tabEnum: ReferralOutboundTabs | null = tab
        ? Object.values(ReferralOutboundTabs).find((value) => value === tab)
        : null;

    if (tabEnum) {
        searchTab = tabEnum;
    }

    const {
        selectedTab,
        setSelectedTab,
        outboundWaiting,
        outboundAccepted,
        outboundBooked,
        outboundDna,
    } = useReferralInboundScreen(searchTab);

    const rxListHeaders = {
        [ReferralOutboundTabs.PENDING]: [
            'Patient',
            'DOB',
            'Sex',
            'Target Clinician',
            '',
        ],
        [ReferralOutboundTabs.ACCEPTED]: [
            'Patient',
            'DOB',
            'Sex',
            'Target Clinician',
            '',
        ],
        [ReferralOutboundTabs.BOOKED]: [
            'Patient',
            'DOB',
            'Sex',
            'Target Clinician',
            '',
        ],
        [ReferralOutboundTabs.DNA]: [
            'Patient',
            'DOB',
            'Sex',
            'Target Clinician',
            '',
        ],
    };

    const renderContent = useCallback(() => {
        switch (selectedTab) {
            case ReferralOutboundTabs.PENDING:
                return (
                    <ReferralListDisplay
                        referralsList={outboundWaiting}
                        headers={rxListHeaders[ReferralOutboundTabs.PENDING]}
                    />
                );
            case ReferralOutboundTabs.ACCEPTED:
                return (
                    <ReferralListDisplay
                        referralsList={outboundAccepted}
                        headers={rxListHeaders[ReferralOutboundTabs.ACCEPTED]}
                    />
                );
            case ReferralOutboundTabs.BOOKED:
                return (
                    <ReferralListDisplay
                        referralsList={outboundBooked}
                        headers={rxListHeaders[ReferralOutboundTabs.BOOKED]}
                    />
                );
            case ReferralOutboundTabs.DNA:
                return (
                    <ReferralListDisplay
                        referralsList={outboundDna}
                        headers={rxListHeaders[ReferralOutboundTabs.DNA]}
                    />
                );
            default:
                return null;
        }
    }, [
        outboundWaiting,
        outboundAccepted,
        outboundBooked,
        outboundDna,
        selectedTab,
    ]);

    const groupPoints = [
        {
            label: 'Pending',
            description:
                'Referrals that you have made and the target clinicians have not yet accepted or rejected. Open referrals you have made will also be displayed here.',
        },
        {
            label: 'Accepted',
            description:
                'Referrals that have made and a clinician has accepted but not yet booked.',
        },
        {
            label: 'Booked',
            description:
                'Referrals that have made and a clinician has booked a consultation.',
        },
        {
            label: 'Did Not Attend (DNA)',
            description:
                'Referrals that have made and a patient has not attended or canceled their referral to the target clinician.',
        },
    ];
    const helpModal = (
        <GroupsHelpModal
            showHelp={showHelp}
            toggleHelp={toggleHelp}
            title="Referrals Made Information"
            summary="Referrals are grouped based on their status. The
                        following groups are available:"
            groupPoints={groupPoints}
        />
    );

    if (isNarrow) {
        return (
            <div className="patient-list">
                {helpModal}
                <h6 className="text-left p-1 d-flex justify-content-between align-items-center">
                    <span>Select referral group</span>
                    <HelpIcon onClick={toggleHelp} />
                </h6>{' '}
                <ReferralListTabsDropDown
                    viewName={tabLabels[selectedTab]}
                    updateView={setSelectedTab}
                    viewOptions={tabOptions}
                />
                {renderContent()}
            </div>
        );
    }

    return (
        <>
            <h1 className="text-center">Referrals Made</h1>
            <div className="nav-tabs-with-help-container">
                {helpModal}
                <div className="referral-list">
                    <Tabs
                        activeKey={selectedTab}
                        onSelect={(k) =>
                            setSelectedTab(k as ReferralOutboundTabs)
                        }
                        id="referrals-tab"
                        className="custom-tabs mb-3"
                    >
                        {Object.entries(tabLabels).map(([key, label]) => (
                            <Tab
                                key={key}
                                eventKey={key}
                                title={label}
                                className="custom-tabs"
                            >
                                {key === selectedTab && renderContent()}
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                <div className="help-icon-container-navtabs">
                    <HelpIcon onClick={toggleHelp} />
                </div>
            </div>
        </>
    );
};

export default ReferralOutboundScreen;
