/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationsSettings
 */
export interface NotificationsSettings {
    /**
     * 
     * @type {number}
     * @memberof NotificationsSettings
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof NotificationsSettings
     */
    readonly createdOn: Date;
    /**
     * 
     * @type {Date}
     * @memberof NotificationsSettings
     */
    readonly updatedOn: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettings
     */
    userCreatedReferral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettings
     */
    userReceivedReferral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettings
     */
    targetHasToggledReferral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettings
     */
    userHasToggledReferral?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettings
     */
    newOpenReferral?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotificationsSettings
     */
    userProfile: number;
}

/**
 * Check if a given object implements the NotificationsSettings interface.
 */
export function instanceOfNotificationsSettings(value: object): value is NotificationsSettings {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdOn' in value) || value['createdOn'] === undefined) return false;
    if (!('updatedOn' in value) || value['updatedOn'] === undefined) return false;
    if (!('userProfile' in value) || value['userProfile'] === undefined) return false;
    return true;
}

export function NotificationsSettingsFromJSON(json: any): NotificationsSettings {
    return NotificationsSettingsFromJSONTyped(json, false);
}

export function NotificationsSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdOn': (new Date(json['createdOn'])),
        'updatedOn': (new Date(json['updatedOn'])),
        'userCreatedReferral': json['userCreatedReferral'] == null ? undefined : json['userCreatedReferral'],
        'userReceivedReferral': json['userReceivedReferral'] == null ? undefined : json['userReceivedReferral'],
        'targetHasToggledReferral': json['targetHasToggledReferral'] == null ? undefined : json['targetHasToggledReferral'],
        'userHasToggledReferral': json['userHasToggledReferral'] == null ? undefined : json['userHasToggledReferral'],
        'newOpenReferral': json['newOpenReferral'] == null ? undefined : json['newOpenReferral'],
        'userProfile': json['userProfile'],
    };
}

export function NotificationsSettingsToJSON(json: any): NotificationsSettings {
    return NotificationsSettingsToJSONTyped(json, false);
}

export function NotificationsSettingsToJSONTyped(value?: Omit<NotificationsSettings, 'id'|'createdOn'|'updatedOn'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userCreatedReferral': value['userCreatedReferral'],
        'userReceivedReferral': value['userReceivedReferral'],
        'targetHasToggledReferral': value['targetHasToggledReferral'],
        'userHasToggledReferral': value['userHasToggledReferral'],
        'newOpenReferral': value['newOpenReferral'],
        'userProfile': value['userProfile'],
    };
}

