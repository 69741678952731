import {useEffect, useState, useCallback, useMemo} from 'react';
import {getClinicCenterStr} from '@/actions/general';
import {debounce} from 'lodash';
import {useClinicianService} from '@/actions';
import {SendHoveredClinicianProps} from '@/hooks/clinicians/interfaces';
import {FullClinicianRoleProfile} from '@/hooks/clinicians/interfaces';
interface UseClinicianCardProps {
    clinician: FullClinicianRoleProfile;
    hoveredClinicianUserProfileIds: number[];
    updateHovered: (data: SendHoveredClinicianProps) => void;
}

interface UseClinicianCardReturn {
    displayPopup: boolean;
    togglePopup: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    clinicianIsHovered: boolean;
    nClinics: number;
    handleShowClinicsLink: (e: React.MouseEvent) => void;
    showAllClinicsModal: boolean;
    toggleAllClinicsModal: () => void;
    goToDirectReferral: (e: React.MouseEvent) => void;
    clinicNames: string[];
    showClinicNames: boolean;
    toggleClinicNames: (e: React.MouseEvent) => void;
}

const useClinicianCard = ({
    clinician,
    hoveredClinicianUserProfileIds,
    updateHovered,
}: UseClinicianCardProps): UseClinicianCardReturn => {
    const clinicianService = useClinicianService();

    const [displayPopup, setDisplayPopup] = useState<boolean>(false);
    const [clinicianIsHovered, setClinicianIsHovered] =
        useState<boolean>(false);
    const [showAllClinicsModal, setShowAllClinicsModal] =
        useState<boolean>(false);
    const [clinicNames, setClinicNames] = useState<string[]>([]);
    const [showClinicNames, setShowClinicNames] = useState<boolean>(false);

    const {clinicAddressIds} = clinician;

    const toggleAllClinicsModal = useCallback(
        () => setShowAllClinicsModal((state) => !state),
        []
    );

    const nClinics = useMemo(
        () => (clinician.otherClinics ? clinician.otherClinics.length + 1 : 1),
        [clinician.otherClinics]
    );

    const togglePopup = useCallback(
        () => setDisplayPopup((prevState) => !prevState),
        []
    );

    const getClinicNames = useCallback(() => {
        const names = [getClinicCenterStr(clinician.clinic)];
        if (clinician.otherClinics && clinician.otherClinics.length > 0) {
            const otherClinics = clinician.otherClinics.map((crp) =>
                getClinicCenterStr(crp.clinic)
            );
            names.push(...otherClinics);
        }
        setClinicNames(names);
    }, [clinician]);

    const toggleClinicNames = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowClinicNames((state) => !state);
    }, []);

    useEffect(() => {
        getClinicNames();
    }, [getClinicNames]);

    const onMouseEnter = useMemo(
        () =>
            debounce(
                () =>
                    updateHovered({
                        hoveredClinicianUserProfileIds: [
                            clinician.userProfile.id,
                        ],
                        hoveredAddressIds: clinicAddressIds as number[],
                    }),
                100
            ),
        [clinician, clinicAddressIds, updateHovered]
    );

    const onMouseLeave = useMemo(
        () =>
            debounce(
                () =>
                    updateHovered({
                        hoveredClinicianUserProfileIds: [],
                        hoveredAddressIds: [],
                    }),
                100
            ),
        [updateHovered]
    );

    const checkIfClinicianIsHovered = useCallback(() => {
        if (!hoveredClinicianUserProfileIds) {
            setClinicianIsHovered(false);
            return;
        }
        const hovered = hoveredClinicianUserProfileIds.includes(
            clinician.userProfile.id
        );
        setClinicianIsHovered(hovered);
    }, [hoveredClinicianUserProfileIds, clinician.userProfile.id]);

    useEffect(() => {
        checkIfClinicianIsHovered();
    }, [checkIfClinicianIsHovered]);

    const handleShowClinicsLink = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            toggleAllClinicsModal();
        },
        [toggleAllClinicsModal]
    );

    const goToDirectReferral = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            clinicianService.goToReferToClinician(clinician.id);
        },
        [clinician.id, clinicianService]
    );

    return {
        displayPopup,
        togglePopup,
        onMouseEnter,
        onMouseLeave,
        clinicianIsHovered,
        nClinics,
        handleShowClinicsLink,
        showAllClinicsModal,
        toggleAllClinicsModal,
        goToDirectReferral,
        clinicNames,
        showClinicNames,
        toggleClinicNames,
    };
};

export default useClinicianCard;
