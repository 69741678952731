import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';

interface FilterFields {
    jobRoleId: number;
    jobRoleSelected?: string;
    jobSpecialtySelected?: string;
    insuranceProviderId: number;
    searchLatitude: number;
    searchLongitude: number;
}

interface UseFilteredClinicians {
    filterFields: FilterFields;
    updateFilterFields: (data: Partial<FilterFields>) => void;
}

const useFilteredClinicians = (jobRoleId: number): UseFilteredClinicians => {
    const lat = useSelector((state: RootState) => state.search.lat);
    const lng = useSelector((state: RootState) => state.search.lng);
    const initData = {
        jobRoleId: jobRoleId,
        insuranceProviderId: 0,
        searchLatitude: lat,
        searchLongitude: lng,
    };
    const [filterFields, setFilterFields] = useState(initData);

    const updateFilterFields = (data) => {
        data = {
            ...data,
            jobRoleSelected: data.selectedJobRole,
            jobSpecialtySelected: data.selectedJobSpecialty,
        };
        delete data['selectedJobRole'];
        delete data['selectedJobSpecialty'];
        setFilterFields((prev) => ({...prev, ...data}));
    };
    useEffect(() => {
        updateFilterFields({searchLatitude: lat, searchLongitude: lng});
    }, [lat, lng]);

    return {
        filterFields,
        updateFilterFields,
    };
};

export default useFilteredClinicians;
