import React, {useEffect, useState} from 'react';
import {Tabs, Tab, Button} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import VerifyClinicianPopup from './popups/VerifyClinicianPopup';
import {useAdminService, DownloadService} from '@/actions';
import Loader from '@components/app/Loader';
import {RootState} from '@/reducers';
import {UserProfile} from '@/generated-client';
import AdminPagTable, {SliceIndexes} from './AdminPaginatedTable';
import {initSliceIndex, formatId} from './PagTableConsts';

enum UserTabs {
    UNVERIFIED_USERS = 'unverifiedUsers',
    USERS = 'users',
    DEACTIVATED_USERS = 'deactivatedUsers',
    OPERATIONS_USERS = 'operationsUsers',
}

const AdminUsersTable: React.FC = () => {
    const adminService = useAdminService();

    useEffect(() => {
        const handleGetAdminUsers = async () => {
            await adminService.getAdminUsersList();
        };
        handleGetAdminUsers();
    }, []);

    const usersList = useSelector(
        (state: RootState) => state.admin.users.users
    );
    const unverifiedUsersList = useSelector(
        (state: RootState) => state.admin.users.unverifiedUsers
    );
    const deactivatedUsersList = useSelector(
        (state: RootState) => state.admin.users.deactivatedUsers
    );
    const operationsUsersList = useSelector(
        (state: RootState) => state.admin.users.operationsUsers
    );

    const [userSlice, setUserSlice] = useState<SliceIndexes>(initSliceIndex);
    const [unverifiedUsersSlice, setUnverifiedUsersSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [deactivatedUsersSlice, setDeactivatedUsersSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [operationsUsersSlice, setOperationsUsersSlice] =
        useState<SliceIndexes>(initSliceIndex);

    const userTabTitles = {
        [UserTabs.USERS]: 'Users',
        [UserTabs.UNVERIFIED_USERS]: 'Unverified Users',
        [UserTabs.DEACTIVATED_USERS]: 'Deactivated Users',
        [UserTabs.OPERATIONS_USERS]: 'Operations Users',
    };

    const userTableHeaders = {
        [UserTabs.USERS]: ['ID', 'Name', 'Email', 'Staff', 'Unverify'],
        [UserTabs.UNVERIFIED_USERS]: ['ID', 'Name', 'Email', 'Staff', 'Verify'],
        [UserTabs.DEACTIVATED_USERS]: ['ID', 'Name', 'Email'],
        [UserTabs.OPERATIONS_USERS]: ['ID', 'Name'],
    };

    const userLists = {
        [UserTabs.USERS]: usersList,
        [UserTabs.UNVERIFIED_USERS]: unverifiedUsersList,
        [UserTabs.DEACTIVATED_USERS]: deactivatedUsersList,
        [UserTabs.OPERATIONS_USERS]: operationsUsersList,
    };
    const greenTick = <i className="fas fa-check" style={{color: 'green'}} />;
    const redCross = <i className="fas fa-times" style={{color: 'red'}} />;

    const createUserRow = (userProfile: UserProfile) => {
        return (
            <tr key={userProfile.id}>
                <td>{formatId(userProfile.id)}</td>
                <td>
                    {userProfile.firstName} {userProfile.lastName}
                </td>
                <td>{userProfile.user.email}</td>
                <td>{userProfile.user.isStaff ? greenTick : redCross}</td>
                <td>
                    <Button
                        id={`verifyClinician${userProfile.id}`}
                        variant="light"
                        className="btn-sm"
                        onClick={() =>
                            setClinicianToChangeVerification({
                                userProfile,
                                setVerified: false,
                            })
                        }
                    >
                        {redCross}
                    </Button>
                </td>
            </tr>
        );
    };

    const createUnverifiedUserRow = (userProfile: UserProfile) => {
        return (
            <tr key={userProfile.id}>
                <td>{formatId(userProfile.id)}</td>
                <td>
                    {userProfile.firstName} {userProfile.lastName}
                </td>
                <td>{userProfile.user.email}</td>
                <td>{userProfile.user.isStaff ? greenTick : redCross}</td>
                <td>
                    <Button
                        id={`verifyClinician${userProfile.id}`}
                        variant="light"
                        className="btn-sm"
                        onClick={() =>
                            setClinicianToChangeVerification({
                                userProfile,
                                setVerified: true,
                            })
                        }
                    >
                        {greenTick}
                    </Button>
                </td>
            </tr>
        );
    };

    const createDeactivatedUserRow = (userProfile: UserProfile) => (
        <tr key={userProfile.id}>
            <td>{formatId(userProfile.id)}</td>
            <td>
                {userProfile.firstName} {userProfile.lastName}
            </td>
            <td>{userProfile.user.email}</td>
        </tr>
    );

    const createOperationsUserRow = (userProfile: UserProfile) => (
        <tr key={userProfile.id}>
            <td>{userProfile.id}</td>
            <td>
                {userProfile.firstName} {userProfile.lastName}
            </td>
        </tr>
    );

    const userTableCreateRow = {
        [UserTabs.USERS]: createUserRow,
        [UserTabs.UNVERIFIED_USERS]: createUnverifiedUserRow,
        [UserTabs.DEACTIVATED_USERS]: createDeactivatedUserRow,
        [UserTabs.OPERATIONS_USERS]: createOperationsUserRow,
    };

    const tabSlices = {
        [UserTabs.USERS]: userSlice,
        [UserTabs.UNVERIFIED_USERS]: unverifiedUsersSlice,
        [UserTabs.DEACTIVATED_USERS]: deactivatedUsersSlice,
        [UserTabs.OPERATIONS_USERS]: operationsUsersSlice,
    };

    const tabSliceFunctions = {
        [UserTabs.USERS]: setUserSlice,
        [UserTabs.UNVERIFIED_USERS]: setUnverifiedUsersSlice,
        [UserTabs.DEACTIVATED_USERS]: setDeactivatedUsersSlice,
        [UserTabs.OPERATIONS_USERS]: setOperationsUsersSlice,
    };

    const [showVerificationPopup, setShowVerificationPopup] =
        useState<boolean>(false);
    const [clinicianToChangeVerification, setClinicianToChangeVerification] =
        useState<{userProfile: UserProfile; setVerified: boolean} | null>(null);

    useEffect(() => {
        if (!clinicianToChangeVerification) return;
        setShowVerificationPopup(true);
    }, [clinicianToChangeVerification]);

    const handleCloseVerificationPopup = () => {
        setShowVerificationPopup(false);
        setClinicianToChangeVerification(null);
    };

    const loadingView = (
        <>
            <div className="gap mt-5" />
            <Loader text="Loading admin users list..." />
        </>
    );

    const isLoading = adminService.requestLoading;
    const onExportClick = () => {
        const downloadService = new DownloadService();
        downloadService.downloadAdminUsersList();
    };
    return (
        <>
            {isLoading ? (
                loadingView
            ) : (
                <>
                    <Button
                        variant="info"
                        className="mb-3"
                        onClick={onExportClick}
                    >
                        Download Users List
                    </Button>
                    <Tabs
                        defaultActiveKey={UserTabs.USERS}
                        id="custom-tabs"
                        className="custom-tabs mb-3"
                    >
                        {Object.values(UserTabs).map((tabKey) => (
                            <Tab
                                eventKey={tabKey}
                                key={tabKey}
                                title={userTabTitles[tabKey]}
                                className="custom-tabs"
                            >
                                <AdminPagTable
                                    headers={userTableHeaders[tabKey]}
                                    items={userLists[tabKey]}
                                    createItemRows={userTableCreateRow[tabKey]}
                                    sliceIndex={tabSlices[tabKey]}
                                    updateSlice={tabSliceFunctions[tabKey]}
                                    emptyTableMessage={`No ${tabKey} to display at the moment`}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                    {showVerificationPopup && clinicianToChangeVerification && (
                        <VerifyClinicianPopup
                            userInfo={clinicianToChangeVerification}
                            showModal={showVerificationPopup}
                            handleClose={handleCloseVerificationPopup}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default AdminUsersTable;
