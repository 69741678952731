import {Dispatch} from 'redux';
import {NavigateFunction} from 'react-router-dom';
import {
    UpdateReferralContactsRequest,
    RequestHelpRequest,
    UpdateUserNotificationsSettingsRequest,
    UpdateUserProfileRequest,
    UserApi,
    ReferralsApi,
} from '@/generated-client';
import * as PRRED from '@/reducers/profileReducer';
import {MedrService} from './authService';

export class UserService extends MedrService {
    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
    }

    async loadUserProfile(): Promise<boolean> {
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userClinicianInformationRetrieve(),
            PRRED.dispatchLoadUserProfileRequest,
            PRRED.dispatchLoadUserProfileSuccess,
            PRRED.dispatchLoadUserProfileFail
        );
    }

    async getReferralNotifications(): Promise<boolean> {
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsReferralNotificationsRetrieve(),
            PRRED.dispatchGetReferralNotificationsRequest,
            PRRED.dispatchGetReferralNotificationsSuccess,
            PRRED.dispatchGetReferralNotificationsFail
        );
    }

    async updateProfile(inputData: UpdateUserProfileRequest): Promise<boolean> {
        const payload = {updateUserProfileRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userUpdateUpdate(payload),
            PRRED.dispatchUpdateProfileRequest,
            PRRED.dispatchUpdateProfileSuccess,
            PRRED.dispatchUpdateProfileFail
        );
    }

    async updateProfilePicture(imageString: string): Promise<boolean> {
        const payload = {image: imageString};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userProfilePicUploadCreate(payload),
            PRRED.dispatchUpdateProfilePictureRequest,
            PRRED.dispatchUpdateProfilePictureSuccess,
            PRRED.dispatchUpdateProfilePictureFail
        );
    }

    async updateUserNotificationsSettings(
        inputData: UpdateUserNotificationsSettingsRequest
    ): Promise<boolean> {
        const payload = {updateUserNotificationsSettingsRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userNotificationsUpdateUpdate(payload),
            PRRED.dispatchUpdateUserNotificationsSettingsRequest,
            PRRED.dispatchUpdateUserNotificationsSettingsSuccess,
            PRRED.dispatchUpdateUserNotificationsSettingsFail
        );
    }

    async getUserSummaryAnalytics(number: number): Promise<boolean> {
        const payload = {number};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userAnalyticsDataRetrieve(payload),
            PRRED.dispatchGetUserSummaryAnalyticsRequest,
            PRRED.dispatchGetUserSummaryAnalyticsSuccess,
            PRRED.dispatchGetUserSummaryAnalyticsFail
        );
    }

    async updateReferralContacts(
        inputData: UpdateReferralContactsRequest
    ): Promise<boolean> {
        const payload = {updateReferralContactsRequest: inputData};
        const api = new UserApi(this.configuration);

        return this.executeApiCall(
            () => api.userReferralContactsUpdateUpdate(payload),
            PRRED.dispatchUpdateReferralContactsRequest,
            PRRED.dispatchUpdateReferralContactsSuccess,
            PRRED.dispatchUpdateReferralContactsFail
        );
    }

    async requestHelp(inputData: RequestHelpRequest): Promise<boolean> {
        const payload = {requestHelpRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userRequestHelpCreate(payload),
            PRRED.dispatchRequestHelpRequest,
            PRRED.dispatchRequestHelpSuccess,
            PRRED.dispatchRequestHelpFail
        );
    }
}
