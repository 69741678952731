import {createContext, useContext} from 'react';
import {
    JobRole,
    ClinicianContact,
    InsuranceAgreement,
} from '@/generated-client';
import {ClinicFormData} from '@/components/clinicians/ClinicForm.interfaces';
import {NewInsuranceProvider} from '@/components/clinicians/InsuranceProviderDisplay/InsuranceProviderForm';

export type RegistrationClinicianContact = Omit<ClinicianContact, 'id'> & {
    isMain: boolean;
};

export type ExistingInsuranceProvider = InsuranceAgreement & {
    selected: boolean;
};

export interface SelectedJobrole {
    jobRole: JobRole;
    jobRoleId: number;
    selectedJobRole: string;
    selectedJobSpecialty: string;
    selectedJobSubSpecialty: string;
}

export interface RegistrationData {
    isReview: boolean;
    email: string;
    title: string;
    firstName: string;
    lastName: string;
    password: string;
    rePassword: string;
    jobRoleId: number;
    jobRoles: JobRole[];
    isDoctor: boolean;
    registrationNumber: string;
    registrationBody: string;
    dob: string;
    clinicianDescription: string;
    clinics: ClinicFormData[];
    appointmentsContacts: RegistrationClinicianContact[];
    existingProviders: ExistingInsuranceProvider[];
    newProviders: NewInsuranceProvider[];
}

export interface RegistrationContextType {
    activeStep: number;
    setNextStep: () => void;
    setPreviousStep: () => void;
    registrationData: RegistrationData;
    resetRegistrationData: () => void;
    updateRegistrationData: (data: Partial<RegistrationData>) => void;
    addJobRole: (jobRole: SelectedJobrole) => void;
    removeJobRole: (jobRole: JobRole) => void;
    addClinic: (clinic: ClinicFormData) => void;
    editClinic: (clinic: ClinicFormData) => void;
    removeClinic: (clinic: ClinicFormData) => void;
    updateAppointmentContacts: (
        contacts: RegistrationClinicianContact[]
    ) => void;
    updateExisitingProviders: (providers: ExistingInsuranceProvider[]) => void;
    updateNewProviders: (providers: NewInsuranceProvider[]) => void;
    onSubmit: (
        e:
            | React.MouseEventHandler<HTMLButtonElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => void;
    showError: boolean;
    toggleShowError: () => void;
}

export const RegistrationContext = createContext<
    RegistrationContextType | undefined
>(undefined);

export function useRegistrationContext() {
    const context = useContext(RegistrationContext);

    if (!context) {
        throw new Error(
            'useRegistrationContext must be used within a RegistrationProvider'
        );
    }

    return context;
}
