/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListCliniciansRequest,
  ListCliniciansResponse,
} from '../models/index';
import {
    ListCliniciansRequestFromJSON,
    ListCliniciansRequestToJSON,
    ListCliniciansResponseFromJSON,
    ListCliniciansResponseToJSON,
} from '../models/index';

export interface ClinicianListCreateRequest {
    listCliniciansRequest: ListCliniciansRequest;
}

/**
 * 
 */
export class ClinicianListApi extends runtime.BaseAPI {

    /**
     * Returns a list of clinicians, distance of each clinician is calculated using the serach GPS coordinates.
     */
    async clinicianListCreateRaw(requestParameters: ClinicianListCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCliniciansResponse>> {
        if (requestParameters['listCliniciansRequest'] == null) {
            throw new runtime.RequiredError(
                'listCliniciansRequest',
                'Required parameter "listCliniciansRequest" was null or undefined when calling clinicianListCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/clinician-list/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListCliniciansRequestToJSON(requestParameters['listCliniciansRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCliniciansResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of clinicians, distance of each clinician is calculated using the serach GPS coordinates.
     */
    async clinicianListCreate(requestParameters: ClinicianListCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCliniciansResponse> {
        const response = await this.clinicianListCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
