interface AgeCheckResult {
    age: number | null;
    isValid: boolean;
}

const CheckAgeOver18FromDob = (DOB: string | null): AgeCheckResult => {
    if (!DOB) return {age: null, isValid: false};

    const birthDateObject = new Date(DOB);
    const currentDate = new Date();
    const ageDifference =
        currentDate.getFullYear() - birthDateObject.getFullYear();

    if (Number.isNaN(ageDifference) || ageDifference < 0)
        return {age: null, isValid: false};

    return {age: ageDifference, isValid: ageDifference >= 18};
};

export default CheckAgeOver18FromDob;
