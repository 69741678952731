/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralPermissions
 */
export interface ReferralPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ReferralPermissions
     */
    permissionViewReferrals: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralPermissions
     */
    permissionCreateReferrals: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralPermissions
     */
    permissionManageReferrals: boolean;
}

/**
 * Check if a given object implements the ReferralPermissions interface.
 */
export function instanceOfReferralPermissions(value: object): value is ReferralPermissions {
    if (!('permissionViewReferrals' in value) || value['permissionViewReferrals'] === undefined) return false;
    if (!('permissionCreateReferrals' in value) || value['permissionCreateReferrals'] === undefined) return false;
    if (!('permissionManageReferrals' in value) || value['permissionManageReferrals'] === undefined) return false;
    return true;
}

export function ReferralPermissionsFromJSON(json: any): ReferralPermissions {
    return ReferralPermissionsFromJSONTyped(json, false);
}

export function ReferralPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralPermissions {
    if (json == null) {
        return json;
    }
    return {
        
        'permissionViewReferrals': json['permissionViewReferrals'],
        'permissionCreateReferrals': json['permissionCreateReferrals'],
        'permissionManageReferrals': json['permissionManageReferrals'],
    };
}

export function ReferralPermissionsToJSON(json: any): ReferralPermissions {
    return ReferralPermissionsToJSONTyped(json, false);
}

export function ReferralPermissionsToJSONTyped(value?: ReferralPermissions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'permissionViewReferrals': value['permissionViewReferrals'],
        'permissionCreateReferrals': value['permissionCreateReferrals'],
        'permissionManageReferrals': value['permissionManageReferrals'],
    };
}

