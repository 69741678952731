/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VerifyDelegateUserRequest,
  VerifyDelegateUserResponse,
} from '../models/index';
import {
    VerifyDelegateUserRequestFromJSON,
    VerifyDelegateUserRequestToJSON,
    VerifyDelegateUserResponseFromJSON,
    VerifyDelegateUserResponseToJSON,
} from '../models/index';

export interface VerifyDelegateUpdateRequest {
    verifyDelegateUserRequest: VerifyDelegateUserRequest;
}

/**
 * 
 */
export class VerifyDelegateApi extends runtime.BaseAPI {

    /**
     * Verify delegate user.
     */
    async verifyDelegateUpdateRaw(requestParameters: VerifyDelegateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VerifyDelegateUserResponse>> {
        if (requestParameters['verifyDelegateUserRequest'] == null) {
            throw new runtime.RequiredError(
                'verifyDelegateUserRequest',
                'Required parameter "verifyDelegateUserRequest" was null or undefined when calling verifyDelegateUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/verify-delegate/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyDelegateUserRequestToJSON(requestParameters['verifyDelegateUserRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifyDelegateUserResponseFromJSON(jsonValue));
    }

    /**
     * Verify delegate user.
     */
    async verifyDelegateUpdate(requestParameters: VerifyDelegateUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VerifyDelegateUserResponse> {
        const response = await this.verifyDelegateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
