import {Dispatch} from 'redux';
import {useSelector} from 'react-redux';
import {NavigateFunction} from 'react-router-dom';
import {
    Referral,
    CreateAdminUserRequest,
    AdminApi,
    InsuranceAgreementsApi,
    UserApi,
} from '@/generated-client';
import {RootState} from '@/reducers';
import * as AXRED from '@/reducers/adminReducer';
import {MedrService} from './authService';

export class AdminService extends MedrService {
    public requestLoading: boolean;

    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
        this.requestLoading = useSelector(
            (state: RootState) => state.admin.loading
        );
    }

    async addNewAdminUser(inputData: CreateAdminUserRequest): Promise<boolean> {
        const payload = {createAdminUserRequest: inputData};
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminNewAdminUserCreate(payload),
            AXRED.dispatchAddNewAdminUserRequest,
            AXRED.dispatchAddNewAdminUserSuccess,
            AXRED.dispatchAddNewAdminUserFailure
        );
    }

    async getPlatformStats(): Promise<boolean> {
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminPlatformStatsRetrieve(),
            AXRED.dispatchGetPlatformStatsRequest,
            AXRED.dispatchGetPlatformStatsSuccess,
            AXRED.dispatchGetPlatformStatsFailure
        );
    }

    async getAdminUsersList(): Promise<boolean> {
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminUserProfilesRetrieve(),
            AXRED.dispatchGetAdminUsersListRequest,
            AXRED.dispatchGetAdminUsersListSuccess,
            AXRED.dispatchGetAdminUsersListFailure
        );
    }

    async getAdminPatientsList(): Promise<boolean> {
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminPatientsRetrieve(),
            AXRED.dispatchGetAdminPatientsListRequest,
            AXRED.dispatchGetAdminPatientsListSuccess,
            AXRED.dispatchGetAdminPatientsListFailure
        );
    }

    async getAdminReferralsList(): Promise<boolean> {
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminReferralsRetrieve(),
            AXRED.dispatchGetAdminReferralsListRequest,
            AXRED.dispatchGetAdminReferralsListSuccess,
            AXRED.dispatchGetAdminReferralsListFailure
        );
    }

    async getAdminClinicansList(): Promise<boolean> {
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminClinicianRoleProfilesRetrieve(),
            AXRED.dispatchGetAdminCliniciansListRequest,
            AXRED.dispatchGetAdminCliniciansListSuccess,
            AXRED.dispatchGetAdminCliniciansListFailure
        );
    }

    async getAdminInsurers(): Promise<boolean> {
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminInsuranceAgreementsRetrieve(),
            AXRED.dispatchGetAdminInsurersRequest,
            AXRED.dispatchGetAdminInsurersSuccess,
            AXRED.dispatchGetAdminInsurersFailure
        );
    }

    async verifyInsurer(insId: number): Promise<boolean> {
        const payload = {id: insId};
        const api = new InsuranceAgreementsApi(this.configuration);
        return this.executeApiCall(
            () => api.insuranceAgreementsVerifyCreate(payload),
            AXRED.dispatchVerifyInsurerRequest,
            AXRED.dispatchVerifyInsurerSuccess,
            AXRED.dispatchVerifyInsurerFailure
        );
    }

    async setUserVerified(
        userId: number,
        setVerified: boolean
    ): Promise<boolean> {
        const payload = {
            userProfileId: userId,
            setUserVerificationRequest: {setVerified},
        };
        const api = new AdminApi(this.configuration);
        return this.executeApiCall(
            () => api.adminSetVerificationUserProfileCreate(payload),
            AXRED.dispatchSetUserVerifiedRequest,
            AXRED.dispatchSetUserVerifiedSuccess,
            AXRED.dispatchSetUserVerifiedFailure
        );
    }

    async requestNewFeature(suggestion: string): Promise<boolean> {
        if (!suggestion || suggestion.length === 0) {
            AXRED.dispatchRequestNewFeatureFailure(
                this.dispatch,
                'Suggestion cannot be empty'
            );
            return false;
        }
        const payload = {
            requestNewFeatureRequest: {suggestion},
        };
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userRequestFeatureCreate(payload),
            AXRED.dispatchRequestNewFeatureRequest,
            AXRED.dispatchRequestNewFeatureSuccess,
            AXRED.dispatchRequestNewFeatureFailure
        );
    }

    getReferralsSlice(
        referrals: Array<Referral>,
        start: number,
        end: number
    ): Array<Referral> {
        return referrals.slice(start, end);
    }
}
