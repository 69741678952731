/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';

/**
 * 
 * @export
 * @interface CreateAdminUserResponse
 */
export interface CreateAdminUserResponse {
    /**
     * 
     * @type {Message}
     * @memberof CreateAdminUserResponse
     */
    message: Message;
}

/**
 * Check if a given object implements the CreateAdminUserResponse interface.
 */
export function instanceOfCreateAdminUserResponse(value: object): value is CreateAdminUserResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function CreateAdminUserResponseFromJSON(json: any): CreateAdminUserResponse {
    return CreateAdminUserResponseFromJSONTyped(json, false);
}

export function CreateAdminUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAdminUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
    };
}

export function CreateAdminUserResponseToJSON(json: any): CreateAdminUserResponse {
    return CreateAdminUserResponseToJSONTyped(json, false);
}

export function CreateAdminUserResponseToJSONTyped(value?: CreateAdminUserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': MessageToJSON(value['message']),
    };
}

