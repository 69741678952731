import React from 'react';
import InsuranceSelectorGrid from '../InsuranceSelectorGrid/InsuranceSelectorGrid';
import useProfileChangeInsurance from './useProfileChangeInsurance';

const ProfileChangeInsurance: React.FC = () => {
    const {existingProviders, updateExistingProviders} =
        useProfileChangeInsurance();

    return (
        <InsuranceSelectorGrid
            currentAgreements={existingProviders}
            updateExisitingProviders={updateExistingProviders}
        />
    );
};

export default ProfileChangeInsurance;
