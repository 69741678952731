/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
    PatientToJSONTyped,
} from './Patient';

/**
 * 
 * @export
 * @interface PatientByIdResponse
 */
export interface PatientByIdResponse {
    /**
     * 
     * @type {Patient}
     * @memberof PatientByIdResponse
     */
    currentPatient: Patient;
}

/**
 * Check if a given object implements the PatientByIdResponse interface.
 */
export function instanceOfPatientByIdResponse(value: object): value is PatientByIdResponse {
    if (!('currentPatient' in value) || value['currentPatient'] === undefined) return false;
    return true;
}

export function PatientByIdResponseFromJSON(json: any): PatientByIdResponse {
    return PatientByIdResponseFromJSONTyped(json, false);
}

export function PatientByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientByIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'currentPatient': PatientFromJSON(json['currentPatient']),
    };
}

export function PatientByIdResponseToJSON(json: any): PatientByIdResponse {
    return PatientByIdResponseToJSONTyped(json, false);
}

export function PatientByIdResponseToJSONTyped(value?: PatientByIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'currentPatient': PatientToJSON(value['currentPatient']),
    };
}

