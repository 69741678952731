import React, {useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {RootState} from '@/reducers';
import {useInsuranceAgreementService} from '@/actions';
import {InsuranceAgreement} from '@/generated-client';
import {customStyles, ViewOption} from '@/constants/styles';

interface InsuranceAgreementSearchBarProps {
    agreement?: number;
    changeValues: (values: {[key: string]: string}) => void;
    valueKey?: string;
}

const InsuranceAgreementSearchBar: React.FC<
    InsuranceAgreementSearchBarProps
> = ({changeValues, agreement = -1, valueKey = 'insuranceProviderId'}) => {
    // load insurance agreements
    const insuranceService = useInsuranceAgreementService();
    const handleGetAgreements = async () => {
        await insuranceService.getInsuranceAgreements();
    };
    useEffect(() => {
        handleGetAgreements();
    }, []);

    function getInsuranceAgreementSearchLabel(
        insuranceAgreement: InsuranceAgreement
    ) {
        if (insuranceAgreement.policyName === 'Standard')
            return `${insuranceAgreement.provider}`;
        return `${insuranceAgreement.provider} (${insuranceAgreement.policyName})`;
    }

    // get options for insurance agreements list
    const insuranceAgreements = useSelector(
        (state: RootState) => state.clinicians.insuranceAgreements
    );

    const options: ViewOption[] = [
        {label: 'Self-payer', value: '0'},
        ...insuranceAgreements.map(
            (insuranceAgreement) =>
                ({
                    label: getInsuranceAgreementSearchLabel(insuranceAgreement),
                    value: insuranceAgreement.id.toString(),
                }) as ViewOption
        ),
    ];

    const getCurrentAgreement = () => {
        if (agreement === -1) return null;
        const agreementStr = agreement.toString();
        const newCurrentAgreement = options.find(
            (option) => option.value === agreementStr
        );
        if (newCurrentAgreement) return newCurrentAgreement;
        return null;
    };

    const updateInsuranceAgreementSelected = (opt: ViewOption) => {
        changeValues({[valueKey]: opt.value});
    };

    const currentAgreement = getCurrentAgreement();

    return (
        <Row className="mb-2 custom-dropdown">
            <Col>
                <Select
                    id="insurance-agreement-search-bar"
                    options={options}
                    onChange={updateInsuranceAgreementSelected}
                    placeholder={
                        currentAgreement !== null
                            ? currentAgreement.label
                            : 'Select insurance agreement'
                    }
                    styles={customStyles}
                    className="custom-dropdown-select"
                />
            </Col>
        </Row>
    );
};

export default InsuranceAgreementSearchBar;
