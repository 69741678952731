import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {Card, Row, Col, Modal, Tabs, Tab} from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';
import {getNameString} from '@/actions/general';
import FavouriteClinicianStar from '../app/parts/FavouriteStar/FavouriteClinicianStar';
import {TopReferrer, FavouriteClinician} from '@/generated-client';
import {useSelector} from 'react-redux';
import {useUserService} from '@/actions';
import Loader from '@components/app/Loader';
import {RootState} from '@/reducers';
import MedrUrlPaths from '@/constants/urls';

type TopReferrers = TopReferrer[];

interface SeeAllCliniciansModalProps {
    show: boolean;
    handleClose: () => void;
    favouriteClinicians: FavouriteClinician[];
    topReferralsFrom: TopReferrers;
    topReferredTo: TopReferrers;
}

export const SeeAllCliniciansModal: React.FC<SeeAllCliniciansModalProps> = ({
    show,
    handleClose,
    favouriteClinicians,
    topReferralsFrom,
    topReferredTo,
}) => {
    const urlPaths = new MedrUrlPaths();

    // Render list of favourite clinicians
    const renderFavouriteClinicians = () => (
        <Row className="dashboard-analytics-list-p">
            {favouriteClinicians.map((favClin) => {
                const clinicianUrl = urlPaths.clinicianPageUrl(
                    favClin.userProfile.id
                );
                return (
                    <Col key={`tc${favClin.id}`} xs={12} md={6}>
                        <div className="d-flex align-items-center mb-2">
                            <span className="mr-2 font-weight-bold medr-text-inv">
                                <FavouriteClinicianStar
                                    clinician={{
                                        isFavourite: true,
                                        userProfile: favClin.userProfile,
                                    }}
                                />
                            </span>
                            <a className="medr-text-inv" href={clinicianUrl}>
                                {getNameString(favClin.userProfile)}
                            </a>
                        </div>
                    </Col>
                );
            })}
        </Row>
    );

    // Render list of referral clinicians
    const renderReferralClinicians = (referralClinicians: TopReferrers) => {
        let rxClin = referralClinicians;

        // Reorder items for better display on larger screens
        if (window.innerWidth > 768) {
            const sortedItems = [...rxClin].sort((a, b) => a.rank - b.rank);
            const midpoint = Math.ceil(sortedItems.length / 2);
            const firstHalf = sortedItems.slice(0, midpoint);
            const secondHalf = sortedItems.slice(midpoint);
            const reorderedItems = [];
            for (let i = 0; i < midpoint; i++) {
                if (firstHalf[i]) reorderedItems.push(firstHalf[i]);
                if (secondHalf[i]) reorderedItems.push(secondHalf[i]);
            }
            rxClin = reorderedItems;
        }

        return (
            <Row className="dashboard-analytics-list-p">
                {rxClin.map((clinicianInfo) => {
                    const clinicianUrl = urlPaths.clinicianPageUrl(
                        clinicianInfo.clinician.id
                    );
                    return (
                        <Col key={`tc${uuidv4()}`} xs={12} md={6}>
                            <div className="d-flex align-items-center mb-2">
                                <span className="mr-2 font-weight-bold medr-text-inv">
                                    {clinicianInfo.rank}.
                                </span>
                                <a
                                    className="medr-text-inv"
                                    href={clinicianUrl}
                                >
                                    {getNameString(clinicianInfo.clinician)} (
                                    {clinicianInfo.count})
                                </a>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        );
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header
                className="clinician-interaction-popup"
                style={{
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                }}
                closeButton
            >
                <Modal.Title className="medr-text-inv">
                    Clinician Interaction Analytics
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="clinician-interaction-popup"
                style={{
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                }}
            >
                <Tabs
                    defaultActiveKey="favouriteClinicians"
                    id="clinicianInteractionTabs"
                    className="mb-4 custom-tabs"
                >
                    <Tab
                        className="custom-tabs"
                        eventKey="favouriteClinicians"
                        title="Favourite Clinicians"
                    >
                        {renderFavouriteClinicians()}
                    </Tab>
                    <Tab
                        className="custom-tabs"
                        eventKey="topReferralsFrom"
                        title="Top Referrals Received"
                    >
                        {renderReferralClinicians(topReferralsFrom)}
                    </Tab>
                    <Tab
                        className="custom-tabs"
                        eventKey="topReferredTo"
                        title="Top Referrals Sent"
                    >
                        {renderReferralClinicians(topReferredTo)}
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    );
};

interface UserAnalyticsSummaryProps {
    analyticsNo?: number;
}

const UserAnalyticsSummary: React.FC<UserAnalyticsSummaryProps> = ({
    analyticsNo = 5,
}) => {
    const userService = useUserService();
    const userServiceRef = useRef(userService);
    userServiceRef.current = userService;

    const isLoading = useSelector(
        (state: RootState) => state.profile.loadingAnalytics
    );
    const userProfile = useSelector(
        (state: RootState) => state.profile.userProfile
    );
    const analytics = useSelector(
        (state: RootState) => state.profile.analytics
    );

    const [showSeeAllCliniciansModal, setShowSeeAllCliniciansModal] =
        useState(false);

    const refreshAnalytics = useCallback(async () => {
        await userService.getUserSummaryAnalytics(analyticsNo);
    }, [analyticsNo, userService]);

    useEffect(() => {
        refreshAnalytics();
    }, []);

    const isDataReady = useMemo(
        () => userProfile && analytics,
        [userProfile, analytics]
    );

    if (!isDataReady) {
        return <div id="user_analytics_missing_up">Loading...</div>;
    }

    const {
        noPatientsMade,
        noReferralsMade,
        noReferralsReceived,
        topReferredTo,
        topReferralsFrom,
        favouriteClinicians,
    } = analytics;

    const handleValue = (value: number) => {
        if (!value) {
            return 'NaN';
        }
        return value;
    };

    const loaderView = (
        <Loader inverse={false} text="Loading referral stats..." />
    );

    const statsCard = () => (
        <Card
            className="my-3 py-3 rounded d-flex flex-fill medr-card"
            id="analyticsStatsCard"
        >
            <Card.Title>Platform Statistics</Card.Title>
            {isLoading && loaderView}
            {!isLoading && (
                <Card.Body className="d-flex flex-column text-left">
                    <ul className="dashboard-analytics-list-p">
                        <li key={uuidv4()}>
                            <span className="dashboard-analytics-sp">
                                Patients Added:
                            </span>
                            <span>{handleValue(noPatientsMade)}</span>
                        </li>
                        <li key={uuidv4()}>
                            <span className="dashboard-analytics-sp">
                                Referrals Sent:
                            </span>
                            <span>{handleValue(noReferralsMade)}</span>
                        </li>
                        <li key={uuidv4()}>
                            <span className="dashboard-analytics-sp">
                                Referrals Received:
                            </span>
                            <span>{handleValue(noReferralsReceived)}</span>
                        </li>
                    </ul>
                    <div className="mt-auto" />
                </Card.Body>
            )}
        </Card>
    );

    const topReferrersCard = (
        referrers: TopReferrers,
        title: string,
        keyId: string
    ) => {
        if (!referrers || referrers.length === 0) {
            return (
                <Card
                    className="my-3 p-3 rounded d-flex flex-fill medr-card"
                    id={keyId}
                >
                    <Card.Title>{title}</Card.Title>
                    {isLoading && loaderView}
                    {!isLoading && (
                        <Card.Body className="d-flex flex-column text-left">
                            <p className="dashoard-ana-text text-center">
                                Top referrers data not available
                            </p>
                        </Card.Body>
                    )}
                </Card>
            );
        }

        const referrersLim = 5;

        return (
            <Card
                className="my-3 p-3 rounded d-flex flex-fill medr-card"
                id={keyId}
            >
                <Card.Title>{title}</Card.Title>
                {isLoading && loaderView}
                {!isLoading && (
                    <Card.Body className="d-flex flex-column text-left">
                        <ol className="dashboard-analytics-list-p">
                            {referrers
                                .slice(0, referrersLim)
                                .map((clinicianInfo) => {
                                    const clinicianUrl = `/clinicians/user/${clinicianInfo.clinician.id}`;
                                    return (
                                        <li key={`tc${uuidv4()}`}>
                                            <div className="row" key={uuidv4()}>
                                                <div className="col-1">
                                                    <FavouriteClinicianStar
                                                        clinician={{
                                                            isFavourite:
                                                                clinicianInfo.isFavourite,
                                                            userProfile:
                                                                clinicianInfo.clinician,
                                                        }}
                                                        requestMade={
                                                            refreshAnalytics
                                                        }
                                                    />
                                                </div>
                                                <div className="col clinician-name">
                                                    <a
                                                        className="medr-text-inv"
                                                        href={clinicianUrl}
                                                    >
                                                        {getNameString(
                                                            clinicianInfo.clinician
                                                        )}{' '}
                                                        ({clinicianInfo.count})
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ol>
                        {referrers.length > referrersLim && (
                            <p>
                                <button
                                    type="button"
                                    onKeyDown={() =>
                                        setShowSeeAllCliniciansModal(true)
                                    }
                                    onClick={() =>
                                        setShowSeeAllCliniciansModal(true)
                                    }
                                    className="btn-link see-all-clinicians"
                                >
                                    See all clinicians
                                </button>
                            </p>
                        )}
                        <div className="mt-auto" />
                    </Card.Body>
                )}
            </Card>
        );
    };

    const getFavouriteCliniciansCard = () => {
        if (!favouriteClinicians || favouriteClinicians.length === 0) {
            return (
                <Card
                    className="my-3 p-3 rounded d-flex flex-fill medr-card"
                    id="favouriteCliniciansCard"
                >
                    <Card.Title>Favourite Clinicians</Card.Title>
                    {isLoading && loaderView}
                    {!isLoading && (
                        <Card.Body className="d-flex flex-column text-left">
                            <p className="text-center">
                                Favourite clinicians have not been selected
                            </p>
                            <div className="mt-auto" />
                        </Card.Body>
                    )}
                </Card>
            );
        }

        const favCliniciansLim = 3;

        return (
            <Card
                className="my-3 p-3 rounded d-flex flex-fill medr-card"
                id="favouriteCliniciansCard"
            >
                <Card.Title>Favourite Clinicians</Card.Title>
                {isLoading && loaderView}
                {!isLoading && (
                    <Card.Body className="d-flex flex-column text-left">
                        <ul style={{listStyleType: 'none', padding: '0px'}}>
                            {favouriteClinicians
                                .slice(0, favCliniciansLim)
                                .map((favClin) => {
                                    const clinicianUrl = `/clinicians/user/${favClin.favouriteUserProfile.id}`;
                                    return (
                                        <li key={uuidv4()}>
                                            <div className="row" key={uuidv4()}>
                                                <div className="col-1">
                                                    <FavouriteClinicianStar
                                                        clinician={{
                                                            isFavourite: true,
                                                            userProfile:
                                                                favClin.favouriteUserProfile,
                                                        }}
                                                        requestMade={
                                                            refreshAnalytics
                                                        }
                                                    />
                                                </div>
                                                <div className="col clinician-name">
                                                    <a
                                                        className="medr-text-inv"
                                                        href={clinicianUrl}
                                                    >
                                                        {getNameString(
                                                            favClin.favouriteUserProfile
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                        {favouriteClinicians.length > favCliniciansLim && (
                            <p>
                                <button
                                    type="button"
                                    onKeyDown={() =>
                                        setShowSeeAllCliniciansModal(true)
                                    }
                                    onClick={() =>
                                        setShowSeeAllCliniciansModal(true)
                                    }
                                    className="btn-link see-all-clinicians"
                                >
                                    See all clinicians
                                </button>
                            </p>
                        )}
                        <div className="mt-auto" />
                    </Card.Body>
                )}
            </Card>
        );
    };

    return (
        <div
            className="medr-layout text-center"
            style={{marginLeft: 'auto', marginRight: 'auto'}}
        >
            <SeeAllCliniciansModal
                show={showSeeAllCliniciansModal}
                handleClose={() => setShowSeeAllCliniciansModal(false)}
                favouriteClinicians={favouriteClinicians}
                topReferralsFrom={topReferralsFrom}
                topReferredTo={topReferredTo}
            />
            <Row>
                <Col className="medr-card-col" lg={6} md={6} sm={12}>
                    {statsCard()}
                </Col>
                <Col className="medr-card-col" lg={6} md={6} sm={12}>
                    {getFavouriteCliniciansCard()}
                </Col>
            </Row>
            <Row>
                <Col className="medr-card-col" lg={6} md={6} sm={12}>
                    {topReferrersCard(
                        topReferralsFrom,
                        'Top Referrals Received',
                        'topReferrersFromCard'
                    )}
                </Col>
                <Col className="medr-card-col" lg={6} md={6} sm={12}>
                    {topReferrersCard(
                        topReferredTo,
                        'Top Referrals Sent',
                        'topReferrersToCard'
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default UserAnalyticsSummary;
