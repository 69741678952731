import React, {useEffect, useState, useCallback} from 'react';
import {Tabs, Tab, Button} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useAdminService, DownloadService} from '@/actions';
import Loader from '@components/app/Loader';
import {getNameString, getAddressStr} from '@/actions/general';
import {UserProfile, ClinicianRoleProfile} from '@/generated-client';
import {RootState} from '@/reducers';
import AdminPagTable, {SliceIndexes} from './AdminPaginatedTable';
import {initSliceIndex, formatId} from './PagTableConsts';

enum CliniciansTabs {
    STAFF = 'staff',
    CLINICIANS = 'clinicians',
    UNVERIFIED = 'unverified',
    DELEGATES = 'delegates',
    DEACTIVATED = 'deactivated',
}

const AdminCliniciansTable: React.FC = () => {
    const adminService = useAdminService();

    // Fetch data effect
    const refreshCliniciansLists = useCallback(async () => {
        try {
            await adminService.getAdminClinicansList();
        } catch (error) {
            console.error('Failed to refresh clinicians list:', error);
            // Optionally set an error state to display an error message to the user
        }
    }, []);

    useEffect(() => {
        refreshCliniciansLists();
    }, [refreshCliniciansLists]);

    const staffList = useSelector(
        (state: RootState) => state.admin.clinicians.staff
    );
    const cliniciansList = useSelector(
        (state: RootState) => state.admin.clinicians.clinicians
    );
    const unverifiedList = useSelector(
        (state: RootState) => state.admin.clinicians.unverified
    );
    const deactivatedList = useSelector(
        (state: RootState) => state.admin.clinicians.deactivated
    );
    const delegatesList = useSelector(
        (state: RootState) => state.admin.clinicians.delegates
    );

    const [staffSlice, setStaffSlice] = useState<SliceIndexes>(initSliceIndex);
    const [cliniciansSlice, setCliniciansSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [unverifiedSlice, setUnverifiedSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [delegatesSlice, setDelegatesSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [deactivatedSlice, setDeactivatedSlice] =
        useState<SliceIndexes>(initSliceIndex);

    const tabSlices = {
        [CliniciansTabs.STAFF]: staffSlice,
        [CliniciansTabs.CLINICIANS]: cliniciansSlice,
        [CliniciansTabs.UNVERIFIED]: unverifiedSlice,
        [CliniciansTabs.DELEGATES]: delegatesSlice,
        [CliniciansTabs.DEACTIVATED]: deactivatedSlice,
    };

    const tabSliceUpdaters = {
        [CliniciansTabs.STAFF]: setStaffSlice,
        [CliniciansTabs.CLINICIANS]: setCliniciansSlice,
        [CliniciansTabs.UNVERIFIED]: setUnverifiedSlice,
        [CliniciansTabs.DELEGATES]: setDelegatesSlice,
        [CliniciansTabs.DEACTIVATED]: setDeactivatedSlice,
    };

    const crpLists = {
        [CliniciansTabs.STAFF]: staffList,
        [CliniciansTabs.CLINICIANS]: cliniciansList,
        [CliniciansTabs.UNVERIFIED]: unverifiedList,
        [CliniciansTabs.DELEGATES]: delegatesList,
        [CliniciansTabs.DEACTIVATED]: deactivatedList,
    };

    const tabtitles = {
        [CliniciansTabs.STAFF]: 'Staff',
        [CliniciansTabs.CLINICIANS]: 'Clinicians',
        [CliniciansTabs.UNVERIFIED]: 'Unverified',
        [CliniciansTabs.DELEGATES]: 'Delegates',
        [CliniciansTabs.DEACTIVATED]: 'Deactivated',
    };

    const tabHeaders = {
        [CliniciansTabs.STAFF]: ['ID', 'Name', 'Site Admin'],
        [CliniciansTabs.CLINICIANS]: [
            'ID',
            'Name',
            'Email',
            'Role',
            'Centre',
            'Registration',
        ],
        [CliniciansTabs.UNVERIFIED]: [
            'ID',
            'Name',
            'Email',
            'Role',
            'Centre',
            'Registration',
        ],
        [CliniciansTabs.DELEGATES]: ['ID', 'Name', 'Email'],
        [CliniciansTabs.DEACTIVATED]: [
            'ID',
            'Name',
            'Email',
            'Role',
            'Centre',
            'Registration',
        ],
    };

    const createStaffRow = (crp: ClinicianRoleProfile, index: number) => (
        <tr key={`${index}-${crp.id}`}>
            <td>{formatId(crp.id)}</td>
            <td>{getNameString(crp.userProfile)}</td>
            <td>{crp.userProfile.user.isStaff ? 'Yes' : 'No'}</td>
        </tr>
    );

    const createDelegeteRow = (user: UserProfile, index: number) => (
        <tr key={`${index}-${user.id}`}>
            <td>{formatId(user.id)}</td>
            <td>{getNameString(user)}</td>
            <td>{user.user.email}</td>
        </tr>
    );

    const createClinicianRow = (
        clinician: ClinicianRoleProfile,
        index: number
    ) => {
        const centreString = !clinician.clinic
            ? 'No clinic'
            : `${clinician.clinic.centreName}, ${getAddressStr(
                  clinician.clinic.address
              )}`;
        return (
            <tr key={`${index}-${clinician.id}`}>
                <td>{formatId(clinician.id)}</td>
                <td>{getNameString(clinician.userProfile)}</td>
                <td>{clinician.userProfile.user.email}</td>
                <td>{clinician.userProfile.jobRole[0].jobTitle}</td>
                <td>{centreString}</td>
                <td>
                    {`${clinician.userProfile.jobRole[0].registrationBody}: ${clinician.userProfile.registrationNumber}`}
                </td>
            </tr>
        );
    };

    const createRowFunctions = {
        [CliniciansTabs.STAFF]: createStaffRow,
        [CliniciansTabs.CLINICIANS]: createClinicianRow,
        [CliniciansTabs.UNVERIFIED]: createClinicianRow,
        [CliniciansTabs.DELEGATES]: createDelegeteRow,
        [CliniciansTabs.DEACTIVATED]: createClinicianRow,
    };

    const isLoading = adminService.requestLoading;
    const onExportClick = () => {
        const downloadService = new DownloadService();
        downloadService.downloadAdminCrpsList();
    };

    return (
        <>
            <Button variant="info" className="mb-3" onClick={onExportClick}>
                Download Clinicians List
            </Button>
            <Tabs
                defaultActiveKey={CliniciansTabs.CLINICIANS}
                id="custom-tabs"
                className="custom-tabs mb-3"
            >
                {Object.values(CliniciansTabs).map((tabKey) => (
                    <Tab
                        eventKey={tabKey}
                        title={tabtitles[tabKey]}
                        key={tabKey}
                        className="custom-tabs"
                    >
                        {isLoading ? (
                            <>
                                <div className="gap mt-5" />
                                <Loader text={`Loading ${tabKey} list...`} />
                            </>
                        ) : (
                            <AdminPagTable
                                headers={tabHeaders[tabKey]}
                                items={crpLists[tabKey]}
                                createItemRows={createRowFunctions[tabKey]}
                                sliceIndex={tabSlices[tabKey]}
                                updateSlice={tabSliceUpdaters[tabKey]}
                                emptyTableMessage={`No ${tabKey} to display at the moment`}
                            />
                        )}
                    </Tab>
                ))}
            </Tabs>
        </>
    );
};

export default AdminCliniciansTable;
