import {Reducer} from 'react';
import * as CONSTS from '@constants/authConstants';
import * as ITF from '@reducers/authReducer/interfaces';
import {AuthState} from './interfaces';
import {createDefaultUserProfile} from '@/constants/initDataConstants';

const initialState: AuthState = {
    loading: false,
    error: '',
    isAuthenticated: false,
    isAdmin: false,
    user: createDefaultUserProfile(),
    userGroup: '',
    token: '',
    delegateRegistration: {
        delegate: createDefaultUserProfile(),
        clinician: createDefaultUserProfile(),
    },
    version: '',
};

// INCOMING REQUEST ACTIONS
function isRequestAction(
    action: ITF.AuthActionTypes
): action is ITF.AuthActionRequests {
    return [
        CONSTS.APP_VERISON_ACTION.REQUEST,
        CONSTS.REGISTER_ACTION.REQUEST,
        CONSTS.VERIFY_EMAIL_ACTION.REQUEST,
        CONSTS.LOGIN_ACTION.REQUEST,
        CONSTS.CHECK_AUTH_ACTION.REQUEST,
        CONSTS.LOGOUT_ACTION.REQUEST,
        CONSTS.RESET_PASSWORD_ACTION.REQUEST,
        CONSTS.REGISTER_DELEGATE_INFO_ACTION.REQUEST,
        CONSTS.RESET_PASSWORD_SET_NEW_ACTION.REQUEST,
    ].includes(action.type);
}

function handleRequestAction(state: AuthState): AuthState {
    return {
        ...state,
        loading: true,
    };
}

// INCOMING FAIL ACTIONS
function isFailureAction(
    action: ITF.AuthActionTypes
): action is ITF.AuthActionFailures {
    return [
        CONSTS.APP_VERISON_ACTION.FAIL,
        CONSTS.REGISTER_ACTION.FAIL,
        CONSTS.VERIFY_EMAIL_ACTION.FAIL,
        CONSTS.LOGIN_ACTION.FAIL,
        CONSTS.CHECK_AUTH_ACTION.FAIL,
        CONSTS.LOGOUT_ACTION.FAIL,
        CONSTS.RESET_PASSWORD_ACTION.FAIL,
        CONSTS.REGISTER_DELEGATE_INFO_ACTION.FAIL,
        CONSTS.RESET_PASSWORD_SET_NEW_ACTION.FAIL,
    ].includes(action.type);
}

function handleFailureAction(state: AuthState): AuthState {
    return {
        ...state,
        loading: false,
    };
}

// INCOMING SUCCESS ACTIONS
function isLogoutSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.LogoutSuccessAction {
    return action.type === CONSTS.LOGOUT_ACTION.SUCCESS;
}

function handleLogoutSuccessAction(): AuthState {
    return {...initialState};
}

function isAppVersionSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.AppVersionSuccessAction {
    return action.type === CONSTS.APP_VERISON_ACTION.SUCCESS;
}

function handleAppVersionSuccessAction(
    state: AuthState,
    action: ITF.AppVersionSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isRegisterUserSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.RegisterSuccessAction {
    return action.type === CONSTS.REGISTER_ACTION.SUCCESS;
}

function handleRegisterUserSuccessAction(
    state: AuthState,
    action: ITF.RegisterSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isVerifyEmailSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.VerifyEmailSuccessAction {
    return action.type === CONSTS.VERIFY_EMAIL_ACTION.SUCCESS;
}

function handleVerifyEmailSuccessAction(
    state: AuthState,
    action: ITF.VerifyEmailSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isResetPasswordSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.ResetPasswordSuccessAction {
    return action.type === CONSTS.RESET_PASSWORD_ACTION.SUCCESS;
}

function handleResetPasswordSuccessAction(
    state: AuthState,
    action: ITF.ResetPasswordSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isResetPasswordSetNewSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.ResetPasswordSetNewSuccessAction {
    return action.type === CONSTS.RESET_PASSWORD_SET_NEW_ACTION.SUCCESS;
}

function handleResetPasswordSetNewSuccessAction(
    state: AuthState,
    action: ITF.ResetPasswordSetNewSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isLoginSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.LoginSuccessAction {
    return action.type === CONSTS.LOGIN_ACTION.SUCCESS;
}

function handleLoginSuccessAction(
    state: AuthState,
    action: ITF.LoginSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isCheckAuthSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.CheckAuthSuccessAction {
    return action.type === CONSTS.CHECK_AUTH_ACTION.SUCCESS;
}

function handleCheckAuthSuccessAction(
    state: AuthState,
    action: ITF.CheckAuthSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isRegisterDelegateInfoSuccessAction(
    action: ITF.AuthActionTypes
): action is ITF.RegisterDelegateInfoSuccessAction {
    return action.type === CONSTS.REGISTER_DELEGATE_INFO_ACTION.SUCCESS;
}

function handleRegisterDelegateInfoSuccessAction(
    state: AuthState,
    action: ITF.RegisterDelegateInfoSuccessAction
): AuthState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

export const authReducer: Reducer<AuthState, ITF.AuthActionTypes> = (
    state = initialState,
    action
) => {
    // INCOMING REQUEST ACTIONS
    if (isRequestAction(action)) {
        return handleRequestAction(state);
    }

    // INCOMING SUCCESS ACTIONS
    if (isAppVersionSuccessAction(action)) {
        return handleAppVersionSuccessAction(state, action);
    }
    if (isRegisterUserSuccessAction(action)) {
        return handleRegisterUserSuccessAction(state, action);
    }
    if (isVerifyEmailSuccessAction(action)) {
        return handleVerifyEmailSuccessAction(state, action);
    }
    if (isResetPasswordSuccessAction(action)) {
        return handleResetPasswordSuccessAction(state, action);
    }
    if (isResetPasswordSetNewSuccessAction(action)) {
        return handleResetPasswordSetNewSuccessAction(state, action);
    }
    if (isLoginSuccessAction(action)) {
        return handleLoginSuccessAction(state, action);
    }
    if (isCheckAuthSuccessAction(action)) {
        return handleCheckAuthSuccessAction(state, action);
    }
    if (isRegisterDelegateInfoSuccessAction(action)) {
        return handleRegisterDelegateInfoSuccessAction(state, action);
    }
    if (isLogoutSuccessAction(action)) {
        return handleLogoutSuccessAction();
    }
    // INCOMING FAIL ACTIONS
    if (isFailureAction(action)) {
        return handleFailureAction(state);
    }
    return state;
};
