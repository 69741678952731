/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface TopReferrer
 */
export interface TopReferrer {
    /**
     * 
     * @type {UserProfile}
     * @memberof TopReferrer
     */
    clinician: UserProfile;
    /**
     * 
     * @type {number}
     * @memberof TopReferrer
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof TopReferrer
     */
    rank: number;
    /**
     * 
     * @type {boolean}
     * @memberof TopReferrer
     */
    isFavourite: boolean;
}

/**
 * Check if a given object implements the TopReferrer interface.
 */
export function instanceOfTopReferrer(value: object): value is TopReferrer {
    if (!('clinician' in value) || value['clinician'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('isFavourite' in value) || value['isFavourite'] === undefined) return false;
    return true;
}

export function TopReferrerFromJSON(json: any): TopReferrer {
    return TopReferrerFromJSONTyped(json, false);
}

export function TopReferrerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopReferrer {
    if (json == null) {
        return json;
    }
    return {
        
        'clinician': UserProfileFromJSON(json['clinician']),
        'count': json['count'],
        'rank': json['rank'],
        'isFavourite': json['isFavourite'],
    };
}

export function TopReferrerToJSON(json: any): TopReferrer {
    return TopReferrerToJSONTyped(json, false);
}

export function TopReferrerToJSONTyped(value?: TopReferrer | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinician': UserProfileToJSON(value['clinician']),
        'count': value['count'],
        'rank': value['rank'],
        'isFavourite': value['isFavourite'],
    };
}

