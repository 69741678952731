import {Dispatch} from 'redux';
import * as CONSTS from '@constants/referralConstants';
import * as CLIENT from '@/generated-client';

// getClinicianReferralsList
export function dispatchGetClinicianReferralsListRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_REFERRALS_ACTION.REQUEST});
}
export function dispatchGetClinicianReferralsListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralsListForUserResponse
) {
    dispatch({
        type: CONSTS.CLINICIAN_REFERRALS_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetClinicianReferralsListFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.CLINICIAN_REFERRALS_ACTION.FAIL, payload: error});
}

// getClinicianReferralsListInbound
export function dispatchGetClinicianReferralsListInboundRequest(
    dispatch: Dispatch
) {
    dispatch({type: CONSTS.CLINICIAN_REFERRALS_INBOUND_ACTION.REQUEST});
}
export function dispatchGetClinicianReferralsListInboundSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralsListInboundForUserResponse
) {
    dispatch({
        type: CONSTS.CLINICIAN_REFERRALS_INBOUND_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetClinicianReferralsListInboundFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.CLINICIAN_REFERRALS_INBOUND_ACTION.FAIL,
        payload: error,
    });
}

// getClinicianReferralsListOutbound
export function dispatchGetClinicianReferralsListOutboundRequest(
    dispatch: Dispatch
) {
    dispatch({type: CONSTS.CLINICIAN_REFERRALS_OUTBOUND_ACTION.REQUEST});
}
export function dispatchGetClinicianReferralsListOutboundSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralsListOutboundForUserResponse
) {
    dispatch({
        type: CONSTS.CLINICIAN_REFERRALS_OUTBOUND_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetClinicianReferralsListOutboundFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.CLINICIAN_REFERRALS_OUTBOUND_ACTION.FAIL,
        payload: error,
    });
}

// createReferral
export function dispatchCreateReferralRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CREATE_REFERRAL_ACTION.REQUEST});
}
export function dispatchCreateReferralSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralCreateResponse
) {
    dispatch({type: CONSTS.CREATE_REFERRAL_ACTION.SUCCESS, payload});
}
export function dispatchCreateReferralFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.CREATE_REFERRAL_ACTION.FAIL, payload: error});
}

// referralAccept
export function dispatchReferralAcceptRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.REFERRAL_ACCEPT_ACTION.REQUEST});
}
export function dispatchReferralAcceptSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralSelectionResponse
) {
    dispatch({type: CONSTS.REFERRAL_ACCEPT_ACTION.SUCCESS, payload});
}
export function dispatchReferralAcceptFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.REFERRAL_ACCEPT_ACTION.FAIL, payload: error});
}

// referralReject
export function dispatchReferralRejectRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.REFERRAL_REJECT_ACTION.REQUEST});
}
export function dispatchReferralRejectSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralSelectionResponse
) {
    dispatch({type: CONSTS.REFERRAL_REJECT_ACTION.SUCCESS, payload});
}
export function dispatchReferralRejectFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.REFERRAL_REJECT_ACTION.FAIL, payload: error});
}

// cancelReferral
export function dispatchCancelReferralRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.REFERRAL_CANCEL_ACTION.REQUEST});
}
export function dispatchCancelReferralSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralsListMadePendingResponse
) {
    dispatch({type: CONSTS.REFERRAL_CANCEL_ACTION.SUCCESS, payload});
}
export function dispatchCancelReferralFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.REFERRAL_CANCEL_ACTION.FAIL, payload: error});
}

// toggleCompleteReferral
export function dispatchToggleCompleteReferralRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.REQUEST});
}
export function dispatchToggleCompleteReferralSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralToggleCompleteResponse
) {
    dispatch({
        type: CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchToggleCompleteReferralFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.FAIL,
        payload: error,
    });
}

// toggleDnaReferral
export function dispatchToggleDnaReferralRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.REFERRAL_TOGGLE_DNA_ACTION.REQUEST});
}
export function dispatchToggleDnaReferralSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralToggleCompleteResponse
) {
    dispatch({type: CONSTS.REFERRAL_TOGGLE_DNA_ACTION.SUCCESS, payload});
}
export function dispatchToggleDnaReferralFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.REFERRAL_TOGGLE_DNA_ACTION.FAIL, payload: error});
}
