import {Dispatch} from 'redux';
import {NavigateFunction} from 'react-router-dom';
import {
    GpsFromPostcodeRequest,
    PatientApi,
    ClinicianInformationApi,
    GetGpsApi,
} from '@/generated-client';
import * as SERED from '@/reducers/searchReducer';
import {MedrService} from './authService';

type Location = {
    postcode: string;
    lat: number;
    lng: number;
};

export class SearchService extends MedrService {
    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
    }

    setSearchPatientNull(): void {
        SERED.dispatchSetPatientNull(this.dispatch);
    }

    setSearchCrpNull(): void {
        SERED.dispatchSetCrpNull(this.dispatch);
    }

    setJobRoleNull(): void {
        SERED.dispatchSetJobRoleNull(this.dispatch);
    }

    setClinicianNull(): void {
        SERED.dispatchSetClinicianNull(this.dispatch);
    }

    async setSearchPatientById(pxId: number): Promise<boolean> {
        const payload = {pxId};
        const api = new PatientApi(this.configuration);
        return this.executeApiCall(
            () => api.patientRetrieve(payload),
            SERED.dispatchSetSearchPatientByIdRequest,
            SERED.dispatchSetSearchPatientByIdSuccess,
            SERED.dispatchSetSearchPatientByIdFail
        );
    }

    async setSearchCrpById(crpId: number): Promise<boolean> {
        const payload = {id: crpId};
        const api = new ClinicianInformationApi(this.configuration);
        return this.executeApiCall(
            () => api.clinicianInformationRetrieve(payload),
            SERED.dispatchSetSearchCrpByIdRequest,
            SERED.dispatchSetSearchCrpByIdSuccess,
            SERED.dispatchSetSearchCrpByIdFail
        );
    }

    async setClinicianById(crpId: number): Promise<boolean> {
        const payload = {id: crpId};
        const api = new ClinicianInformationApi(this.configuration);
        return this.executeApiCall(
            () => api.clinicianInformationRetrieve(payload),
            SERED.dispatchSetClinicianByIdRequest,
            SERED.dispatchSetClinicianByIdSuccess,
            SERED.dispatchSetClinicianByIdFail
        );
    }

    setSearchGps(postCode: string, lat: number, lng: number) {
        SERED.dispatchSetSearchGpsRequest(this.dispatch, postCode, lat, lng);
    }

    async setSearchPostcode(
        inputData: GpsFromPostcodeRequest
    ): Promise<boolean> {
        const payload = {gpsFromPostcodeRequest: inputData};
        const api = new GetGpsApi(this.configuration);
        return this.executeApiCall(
            () => api.getGpsCreate(payload),
            SERED.dispatchSetSearchPostcodeRequest,
            SERED.dispatchSetSearchPostcodeSuccess,
            SERED.dispatchSetSearchPostcodeFail
        );
    }

    async getSearchPostcodeData(
        inputData: GpsFromPostcodeRequest
    ): Promise<Location> {
        const payload = {gpsFromPostcodeRequest: inputData};
        const api = new GetGpsApi(this.configuration);

        SERED.dispatchGetSearchPostcodeDataRequest(this.dispatch);
        try {
            const data = await api.getGpsCreate(payload);
            SERED.dispatchGetSearchPostcodeDataSuccess(this.dispatch, data);
            return data;
        } catch (error) {
            SERED.dispatchGetSearchPostcodeDataFail(this.dispatch, error);
            return null;
        }
    }
}
