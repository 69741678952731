import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import {ViewOption, customStyles} from '@/constants/styles';

interface PatientListTabsDropDownProps {
    viewName: string;
    viewOptions: ViewOption[];
    updateView: (value: string) => void;
}

const PatientListTabsDropDown: React.FC<PatientListTabsDropDownProps> = ({
    viewName,
    viewOptions,
    updateView,
}) => {
    return (
        <Row className="p-1 custom-dropdown">
            <Col>
                <Select
                    options={viewOptions}
                    onChange={(opt) => updateView(opt.value)}
                    placeholder={viewName}
                    styles={customStyles}
                    className="custom-dropdown-select"
                />
            </Col>
        </Row>
    );
};

export default PatientListTabsDropDown;
