import React from 'react';
import {Row, Col} from 'react-bootstrap';

const NotFound: React.FC = () => {
    return (
        <>
            <Row className="text-center p-3">
                <Col className="text-center medr-text">
                    <h1>404 - Not Found</h1>
                    <p>The page you&apos;re looking for doesn&apos;t exist.</p>
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="/dashboard">
                        <img
                            src="/logos/white_logo_transparent_background.png"
                            alt="404"
                            className="img-404"
                        />
                    </a>
                </Col>
            </Row>
        </>
    );
};

export default NotFound;
