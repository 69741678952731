import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useUserService} from '@/actions';
import {RootState} from '@/reducers';
import {RegistrationClinicianContact} from '@contexts/RegistrationContext';

interface UseReferralContactsReturn {
    referralContacts: RegistrationClinicianContact[];
    requiredEmails: string[];
    updateAppointmentContacts: (
        appointmentsContacts: RegistrationClinicianContact[]
    ) => void;
}

const useReferralContacts = (): UseReferralContactsReturn => {
    const userService = useUserService();

    const updateAppointmentContacts = async (appointmentsContacts) => {
        await userService.updateReferralContacts({appointmentsContacts});
    };
    const userProfile = useSelector(
        (state: RootState) => state.profile.userProfile
    );
    const clinicianProfiles = useSelector(
        (state: RootState) => state.profile.clinicianProfiles
    );
    // if (clinicianProfiles.length === 0) return {};
    const rxContacts = clinicianProfiles[0].userProfile.appointmentsContact;

    const [referralContacts, setReferralContacts] = useState([]);

    // const userEmail = useSelector(state => state.profile.userProfile.user.email);
    const delegates = useSelector(
        (state: RootState) => state.profile.delegates
    );
    const delegateEmails = delegates.map(
        (dl) => dl.delegateUserProfile.user.email
    );
    const requiredEmails = [...delegateEmails];

    useEffect(() => {
        if (rxContacts) {
            const appContacts = rxContacts.map((contact) => ({
                ...contact,
                isMain: contact.email === userProfile.user.email,
            }));
            setReferralContacts(appContacts);
        } else {
            setReferralContacts([]);
        }
    }, [rxContacts]);

    return {referralContacts, requiredEmails, updateAppointmentContacts};
};

export default useReferralContacts;
