import {useEffect} from 'react';
import {Dispatch} from 'redux';
import {NavigateFunction} from 'react-router-dom';
import Cookies from 'js-cookie';
import {MedrPages} from '@/constants/variables';
import MedrUrlPaths from '@/constants/urls';
import {
    CheckRegisteringEmailRequest,
    RegisterUserRequest,
    CheckClinicAddressRequest,
    ResetUserPasswordSetNewRequest,
    UpdateUserPasswordRequest,
    ErrorNotificationApi,
    AppVersionApi,
    UserApi,
    LoginApi,
    Configuration,
    LogoutApi,
    RegisterApi,
    ResponseError,
    MedrAppVersion,
    CheckClinicAddressResponse,
    LoginUserResponse,
    CheckRegisteringEmailResponse,
} from '@/generated-client';
import {ApiClientConstants} from '@/actions/variables';
import * as AUTHRED from '@/reducers/authReducer';
import {ReferralInboundTabs} from '@/screens/referrals/ReferralInboundScreen';
import {ReferralOutboundTabs} from '@/screens/referrals/ReferralOutboundScreen';

export function unauthResponseClear(): void {
    Cookies.remove(ApiClientConstants.AUTH_TOKEN);
    localStorage.clear();
}

enum UnauthErrorDetails {
    AUTH_CREDENTIALS_NOT_PROVIDED = 'Authentication credentials were not provided.',
    INVALID_TOKEN = 'Invalid token.',
}

let alertShown = false;

export async function redirectIfInvalidToken(
    error: ResponseError,
    navigate: NavigateFunction
): Promise<void> {
    console.log('redirectIfInvalidToken', error);

    if (error.response.status !== 403) return;

    const reponse = await error.response.text();
    const errorDetails = JSON.parse(reponse).detail;

    const unauthStatements = Object.values(UnauthErrorDetails).map(
        (value) => value
    );

    if (!unauthStatements.includes(errorDetails)) return;
    unauthResponseClear();
    navigate(MedrPages.LOGIN.toString());
    if (!alertShown) {
        alertShown = true;
        alert('Your session has expired. Please log in again.');
        // Reset the flag after a short delay
        setTimeout(() => {
            alertShown = false;
        }, 5000); // 5 seconds delay
    }
}

export class MedrService {
    public configuration: Configuration;
    public isAuthenticated: boolean;
    public authToken: string;
    public urlPaths: MedrUrlPaths;
    public navigate: NavigateFunction;
    public dispatch: Dispatch;

    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        this.refreshConfiguration();
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.urlPaths = new MedrUrlPaths();
    }

    private getAuthToken(): void {
        this.authToken = Cookies.get(ApiClientConstants.AUTH_TOKEN);
    }

    private getAuthTokenString(): string {
        return `Token ${this.authToken}`;
    }

    protected checkIsAuthenticated(): void {
        this.getAuthToken();
        this.isAuthenticated = this.authToken && this.authToken !== '';
    }
    checkAuthToken(): boolean {
        this.checkIsAuthenticated();
        return this.isAuthenticated;
    }

    protected getConfiguration(): void {
        if (this.isAuthenticated) {
            this.configuration = new Configuration({
                basePath: ApiClientConstants.API_BACKEND_URL,
                apiKey: this.getAuthTokenString(),
            });
            return;
        }
        this.configuration = new Configuration({
            basePath: ApiClientConstants.API_BACKEND_URL,
        });
    }

    refreshConfiguration(): void {
        this.checkIsAuthenticated();
        this.getConfiguration();
    }

    protected async executeApiCall<T>(
        apiCall: () => Promise<T>,
        requestAction: (dispatch: Dispatch) => void,
        successAction: (dispatch: Dispatch, data: T) => void,
        failAction: (dispatch: Dispatch, error: string) => void
    ): Promise<boolean> {
        requestAction(this.dispatch);
        try {
            const data = await apiCall();
            successAction(this.dispatch, data);
            return true;
        } catch (error) {
            redirectIfInvalidToken(error, this.navigate);
            failAction(this.dispatch, error);
            return false;
        }
    }
    protected async executeApiCallReturnObject<T>(
        apiCall: () => Promise<T>,
        requestAction: (dispatch: Dispatch) => void,
        successAction: (dispatch: Dispatch, data: T) => void,
        failAction: (dispatch: Dispatch, error: string) => void
    ): Promise<T> {
        requestAction(this.dispatch);
        try {
            const data = await apiCall();
            successAction(this.dispatch, data);
            return data;
        } catch (error) {
            redirectIfInvalidToken(error, this.navigate);
            failAction(this.dispatch, error);
            return null;
        }
    }

    goToLandingPage(): void {
        this.navigate(MedrPages.HOME);
    }
    goToDashboardPage(): void {
        this.navigate(MedrPages.DASHBOARD);
    }
    goToLoginPage(): void {
        this.navigate(MedrPages.LOGIN);
    }

    goToRegisterDelegateErrorPage(): void {
        this.navigate(MedrPages.REGISTER_DELEGATE_VERIFY_EMAIL_ERROR);
    }

    goToUserListPage(): void {
        this.navigate(MedrPages.ADMIN_USERS_LIST);
    }

    goToClinicianPage(userProfileId: number): void {
        this.navigate(this.urlPaths.clinicianGetUrl(userProfileId));
    }

    goToCliniciansMapPage(): void {
        this.navigate(MedrPages.CLINICIANS);
    }
    goToReferToClinician(clinicianId: number): void {
        this.navigate(this.urlPaths.rxToClinicianUrl(clinicianId));
    }
    goToPatientsList(): void {
        this.navigate(MedrPages.PATIENTS);
    }

    goToEditPatient(patientId: number): void {
        this.navigate(this.urlPaths.patientsEditUrl(patientId));
    }

    goToReferPatient(patientId: number): void {
        this.navigate(this.urlPaths.rxPatientSelectedUrl(patientId));
    }

    goToMakeReferral(): void {
        this.navigate(MedrPages.REFERRALS_ADD);
    }

    gotToReceivedReferrals(): void {
        this.navigate(MedrPages.REFERRALS);
    }
    goToInboundReferrals(): void {
        this.navigate(MedrPages.REFERRALS_INBOUND);
    }
    goToInboundReferralsPending(): void {
        this.navigate(
            this.urlPaths.inboundReferralsUrl(ReferralInboundTabs.PENDING)
        );
    }
    gotToInboundReferralsAccepted(): void {
        this.navigate(
            this.urlPaths.inboundReferralsUrl(ReferralInboundTabs.ACCEPTED)
        );
    }
    goToInboundReferralsBooked(): void {
        this.navigate(
            this.urlPaths.inboundReferralsUrl(ReferralInboundTabs.BOOKED)
        );
    }
    goToInboundReferralsDna(): void {
        this.navigate(
            this.urlPaths.inboundReferralsUrl(ReferralInboundTabs.DNA)
        );
    }
    goToInboundReferralsOpen(): void {
        this.navigate(
            this.urlPaths.inboundReferralsUrl(ReferralInboundTabs.OPEN)
        );
    }
    goToOutboundReferrals(): void {
        this.navigate(MedrPages.REFERRALS_OUTBOUND);
    }
    goToOutboundReferralsPending(): void {
        this.navigate(
            this.urlPaths.outboundReferralsUrl(ReferralOutboundTabs.PENDING)
        );
    }
    gotToOutboundReferralsAccepted(): void {
        this.navigate(
            this.urlPaths.outboundReferralsUrl(ReferralOutboundTabs.ACCEPTED)
        );
    }
    goToOutboundReferralsBooked(): void {
        this.navigate(
            this.urlPaths.outboundReferralsUrl(ReferralOutboundTabs.BOOKED)
        );
    }
    goToOutboundReferralsDna(): void {
        this.navigate(
            this.urlPaths.outboundReferralsUrl(ReferralOutboundTabs.DNA)
        );
    }
}

export class AuthService extends MedrService {
    private TOKEN_EXPIRY_DAYS = 7;

    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
    }

    async getAppVersion(): Promise<MedrAppVersion> {
        const api = new AppVersionApi(this.configuration);
        return this.executeApiCallReturnObject(
            () => api.appVersionRetrieve(),
            AUTHRED.dispatchGetAppVersionRequest,
            AUTHRED.dispatchGetAppVersionSuccess,
            AUTHRED.dispatchGetAppVersionFail
        );
    }

    async checkAuth(): Promise<boolean> {
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userCheckAuthRetrieve(),
            AUTHRED.dispatchCheckAuthRequest,
            AUTHRED.dispatchCheckAuthSuccess,
            AUTHRED.dispatchCheckAuthFail
        );
    }

    private setAuthData(data: LoginUserResponse): void {
        Cookies.set(ApiClientConstants.AUTH_TOKEN, data.token, {
            expires: this.TOKEN_EXPIRY_DAYS,
        });
    }

    async login(username: string, password: string): Promise<boolean> {
        const payload = {
            loginRequest: {username, password},
        };
        const api = new LoginApi(this.configuration);
        AUTHRED.dispatchLoginRequest(this.dispatch);

        try {
            const data = await api.loginCreate(payload);
            this.setAuthData(data);
            AUTHRED.dispatchLoginSuccess(this.dispatch, data);
            return true;
        } catch (error) {
            AUTHRED.dispatchLoginFail(this.dispatch, error);
            this.unauthResponseClear();
            return false;
        }
    }

    logout(): Promise<void> {
        AUTHRED.dispatchLogoutRequest(this.dispatch);
        try {
            const api = new LogoutApi(this.configuration);
            api.logoutCreate();
            this.unauthResponseClear();
            AUTHRED.dispatchLogoutSuccess(this.dispatch);
        } catch (error) {
            AUTHRED.dispatchLogoutFail(this.dispatch, error);
        }
        return;
    }

    async register(data: RegisterUserRequest): Promise<boolean> {
        const payload = {registerUserRequest: data};
        const api = new RegisterApi(this.configuration);
        return this.executeApiCall(
            () => api.registerCreate(payload),
            AUTHRED.dispatchRegisterRequest,
            AUTHRED.dispatchRegisterSuccess,
            AUTHRED.dispatchRegisterFail
        );
    }

    async registerCheckEmail(
        registerData: CheckRegisteringEmailRequest
    ): Promise<CheckRegisteringEmailResponse> {
        if (registerData.email === '') return {emailValid: true};
        const payload = {
            checkRegisteringEmailRequest: registerData,
        };
        const api = new RegisterApi(this.configuration);
        return this.executeApiCallReturnObject(
            () => api.registerCheckEmailCreate(payload),
            AUTHRED.dispatchRegisterCheckEmailRequest,
            AUTHRED.dispatchRegisterCheckEmailSuccess,
            AUTHRED.dispatchRegisterCheckEmailFail
        );
    }

    async registerCheckClinic(
        registerData: CheckClinicAddressRequest
    ): Promise<CheckClinicAddressResponse> {
        if (registerData.postcode === '') return {addressValid: false};
        const payload = {checkClinicAddressRequest: registerData};
        const api = new RegisterApi(this.configuration);
        return this.executeApiCallReturnObject(
            () => api.registerCheckClinicCreate(payload),
            AUTHRED.dispatchRegisterCheckClinicRequest,
            AUTHRED.dispatchRegisterCheckClinicSuccess,
            AUTHRED.dispatchRegisterCheckClinicFail
        );
    }

    async verifyUserEmail(
        code: string,
        time: string,
        user: string
    ): Promise<boolean> {
        const payload = {code, time, user};
        const api = new RegisterApi(this.configuration);
        return this.executeApiCall(
            () => api.registerVerifyEmailUsertimecodeCreate(payload),
            AUTHRED.dispatchVerifyUserEmailRequest,
            AUTHRED.dispatchVerifyUserEmailSuccess,
            AUTHRED.dispatchVerifyUserEmailFail
        );
    }

    async resetPassword(email: string): Promise<boolean> {
        const payload = {resetUserPasswordRequest: {email}};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userPasswordResetCreate(payload),
            AUTHRED.dispatchResetPasswordSetNewRequest,
            AUTHRED.dispatchResetPasswordSetNewSuccess,
            AUTHRED.dispatchResetPasswordSetNewFail
        );
    }

    async resetPasswordSetNew(
        data: ResetUserPasswordSetNewRequest
    ): Promise<boolean> {
        const payload = {resetUserPasswordSetNewRequest: data};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userPasswordResetSetNewCreate(payload),
            AUTHRED.dispatchResetPasswordSetNewRequest,
            AUTHRED.dispatchResetPasswordSetNewSuccess,
            AUTHRED.dispatchResetPasswordSetNewFail
        );
    }

    async sendResetPassword(data: UpdateUserPasswordRequest): Promise<boolean> {
        const payload = {updateUserPasswordRequest: data};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userPasswordUpdateUpdate(payload),
            AUTHRED.dispatchSendResetPasswordRequest,
            AUTHRED.dispatchSendResetPasswordSuccess,
            AUTHRED.dispatchSendResetPasswordFail
        );
    }

    async updateUserPassword(
        data: UpdateUserPasswordRequest
    ): Promise<boolean> {
        const payload = {updateUserPasswordRequest: data};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userPasswordUpdateUpdate(payload),
            AUTHRED.dispatchUpdateUserPasswordRequest,
            AUTHRED.dispatchUpdateUserPasswordSuccess,
            AUTHRED.dispatchUpdateUserPasswordFail
        );
    }

    RedirectIfAuthDetailsAvailable(): void {
        this.checkIsAuthenticated();
        useEffect(() => {
            if (this.isAuthenticated) {
                this.goToDashboardPage();
            }
        }, [this.isAuthenticated, this.navigate]);
    }

    hasAuthToken(): boolean {
        const authToken = Cookies.get(ApiClientConstants.AUTH_TOKEN);
        return authToken && authToken !== '';
    }

    unauthResponseClear(): void {
        unauthResponseClear();
    }

    async deactivateAccount(userProfileId: number): Promise<boolean> {
        const payload = {userProfileId};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userDeactivateAccountDestroy(payload),
            AUTHRED.dispatchDeactivateAccountRequest,
            AUTHRED.dispatchDeactivateAccountSuccess,
            AUTHRED.dispatchDeactivateAccountFail
        );
    }

    async sendErrorNotification(errorDescription: string): Promise<boolean> {
        const payload = {sendErrorInApplictionInfo: {errorDescription}};
        const api = new ErrorNotificationApi(this.configuration);
        return this.executeApiCall(
            () => api.errorNotificationCreate(payload),
            AUTHRED.dispatchSendErrorNotificationRequest,
            AUTHRED.dispatchSendErrorNotificationSuccess,
            AUTHRED.dispatchSendErrorNotificationFail
        );
    }
}
