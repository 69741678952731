import {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';
import {reformatDateDash} from '@/actions/general';

interface UserFormData {
    title: string;
    firstName: string;
    lastName: string;
    dob: string;
    clinicianDescription: string;
}

interface UserUpdateRequestReturn {
    userFormData: UserFormData;
    updateFormData: (data: Partial<UserFormData>) => void;
    setDOB: (e: React.ChangeEvent<HTMLElement>) => void;
    isDOBValid: boolean;
}

function createInitUserFormData() {
    return {
        title: '',
        firstName: '',
        lastName: '',
        dob: '',
        clinicianDescription: '',
    };
}

const useUserUpdateRequest = (): UserUpdateRequestReturn => {
    // Initialize the user form data with the user's current data
    const clinicianProfiles = useSelector(
        (state: RootState) => state.profile.clinicianProfiles
    );
    let initFormData = createInitUserFormData();
    if (clinicianProfiles) {
        const userProfile = clinicianProfiles[0].userProfile;
        initFormData = {
            title: userProfile.title,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            dob: reformatDateDash(userProfile.dob),
            clinicianDescription: userProfile.clinicianDescription,
        };
    }

    const [userFormData, setUserFormData] =
        useState<UserFormData>(initFormData);
    const [isDOBValid, setIsDOBValid] = useState(true);
    const updateFormData = (data: Partial<UserFormData>) =>
        setUserFormData({...userFormData, ...data});

    const checkAge = (DOB: string) => {
        if (!DOB) {
            setIsDOBValid(false); // If no date is selected, consider it not old enough.
            return;
        }

        // Parse the birthDate string to a Date object
        const birthDateObject = new Date(DOB);
        const currentDate = new Date();

        // Calculate the difference in years
        const ageDifference =
            currentDate.getFullYear() - birthDateObject.getFullYear();

        // Check if the person is 18 or more years old
        const isValid = ageDifference >= 18 && ageDifference <= 100;
        setIsDOBValid(isValid);
    };

    const setDOB = (e: React.ChangeEvent<HTMLInputElement>) => {
        checkAge(e.target.value);
        setUserFormData({...userFormData, dob: e.target.value});
    };
    return {
        userFormData,
        updateFormData,
        setDOB,
        isDOBValid,
    };
};

export default useUserUpdateRequest;
