import {createActionTypes, ActionStatus} from './requestConstant';

export const PATIENTS_LIST_ACTION: ActionStatus =
    createActionTypes('PATIENTS_LIST');
export const CURRENT_PATIENT_ACTION: ActionStatus =
    createActionTypes('CURRENT_PATIENT');
export const CREATE_PATIENT_ACTION: ActionStatus =
    createActionTypes('CREATE_PATIENT');
export const CLINICIAN_PATIENTS_ACTION: ActionStatus =
    createActionTypes('CLINICIAN_PATIENTS');
export const PATIENT_DELETE_ACTION: ActionStatus =
    createActionTypes('PATIENT_DELETE');
export const PATIENT_UPDATE_ACTION: ActionStatus =
    createActionTypes('PATIENT_UPDATE');
export const PATIENT_REFERRALS_ACTION: ActionStatus =
    createActionTypes('PATIENT_REFERRALS');
export const GPS_COORDS_ACTION: ActionStatus = createActionTypes('GPS_COORDS');

// export const PATIENT_ACTIVE_TOGGLE_ACTION: ActionStatus = createActionTypes( 'PATIENT_ACTIVE_TOGGLE' );

// export const PATIENT_ACTIVE_TOGGLE_REQUEST: string =
// 	'PATIENT_ACTIVE_TOGGLE_REQUEST';
// export const PATIENT_ACTIVE_TOGGLE_SUCCESS: string =
// 	'PATIENT_ACTIVE_TOGGLE_SUCCESS';
// export const PATIENT_ACTIVE_TOGGLE_FAIL: string = 'PATIENT_ACTIVE_TOGGLE_FAIL';
