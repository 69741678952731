import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';
import {useReferralService} from '@/actions';
import {
    useReferralOutboundScreenReturn,
    ReferralOutboundTabs,
} from './interface';

const useReferralInboundScreen = (
    tab: ReferralOutboundTabs
): useReferralOutboundScreenReturn => {
    const [selectedTab, setSelectedTab] = useState<ReferralOutboundTabs>(tab);
    const referralService = useReferralService();

    const handleReferralsList = useCallback(async () => {
        await referralService.getClinicianReferralsListOutbound();
    }, []);

    useEffect(() => {
        handleReferralsList();
    }, []);
    const outboundWaiting = useSelector(
        (state: RootState) => state.referrals.referralsMade.waiting
    );
    const outboundAccepted = useSelector(
        (state: RootState) => state.referrals.referralsMade.accepted
    );
    const outboundBooked = useSelector(
        (state: RootState) => state.referrals.referralsMade.booked
    );
    const outboundDna = useSelector(
        (state: RootState) => state.referrals.referralsMade.dna
    );

    return {
        selectedTab,
        setSelectedTab,
        outboundWaiting,
        outboundAccepted,
        outboundBooked,
        outboundDna,
    };
};
export default useReferralInboundScreen;
