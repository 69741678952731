import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Select, {SingleValue} from 'react-select';
import useTitleDropdown from './useTitleDropdown';

interface TitleDropdownProps {
    title?: string | null;
    sendTitle: (value: {[key: string]: string}) => void;
    valueKey?: string;
}

const TitleDropdown: React.FC<TitleDropdownProps> = ({
    title = null,
    sendTitle,
    valueKey = 'title',
}) => {
    const {titleOptions, customStyles, currentTitle} = useTitleDropdown(title);

    // Handle selection change
    const handleChange = (opt: SingleValue<{label: string; value: string}>) => {
        if (opt) {
            sendTitle({[valueKey]: opt.value});
        }
    };

    return (
        <Row className="mb-2 custom-dropdown">
            <Col>
                <Select
                    options={titleOptions}
                    onChange={handleChange}
                    placeholder={currentTitle}
                    styles={customStyles}
                    className="custom-dropdown-select"
                />
            </Col>
        </Row>
    );
};

export default TitleDropdown;
