/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestHelpRequest
 */
export interface RequestHelpRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestHelpRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestHelpRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestHelpRequest
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof RequestHelpRequest
     */
    message: string;
}

/**
 * Check if a given object implements the RequestHelpRequest interface.
 */
export function instanceOfRequestHelpRequest(value: object): value is RequestHelpRequest {
    if (!('subject' in value) || value['subject'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function RequestHelpRequestFromJSON(json: any): RequestHelpRequest {
    return RequestHelpRequestFromJSONTyped(json, false);
}

export function RequestHelpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestHelpRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'email': json['email'] == null ? undefined : json['email'],
        'subject': json['subject'],
        'message': json['message'],
    };
}

export function RequestHelpRequestToJSON(json: any): RequestHelpRequest {
    return RequestHelpRequestToJSONTyped(json, false);
}

export function RequestHelpRequestToJSONTyped(value?: RequestHelpRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'email': value['email'],
        'subject': value['subject'],
        'message': value['message'],
    };
}

