import React, {useEffect, useState} from 'react';
import {Button, Tabs, Tab} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useAdminService, DownloadService} from '@/actions';
import Loader from '@components/app/Loader';
import {InsuranceAgreement} from '@/generated-client';
import AdminPagTable, {SliceIndexes} from './AdminPaginatedTable';
import {RootState} from '@/reducers';
import {initSliceIndex, formatId} from './PagTableConsts';

enum InsurersTabs {
    UNVERIFIED = 'unverifiedInsurers',
    INSURERS = 'insurers',
}

const AdminInsurersTable: React.FC = () => {
    const adminService = useAdminService();

    const handleGetInsurers = async () => {
        await adminService.getAdminInsurers();
    };

    useEffect(() => {
        handleGetInsurers();
    }, []);

    const setInsurerVerified = async (insurerId: number) => {
        await adminService.verifyInsurer(insurerId);
        handleGetInsurers(); // Refresh the list after setting an insurer as verified
    };

    const unverifiedInsurers = useSelector(
        (state: RootState) => state.admin.insurers.unverifiedInsurers
    );
    const insurers = useSelector(
        (state: RootState) => state.admin.insurers.insurers
    );

    const [unverifiedSlice, setUnverifiedSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [insurersSlice, setInsurersSlice] =
        useState<SliceIndexes>(initSliceIndex);

    const tabTitles = {
        [InsurersTabs.UNVERIFIED]: 'Unverified Insurers',
        [InsurersTabs.INSURERS]: 'Verified Insurers',
    };

    const tabHeaders = {
        [InsurersTabs.UNVERIFIED]: ['ID', 'INSURER', 'VERIFY'],
        [InsurersTabs.INSURERS]: ['ID', 'INSURER'],
    };

    const tabItems = {
        [InsurersTabs.UNVERIFIED]: unverifiedInsurers,
        [InsurersTabs.INSURERS]: insurers,
    };

    const createUnverifiedRows = (ins: InsuranceAgreement, index: number) => (
        <tr key={`${index}-${ins.id}`}>
            <td>{formatId(ins.id)}</td>
            <td>{ins.provider}</td>
            <td>
                <Button
                    id={`insBtn${ins.id}`}
                    variant="info"
                    className="btn-sm"
                    onClick={() => setInsurerVerified(ins.id)}
                >
                    Set verified
                </Button>
            </td>
        </tr>
    );

    const createInsurerRows = (ins: InsuranceAgreement, index: number) => (
        <tr key={`${index}-${ins.id}`}>
            <td>{formatId(ins.id)}</td>
            <td>{ins.provider}</td>
        </tr>
    );

    const tabCreateRows = {
        [InsurersTabs.UNVERIFIED]: createUnverifiedRows,
        [InsurersTabs.INSURERS]: createInsurerRows,
    };

    const tabSlices = {
        [InsurersTabs.UNVERIFIED]: unverifiedSlice,
        [InsurersTabs.INSURERS]: insurersSlice,
    };

    const tabSliceUpdaters = {
        [InsurersTabs.UNVERIFIED]: setUnverifiedSlice,
        [InsurersTabs.INSURERS]: setInsurersSlice,
    };

    const loadingView = (
        <>
            <div className="gap mt-5" />
            <Loader text="Loading admin insurers list..." />
        </>
    );

    const onExportClick = () => {
        const downloadService = new DownloadService();
        downloadService.downloadAdminInsuranceAgreementsList();
    };

    return (
        <>
            <Button variant="info" className="mb-3" onClick={onExportClick}>
                Download Insurers List
            </Button>
            <Tabs
                defaultActiveKey={InsurersTabs.INSURERS}
                id="custom-tabs"
                className="custom-tabs mb-3"
            >
                {Object.values(InsurersTabs).map((tabKey) => (
                    <Tab
                        eventKey={tabKey}
                        title={tabTitles[tabKey]}
                        key={tabKey}
                        className="custom-tabs"
                    >
                        {adminService.requestLoading ? (
                            loadingView
                        ) : (
                            <AdminPagTable
                                headers={tabHeaders[tabKey]}
                                items={tabItems[tabKey]}
                                createItemRows={tabCreateRows[tabKey]}
                                sliceIndex={tabSlices[tabKey]}
                                updateSlice={tabSliceUpdaters[tabKey]}
                                emptyTableMessage={`No ${tabTitles[tabKey]} have been found`}
                            />
                        )}
                    </Tab>
                ))}
            </Tabs>
        </>
    );
};

export default AdminInsurersTable;
