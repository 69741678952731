/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GpsFromPostcodeRequest,
  GpsFromPostcodeResponse,
} from '../models/index';
import {
    GpsFromPostcodeRequestFromJSON,
    GpsFromPostcodeRequestToJSON,
    GpsFromPostcodeResponseFromJSON,
    GpsFromPostcodeResponseToJSON,
} from '../models/index';

export interface GetGpsCreateRequest {
    gpsFromPostcodeRequest: GpsFromPostcodeRequest;
}

/**
 * 
 */
export class GetGpsApi extends runtime.BaseAPI {

    /**
     * Get GPS coordinates from a given postcode.  This endpoint takes a POST request with JSON data containing a \'postCode\' field. It uses the provided postcode to fetch GPS coordinates using a utility class.  Parameters: - `postCode` (str): The postcode for which GPS coordinates are requested.  Example POST data: ``` {     \"postCode\": \"12345\" } ```  Responses: - 200 OK: Returns the GPS coordinates in the response data. - 400 Bad Request: Indicates a validation error if the \'postCode\' field is missing. - 500 Internal Server Error: Indicates an error during GPS coordinate retrieval.  Returns: A JSON response containing the GPS coordinates.  Example response data: ``` {     \"latitude\": 123.456,     \"longitude\": -78.901 } ```  Note: - This endpoint is accessible to any user (no authentication required). - Ensure the utility class `GPSFromAddress` is properly implemented and available.
     */
    async getGpsCreateRaw(requestParameters: GetGpsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GpsFromPostcodeResponse>> {
        if (requestParameters['gpsFromPostcodeRequest'] == null) {
            throw new runtime.RequiredError(
                'gpsFromPostcodeRequest',
                'Required parameter "gpsFromPostcodeRequest" was null or undefined when calling getGpsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/get-gps/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GpsFromPostcodeRequestToJSON(requestParameters['gpsFromPostcodeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GpsFromPostcodeResponseFromJSON(jsonValue));
    }

    /**
     * Get GPS coordinates from a given postcode.  This endpoint takes a POST request with JSON data containing a \'postCode\' field. It uses the provided postcode to fetch GPS coordinates using a utility class.  Parameters: - `postCode` (str): The postcode for which GPS coordinates are requested.  Example POST data: ``` {     \"postCode\": \"12345\" } ```  Responses: - 200 OK: Returns the GPS coordinates in the response data. - 400 Bad Request: Indicates a validation error if the \'postCode\' field is missing. - 500 Internal Server Error: Indicates an error during GPS coordinate retrieval.  Returns: A JSON response containing the GPS coordinates.  Example response data: ``` {     \"latitude\": 123.456,     \"longitude\": -78.901 } ```  Note: - This endpoint is accessible to any user (no authentication required). - Ensure the utility class `GPSFromAddress` is properly implemented and available.
     */
    async getGpsCreate(requestParameters: GetGpsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GpsFromPostcodeResponse> {
        const response = await this.getGpsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
