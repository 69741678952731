import React from 'react';
import {Card} from 'react-bootstrap';

interface MissingInfoBoxProps {
    title: string;
    subtitle?: string; // Optional subtitle
}

const MissingInfoBox: React.FC<MissingInfoBoxProps> = ({title, subtitle}) => {
    return (
        <Card
            className="mb-2 rounded d-flex flex-fill"
            style={{padding: '1rem'}}
        >
            <Card.Title>{title}</Card.Title>
            {subtitle && (
                <Card.Subtitle className="text-muted">{subtitle}</Card.Subtitle>
            )}
        </Card>
    );
};

export default MissingInfoBox;
