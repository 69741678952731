import React from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import {useAuthService} from '@/actions';

const AuthenticatedRoutes: React.FC = () => {
    const authService = useAuthService();

    if (!authService.hasAuthToken()) {
        return <Navigate to="/login" />;
    }
    return <Outlet />;
};

export default AuthenticatedRoutes;
