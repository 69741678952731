import React from 'react';
import {Row, Col} from 'react-bootstrap';

const ServerDownErrorScreen: React.FC = () => {
    return (
        <>
            <Row className="text-center p-3">
                <Col className="text-center medr-text">
                    <h1>503 - Server Error</h1>
                    <p>
                        The MedR server is currently unavailable. Please try
                        again later. We apologise for the inconvenience.
                    </p>
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <a href="/dashboard">
                        <img
                            src="/logos/white_logo_transparent_background.png"
                            alt="404"
                            className="img-404"
                        />
                    </a>
                </Col>
            </Row>
        </>
    );
};

export default ServerDownErrorScreen;
