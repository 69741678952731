import {createActionTypes, ActionStatus} from './requestConstant';

export const PLATFORM_STATS_ACTION: ActionStatus =
    createActionTypes('PLATFORM_STATS');

export const ADMIN_USERS_LIST_ACTION: ActionStatus =
    createActionTypes('ADMIN_USERS_LIST');

export const ADMIN_PATIENTS_LIST_ACTION: ActionStatus = createActionTypes(
    'ADMIN_PATIENTS_LIST'
);

export const ADMIN_REFERRALS_LIST_ACTION: ActionStatus = createActionTypes(
    'ADMIN_REFERRALS_LIST'
);

export const ADMIN_CLINICIANS_LIST_ACTION: ActionStatus = createActionTypes(
    'ADMIN_CLINICIANS_LIST'
);

export const ADMIN_SET_USER_VERIFIED_ACTION: ActionStatus = createActionTypes(
    'ADMIN_SET_USER_VERIFIED'
);

export const REQUEST_NEW_FEATURE_ACTION: ActionStatus = createActionTypes(
    'REQUEST_NEW_FEATURE'
);

export const ADMIN_INSURERS_LIST_ACTION: ActionStatus = createActionTypes(
    'ADMIN_INSURERS_LIST'
);

export const ADMIN_INSURER_VERIFY_ACTION: ActionStatus = createActionTypes(
    'ADMIN_INSURER_VERIFY'
);

export const ADD_NEW_ADMIN_ACTION: ActionStatus =
    createActionTypes('ADD_NEW_ADMIN');
