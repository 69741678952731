/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlatformStats } from './PlatformStats';
import {
    PlatformStatsFromJSON,
    PlatformStatsFromJSONTyped,
    PlatformStatsToJSON,
    PlatformStatsToJSONTyped,
} from './PlatformStats';

/**
 * 
 * @export
 * @interface GetPlatformStatisticsResponse
 */
export interface GetPlatformStatisticsResponse {
    /**
     * 
     * @type {PlatformStats}
     * @memberof GetPlatformStatisticsResponse
     */
    platformStats: PlatformStats;
}

/**
 * Check if a given object implements the GetPlatformStatisticsResponse interface.
 */
export function instanceOfGetPlatformStatisticsResponse(value: object): value is GetPlatformStatisticsResponse {
    if (!('platformStats' in value) || value['platformStats'] === undefined) return false;
    return true;
}

export function GetPlatformStatisticsResponseFromJSON(json: any): GetPlatformStatisticsResponse {
    return GetPlatformStatisticsResponseFromJSONTyped(json, false);
}

export function GetPlatformStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPlatformStatisticsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'platformStats': PlatformStatsFromJSON(json['platformStats']),
    };
}

export function GetPlatformStatisticsResponseToJSON(json: any): GetPlatformStatisticsResponse {
    return GetPlatformStatisticsResponseToJSONTyped(json, false);
}

export function GetPlatformStatisticsResponseToJSONTyped(value?: GetPlatformStatisticsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'platformStats': PlatformStatsToJSON(value['platformStats']),
    };
}

