import React, {useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import PatientSearchBar from '../../patient/PatientSearchBar';
import PatientEntryForm from '../../patient/PatientEntryForm';
import {useReferralEntryContext} from '@contexts/ReferralEntryContext';

interface ReferralStepPatientProps {
    showCleanButton: boolean;
}

const ReferralStepPatient: React.FC<ReferralStepPatientProps> = ({
    showCleanButton,
}) => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const {
        patientId,
        patientSelected,
        sendPxId,
        addNewPatientSuccess,
        clearPatient,
    } = useReferralEntryContext();

    return (
        <div
            className="medr-layout text-center newpatient-page"
            style={{
                maxWidth: '700px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <Row>
                <Col>
                    <h4>Select patient to refer</h4>
                </Col>
            </Row>

            <PatientSearchBar
                currentPatientId={patientId}
                sendPxId={sendPxId}
            />

            <Row className="mt-5">
                <Col>
                    <h4>
                        {patientSelected === false
                            ? 'Or add new patient'
                            : 'Update selected patient'}
                    </h4>
                </Col>
                {showCleanButton && (
                    <Col>
                        <Button
                            variant="info"
                            onClick={clearPatient}
                            disabled={!patientSelected}
                        >
                            {' '}
                            Clear patient
                        </Button>
                    </Col>
                )}
            </Row>
            <PatientEntryForm
                patientId={patientId}
                isSuccessful={addNewPatientSuccess}
            />
        </div>
    );
};

export default ReferralStepPatient;
