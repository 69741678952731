/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestNewFeatureRequest
 */
export interface RequestNewFeatureRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestNewFeatureRequest
     */
    suggestion: string;
}

/**
 * Check if a given object implements the RequestNewFeatureRequest interface.
 */
export function instanceOfRequestNewFeatureRequest(value: object): value is RequestNewFeatureRequest {
    if (!('suggestion' in value) || value['suggestion'] === undefined) return false;
    return true;
}

export function RequestNewFeatureRequestFromJSON(json: any): RequestNewFeatureRequest {
    return RequestNewFeatureRequestFromJSONTyped(json, false);
}

export function RequestNewFeatureRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestNewFeatureRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'suggestion': json['suggestion'],
    };
}

export function RequestNewFeatureRequestToJSON(json: any): RequestNewFeatureRequest {
    return RequestNewFeatureRequestToJSONTyped(json, false);
}

export function RequestNewFeatureRequestToJSONTyped(value?: RequestNewFeatureRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'suggestion': value['suggestion'],
    };
}

