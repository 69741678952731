/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GpsFromPostcodeResponse
 */
export interface GpsFromPostcodeResponse {
    /**
     * 
     * @type {string}
     * @memberof GpsFromPostcodeResponse
     */
    postcode: string;
    /**
     * 
     * @type {number}
     * @memberof GpsFromPostcodeResponse
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof GpsFromPostcodeResponse
     */
    lng: number;
}

/**
 * Check if a given object implements the GpsFromPostcodeResponse interface.
 */
export function instanceOfGpsFromPostcodeResponse(value: object): value is GpsFromPostcodeResponse {
    if (!('postcode' in value) || value['postcode'] === undefined) return false;
    if (!('lat' in value) || value['lat'] === undefined) return false;
    if (!('lng' in value) || value['lng'] === undefined) return false;
    return true;
}

export function GpsFromPostcodeResponseFromJSON(json: any): GpsFromPostcodeResponse {
    return GpsFromPostcodeResponseFromJSONTyped(json, false);
}

export function GpsFromPostcodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GpsFromPostcodeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'postcode': json['postcode'],
        'lat': json['lat'],
        'lng': json['lng'],
    };
}

export function GpsFromPostcodeResponseToJSON(json: any): GpsFromPostcodeResponse {
    return GpsFromPostcodeResponseToJSONTyped(json, false);
}

export function GpsFromPostcodeResponseToJSONTyped(value?: GpsFromPostcodeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'postcode': value['postcode'],
        'lat': value['lat'],
        'lng': value['lng'],
    };
}

