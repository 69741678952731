import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';
import {useReferralService} from '@/actions';
import {useReferralInboundScreenReturn, ReferralInboundTabs} from './interface';

const useReferralInboundScreen = (
    tab: ReferralInboundTabs
): useReferralInboundScreenReturn => {
    const [selectedTab, setSelectedTab] = useState<ReferralInboundTabs>(tab);
    const referralService = useReferralService();

    const handleReferralsList = useCallback(async () => {
        await referralService.getClinicianReferralsListInbound();
    }, []);

    useEffect(() => {
        handleReferralsList();
    }, []);
    const inboundPending = useSelector(
        (state: RootState) => state.referrals.referralsReceived.waiting
    );
    const inboundAccepted = useSelector(
        (state: RootState) => state.referrals.referralsReceived.accepted
    );
    const inboundBooked = useSelector(
        (state: RootState) => state.referrals.referralsReceived.booked
    );
    const inboundDna = useSelector(
        (state: RootState) => state.referrals.referralsReceived.dna
    );
    const referralsOpen = useSelector(
        (state: RootState) => state.referrals.referralsOpen
    );

    return {
        selectedTab,
        setSelectedTab,
        inboundPending,
        inboundAccepted,
        inboundBooked,
        inboundDna,
        referralsOpen,
    };
};
export default useReferralInboundScreen;
