/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
    ReferralToJSONTyped,
} from './Referral';

/**
 * 
 * @export
 * @interface ReferralToggleCompleteResponse
 */
export interface ReferralToggleCompleteResponse {
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralToggleCompleteResponse
     */
    accepted: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralToggleCompleteResponse
     */
    booked: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralToggleCompleteResponse
     */
    dna: Array<Referral>;
}

/**
 * Check if a given object implements the ReferralToggleCompleteResponse interface.
 */
export function instanceOfReferralToggleCompleteResponse(value: object): value is ReferralToggleCompleteResponse {
    if (!('accepted' in value) || value['accepted'] === undefined) return false;
    if (!('booked' in value) || value['booked'] === undefined) return false;
    if (!('dna' in value) || value['dna'] === undefined) return false;
    return true;
}

export function ReferralToggleCompleteResponseFromJSON(json: any): ReferralToggleCompleteResponse {
    return ReferralToggleCompleteResponseFromJSONTyped(json, false);
}

export function ReferralToggleCompleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralToggleCompleteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'accepted': ((json['accepted'] as Array<any>).map(ReferralFromJSON)),
        'booked': ((json['booked'] as Array<any>).map(ReferralFromJSON)),
        'dna': ((json['dna'] as Array<any>).map(ReferralFromJSON)),
    };
}

export function ReferralToggleCompleteResponseToJSON(json: any): ReferralToggleCompleteResponse {
    return ReferralToggleCompleteResponseToJSONTyped(json, false);
}

export function ReferralToggleCompleteResponseToJSONTyped(value?: ReferralToggleCompleteResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'accepted': ((value['accepted'] as Array<any>).map(ReferralToJSON)),
        'booked': ((value['booked'] as Array<any>).map(ReferralToJSON)),
        'dna': ((value['dna'] as Array<any>).map(ReferralToJSON)),
    };
}

