import {Reducer} from 'react';
import * as CONSTS from '@constants/profileConstants';
import * as USRCONSTS from '@/constants/userConstants';
import * as AUTHCON from '@constants/authConstants';
import * as CLINCON from '@constants/clinicianConstants';
import {LOGOUT_ACTION} from '@constants/authConstants';
import {
    createDefaultUserProfile,
    createDefaultNotificationSettings,
} from '@/constants/initDataConstants';
import * as ITF from '@reducers/profileReducer/interfaces';
import {ProfileState} from './interfaces';
import {LogoutSuccessAction} from '@reducers/authReducer';

const initialState: ProfileState = {
    loading: false,
    loadingAnalytics: false,
    loadingReferralNotifications: false,
    userProfile: createDefaultUserProfile(),
    isDelegate: false,
    delegates: [],
    pendingDelegates: [],
    clinicianProfiles: [], /// update
    notificationSettings: createDefaultNotificationSettings(),
    analytics: {
        number: null,
        noPatientsMade: null,
        noPatientsReceived: null,
        noReferralsMade: null,
        noReferralsReceived: null,
        topReferredTo: [],
        topReferralsFrom: [],
        favouriteClinicians: [],
    },
    referralNotifications: {nReferrals: -1},
    referralPermissions: null,
};

// INCOMING REQUEST ACTIONS
function isReferralNotificationRequestAction(
    action: ITF.ProfileActionRequests
): action is ITF.ProfileActionRequests {
    return action.type === CONSTS.REFERRAL_NOTIFICATIONS_ACTION.REQUEST;
}

function handleReferralNotificationRequestAction(
    state: ProfileState
): ProfileState {
    return {
        ...state,
        loadingReferralNotifications: true,
    };
}

function isUserSummaryAnalyticsRequestAction(
    action: ITF.ProfileActionRequests
): action is ITF.ProfileActionRequests {
    return action.type === CONSTS.USER_SUMMARY_ANALYTICS_ACTION.REQUEST;
}

function handleUserSummaryAnalyticsRequestAction(
    state: ProfileState
): ProfileState {
    return {
        ...state,
        loadingAnalytics: true,
    };
}

function isRequestAction(
    action: ITF.ProfileActionRequests
): action is ITF.ProfileActionRequests {
    return [
        CONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.REQUEST,
        AUTHCON.USER_DELEGATES_ACTION.REQUEST,
        CONSTS.LOAD_USER_PROFILE_ACTION.REQUEST,
        CLINCON.CLINICIAN_CREATE_ACTION.REQUEST,
        CLINCON.CLINICIAN_ROLE_REMOVE_REQ_ACTION.REQUEST,
        CONSTS.UPDATE_USER_PROFILE_ACTION.REQUEST,
        CLINCON.CLINICIAN_UPDATE_ACTION.REQUEST,
        CONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.REQUEST,
        AUTHCON.DEACTIVATE_DELEGATE_ACTION.REQUEST,
        AUTHCON.RESTORE_DELEGATE_ACTION.REQUEST,
        AUTHCON.UPDATE_DELEGATE_PERMISSION_ACTION.REQUEST,
        USRCONSTS.UPDATE_PROFILE_PIC_ACTION.REQUEST,
    ].includes(action.type);
}

function handleRequestAction(state: ProfileState): ProfileState {
    return {
        ...state,
        loading: true,
    };
}

// INCOMING SUCCESS ACTIONS
///

function isLoadUserProfileSuccessAction(
    action: ITF.LoadUserProfileSuccessAction
): action is ITF.LoadUserProfileSuccessAction {
    return action.type === CONSTS.LOAD_USER_PROFILE_ACTION.SUCCESS;
}

function handleLoadUserProfileSuccessAction(
    state: ProfileState,
    action: ITF.LoadUserProfileSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isUpdateUserProfileSuccessAction(
    action: ITF.UpdateUserProfileSuccessAction
): action is ITF.UpdateUserProfileSuccessAction {
    return action.type === CONSTS.UPDATE_USER_PROFILE_ACTION.SUCCESS;
}

function handleUpdateUserProfileSuccessAction(
    state: ProfileState,
    action: ITF.UpdateUserProfileSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isUpdatProfilePictureSuccessAction(
    action: ITF.UpdateProfilePictureSuccessAction
): action is ITF.UpdateProfilePictureSuccessAction {
    return action.type === USRCONSTS.UPDATE_PROFILE_PIC_ACTION.SUCCESS;
}

function handleUpdateProfilePictureSuccessAction(
    state: ProfileState,
    action: ITF.UpdateProfilePictureSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isUpdateUserNotificationsSuccessAction(
    action: ITF.UpdateUserNotificationsSuccessAction
): action is ITF.UpdateUserNotificationsSuccessAction {
    return action.type === CONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.SUCCESS;
}

function handleUpdateUserNotificationsSuccessAction(
    state: ProfileState,
    action: ITF.UpdateUserNotificationsSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isUserDelegatesSuccessAction(
    action: ITF.UserDelegatesSuccessAction
): action is ITF.UserDelegatesSuccessAction {
    return action.type === AUTHCON.USER_DELEGATES_ACTION.SUCCESS;
}

function handleUserDelegatesSuccessAction(
    state: ProfileState,
    action: ITF.UserDelegatesSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isUpdateReferralContactsSuccessAction(
    action: ITF.UpdateReferralContactsSuccessAction
): action is ITF.UpdateReferralContactsSuccessAction {
    return action.type === CONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.SUCCESS;
}

function handleUpdateReferralContactsSuccessAction(
    state: ProfileState,
    action: ITF.UpdateReferralContactsSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isDeactivateDelegateSuccessAction(
    action: ITF.DeactivateDelegateSuccessAction
): action is ITF.DeactivateDelegateSuccessAction {
    return action.type === AUTHCON.DEACTIVATE_DELEGATE_ACTION.SUCCESS;
}

function handleDeactivateDelegateSuccessAction(
    state: ProfileState,
    action: ITF.DeactivateDelegateSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isRestoreDelegateSuccessAction(
    action: ITF.RestoreDelegateSuccessAction
): action is ITF.RestoreDelegateSuccessAction {
    return action.type === AUTHCON.RESTORE_DELEGATE_ACTION.SUCCESS;
}

function handleRestoreDelegateSuccessAction(
    state: ProfileState,
    action: ITF.RestoreDelegateSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isUpdateDelegatePermissionSuccessAction(
    action: ITF.UpdateDelegatePermissionSuccessAction
): action is ITF.UpdateDelegatePermissionSuccessAction {
    return action.type === AUTHCON.UPDATE_DELEGATE_PERMISSION_ACTION.SUCCESS;
}

function handleUpdateDelegatePermissionSuccessAction(
    state: ProfileState,
    action: ITF.UpdateDelegatePermissionSuccessAction
): ProfileState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isUserSummaryAnalyticsSuccessAction(
    action: ITF.UserSummaryAnalyticsSuccessAction
): action is ITF.UserSummaryAnalyticsSuccessAction {
    return action.type === CONSTS.USER_SUMMARY_ANALYTICS_ACTION.SUCCESS;
}

function handleUserSummaryAnalyticsSuccessAction(
    state: ProfileState,
    action: ITF.UserSummaryAnalyticsSuccessAction
): ProfileState {
    return {
        ...state,
        loadingAnalytics: false,
        analytics: action.payload,
    };
}

function isReferralNotificationsSuccessAction(
    action: ITF.ReferralNotificationSuccessAction
): action is ITF.ReferralNotificationSuccessAction {
    return action.type === CONSTS.REFERRAL_NOTIFICATIONS_ACTION.SUCCESS;
}

function handleReferralNotificationsSuccessAction(
    state: ProfileState,
    action: ITF.ReferralNotificationSuccessAction
): ProfileState {
    return {
        ...state,
        loadingReferralNotifications: false,
        referralNotifications: action.payload,
    };
}

function isClinicianCreateSuccessAction(
    action: ITF.ClinicianCreateSuccessAction
): action is ITF.ClinicianCreateSuccessAction {
    return action.type === CLINCON.CLINICIAN_CREATE_ACTION.SUCCESS;
}

function handleClinicianCreateSuccessAction(
    state: ProfileState,
    action: ITF.ClinicianCreateSuccessAction
): ProfileState {
    return {
        ...state,
        loading: false,
        clinicianProfiles: action.payload.clinProfs,
    };
}

function isClinicianUpdateSuccessAction(
    action: ITF.ClinicianUpdateSuccessAction
): action is ITF.ClinicianUpdateSuccessAction {
    return action.type === CLINCON.CLINICIAN_UPDATE_ACTION.SUCCESS;
}

function handleClinicianUpdateSuccessAction(
    state: ProfileState,
    action: ITF.ClinicianUpdateSuccessAction
): ProfileState {
    return {
        ...state,
        loading: false,
        clinicianProfiles: action.payload.clinProfs,
    };
}

function isClinicianRoleRemoveReqSuccessAction(
    action: ITF.ClinicianRoleRemoveReqSuccessAction
): action is ITF.ClinicianRoleRemoveReqSuccessAction {
    return action.type === CLINCON.CLINICIAN_ROLE_REMOVE_REQ_ACTION.SUCCESS;
}

function handleClinicianRoleRemoveReqSuccessAction(
    state: ProfileState,
    action: ITF.ClinicianRoleRemoveReqSuccessAction
): ProfileState {
    return {
        ...state,
        loading: false,
        clinicianProfiles: action.payload.clinProfs,
    };
}

///

function isLogoutSuccessAction(
    action: LogoutSuccessAction
): action is LogoutSuccessAction {
    return action.type === LOGOUT_ACTION.SUCCESS;
}

function handleLogoutSuccessAction(): ProfileState {
    return {...initialState};
}

// INCOMING FAIL ACTIONS
function isLoadUserProfileFailureAction(
    action: ITF.ProfileActionFailure
): action is ITF.ProfileActionFailure {
    return action.type === CONSTS.LOAD_USER_PROFILE_ACTION.FAIL;
}

function handleLoadUserProfileFailureAction(): ProfileState {
    return {
        ...initialState,
    };
}

function isReferralNotificationsFailureAction(
    action: ITF.ProfileActionFailure
): action is ITF.ProfileActionFailure {
    return action.type === CONSTS.REFERRAL_NOTIFICATIONS_ACTION.FAIL;
}

function handleReferralNOtificationsFailureAction(
    state: ProfileState
): ProfileState {
    return {
        ...state,
        loading: false,
        referralNotifications: {nReferrals: null},
    };
}

function isUserDelegatesFailureAction(
    action: ITF.ProfileActionFailure
): action is ITF.ProfileActionFailure {
    return action.type === AUTHCON.USER_DELEGATES_ACTION.FAIL;
}

function handleUserDelegatesFailureAction(state: ProfileState): ProfileState {
    return {
        ...state,
        loading: false,
        delegates: [],
    };
}

function isFailureAction(
    action: ITF.ProfileActionFailure
): action is ITF.ProfileActionFailure {
    return [
        CONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.FAIL,
        AUTHCON.USER_DELEGATES_ACTION.FAIL,
        CONSTS.LOAD_USER_PROFILE_ACTION.FAIL,
        CLINCON.CLINICIAN_CREATE_ACTION.FAIL,
        CLINCON.CLINICIAN_ROLE_REMOVE_REQ_ACTION.FAIL,
        CONSTS.UPDATE_USER_PROFILE_ACTION.FAIL,
        CLINCON.CLINICIAN_UPDATE_ACTION.FAIL,
        CONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.FAIL,
        USRCONSTS.UPDATE_PROFILE_PIC_ACTION.FAIL,
    ].includes(action.type);
}

function handleFailureAction(state: ProfileState): ProfileState {
    return {
        ...state,
        loading: false,
    };
}

export const profileReducer: Reducer<ProfileState, ITF.ProfileActionTypes> = (
    state = initialState,
    action
) => {
    // INCOMING REQUEST ACTIONS
    if (isReferralNotificationRequestAction(action)) {
        return handleReferralNotificationRequestAction(state);
    }
    if (isUserSummaryAnalyticsRequestAction(action)) {
        return handleUserSummaryAnalyticsRequestAction(state);
    }
    if (isRequestAction(action)) {
        return handleRequestAction(state);
    }

    // INCOMING SUCCESS ACTIONS
    if (isLoadUserProfileSuccessAction(action)) {
        return handleLoadUserProfileSuccessAction(state, action);
    }
    if (isUpdateUserProfileSuccessAction(action)) {
        return handleUpdateUserProfileSuccessAction(state, action);
    }
    if (isUpdatProfilePictureSuccessAction(action)) {
        return handleUpdateProfilePictureSuccessAction(state, action);
    }
    if (isUpdateUserNotificationsSuccessAction(action)) {
        return handleUpdateUserNotificationsSuccessAction(state, action);
    }
    if (isUserDelegatesSuccessAction(action)) {
        return handleUserDelegatesSuccessAction(state, action);
    }
    if (isUpdateReferralContactsSuccessAction(action)) {
        return handleUpdateReferralContactsSuccessAction(state, action);
    }
    if (isDeactivateDelegateSuccessAction(action)) {
        return handleDeactivateDelegateSuccessAction(state, action);
    }
    if (isRestoreDelegateSuccessAction(action)) {
        return handleRestoreDelegateSuccessAction(state, action);
    }
    if (isUpdateDelegatePermissionSuccessAction(action)) {
        return handleUpdateDelegatePermissionSuccessAction(state, action);
    }
    if (isUserSummaryAnalyticsSuccessAction(action)) {
        return handleUserSummaryAnalyticsSuccessAction(state, action);
    }
    if (isReferralNotificationsSuccessAction(action)) {
        return handleReferralNotificationsSuccessAction(state, action);
    }
    if (isClinicianCreateSuccessAction(action)) {
        return handleClinicianCreateSuccessAction(state, action);
    }
    if (isClinicianUpdateSuccessAction(action)) {
        return handleClinicianUpdateSuccessAction(state, action);
    }
    if (isClinicianRoleRemoveReqSuccessAction(action)) {
        return handleClinicianRoleRemoveReqSuccessAction(state, action);
    }
    if (isLogoutSuccessAction(action)) {
        return handleLogoutSuccessAction();
    }
    // INCOMING FAIL ACTIONS
    if (isLoadUserProfileFailureAction(action)) {
        return handleLoadUserProfileFailureAction();
    }
    if (isReferralNotificationsFailureAction(action)) {
        return handleReferralNOtificationsFailureAction(state);
    }
    if (isUserDelegatesFailureAction(action)) {
        return handleUserDelegatesFailureAction(state);
    }

    if (isFailureAction(action)) {
        return handleFailureAction(state);
    }
    return state;
};
