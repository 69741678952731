import React from 'react';
import {Modal, Button} from 'react-bootstrap';

interface ReferralSentPopupProps {
    showModal: boolean;
    handleClose: () => void;
}

const ReferralSentPopup: React.FC<ReferralSentPopupProps> = ({
    showModal,
    handleClose,
}) => {
    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="medr-inv">
                    Your referral has been sent successfully!
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="info" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReferralSentPopup;
