/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
    ReferralToJSONTyped,
} from './Referral';
import type { ReferralsGroupLists } from './ReferralsGroupLists';
import {
    ReferralsGroupListsFromJSON,
    ReferralsGroupListsFromJSONTyped,
    ReferralsGroupListsToJSON,
    ReferralsGroupListsToJSONTyped,
} from './ReferralsGroupLists';

/**
 * 
 * @export
 * @interface ReferralsListForUserResponse
 */
export interface ReferralsListForUserResponse {
    /**
     * 
     * @type {ReferralsGroupLists}
     * @memberof ReferralsListForUserResponse
     */
    referralsMade: ReferralsGroupLists;
    /**
     * 
     * @type {ReferralsGroupLists}
     * @memberof ReferralsListForUserResponse
     */
    referralsReceived: ReferralsGroupLists;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralsListForUserResponse
     */
    referralsOpen: Array<Referral>;
}

/**
 * Check if a given object implements the ReferralsListForUserResponse interface.
 */
export function instanceOfReferralsListForUserResponse(value: object): value is ReferralsListForUserResponse {
    if (!('referralsMade' in value) || value['referralsMade'] === undefined) return false;
    if (!('referralsReceived' in value) || value['referralsReceived'] === undefined) return false;
    if (!('referralsOpen' in value) || value['referralsOpen'] === undefined) return false;
    return true;
}

export function ReferralsListForUserResponseFromJSON(json: any): ReferralsListForUserResponse {
    return ReferralsListForUserResponseFromJSONTyped(json, false);
}

export function ReferralsListForUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralsListForUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'referralsMade': ReferralsGroupListsFromJSON(json['referralsMade']),
        'referralsReceived': ReferralsGroupListsFromJSON(json['referralsReceived']),
        'referralsOpen': ((json['referralsOpen'] as Array<any>).map(ReferralFromJSON)),
    };
}

export function ReferralsListForUserResponseToJSON(json: any): ReferralsListForUserResponse {
    return ReferralsListForUserResponseToJSONTyped(json, false);
}

export function ReferralsListForUserResponseToJSONTyped(value?: ReferralsListForUserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'referralsMade': ReferralsGroupListsToJSON(value['referralsMade']),
        'referralsReceived': ReferralsGroupListsToJSON(value['referralsReceived']),
        'referralsOpen': ((value['referralsOpen'] as Array<any>).map(ReferralToJSON)),
    };
}

