import React from 'react';
import AdminCliniciansTable from '../../components/admin/AdminCliniciansTable';

const AdminCliniciansListScreen: React.FC = () => {
    return (
        <>
            <h1>Clinicians</h1>
            <AdminCliniciansTable />
        </>
    );
};

export default AdminCliniciansListScreen;
