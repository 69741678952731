import React from 'react';
import {Form} from 'react-bootstrap';

interface ClinicianDescriptionFieldProps {
    clinicianDescription: string;
    updateFormData: (data: {clinicianDescription: string}) => void;
}

const ClinicianDescriptionField: React.FC<ClinicianDescriptionFieldProps> = ({
    clinicianDescription,
    updateFormData,
}) => {
    const maximumLength = 1000;
    const currentLength = clinicianDescription?.length ?? 0;
    const isTooLong = currentLength > maximumLength;
    const lengthSummary = isTooLong
        ? `(Maximum characters exceeded ${maximumLength})`
        : `(${currentLength} of ${maximumLength} characters)`;

    return (
        <Form.Group controlId="clinicianDescription" as="div">
            <Form.Label className="my-0">
                {' '}
                Clinician description{' '}
                <small
                    className={isTooLong ? 'text-danger ' : ''}
                    style={{fontSize: '0.8rem'}}
                >
                    {' '}
                    {lengthSummary}{' '}
                </small>
            </Form.Label>
            <Form.Control
                required
                as="textarea"
                placeholder="Enter clinician description"
                rows={8}
                value={clinicianDescription}
                onChange={(e) =>
                    updateFormData({clinicianDescription: e.target.value})
                }
                isInvalid={isTooLong}
            />
        </Form.Group>
    );
};

export default ClinicianDescriptionField;
