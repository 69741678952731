/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GpsFromPostcodeRequest
 */
export interface GpsFromPostcodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GpsFromPostcodeRequest
     */
    postCode: string;
}

/**
 * Check if a given object implements the GpsFromPostcodeRequest interface.
 */
export function instanceOfGpsFromPostcodeRequest(value: object): value is GpsFromPostcodeRequest {
    if (!('postCode' in value) || value['postCode'] === undefined) return false;
    return true;
}

export function GpsFromPostcodeRequestFromJSON(json: any): GpsFromPostcodeRequest {
    return GpsFromPostcodeRequestFromJSONTyped(json, false);
}

export function GpsFromPostcodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GpsFromPostcodeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'postCode': json['postCode'],
    };
}

export function GpsFromPostcodeRequestToJSON(json: any): GpsFromPostcodeRequest {
    return GpsFromPostcodeRequestToJSONTyped(json, false);
}

export function GpsFromPostcodeRequestToJSONTyped(value?: GpsFromPostcodeRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'postCode': value['postCode'],
    };
}

