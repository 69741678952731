import React, {useState, useEffect, useCallback} from 'react';
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import InviteUserForm from '../user/InviteUserForm';
import useReferralNotifications from '@hooks/referrals/useReferralNotifications';
import useUserPermissions from '@hooks/user/useUserPermissions';
import {useAuthService, useUserService} from '@/actions';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import {RootState} from '@/reducers';
import {
    MedrPages,
    allowAllPaths,
    UserGroups,
    allStaffGroups,
} from '@/constants/variables';

const Header: React.FC = () => {
    const authService = useAuthService();
    const userService = useUserService();
    const location = useLocation();
    const {pathname} = location;

    const isAuthenticated = authService.hasAuthToken();
    const userProfile = useSelector((state: RootState) => state.auth.user);
    const isVerified = useSelector(
        (state: RootState) => state.auth.user.isVerified
    );
    const userGroup = useSelector((state: RootState) => state.auth.userGroup);
    const {permissionCreateReferrals} = useUserPermissions();
    const {nReferrals} = useReferralNotifications(isAuthenticated);

    const {isNarrow} = useScreenInfo();
    const [displayInviteUserForm, setDisplayInviteUserForm] =
        useState<boolean>(false);

    const maxLogoHeight = isNarrow ? 60 : 100;

    const isAdmin = allStaffGroups.includes(userGroup);
    const isFullAdmin = userGroup === UserGroups.STAFF.toString();
    const isDelegate = userGroup === UserGroups.DELEGATE.toString();

    const handleCheckAuth = useCallback(async () => {
        if (
            !userProfile.id &&
            !allowAllPaths.includes(pathname) &&
            !pathname.includes(MedrPages.PASSWORD_SET_NEW.toString()) &&
            !pathname.includes(MedrPages.REGISTERED_VERIFY_EMAIL.toString())
        ) {
            await authService.checkAuth();
            await userService.loadUserProfile();
        }
    }, [authService, userService, userProfile.id, pathname, allowAllPaths]);

    useEffect(() => {
        handleCheckAuth();
    }, [handleCheckAuth]);

    function handleLogout() {
        authService.logout();
    }

    const renderAuthMainLinks = () => (
        <>
            <LinkContainer to={MedrPages.DASHBOARD}>
                <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Patients" id="patientsmenue">
                {permissionCreateReferrals && (
                    <LinkContainer to={MedrPages.PATIENTS_ADD}>
                        <NavDropdown.Item>Add new patient</NavDropdown.Item>
                    </LinkContainer>
                )}
                <LinkContainer to={MedrPages.PATIENTS}>
                    <NavDropdown.Item>View patients</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
            <NavDropdown
                title={
                    <span style={{position: 'relative'}}>
                        Referrals
                        {nReferrals > 0 && (
                            <span className="header-badge header-badge-red">
                                {nReferrals}
                            </span>
                        )}
                    </span>
                }
                id="referralsmenue"
            >
                {permissionCreateReferrals && (
                    <LinkContainer to={MedrPages.REFERRALS_ADD}>
                        <NavDropdown.Item>Make referral</NavDropdown.Item>
                    </LinkContainer>
                )}
                <LinkContainer to={MedrPages.REFERRALS_INBOUND}>
                    <NavDropdown.Item>Referrals received</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={MedrPages.REFERRALS_OUTBOUND}>
                    <NavDropdown.Item>Referrals made</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
            <LinkContainer to={MedrPages.CLINICIANS}>
                <Nav.Link>Find a clinician</Nav.Link>
            </LinkContainer>
            <Nav.Link onClick={() => setDisplayInviteUserForm(true)}>
                Invite a clinician
            </Nav.Link>
            {displayInviteUserForm ? (
                <InviteUserForm
                    showModal={displayInviteUserForm}
                    handleClose={() => setDisplayInviteUserForm(false)}
                />
            ) : null}
        </>
    );

    const renderAdminLinks = () => (
        <>
            <LinkContainer to={MedrPages.CLINICIANS}>
                <Nav.Link>Find a clinician</Nav.Link>
            </LinkContainer>
            <Nav.Link onClick={() => setDisplayInviteUserForm(true)}>
                Invite a clinician
            </Nav.Link>
            {displayInviteUserForm ? (
                <InviteUserForm
                    showModal={displayInviteUserForm}
                    handleClose={() => setDisplayInviteUserForm(false)}
                />
            ) : null}
            <NavDropdown title="Admin" id="adminmenue">
                <LinkContainer to={MedrPages.ADMIN_USERS_LIST}>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={MedrPages.ADMIN_CLINICIANS_LIST}>
                    <NavDropdown.Item>Clinician Roles</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={MedrPages.ADMIN_PATIENTS}>
                    <NavDropdown.Item>Patients</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={MedrPages.ADMIN_REFERRALS}>
                    <NavDropdown.Item>Referrals</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={MedrPages.ADMIN_INSURANCE_AGREEMENTS}>
                    <NavDropdown.Item>Insurers</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
        </>
    );

    const renderProfileDropdown = () => (
        <NavDropdown
            id="username"
            title={
                <span>
                    <i className="fa-solid fa-address-card" /> Profile
                </span>
            }
        >
            {isFullAdmin && (
                <LinkContainer to={MedrPages.ADMIN_ADD_STAFF}>
                    <NavDropdown.Item>
                        <i className="fa-solid fa-people-group" /> Add new admin
                    </NavDropdown.Item>
                </LinkContainer>
            )}
            {!isAdmin && (
                <LinkContainer to={MedrPages.PROFILE}>
                    <NavDropdown.Item>
                        <i className="fa-solid fa-address-card" /> View/update
                    </NavDropdown.Item>
                </LinkContainer>
            )}
            {!isDelegate && !isAdmin && (
                <LinkContainer to={MedrPages.DELEGATES}>
                    <NavDropdown.Item>
                        <i className="fa-solid fa-people-group" /> Delegate
                        accounts
                    </NavDropdown.Item>
                </LinkContainer>
            )}
            <LinkContainer to={MedrPages.AUTH_HELP}>
                <NavDropdown.Item>
                    <i className="fa-solid fa-circle-info" /> Help
                </NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={MedrPages.HOME}>
                <NavDropdown.Item onClick={handleLogout}>
                    <i className="fa-solid fa-right-from-bracket" /> Logout
                </NavDropdown.Item>
            </LinkContainer>
        </NavDropdown>
    );

    const renderUnauthenticatedLinks = () => (
        <>
            <LinkContainer to={MedrPages.LOGIN}>
                <Nav.Link>
                    <i className="fas fa-user" /> Login
                </Nav.Link>
            </LinkContainer>
            {!pathname.includes(MedrPages.REGISTER.toString()) && (
                <LinkContainer to={MedrPages.REGISTER}>
                    <Nav.Link>
                        <i className="fas fa-notes-medical" /> Register
                    </Nav.Link>
                </LinkContainer>
            )}
            <LinkContainer to={MedrPages.HELP}>
                <Nav.Link>
                    <i className="fas fa-circle-info" /> Help
                </Nav.Link>
            </LinkContainer>
        </>
    );

    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <Container>
                    <LinkContainer to={MedrPages.HOME}>
                        <Navbar.Brand>
                            <img
                                src="/logos/white_logo_transparent_background.png"
                                alt="MedR Logo"
                                style={{maxHeight: maxLogoHeight}}
                            />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            {isAuthenticated &&
                                isVerified &&
                                !isAdmin &&
                                renderAuthMainLinks()}
                            {isAdmin && renderAdminLinks()}
                            {isAuthenticated
                                ? renderProfileDropdown()
                                : renderUnauthenticatedLinks()}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
