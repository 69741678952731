import React from 'react';
import {IoAddCircle, IoRemoveCircle} from 'react-icons/io5';
import {Tooltip} from 'react-tooltip';

export interface DetailsIconProps {
    displayTextOpen: string;
    displayTextClosed: string;
    isOpen: boolean;
    onClick: () => void;
}

const DetailsIcon: React.FC<DetailsIconProps> = ({
    isOpen,
    onClick,
    displayTextOpen,
    displayTextClosed,
}) => {
    if (!isOpen) {
        return (
            <>
                <IoAddCircle
                    size={24}
                    color="white"
                    data-tooltip-id="info-tooltip"
                    data-tooltip-content={displayTextClosed}
                    onClick={onClick}
                    style={{cursor: 'pointer', margin: 0}}
                />
                <Tooltip id="info-tooltip" className="rounded-tooltip" />
            </>
        );
    }
    return (
        <>
            <IoRemoveCircle
                size={24}
                color="white"
                data-tooltip-id="info-tooltip"
                data-tooltip-content={displayTextOpen}
                onClick={onClick}
                style={{cursor: 'pointer', margin: 0}}
            />
            <Tooltip id="info-tooltip" />
        </>
    );
};
export default DetailsIcon;
