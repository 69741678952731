/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
    ReferralToJSONTyped,
} from './Referral';

/**
 * 
 * @export
 * @interface PatientReferralsList
 */
export interface PatientReferralsList {
    /**
     * 
     * @type {Array<Referral>}
     * @memberof PatientReferralsList
     */
    activeReferrals: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof PatientReferralsList
     */
    inactiveReferrals: Array<Referral>;
}

/**
 * Check if a given object implements the PatientReferralsList interface.
 */
export function instanceOfPatientReferralsList(value: object): value is PatientReferralsList {
    if (!('activeReferrals' in value) || value['activeReferrals'] === undefined) return false;
    if (!('inactiveReferrals' in value) || value['inactiveReferrals'] === undefined) return false;
    return true;
}

export function PatientReferralsListFromJSON(json: any): PatientReferralsList {
    return PatientReferralsListFromJSONTyped(json, false);
}

export function PatientReferralsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientReferralsList {
    if (json == null) {
        return json;
    }
    return {
        
        'activeReferrals': ((json['activeReferrals'] as Array<any>).map(ReferralFromJSON)),
        'inactiveReferrals': ((json['inactiveReferrals'] as Array<any>).map(ReferralFromJSON)),
    };
}

export function PatientReferralsListToJSON(json: any): PatientReferralsList {
    return PatientReferralsListToJSONTyped(json, false);
}

export function PatientReferralsListToJSONTyped(value?: PatientReferralsList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'activeReferrals': ((value['activeReferrals'] as Array<any>).map(ReferralToJSON)),
        'inactiveReferrals': ((value['inactiveReferrals'] as Array<any>).map(ReferralToJSON)),
    };
}

