import {useEffect, useState, useMemo, useRef, useCallback} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {Icon} from 'leaflet';
import iconUrl from './marker-icon.png';
import {HoveredClinicianProps} from '@/hooks/clinicians/interfaces';
import {scrollToClinicianCard} from '@/utils/scrollToElement';
import {MapAddress} from '@/generated-client';

type LatLngTuple = [number, number];

interface UseClinicMarkerProps {
    clinic: MapAddress;
    hoveredAddressIds: number[];
    updateHovered: (data: HoveredClinicianProps) => void;
}

interface UseClinicMarkerReturn {
    geocode: LatLngTuple;
    puname: string;
    addressIsHovered: boolean;
    customIcon: Icon;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    listOfClinicians: number[];
}

const useClinicMarker = ({
    clinic,
    hoveredAddressIds,
    updateHovered,
}: UseClinicMarkerProps): UseClinicMarkerReturn => {
    const geocode: LatLngTuple = [
        clinic.latitude,
        clinic.longitude,
    ] as LatLngTuple;
    const puname = clinic.postcode;
    const listOfClinicians = clinic.userProfileIds;

    const customIconNormal = useMemo(
        () =>
            new Icon({
                iconUrl: iconUrl,
                iconSize: [25, 25],
            }),
        []
    );

    const customIconHovered = useMemo(
        () =>
            new Icon({
                iconUrl: iconUrl,
                iconSize: [50, 50],
            }),
        []
    );

    const [customIcon, setCustomIcon] = useState<Icon>(customIconNormal);
    const [addressIsHovered, setAddressIsHovered] = useState<boolean>(false);

    const checkIsHovered = useCallback(() => {
        if (!hoveredAddressIds) {
            setAddressIsHovered(false);
            setCustomIcon(customIconNormal);
            return;
        }
        const hovered = hoveredAddressIds.includes(clinic.id);
        setAddressIsHovered(hovered);
        setCustomIcon(hovered ? customIconHovered : customIconNormal);
    }, [hoveredAddressIds, clinic.id, customIconNormal, customIconHovered]);

    useEffect(() => {
        checkIsHovered();
    }, [checkIsHovered]);

    const onMouseEnter = useCallback(() => {
        updateHovered({
            hoveredClinicianUserProfileIds: clinic.userProfileIds,
            hoveredAddressIds: [clinic.id],
            hoveredClinicianId: clinic.id,
        });

        scrollToClinicianCard('map-clinician-card');
    }, [clinic.userProfileIds, clinic.id, updateHovered]);

    const onMouseLeave = useCallback(() => {
        updateHovered({
            hoveredClinicianUserProfileIds: [],
            hoveredAddressIds: [],
            hoveredClinicianId: null,
        });
    }, [updateHovered]);

    return {
        geocode,
        puname,
        addressIsHovered,
        customIcon,
        onMouseEnter,
        onMouseLeave,
        listOfClinicians,
    };
};

export default useClinicMarker;
