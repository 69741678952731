import React, {useRef, useState, useEffect} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
    getNameString,
    reformatDate,
    reformatDateTime,
    randomDateString,
    randomNameString,
} from '@/actions/general';
import {ReferralBarProps, ReferralView} from './interfaces';
import useReferralBar from './hooks';
import useScreenInfo, {ScreenSize} from '@/hooks/app/useScreenInfo';
import {ClinicianRoleProfile, Referral} from '@/generated-client';
import DetailsIcon from '@/components/app/parts/DetailsIcon';
import EmailIcon from '@/components/app/parts/EmailIcon';
import PhoneIcon from '@/components/app/parts/PhoneIcon';
import AddressIcon from '@/components/app/parts/AddressIcon';

interface GetTargetClinicianProps {
    isOpen: boolean;
    clinician: ClinicianRoleProfile;
    clinicianPageUrl: string;
}

interface RxBarTopLineProps {
    referral: Referral;
    clinicianPageUrl: string;
    isOpen: boolean;
}

const ReferralBar: React.FC<ReferralBarProps> = ({referral}) => {
    const {isNarrow, screenType} = useScreenInfo();

    const {
        selectedView,
        isOpen,
        handleClickOpen,
        permissionManageReferrals,
        referrerClinicianPageUrl,
        targetClinicianPageUrl,
        onClickCancelButton,
        onClickAcceptButton,
        onClickRejectButton,
        onClickCompleteButton,
        onClickDnaButton,
        onClickSetActiveButton,
    } = useReferralBar(referral);

    if (!referral) {
        return <div id="referral_missing">Loading...</div>;
    }
    // BUTTONS

    const buttonClassName = `sel-button ${isNarrow ? 'mobile-rx-bar-btn' : 'w80'}`;

    const cancelButton = (
        <OverlayTrigger
            placement="left"
            delay={{show: 250, hide: 400}}
            overlay={
                <Tooltip id="cancel-button-tooltip" className="rounded-tooltip">
                    Cancel this referral
                </Tooltip>
            }
        >
            <Button
                className={buttonClassName}
                variant="danger"
                onClick={onClickCancelButton}
                disabled={!permissionManageReferrals}
            >
                Cancel
            </Button>
        </OverlayTrigger>
    );

    const acceptButton = (
        <OverlayTrigger
            placement="left"
            delay={{show: 250, hide: 400}}
            overlay={
                <Tooltip id="accept-button-tooltip" className="rounded-tooltip">
                    Accept this referral from{' '}
                    {getNameString(referral.referrer.userProfile)}
                </Tooltip>
            }
        >
            <Button
                className={buttonClassName}
                variant="success"
                onClick={onClickAcceptButton}
                disabled={!permissionManageReferrals}
            >
                Accept
            </Button>
        </OverlayTrigger>
    );

    const rejectButton = (
        <OverlayTrigger
            placement="left"
            delay={{show: 250, hide: 400}}
            overlay={
                <Tooltip id="reject-button-tooltip" className="rounded-tooltip">
                    Reject this referral from{' '}
                    {getNameString(referral.referrer.userProfile)}
                </Tooltip>
            }
        >
            <Button
                className={buttonClassName}
                variant="danger"
                onClick={onClickRejectButton}
                disabled={!permissionManageReferrals}
            >
                Reject
            </Button>
        </OverlayTrigger>
    );
    const bookedButton = (
        <OverlayTrigger
            placement="left"
            delay={{show: 250, hide: 400}}
            overlay={
                <Tooltip id="reject-button-tooltip" className="rounded-tooltip">
                    Set {getNameString(referral.patient)} as booked
                </Tooltip>
            }
        >
            <Button
                className={buttonClassName}
                variant="success"
                onClick={onClickCompleteButton}
                disabled={!permissionManageReferrals}
            >
                Booked
            </Button>
        </OverlayTrigger>
    );
    const dnaButton = (
        <OverlayTrigger
            placement="left"
            delay={{show: 250, hide: 400}}
            overlay={
                <Tooltip id="reject-button-tooltip" className="rounded-tooltip">
                    Set {getNameString(referral.patient)} as DNA/cancelled
                    appointment
                </Tooltip>
            }
        >
            <Button
                className={buttonClassName}
                variant="danger"
                onClick={onClickDnaButton}
                disabled={!permissionManageReferrals}
            >
                DNA
            </Button>
        </OverlayTrigger>
    );
    const setActiveButton = (
        <OverlayTrigger
            placement="left"
            delay={{show: 250, hide: 400}}
            overlay={
                <Tooltip id="reject-button-tooltip" className="rounded-tooltip">
                    Move {getNameString(referral.patient)} back to accepted
                    referrals
                </Tooltip>
            }
        >
            <Button
                className={buttonClassName}
                variant="info"
                onClick={onClickSetActiveButton}
                disabled={!permissionManageReferrals}
            >
                Set active
            </Button>
        </OverlayTrigger>
    );

    const GetTargetClinician: React.FC<GetTargetClinicianProps> = ({
        isOpen,
        clinician,
        clinicianPageUrl,
    }) => {
        if (isOpen)
            return (
                <a
                    onClick={(event) => event.stopPropagation()}
                    href={clinicianPageUrl}
                >
                    <p className="m-0">
                        {getNameString(clinician.userProfile)}
                    </p>
                </a>
            );
        return getNameString(clinician.userProfile);
    };

    // TOP LINE DISPLAY

    const TopLineFormat = ({
        pxName,
        pxDob,
        pxSex,
        clinician,
        clinicianPageUrl,
        buttons,
    }) => {
        const detailsDisplayOpen = 'Hide referral details';
        const detailsDisplayClosed = 'Show referral details';
        const clinicianName = clinician ? (
            <GetTargetClinician
                isOpen={isOpen}
                clinician={clinician}
                clinicianPageUrl={clinicianPageUrl}
            />
        ) : (
            openToAllLine(referral)
        );
        if (screenType < ScreenSize.LARGE) {
            return (
                <div className="row">
                    <div className="col  flex-grow-1">
                        <div className="p-1">
                            <DetailsIcon
                                isOpen={isOpen}
                                onClick={handleClickOpen}
                                displayTextOpen={detailsDisplayOpen}
                                displayTextClosed={detailsDisplayClosed}
                            />
                        </div>
                        {pxName}
                        {pxDob}
                        {pxSex}
                        {clinicianName}
                    </div>
                    <div className="sel-button-mobile-col">
                        {buttons.map((button: JSX.Element, index: number) => (
                            <div className="p-1" key={index}>
                                {button}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className="d-flex align-items-center referral-bar">
                <div style={{width: '25px'}}>
                    <DetailsIcon
                        isOpen={isOpen}
                        onClick={handleClickOpen}
                        displayTextOpen={detailsDisplayOpen}
                        displayTextClosed={detailsDisplayClosed}
                    />
                </div>
                <div
                    className="flex-fill referral-bar-item lines-max-2 referral-bar-item-1"
                    style={{paddingLeft: '10px'}}
                >
                    {pxName}
                </div>
                <div
                    className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2"
                    style={{paddingLeft: '4px'}}
                >
                    {pxDob}
                </div>
                <div
                    className="flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-3"
                    style={{paddingLeft: '4px'}}
                >
                    {pxSex}
                </div>
                <div
                    className="flex-fill referral-bar-item lines-max-1"
                    style={{paddingLeft: '4px'}}
                >
                    {clinician ? (
                        <GetTargetClinician
                            isOpen={isOpen}
                            clinician={clinician}
                            clinicianPageUrl={clinicianPageUrl}
                        />
                    ) : (
                        openToAllLine(referral)
                    )}
                </div>
                <div className="d-flex align-items-center p-0 bar-buttons referral-bar-item-5">
                    {buttons.map((button, index) => (
                        <div
                            className="flex-fill text-center referral-bar-item"
                            key={index}
                        >
                            {button}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const invisbleButton = <Button className="invisible" />;

    const openToAllLine = (referral: Referral) =>
        `Open to all ${referral.jobRole.jobTitle}s`;

    const RxBarTopLineSent: React.FC<RxBarTopLineProps> = ({
        referral,
        clinicianPageUrl,
    }) => {
        const {
            patient,
            specifiedClinician,
            targetClinician,
            targetHasAccepted,
        } = referral;

        const pxName = <p className="m-0">{getNameString(patient)}</p>;
        const pxDob = <p className="m-0">{reformatDate(patient.dob)}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const clinician = specifiedClinician ? targetClinician : null;
        const buttons = targetHasAccepted
            ? [invisbleButton, invisbleButton, invisbleButton]
            : [invisbleButton, invisbleButton, cancelButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                clinician={clinician}
                clinicianPageUrl={clinicianPageUrl}
                buttons={buttons}
            />
        );
    };

    const RxBarTopLineOpen: React.FC<RxBarTopLineProps> = ({
        referral,
        clinicianPageUrl,
    }) => {
        const {referrer, patient} = referral;

        const pxName = <p className="m-0 blur-text">{randomNameString()}</p>;
        const pxDob = <p className="m-0 blur-text">{randomDateString()}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const clinician = referrer;
        const buttons = [invisbleButton, invisbleButton, acceptButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                clinician={clinician}
                clinicianPageUrl={clinicianPageUrl}
                buttons={buttons}
            />
        );
    };

    const RxBarTopLinePending: React.FC<RxBarTopLineProps> = ({
        referral,
        clinicianPageUrl,
    }) => {
        const {referrer} = referral;

        const pxName = <p className="m-0 blur-text">{randomNameString()}</p>;
        const pxDob = <p className="m-0 blur-text">{randomDateString()}</p>;
        const pxSex = <p className="m-0 blur-text">{patient.sex}</p>;
        const clinician = referrer;
        const buttons = [invisbleButton, acceptButton, rejectButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                clinician={clinician}
                clinicianPageUrl={clinicianPageUrl}
                buttons={buttons}
            />
        );
    };

    const RxBarTopLineAccepted: React.FC<RxBarTopLineProps> = ({
        referral,
        clinicianPageUrl,
    }) => {
        const {patient, referrer} = referral;
        const pxName = <p className="m-0">{getNameString(patient)}</p>;
        const pxDob = <p className="m-0">{reformatDate(patient.dob)}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const clinician = referrer;
        const buttons = [rejectButton, dnaButton, bookedButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                clinician={clinician}
                clinicianPageUrl={clinicianPageUrl}
                buttons={buttons}
            />
        );
    };

    const RxBarTopLineBooked: React.FC<RxBarTopLineProps> = ({
        referral,
        clinicianPageUrl,
    }) => {
        const {patient, referrer} = referral;
        const pxName = <p className="m-0">{getNameString(patient)}</p>;
        const pxDob = <p className="m-0">{reformatDate(patient.dob)}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const clinician = referrer;
        const buttons = [invisbleButton, setActiveButton, dnaButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                clinician={clinician}
                clinicianPageUrl={clinicianPageUrl}
                buttons={buttons}
            />
        );
    };

    const RxBarTopLineDna: React.FC<RxBarTopLineProps> = ({
        referral,
        clinicianPageUrl,
    }) => {
        const {patient, referrer} = referral;
        const pxName = <p className="m-0">{getNameString(patient)}</p>;
        const pxDob = <p className="m-0">{reformatDate(patient.dob)}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const clinician = referrer;
        const buttons = [invisbleButton, setActiveButton, bookedButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                clinician={clinician}
                clinicianPageUrl={clinicianPageUrl}
                buttons={buttons}
            />
        );
    };

    const topLines = {
        [ReferralView.Sent]: (
            <RxBarTopLineSent
                referral={referral}
                clinicianPageUrl={targetClinicianPageUrl}
                isOpen={isOpen}
            />
        ),
        [ReferralView.Open]: (
            <RxBarTopLineOpen
                referral={referral}
                clinicianPageUrl={referrerClinicianPageUrl}
                isOpen={isOpen}
            />
        ),
        [ReferralView.Pending]: (
            <RxBarTopLinePending
                referral={referral}
                clinicianPageUrl={referrerClinicianPageUrl}
                isOpen={isOpen}
            />
        ),
        [ReferralView.Accepted]: (
            <RxBarTopLineAccepted
                referral={referral}
                clinicianPageUrl={referrerClinicianPageUrl}
                isOpen={isOpen}
            />
        ),
        [ReferralView.Booked]: (
            <RxBarTopLineBooked
                referral={referral}
                clinicianPageUrl={referrerClinicianPageUrl}
                isOpen={isOpen}
            />
        ),
        [ReferralView.Dna]: (
            <RxBarTopLineDna
                referral={referral}
                clinicianPageUrl={referrerClinicianPageUrl}
                isOpen={isOpen}
            />
        ),
    };

    // RX INFO
    const {patient, mainDescription, history} = referral;
    const iconWidth = 20;
    const iconPadding = 5;
    const totalWidth = iconWidth + iconPadding;
    const iconSpanStyle = {
        width: `${iconWidth}px`,
        paddingRight: `${iconPadding}px`,
    };
    const noIconSpanStyle = {paddingLeft: `${totalWidth}px`};

    const pxContactDetails = (
        insurance: string,
        email: JSX.Element,
        phone: JSX.Element,
        firstLine: string,
        city: string,
        postcode: string,
        blurView: boolean = false
    ) => {
        return (
            <>
                <p className="m-0 p-0 text-left mt-1">
                    Insurance: <small>{insurance}</small>
                </p>
                <p
                    className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                >
                    <span style={iconSpanStyle}>
                        <EmailIcon />
                    </span>
                    {email}
                </p>
                <p
                    className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                >
                    <span style={iconSpanStyle}>
                        <PhoneIcon />
                    </span>
                    {phone}
                </p>
                {firstLine !== '' && (
                    <p
                        className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                    >
                        <span style={iconSpanStyle}>
                            <AddressIcon />
                        </span>
                        <small>{firstLine}</small>
                    </p>
                )}
                {city !== '' && (
                    <p
                        className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                    >
                        {firstLine === '' ? (
                            <>
                                <span style={iconSpanStyle}>
                                    <AddressIcon />
                                </span>
                                <small>{city}</small>
                            </>
                        ) : (
                            <span style={noIconSpanStyle}>
                                <small>{city}</small>
                            </span>
                        )}
                    </p>
                )}

                <p
                    className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                >
                    {city === '' && firstLine === '' ? (
                        <>
                            <span style={iconSpanStyle}>
                                <AddressIcon />
                            </span>
                            <small>{postcode}</small>
                        </>
                    ) : (
                        <span style={noIconSpanStyle}>
                            <small>{postcode}</small>
                        </span>
                    )}
                </p>
            </>
        );
    };

    const pxContact = () => {
        const insurance = patient.insurancePolicy
            ? `${patient.insurancePolicy.provider}`
            : 'Self-payer';
        const email = (
            <a
                onClick={(event) => event.stopPropagation()}
                href={`mailto:"${patient.primaryEmail}"`}
            >
                <small>{patient.primaryEmail}</small>
            </a>
        );
        const phone = (
            <a
                onClick={(event) => event.stopPropagation()}
                href={`tel:"${patient.primaryPhoneNumber}"`}
            >
                <small>{patient.primaryPhoneNumber}</small>
            </a>
        );
        const firstLine = patient.address.firstLine;
        const city = patient.address.city;
        const postcode = patient.address.postcode;
        return pxContactDetails(
            insurance,
            email,
            phone,
            firstLine,
            city,
            postcode
        );
    };

    const pxContactHidden = () => {
        const insurance = patient.insurancePolicy
            ? `${patient.insurancePolicy.provider}`
            : 'Self-payer';
        const email = <small>newemail@example.com</small>;
        const phone = <small>0123456789</small>;
        const firstLine = '10 Downing Street';
        const city = 'London';
        const postcode = 'SW1A 2AB';
        return pxContactDetails(
            insurance,
            email,
            phone,
            firstLine,
            city,
            postcode,
            true
        );
    };

    const histRef = useRef(null);
    const reasonRef = useRef(null);
    const [histRefM4L, setHistRefM4L] = useState(false);
    const [reasonRefM4L, setReasonRefM4L] = useState(false);
    const [expandBottom, setExpandBottom] = useState(false);

    const handleExpandBottom = () => setExpandBottom((prev) => !prev);
    const readMoreButton = (
        <button
            className="btn btn-link medr"
            onClick={handleExpandBottom}
            style={{paddingLeft: '0px'}}
        >
            {expandBottom ? 'Read Less' : 'Read More'}
        </button>
    );

    const pxHistory = (
        <>
            <p className="m-0 p-0 mt-1 text-left" ref={histRef}>
                <strong style={{fontWeight: 'bold'}}>History: </strong>
                <small
                    className={`m-0 p-0 mt-1 text-left ${expandBottom ? '' : 'lines-max-4'}`}
                >
                    {history}
                </small>
                {histRefM4L && readMoreButton}
            </p>
        </>
    );

    const pxReason = (
        <>
            <p className="m-0 p-0 mt-1 text-left" ref={reasonRef}>
                <strong style={{fontWeight: 'bold'}}>
                    Reason for referral:{' '}
                </strong>
                <small
                    className={`m-0 p-0 mt-1 text-left ${expandBottom ? '' : 'lines-max-4'}`}
                >
                    {mainDescription}
                </small>
                {reasonRefM4L && readMoreButton}
            </p>
        </>
    );

    const checkTextLength = (ref, setM4L) => {
        if (isOpen && ref.current) {
            const lineHeight = parseInt(
                window.getComputedStyle(ref.current).lineHeight
            );
            const height = ref.current.clientHeight;
            const lines = height / lineHeight;
            setM4L(lines > 4);
        }
    };

    useEffect(() => {
        const checkTextLines = () => {
            checkTextLength(histRef, setHistRefM4L);
        };

        checkTextLines();
        window.addEventListener('resize', checkTextLines);

        return () => {
            window.removeEventListener('resize', checkTextLines);
        };
    }, [isOpen, history]);

    useEffect(() => {
        const checkTextLines = () => {
            checkTextLength(reasonRef, setReasonRefM4L);
        };

        checkTextLines();
        window.addEventListener('resize', checkTextLines);

        return () => {
            window.removeEventListener('resize', checkTextLines);
        };
    }, [mainDescription]);

    // BOTTOM LINE DISPLAY
    const BottomLineFormat = ({items}) => {
        return (
            <div className="d-flex p-1 referral-info">
                {items.map((item: JSX.Element, index: number) => (
                    <div
                        className="flex-fill text-center referral-bar-bottom"
                        key={index}
                    >
                        {item}
                    </div>
                ))}
            </div>
        );
    };

    const RxBarBottomLineSent: React.FC = () => {
        const items = [pxContact(), pxHistory, pxReason];
        return <BottomLineFormat items={items} />;
    };

    const RxBarBottomLineOpen: React.FC = () => {
        const items = [pxContactHidden(), pxHistory, pxReason];
        return <BottomLineFormat items={items} />;
    };

    const RxBarBottomLinePending: React.FC = () => {
        const items = [pxContactHidden(), pxHistory, pxReason];
        return <BottomLineFormat items={items} />;
    };

    const RxBarBottomLineAccepted: React.FC = () => {
        const items = [pxContact(), pxHistory, pxReason];
        return <BottomLineFormat items={items} />;
    };

    const RxBarBottomLineBooked: React.FC = () => {
        const items = [pxContact(), pxHistory, pxReason];
        return <BottomLineFormat items={items} />;
    };

    const RxBarBottomLineDna: React.FC = () => {
        const items = [pxContact(), pxHistory, pxReason];
        return <BottomLineFormat items={items} />;
    };

    const bottomLines = {
        [ReferralView.Sent]: <RxBarBottomLineSent />,
        [ReferralView.Open]: <RxBarBottomLineOpen />,
        [ReferralView.Pending]: <RxBarBottomLinePending />,
        [ReferralView.Accepted]: <RxBarBottomLineAccepted />,
        [ReferralView.Booked]: <RxBarBottomLineBooked />,
        [ReferralView.Dna]: <RxBarBottomLineDna />,
    };
    const timeInfo = (
        <div
            className="d-flex p-1 referral-info"
            style={{paddingLeft: '10px', paddingRight: '10px'}}
        >
            <div className="flex-fill text-left referral-bar-bottom">
                <p className="m-0 text-small">
                    <small>
                        Referred: {reformatDateTime(referral.createdOn)}
                    </small>
                </p>
            </div>
            <div className="flex-fill text-left referral-bar-bottom">
                <p className="m-0 text-small">
                    <small>
                        Accepted:{' '}
                        {referral.acceptedOn
                            ? reformatDateTime(referral.acceptedOn)
                            : 'Not yet accepted'}
                    </small>
                </p>
            </div>
            <div className="flex-fill text-left referral-bar-bottom">
                <p className="m-0 text-small">
                    <small>
                        Completed:{' '}
                        {referral.completedOn
                            ? reformatDateTime(referral.completedOn)
                            : 'Not yet completed'}
                    </small>
                </p>
            </div>
        </div>
    );

    return (
        <div
            className={`medr-rounded-inv obj-clickable ${isOpen ? 'bar-open' : ''}`}
            role="button"
            tabIndex={0}
            id={`refbar${referral.id}`}
        >
            {topLines[selectedView]}
            {isOpen && bottomLines[selectedView]}
            {isOpen && timeInfo}
        </div>
    );
};

export default ReferralBar;
