import {Reducer} from 'react';
import * as CONSTS from '@constants/referralConstants';
import {createDefaultReferral} from '@constants/initDataConstants';
import {LOGOUT_ACTION} from '@constants/authConstants';

import * as ITF from '@reducers/referralReducer/interfaces';
import {ReferralState} from './interfaces';
import {LogoutSuccessAction} from '@reducers/authReducer';

const initialState: ReferralState = {
    loading: false,
    referralList: null,
    referral: createDefaultReferral(),
    referralsMade: {waiting: [], accepted: [], booked: [], dna: []},
    referralsReceived: {waiting: [], accepted: [], booked: [], dna: []},
    referralsOpen: [],
};

// INCOMING REQUESTS
function ifRequestAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralActionRequests {
    return [
        CONSTS.CLINICIAN_REFERRALS_ACTION.REQUEST,
        CONSTS.CLINICIAN_REFERRALS_INBOUND_ACTION.REQUEST,
        CONSTS.CLINICIAN_REFERRALS_OUTBOUND_ACTION.REQUEST,
        CONSTS.REFERRAL_ACCEPT_ACTION.REQUEST,
        CONSTS.REFERRAL_REJECT_ACTION.REQUEST,
        CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.REQUEST,
        CONSTS.REFERRAL_TOGGLE_DNA_ACTION.REQUEST,
        CONSTS.CREATE_REFERRAL_ACTION.REQUEST,
        // CONSTS.REFERRAL_LIST_REQUEST,
        CONSTS.REFERRAL_REQUEST,
        CONSTS.REFERRAL_CANCEL_ACTION.REQUEST,
    ].includes(action.type);
}

function handleRequestAction(state: ReferralState): ReferralState {
    return {
        ...state,
        loading: true,
    };
}

// INCOMING FAILURES
function isClinicianReferralsFailAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralActionFailures {
    return (
        action.type === CONSTS.CLINICIAN_REFERRALS_ACTION.FAIL ||
        action.type === CONSTS.CLINICIAN_REFERRALS_INBOUND_ACTION.FAIL ||
        action.type === CONSTS.CLINICIAN_REFERRALS_OUTBOUND_ACTION.FAIL
    );
}

function handleClinicianReferralsFailAction(
    state: ReferralState
): ReferralState {
    return {
        ...state,
        loading: false,
        referralsMade: {waiting: [], accepted: [], booked: [], dna: []},
        referralsReceived: {waiting: [], accepted: [], booked: [], dna: []},
        referralsOpen: [],
    };
}

function isReferralSingleFailAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralActionFailures {
    return [CONSTS.CREATE_REFERRAL_ACTION.FAIL, CONSTS.REFERRAL_FAIL].includes(
        action.type
    );
}

function handleReferralSingleFailAction(state: ReferralState): ReferralState {
    return {
        ...state,
        loading: false,
        referral: createDefaultReferral(),
    };
}

function ifFailureAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralActionFailures {
    return [
        CONSTS.REFERRAL_ACCEPT_ACTION.FAIL,
        CONSTS.REFERRAL_REJECT_ACTION.FAIL,
        CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.FAIL,
        CONSTS.REFERRAL_TOGGLE_DNA_ACTION.FAIL,
        CONSTS.REFERRAL_CANCEL_ACTION.FAIL,
    ].includes(action.type);
}

function handleFailureAction(state: ReferralState): ReferralState {
    return {
        ...state,
        loading: false,
    };
}

// INCOMING SUCCESS ACTIONS
function isReferralSelectionSuccessAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralSelectionSuccessAction {
    return [
        CONSTS.REFERRAL_ACCEPT_ACTION.SUCCESS,
        CONSTS.REFERRAL_REJECT_ACTION.SUCCESS,
    ].includes(action.type);
}
function handleReferralSelectionSuccessAction(
    state: ReferralState,
    action: ITF.ReferralSelectionSuccessAction
): ReferralState {
    return {
        ...state,
        loading: false,
        referralsReceived: {
            ...state.referralsReceived,
            waiting: action.payload.waiting,
            accepted: action.payload.accepted,
        },
        referralsOpen: action.payload.open,
    };
}

function isReferralToggleCompleteSuccessAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralToggleCompleteSuccessAction {
    return [
        CONSTS.REFERRAL_TOGGLE_DNA_ACTION.SUCCESS,
        CONSTS.REFERRAL_TOGGLE_COMPLETE_ACTION.SUCCESS,
    ].includes(action.type);
}

function handleReferralToggleCompleteSuccessAction(
    state: ReferralState,
    action: ITF.ReferralToggleCompleteSuccessAction
): ReferralState {
    return {
        ...state,
        loading: false,
        referralsReceived: {
            ...state.referralsReceived,
            ...action.payload,
        },
    };
}

function isReferralCancelSuccessAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralListsMadePendingSuccessAction {
    return action.type === CONSTS.REFERRAL_CANCEL_ACTION.SUCCESS;
}

function handleReferralCancelSuccessAction(
    state: ReferralState,
    action: ITF.ReferralListsMadePendingSuccessAction
): ReferralState {
    return {
        ...state,
        loading: false,
        referralsMade: {
            ...state.referralsMade,
            ...action.payload,
        },
    };
}

function isReferralSingleSuccessAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralSingleSuccessAction {
    return [
        CONSTS.CREATE_REFERRAL_ACTION.SUCCESS,
        CONSTS.REFERRAL_SUCCESS,
    ].includes(action.type);
}

function handleReferralSingleSuccessAction(
    state: ReferralState,
    action: ITF.ReferralSingleSuccessAction
): ReferralState {
    return {
        ...state,
        loading: false,
        referral: action.payload.referral,
    };
}

function isReferralListsSuccessAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralListsSuccessAction {
    return [
        CONSTS.CLINICIAN_REFERRALS_ACTION.SUCCESS,
    ].includes(action.type);
}

function handleReferralListsSuccessAction(
    state: ReferralState,
    action: ITF.ReferralListsSuccessAction
): ReferralState {
    return {
        ...state,
        loading: false,
        referralsMade: action.payload.referralsMade,
        referralsReceived: action.payload.referralsReceived,
        referralsOpen: action.payload.referralsOpen,
    };
}

function isReferralInboundListsSuccessAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralInboundListsSuccessAction {
    return action.type === CONSTS.CLINICIAN_REFERRALS_INBOUND_ACTION.SUCCESS;
}

function handleReferralInboundListsSuccessAction(
    state: ReferralState,
    action: ITF.ReferralInboundListsSuccessAction
): ReferralState {
    return {
        ...state,
        loading: false,
        ...action.payload,
    };
}

function isReferralOutboundListsSuccessAction(
    action: ITF.ReferralActionTypes
): action is ITF.ReferralOutboundListsSuccessAction {
    return action.type === CONSTS.CLINICIAN_REFERRALS_OUTBOUND_ACTION.SUCCESS;
}

function handleReferralOutboundListsSuccessAction(
    state: ReferralState,
    action: ITF.ReferralOutboundListsSuccessAction
): ReferralState {
    return {
        ...state,
        loading: false,
        ...action.payload,
    };
}

function isLogoutSuccessAction(
    action: ITF.ReferralActionTypes
): action is LogoutSuccessAction {
    return action.type === LOGOUT_ACTION.SUCCESS;
}
function handleLogoutSuccessAction(): ReferralState {
    return {...initialState};
}

export const referralReducer: Reducer<
    ReferralState,
    ITF.ReferralActionTypes
> = (state = initialState, action) => {
    // INCOMING REQUEST ACTIONS
    if (ifRequestAction(action)) {
        return handleRequestAction(state);
    }

    // INCOMING SUCCESS ACTIONS
    if (isReferralInboundListsSuccessAction(action)) {
        return handleReferralInboundListsSuccessAction(state, action);
    }
    if (isReferralOutboundListsSuccessAction(action)) {
        return handleReferralOutboundListsSuccessAction(state, action);
    }
    if (isReferralCancelSuccessAction(action)) {
        return handleReferralCancelSuccessAction(state, action);
    }
    if (isReferralSelectionSuccessAction(action)) {
        return handleReferralSelectionSuccessAction(state, action);
    }
    if (isReferralToggleCompleteSuccessAction(action)) {
        return handleReferralToggleCompleteSuccessAction(state, action);
    }
    if (isReferralSingleSuccessAction(action)) {
        return handleReferralSingleSuccessAction(state, action);
    }
    if (isReferralListsSuccessAction(action)) {
        return handleReferralListsSuccessAction(state, action);
    }

    if (isLogoutSuccessAction(action)) {
        return handleLogoutSuccessAction();
    }

    // INCOMING FAIL ACTIONS
    if (isClinicianReferralsFailAction(action)) {
        return handleClinicianReferralsFailAction(state);
    }
    if (isReferralSingleFailAction(action)) {
        return handleReferralSingleFailAction(state);
    }

    if (ifFailureAction(action)) {
        return handleFailureAction(state);
    }
    return state;
};
