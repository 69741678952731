import * as CONSTS from '@constants/authConstants';
import * as CONSTSUSR from '@/constants/userConstants';
import {Dispatch} from 'redux';
import * as CLIENT from '@/generated-client';

// App Version

export function dispatchGetAppVersionRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.APP_VERISON_ACTION.REQUEST,
    });
}
export function dispatchGetAppVersionSuccess(
    dispatch: Dispatch,
    payload: CLIENT.MedrAppVersion
): void {
    dispatch({
        type: CONSTS.APP_VERISON_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetAppVersionFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.APP_VERISON_ACTION.FAIL,
        payload: error,
    });
}

// Check Auth
export function dispatchCheckAuthRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.CHECK_AUTH_ACTION.REQUEST,
    });
}
export function dispatchCheckAuthSuccess(
    dispatch: Dispatch,
    payload: CLIENT.LoginUserResponse
): void {
    dispatch({
        type: CONSTS.CHECK_AUTH_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchCheckAuthFail(dispatch: Dispatch, error: string): void {
    dispatch({
        type: CONSTS.CHECK_AUTH_ACTION.FAIL,
        payload: error,
    });
}

// Login
export function dispatchLoginRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.LOGIN_ACTION.REQUEST,
    });
}
export function dispatchLoginSuccess(
    dispatch: Dispatch,
    payload: CLIENT.LoginUserResponse
): void {
    dispatch({
        type: CONSTS.LOGIN_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchLoginFail(dispatch: Dispatch, error: string): void {
    dispatch({
        type: CONSTS.LOGIN_ACTION.FAIL,
        payload: error,
    });
}

// Logout
export function dispatchLogoutRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.LOGOUT_ACTION.REQUEST,
    });
}
export function dispatchLogoutSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.LOGOUT_ACTION.SUCCESS,
    });
}
export function dispatchLogoutFail(dispatch: Dispatch, error: string): void {
    dispatch({
        type: CONSTS.LOGOUT_ACTION.FAIL,
        payload: error,
    });
}

// Register
export function dispatchRegisterRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.REGISTER_ACTION.REQUEST,
    });
}
export function dispatchRegisterSuccess(
    dispatch: Dispatch,
    payload: CLIENT.RegisterUserResponse
): void {
    dispatch({
        type: CONSTS.REGISTER_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchRegisterFail(dispatch: Dispatch, error: string): void {
    dispatch({
        type: CONSTS.REGISTER_ACTION.FAIL,
        payload: error,
    });
}

// Check Reg email
export function dispatchRegisterCheckEmailRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.REGISTER_CHECK_EMAIL_ACTION.REQUEST,
    });
}
export function dispatchRegisterCheckEmailSuccess(
    dispatch: Dispatch,
    payload: CLIENT.CheckRegisteringEmailResponse
): void {
    dispatch({
        type: CONSTS.REGISTER_CHECK_EMAIL_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchRegisterCheckEmailFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.REGISTER_CHECK_EMAIL_ACTION.FAIL,
        payload: error,
    });
}

// registerCheckClinic
export function dispatchRegisterCheckClinicRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.REGISTER_CHECK_CLINIC_ACTION.REQUEST,
    });
}
export function dispatchRegisterCheckClinicSuccess(
    dispatch: Dispatch,
    payload: CLIENT.CheckClinicAddressResponse
): void {
    dispatch({
        type: CONSTS.REGISTER_CHECK_CLINIC_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchRegisterCheckClinicFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.REGISTER_CHECK_CLINIC_ACTION.FAIL,
        payload: error,
    });
}

// verifyUserEmail
export function dispatchVerifyUserEmailRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.VERIFY_EMAIL_ACTION.REQUEST,
    });
}
export function dispatchVerifyUserEmailSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.VERIFY_EMAIL_ACTION.SUCCESS,
    });
}
export function dispatchVerifyUserEmailFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.VERIFY_EMAIL_ACTION.FAIL,
        payload: error,
    });
}

// registerDelegateUser
export function dispatchRegisterDelegateUserRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.REGISTER_DELEGATE_ACTION.REQUEST,
    });
}
export function dispatchRegisterDelegateUserSuccess(
    dispatch: Dispatch,
    payload: CLIENT.RegisterDelegateUserResponse
): void {
    dispatch({
        type: CONSTS.REGISTER_DELEGATE_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchRegisterDelegateUserFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.REGISTER_DELEGATE_ACTION.FAIL,
        payload: error,
    });
}

// getVerifyDelegateUser
export function dispatchGetVerifyDelegateUserRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.REGISTER_DELEGATE_INFO_ACTION.REQUEST,
    });
}
export function dispatchGetVerifyDelegateUserSuccess(
    dispatch: Dispatch,
    payload: CLIENT.DelegateUserRegistrationInfoResponse
): void {
    dispatch({
        type: CONSTS.REGISTER_DELEGATE_INFO_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetVerifyDelegateUserFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.REGISTER_DELEGATE_INFO_ACTION.FAIL,
        payload: error,
    });
}

// verifyDelegateUser
export function dispatchVerifyDelegateUserRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.VERIFY_DELEGATE_ACTION.REQUEST,
    });
}
export function dispatchVerifyDelegateUserSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.VERIFY_DELEGATE_ACTION.SUCCESS,
    });
}
export function dispatchVerifyDelegateUserFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.VERIFY_DELEGATE_ACTION.FAIL,
        payload: error,
    });
}

// getUserDelegateList
export function dispatchGetUserDelegateListRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.USER_DELEGATES_ACTION.REQUEST,
    });
}
export function dispatchGetUserDelegateListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.DelegatesListResponse
): void {
    dispatch({
        type: CONSTS.USER_DELEGATES_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetUserDelegateListFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.USER_DELEGATES_ACTION.FAIL,
        payload: error,
    });
}

// updateDelegateUserPermission
export function dispatchUpdateDelegateUserPermissionRequest(
    dispatch: Dispatch
): void {
    dispatch({
        type: CONSTS.UPDATE_DELEGATE_PERMISSION_ACTION.REQUEST,
    });
}
export function dispatchUpdateDelegateUserPermissionSuccess(
    dispatch: Dispatch,
    payload: CLIENT.DelegatesListResponse
): void {
    dispatch({
        type: CONSTS.UPDATE_DELEGATE_PERMISSION_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchUpdateDelegateUserPermissionFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.UPDATE_DELEGATE_PERMISSION_ACTION.FAIL,
        payload: error,
    });
}

// deactivateDelegateUser
export function dispatchDeactivateDelegateUserRequest(
    dispatch: Dispatch
): void {
    dispatch({
        type: CONSTS.DEACTIVATE_DELEGATE_ACTION.REQUEST,
    });
}
export function dispatchDeactivateDelegateUserSuccess(
    dispatch: Dispatch,
    payload: CLIENT.DelegatesListResponse
): void {
    dispatch({
        type: CONSTS.DEACTIVATE_DELEGATE_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchDeactivateDelegateUserFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.DEACTIVATE_DELEGATE_ACTION.FAIL,
        payload: error,
    });
}

// restoreDelegateUser
export function dispatchRestoreDelegateUserRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.RESTORE_DELEGATE_ACTION.REQUEST,
    });
}
export function dispatchRestoreDelegateUserSuccess(
    dispatch: Dispatch,
    payload: CLIENT.DelegatesListResponse
): void {
    dispatch({
        type: CONSTS.RESTORE_DELEGATE_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchRestoreDelegateUserFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.RESTORE_DELEGATE_ACTION.FAIL,
        payload: error,
    });
}

// resetPasswordSetNew
export function dispatchResetPasswordSetNewRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.RESET_PASSWORD_SET_NEW_ACTION.REQUEST,
    });
}
export function dispatchResetPasswordSetNewSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.RESET_PASSWORD_SET_NEW_ACTION.SUCCESS,
    });
}
export function dispatchResetPasswordSetNewFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.RESET_PASSWORD_SET_NEW_ACTION.FAIL,
        payload: error,
    });
}

//sendResetPassword
export function dispatchSendResetPasswordRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.REQUEST,
    });
}
export function dispatchSendResetPasswordSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.SUCCESS,
    });
}
export function dispatchSendResetPasswordFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.FAIL,
        payload: error,
    });
}

// updateUserPassword
export function dispatchUpdateUserPasswordRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.REQUEST,
    });
}
export function dispatchUpdateUserPasswordSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.SUCCESS,
    });
}
export function dispatchUpdateUserPasswordFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTSUSR.USER_UPDATE_PASSWORD_ACTION.FAIL,
        payload: error,
    });
}

// deactivateAccount
export function dispatchDeactivateAccountRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.DEACTIVATE_FULL_ACCOUNT_ACTION.REQUEST,
    });
}
export function dispatchDeactivateAccountSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.DEACTIVATE_FULL_ACCOUNT_ACTION.SUCCESS,
    });
}
export function dispatchDeactivateAccountFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.DEACTIVATE_FULL_ACCOUNT_ACTION.FAIL,
        payload: error,
    });
}

// sendErrorNotification
export function dispatchSendErrorNotificationRequest(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.SEND_ERROR_NOTIFICATION_ACTION.REQUEST,
    });
}
export function dispatchSendErrorNotificationSuccess(dispatch: Dispatch): void {
    dispatch({
        type: CONSTS.SEND_ERROR_NOTIFICATION_ACTION.SUCCESS,
    });
}
export function dispatchSendErrorNotificationFail(
    dispatch: Dispatch,
    error: string
): void {
    dispatch({
        type: CONSTS.SEND_ERROR_NOTIFICATION_ACTION.FAIL,
        payload: error,
    });
}
