/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterDelegateUserRequest
 */
export interface RegisterDelegateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterDelegateUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDelegateUserRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDelegateUserRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDelegateUserRequest
     */
    lastName: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterDelegateUserRequest
     */
    permissionViewReferrals: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterDelegateUserRequest
     */
    permissionCreateReferrals: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterDelegateUserRequest
     */
    permissionManageReferrals: boolean;
}

/**
 * Check if a given object implements the RegisterDelegateUserRequest interface.
 */
export function instanceOfRegisterDelegateUserRequest(value: object): value is RegisterDelegateUserRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('permissionViewReferrals' in value) || value['permissionViewReferrals'] === undefined) return false;
    if (!('permissionCreateReferrals' in value) || value['permissionCreateReferrals'] === undefined) return false;
    if (!('permissionManageReferrals' in value) || value['permissionManageReferrals'] === undefined) return false;
    return true;
}

export function RegisterDelegateUserRequestFromJSON(json: any): RegisterDelegateUserRequest {
    return RegisterDelegateUserRequestFromJSONTyped(json, false);
}

export function RegisterDelegateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterDelegateUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'title': json['title'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'permissionViewReferrals': json['permissionViewReferrals'],
        'permissionCreateReferrals': json['permissionCreateReferrals'],
        'permissionManageReferrals': json['permissionManageReferrals'],
    };
}

export function RegisterDelegateUserRequestToJSON(json: any): RegisterDelegateUserRequest {
    return RegisterDelegateUserRequestToJSONTyped(json, false);
}

export function RegisterDelegateUserRequestToJSONTyped(value?: RegisterDelegateUserRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'title': value['title'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'permissionViewReferrals': value['permissionViewReferrals'],
        'permissionCreateReferrals': value['permissionCreateReferrals'],
        'permissionManageReferrals': value['permissionManageReferrals'],
    };
}

