import {ClinicCenter} from '@/generated-client';
export interface ClinicFormData {
    clinicName: string;
    centreName: string;
    address: {
        firstLine: string;
        city: string;
        postcode: string;
    };
    listInd?: number;
    id?: number;
}

export type ClinicFormInput = ClinicCenter | ClinicFormData;

export function createDefaultClinicFormData(): ClinicFormData {
    return {
        clinicName: '',
        centreName: '',
        address: {
            firstLine: '',
            city: '',
            postcode: '',
        },
    };
}
