import React from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
    getNameString,
    reformatDate,
    reformatDateTime,
    randomDateString,
    randomNameString,
    randomPhoneNumber,
} from '@/actions/general';
import {DisplayType, PatientBarProps, ButtonFormatProps} from './interfaces';
import usePatientBar from './hooks';
import DetailsIcon from '@/components/app/parts/DetailsIcon';
import AddressIcon from '@/components/app/parts/AddressIcon';
import EmailIcon from '@/components/app/parts/EmailIcon';
import PhoneIcon from '@/components/app/parts/PhoneIcon';
import useScreenInfo from '@/hooks/app/useScreenInfo';

const PatientBar: React.FC<PatientBarProps> = ({patient}) => {
    const {isNarrow} = useScreenInfo();

    const {
        selectedView,
        isOpen,
        handleClickOpen,
        permissionCreateReferrals,
        permissionManageReferrals,
        onClickMakeReferButton,
        onClickMakeReferOnwardButton,
        onClickUpdateButton,
        onClickDeleteButton,
        // onClickSeeReferralsButton,
    } = usePatientBar(patient);

    if (!patient) {
        return <div id="referral_missing">Loading...</div>;
    }

    // BUTTONS
    const invisbleButton = <Button className="invisible" />;

    const ButtonFormat: React.FC<ButtonFormatProps> = ({
        toolTipText,
        variant,
        onClick,
        isDisabled,
        buttonText,
    }) => {
        return (
            <OverlayTrigger
                placement="left"
                delay={{show: 250, hide: 400}}
                overlay={
                    <Tooltip
                        id="reject-button-tooltip"
                        className="rounded-tooltip"
                    >
                        {toolTipText}
                    </Tooltip>
                }
            >
                <Button
                    className="sel-button w80"
                    variant={variant}
                    onClick={onClick}
                    disabled={isDisabled}
                >
                    {buttonText}
                </Button>
            </OverlayTrigger>
        );
    };

    const makeReferButton = (
        <ButtonFormat
            toolTipText={`Refer ${getNameString(patient)} to another clinician`}
            buttonText="Refer"
            variant="success"
            onClick={onClickMakeReferButton}
            isDisabled={!permissionCreateReferrals}
        />
    );

    const makeReferOnwardButton = (
        <ButtonFormat
            toolTipText={`Refer ${getNameString(patient)} to another clinician`}
            buttonText="Refer on"
            variant="success"
            onClick={onClickMakeReferOnwardButton}
            isDisabled={!permissionCreateReferrals}
        />
    );

    const updateButton = (
        <ButtonFormat
            toolTipText={`Update ${getNameString(patient)}'s information`}
            buttonText="Update"
            variant="info"
            onClick={onClickUpdateButton}
            isDisabled={!permissionManageReferrals}
        />
    );

    const deleteButton = (
        <ButtonFormat
            toolTipText={`Delete ${getNameString(patient)}`}
            buttonText="Delete"
            variant="danger"
            onClick={onClickDeleteButton}
            isDisabled={!permissionManageReferrals}
        />
    );

    // TOP LINE DISPLAY
    const TopLineFormat = ({pxName, pxDob, pxSex, buttons}) => {
        const detailsDisplayOpen = 'Hide patient details';
        const detailsDisplayClosed = 'Show patient details';
        if (isNarrow) {
            return (
                <div className="row">
                    <div className="col  flex-grow-1">
                        <div className="p-1">
                            <DetailsIcon
                                isOpen={isOpen}
                                onClick={handleClickOpen}
                                displayTextOpen={detailsDisplayOpen}
                                displayTextClosed={detailsDisplayClosed}
                            />
                        </div>
                        {pxName}
                        {pxDob}
                        {pxSex}
                    </div>
                    <div className="sel-button-mobile-col">
                        {buttons.map((button: JSX.Element, index: number) => (
                            <div className="p-1" key={index}>
                                {button}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return (
            <div className="d-flex align-items-center referral-bar">
                <div style={{width: '25px'}}>
                    <DetailsIcon
                        isOpen={isOpen}
                        onClick={handleClickOpen}
                        displayTextOpen={detailsDisplayOpen}
                        displayTextClosed={detailsDisplayClosed}
                    />
                </div>
                <div
                    className="flex-fill referral-bar-item lines-max-2 referral-bar-item-1"
                    style={{paddingLeft: '10px'}}
                >
                    {pxName}
                </div>
                <div
                    className="flex-fill referral-bar-item lines-max-2 referral-bar-item-2"
                    style={{paddingLeft: '4px'}}
                >
                    {pxDob}
                </div>
                <div
                    className="flex-fill referral-bar-item lines-max-2 referral-bar-item-3"
                    style={{paddingLeft: '4px'}}
                >
                    {pxSex}
                </div>
                <div
                    className="flex-fill referral-bar-item lines-max-1"
                    style={{paddingLeft: '4px'}}
                >
                    {' '}
                </div>
                <div className="d-flex align-items-center p-0 bar-buttons referral-bar-item-5">
                    {buttons.map((button: JSX.Element, index: number) => (
                        <div
                            className="flex-fill text-center referral-bar-item"
                            key={index}
                        >
                            {button}
                        </div>
                    ))}
                </div>
                {/* <div className="text-right">
                        <div style={{width: '25px'}}>
                            <CopyIcon/>
                        </div>
                    </div> */}
            </div>
        );
    };

    const PxBarTopLineAdded = () => {
        const pxName = <p className="m-0">{getNameString(patient)}</p>;
        const pxDob = <p className="m-0">{reformatDate(patient.dob)}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const buttons = [makeReferButton, updateButton, deleteButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                buttons={buttons}
            />
        );
    };

    const PxBarTopLineSent = () => {
        const pxName = <p className="m-0">{getNameString(patient)}</p>;
        const pxDob = <p className="m-0">{reformatDate(patient.dob)}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const buttons = [invisbleButton, makeReferOnwardButton, updateButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                buttons={buttons}
            />
        );
    };

    const PxBarTopLineAccepted = () => {
        const pxName = <p className="m-0">{getNameString(patient)}</p>;
        const pxDob = <p className="m-0">{reformatDate(patient.dob)}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const buttons = [invisbleButton, makeReferOnwardButton, updateButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                buttons={buttons}
            />
        );
    };

    const PxBarTopLinePending = () => {
        const pxName = <p className="m-0 blur-text">{randomNameString()}</p>;
        const pxDob = <p className="m-0 blur-text">{randomDateString()}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const buttons = [invisbleButton, makeReferOnwardButton, updateButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                buttons={buttons}
            />
        );
    };

    const PxBarTopLineOpen = () => {
        const pxName = <p className="m-0 blur-text">{randomNameString()}</p>;
        const pxDob = <p className="m-0 blur-text">{randomDateString()}</p>;
        const pxSex = <p className="m-0">{patient.sex}</p>;
        const buttons = [invisbleButton, makeReferOnwardButton, updateButton];
        return (
            <TopLineFormat
                pxName={pxName}
                pxDob={pxDob}
                pxSex={pxSex}
                buttons={buttons}
            />
        );
    };

    const topLines = {
        [DisplayType.Added]: <PxBarTopLineAdded />,
        [DisplayType.Sent]: <PxBarTopLineSent />,
        [DisplayType.Accepted]: <PxBarTopLineAccepted />,
        [DisplayType.Pending]: <PxBarTopLinePending />,
        [DisplayType.Open]: <PxBarTopLineOpen />,
    };

    const iconWidth = 20;
    const iconPadding = 5;
    const totalWidth = iconWidth + iconPadding;
    const iconSpanStyle = {
        width: `${iconWidth}px`,
        paddingRight: `${iconPadding}px`,
    };
    const noIconSpanStyle = {paddingLeft: `${totalWidth}px`};

    const AddressDetails = (
        firstLine: string,
        city: string,
        postcode: string,
        blurView: boolean = false
    ) => {
        return (
            <>
                {firstLine !== '' && (
                    <p
                        className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                    >
                        <span style={iconSpanStyle}>
                            <AddressIcon />
                        </span>
                        <small>{firstLine}</small>
                    </p>
                )}
                {city !== '' && (
                    <p
                        className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                    >
                        {firstLine === '' ? (
                            <>
                                <span style={iconSpanStyle}>
                                    <AddressIcon />
                                </span>
                                <small>{city}</small>
                            </>
                        ) : (
                            <span style={noIconSpanStyle}>
                                <small>{city}</small>
                            </span>
                        )}
                    </p>
                )}

                <p
                    className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                >
                    {city === '' && firstLine === '' ? (
                        <>
                            <span style={iconSpanStyle}>
                                <AddressIcon />
                            </span>
                            <small>{postcode}</small>
                        </>
                    ) : (
                        <span style={noIconSpanStyle}>
                            <small>{postcode}</small>
                        </span>
                    )}
                </p>
            </>
        );
    };

    const pxAddress = () => {
        const firstLine = patient.address.firstLine;
        const city = patient.address.city;
        const postcode = patient.address.postcode;
        return AddressDetails(firstLine, city, postcode);
    };

    const pxAddressHidden = () => {
        const firstLine = '10 Downing Street';
        const city = 'London';
        const postcode = 'SW1A 2AB';
        return AddressDetails(firstLine, city, postcode, true);
    };

    const ContactDetails = (
        email: JSX.Element,
        phone: JSX.Element,
        blurView: boolean = false
    ) => {
        return (
            <>
                <p
                    className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                >
                    <span style={iconSpanStyle}>
                        <EmailIcon />
                    </span>
                    {email}
                </p>
                <p
                    className={`m-0 p-0 text-left ${blurView ? 'blur-text' : ''}`}
                >
                    <span style={iconSpanStyle}>
                        <PhoneIcon />
                    </span>
                    {phone}
                </p>
            </>
        );
    };

    const pxContact = () => {
        const email = (
            <a
                // onClick={(event) => event.stopPropagation()}
                href={`mailto:"${patient.primaryEmail}"`}
            >
                <small>{patient.primaryEmail}</small>
            </a>
        );
        const phone = (
            <a
                onClick={(event) => event.stopPropagation()}
                href={`tel:"${patient.primaryPhoneNumber}"`}
            >
                <small>{patient.primaryPhoneNumber}</small>
            </a>
        );
        return ContactDetails(email, phone);
    };

    const pxContactHidden = () => {
        const email = <small>newemail@example.com</small>;
        const phone = <small>{randomPhoneNumber()}</small>;
        return ContactDetails(email, phone, true);
    };

    const PatientInfo = (sex: string, age: number, insurance: string) => {
        return (
            <>
                <p className="m-0 p-0 text-left">
                    <small>Sex: {sex}</small>
                </p>
                <p className="m-0 p-0 text-left">
                    <small>Age: {age.toFixed(0)} years old</small>
                </p>
                <p className="m-0 p-0 text-left">
                    <small>Insurance: {insurance}</small>
                </p>
            </>
        );
    };
    const pxInfo = () => {
        const sex = patient.sex;
        const age = patient.currentAge;
        const insurance = patient.insurancePolicy
            ? `${patient.insurancePolicy.provider}`
            : 'Self-payer';
        return PatientInfo(sex, age, insurance);
    };
    const pxInfoHidden = () => {
        const sex = patient.sex;
        const age = patient.currentAge;
        const insurance = patient.insurancePolicy
            ? `${patient.insurancePolicy.provider}`
            : 'Self-payer';
        return PatientInfo(sex, age, insurance);
    };

    const BottomLineFormat = ({items}) => {
        return (
            <div className="d-flex p-1 referral-info">
                {items.map((item: JSX.Element, index: number) => (
                    <div
                        className="flex-fill text-center referral-bar-bottom"
                        key={index}
                    >
                        {item}
                    </div>
                ))}
            </div>
        );
    };

    const RxBarBottomLineAdded: React.FC = () => {
        const items = [pxInfo(), pxContact(), pxAddress()];
        return <BottomLineFormat items={items} />;
    };
    const RxBarBottomLineSent: React.FC = () => {
        const items = [pxInfo(), pxContact(), pxAddress()];
        return <BottomLineFormat items={items} />;
    };
    const RxBarBottomLineAccepted: React.FC = () => {
        const items = [pxInfo(), pxContact(), pxAddress()];
        return <BottomLineFormat items={items} />;
    };
    const RxBarBottomLinePending: React.FC = () => {
        const items = [pxInfoHidden(), pxContactHidden(), pxAddressHidden()];
        return <BottomLineFormat items={items} />;
    };

    const RxBarBottomLineOpen: React.FC = () => {
        const items = [pxInfoHidden(), pxContactHidden(), pxAddressHidden()];
        return <BottomLineFormat items={items} />;
    };

    const bottomLines = {
        [DisplayType.Added]: <RxBarBottomLineAdded />,
        [DisplayType.Sent]: <RxBarBottomLineSent />,
        [DisplayType.Accepted]: <RxBarBottomLineAccepted />,
        [DisplayType.Pending]: <RxBarBottomLinePending />,
        [DisplayType.Open]: <RxBarBottomLineOpen />,
    };

    const timeInfo = (
        <div className="d-flex p-1 referral-info">
            <div className="flex-fill text-left referral-bar-bottom">
                <p className="m-0 text-small">
                    <small>Added: {reformatDateTime(patient.createdOn)}</small>
                </p>
            </div>
        </div>
    );
    return (
        <div
            // className={`medr-rounded-inv obj-clickable ${isOpen ? 'bar-open' : ''}`}
            className={`medr-rounded-inv ${isOpen ? 'bar-open' : ''}`}
            role="button"
            tabIndex={0}
            id={`pxbar${patient.id}`}
        >
            {topLines[selectedView]}
            {isOpen && bottomLines[selectedView]}
            {isOpen && timeInfo}
        </div>
    );
};

export default PatientBar;
