import {useState, useEffect} from 'react';
import {useClinicianService} from '@/actions';
import {ClinicianRoleProfile} from '@/generated-client';
import {UseUserProfileCardReturn} from './interfaces';

export const useUserProfileCard = (
    userProfileId: number
): UseUserProfileCardReturn => {
    const clinicianService = useClinicianService();
    const [clinicianProfiles, setClinicianProfiles] = useState<
        ClinicianRoleProfile[] | null
    >(null);
    const [showError, setShowError] = useState<boolean>(false);
    const toggleError = () => {};

    const goToDirectReferral = (crpId: number) => {
        clinicianService.goToReferToClinician(crpId);
    };

    useEffect(() => {
        const handleGetClinicianById = async () => {
            const data =
                await clinicianService.getClinicianByUserDataId(userProfileId);
            if (!data) {
                setShowError(true);
                setClinicianProfiles(null);
                setTimeout(() => {
                    clinicianService.goToCliniciansMapPage();
                }, 5000);
                return;
            }
            setClinicianProfiles(data.allCrps);
        };
        handleGetClinicianById();
    }, []);

    return {clinicianProfiles, goToDirectReferral, showError, toggleError};
};
