import React from 'react';

const SendResetPassswordScreenSuccess: React.FC = () => {
    return (
        <div
            className="medr-layout text-center reset-password-page"
            style={{
                maxWidth: '380px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <h1>Password reset</h1>
            <p className="medr-text">
                Please check your emails for a link to set your new password
            </p>
        </div>
    );
};

export default SendResetPassswordScreenSuccess;
