/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DelegateUserLink } from './DelegateUserLink';
import {
    DelegateUserLinkFromJSON,
    DelegateUserLinkFromJSONTyped,
    DelegateUserLinkToJSON,
    DelegateUserLinkToJSONTyped,
} from './DelegateUserLink';

/**
 * 
 * @export
 * @interface DeactivateDelegateRequest
 */
export interface DeactivateDelegateRequest {
    /**
     * 
     * @type {DelegateUserLink}
     * @memberof DeactivateDelegateRequest
     */
    delegate: DelegateUserLink;
}

/**
 * Check if a given object implements the DeactivateDelegateRequest interface.
 */
export function instanceOfDeactivateDelegateRequest(value: object): value is DeactivateDelegateRequest {
    if (!('delegate' in value) || value['delegate'] === undefined) return false;
    return true;
}

export function DeactivateDelegateRequestFromJSON(json: any): DeactivateDelegateRequest {
    return DeactivateDelegateRequestFromJSONTyped(json, false);
}

export function DeactivateDelegateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeactivateDelegateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'delegate': DelegateUserLinkFromJSON(json['delegate']),
    };
}

export function DeactivateDelegateRequestToJSON(json: any): DeactivateDelegateRequest {
    return DeactivateDelegateRequestToJSONTyped(json, false);
}

export function DeactivateDelegateRequestToJSONTyped(value?: DeactivateDelegateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'delegate': DelegateUserLinkToJSON(value['delegate']),
    };
}

