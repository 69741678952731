import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {SexEnum} from '@/generated-client';
import {customStyles, ViewOption} from '@/constants/styles';

interface GenderSearchBarProps {
    gender?: SexEnum | string;
    changeValue: (value: {[key: string]: string}) => void;
    valueKey?: string;
}

const GenderSearchBar: React.FC<GenderSearchBarProps> = ({
    gender = 'Search sex',
    changeValue,
    valueKey = 'sex',
}) => {
    const [currentGender, setCurrentGender] = useState<string>(gender);

    // Define options for the Select component
    const options: ViewOption[] = Object.values(SexEnum).map((value) => ({
        label: value,
        value,
    }));

    // Check if the provided gender is in the options list
    const checkGenderList = (): string => {
        const isInLabels = options.some((dict) => dict.label === gender);
        return isInLabels ? gender : '';
    };

    // Update currentGender when the gender prop changes
    useEffect(() => {
        setCurrentGender(checkGenderList());
    }, [gender]);

    return (
        <Select
            id="gender-search-bar"
            options={options}
            onChange={(opt) => changeValue({[valueKey]: opt?.value || ''})}
            placeholder={currentGender !== '' ? currentGender : 'Select sex'}
            className="form-select custom-dropdown-select"
            styles={customStyles}
        />
    );
};

export default GenderSearchBar;
