import {Dispatch} from 'redux';
import * as CONSTS from '@/constants/clinicianConstants';
import * as CLIENT from '@/generated-client';

// getInsuranceAgreements
export function dispatchGetInsuranceAgreementsRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.REQUEST});
}
export function dispatchGetInsuranceAgreementsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.InsuranceAgreementListResponse
) {
    dispatch({type: CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.SUCCESS, payload});
}
export function dispatchGetInsuranceAgreementsFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.INSURANCE_AGREEMENT_LIST_ACTION.FAIL,
        payload: error,
    });
}

// getCliniciansList
export function dispatchGetCliniciansListRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_LIST_ACTION.REQUEST});
}
export function dispatchGetCliniciansListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ListCliniciansResponse
) {
    dispatch({type: CONSTS.CLINICIAN_LIST_ACTION.SUCCESS, payload});
}
export function dispatchGetCliniciansListFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.CLINICIAN_LIST_ACTION.FAIL, payload: error});
}

// getClinicsList
export function dispatchGetClinicsListRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICS_LIST_ACTION.REQUEST});
}
export function dispatchGetClinicsListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ListClinicsResponse
) {
    dispatch({type: CONSTS.CLINICS_LIST_ACTION.SUCCESS, payload});
}
export function dispatchGetClinicsListFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.CLINICS_LIST_ACTION.FAIL, payload: error});
}

// getClinicianById
export function dispatchGetClinicianByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_BY_ID_ACTION.REQUEST});
}
export function dispatchGetClinicianByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianInfoByIdResponse
) {
    dispatch({type: CONSTS.CLINICIAN_BY_ID_ACTION.SUCCESS, payload});
}
export function dispatchGetClinicianByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.CLINICIAN_BY_ID_ACTION.FAIL, payload: error});
}

// getClinicianDataById
export function dispatchGetClinicianDataByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_BY_ID_ACTION.REQUEST});
}
export function dispatchGetClinicianDataByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianInfoByIdResponse
) {
    dispatch({type: CONSTS.CLINICIAN_BY_ID_ACTION.SUCCESS, payload});
}
export function dispatchGetClinicianDataByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.CLINICIAN_BY_ID_ACTION.FAIL, payload: error});
}

// getClinicianByUserId
export function dispatchGetClinicianByUserIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.USER_CRPS_GET_ACTION.REQUEST});
}
export function dispatchGetClinicianByUserIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianInfoByIdResponse
) {
    dispatch({type: CONSTS.USER_CRPS_GET_ACTION.SUCCESS, payload});
}
export function dispatchGetClinicianByUserIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.USER_CRPS_GET_ACTION.FAIL, payload: error});
}

// postCreateCrp
export function dispatchPostCreateCrpRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_CREATE_ACTION.REQUEST});
}
export function dispatchPostCreateCrpSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianProfileCreateResponse
) {
    dispatch({type: CONSTS.CLINICIAN_CREATE_ACTION.SUCCESS, payload});
}
export function dispatchPostCreateCrpFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.CLINICIAN_CREATE_ACTION.FAIL, payload: error});
}

// postRequestRemoveCrp
export function dispatchPostRequestRemoveCrpRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_ROLE_REMOVE_REQ_ACTION.REQUEST});
}
export function dispatchPostRequestRemoveCrpSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianProfileCreateResponse
) {
    dispatch({
        type: CONSTS.CLINICIAN_ROLE_REMOVE_REQ_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchPostRequestRemoveCrpFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.CLINICIAN_ROLE_REMOVE_REQ_ACTION.FAIL,
        payload: error,
    });
}

// editCrp
export function dispatchEditCrpRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_UPDATE_ACTION.REQUEST});
}
export function dispatchEditCrpSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianProfileCreateResponse
) {
    dispatch({type: CONSTS.CLINICIAN_UPDATE_ACTION.SUCCESS, payload});
}
export function dispatchEditCrpFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.CLINICIAN_UPDATE_ACTION.FAIL, payload: error});
}

// postDeactivateCrp
export function dispatchPostDeactivateCrpRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.REQUEST});
}
export function dispatchPostDeactivateCrpSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianProfileDeactivateResponse
) {
    dispatch({
        type: CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchPostDeactivateCrpFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.CLINICIAN_ROLE_DEACTIVATE_ACTION.FAIL,
        payload: error,
    });
}

// updateClinicianInsuranceAgreements
export function dispatchUpdateClinicianInsuranceAgreementsRequest(
    dispatch: Dispatch
) {
    dispatch({type: CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.REQUEST});
}
export function dispatchUpdateClinicianInsuranceAgreementsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianInsuranceUpdateResponse
) {
    dispatch({
        type: CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchUpdateClinicianInsuranceAgreementsFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.CLINICIAN_INSURANCE_UPDATE_ACTION.FAIL,
        payload: error,
    });
}

// addClinicianToFavourites
export function dispatchAddClinicianToFavouritesRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_ADD_FAVOURITE_ACTION.REQUEST});
}
export function dispatchAddClinicianToFavouritesSuccess(
    dispatch: Dispatch,
    payload: CLIENT.FavouriteClinicianCreateResponse
) {
    dispatch({type: CONSTS.CLINICIAN_ADD_FAVOURITE_ACTION.SUCCESS, payload});
}
export function dispatchAddClinicianToFavouritesFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.CLINICIAN_ADD_FAVOURITE_ACTION.FAIL,
        payload: error,
    });
}

// removeClinicianFromFavourites
export function dispatchRemoveClinicianFromFavouritesRequest(
    dispatch: Dispatch
) {
    dispatch({type: CONSTS.CLINICIAN_RM_FAVOURITE_ACTION.REQUEST});
}
export function dispatchRemoveClinicianFromFavouritesSuccess(
    dispatch: Dispatch,
    payload: CLIENT.FavouriteClinicianCreateResponse
) {
    dispatch({type: CONSTS.CLINICIAN_RM_FAVOURITE_ACTION.SUCCESS, payload});
}
export function dispatchRemoveClinicianFromFavouritesFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.CLINICIAN_RM_FAVOURITE_ACTION.FAIL,
        payload: error,
    });
}

// inviteClinicianToMedr
export function dispatchInviteClinicianToMedrRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.INVITE_CLINICIAN_ACTION.REQUEST});
}
export function dispatchInviteClinicianToMedrSuccess(
    dispatch: Dispatch,
    payload: CLIENT.InviteClinicianResponse
) {
    dispatch({type: CONSTS.INVITE_CLINICIAN_ACTION.SUCCESS, payload});
}
export function dispatchInviteClinicianToMedrFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.INVITE_CLINICIAN_ACTION.FAIL, payload: error});
}

// getJobRoles
export function dispatchGetJobRolesRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.JOB_ROLE_LIST_ACTION.REQUEST});
}
export function dispatchGetJobRolesSuccess(
    dispatch: Dispatch,
    payload: CLIENT.JobRoleListResponse
) {
    dispatch({type: CONSTS.JOB_ROLE_LIST_ACTION.SUCCESS, payload});
}
export function dispatchGetJobRolesFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.JOB_ROLE_LIST_ACTION.FAIL, payload: error});
}

// getFilteredJobRoles
export function dispatchGetFilteredJobRolesRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.JOB_ROLE_LIST_ACTION.REQUEST});
}
export function dispatchGetFilteredJobRolesSuccess(
    dispatch: Dispatch,
    payload: CLIENT.JobRoleListResponse
) {
    dispatch({type: CONSTS.JOB_ROLE_LIST_ACTION.SUCCESS, payload});
}
export function dispatchGetFilteredJobRolesFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.JOB_ROLE_LIST_ACTION.FAIL, payload: error});
}

// getJobRoleById
export function dispatchGetJobRoleByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.JOB_ROLE_BY_ID_ACTION.REQUEST});
}
export function dispatchGetJobRoleByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.JobRoleByIdResponse
) {
    dispatch({type: CONSTS.JOB_ROLE_BY_ID_ACTION.SUCCESS, payload});
}
export function dispatchGetJobRoleByIdFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.JOB_ROLE_BY_ID_ACTION.FAIL, payload: error});
}
