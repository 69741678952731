/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `OPEN` - Open
 * * `PENDING` - Pending
 * * `ACCEPTED` - Accepted
 * * `REJECTED` - Rejected
 * * `BOOKED` - Booked
 * * `DNA` - Did Not Attend
 * * `ADMIN` - Admin
 * * `SENT` - Sent
 * @export
 */
export const StatusEnum = {
    Open: 'OPEN',
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Booked: 'BOOKED',
    Dna: 'DNA',
    Admin: 'ADMIN',
    Sent: 'SENT'
} as const;
export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];


export function instanceOfStatusEnum(value: any): boolean {
    for (const key in StatusEnum) {
        if (Object.prototype.hasOwnProperty.call(StatusEnum, key)) {
            if (StatusEnum[key as keyof typeof StatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StatusEnumFromJSON(json: any): StatusEnum {
    return StatusEnumFromJSONTyped(json, false);
}

export function StatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusEnum {
    return json as StatusEnum;
}

export function StatusEnumToJSON(value?: StatusEnum | null): any {
    return value as any;
}

export function StatusEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): StatusEnum {
    return value as StatusEnum;
}

