/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckRegisteringEmailResponse
 */
export interface CheckRegisteringEmailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckRegisteringEmailResponse
     */
    emailValid: boolean;
}

/**
 * Check if a given object implements the CheckRegisteringEmailResponse interface.
 */
export function instanceOfCheckRegisteringEmailResponse(value: object): value is CheckRegisteringEmailResponse {
    if (!('emailValid' in value) || value['emailValid'] === undefined) return false;
    return true;
}

export function CheckRegisteringEmailResponseFromJSON(json: any): CheckRegisteringEmailResponse {
    return CheckRegisteringEmailResponseFromJSONTyped(json, false);
}

export function CheckRegisteringEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckRegisteringEmailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'emailValid': json['emailValid'],
    };
}

export function CheckRegisteringEmailResponseToJSON(json: any): CheckRegisteringEmailResponse {
    return CheckRegisteringEmailResponseToJSONTyped(json, false);
}

export function CheckRegisteringEmailResponseToJSONTyped(value?: CheckRegisteringEmailResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'emailValid': value['emailValid'],
    };
}

