import React from 'react';
import UserUpdateRequest from '../UpdateUserDetails/UserUpdateRequest';
import ReferralsContactsDisplay from '../ReferralContacts/ReferralContactsDisplay';
import useReferralContacts from '@/hooks/clinicians/useReferralContacts';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';

const ProfileInfoUpdate: React.FC = () => {
    const isDelegate = useSelector(
        (state: RootState) => state.profile.isDelegate
    );
    const {referralContacts, requiredEmails, updateAppointmentContacts} =
        useReferralContacts();

    return (
        <>
            <UserUpdateRequest />
            {!isDelegate ? (
                <ReferralsContactsDisplay
                    contacts={referralContacts}
                    updateAppointmentContacts={updateAppointmentContacts}
                    requiredEmails={requiredEmails}
                />
            ) : (
                <div className="medr-rounded bg-medr-gray bluegreen mt-4">
                    <h4 className="bluegreen text-center">Referral Contacts</h4>
                    <p>Only the clinician can update Referral contacts</p>
                </div>
            )}
        </>
    );
};

export default ProfileInfoUpdate;
