/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
    ReferralToJSONTyped,
} from './Referral';

/**
 * 
 * @export
 * @interface ReferralsListMadePendingResponse
 */
export interface ReferralsListMadePendingResponse {
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralsListMadePendingResponse
     */
    waiting: Array<Referral>;
}

/**
 * Check if a given object implements the ReferralsListMadePendingResponse interface.
 */
export function instanceOfReferralsListMadePendingResponse(value: object): value is ReferralsListMadePendingResponse {
    if (!('waiting' in value) || value['waiting'] === undefined) return false;
    return true;
}

export function ReferralsListMadePendingResponseFromJSON(json: any): ReferralsListMadePendingResponse {
    return ReferralsListMadePendingResponseFromJSONTyped(json, false);
}

export function ReferralsListMadePendingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralsListMadePendingResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'waiting': ((json['waiting'] as Array<any>).map(ReferralFromJSON)),
    };
}

export function ReferralsListMadePendingResponseToJSON(json: any): ReferralsListMadePendingResponse {
    return ReferralsListMadePendingResponseToJSONTyped(json, false);
}

export function ReferralsListMadePendingResponseToJSONTyped(value?: ReferralsListMadePendingResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'waiting': ((value['waiting'] as Array<any>).map(ReferralToJSON)),
    };
}

