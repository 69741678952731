import React from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import TitleDropdown from '../TitleDropdown/TitleDropdown';
import useUserUpdateRequest from './useUserUpdateRequest';
import ClinicianDescriptionField from '@components/user/UpdateUserDetails/ClinicianDescriptionField';
import {useUserService} from '@/actions';
import NotificationMessage, {
    Variant,
} from '@/components/app/NotificationMessage';

const UserUpdateRequest: React.FC = () => {
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const toggleSuccess = () => setShowSuccess((prev) => !prev);
    const toggleError = () => setShowError((prev) => !prev);

    const userService = useUserService();

    const {userFormData, updateFormData, setDOB, isDOBValid} =
        useUserUpdateRequest();

    const {title, firstName, lastName, dob, clinicianDescription} =
        userFormData;

    const handleUpdateProfile = async () => {
        const payload = {...userFormData, dob: new Date(dob)};
        const isSuccess = await userService.updateProfile(payload);
        if (isSuccess) {
            toggleSuccess();
        } else {
            toggleError();
        }
    };

    const onSubmit = () => handleUpdateProfile();

    const requestErrorText =
        'Error updating user information, please try again.';
    const requestSuccessText = 'Succesfully updated user information!';

    return (
        <>
            <NotificationMessage
                show={showSuccess}
                toggleShow={toggleSuccess}
                variant={Variant.SUCCESS}
                message={requestSuccessText}
            />
            <NotificationMessage
                show={showError}
                toggleShow={toggleError}
                variant={Variant.DANGER}
                message={requestErrorText}
            />
            <Form className="text-left">
                <div className="row py-1">
                    <div className="col-md-4">
                        <Form.Label className="my-0 text-left">
                            Title
                        </Form.Label>
                        <TitleDropdown
                            title={title}
                            sendTitle={updateFormData}
                        />
                    </div>
                    <div className="col-md-8">
                        <Form.Group controlId="firstName">
                            <Form.Label className="my-0 text-left">
                                First name
                            </Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter first name"
                                value={firstName}
                                className="mb-3"
                                onChange={(e) =>
                                    updateFormData({
                                        firstName: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 py-1">
                        <Form.Group controlId="dob" className="mb-3">
                            <Form.Label className="my-0">DOB</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                name="dob"
                                value={dob}
                                onChange={(e) => setDOB(e)}
                                isInvalid={!isDOBValid}
                            />
                            <Form.Control.Feedback type="invalid">
                                Check patient is over 18 years old.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="col-md-8 py-1">
                        <Form.Group controlId="lastName">
                            <Form.Label className="my-0">Last name</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter last name"
                                value={lastName}
                                className="mb-3"
                                onChange={(e) =>
                                    updateFormData({lastName: e.target.value})
                                }
                            />
                        </Form.Group>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <ClinicianDescriptionField
                            clinicianDescription={clinicianDescription}
                            updateFormData={updateFormData}
                        />
                    </div>
                </div>
                <Row className="mt-4 mb-5">
                    <Col className="text-center">
                        <Button
                            className="sel-button w80"
                            variant="primary"
                            onClick={onSubmit}
                        >
                            Update user information
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default UserUpdateRequest;
