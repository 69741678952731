import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';

interface DelegateRegistrationSuccessPopupProps {
    showModal: boolean;
}

const DelegateRegistrationSuccessPopup: React.FC<
    DelegateRegistrationSuccessPopupProps
> = ({showModal}) => {
    const navigate = useNavigate();
    const closeModal = () => navigate('/login');

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv">
                    Successful registration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="medr-text-inv">
                    Your delegate user account has been registered and verified.
                    You will be taken to the login page after closing this
                    popup.
                </p>
            </Modal.Body>
        </Modal>
    );
};

export default DelegateRegistrationSuccessPopup;
