import {useSelector} from 'react-redux';
import {JobRole} from '@/generated-client';
import {RootState} from '@/reducers';

interface UseJobRolesReturn {
    jobRoles: JobRole[];
    getJobRoleById: (id: number) => JobRole | null;
}

const useJobRoles = (): UseJobRolesReturn => {
    const jobRoles = useSelector(
        (state: RootState) => state.clinicians.jobRoles
    );
    const getJobRoleById = (id: number): JobRole => {
        const jrs = jobRoles.filter((jobRole) => jobRole.id === id);
        if (jrs.length === 0) return null;
        return jrs[0];
    };

    return {
        jobRoles,
        getJobRoleById,
    };
};

export default useJobRoles;
