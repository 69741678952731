/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface UserLists
 */
export interface UserLists {
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof UserLists
     */
    users: Array<UserProfile>;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof UserLists
     */
    unverifiedUsers: Array<UserProfile>;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof UserLists
     */
    deactivatedUsers: Array<UserProfile>;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof UserLists
     */
    operationsUsers: Array<UserProfile>;
}

/**
 * Check if a given object implements the UserLists interface.
 */
export function instanceOfUserLists(value: object): value is UserLists {
    if (!('users' in value) || value['users'] === undefined) return false;
    if (!('unverifiedUsers' in value) || value['unverifiedUsers'] === undefined) return false;
    if (!('deactivatedUsers' in value) || value['deactivatedUsers'] === undefined) return false;
    if (!('operationsUsers' in value) || value['operationsUsers'] === undefined) return false;
    return true;
}

export function UserListsFromJSON(json: any): UserLists {
    return UserListsFromJSONTyped(json, false);
}

export function UserListsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLists {
    if (json == null) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(UserProfileFromJSON)),
        'unverifiedUsers': ((json['unverifiedUsers'] as Array<any>).map(UserProfileFromJSON)),
        'deactivatedUsers': ((json['deactivatedUsers'] as Array<any>).map(UserProfileFromJSON)),
        'operationsUsers': ((json['operationsUsers'] as Array<any>).map(UserProfileFromJSON)),
    };
}

export function UserListsToJSON(json: any): UserLists {
    return UserListsToJSONTyped(json, false);
}

export function UserListsToJSONTyped(value?: UserLists | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'users': ((value['users'] as Array<any>).map(UserProfileToJSON)),
        'unverifiedUsers': ((value['unverifiedUsers'] as Array<any>).map(UserProfileToJSON)),
        'deactivatedUsers': ((value['deactivatedUsers'] as Array<any>).map(UserProfileToJSON)),
        'operationsUsers': ((value['operationsUsers'] as Array<any>).map(UserProfileToJSON)),
    };
}

