import {useState, useEffect, useCallback} from 'react';
import {useAuthService, useDelegateService} from '@/actions';
import {EmailFieldChangeValues} from '@/components/app/parts/FormFields';

interface RegisterDelegateData {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    delegatingClinicianUserProfileId: number;
    permissionViewReferrals: boolean;
    permissionCreateReferrals: boolean;
    permissionManageReferrals: boolean;
}

interface FormChecks {
    title: boolean;
    firstName: boolean;
    lastName: boolean;
    email: boolean;
}

interface UseRegisterDelegateReturn {
    registerDelegateData: RegisterDelegateData;
    updateRegisterDelegateData: (data: Partial<RegisterDelegateData>) => void;
    updateRegisterEmail: (e: EmailFieldChangeValues) => void;
    formChecks: FormChecks;
    checkForm: () => boolean;
    onSubmit: (
        e:
            | React.MouseEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>
    ) => Promise<void>;
    showSuccessfulRegistration: boolean;
    dupEmail: boolean;
}

function createInitRegisterDelegateData(
    delegatingClinicianUserProfileId: number
): RegisterDelegateData {
    return {
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        delegatingClinicianUserProfileId,
        permissionViewReferrals: true,
        permissionCreateReferrals: true,
        permissionManageReferrals: true,
    };
}

function createInitFormChecks(): FormChecks {
    return {
        title: true,
        firstName: true,
        lastName: true,
        email: true,
    };
}

const useRegisterDelegate = (
    delegatingClinicianUserProfileId: number
): UseRegisterDelegateReturn => {
    const authService = useAuthService();
    const delegateService = useDelegateService();

    const [registerDelegateData, setRegisterDelegateData] =
        useState<RegisterDelegateData>(
            createInitRegisterDelegateData(delegatingClinicianUserProfileId)
        );
    const [formChecks, setFormChecks] = useState<FormChecks>(
        createInitFormChecks()
    );
    const [showSuccessfulRegistration, setShowSuccessfulRegistration] =
        useState<boolean>(false);
    const [dupEmail, setDupEmail] = useState<boolean>(false);

    const updateRegisterDelegateData = useCallback(
        (data: Partial<RegisterDelegateData>) => {
            setRegisterDelegateData((prevState) => ({...prevState, ...data}));
        },
        []
    );

    const updateRegisterEmail = useCallback(
        (e: EmailFieldChangeValues) => {
            updateRegisterDelegateData({email: e.email});
        },
        [updateRegisterDelegateData]
    );

    const handleEmailCheck = useCallback(async () => {
        if (registerDelegateData.email) {
            const emailValid = await authService.registerCheckEmail({
                email: registerDelegateData.email,
            });
            setDupEmail(!emailValid);
        }
    }, [registerDelegateData.email, authService]);

    useEffect(() => {
        handleEmailCheck();
    }, [handleEmailCheck]);

    const checkForm = useCallback(() => {
        const newFormChecks: FormChecks = {
            title: registerDelegateData.title.length > 0,
            firstName: registerDelegateData.firstName.length > 0,
            lastName: registerDelegateData.lastName.length > 0,
            email: registerDelegateData.email.length > 0 && !dupEmail,
        };

        setFormChecks(newFormChecks);

        return (
            newFormChecks.firstName &&
            newFormChecks.lastName &&
            newFormChecks.email
        );
    }, [registerDelegateData, dupEmail]);

    const onSubmit = useCallback(
        async (
            e:
                | React.MouseEvent<HTMLButtonElement>
                | React.FormEvent<HTMLFormElement>
        ) => {
            e.preventDefault();
            const ready = checkForm();
            if (!ready) return;
            const success =
                await delegateService.registerDelegateUser(
                    registerDelegateData
                );
            setShowSuccessfulRegistration(success);
        },
        [checkForm, delegateService, registerDelegateData]
    );

    return {
        registerDelegateData,
        updateRegisterDelegateData,
        updateRegisterEmail,
        formChecks,
        checkForm,
        onSubmit,
        showSuccessfulRegistration,
        dupEmail,
    };
};

export default useRegisterDelegate;
