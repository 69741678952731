/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckClinicAddressRequest,
  CheckClinicAddressResponse,
  CheckRegisteringEmailRequest,
  CheckRegisteringEmailResponse,
  ReceiveEmailVerificationResponse,
  RegisterUserRequest,
  RegisterUserResponse,
} from '../models/index';
import {
    CheckClinicAddressRequestFromJSON,
    CheckClinicAddressRequestToJSON,
    CheckClinicAddressResponseFromJSON,
    CheckClinicAddressResponseToJSON,
    CheckRegisteringEmailRequestFromJSON,
    CheckRegisteringEmailRequestToJSON,
    CheckRegisteringEmailResponseFromJSON,
    CheckRegisteringEmailResponseToJSON,
    ReceiveEmailVerificationResponseFromJSON,
    ReceiveEmailVerificationResponseToJSON,
    RegisterUserRequestFromJSON,
    RegisterUserRequestToJSON,
    RegisterUserResponseFromJSON,
    RegisterUserResponseToJSON,
} from '../models/index';

export interface RegisterCheckClinicCreateRequest {
    checkClinicAddressRequest: CheckClinicAddressRequest;
}

export interface RegisterCheckEmailCreateRequest {
    checkRegisteringEmailRequest: CheckRegisteringEmailRequest;
}

export interface RegisterCreateRequest {
    registerUserRequest: RegisterUserRequest;
}

export interface RegisterVerifyEmailUsertimecodeCreateRequest {
    code: string;
    time: string;
    user: string;
}

/**
 * 
 */
export class RegisterApi extends runtime.BaseAPI {

    /**
     * Checks for duplicate email in users.
     */
    async registerCheckClinicCreateRaw(requestParameters: RegisterCheckClinicCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckClinicAddressResponse>> {
        if (requestParameters['checkClinicAddressRequest'] == null) {
            throw new runtime.RequiredError(
                'checkClinicAddressRequest',
                'Required parameter "checkClinicAddressRequest" was null or undefined when calling registerCheckClinicCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/register/check-clinic/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckClinicAddressRequestToJSON(requestParameters['checkClinicAddressRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckClinicAddressResponseFromJSON(jsonValue));
    }

    /**
     * Checks for duplicate email in users.
     */
    async registerCheckClinicCreate(requestParameters: RegisterCheckClinicCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckClinicAddressResponse> {
        const response = await this.registerCheckClinicCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks for duplicate email in users.
     */
    async registerCheckEmailCreateRaw(requestParameters: RegisterCheckEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckRegisteringEmailResponse>> {
        if (requestParameters['checkRegisteringEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'checkRegisteringEmailRequest',
                'Required parameter "checkRegisteringEmailRequest" was null or undefined when calling registerCheckEmailCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/register/check-email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckRegisteringEmailRequestToJSON(requestParameters['checkRegisteringEmailRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckRegisteringEmailResponseFromJSON(jsonValue));
    }

    /**
     * Checks for duplicate email in users.
     */
    async registerCheckEmailCreate(requestParameters: RegisterCheckEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckRegisteringEmailResponse> {
        const response = await this.registerCheckEmailCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registers user, creates user profile and main clinician profile.
     */
    async registerCreateRaw(requestParameters: RegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterUserResponse>> {
        if (requestParameters['registerUserRequest'] == null) {
            throw new runtime.RequiredError(
                'registerUserRequest',
                'Required parameter "registerUserRequest" was null or undefined when calling registerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/register/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserRequestToJSON(requestParameters['registerUserRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterUserResponseFromJSON(jsonValue));
    }

    /**
     * Registers user, creates user profile and main clinician profile.
     */
    async registerCreate(requestParameters: RegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterUserResponse> {
        const response = await this.registerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specified user to verified.
     */
    async registerVerifyEmailUsertimecodeCreateRaw(requestParameters: RegisterVerifyEmailUsertimecodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReceiveEmailVerificationResponse>> {
        if (requestParameters['code'] == null) {
            throw new runtime.RequiredError(
                'code',
                'Required parameter "code" was null or undefined when calling registerVerifyEmailUsertimecodeCreate().'
            );
        }

        if (requestParameters['time'] == null) {
            throw new runtime.RequiredError(
                'time',
                'Required parameter "time" was null or undefined when calling registerVerifyEmailUsertimecodeCreate().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling registerVerifyEmailUsertimecodeCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/register/verify-email/user={user}&time={time}&code={code}/`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters['code']))).replace(`{${"time"}}`, encodeURIComponent(String(requestParameters['time']))).replace(`{${"user"}}`, encodeURIComponent(String(requestParameters['user']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReceiveEmailVerificationResponseFromJSON(jsonValue));
    }

    /**
     * Updates the specified user to verified.
     */
    async registerVerifyEmailUsertimecodeCreate(requestParameters: RegisterVerifyEmailUsertimecodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReceiveEmailVerificationResponse> {
        const response = await this.registerVerifyEmailUsertimecodeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
