import React from 'react';
import {Form} from 'react-bootstrap';

interface PatientFilterInputProps {
    setFilterString: (str: string) => void;
}

const PatientFilterInput: React.FC<PatientFilterInputProps> = ({
    setFilterString,
}) => {
    const updateStrs = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setFilterString(e.target.value);
    };

    return (
        <Form className="text-left">
            <Form.Group controlId="pxSearchBar" className="mt-3 mb-3">
                <Form.Control
                    required
                    type="name"
                    placeholder="Search patient by name"
                    name="searchStr"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateStrs(e)
                    }
                />
            </Form.Group>
        </Form>
    );
};

export default PatientFilterInput;
