/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserNotificationsSettingsRequest
 */
export interface UpdateUserNotificationsSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserNotificationsSettingsRequest
     */
    userProfileId: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserNotificationsSettingsRequest
     */
    field: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserNotificationsSettingsRequest
     */
    value: boolean;
}

/**
 * Check if a given object implements the UpdateUserNotificationsSettingsRequest interface.
 */
export function instanceOfUpdateUserNotificationsSettingsRequest(value: object): value is UpdateUserNotificationsSettingsRequest {
    if (!('userProfileId' in value) || value['userProfileId'] === undefined) return false;
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function UpdateUserNotificationsSettingsRequestFromJSON(json: any): UpdateUserNotificationsSettingsRequest {
    return UpdateUserNotificationsSettingsRequestFromJSONTyped(json, false);
}

export function UpdateUserNotificationsSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserNotificationsSettingsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userProfileId': json['userProfileId'],
        'field': json['field'],
        'value': json['value'],
    };
}

export function UpdateUserNotificationsSettingsRequestToJSON(json: any): UpdateUserNotificationsSettingsRequest {
    return UpdateUserNotificationsSettingsRequestToJSONTyped(json, false);
}

export function UpdateUserNotificationsSettingsRequestToJSONTyped(value?: UpdateUserNotificationsSettingsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userProfileId': value['userProfileId'],
        'field': value['field'],
        'value': value['value'],
    };
}

