import {useState, useEffect} from 'react';
import {Patient} from '@/generated-client';

interface UsePatientsListProps {
    patientsList: Patient[];
    start: number;
    end: number;
    filter: string;
}

const usePatientsList = ({
    patientsList,
    start,
    end,
    filter = '',
}: UsePatientsListProps): [
    Patient[],
    (startInd: number, endInd: number) => void,
    number,
] => {
    const [patients, setPatients] = useState<Patient[]>([]);
    const [startSlice, setStartSlice] = useState<number>(start);
    const [endSlice, setEndSlice] = useState<number>(end);
    const patientsLength = patients.length;

    const updateSlice = (startInd: number, endInd: number) => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    const filterPatientList = (): Patient[] => {
        if (!filter || filter === '') return patientsList;
        return patientsList.filter((patient) =>
            `${patient.title} ${patient.firstName} ${patient.lastName}`
                .toLowerCase()
                .includes(filter.toLowerCase())
        );
    };

    const updatePatients = () => {
        if (!patientsList || patientsList.length === 0) {
            setPatients([]);
            return;
        }
        const filtPx = filterPatientList();
        const slice = filtPx.slice(startSlice, endSlice);
        setPatients(slice);
    };

    useEffect(() => {
        updatePatients();
    }, [patientsList, startSlice, endSlice, filter]);

    return [patients, updateSlice, patientsLength];
};

export default usePatientsList;
