/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckClinicAddressRequest
 */
export interface CheckClinicAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckClinicAddressRequest
     */
    firstLine?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckClinicAddressRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckClinicAddressRequest
     */
    postcode: string;
}

/**
 * Check if a given object implements the CheckClinicAddressRequest interface.
 */
export function instanceOfCheckClinicAddressRequest(value: object): value is CheckClinicAddressRequest {
    if (!('postcode' in value) || value['postcode'] === undefined) return false;
    return true;
}

export function CheckClinicAddressRequestFromJSON(json: any): CheckClinicAddressRequest {
    return CheckClinicAddressRequestFromJSONTyped(json, false);
}

export function CheckClinicAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckClinicAddressRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'firstLine': json['firstLine'] == null ? undefined : json['firstLine'],
        'city': json['city'] == null ? undefined : json['city'],
        'postcode': json['postcode'],
    };
}

export function CheckClinicAddressRequestToJSON(json: any): CheckClinicAddressRequest {
    return CheckClinicAddressRequestToJSONTyped(json, false);
}

export function CheckClinicAddressRequestToJSONTyped(value?: CheckClinicAddressRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'firstLine': value['firstLine'],
        'city': value['city'],
        'postcode': value['postcode'],
    };
}

