/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CliniciansLists } from './CliniciansLists';
import {
    CliniciansListsFromJSON,
    CliniciansListsFromJSONTyped,
    CliniciansListsToJSON,
    CliniciansListsToJSONTyped,
} from './CliniciansLists';

/**
 * 
 * @export
 * @interface CliniciansListForAdminResponse
 */
export interface CliniciansListForAdminResponse {
    /**
     * 
     * @type {CliniciansLists}
     * @memberof CliniciansListForAdminResponse
     */
    clinicians: CliniciansLists;
}

/**
 * Check if a given object implements the CliniciansListForAdminResponse interface.
 */
export function instanceOfCliniciansListForAdminResponse(value: object): value is CliniciansListForAdminResponse {
    if (!('clinicians' in value) || value['clinicians'] === undefined) return false;
    return true;
}

export function CliniciansListForAdminResponseFromJSON(json: any): CliniciansListForAdminResponse {
    return CliniciansListForAdminResponseFromJSONTyped(json, false);
}

export function CliniciansListForAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CliniciansListForAdminResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'clinicians': CliniciansListsFromJSON(json['clinicians']),
    };
}

export function CliniciansListForAdminResponseToJSON(json: any): CliniciansListForAdminResponse {
    return CliniciansListForAdminResponseToJSONTyped(json, false);
}

export function CliniciansListForAdminResponseToJSONTyped(value?: CliniciansListForAdminResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinicians': CliniciansListsToJSON(value['clinicians']),
    };
}

