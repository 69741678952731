import React from 'react';
import AdminReferralsTable from '@components/admin/AdminReferralsTable';

const AdminReferralsListScreen: React.FC = () => {
    return (
        <div>
            <h1>Referrals</h1>
            <AdminReferralsTable />
        </div>
    );
};

export default AdminReferralsListScreen;
