import React, {useState} from 'react';
import useUserPermissions from '@hooks/user/useUserPermissions';
import {useUserService, useReferralService} from '@/actions';
import {Referral} from '@/generated-client';
import {ReferralView, UseReferralBarReturn} from './interfaces';

const useReferralBar = (referral: Referral): UseReferralBarReturn => {
    const userService = useUserService();
    const referralService = useReferralService();
    const setReferralView = (referral: Referral): ReferralView | null => {
        const ref =
            referral.displayType.charAt(0).toUpperCase() +
            referral.displayType.slice(1).toLowerCase();
        if (Object.values(ReferralView).includes(ref as ReferralView)) {
            return ReferralView[ref as keyof typeof ReferralView];
        }
        return null;
    };
    const selectedView = setReferralView(referral);

    const handleAcceptReferral = async (): Promise<boolean> => {
        return await referralService.acceptReferral(referral.id);
    };

    const handleRejectReferral = async (): Promise<boolean> => {
        return await referralService.rejectReferral(referral.id);
    };

    const handleCompleteReferral = async (
        crpId: number,
        toComplete: boolean
    ): Promise<boolean> => {
        return await referralService.toggleCompleteReferral(referral.id, {
            toComplete,
            crpId,
        });
    };

    const handleDnaReferral = async (
        crpId: number,
        toDna: boolean
    ): Promise<boolean> => {
        return await referralService.toggleDnaReferral(referral.id, {
            toDna,
            crpId,
        });
    };

    const handleCancelReferral = async (): Promise<boolean> => {
        return await referralService.cancelReferral(referral.id);
    };

    const {permissionManageReferrals} = useUserPermissions();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleClickOpen = () => setIsOpen((prev) => !prev);

    const {referrer, specifiedClinician, targetClinician} = referral;

    const referrerClinicianPageUrl = userService.urlPaths.clinicianGetUrl(
        referrer.userProfile.id
    );

    const targetClinicianPageUrl = specifiedClinician
        ? userService.urlPaths.clinicianGetUrl(targetClinician.userProfile.id)
        : '';

    const acceptFromOpenRef = async () => {
        const toggleSuccess = await handleAcceptReferral();
        if (!toggleSuccess) return;
        await userService.getReferralNotifications();
    };

    const acceptSpecificReferral = async () => {
        const toggleSuccess = await handleAcceptReferral();
        if (!toggleSuccess) return;
        await userService.getReferralNotifications();
    };

    const onClickCancelButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        handleCancelReferral();
    };

    const onClickAcceptButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
 
        if (specifiedClinician) {
            acceptSpecificReferral();
        } else {
            acceptFromOpenRef();
        }
    };

    const onClickRejectButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        handleRejectReferral();
    };

    const onClickCompleteButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        const crpId = targetClinician.id;
        handleCompleteReferral(crpId, true);
    };
    const onClickDnaButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const crpId = targetClinician.id;
        handleDnaReferral(crpId, true);
    };

    const onClickSetActiveButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        const crpId = targetClinician.id;
        if (referral.completed) {
            handleCompleteReferral(crpId, false);
            return;
        }
        handleDnaReferral(crpId, false);
    };

    const referralAccepted = referral.targetHasAccepted;
    const referralCompleted = referral.completed;

    return {
        selectedView,
        isOpen,
        handleClickOpen,
        permissionManageReferrals,
        referrerClinicianPageUrl,
        targetClinicianPageUrl,
        referralAccepted,
        referralCompleted,
        onClickCancelButton,
        onClickAcceptButton,
        onClickRejectButton,
        onClickCompleteButton,
        onClickDnaButton,
        onClickSetActiveButton,
    };
};

export default useReferralBar;
