import {useSelector} from 'react-redux';
import {useClinicianService, useUserService} from '@/actions';
import {InsuranceAgreement} from '@/generated-client';
import {RootState} from '@/reducers';

interface ProfileChangeInsuranceReturn {
    existingProviders: InsuranceAgreement[];
    updateExistingProviders: (providers: InsuranceAgreement[]) => void;
    updateNewProviders: (providers: InsuranceAgreement[]) => void;
}

const useProfileChangeInsurance = (): ProfileChangeInsuranceReturn => {
    const clinicalProfiles = useSelector(
        (state: RootState) => state.profile.clinicianProfiles
    );

    const clinicianService = useClinicianService();
    const userService = useUserService();

    const existingProviders = clinicalProfiles[0].insuranceAgreements;

    const updateExistingProviders = async (providers: InsuranceAgreement[]) => {
        if (providers === existingProviders) return;

        const iaSuccess =
            await clinicianService.updateClinicianInsuranceAgreements({
                existingAgreements: providers,
            });
        if (!iaSuccess) return;

        const loadSuccess = await userService.loadUserProfile();
        if (!loadSuccess) return;
    };

    const updateNewProviders = (providers: InsuranceAgreement[]) => {
        if (!providers || providers.length === 0) return;
        console.log('send request to update', providers);
    };
    return {
        existingProviders,
        updateExistingProviders,
        updateNewProviders,
    };
};

export default useProfileChangeInsurance;
