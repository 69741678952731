/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';

/**
 * 
 * @export
 * @interface JobRoleListResponse
 */
export interface JobRoleListResponse {
    /**
     * 
     * @type {Array<JobRole>}
     * @memberof JobRoleListResponse
     */
    jobRoles: Array<JobRole>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobRoleListResponse
     */
    clinicianTypes: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<{ [key: string]: any; }>; }}
     * @memberof JobRoleListResponse
     */
    specialties: { [key: string]: Array<{ [key: string]: any; }>; };
    /**
     * 
     * @type {Array<number>}
     * @memberof JobRoleListResponse
     */
    doctorIds: Array<number>;
}

/**
 * Check if a given object implements the JobRoleListResponse interface.
 */
export function instanceOfJobRoleListResponse(value: object): value is JobRoleListResponse {
    if (!('jobRoles' in value) || value['jobRoles'] === undefined) return false;
    if (!('clinicianTypes' in value) || value['clinicianTypes'] === undefined) return false;
    if (!('specialties' in value) || value['specialties'] === undefined) return false;
    if (!('doctorIds' in value) || value['doctorIds'] === undefined) return false;
    return true;
}

export function JobRoleListResponseFromJSON(json: any): JobRoleListResponse {
    return JobRoleListResponseFromJSONTyped(json, false);
}

export function JobRoleListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobRoleListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'jobRoles': ((json['jobRoles'] as Array<any>).map(JobRoleFromJSON)),
        'clinicianTypes': json['clinicianTypes'],
        'specialties': json['specialties'],
        'doctorIds': json['doctorIds'],
    };
}

export function JobRoleListResponseToJSON(json: any): JobRoleListResponse {
    return JobRoleListResponseToJSONTyped(json, false);
}

export function JobRoleListResponseToJSONTyped(value?: JobRoleListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'jobRoles': ((value['jobRoles'] as Array<any>).map(JobRoleToJSON)),
        'clinicianTypes': value['clinicianTypes'],
        'specialties': value['specialties'],
        'doctorIds': value['doctorIds'],
    };
}

