import React, {useCallback} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {getNameString} from '@/actions/general';
import {useClinicianService} from '@/actions';
import {UserProfile} from '@/generated-client';
export interface FavStarClinician {
    isFavourite: boolean;
    userProfile: UserProfile;
}
interface FavClinicianStarPopupProps {
    clinician: FavStarClinician;
    showPopup: boolean;
    handleClose: () => void;
    requestMade: () => void;
}

const FavClinicianStarPopup: React.FC<FavClinicianStarPopupProps> = ({
    clinician,
    showPopup,
    handleClose,
    requestMade,
}) => {
    const clinicianService = useClinicianService();

    const {isFavourite, userProfile} = clinician;
    const {id} = userProfile;

    const onClick = useCallback(async () => {
        try {
            if (isFavourite) {
                await clinicianService.removeClinicianFromFavourites(id);
            } else {
                await clinicianService.addClinicianToFavourites(id);
            }
            handleClose();
            setTimeout(() => {
                // TODO: understand why deley in add/remove favourites results in a deleay in calculating the new list of favourites
                requestMade();
            }, 1000);

            // Delaying the `requestMade` call might not be the best approach.
            // Consider refactoring to update the UI based on the successful
            // completion of `addClinicianToFavourites` or `removeClinicianFromFavourites`.
            // This eliminates the need for an arbitrary timeout.
            // requestMade();
        } catch (error) {
            console.error('Error updating favourites:', error);
            // Consider adding user-friendly error feedback here, such as
            // displaying an error message in the UI.
        }
    }, [clinicianService, id, isFavourite, handleClose, requestMade]);

    return (
        <Modal show={showPopup} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv">
                    {isFavourite ? 'Remove' : 'Add'}{' '}
                    {getNameString(userProfile)} {isFavourite ? 'from' : 'to'}{' '}
                    favourite clinicians?
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button
                    variant={isFavourite ? 'danger' : 'success'}
                    onClick={onClick}
                >
                    {isFavourite ? 'Remove' : 'Add'}
                </Button>
                <Button variant="info" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FavClinicianStarPopup;
