/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InsuranceAgreement } from './InsuranceAgreement';
import {
    InsuranceAgreementFromJSON,
    InsuranceAgreementFromJSONTyped,
    InsuranceAgreementToJSON,
    InsuranceAgreementToJSONTyped,
} from './InsuranceAgreement';

/**
 * 
 * @export
 * @interface ClinicianInsuranceUpdateRequest
 */
export interface ClinicianInsuranceUpdateRequest {
    /**
     * 
     * @type {Array<InsuranceAgreement>}
     * @memberof ClinicianInsuranceUpdateRequest
     */
    existingAgreements: Array<InsuranceAgreement>;
}

/**
 * Check if a given object implements the ClinicianInsuranceUpdateRequest interface.
 */
export function instanceOfClinicianInsuranceUpdateRequest(value: object): value is ClinicianInsuranceUpdateRequest {
    if (!('existingAgreements' in value) || value['existingAgreements'] === undefined) return false;
    return true;
}

export function ClinicianInsuranceUpdateRequestFromJSON(json: any): ClinicianInsuranceUpdateRequest {
    return ClinicianInsuranceUpdateRequestFromJSONTyped(json, false);
}

export function ClinicianInsuranceUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianInsuranceUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'existingAgreements': ((json['existingAgreements'] as Array<any>).map(InsuranceAgreementFromJSON)),
    };
}

export function ClinicianInsuranceUpdateRequestToJSON(json: any): ClinicianInsuranceUpdateRequest {
    return ClinicianInsuranceUpdateRequestToJSONTyped(json, false);
}

export function ClinicianInsuranceUpdateRequestToJSONTyped(value?: ClinicianInsuranceUpdateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'existingAgreements': ((value['existingAgreements'] as Array<any>).map(InsuranceAgreementToJSON)),
    };
}

