import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {getNameString} from '@/actions/general';
import UserAnalyticsSummary from '@/components/user/UserAnalyticsSummary';
import AppUpdatesPanel from '@components/app/AppUpdatesPanel';
import useDelegateUser from '@hooks/user/useDelegateUser';
import useUserPermissions from '@hooks/user/useUserPermissions';
import {useAuthService} from '@/actions';

const DashboardDelegate: React.FC = () => {
    const authService = useAuthService();

    const {userProfile, clinicianUserProfile} = useDelegateUser();
    const {permissionCreateReferrals} = useUserPermissions();

    const handleNavigationNewReferral = () => authService.goToMakeReferral();
    const handleNavigationViewReferrals = () =>
        authService.gotToReceivedReferrals();

    return (
        <>
            <Row className="medr-layout text-center">
                <Col>
                    <h4>
                        Welcome {userProfile && getNameString(userProfile)},
                        this is a delegate account for{' '}
                        {clinicianUserProfile &&
                            getNameString(clinicianUserProfile)}
                    </h4>
                </Col>
            </Row>
            <Row className="medr-layout text-center">
                <Col>
                    <Button
                        type="button"
                        onClick={handleNavigationNewReferral}
                        className="sel-button w100"
                        disabled={!permissionCreateReferrals}
                    >
                        Make referral
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="button"
                        onClick={handleNavigationViewReferrals}
                        className="sel-button w100"
                    >
                        View referrals
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={9}>
                    <UserAnalyticsSummary />
                </Col>
                <Col xs={12} md={3}>
                    <AppUpdatesPanel />
                </Col>
            </Row>
        </>
    );
};

export default DashboardDelegate;
