/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    AddressToJSONTyped,
} from './Address';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';
import type { PatientTitle } from './PatientTitle';
import {
    PatientTitleFromJSON,
    PatientTitleFromJSONTyped,
    PatientTitleToJSON,
    PatientTitleToJSONTyped,
} from './PatientTitle';
import type { InsuranceAgreement } from './InsuranceAgreement';
import {
    InsuranceAgreementFromJSON,
    InsuranceAgreementFromJSONTyped,
    InsuranceAgreementToJSON,
    InsuranceAgreementToJSONTyped,
} from './InsuranceAgreement';
import type { SexEnum } from './SexEnum';
import {
    SexEnumFromJSON,
    SexEnumFromJSONTyped,
    SexEnumToJSON,
    SexEnumToJSONTyped,
} from './SexEnum';

/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    readonly id: number;
    /**
     * 
     * @type {UserProfile}
     * @memberof Patient
     */
    createdBy: UserProfile;
    /**
     * 
     * @type {Address}
     * @memberof Patient
     */
    address: Address;
    /**
     * 
     * @type {InsuranceAgreement}
     * @memberof Patient
     */
    insurancePolicy: InsuranceAgreement;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    readonly currentAge: number;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    readonly displayType: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    readonly viewType: string;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    detailsInImage?: boolean;
    /**
     * 
     * @type {PatientTitle}
     * @memberof Patient
     */
    title?: PatientTitle;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    patientReference?: string;
    /**
     * 
     * @type {SexEnum}
     * @memberof Patient
     */
    sex?: SexEnum;
    /**
     * 
     * @type {Date}
     * @memberof Patient
     */
    dob?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    primaryEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    primaryPhoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    isSelfPayer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    detailsImage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    patientActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    consentGiven?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Patient
     */
    consentGivenOn?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Patient
     */
    readonly createdOn: Date;
}



/**
 * Check if a given object implements the Patient interface.
 */
export function instanceOfPatient(value: object): value is Patient {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('insurancePolicy' in value) || value['insurancePolicy'] === undefined) return false;
    if (!('currentAge' in value) || value['currentAge'] === undefined) return false;
    if (!('displayType' in value) || value['displayType'] === undefined) return false;
    if (!('viewType' in value) || value['viewType'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('createdOn' in value) || value['createdOn'] === undefined) return false;
    return true;
}

export function PatientFromJSON(json: any): Patient {
    return PatientFromJSONTyped(json, false);
}

export function PatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Patient {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdBy': UserProfileFromJSON(json['createdBy']),
        'address': AddressFromJSON(json['address']),
        'insurancePolicy': InsuranceAgreementFromJSON(json['insurancePolicy']),
        'currentAge': json['currentAge'],
        'displayType': json['displayType'],
        'viewType': json['viewType'],
        'detailsInImage': json['detailsInImage'] == null ? undefined : json['detailsInImage'],
        'title': json['title'] == null ? undefined : PatientTitleFromJSON(json['title']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'patientReference': json['patientReference'] == null ? undefined : json['patientReference'],
        'sex': json['sex'] == null ? undefined : SexEnumFromJSON(json['sex']),
        'dob': json['dob'] == null ? undefined : (new Date(json['dob'])),
        'primaryEmail': json['primaryEmail'] == null ? undefined : json['primaryEmail'],
        'primaryPhoneNumber': json['primaryPhoneNumber'] == null ? undefined : json['primaryPhoneNumber'],
        'isSelfPayer': json['isSelfPayer'] == null ? undefined : json['isSelfPayer'],
        'detailsImage': json['detailsImage'] == null ? undefined : json['detailsImage'],
        'patientActive': json['patientActive'] == null ? undefined : json['patientActive'],
        'consentGiven': json['consentGiven'] == null ? undefined : json['consentGiven'],
        'consentGivenOn': json['consentGivenOn'] == null ? undefined : (new Date(json['consentGivenOn'])),
        'createdOn': (new Date(json['createdOn'])),
    };
}

export function PatientToJSON(json: any): Patient {
    return PatientToJSONTyped(json, false);
}

export function PatientToJSONTyped(value?: Omit<Patient, 'id'|'currentAge'|'displayType'|'viewType'|'createdOn'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'createdBy': UserProfileToJSON(value['createdBy']),
        'address': AddressToJSON(value['address']),
        'insurancePolicy': InsuranceAgreementToJSON(value['insurancePolicy']),
        'detailsInImage': value['detailsInImage'],
        'title': PatientTitleToJSON(value['title']),
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'patientReference': value['patientReference'],
        'sex': SexEnumToJSON(value['sex']),
        'dob': value['dob'] == null ? undefined : ((value['dob'] as any).toISOString().substring(0,10)),
        'primaryEmail': value['primaryEmail'],
        'primaryPhoneNumber': value['primaryPhoneNumber'],
        'isSelfPayer': value['isSelfPayer'],
        'detailsImage': value['detailsImage'],
        'patientActive': value['patientActive'],
        'consentGiven': value['consentGiven'],
        'consentGivenOn': value['consentGivenOn'] == null ? undefined : ((value['consentGivenOn'] as any).toISOString()),
    };
}

