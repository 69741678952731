import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {useParams, useNavigate} from 'react-router-dom';
import ReferralStepPatient from '@/components/referrals/entry/ReferralStepPatient';
import ReferralStepInfo from '@/components/referrals/entry/ReferralStepInfo';
import ReferralStepSelectClinician from '@/components/referrals/entry/ReferralStepSelectClinician';
import ReferralStepReview from '@/components/referrals/entry/ReferralStepReview';
import {
    ReferralFormHandler,
    ReferralJobInput,
    ReferralInfoInput,
    Coordinates,
} from '@hooks/referrals/ReferralFormHandler';
import {
    ReferralEntryContext,
    useReferralEntryContext,
} from '@contexts/ReferralEntryContext';
import useUserClinicianProfiles from '@/hooks/clinicians/useUserClinicianProfiles';
import Loader from '@components/app/Loader';
import {PatientRequestReturnedType} from '@/actions/patientService';

const ReferralFormButtons: React.FC = () => {
    const {activeStep, handleNext, handlePrevious, patientSelected} =
        useReferralEntryContext();
    return (
        <div
            className="medr-layout text-center ref-info-page"
            style={{
                maxWidth: '700px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <Row className="mt-2">
                <Col>
                    {activeStep !== 0 && (
                        <Button
                            className="sel-button w80"
                            variant="dark"
                            onClick={handlePrevious}
                        >
                            Previous
                        </Button>
                    )}
                    {activeStep === 0 && (
                        <Button
                            className="sel-button w80"
                            variant="primary"
                            disabled={!patientSelected}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const loadingView = (
    <>
        <div className="gap mt-5" />
        <Loader text="Loading referral entry process..." />
    </>
);

export const ReferralEntryScreen: React.FC = () => {
    // initialise the referral form handler

    const maxSteps = 3;

    const navigate = useNavigate();
    const referralFormHandler = new ReferralFormHandler({maxSteps});

    const [activeStep, setActiveStep] = useState(0);
    const [referralData, setReferralData] = useState(
        referralFormHandler.initialiseData()
    );
    const [referralMade, setReferralMade] = useState(false);
    const [isInitialised, setIsInitialised] = useState(false);

    useEffect(() => {
        referralFormHandler.initialiseBlankReferral(
            setReferralData,
            setIsInitialised
        );
        return () => {};
    }, []);

    const handleNext = () => referralFormHandler.nextActiveStep(setActiveStep);
    const handlePrevious = () =>
        referralFormHandler.previousActiveStep(setActiveStep);

    // patient search and selection
    const clearPatient = () =>
        referralFormHandler.clearPatient(setReferralData);

    const updateSelectedPatient = async (pxId: number) =>
        await referralFormHandler.updateSelectedPatient(
            pxId,
            setReferralData,
            handleNext
        );
    const successfulPxForm = async (results: PatientRequestReturnedType) =>
        await referralFormHandler.successfulPxForm(
            results,
            setReferralData,
            handleNext
        );

    // referral info entry
    const updateRxInfo = (value: ReferralInfoInput) =>
        referralFormHandler.updateReferralInfo(value, setReferralData);

    // clinician selection
    const setJobRoleInfo = (value: ReferralJobInput) =>
        referralFormHandler.setJobRole(value, setReferralData);
    const setTargetClinicianOpen = () =>
        referralFormHandler.setOpenReferral(
            referralData,
            setReferralData,
            handleNext
        );
    const setTargetClinician = async (targetCrpId: number) =>
        await referralFormHandler.selectTargetClinician(
            targetCrpId,
            referralData,
            setReferralData,
            handleNext
        );
    const changeGPS = (coordinates: Coordinates) =>
        referralFormHandler.updateGPS(coordinates, setReferralData);

    const refreshClinicians = async () =>
        await referralFormHandler.refreshClinicians(referralData);

    useEffect(() => {
        if (activeStep === 2) {
            refreshClinicians();
        }
        return () => {};
    }, [activeStep, referralData]);

    // Form submission
    const validateReferralData = () =>
        referralFormHandler.validateReferralData(referralData);

    const {clinicianProfiles, validateClinicianProfiles} =
        useUserClinicianProfiles();
    const crp = clinicianProfiles[0];

    const handleCreateReferral = async () =>
        await referralFormHandler.submitReferral(
            crp.id,
            referralData,
            setReferralMade
        );
    const exitReferral = () => navigate('/dashboard');

    // context values

    const referralEntryContextValues = {
        referralData,
        activeStep,
        patientId: referralData.patientId,
        patientSelected: referralData.selectedPatient,
        patient: referralData.currentPatient,
        sendPxId: updateSelectedPatient,
        addNewPatientSuccess: successfulPxForm,
        clearPatient,
        patientHistory: referralData.patientHistory,
        mainDescription: referralData.mainDescription,
        updateRxInfo: updateRxInfo,
        handleNext,
        handlePrevious,
        jobRole: referralData.jobRole,
        targetClinician: referralData.targetClinician,
        specifiedClinician: referralData.specifiedClinician,
        setJobRole: setJobRoleInfo,
        setTargetClinicianOpen,
        setTargetClinician,
        refreshClinicians,
        changeGPS,
        insuranceProvider: referralData.currentPatient.insurancePolicy,
        validateClinicianProfiles,
        validateReferralData,
        onSubmit: handleCreateReferral,
        referralMade,
        exitReferral,
    };

    const referralSteps = [
        <ReferralStepPatient showCleanButton />,
        <ReferralStepInfo />,
        <ReferralStepSelectClinician />,
        <ReferralStepReview />,
    ];

    return (
        <ReferralEntryContext.Provider value={referralEntryContextValues}>
            {!isInitialised && loadingView}
            {isInitialised && referralSteps[activeStep]}
            {isInitialised && <ReferralFormButtons />}
        </ReferralEntryContext.Provider>
    );
};

export function ClinicianReferralEntryScreen() {
    // initialise the referral form handler

    const maxSteps = 2;

    const navigate = useNavigate();
    const referralFormHandler = new ReferralFormHandler({maxSteps});

    const [activeStep, setActiveStep] = useState(0);
    const [referralData, setReferralData] = useState(
        referralFormHandler.initialiseData()
    );
    const [referralMade, setReferralMade] = useState(false);

    const {clinicianId} = useParams();
    const clinicianIdNum = parseInt(clinicianId);
    const [isInitialised, setIsInitialised] = useState(false);

    useEffect(() => {
        const initialise = async () => {
            await referralFormHandler.initialiseToClinicianReferral(
                clinicianIdNum,
                referralData,
                setReferralData,
                setIsInitialised
            );
        };
        initialise();
        return () => {};
    }, []);

    const handleNext = () => referralFormHandler.nextActiveStep(setActiveStep);
    const handlePrevious = () =>
        referralFormHandler.previousActiveStep(setActiveStep);

    // patient search and selection
    const clearPatient = () =>
        referralFormHandler.clearPatient(setReferralData);

    const updateSelectedPatient = async (pxId: number) =>
        await referralFormHandler.updateSelectedPatient(
            pxId,
            setReferralData,
            handleNext
        );

    const successfulPxForm = async (results: PatientRequestReturnedType) => {
        await referralFormHandler.successfulPxForm(
            results,
            setReferralData,
            handleNext
        );
    };

    // referral info entry
    const updateRxInfo = (value: ReferralInfoInput) =>
        referralFormHandler.updateReferralInfo(value, setReferralData);

    // clinician selection
    const setJobRoleInfo = () => {};
    const setTargetClinicianOpen = () => {};
    const setTargetClinician = () => {};
    const changeGPS = () => {};
    const refreshClinicians = async () => {};

    // Form submission
    const validateReferralData = () =>
        referralFormHandler.validateReferralData(referralData);

    const {clinicianProfiles, validateClinicianProfiles} =
        useUserClinicianProfiles();
    const crp = clinicianProfiles[0];

    const handleCreateReferral = async () =>
        await referralFormHandler.submitReferral(
            crp.id,
            referralData,
            setReferralMade
        );
    const exitReferral = () => navigate('/dashboard');

    // context values
    const referralEntryContextValues = {
        referralData,
        activeStep,
        patientId: referralData.patientId,
        patientSelected: referralData.selectedPatient,
        patient: referralData.currentPatient,
        sendPxId: updateSelectedPatient,
        addNewPatientSuccess: successfulPxForm,
        clearPatient,
        patientHistory: referralData.patientHistory,
        mainDescription: referralData.mainDescription,
        updateRxInfo: updateRxInfo,
        handleNext,
        handlePrevious,
        jobRole: referralData.jobRole,
        targetClinician: referralData.targetClinician,
        specifiedClinician: referralData.specifiedClinician,
        setJobRole: setJobRoleInfo,
        setTargetClinicianOpen,
        setTargetClinician,
        refreshClinicians,
        changeGPS,
        insuranceProvider: referralData.currentPatient.insurancePolicy,
        validateClinicianProfiles,
        validateReferralData,
        onSubmit: handleCreateReferral,
        referralMade,
        exitReferral,
    };

    const referralSteps = [
        <ReferralStepPatient showCleanButton />,
        <ReferralStepInfo />,
        <ReferralStepReview />,
    ];

    return (
        <ReferralEntryContext.Provider value={referralEntryContextValues}>
            {!isInitialised && loadingView}
            {isInitialised && referralSteps[activeStep]}
            {isInitialised && <ReferralFormButtons />}
        </ReferralEntryContext.Provider>
    );
}

export function DirectReferralEntryScreen() {
    // initialise the referral form handler

    const maxSteps = 3;

    const navigate = useNavigate();
    const referralFormHandler = new ReferralFormHandler({maxSteps});

    const [activeStep, setActiveStep] = useState(1);
    const [referralData, setReferralData] = useState(
        referralFormHandler.initialiseData()
    );
    const [referralMade, setReferralMade] = useState(false);

    const {patientId} = useParams();
    const patientIdNum = parseInt(patientId);
    const [isInitialised, setIsInitialised] = useState(false);

    useEffect(() => {
        const initialise = async () => {
            await referralFormHandler.initialiseToPatientReferral(
                patientIdNum,
                setReferralData,
                setIsInitialised
            );
        };
        initialise();
        return () => {};
    }, []);

    const handleNext = () => referralFormHandler.nextActiveStep(setActiveStep);
    const handlePrevious = () =>
        referralFormHandler.previousActiveStep(setActiveStep);

    // patient search and selection
    const updateSelectedPatient = async (pxId: number) =>
        await referralFormHandler.updateSelectedPatient(
            pxId,
            setReferralData,
            handleNext
        );
    const successfulPxForm = async (results: PatientRequestReturnedType) =>
        await referralFormHandler.successfulPxForm(
            results,
            setReferralData,
            handleNext
        );

    // referral info entry
    const updateRxInfo = (value: ReferralInfoInput) =>
        referralFormHandler.updateReferralInfo(value, setReferralData);

    // clinician selection
    const setJobRoleInfo = (value: ReferralJobInput) =>
        referralFormHandler.setJobRole(value, setReferralData);
    const setTargetClinicianOpen = () =>
        referralFormHandler.setOpenReferral(
            referralData,
            setReferralData,
            handleNext
        );
    const setTargetClinician = async (targetCrpId: number) =>
        await referralFormHandler.selectTargetClinician(
            targetCrpId,
            referralData,
            setReferralData,
            handleNext
        );
    const changeGPS = (coordinates: Coordinates) =>
        referralFormHandler.updateGPS(coordinates, setReferralData);
    const refreshClinicians = async () =>
        await referralFormHandler.refreshClinicians(referralData);

    useEffect(() => {
        if (activeStep === 2) {
            refreshClinicians();
        }
        return () => {};
    }, [activeStep, referralData]);

    // Form submission
    const validateReferralData = () =>
        referralFormHandler.validateReferralData(referralData);

    const {clinicianProfiles, validateClinicianProfiles} =
        useUserClinicianProfiles();
    const crp = clinicianProfiles[0];

    const handleCreateReferral = async () =>
        await referralFormHandler.submitReferral(
            crp.id,
            referralData,
            setReferralMade
        );
    const exitReferral = () => navigate('/dashboard');

    // context values

    const referralEntryContextValues = {
        referralData,
        activeStep,
        patientId: referralData.patientId,
        patientSelected: referralData.selectedPatient,
        patient: referralData.currentPatient,
        sendPxId: updateSelectedPatient,
        addNewPatientSuccess: successfulPxForm,
        clearPatient: () => {},
        patientHistory: referralData.patientHistory,
        mainDescription: referralData.mainDescription,
        updateRxInfo: updateRxInfo,
        handleNext,
        handlePrevious,
        jobRole: referralData.jobRole,
        targetClinician: referralData.targetClinician,
        specifiedClinician: referralData.specifiedClinician,
        setJobRole: setJobRoleInfo,
        setTargetClinicianOpen,
        setTargetClinician,
        refreshClinicians,
        changeGPS,
        insuranceProvider: referralData.currentPatient.insurancePolicy,
        validateClinicianProfiles,
        validateReferralData,
        onSubmit: handleCreateReferral,
        referralMade,
        exitReferral,
    };

    const referralSteps = [
        <ReferralStepPatient showCleanButton={false} />,
        <ReferralStepInfo />,
        <ReferralStepSelectClinician />,
        <ReferralStepReview />,
    ];

    return (
        <ReferralEntryContext.Provider value={referralEntryContextValues}>
            {!isInitialised && loadingView}
            {isInitialised && referralSteps[activeStep]}
            {isInitialised && <ReferralFormButtons />}
        </ReferralEntryContext.Provider>
    );
}
