import React, {useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import RegisterPersonalDetailsForm from '@/components/user/Registration/RegisterUserDetailsForm';
import RegisterClinicianDetialsForm from '@/components/user/Registration/RegisterClinicianDetailsForm';
import RegisterInsuranceDetails from '@/components/user/Registration/RegisterInsuranceDetails';
import RegisterReview from '@/components/user/Registration/RegisterReview';
import useUserRegistration from '@/hooks/user/useUserRegistration';
import {RegistrationContext} from '@contexts/RegistrationContext';

const SignupScreen: React.FC = () => {
    const registrationContextValues = useUserRegistration();
    const {activeStep, setPreviousStep} = registrationContextValues;

    // Define the steps for the registration process
    const registrationSteps = [
        <RegisterPersonalDetailsForm key="personal-details" />,
        <RegisterClinicianDetialsForm key="clinician-details" />,
        <RegisterInsuranceDetails key="insurance-details" />,
        <RegisterReview key="review" />,
    ];

    useEffect(() => {
        console.log(registrationContextValues.registrationData);
    }, [registrationContextValues.registrationData]);

    return (
        <RegistrationContext.Provider value={registrationContextValues}>
            <div
                className="medr-layout text-center register-page"
                style={{
                    maxWidth: '90%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <h1>Registration</h1>
                <Row>
                    <Col className="py-0">{registrationSteps[activeStep]}</Col>
                </Row>
                {activeStep !== 0 && (
                    <Row>
                        <Col className="text-center">
                            <Button
                                className="sel-button w80"
                                variant="dark"
                                onClick={setPreviousStep}
                            >
                                Previous
                            </Button>
                        </Col>
                    </Row>
                )}
            </div>
        </RegistrationContext.Provider>
    );
};

export default SignupScreen;
