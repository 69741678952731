import {Reducer} from 'react';
import * as CONSTS from '@constants/patientConstants';
import {
    createDefaultPatient,
    createDefaultGpsFromPostcodeResponse,
} from '@constants/initDataConstants';
import {LOGOUT_ACTION} from '@constants/authConstants';

import * as ITF from '@reducers/patientReducer/interfaces';
import {PatientState} from './interfaces';
import {LogoutSuccessAction} from '../authReducer';

const initialState: PatientState = {
    loading: false,
    gpsCoords: createDefaultGpsFromPostcodeResponse(),
    currentPatient: createDefaultPatient(),
    currentPatientReferrals: {
        activeReferrals: [],
        inactiveReferrals: [],
    },
    addedPatients: [],
    referredPatients: [],
    pendingPatients: [],
    acceptedPatients: [],
    completedPatients: [],
};

// INCOMING REQUESTS
function isRequestAction(
    action: ITF.PatientActionTypes
): action is ITF.PatientActionRequests {
    return [
        CONSTS.CURRENT_PATIENT_ACTION.REQUEST,
        CONSTS.CREATE_PATIENT_ACTION.REQUEST,
        CONSTS.CLINICIAN_PATIENTS_ACTION.REQUEST,
        CONSTS.PATIENT_REFERRALS_ACTION.REQUEST,
        CONSTS.PATIENT_DELETE_ACTION.REQUEST,
        CONSTS.GPS_COORDS_ACTION.REQUEST,
    ].includes(action.type);
}

function handleRequestAction(state: PatientState): PatientState {
    return {
        ...state,
        loading: true,
    };
}

// INCOMING FAILURES
function isPxResetAction(
    action: ITF.PatientActionTypes
): action is ITF.PatientResetAction {
    return [
        CONSTS.CURRENT_PATIENT_ACTION.RESET,
        CONSTS.CURRENT_PATIENT_ACTION.FAIL,
        CONSTS.GPS_COORDS_ACTION.FAIL,
    ].includes(action.type);
}

function handlePxResetAction(state: PatientState): PatientState {
    return {
        ...state,
        loading: false,
        currentPatient: createDefaultPatient(),
    };
}

function isPxFailErrorAction(
    action: ITF.PatientActionTypes
): action is ITF.PatientFailErrorAction {
    return [
        CONSTS.CURRENT_PATIENT_ACTION.FAIL,
        CONSTS.CURRENT_PATIENT_ACTION.RESET,
        CONSTS.CLINICIAN_PATIENTS_ACTION.FAIL,
        CONSTS.PATIENT_REFERRALS_ACTION.FAIL,
        CONSTS.CREATE_PATIENT_ACTION.FAIL,
        CONSTS.PATIENT_DELETE_ACTION.FAIL,
    ].includes(action.type);
}

function handlePxFailErrorAction(
    state: PatientState,
    action: ITF.PatientFailErrorAction
): PatientState {
    return {
        ...state,
        error: action.payload,
        loading: false,
    };
}

// INCOMING SUCCESS ACTIONS
function isClincianPatientsSuccessAction(
    action: ITF.PatientActionTypes
): action is ITF.ClinicianPatientsSuccessAction {
    return action.type === CONSTS.CLINICIAN_PATIENTS_ACTION.SUCCESS;
}

function handleClinicianPatientsSuccessAction(
    state: PatientState,
    action: ITF.ClinicianPatientsSuccessAction
): PatientState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isPatientDeleteSuccessAction(
    action: ITF.PatientActionTypes
): action is ITF.PatientDeleteSuccessAction {
    return action.type === CONSTS.PATIENT_DELETE_ACTION.SUCCESS;
}

function handlePatientDeleteSuccessAction(
    state: PatientState,
    action: ITF.PatientDeleteSuccessAction
): PatientState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isCurrentPatientSuccessAction(
    action: ITF.PatientActionTypes
): action is ITF.CurrentPatientSuccessAction {
    return action.type === CONSTS.CURRENT_PATIENT_ACTION.SUCCESS;
}

function handleCurrentPatientSuccessAction(
    state: PatientState,
    action: ITF.CurrentPatientSuccessAction
): PatientState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isCreatePatientSuccessAction(
    action: ITF.PatientActionTypes
): action is ITF.CreatePatientSuccessAction {
    return action.type === CONSTS.CREATE_PATIENT_ACTION.SUCCESS;
}

function handleCreatePatientSuccessAction(
    state: PatientState,
    action: ITF.CreatePatientSuccessAction
): PatientState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isPatientReferralsSuccessAction(
    action: ITF.PatientActionTypes
): action is ITF.PatientReferralsSuccessAction {
    return action.type === CONSTS.PATIENT_REFERRALS_ACTION.SUCCESS;
}

function handlePatientReferralsSuccessAction(
    state: PatientState,
    action: ITF.PatientReferralsSuccessAction
): PatientState {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
}

function isGpsCoordsSuccessAction(
    action: ITF.PatientActionTypes
): action is ITF.GpsCoordsSuccessAction {
    return action.type === CONSTS.GPS_COORDS_ACTION.SUCCESS;
}

function handleGpsCoordsSuccessAction(
    state: PatientState,
    action: ITF.GpsCoordsSuccessAction
): PatientState {
    return {
        ...state,
        loading: false,
        // currentPatient: action.payload, // TODO: check if this is correct
        gpsCoords: action.payload, // TODO: check if this is correct
    };
}

function isLogoutSuccessAction(
    action: ITF.PatientActionTypes
): action is LogoutSuccessAction {
    return action.type === LOGOUT_ACTION.SUCCESS;
}
function handleLogoutSuccessAction(): PatientState {
    return {...initialState};
}

export const patientReducer: Reducer<PatientState, ITF.PatientActionTypes> = (
    state = initialState,
    action
) => {
    // INCOMING REQUEST ACTIONS
    if (isRequestAction(action)) {
        return handleRequestAction(state);
    }

    // INCOMING SUCCESS ACTIONS
    if (isClincianPatientsSuccessAction(action)) {
        return handleClinicianPatientsSuccessAction(state, action);
    }
    if (isPatientDeleteSuccessAction(action)) {
        return handlePatientDeleteSuccessAction(state, action);
    }
    if (isCurrentPatientSuccessAction(action)) {
        return handleCurrentPatientSuccessAction(state, action);
    }
    if (isCreatePatientSuccessAction(action)) {
        return handleCreatePatientSuccessAction(state, action);
    }
    if (isPatientReferralsSuccessAction(action)) {
        return handlePatientReferralsSuccessAction(state, action);
    }
    if (isGpsCoordsSuccessAction(action)) {
        return handleGpsCoordsSuccessAction(state, action);
    }

    if (isLogoutSuccessAction(action)) {
        return handleLogoutSuccessAction();
    }

    // INCOMING RESET ACTIONS
    if (isPxResetAction(action)) {
        return handlePxResetAction(state);
    }
    // INCOMING FAIL ACTIONS
    if (isPxFailErrorAction(action)) {
        return handlePxFailErrorAction(state, action);
    }
    return state;
};
