import React from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useAuthService} from '@/actions';
import {RootState} from '@/reducers';

const AdminRoutes: React.FC = () => {
    const authService = useAuthService();

    if (!authService.hasAuthToken()) {
        return <Navigate to="/login" />;
    }

    const userGroup = useSelector((state: RootState) => state.auth.userGroup);
    const isAdmin = ['Staff', 'Full Admin'].includes(userGroup);

    if (!isAdmin) {
        console.log('Not an admin');
        return <Navigate to="/dashboard" />;
    }

    return <Outlet />;
};

export default AdminRoutes;
