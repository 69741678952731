import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import CheckAgeOver18FromDob from '@services/AgeCheck';

interface DobFormFieldProps {
    dob: string;
    dobKey: string;
    changeValues: (values: {[key: string]: string}) => void;
}

const DobFormField: React.FC<DobFormFieldProps> = ({
    dob,
    dobKey,
    changeValues,
}) => {
    const [isDobValid, setIsDobValid] = useState<boolean>(true);
    const [under18, setUnder18] = useState<boolean>(false);

    const setDob = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dobValue = e.target.value;
        const dobPattern = /^\d{4}-\d{2}-\d{2}$/;
        const isPatternValid = dobPattern.test(dobValue);
        const {isValid} = CheckAgeOver18FromDob(e.target.value);
        setUnder18(!isValid);
        setIsDobValid(isPatternValid);

        if (isValid && isPatternValid) {
            changeValues({[dobKey]: dobValue});
        }
    };

    return (
        <Form.Group controlId="dob" className="mt-2">
            <Form.Label className="my-0">Date of birth</Form.Label>
            <Form.Control
                required
                type="date"
                placeholder="Enter Date of Birth"
                defaultValue={dob}
                onChange={setDob}
                isInvalid={!isDobValid || under18}
            />
            <Form.Control.Feedback type="invalid">
                {!isDobValid
                    ? 'Please enter a date of birth.'
                    : 'Users must be over 18 years old.'}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default DobFormField;
