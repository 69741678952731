import {useEffect, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';
import {JobRole} from '@/generated-client';

enum UserTypes {
    Staff = 'Staff',
    Delegate = 'Delegate',
    Patient = 'Patient',
    Clinician = 'Clinician',
}

interface UseUserJobRole {
    jobRoles: JobRole[];
    userType: UserTypes | null;
}

const useUserJobRole = (): UseUserJobRole => {
    const jobRoles = useSelector((state: RootState) => state.auth.user.jobRole);
    const [userType, setUserType] = useState<UserTypes | null>(null);

    const checkUserType = useMemo(() => {
        if (!jobRoles || jobRoles.length === 0) return null;

        if (jobRoles.some((jr) => jr.jobRole === UserTypes.Staff)) {
            return UserTypes.Staff;
        }

        if (jobRoles.some((jr) => jr.jobRole === UserTypes.Delegate)) {
            return UserTypes.Delegate;
        }

        if (jobRoles.some((jr) => jr.jobRole === UserTypes.Patient)) {
            return UserTypes.Patient;
        }

        return UserTypes.Clinician;
    }, [jobRoles]);

    useEffect(() => {
        setUserType(checkUserType);
    }, [checkUserType]);

    return {
        jobRoles,
        userType,
    };
};

export default useUserJobRole;
