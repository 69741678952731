import React from 'react';
import {Button, Modal, Card} from 'react-bootstrap';
import {getNameString} from '@/actions/general';
import useDelegateUserCard from './useDelegateUserCard';
import {useDelegateService} from '@/actions';
import {DelegateUserLink} from '@/generated-client';

interface DeactivatedDelegateUserCardProps {
    delegate: DelegateUserLink;
}

export const DeactivatedDelegateUserCard: React.FC<
    DeactivatedDelegateUserCardProps
> = ({delegate}) => {
    if (!delegate) return;

    const delegateService = useDelegateService();

    const handleClick = async () => {
        const success = await delegateService.restoreDelegateUser(delegate);
        if (!success) return;
    };

    return (
        <Card
            className="mb-2 rounded d-flex flex-fill"
            style={{padding: '0.5rem'}}
        >
            <div className="d-flex justify-content-between">
                <div className="text-start">
                    <Card.Title
                        style={{
                            marginBottom: '0rem',
                            marginTop: '0rem',
                            textAlign: 'left',
                        }}
                    >
                        {getNameString(delegate.delegateUserProfile)}
                    </Card.Title>
                    <Card.Subtitle
                        className="text-muted"
                        style={{
                            marginTop: '0rem',
                            marginBottom: '0rem',
                            textAlign: 'left',
                        }}
                    >
                        {delegate.delegateUserProfile.user.email}
                    </Card.Subtitle>
                </div>
                <Button
                    style={{
                        borderRadius: '20px',
                        padding: '10px 10px',
                        fontSize: '0.7rem',
                    }}
                    variant="info"
                    disabled={!delegate.hasRegistered}
                    onClick={handleClick}
                >
                    {delegate.hasRegistered
                        ? 'Restore user'
                        : 'Awaiting user registration'}
                </Button>
            </div>
        </Card>
    );
};

interface PermissionToggleBarProps {
    field: string;
    label: string;
    value?: boolean;
    onChange: (newValue: {[key: string]: boolean}) => void;
}

export const PermissionToggleBar: React.FC<PermissionToggleBarProps> = ({
    field,
    label,
    value = false,
    onChange,
}) => {
    const sendNewValue = (newValue: boolean) => onChange({[field]: newValue});
    return (
        <div className="notif-setting-bar">
            <div className="notif-setting-left">
                <Button
                    variant={value ? 'success' : 'outline-success'}
                    className="medr-text notif-button"
                    onClick={() => sendNewValue(true)}
                >
                    Enabled
                </Button>
                <Button
                    variant={!value ? 'danger' : 'outline-danger'}
                    className="medr-text notif-button"
                    onClick={() => sendNewValue(false)}
                >
                    Disabled
                </Button>
            </div>
            <div className="notif-setting-right medr-text medr-small">
                {label}
            </div>
        </div>
    );
};

interface DeactivateDelegateModalProps {
    showModal?: boolean;
    handleCloseModal?: () => void;
    deactivateDelegate?: () => void;
}

const DeactivateDelegateModal: React.FC<DeactivateDelegateModalProps> = ({
    showModal = false,
    handleCloseModal = () => {},
    deactivateDelegate = () => {},
}) => {
    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-text-inv">
                    Confirm Deactivation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to deactivate this delegate account?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button
                    variant="danger"
                    onClick={() => {
                        deactivateDelegate();
                    }}
                >
                    Deactivate delegate
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

interface DelegateUserCardProps {
    delegate: DelegateUserLink;
}

const DelegateUserCard: React.FC<DelegateUserCardProps> = ({delegate}) => {
    const {
        deactivateDelegate,
        updatePermission,
        showDectivateModal,
        toggleShowDeactivate,
    } = useDelegateUserCard(delegate);

    return (
        <>
            <DeactivateDelegateModal
                showModal={showDectivateModal}
                handleCloseModal={toggleShowDeactivate}
                deactivateDelegate={deactivateDelegate}
            />
            <Card
                className="mb-2 rounded d-flex flex-fill"
                style={{padding: '0.5rem'}}
            >
                <div className="d-flex justify-content-between">
                    <div className="text-start">
                        <Card.Title
                            style={{
                                marginBottom: '0rem',
                                marginTop: '0rem',
                                textAlign: 'left',
                            }}
                        >
                            {getNameString(delegate.delegateUserProfile)}
                        </Card.Title>
                        <Card.Subtitle
                            className="text-muted"
                            style={{
                                marginTop: '0rem',
                                marginBottom: '0rem',
                                textAlign: 'left',
                            }}
                        >
                            {delegate.delegateUserProfile.user.email}
                        </Card.Subtitle>
                    </div>
                    <Button
                        style={{
                            borderRadius: '20px',
                            padding: '10px 10px',
                            fontSize: '0.7rem',
                        }}
                        variant="danger"
                        onClick={toggleShowDeactivate}
                    >
                        Deactive delegate account
                    </Button>
                </div>
                <PermissionToggleBar
                    field="permissionViewReferrals"
                    label="Able to view referrals"
                    value={delegate.permissionViewReferrals}
                    onChange={updatePermission}
                />
                <PermissionToggleBar
                    field="permisisonCreateReferrals"
                    label="Able to create and send referrals"
                    value={delegate.permissionCreateReferrals}
                    onChange={updatePermission}
                />
                <PermissionToggleBar
                    field="permisisonManageReferrals"
                    label="Able to manage referrals (accept and reject)"
                    value={delegate.permissionManageReferrals}
                    onChange={updatePermission}
                />
            </Card>
        </>
    );
};

export default DelegateUserCard;
