/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressInputForm
 */
export interface AddressInputForm {
    /**
     * 
     * @type {string}
     * @memberof AddressInputForm
     */
    firstLine: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInputForm
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInputForm
     */
    postcode: string;
}

/**
 * Check if a given object implements the AddressInputForm interface.
 */
export function instanceOfAddressInputForm(value: object): value is AddressInputForm {
    if (!('firstLine' in value) || value['firstLine'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('postcode' in value) || value['postcode'] === undefined) return false;
    return true;
}

export function AddressInputFormFromJSON(json: any): AddressInputForm {
    return AddressInputFormFromJSONTyped(json, false);
}

export function AddressInputFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressInputForm {
    if (json == null) {
        return json;
    }
    return {
        
        'firstLine': json['firstLine'],
        'city': json['city'],
        'postcode': json['postcode'],
    };
}

export function AddressInputFormToJSON(json: any): AddressInputForm {
    return AddressInputFormToJSONTyped(json, false);
}

export function AddressInputFormToJSONTyped(value?: AddressInputForm | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'firstLine': value['firstLine'],
        'city': value['city'],
        'postcode': value['postcode'],
    };
}

