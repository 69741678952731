import {createActionTypes, ActionStatus} from './requestConstant';

export const APP_VERISON_ACTION: ActionStatus =
    createActionTypes('APP_VERISON');
export const SEND_ERROR_NOTIFICATION_ACTION: ActionStatus = createActionTypes(
    'SEND_ERROR_NOTIFICATION'
);
export const REGISTER_ACTION: ActionStatus = createActionTypes('REGISTER');
export const REGISTER_DELEGATE_ACTION: ActionStatus =
    createActionTypes('REGISTER_DELEGATE');
export const REGISTER_DELEGATE_INFO_ACTION: ActionStatus = createActionTypes(
    'REGISTER_DELEGATE_INFO'
);

export const VERIFY_DELEGATE_ACTION: ActionStatus =
    createActionTypes('VERIFY_DELEGATE');

export const USER_DELEGATES_ACTION: ActionStatus =
    createActionTypes('USER_DELEGATES');

export const UPDATE_DELEGATE_PERMISSION_ACTION: ActionStatus =
    createActionTypes('UPDATE_DELEGATE_PERMISSION');
export const DEACTIVATE_DELEGATE_ACTION: ActionStatus = createActionTypes(
    'DEACTIVATE_DELEGATE'
);
export const RESTORE_DELEGATE_ACTION: ActionStatus =
    createActionTypes('RESTORE_DELEGATE');
export const REGISTER_CHECK_EMAIL_ACTION: ActionStatus = createActionTypes(
    'REGISTER_CHECK_EMAIL'
);
export const REGISTER_CHECK_CLINIC_ACTION: ActionStatus = createActionTypes(
    'REGISTER_CHECK_CLINIC'
);

export const VERIFY_EMAIL_ACTION: ActionStatus =
    createActionTypes('VERIFY_EMAIL');

export const LOGIN_ACTION: ActionStatus = createActionTypes('LOGIN');
export const CHECK_AUTH_ACTION: ActionStatus = createActionTypes('CHECK_AUTH');
export const LOGOUT_ACTION: ActionStatus = createActionTypes('LOGOUT');
export const RESET_PASSWORD_ACTION: ActionStatus =
    createActionTypes('RESET_PASSWORD');
export const RESET_PASSWORD_SET_NEW_ACTION: ActionStatus = createActionTypes(
    'RESET_PASSWORD_SET_NEW'
);

export const DEACTIVATE_FULL_ACCOUNT_ACTION: ActionStatus = createActionTypes(
    'DEACTIVATE_FULL_ACCOUNT'
);

// export const AUTH_FROM_STORAGE: string = 'AUTH_FROM_STORAGE';
