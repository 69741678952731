/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckClinicAddressResponse
 */
export interface CheckClinicAddressResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckClinicAddressResponse
     */
    addressValid: boolean;
}

/**
 * Check if a given object implements the CheckClinicAddressResponse interface.
 */
export function instanceOfCheckClinicAddressResponse(value: object): value is CheckClinicAddressResponse {
    if (!('addressValid' in value) || value['addressValid'] === undefined) return false;
    return true;
}

export function CheckClinicAddressResponseFromJSON(json: any): CheckClinicAddressResponse {
    return CheckClinicAddressResponseFromJSONTyped(json, false);
}

export function CheckClinicAddressResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckClinicAddressResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'addressValid': json['addressValid'],
    };
}

export function CheckClinicAddressResponseToJSON(json: any): CheckClinicAddressResponse {
    return CheckClinicAddressResponseToJSONTyped(json, false);
}

export function CheckClinicAddressResponseToJSONTyped(value?: CheckClinicAddressResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'addressValid': value['addressValid'],
    };
}

