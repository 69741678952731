import {TitleEnum} from '@/generated-client';
import {StylesConfig} from 'react-select';
import {ViewOption, customStyles} from '@/constants/styles';

interface UseTitleDropdownReturn {
    titleOptions: ViewOption[];
    customStyles: StylesConfig<ViewOption, false>;
    currentTitle: string;
}

const useTitleDropdown = (title?: string): UseTitleDropdownReturn => {
    const titles = Object.values(TitleEnum);
    const titleOptions: ViewOption[] = titles.map((title_) => ({
        label: title_,
        value: title_,
    }));
    const currentTitle = title || 'Select title';
    return {
        titleOptions,
        customStyles,
        currentTitle,
    };
};

export default useTitleDropdown;
