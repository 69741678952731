/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralToggleBookedRequest
 */
export interface ReferralToggleBookedRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ReferralToggleBookedRequest
     */
    toComplete: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralToggleBookedRequest
     */
    crpId: number;
}

/**
 * Check if a given object implements the ReferralToggleBookedRequest interface.
 */
export function instanceOfReferralToggleBookedRequest(value: object): value is ReferralToggleBookedRequest {
    if (!('toComplete' in value) || value['toComplete'] === undefined) return false;
    if (!('crpId' in value) || value['crpId'] === undefined) return false;
    return true;
}

export function ReferralToggleBookedRequestFromJSON(json: any): ReferralToggleBookedRequest {
    return ReferralToggleBookedRequestFromJSONTyped(json, false);
}

export function ReferralToggleBookedRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralToggleBookedRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'toComplete': json['toComplete'],
        'crpId': json['crpId'],
    };
}

export function ReferralToggleBookedRequestToJSON(json: any): ReferralToggleBookedRequest {
    return ReferralToggleBookedRequestToJSONTyped(json, false);
}

export function ReferralToggleBookedRequestToJSONTyped(value?: ReferralToggleBookedRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'toComplete': value['toComplete'],
        'crpId': value['crpId'],
    };
}

