import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {getNameString, getClinicCenterStr} from '@/actions/general';
import {ProfilePictureDisplay} from '../ProfilePicture';
import FavouriteClinicianStar from '@components/app/parts/FavouriteStar/FavouriteClinicianStar';
import {ClinicianContact} from '@/generated-client';
import NotificationMessage, {Variant} from '../../app/NotificationMessage';

import {UserProfileCardProps} from './interfaces';
import {useUserProfileCard} from './hooks';

const UserProfileCard: React.FC<UserProfileCardProps> = ({
    userProfileId,
    isUser = false,
}) => {
    const {clinicianProfiles, goToDirectReferral, showError, toggleError} =
        useUserProfileCard(userProfileId);

    // Early return if no clinician profiles are provided
    if (!clinicianProfiles) {
        return (
            <>
                <NotificationMessage
                    show={showError}
                    toggleShow={toggleError}
                    variant={Variant.DANGER}
                    message="Failed to find a clinician with a matching id, redirecting to clinicians map page"
                />
                <h6>Clinician profile not found</h6>;
            </>
        );
    }

    const clinician = clinicianProfiles[0];
    const {userProfile, insuranceAgreements} = clinician;
    const {clinicianDescription, jobRole, appointmentsContact} = userProfile;

    // Render list of insurers
    const renderInsurersList = !insuranceAgreements ? (
        <p>No insurers listed</p>
    ) : (
        <ul className="list-unstyled providers-list insurance-column-list">
            {insuranceAgreements.map((agreement) => (
                <li key={`ia${agreement.id}`}>{agreement.provider}</li>
            ))}
        </ul>
    );

    // Render appointment contacts
    const renderAppointmentContact = (ac: ClinicianContact) => {
        if (!ac.email && !ac.phoneNumber) return null;

        return (
            <li className="d-flex mb-2" key={`appC${ac.id}`}>
                <div
                    className="flex-shrink-0 d-flex align-items-center"
                    style={{width: '30px'}}
                >
                    <i
                        className={`${
                            ac.email
                                ? 'fa-regular fa-envelope'
                                : 'fa-solid fa-phone-volume'
                        } text-muted mcc-icon`}
                    />
                </div>
                <div className="d-flex align-items-center flex-grow-1">
                    <p className="mb-0 text-muted mcc-text">
                        {ac.email ? (
                            <a
                                className="text-muted"
                                href={`mailto:${ac.email}`}
                            >
                                {ac.email}
                            </a>
                        ) : (
                            <a
                                className="text-muted"
                                href={`tel:${ac.phoneNumber}`}
                            >
                                {ac.phoneNumber}
                            </a>
                        )}
                    </p>
                </div>
            </li>
        );
    };

    // Render list of appointment contacts
    const renderAppointmentContacts = () => {
        if (!appointmentsContact || appointmentsContact.length === 0) {
            return <p>No appointment contacts listed</p>;
        }

        return (
            <ul key="list-unstyled mb-1" style={{paddingLeft: '5px'}}>
                {appointmentsContact.map((ac) => renderAppointmentContact(ac))}
            </ul>
        );
    };

    // Get clinic names
    const clinics = clinicianProfiles.map((crp) =>
        getClinicCenterStr(crp.clinic)
    );

    // Get clinician roles as a comma-separated string
    const clinicianRoles = jobRole.map((jr) => jr.jobTitle).join(', ');

    return (
        <>
            <NotificationMessage
                show={showError}
                toggleShow={toggleError}
                variant={Variant.DANGER}
                message="Failed to get clinician by id, redirecting to clinicians map page"
            />
            <Card
                className="mb-2 rounded d-flex flex-fill"
                style={{padding: '0.5rem', maxWidth: '700px'}}
            >
                <div className="user-card-name-pic-box">
                    <div className="user-card-pic-box">
                        <ProfilePictureDisplay userProfile={userProfile} />
                    </div>
                    <div className="user-card-name-box text-left">
                        <Card.Title className="d-flex align-items-start">
                            <div
                                className="flex-shrink-0 d-flex align-items-center"
                                style={{width: '30px'}}
                            >
                                {!isUser && (
                                    <FavouriteClinicianStar
                                        clinician={clinician}
                                        requestMade={() => {}}
                                    />
                                )}
                            </div>
                            <span className="ms-2">
                                {getNameString(userProfile)}
                            </span>
                        </Card.Title>
                        <Card.Subtitle
                            className="text-muted mb-2"
                            style={{paddingLeft: '30px'}}
                        >
                            {clinicianRoles}
                        </Card.Subtitle>
                        <ul className="list-unstyled mb-1">
                            {clinics.map((name) => (
                                <li key={name} className="d-flex mb-2">
                                    <div
                                        className="flex-shrink-0 d-flex align-items-center"
                                        style={{width: '30px'}}
                                    >
                                        <i className="fa-solid fa-house-medical text-muted mcc-icon" />
                                    </div>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <p className="mb-0 text-muted mcc-text">
                                            {name}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {!isUser && (
                            <Button
                                variant="primary"
                                className="usr-rx-now-button mt-1 w100"
                                onClick={() => goToDirectReferral(clinician.id)}
                            >
                                Refer patient now
                            </Button>
                        )}
                    </div>
                </div>
                <hr />
                <div className="user-card-sec-row-box">
                    <div className="text-left user-card-contacts-box">
                        <Card.Title className="text-left">
                            Contact details
                        </Card.Title>
                        {renderAppointmentContacts()}
                    </div>
                    <div className="user-card-description-box">
                        <Card.Title className="text-left">
                            Clinician Bio
                        </Card.Title>
                        <p className="text-muted">{clinicianDescription}</p>
                    </div>
                </div>
                <hr />
                <Card.Title>Insurers covered</Card.Title>
                <div className="text-muted text-left">{renderInsurersList}</div>
            </Card>
        </>
    );
};

export default UserProfileCard;
