import {createContext, useContext} from 'react';
import {MapAddress} from '@/generated-client';
import {HoveredClinicianProps} from '@/hooks/clinicians/interfaces';
import {MapViewCoords} from '@/hooks/clinicians/interfaces';
import {
    LocationSearchResponse,
    LocationSearchResult,
} from '@/features/map/useMapView';
import {FullClinicianRoleProfile} from '@/hooks/clinicians/interfaces';

export interface MapViewContextType {
    clinicians: FullClinicianRoleProfile[];
    refreshClinicians: () => void;
    showReferralButton: boolean;
    hoveredClinicianUserProfileIds: number[];
    updateHovered: (data: HoveredClinicianProps) => void;
    hoveredAddressIds: number[];
    clinics: MapAddress[];
    handleShowFavourites: (value: boolean) => void;
    onClinicianSelect: (targetCrpId: number) => void;
    updateMapViewCoords: (coords: MapViewCoords) => void;
    updateShowOnlyFavourites: (tabRef: string) => void;
    receivePostcodeSearch: (e: LocationSearchResponse) => void;
    location: LocationSearchResult;
    hoveredClinicianId?: number | null;
}

export const MapViewContext = createContext<MapViewContextType | undefined>(
    undefined
);

export function useMapViewContext() {
    const context = useContext(MapViewContext);

    if (!context) {
        throw new Error(
            'useMapViewContext must be used within a MapViewProvider'
        );
    }

    return context;
}
