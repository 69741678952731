import {Reducer} from 'react';
import * as CONSTS from '@constants/messageConstants';

import * as ITF from '@reducers/messageReducer/interfaces';
import {MessageState} from './interfaces';

const initialState: MessageState = {
    variant: '',
    message: '',
};

function isMessageReceivedAction(
    action: ITF.MessageActionTypes
): action is ITF.MessageReceivedAction {
    return action.type === CONSTS.MESSAGE_RECEIVED; // TODO: || action.type === AUTHCONSTS.DEACTIVATE_FULL_ACCOUNT_ACTION.SUCCESS;
}

function handleMessageReceivedAction(
    state: MessageState,
    action: ITF.MessageReceivedAction
): MessageState {
    return action.payload.message;
}

function isMessageResetAction(
    action: ITF.MessageActionTypes
): action is ITF.MessageResetAction {
    return action.type === CONSTS.MESSAGE_RESET; // TODO:  || action.type === LOGOUT_ACTION.SUCCESS;
}

function handleMessageResetAction(): MessageState {
    return initialState;
}

export const messageReducer: Reducer<MessageState, ITF.MessageActionTypes> = (
    state = initialState,
    action
) => {
    if (isMessageReceivedAction(action)) {
        return handleMessageReceivedAction(state, action);
    }
    if (isMessageResetAction(action)) {
        return handleMessageResetAction();
    }
    return state;
};
