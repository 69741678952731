import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import ProfileInfoUpdate from '@/components/user/Profile/ProfileInfoUpdate';
import ProfileChangePassword from '@/components/user/Profile/ProfileChangePassword';
import ProfileAddClinic from '@/components/user/Profile/ProfileAddClinic';
import ProfileChangeInsurance from '@/components/user/Profile/ProfileChangeInsurance';
import ProfileChangeNotifications from '@/components/user/Profile/ProfileChangeNotifications';
import UserClinicianProfiles from '@/components/clinicians/UserClinicianProfiles';
import Loader from '@components/app/Loader';
import ProfileTabsDropDown from '@/components/user/ProfileTabsDropdown/ProfileTabsDropDown';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import ProfileFullDeactivatePopup from '@/components/user/Profile/ProfileFullDeactivatePopup';
import {RootState} from '@/reducers';

enum ProfileView {
    PROFILE = 'profile',
    INFO = 'info',
    PASSWORD = 'password',
    CLINIC = 'clinic',
    INSURANCE = 'insurance',
    NOTIFICATIONS = 'notifications',
}

const ProfileViewTitles: Record<ProfileView, string> = {
    [ProfileView.PROFILE]: 'Profile',
    [ProfileView.INFO]: 'Update Information',
    [ProfileView.PASSWORD]: 'Change password',
    [ProfileView.CLINIC]: 'Manage clinics',
    [ProfileView.INSURANCE]: 'Update insurance agreements',
    [ProfileView.NOTIFICATIONS]: 'Update notification settings',
};

interface ViewOption {
    value: string;
    label: string;
}

const ProfileScreen: React.FC = () => {
    const clinicalProfiles = useSelector(
        (state: RootState) => state.profile.clinicianProfiles
    );
    const isDelegate = useSelector(
        (state: RootState) => state.profile.isDelegate
    );
    const [view, setView] = useState<string>(ProfileView.PROFILE);
    const [title, setTitle] = useState<string>(ProfileView.PROFILE);
    const {isNarrow} = useScreenInfo();
    const [showDeactivatePopup, setShowDeactivatePopup] =
        useState<boolean>(false);
    const colSizes = {sm: 12, md: 3};

    const userProfileId = useSelector((state: RootState) => state.auth.user.id);

    // Update title based on the current view
    useEffect(() => {
        const titles: Record<string, string> = {
            profile: 'Profile',
            info: 'Update Information',
            password: 'Change password',
            clinic: 'Manage clinics',
            insurance: 'Update insurance agreements',
            notifications: 'Update notification settings',
        };
        setTitle(titles[view]);
    }, [view]);

    // Define view options
    const viewOptions: ViewOption[] = Object.entries(ProfileView).map(
        ([, view]) => ({
            value: view,
            label: ProfileViewTitles[view],
        })
    );

    // Render buttons for each view
    const renderButtons = () => {
        return (
            <>
                {viewOptions.map((option) => {
                    if (
                        isDelegate &&
                        option.value === ProfileView.NOTIFICATIONS
                    )
                        return null;
                    return (
                        <Row key={option.value}>
                            <Col>
                                <Button
                                    className={`sel-button w100 mb-4 ${view === option.value ? 'active' : ''}`}
                                    onClick={() => setView(option.value)}
                                >
                                    {option.label}
                                </Button>
                            </Col>
                        </Row>
                    );
                })}
                <Row key="rm-account">
                    <Col>
                        <Button
                            className="sel-button w100 mb-4"
                            variant="danger"
                            onClick={() => setShowDeactivatePopup(true)}
                        >
                            Deactivate account
                        </Button>
                    </Col>
                </Row>
            </>
        );
    };

    // Render the appropriate component based on the current view
    const renderView = () => {
        switch (view) {
            case ProfileView.PROFILE:
                return <UserClinicianProfiles crps={clinicalProfiles} isUser />;
            case ProfileView.INFO:
                return <ProfileInfoUpdate />;
            case ProfileView.PASSWORD:
                return <ProfileChangePassword />;
            case ProfileView.CLINIC:
                return <ProfileAddClinic />;
            case ProfileView.INSURANCE:
                return <ProfileChangeInsurance />;
            case ProfileView.NOTIFICATIONS:
                return !isDelegate && <ProfileChangeNotifications />;
            default:
                return null;
        }
    };

    if (!clinicalProfiles || clinicalProfiles.length === 0) {
        return (
            <div className="medr-layout text-center profile-page">
                <h1>Profile</h1>
                <Loader text="Loading user data" />
            </div>
        );
    }

    return (
        <div
            className="medr-layout text-center profile-page"
            style={{marginLeft: 'auto', marginRight: 'auto'}}
        >
            <h1>{title}</h1>
            <ProfileFullDeactivatePopup
                userProfileId={userProfileId}
                showModal={showDeactivatePopup}
                handleClose={() => setShowDeactivatePopup(false)}
            />
            <Row>
                <Col className="py-1" sm={colSizes.sm} md={colSizes.md}>
                    {isNarrow ? (
                        <ProfileTabsDropDown
                            viewName={title}
                            viewOptions={viewOptions}
                            updateView={setView}
                        />
                    ) : (
                        renderButtons()
                    )}
                </Col>
                <Col>{renderView()}</Col>
            </Row>
        </div>
    );
};

export default ProfileScreen;
