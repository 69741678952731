/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicianRoleProfile } from './ClinicianRoleProfile';
import {
    ClinicianRoleProfileFromJSON,
    ClinicianRoleProfileFromJSONTyped,
    ClinicianRoleProfileToJSON,
    ClinicianRoleProfileToJSONTyped,
} from './ClinicianRoleProfile';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
    PatientToJSONTyped,
} from './Patient';
import type { ReferralStatus } from './ReferralStatus';
import {
    ReferralStatusFromJSON,
    ReferralStatusFromJSONTyped,
    ReferralStatusToJSON,
    ReferralStatusToJSONTyped,
} from './ReferralStatus';

/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    id: number;
    /**
     * 
     * @type {Patient}
     * @memberof Referral
     */
    patient: Patient;
    /**
     * 
     * @type {ClinicianRoleProfile}
     * @memberof Referral
     */
    referrer: ClinicianRoleProfile;
    /**
     * 
     * @type {JobRole}
     * @memberof Referral
     */
    jobRole: JobRole;
    /**
     * 
     * @type {boolean}
     * @memberof Referral
     */
    specifiedClinician: boolean;
    /**
     * 
     * @type {ClinicianRoleProfile}
     * @memberof Referral
     */
    readonly targetClinician: ClinicianRoleProfile;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    mainDescription: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    readonly displayType: string;
    /**
     * 
     * @type {boolean}
     * @memberof Referral
     */
    readonly isPrivateView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Referral
     */
    targetHasAccepted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    history?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Referral
     */
    completed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Referral
     */
    hasDna?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Referral
     */
    readonly createdOn: Date;
    /**
     * 
     * @type {Date}
     * @memberof Referral
     */
    acceptedOn?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Referral
     */
    completedOn?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Referral
     */
    dnaOn?: Date | null;
    /**
     * 
     * @type {ReferralStatus}
     * @memberof Referral
     */
    status?: ReferralStatus | null;
}

/**
 * Check if a given object implements the Referral interface.
 */
export function instanceOfReferral(value: object): value is Referral {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('patient' in value) || value['patient'] === undefined) return false;
    if (!('referrer' in value) || value['referrer'] === undefined) return false;
    if (!('jobRole' in value) || value['jobRole'] === undefined) return false;
    if (!('specifiedClinician' in value) || value['specifiedClinician'] === undefined) return false;
    if (!('targetClinician' in value) || value['targetClinician'] === undefined) return false;
    if (!('mainDescription' in value) || value['mainDescription'] === undefined) return false;
    if (!('displayType' in value) || value['displayType'] === undefined) return false;
    if (!('isPrivateView' in value) || value['isPrivateView'] === undefined) return false;
    if (!('createdOn' in value) || value['createdOn'] === undefined) return false;
    return true;
}

export function ReferralFromJSON(json: any): Referral {
    return ReferralFromJSONTyped(json, false);
}

export function ReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referral {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'patient': PatientFromJSON(json['patient']),
        'referrer': ClinicianRoleProfileFromJSON(json['referrer']),
        'jobRole': JobRoleFromJSON(json['jobRole']),
        'specifiedClinician': json['specifiedClinician'],
        'targetClinician': ClinicianRoleProfileFromJSON(json['targetClinician']),
        'mainDescription': json['mainDescription'],
        'displayType': json['displayType'],
        'isPrivateView': json['isPrivateView'],
        'targetHasAccepted': json['targetHasAccepted'] == null ? undefined : json['targetHasAccepted'],
        'history': json['history'] == null ? undefined : json['history'],
        'completed': json['completed'] == null ? undefined : json['completed'],
        'hasDna': json['hasDna'] == null ? undefined : json['hasDna'],
        'createdOn': (new Date(json['createdOn'])),
        'acceptedOn': json['acceptedOn'] == null ? undefined : (new Date(json['acceptedOn'])),
        'completedOn': json['completedOn'] == null ? undefined : (new Date(json['completedOn'])),
        'dnaOn': json['dnaOn'] == null ? undefined : (new Date(json['dnaOn'])),
        'status': json['status'] == null ? undefined : ReferralStatusFromJSON(json['status']),
    };
}

export function ReferralToJSON(json: any): Referral {
    return ReferralToJSONTyped(json, false);
}

export function ReferralToJSONTyped(value?: Omit<Referral, 'targetClinician'|'displayType'|'isPrivateView'|'createdOn'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'patient': PatientToJSON(value['patient']),
        'referrer': ClinicianRoleProfileToJSON(value['referrer']),
        'jobRole': JobRoleToJSON(value['jobRole']),
        'specifiedClinician': value['specifiedClinician'],
        'mainDescription': value['mainDescription'],
        'targetHasAccepted': value['targetHasAccepted'],
        'history': value['history'],
        'completed': value['completed'],
        'hasDna': value['hasDna'],
        'acceptedOn': value['acceptedOn'] == null ? undefined : ((value['acceptedOn'] as any).toISOString()),
        'completedOn': value['completedOn'] == null ? undefined : ((value['completedOn'] as any).toISOString()),
        'dnaOn': value['dnaOn'] == null ? undefined : ((value['dnaOn'] as any).toISOString()),
        'status': ReferralStatusToJSON(value['status']),
    };
}

