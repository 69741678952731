import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {getNameString} from '@/actions/general';
import FavouriteClinicianStar from '@components/app/parts/FavouriteStar/FavouriteClinicianStar';
import useClinicianCard from './useClinicianCard.js';
import {useMapViewContext} from './MapViewContext.js';
import {FullClinicianRoleProfile} from '@/hooks/clinicians/interfaces';
interface ClinicianCardProps {
    clinician: FullClinicianRoleProfile;
    refCallback?: (el: HTMLDivElement | null) => void;
}

const ClinicianCard: React.FC<ClinicianCardProps> = ({
    clinician,
    refCallback, // eslint-disable-line @typescript-eslint/no-unused-vars
}) => {
    const {
        refreshClinicians,
        showReferralButton,
        hoveredClinicianUserProfileIds,
        updateHovered,
        onClinicianSelect,
    } = useMapViewContext();

    const {
        onMouseEnter,
        onMouseLeave,
        clinicianIsHovered,
        goToDirectReferral,
        clinicNames,
    } = useClinicianCard({
        clinician,
        hoveredClinicianUserProfileIds,
        updateHovered,
    });

    const clinicianRoles = clinician.userProfile.jobRole.map(
        (jobRole) => jobRole.jobTitle
    );
    const clinicianRolesString = clinicianRoles.join(', ');

    const clinSel = (e) => {
        if (e?.target?.className.includes('btn-')) return;
        onClinicianSelect(clinician.id);
    };
    return (
        <Card
            className={`mb-2 rounded d-flex flex-fill ${
                clinicianIsHovered ? 'map-clinician-card' : ''
            }`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={clinSel}
            style={{padding: '0.5rem', cursor: 'pointer'}}
        >
            <div className="d-flex justify-content-between align-items-center">
                <Card.Title style={{marginBottom: '0rem', marginTop: '0rem'}}>
                    {getNameString(clinician.userProfile)}
                </Card.Title>
                <Card.Subtitle
                    className="text-muted"
                    style={{marginTop: '0rem', marginBottom: '0rem'}}
                >
                    {clinician.distance.toFixed(1)}km
                </Card.Subtitle>
            </div>
            <Card.Subtitle className="mb-3 mt-1 text-muted">
                {clinicianRolesString}
            </Card.Subtitle>
            <ul className="list-unstyled mb-1">
                {clinicNames.map((name) => (
                    <li key={name} className="d-flex mb-2">
                        <div className="flex-shrink-0 d-flex align-items-center">
                            <i className="fa-solid fa-house-medical text-muted mcc-icon" />
                        </div>
                        <div className="d-flex align-items-center flex-grow-1">
                            <p className="mb-0 text-muted mcc-text">{name}</p>
                        </div>
                    </li>
                ))}
            </ul>
            {showReferralButton ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        className="map-clinician-card-rx-btn"
                        variant="primary"
                        onClick={goToDirectReferral}
                    >
                        Refer patient
                    </Button>
                    <FavouriteClinicianStar
                        clinician={clinician}
                        requestMade={refreshClinicians}
                    />
                </div>
            ) : (
                <div className="d-flex justify-content-end align-items-center">
                    <FavouriteClinicianStar
                        clinician={clinician}
                        requestMade={refreshClinicians}
                    />
                </div>
            )}
        </Card>
    );
};

export default ClinicianCard;
