import {Referral} from '@/generated-client';

export interface ReferralBarProps {
    referral: Referral;
}

export enum ReferralView {
    Sent = 'Sent',
    Pending = 'Pending',
    Accepted = 'Accepted',
    Open = 'Open',
    Booked = 'Booked',
    Dna = 'Dna',
}

export interface UseReferralBarReturn {
    selectedView: ReferralView;
    isOpen: boolean;
    handleClickOpen: () => void;
    permissionManageReferrals: boolean;
    referrerClinicianPageUrl: string;
    targetClinicianPageUrl: string;
    referralAccepted: boolean;
    referralCompleted: boolean;
    onClickCancelButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickAcceptButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickRejectButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickCompleteButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickDnaButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickSetActiveButton: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => void;
}
