/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicianRoleProfile } from './ClinicianRoleProfile';
import {
    ClinicianRoleProfileFromJSON,
    ClinicianRoleProfileFromJSONTyped,
    ClinicianRoleProfileToJSON,
    ClinicianRoleProfileToJSONTyped,
} from './ClinicianRoleProfile';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';

/**
 * 
 * @export
 * @interface ClinicianProfileUpdateResponse
 */
export interface ClinicianProfileUpdateResponse {
    /**
     * 
     * @type {Message}
     * @memberof ClinicianProfileUpdateResponse
     */
    message: Message;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof ClinicianProfileUpdateResponse
     */
    clinProfs: Array<ClinicianRoleProfile>;
}

/**
 * Check if a given object implements the ClinicianProfileUpdateResponse interface.
 */
export function instanceOfClinicianProfileUpdateResponse(value: object): value is ClinicianProfileUpdateResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('clinProfs' in value) || value['clinProfs'] === undefined) return false;
    return true;
}

export function ClinicianProfileUpdateResponseFromJSON(json: any): ClinicianProfileUpdateResponse {
    return ClinicianProfileUpdateResponseFromJSONTyped(json, false);
}

export function ClinicianProfileUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianProfileUpdateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
        'clinProfs': ((json['clinProfs'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
    };
}

export function ClinicianProfileUpdateResponseToJSON(json: any): ClinicianProfileUpdateResponse {
    return ClinicianProfileUpdateResponseToJSONTyped(json, false);
}

export function ClinicianProfileUpdateResponseToJSONTyped(value?: ClinicianProfileUpdateResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': MessageToJSON(value['message']),
        'clinProfs': ((value['clinProfs'] as Array<any>).map(ClinicianRoleProfileToJSON)),
    };
}

