import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {UserProfile} from '@/generated-client';
import {RootState} from '@/reducers';
import {
    UseProfilePictureReturn,
    UsePictureUploadModalReturn,
} from './interfaces';
import {useUserService} from '@/actions';

export const useProfilePicture = (
    userProfile: UserProfile
): UseProfilePictureReturn => {
    const [up, setUp] = useState(userProfile);
    const [showPicUpload, setShowPicUpload] = useState(false);
    const loggedInUser = useSelector(
        (state: RootState) => state.profile.userProfile
    );
    const editable = loggedInUser.id === userProfile.id;

    const uploadPicture = () => {
        if (editable) setShowPicUpload(true);
    };
    const uploadPictureKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            uploadPicture();
        }
    };

    useEffect(() => {
        if (editable) {
            setUp(userProfile);
        }
    }, [loggedInUser.profilePicture]);

    const profilePicturePath = checkProfileUrlString(up.profilePicture);
    return {
        profilePicturePath,
        editable,
        uploadPicture,
        uploadPictureKeyPress,
        showPicUpload,
        setShowPicUpload,
    };
};

export const checkProfileUrlString = (
    profilePicture: string
): string | null => {
    if (!profilePicture) return null;
    if (profilePicture.startsWith('http')) return profilePicture;
    return `http://localhost:8000${profilePicture}`;
};

export const usePictureUploadModal = (
    handleClose: () => void
): UsePictureUploadModalReturn => {
    const userService = useUserService();

    const imageFilePath = useSelector(
        (state: RootState) => state.profile.userProfile.profilePicture
    );
    const imageSrc = checkProfileUrlString(imageFilePath);

    const [preview, setPreview] = useState<string | null>(null);
    const bgColor = '#474649';

    const onBeforeFileLoad = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files[0].size > 716800) {
            //   alert("File is too big!");
            e.target.value = '';
        }
    };
    const onCrop = (view: string) => {
        setPreview(view);
    };
    const onClose = () => setPreview(null);

    const saveImage = async () => {
        if (!preview) return;

        // view string format
        // "data:image/png;base64,iVBORw0KGgoA...."
        const imageString = preview.split(',')[1];

        const uploadPicSuccess =
            await userService.updateProfilePicture(imageString);
        if (!uploadPicSuccess) return;
        handleClose();
        window.location.reload();
    };

    return {
        imageSrc,
        preview,
        bgColor,
        onBeforeFileLoad,
        onCrop,
        onClose,
        saveImage,
    };
};
