import React, {useState, useEffect} from 'react';
import {Row, Col, Card, Button} from 'react-bootstrap';
import {PatientBar} from './PatientBar';
import PaginationBar from '../app/PaginationBar';
import usePatientsList from '@/hooks/patients/usePatientsList';
import PatientFilterInput from './PatientFilterInput';
import usePatientFilter from '@hooks/patients/usePatientFilter';
import {useSelector} from 'react-redux';
import Loader from '@components/app/Loader';
import {Patient} from '@/generated-client';
import {RootState} from '@/reducers';

const NoPatientsDisplay: React.FC = () => {
    return (
        <Card
            className="mb-2 rounded d-flex flex-fill"
            style={{padding: '1rem'}}
        >
            <Card.Title>No patients to display at the moment </Card.Title>
            <Card.Subtitle className="text-muted">
                If you have used the search bar, please modify your search
                criteria and try again.
            </Card.Subtitle>
        </Card>
    );
};

interface PatientCardsDisplayProps {
    patientsList: Patient[];
    headers: string[];
    showSearch?: boolean;
}

const PatientCardsDisplay: React.FC<PatientCardsDisplayProps> = ({
    patientsList,
    headers,
    showSearch = true,
}) => {
    const [newestFirst, setNewestFirst] = useState(true);
    const [pxBarsDisplay, setPxBarsDisplay] = useState(null);
    const toggleNewestFirst = () => setNewestFirst((prev) => !prev);
    const nPatientsWindow = 25;
    const start = 0;
    const end = nPatientsWindow;
    const isLoading = useSelector((state: RootState) => state.patients.loading);

    const [filterStr, setFilterStr] = usePatientFilter();
    const [patients, updatePatientsSlice, patientsLength] = usePatientsList({
        patientsList,
        start,
        end,
        filter: filterStr,
    });

    const rxHeaders = (
        <div className="py-1" style={{marginLeft: '37px'}}>
            <div className="align-items-center p-0 referral-bar referral-bar-header d-none d-md-flex">
                {headers.map((header, index) => (
                    <div
                        className={`flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-${index + 1}`}
                        key={`${index}abc`}
                    >
                        <h4 className="m-0">{header}</h4>
                    </div>
                ))}
                <div className="flex-grow-1 text-right">
                    <Button
                        className="btn-sm medr-toggle-sort-btn"
                        onClick={toggleNewestFirst}
                    >
                        {newestFirst ? 'Set newest first' : 'Set oldest first'}
                    </Button>
                </div>
            </div>
        </div>
    );

    const pxBars = () => {
        if (newestFirst) {
            return patients.map((patient: Patient) => (
                <div className="p-1" key={`pxD${patient.id}`}>
                    <PatientBar patient={patient} />
                </div>
            ));
        }

        const pxRev = [...patients].reverse();
        return pxRev.map((patient: Patient) => (
            <div className="p-1" key={`pxD${patient.id}`}>
                <PatientBar patient={patient} />
            </div>
        ));
    };

    useEffect(() => {
        setPxBarsDisplay(pxBars());
    }, [patients, newestFirst]);

    return (
        <>
            {showSearch && (
                <Row className="text-left p-1">
                    <Col className="px-filter-bar-col">
                        <PatientFilterInput setFilterString={setFilterStr} />
                    </Col>
                </Row>
            )}
            {isLoading && (
                <>
                    <div className="gap mt-5" />
                    <Loader text="Loading patients..." />
                </>
            )}
            {!isLoading && rxHeaders}
            {!isLoading && patientsLength === 0 && <NoPatientsDisplay />}
            {!isLoading && pxBarsDisplay}
            {!isLoading && patientsLength > nPatientsWindow && (
                <>
                    <hr className="medr" />
                    <PaginationBar
                        arrayLength={patientsLength}
                        setSliceValues={updatePatientsSlice}
                        windowSize={nPatientsWindow}
                    />
                </>
            )}
        </>
    );
};

export default PatientCardsDisplay;
