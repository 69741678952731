import {Dispatch} from 'redux';
import * as USRCONSTS from '@/constants/userConstants';
import * as PROFCONSTS from '@/constants/profileConstants';
import * as CLIENT from '@/generated-client';

// loadUserProfile
export function dispatchLoadUserProfileRequest(dispatch: Dispatch) {
    dispatch({type: PROFCONSTS.LOAD_USER_PROFILE_ACTION.REQUEST});
}
export function dispatchLoadUserProfileSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianUserInfoResponse
) {
    dispatch({type: PROFCONSTS.LOAD_USER_PROFILE_ACTION.SUCCESS, payload});
}
export function dispatchLoadUserProfileFail(dispatch: Dispatch, error: string) {
    dispatch({
        type: PROFCONSTS.LOAD_USER_PROFILE_ACTION.FAIL,
        payload: error,
    });
}

// getReferralNotifications
export function dispatchGetReferralNotificationsRequest(dispatch: Dispatch) {
    dispatch({type: PROFCONSTS.REFERRAL_NOTIFICATIONS_ACTION.REQUEST});
}
export function dispatchGetReferralNotificationsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralsNotificationsForUserResponse
) {
    dispatch({
        type: PROFCONSTS.REFERRAL_NOTIFICATIONS_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetReferralNotificationsFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: PROFCONSTS.REFERRAL_NOTIFICATIONS_ACTION.FAIL,
        payload: error,
    });
}

// updateProfile
export function dispatchUpdateProfileRequest(dispatch: Dispatch) {
    dispatch({type: PROFCONSTS.UPDATE_USER_PROFILE_ACTION.REQUEST});
}
export function dispatchUpdateProfileSuccess(
    dispatch: Dispatch,
    payload: CLIENT.UpdateUserProfileResponse
) {
    dispatch({type: PROFCONSTS.UPDATE_USER_PROFILE_ACTION.SUCCESS, payload});
}
export function dispatchUpdateProfileFail(dispatch: Dispatch, error: string) {
    dispatch({
        type: PROFCONSTS.UPDATE_USER_PROFILE_ACTION.FAIL,
        payload: error,
    });
}

// updateProfilePicture
export function dispatchUpdateProfilePictureRequest(dispatch: Dispatch) {
    dispatch({type: USRCONSTS.UPDATE_PROFILE_PIC_ACTION.REQUEST});
}
export function dispatchUpdateProfilePictureSuccess(
    dispatch: Dispatch,
    payload: CLIENT.UpdateUserProfilePictureResponse
) {
    dispatch({type: USRCONSTS.UPDATE_PROFILE_PIC_ACTION.SUCCESS, payload});
}
export function dispatchUpdateProfilePictureFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: USRCONSTS.UPDATE_PROFILE_PIC_ACTION.FAIL,
        payload: error,
    });
}

// updateUserNotificationsSettings
export function dispatchUpdateUserNotificationsSettingsRequest(
    dispatch: Dispatch
) {
    dispatch({type: PROFCONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.REQUEST});
}
export function dispatchUpdateUserNotificationsSettingsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.UpdateUserNotificationsSettingsResponse
) {
    dispatch({
        type: PROFCONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchUpdateUserNotificationsSettingsFail(
    dispatch: Dispatch,
    error: string = ''
) {
    dispatch({
        type: PROFCONSTS.UPDATE_USER_NOTIFICATIONS_ACTION.FAIL,
        payload: error,
    });
}

// getUserSummaryAnalytics
export function dispatchGetUserSummaryAnalyticsRequest(dispatch: Dispatch) {
    dispatch({type: PROFCONSTS.USER_SUMMARY_ANALYTICS_ACTION.REQUEST});
}
export function dispatchGetUserSummaryAnalyticsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianAnalyticsResponse
) {
    dispatch({
        type: PROFCONSTS.USER_SUMMARY_ANALYTICS_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetUserSummaryAnalyticsFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: PROFCONSTS.USER_SUMMARY_ANALYTICS_ACTION.FAIL,
        payload: error,
    });
}

// updateReferralContacts
export function dispatchUpdateReferralContactsRequest(dispatch: Dispatch) {
    dispatch({type: PROFCONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.REQUEST});
}
export function dispatchUpdateReferralContactsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.UpdateReferralContactsResponse
) {
    dispatch({
        type: PROFCONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchUpdateReferralContactsFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: PROFCONSTS.UPDATE_REFERRAL_CONTACTS_ACTION.FAIL,
        payload: error,
    });
}

// requestHelp
export function dispatchRequestHelpRequest(dispatch: Dispatch) {
    dispatch({type: PROFCONSTS.REQUEST_HELP_ACTION.REQUEST});
}
export function dispatchRequestHelpSuccess(
    dispatch: Dispatch,
    payload: CLIENT.RequestHelpResponse
) {
    dispatch({type: PROFCONSTS.REQUEST_HELP_ACTION.SUCCESS, payload});
}
export function dispatchRequestHelpFail(dispatch: Dispatch, error: string) {
    dispatch({type: PROFCONSTS.REQUEST_HELP_ACTION.FAIL, payload: error});
}
