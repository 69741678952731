/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
    ReferralToJSONTyped,
} from './Referral';

/**
 * 
 * @export
 * @interface ReferralSelectionResponse
 */
export interface ReferralSelectionResponse {
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralSelectionResponse
     */
    waiting: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralSelectionResponse
     */
    accepted: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralSelectionResponse
     */
    open: Array<Referral>;
}

/**
 * Check if a given object implements the ReferralSelectionResponse interface.
 */
export function instanceOfReferralSelectionResponse(value: object): value is ReferralSelectionResponse {
    if (!('waiting' in value) || value['waiting'] === undefined) return false;
    if (!('accepted' in value) || value['accepted'] === undefined) return false;
    if (!('open' in value) || value['open'] === undefined) return false;
    return true;
}

export function ReferralSelectionResponseFromJSON(json: any): ReferralSelectionResponse {
    return ReferralSelectionResponseFromJSONTyped(json, false);
}

export function ReferralSelectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralSelectionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'waiting': ((json['waiting'] as Array<any>).map(ReferralFromJSON)),
        'accepted': ((json['accepted'] as Array<any>).map(ReferralFromJSON)),
        'open': ((json['open'] as Array<any>).map(ReferralFromJSON)),
    };
}

export function ReferralSelectionResponseToJSON(json: any): ReferralSelectionResponse {
    return ReferralSelectionResponseToJSONTyped(json, false);
}

export function ReferralSelectionResponseToJSONTyped(value?: ReferralSelectionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'waiting': ((value['waiting'] as Array<any>).map(ReferralToJSON)),
        'accepted': ((value['accepted'] as Array<any>).map(ReferralToJSON)),
        'open': ((value['open'] as Array<any>).map(ReferralToJSON)),
    };
}

