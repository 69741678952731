import React from 'react';
import AdminPatientsTable from '@components/admin/AdminPatientsTable';

const AdminPatientListScreen: React.FC = () => {
    return (
        <div>
            <h1>Patients</h1>
            <AdminPatientsTable />
        </div>
    );
};

export default AdminPatientListScreen;
