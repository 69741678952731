import React from 'react';
import AdminInsurersTable from '@/components/admin/AdminInsurersTable';

const AdminInsurersScreen: React.FC = () => {
    return (
        <div>
            <h2>Insurence Providers</h2>
            <AdminInsurersTable />
        </div>
    );
};

export default AdminInsurersScreen;
