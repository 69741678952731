/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetUserPasswordSetNewRequest
 */
export interface ResetUserPasswordSetNewRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordSetNewRequest
     */
    userHash: string;
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordSetNewRequest
     */
    timeHash: string;
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordSetNewRequest
     */
    verificationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordSetNewRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordSetNewRequest
     */
    rePassword: string;
}

/**
 * Check if a given object implements the ResetUserPasswordSetNewRequest interface.
 */
export function instanceOfResetUserPasswordSetNewRequest(value: object): value is ResetUserPasswordSetNewRequest {
    if (!('userHash' in value) || value['userHash'] === undefined) return false;
    if (!('timeHash' in value) || value['timeHash'] === undefined) return false;
    if (!('verificationCode' in value) || value['verificationCode'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('rePassword' in value) || value['rePassword'] === undefined) return false;
    return true;
}

export function ResetUserPasswordSetNewRequestFromJSON(json: any): ResetUserPasswordSetNewRequest {
    return ResetUserPasswordSetNewRequestFromJSONTyped(json, false);
}

export function ResetUserPasswordSetNewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetUserPasswordSetNewRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userHash': json['userHash'],
        'timeHash': json['timeHash'],
        'verificationCode': json['verificationCode'],
        'password': json['password'],
        'rePassword': json['rePassword'],
    };
}

export function ResetUserPasswordSetNewRequestToJSON(json: any): ResetUserPasswordSetNewRequest {
    return ResetUserPasswordSetNewRequestToJSONTyped(json, false);
}

export function ResetUserPasswordSetNewRequestToJSONTyped(value?: ResetUserPasswordSetNewRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userHash': value['userHash'],
        'timeHash': value['timeHash'],
        'verificationCode': value['verificationCode'],
        'password': value['password'],
        'rePassword': value['rePassword'],
    };
}

