/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationsSettings } from './NotificationsSettings';
import {
    NotificationsSettingsFromJSON,
    NotificationsSettingsFromJSONTyped,
    NotificationsSettingsToJSON,
    NotificationsSettingsToJSONTyped,
} from './NotificationsSettings';

/**
 * 
 * @export
 * @interface UpdateUserNotificationsSettingsResponse
 */
export interface UpdateUserNotificationsSettingsResponse {
    /**
     * 
     * @type {NotificationsSettings}
     * @memberof UpdateUserNotificationsSettingsResponse
     */
    notificationSettings: NotificationsSettings;
}

/**
 * Check if a given object implements the UpdateUserNotificationsSettingsResponse interface.
 */
export function instanceOfUpdateUserNotificationsSettingsResponse(value: object): value is UpdateUserNotificationsSettingsResponse {
    if (!('notificationSettings' in value) || value['notificationSettings'] === undefined) return false;
    return true;
}

export function UpdateUserNotificationsSettingsResponseFromJSON(json: any): UpdateUserNotificationsSettingsResponse {
    return UpdateUserNotificationsSettingsResponseFromJSONTyped(json, false);
}

export function UpdateUserNotificationsSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserNotificationsSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationSettings': NotificationsSettingsFromJSON(json['notificationSettings']),
    };
}

export function UpdateUserNotificationsSettingsResponseToJSON(json: any): UpdateUserNotificationsSettingsResponse {
    return UpdateUserNotificationsSettingsResponseToJSONTyped(json, false);
}

export function UpdateUserNotificationsSettingsResponseToJSONTyped(value?: UpdateUserNotificationsSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'notificationSettings': NotificationsSettingsToJSON(value['notificationSettings']),
    };
}

