import {Dispatch} from 'redux';
import {InsuranceAgreementsApi} from '@/generated-client';
import * as RED from '@/reducers/cliniciansReducer';
import {MedrService} from './authService';
import {NavigateFunction} from 'react-router-dom';

export class InsuranceAgreementService extends MedrService {
    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
    }

    async getInsuranceAgreements(): Promise<boolean> {
        const api = new InsuranceAgreementsApi(this.configuration);
        return this.executeApiCall(
            () => api.insuranceAgreementsList(),
            RED.dispatchGetInsuranceAgreementsRequest,
            RED.dispatchGetInsuranceAgreementsSuccess,
            RED.dispatchGetInsuranceAgreementsFail
        );
    }
}
