import {Reducer, combineReducers} from 'redux';
import {adminReducer as admin} from './adminReducer/adminReducer';
import {authReducer as auth} from './authReducer';
import {cliniciansReducer as clinicians} from './cliniciansReducer/cliniciansReducer';
import {messageReducer as message} from './messageReducer/messageReducer';
import {patientReducer as patients} from './patientReducer/patientReducer';
import {profileReducer as profile} from './profileReducer/profileReducer';
import {referralReducer as referrals} from './referralReducer';
import {searchReducer as search} from './searchReducer/searchReducer';
import {AdminState} from './adminReducer';
import {AuthState} from './authReducer';
import {ClinicianState} from './cliniciansReducer';
import {MessageState} from './messageReducer';
import {PatientState} from './patientReducer';
import {ProfileState} from './profileReducer';
import {ReferralState} from './referralReducer';
import {SearchState} from './searchReducer';

export interface RootState {
    admin: AdminState;
    auth: AuthState;
    clinicians: ClinicianState;
    message: MessageState;
    patients: PatientState;
    profile: ProfileState;
    referrals: ReferralState;
    search: SearchState;
}

const rootReducer: Reducer<RootState> = combineReducers({
    message,
    auth,
    admin,
    profile,
    patients,
    referrals,
    clinicians,
    search,
});

export default rootReducer;
