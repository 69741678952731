/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReferralsGroupLists } from './ReferralsGroupLists';
import {
    ReferralsGroupListsFromJSON,
    ReferralsGroupListsFromJSONTyped,
    ReferralsGroupListsToJSON,
    ReferralsGroupListsToJSONTyped,
} from './ReferralsGroupLists';

/**
 * 
 * @export
 * @interface ReferralsListOutboundForUserResponse
 */
export interface ReferralsListOutboundForUserResponse {
    /**
     * 
     * @type {ReferralsGroupLists}
     * @memberof ReferralsListOutboundForUserResponse
     */
    referralsMade: ReferralsGroupLists;
}

/**
 * Check if a given object implements the ReferralsListOutboundForUserResponse interface.
 */
export function instanceOfReferralsListOutboundForUserResponse(value: object): value is ReferralsListOutboundForUserResponse {
    if (!('referralsMade' in value) || value['referralsMade'] === undefined) return false;
    return true;
}

export function ReferralsListOutboundForUserResponseFromJSON(json: any): ReferralsListOutboundForUserResponse {
    return ReferralsListOutboundForUserResponseFromJSONTyped(json, false);
}

export function ReferralsListOutboundForUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralsListOutboundForUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'referralsMade': ReferralsGroupListsFromJSON(json['referralsMade']),
    };
}

export function ReferralsListOutboundForUserResponseToJSON(json: any): ReferralsListOutboundForUserResponse {
    return ReferralsListOutboundForUserResponseToJSONTyped(json, false);
}

export function ReferralsListOutboundForUserResponseToJSONTyped(value?: ReferralsListOutboundForUserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'referralsMade': ReferralsGroupListsToJSON(value['referralsMade']),
    };
}

