import React, {useState} from 'react';
import ClinicForm from './ClinicForm';
import ClinicsDisplayCard from './ClinicDisplayCard';
import {Button} from 'react-bootstrap';
import {
    ClinicFormData,
    createDefaultClinicFormData,
} from './ClinicForm.interfaces';

interface ClinicsDisplayBoxProps {
    clinics: ClinicFormData[];
    editable: boolean;
    addClinic?: (clinic: ClinicFormData) => void;
    removeClinic?: (clinic: ClinicFormData) => void;
    editClinic?: (clinic: ClinicFormData) => void;
}

const ClinicsDisplayBox: React.FC<ClinicsDisplayBoxProps> = ({
    clinics,
    editable,
    addClinic,
    removeClinic,
    editClinic,
}) => {
    const [showForm, setShowForm] = useState(clinics.length === 0);
    const [newClinicForm, setNewClinicForm] = useState<ClinicFormData>(
        createDefaultClinicFormData()
    );
    const addNewClinicToUser = (clinic: ClinicFormData) => {
        addClinic(clinic);
        setNewClinicForm(createDefaultClinicFormData());
        setShowForm(false);
    };

    const clinicsAdded =
        clinics.length > 0
            ? clinics.map((clinic: ClinicFormData) => (
                  <ClinicsDisplayCard
                      key={`newCp${clinic.centreName}`}
                      values={clinic}
                      editable={editable}
                      removeClinic={removeClinic}
                      editClinic={(e) =>
                          editClinic({...e, listInd: clinic.listInd})
                      }
                  />
              ))
            : null;

    if (!editable) {
        return (
            <div className="medr-rounded">
                <h4 className="text-left py-2 bluegreen m-2">
                    Assigned clinics
                </h4>
                {clinicsAdded}
            </div>
        );
    }

    return (
        <div className="medr-rounded bg-medr-gray bluegreen">
            <h4 className="bluegreen text-center">Assigned clinics</h4>
            {clinicsAdded}
            <div
                className="col-md-12 mt-1"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant={showForm ? 'danger' : 'info'}
                    className="sel-button p-1"
                    onClick={() => setShowForm(!showForm)}
                >
                    {showForm ? 'Hide clinic form' : 'Add clinic'}
                </Button>
            </div>

            {showForm && (
                <ClinicForm
                    clinicData={newClinicForm}
                    updateClinicData={setNewClinicForm}
                    handleAddClinic={addNewClinicToUser}
                />
            )}
        </div>
    );
};

export default ClinicsDisplayBox;
