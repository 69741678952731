/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
    PatientToJSONTyped,
} from './Patient';

/**
 * 
 * @export
 * @interface PatientUpdateByIdResponse
 */
export interface PatientUpdateByIdResponse {
    /**
     * 
     * @type {Patient}
     * @memberof PatientUpdateByIdResponse
     */
    patient: Patient;
}

/**
 * Check if a given object implements the PatientUpdateByIdResponse interface.
 */
export function instanceOfPatientUpdateByIdResponse(value: object): value is PatientUpdateByIdResponse {
    if (!('patient' in value) || value['patient'] === undefined) return false;
    return true;
}

export function PatientUpdateByIdResponseFromJSON(json: any): PatientUpdateByIdResponse {
    return PatientUpdateByIdResponseFromJSONTyped(json, false);
}

export function PatientUpdateByIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientUpdateByIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'patient': PatientFromJSON(json['patient']),
    };
}

export function PatientUpdateByIdResponseToJSON(json: any): PatientUpdateByIdResponse {
    return PatientUpdateByIdResponseToJSONTyped(json, false);
}

export function PatientUpdateByIdResponseToJSONTyped(value?: PatientUpdateByIdResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'patient': PatientToJSON(value['patient']),
    };
}

