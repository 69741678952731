import {useState, useEffect, useCallback} from 'react';
import {defaultMapLat, defaultMapLng} from '@/constants/variables';

const favouritesTabRef = 'favouriteClinicians';

export type LocationSearchResult = {
    loaded: boolean;
    coordinates: {lat: number; lng: number};
    mapCenter: [number, number];
    error?: string; // TODO: check if this is correct
};

export type LocationSearchResponse = {
    postcode: string;
    searchLatitude: number;
    searchLongitude: number;
};

const getLocationDataStructure = (
    loaded: boolean,
    lat: number,
    lng: number
): LocationSearchResult => ({
    loaded,
    coordinates: {lat, lng},
    mapCenter: [lat, lng],
    error: null,
});

const errorLocation: LocationSearchResult = {
    loaded: true,
    coordinates: {lat: defaultMapLat, lng: defaultMapLng},
    mapCenter: [defaultMapLat, defaultMapLng],
    error: null,
};

interface UseMapViewReferralEntryProps {
    patientLat: number;
    patientLng: number;
    handleShowFavourites: (value: boolean) => void;
}

interface UseMapViewReferralEntryReturn {
    updateShowOnlyFavourites: (tabRef: string) => void;
    receivePostcodeSearch: (e: LocationSearchResponse) => void;
    location: LocationSearchResult;
}

export const useMapViewReferralEntry = ({
    patientLat,
    patientLng,
    handleShowFavourites,
}: UseMapViewReferralEntryProps): UseMapViewReferralEntryReturn => {
    const [location, setLocation] = useState<LocationSearchResult>(() =>
        getLocationDataStructure(true, patientLat, patientLng)
    );

    const updateShowOnlyFavourites = useCallback(
        (tabRef: string) => {
            handleShowFavourites(tabRef === favouritesTabRef);
        },
        [handleShowFavourites]
    );

    const receivePostcodeSearch = useCallback((e: LocationSearchResponse) => {
        setLocation(
            getLocationDataStructure(true, e.searchLatitude, e.searchLongitude)
        );
    }, []);

    return {
        updateShowOnlyFavourites,
        receivePostcodeSearch,
        location,
    };
};

interface UseMapViewClinicianListProps {
    handleShowFavourites: (value: boolean) => void;
}

interface UseMapViewClinicianListReturn {
    updateShowOnlyFavourites: (tabRef: string) => void;
    receivePostcodeSearch: (e: LocationSearchResponse) => void;
    location: LocationSearchResult;
}

export const useMapViewClinicianList = ({
    handleShowFavourites,
}: UseMapViewClinicianListProps): UseMapViewClinicianListReturn => {
    const [userLocation, setUserLocation] = useState<LocationSearchResult>(
        getLocationDataStructure(false, 0, 0)
    );
    const [searchedLocation, setSearchedLocation] =
        useState<LocationSearchResult>(getLocationDataStructure(false, 0, 0));
    const [location, setLocation] = useState<LocationSearchResult>(
        getLocationDataStructure(false, 0, 0)
    );

    const getCurrentUserLocationPermissionSetting = useCallback(async () => {
        return navigator.permissions
            .query({name: 'geolocation' as PermissionName})
            .then((result) => result.state);
    }, []);

    const onUserLocationSuccess = useCallback(
        (currentLocation: GeolocationPosition) => {
            setUserLocation(
                getLocationDataStructure(
                    true,
                    currentLocation.coords.latitude,
                    currentLocation.coords.longitude
                )
            );
        },
        []
    );

    const onUserLocationError = useCallback(
        (error: GeolocationPositionError) => {
            console.log('Error getting user location', error);
            setUserLocation(errorLocation);
        },
        []
    );

    const handleDeniedPermission = useCallback(() => {
        alert(
            'Enabling location access will provide a better user experience on medr.co.uk. Please go to your browser settings and enable location services to enjoy personalized features and services.'
        );
        onUserLocationError({} as GeolocationPositionError);
    }, [onUserLocationError]);

    const requestGeolocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition(
            onUserLocationSuccess,
            onUserLocationError
        );
    }, [onUserLocationSuccess, onUserLocationError]);

    const handleCheckPermission = useCallback(async () => {
        const refreshPermissions = ['prompt', 'granted'];
        const permission = await getCurrentUserLocationPermissionSetting();
        if (refreshPermissions.includes(permission)) {
            requestGeolocation();
        } else {
            handleDeniedPermission();
        }
    }, [
        getCurrentUserLocationPermissionSetting,
        requestGeolocation,
        handleDeniedPermission,
    ]);

    useEffect(() => {
        handleCheckPermission();
    }, [handleCheckPermission]);

    // const handleCheckPermission = async () => {
    // 	const refreshPermissions = [ "prompt", "granted" ];
    // 	const permission = await getCurrentUserLocationPermissionSetting();
    // 	if ( refreshPermissions.includes( permission ) ) {
    // 		requestGeolocation();
    // 	} else if ( permission === "denied" ) {
    // 		handleDeniedPermission();
    // 	} else {
    // 		console.log( "Permission not granted yet" );
    // 		handleDeniedPermission();
    // 	}
    // }

    const updateShowOnlyFavourites = useCallback(
        (tabRef: string) => {
            handleShowFavourites(tabRef === favouritesTabRef);
        },
        [handleShowFavourites]
    );

    const receivePostcodeSearch = useCallback((e: LocationSearchResponse) => {
        setSearchedLocation(
            getLocationDataStructure(true, e.searchLatitude, e.searchLongitude)
        );
    }, []);

    const onSearchLocationChange = useCallback(() => {
        if (!userLocation.loaded || userLocation.error) return;
        if (
            searchedLocation.mapCenter[0] === location.mapCenter[0] &&
            searchedLocation.mapCenter[1] === location.mapCenter[1]
        )
            return;
        setLocation(searchedLocation);
    }, [userLocation, searchedLocation]);

    const onUserLocationChange = useCallback(() => {
        if (!userLocation.loaded || userLocation.error) return;
        if (
            userLocation.mapCenter[0] === location.mapCenter[0] &&
            userLocation.mapCenter[1] === location.mapCenter[1]
        )
            return;
        if (
            searchedLocation.loaded &&
            searchedLocation.mapCenter[0] === location.mapCenter[0] &&
            searchedLocation.mapCenter[1] === location.mapCenter[1]
        )
            return;
        setLocation(userLocation);
    }, [userLocation, searchedLocation, location]);

    useEffect(() => {
        onSearchLocationChange();
    }, [searchedLocation, onSearchLocationChange]);

    useEffect(() => {
        onUserLocationChange();
    }, [userLocation, onUserLocationChange]);

    return {
        updateShowOnlyFavourites,
        receivePostcodeSearch,
        location,
    };
};
