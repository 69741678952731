import {useState, useEffect} from 'react';

export enum ScreenSize {
    MOBILE = 500,
    SMALL = 768,
    MEDIUM = 992,
    LARGE = 1200,
    XLARGE = 10000,
}

interface ScreenInfo {
    isNarrow: boolean;
    screenType: ScreenSize;
}

const useScreenInfo = (): ScreenInfo => {
    const [output, setOutput] = useState<ScreenInfo>(measureScreen());

    function measureScreen(): ScreenInfo {
        const width = window.innerWidth;
        if (width <= ScreenSize.MOBILE) {
            return {isNarrow: true, screenType: ScreenSize.MOBILE};
        }
        if (width <= ScreenSize.SMALL) {
            return {isNarrow: true, screenType: ScreenSize.SMALL};
        }
        if (width <= ScreenSize.MEDIUM) {
            return {isNarrow: false, screenType: ScreenSize.MEDIUM};
        }
        if (width <= ScreenSize.LARGE) {
            return {isNarrow: false, screenType: ScreenSize.LARGE};
        }
        return {isNarrow: false, screenType: ScreenSize.XLARGE};
    }

    useEffect(() => {
        const handleResize = (): void => {
            setOutput(measureScreen());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return output;
};

export default useScreenInfo;
