import React, {useEffect, useState} from 'react';
import {Tabs, Tab, Button} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {getNameString, reformatDateTime} from '@/actions/general';
import {useAdminService, DownloadService} from '@/actions';
import Loader from '@components/app/Loader';
import {Referral} from '@/generated-client';
import {RootState} from '@/reducers';
import AdminPagTable, {SliceIndexes} from './AdminPaginatedTable';
import {initSliceIndex, formatId} from './PagTableConsts';

enum ReferralStatusTabs {
    OPEN = 'openReferrals',
    PENDING = 'pendingReferrals',
    ACCEPTED = 'acceptedReferrals',
    BOOKED = 'bookedReferrals',
    DNA = 'dnaReferrals',
}

const AdminReferralTable: React.FC = () => {
    const adminService = useAdminService();

    const handelGetAdminReferrals = async () =>
        await adminService.getAdminReferralsList();

    useEffect(() => {
        handelGetAdminReferrals();
    }, []);

    const open = useSelector((state: RootState) => state.admin.referrals.open);
    const pending = useSelector(
        (state: RootState) => state.admin.referrals.pending
    );
    const accepted = useSelector(
        (state: RootState) => state.admin.referrals.accepted
    );
    const booked = useSelector(
        (state: RootState) => state.admin.referrals.booked
    );
    const dna = useSelector((state: RootState) => state.admin.referrals.dna); // TODO: Change to DNA

    const [openReferralsSlice, setOpenReferralsSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [pendingReferralsSlice, setPendingReferralsSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [acceptedReferralsSlice, setAcceptedReferralsSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [bookedReferralsSlice, setBookedReferralsSlice] =
        useState<SliceIndexes>(initSliceIndex);
    const [dnaReferralsSlice, setDnaReferralsSlice] =
        useState<SliceIndexes>(initSliceIndex);

    const tabTitles = {
        [ReferralStatusTabs.OPEN]: 'Open Referrals',
        [ReferralStatusTabs.PENDING]: 'Pending Referrals',
        [ReferralStatusTabs.ACCEPTED]: 'Accepted Referrals',
        [ReferralStatusTabs.BOOKED]: 'Booked Referrals',
        [ReferralStatusTabs.DNA]: 'DNA Referrals',
    };
    const referralLists = {
        [ReferralStatusTabs.OPEN]: open,
        [ReferralStatusTabs.PENDING]: pending,
        [ReferralStatusTabs.ACCEPTED]: accepted,
        [ReferralStatusTabs.BOOKED]: booked,
        [ReferralStatusTabs.DNA]: dna,
    };

    const referralHeaders = {
        [ReferralStatusTabs.OPEN]: [
            'Row: ID',
            'Created On',
            'Px ID',
            'Referrer',
            'Description',
        ],
        [ReferralStatusTabs.PENDING]: [
            'Row',
            'Created On',
            'Px ID',
            'Referrer',
            'Target Clinician',
        ],
        [ReferralStatusTabs.ACCEPTED]: [
            'Row',
            'Created On',
            'Px ID',
            'Referrer',
            'Target Clinician',
        ],
        [ReferralStatusTabs.BOOKED]: [
            'Row',
            'Created On',
            'Px ID',
            'Referrer',
            'Target Clinician',
        ],
        [ReferralStatusTabs.DNA]: [
            'Row',
            'Created On',
            'Px ID',
            'Referrer',
            'Target Clinician',
        ],
    };

    const referralSlices = {
        [ReferralStatusTabs.OPEN]: openReferralsSlice,
        [ReferralStatusTabs.PENDING]: pendingReferralsSlice,
        [ReferralStatusTabs.ACCEPTED]: acceptedReferralsSlice,
        [ReferralStatusTabs.BOOKED]: bookedReferralsSlice,
        [ReferralStatusTabs.DNA]: dnaReferralsSlice,
    };

    const referralSliceUpdaters = {
        [ReferralStatusTabs.OPEN]: setOpenReferralsSlice,
        [ReferralStatusTabs.PENDING]: setPendingReferralsSlice,
        [ReferralStatusTabs.ACCEPTED]: setAcceptedReferralsSlice,
        [ReferralStatusTabs.BOOKED]: setBookedReferralsSlice,
        [ReferralStatusTabs.DNA]: setDnaReferralsSlice,
    };

    const createOpenReferralRows = (referral: Referral, index: number) => (
        <tr key={referral.id}>
            <td>{`${index + 1}: ${formatId(referral.id)}`}</td>
            <td>{reformatDateTime(referral.createdOn)}</td>
            <td>{formatId(referral.patient.id)}</td>
            <td>
                {getNameString(referral.referrer.userProfile)} (
                {referral.referrer.userProfile.jobRole[0].jobTitle})
            </td>
            <td>{referral.mainDescription}</td>
        </tr>
    );

    const createReferralRows = (referral: Referral, index: number) => (
        <tr key={referral.id}>
            <td>{`${index + 1}: ${formatId(referral.id)}`}</td>
            <td>{reformatDateTime(referral.createdOn)}</td>
            <td>{formatId(referral.patient.id)}</td>
            <td>
                {getNameString(referral.referrer.userProfile)} (
                {referral.referrer.userProfile.jobRole[0].jobTitle})
            </td>
            <td>
                {getNameString(referral.targetClinician.userProfile)} (
                {referral.targetClinician.userProfile.jobRole[0].jobTitle})
            </td>
        </tr>
    );

    const createDnaReferralRows = (referral: Referral, index: number) => (
        <tr key={referral.id}>
            <td>{`${index + 1}: ${formatId(referral.id)}`}</td>
            <td>{reformatDateTime(referral.createdOn)}</td>
            <td>{formatId(referral.patient.id)}</td>
            <td>
                {getNameString(referral.referrer.userProfile)} (
                {referral.referrer.userProfile.jobRole[0].jobTitle})
            </td>
            <td>
                {referral.targetClinician
                    ? `${getNameString(referral.targetClinician.userProfile)} (
                    ${referral.targetClinician.userProfile.jobRole[0].jobTitle})`
                    : 'No target clinician'}
            </td>
        </tr>
    );

    const tabCreatreRows = {
        [ReferralStatusTabs.OPEN]: createOpenReferralRows,
        [ReferralStatusTabs.PENDING]: createReferralRows,
        [ReferralStatusTabs.ACCEPTED]: createReferralRows,
        [ReferralStatusTabs.BOOKED]: createReferralRows,
        [ReferralStatusTabs.DNA]: createDnaReferralRows,
    };

    const isLoading = adminService.requestLoading;
    const onExportClick = () => {
        const downloadService = new DownloadService();
        downloadService.downloadAdminReferralsList();
    };
    return (
        <>
            <Button variant="info" className="mb-3" onClick={onExportClick}>
                Download Referrals List
            </Button>
            <Tabs
                defaultActiveKey={ReferralStatusTabs.OPEN}
                id="custom-tabs"
                className="custom-tabs mb-3"
            >
                {Object.values(ReferralStatusTabs).map((tabKey) => (
                    <Tab
                        key={tabKey}
                        eventKey={tabKey}
                        title={tabTitles[tabKey]}
                        className="custom-tabs"
                    >
                        {isLoading ? (
                            <>
                                <div className="gap mt-5" />
                                <Loader text="Loading admin referrals list..." />
                            </>
                        ) : (
                            <AdminPagTable
                                headers={referralHeaders[tabKey]}
                                items={referralLists[tabKey]}
                                createItemRows={tabCreatreRows[tabKey]}
                                sliceIndex={referralSlices[tabKey]}
                                updateSlice={referralSliceUpdaters[tabKey]}
                                emptyTableMessage="No referrals to display at the moment"
                            />
                        )}
                    </Tab>
                ))}
            </Tabs>
        </>
    );
};

export default AdminReferralTable;
