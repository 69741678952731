import React from 'react';

const DelegateVerificationError: React.FC = () => {
    return (
        <>
            <div
                className="text-center"
                style={{
                    width: '700px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <h1>Registration failed</h1>
            </div>
            <div
                style={{
                    width: '700px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <p className="medr-text py-2">
                    There has been an error verifying your MedR user account.
                </p>
                <p className="medr-text py-1">
                    Please email the{' '}
                    <u>
                        <a href="mailto:register@medr.co.uk">
                            MedR registration team
                        </a>
                    </u>{' '}
                    to complete registration.
                </p>
            </div>
        </>
    );
};

export default DelegateVerificationError;
