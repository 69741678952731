/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface FavouriteClinician
 */
export interface FavouriteClinician {
    /**
     * 
     * @type {number}
     * @memberof FavouriteClinician
     */
    readonly id: number;
    /**
     * 
     * @type {UserProfile}
     * @memberof FavouriteClinician
     */
    readonly userProfile: UserProfile;
    /**
     * 
     * @type {UserProfile}
     * @memberof FavouriteClinician
     */
    readonly favouriteUserProfile: UserProfile;
}

/**
 * Check if a given object implements the FavouriteClinician interface.
 */
export function instanceOfFavouriteClinician(value: object): value is FavouriteClinician {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userProfile' in value) || value['userProfile'] === undefined) return false;
    if (!('favouriteUserProfile' in value) || value['favouriteUserProfile'] === undefined) return false;
    return true;
}

export function FavouriteClinicianFromJSON(json: any): FavouriteClinician {
    return FavouriteClinicianFromJSONTyped(json, false);
}

export function FavouriteClinicianFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavouriteClinician {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userProfile': UserProfileFromJSON(json['userProfile']),
        'favouriteUserProfile': UserProfileFromJSON(json['favouriteUserProfile']),
    };
}

export function FavouriteClinicianToJSON(json: any): FavouriteClinician {
    return FavouriteClinicianToJSONTyped(json, false);
}

export function FavouriteClinicianToJSONTyped(value?: Omit<FavouriteClinician, 'id'|'userProfile'|'favouriteUserProfile'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
    };
}

