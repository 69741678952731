/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressInputForm } from './AddressInputForm';
import {
    AddressInputFormFromJSON,
    AddressInputFormFromJSONTyped,
    AddressInputFormToJSON,
    AddressInputFormToJSONTyped,
} from './AddressInputForm';

/**
 * 
 * @export
 * @interface ClinicianProfileCreateRequest
 */
export interface ClinicianProfileCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClinicianProfileCreateRequest
     */
    clinicName: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicianProfileCreateRequest
     */
    centreName?: string;
    /**
     * 
     * @type {AddressInputForm}
     * @memberof ClinicianProfileCreateRequest
     */
    address: AddressInputForm;
}

/**
 * Check if a given object implements the ClinicianProfileCreateRequest interface.
 */
export function instanceOfClinicianProfileCreateRequest(value: object): value is ClinicianProfileCreateRequest {
    if (!('clinicName' in value) || value['clinicName'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    return true;
}

export function ClinicianProfileCreateRequestFromJSON(json: any): ClinicianProfileCreateRequest {
    return ClinicianProfileCreateRequestFromJSONTyped(json, false);
}

export function ClinicianProfileCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianProfileCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clinicName': json['clinicName'],
        'centreName': json['centreName'] == null ? undefined : json['centreName'],
        'address': AddressInputFormFromJSON(json['address']),
    };
}

export function ClinicianProfileCreateRequestToJSON(json: any): ClinicianProfileCreateRequest {
    return ClinicianProfileCreateRequestToJSONTyped(json, false);
}

export function ClinicianProfileCreateRequestToJSONTyped(value?: ClinicianProfileCreateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinicName': value['clinicName'],
        'centreName': value['centreName'],
        'address': AddressInputFormToJSON(value['address']),
    };
}

