/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatientReferralsList } from './PatientReferralsList';
import {
    PatientReferralsListFromJSON,
    PatientReferralsListFromJSONTyped,
    PatientReferralsListToJSON,
    PatientReferralsListToJSONTyped,
} from './PatientReferralsList';

/**
 * 
 * @export
 * @interface ReferralsListForPatientResponse
 */
export interface ReferralsListForPatientResponse {
    /**
     * 
     * @type {PatientReferralsList}
     * @memberof ReferralsListForPatientResponse
     */
    currentPatientReferrals: PatientReferralsList;
}

/**
 * Check if a given object implements the ReferralsListForPatientResponse interface.
 */
export function instanceOfReferralsListForPatientResponse(value: object): value is ReferralsListForPatientResponse {
    if (!('currentPatientReferrals' in value) || value['currentPatientReferrals'] === undefined) return false;
    return true;
}

export function ReferralsListForPatientResponseFromJSON(json: any): ReferralsListForPatientResponse {
    return ReferralsListForPatientResponseFromJSONTyped(json, false);
}

export function ReferralsListForPatientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralsListForPatientResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'currentPatientReferrals': PatientReferralsListFromJSON(json['currentPatientReferrals']),
    };
}

export function ReferralsListForPatientResponseToJSON(json: any): ReferralsListForPatientResponse {
    return ReferralsListForPatientResponseToJSONTyped(json, false);
}

export function ReferralsListForPatientResponseToJSONTyped(value?: ReferralsListForPatientResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'currentPatientReferrals': PatientReferralsListToJSON(value['currentPatientReferrals']),
    };
}

