/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InsuranceAgreement } from './InsuranceAgreement';
import {
    InsuranceAgreementFromJSON,
    InsuranceAgreementFromJSONTyped,
    InsuranceAgreementToJSON,
    InsuranceAgreementToJSONTyped,
} from './InsuranceAgreement';

/**
 * 
 * @export
 * @interface InsurerGroups
 */
export interface InsurerGroups {
    /**
     * 
     * @type {Array<InsuranceAgreement>}
     * @memberof InsurerGroups
     */
    insurers: Array<InsuranceAgreement>;
    /**
     * 
     * @type {Array<InsuranceAgreement>}
     * @memberof InsurerGroups
     */
    unverifiedInsurers: Array<InsuranceAgreement>;
}

/**
 * Check if a given object implements the InsurerGroups interface.
 */
export function instanceOfInsurerGroups(value: object): value is InsurerGroups {
    if (!('insurers' in value) || value['insurers'] === undefined) return false;
    if (!('unverifiedInsurers' in value) || value['unverifiedInsurers'] === undefined) return false;
    return true;
}

export function InsurerGroupsFromJSON(json: any): InsurerGroups {
    return InsurerGroupsFromJSONTyped(json, false);
}

export function InsurerGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurerGroups {
    if (json == null) {
        return json;
    }
    return {
        
        'insurers': ((json['insurers'] as Array<any>).map(InsuranceAgreementFromJSON)),
        'unverifiedInsurers': ((json['unverifiedInsurers'] as Array<any>).map(InsuranceAgreementFromJSON)),
    };
}

export function InsurerGroupsToJSON(json: any): InsurerGroups {
    return InsurerGroupsToJSONTyped(json, false);
}

export function InsurerGroupsToJSONTyped(value?: InsurerGroups | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'insurers': ((value['insurers'] as Array<any>).map(InsuranceAgreementToJSON)),
        'unverifiedInsurers': ((value['unverifiedInsurers'] as Array<any>).map(InsuranceAgreementToJSON)),
    };
}

