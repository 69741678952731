/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DelegateRegistrationInfo } from './DelegateRegistrationInfo';
import {
    DelegateRegistrationInfoFromJSON,
    DelegateRegistrationInfoFromJSONTyped,
    DelegateRegistrationInfoToJSON,
    DelegateRegistrationInfoToJSONTyped,
} from './DelegateRegistrationInfo';

/**
 * 
 * @export
 * @interface DelegateUserRegistrationInfoResponse
 */
export interface DelegateUserRegistrationInfoResponse {
    /**
     * 
     * @type {DelegateRegistrationInfo}
     * @memberof DelegateUserRegistrationInfoResponse
     */
    delegateRegistration: DelegateRegistrationInfo;
}

/**
 * Check if a given object implements the DelegateUserRegistrationInfoResponse interface.
 */
export function instanceOfDelegateUserRegistrationInfoResponse(value: object): value is DelegateUserRegistrationInfoResponse {
    if (!('delegateRegistration' in value) || value['delegateRegistration'] === undefined) return false;
    return true;
}

export function DelegateUserRegistrationInfoResponseFromJSON(json: any): DelegateUserRegistrationInfoResponse {
    return DelegateUserRegistrationInfoResponseFromJSONTyped(json, false);
}

export function DelegateUserRegistrationInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegateUserRegistrationInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'delegateRegistration': DelegateRegistrationInfoFromJSON(json['delegateRegistration']),
    };
}

export function DelegateUserRegistrationInfoResponseToJSON(json: any): DelegateUserRegistrationInfoResponse {
    return DelegateUserRegistrationInfoResponseToJSONTyped(json, false);
}

export function DelegateUserRegistrationInfoResponseToJSONTyped(value?: DelegateUserRegistrationInfoResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'delegateRegistration': DelegateRegistrationInfoToJSON(value['delegateRegistration']),
    };
}

