import {Referral} from '@/generated-client';

export enum ReferralOutboundTabs {
    PENDING = 'outboundPending',
    ACCEPTED = 'outboundAccepted',
    BOOKED = 'outboundBooked',
    DNA = 'outboundDna',
}

export const tabLabels: Record<ReferralOutboundTabs, string> = {
    [ReferralOutboundTabs.PENDING]: 'Pending',
    [ReferralOutboundTabs.ACCEPTED]: 'Accepted',
    [ReferralOutboundTabs.BOOKED]: 'Booked',
    [ReferralOutboundTabs.DNA]: 'DNA',
};

export const tabOptions = Object.entries(tabLabels).map(([value, label]) => ({
    value,
    label,
}));

export interface useReferralOutboundScreenReturn {
    selectedTab: ReferralOutboundTabs;
    setSelectedTab: (tab: ReferralOutboundTabs) => void;
    outboundWaiting: Referral[];
    outboundAccepted: Referral[];
    outboundBooked: Referral[];
    outboundDna: Referral[];
}
