import React from 'react';
import {Row, Col} from 'react-bootstrap';
import UserProfileCard from '../user/UserProfileCard';
import Loader from '../app/Loader';
import {ClinicianRoleProfile} from '@/generated-client';

interface UserClinicianProfilesProps {
    crps: ClinicianRoleProfile[];
    isUser?: boolean;
}

const UserClinicianProfiles: React.FC<UserClinicianProfilesProps> = ({
    crps,
}) => {
    if (!crps || crps.length === 0) {
        return <Loader text="Loading clinician profiles..." />;
    }
    const userProfileId = crps[0].userProfile.id;
    return (
        <div>
            <Row className="text-center py-1" key={`upid${userProfileId}`}>
                <Col>
                    <UserProfileCard userProfileId={userProfileId} />
                </Col>
            </Row>
        </div>
    );
};

export default UserClinicianProfiles;
