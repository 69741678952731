import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {usePatientService} from '@/actions';
import {getPatientNameString, reformatDate} from '@/actions/general';
import {RootState} from '@/reducers';
import {Patient} from '@/generated-client';

interface ViewOption {
    label: string;
    value: string;
}

interface UsePatientSearchBarReturn {
    patients: Patient[];
    patientsOptions: ViewOption[];
    selectPatientByOption: (opt: ViewOption) => void;
    selectedPatient: ViewOption;
    currentPatient: Patient;
}

const usePatientSearchBar = (
    currentPatientId: number,
    sendPxId: (id: number) => void
): UsePatientSearchBarReturn => {
    const patientService = usePatientService();

    const handleGetPatients = async () =>
        await patientService.getClinicianPatientList();
    const handleGetPatientById = async (pxId: number) =>
        await patientService.getPatientById(pxId);

    useEffect(() => {
        handleGetPatients();
        return () => {};
    }, []);

    const addedPatients = useSelector(
        (state: RootState) => state.patients.addedPatients
    );
    const referredPatients = useSelector(
        (state: RootState) => state.patients.referredPatients
    );
    const acceptedPatients = useSelector(
        (state: RootState) => state.patients.acceptedPatients
    );
    const completedPatients = useSelector(
        (state: RootState) => state.patients.completedPatients
    );

    const currentPatient = useSelector(
        (state: RootState) => state.patients.currentPatient
    );
    const [patients, setPatients] = useState([]); // [activePatients, pastPatients, acceptedPatients]
    const [patientsOptions, setPatientsOptions] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [inputPxId, setInputPxId] = useState(null);

    const getPxOption = (px: Patient) => ({
        label: (
            <>
                <strong style={{fontWeight: 'bold'}}>
                    {getPatientNameString(px)}
                </strong>{' '}
                - DOB:{reformatDate(px.dob)} - {px.address.postcode}
            </>
        ),
        value: `${getPatientNameString(px)} - DOB:${reformatDate(px.dob)} - ${
            px.address.postcode
        }`,
        pid: px.id,
    });

    useEffect(() => {
        if (
            !addedPatients &&
            !referredPatients &&
            !acceptedPatients &&
            !completedPatients
        )
            return;
        const pxList = [
            ...addedPatients,
            ...referredPatients,
            ...acceptedPatients,
            ...completedPatients,
        ];
        const optsList = pxList.map((px) => getPxOption(px));
        setPatients(pxList);
        setPatientsOptions(optsList);
    }, [addedPatients, referredPatients, acceptedPatients, completedPatients]);

    const selectPatientByOption = (opt) => {
        const px = patients.find((patient) => patient.id === opt.pid);
        if (!px) return;
        setSelectedPatient(opt);
        if (currentPatient && currentPatient.id === px.id) return;
        handleGetPatientById(px.id);
        if (sendPxId) {
            sendPxId(px.id);
        }
        // setCurrentPatient(px);
    };

    const selectPatientByInputId = () => {
        if (!inputPxId) return;
        if (inputPxId === 0) {
            setSelectedPatient(null);
            return;
        }
        if (currentPatient && currentPatient.id === inputPxId) return;
        handleGetPatientById(currentPatient.id);
    };

    useEffect(() => {
        if (!currentPatientId || currentPatientId === 0) {
            patientService.resetCurrentPatient();
        }
        setInputPxId(currentPatientId);
    }, [currentPatientId]);
    useEffect(() => {
        selectPatientByInputId();
    }, [inputPxId]);

    useEffect(() => {
        if (!currentPatient) return;
        if (!currentPatient.id || currentPatient.id === 0) {
            setSelectedPatient(null);
            return;
        }

        setSelectedPatient(getPxOption(currentPatient));
    }, [currentPatient]);

    return {
        patients,
        patientsOptions,
        selectPatientByOption,
        selectedPatient,
        currentPatient,
    };
};

export default usePatientSearchBar;
