/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LastLoggedIn } from './LastLoggedIn';
import {
    LastLoggedInFromJSON,
    LastLoggedInFromJSONTyped,
    LastLoggedInToJSON,
    LastLoggedInToJSONTyped,
} from './LastLoggedIn';
import type { ReferralsSummary } from './ReferralsSummary';
import {
    ReferralsSummaryFromJSON,
    ReferralsSummaryFromJSONTyped,
    ReferralsSummaryToJSON,
    ReferralsSummaryToJSONTyped,
} from './ReferralsSummary';

/**
 * 
 * @export
 * @interface PlatformStats
 */
export interface PlatformStats {
    /**
     * 
     * @type {LastLoggedIn}
     * @memberof PlatformStats
     */
    lastLoggedIn: LastLoggedIn;
    /**
     * 
     * @type {ReferralsSummary}
     * @memberof PlatformStats
     */
    referrals: ReferralsSummary;
}

/**
 * Check if a given object implements the PlatformStats interface.
 */
export function instanceOfPlatformStats(value: object): value is PlatformStats {
    if (!('lastLoggedIn' in value) || value['lastLoggedIn'] === undefined) return false;
    if (!('referrals' in value) || value['referrals'] === undefined) return false;
    return true;
}

export function PlatformStatsFromJSON(json: any): PlatformStats {
    return PlatformStatsFromJSONTyped(json, false);
}

export function PlatformStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformStats {
    if (json == null) {
        return json;
    }
    return {
        
        'lastLoggedIn': LastLoggedInFromJSON(json['last_logged_in']),
        'referrals': ReferralsSummaryFromJSON(json['referrals']),
    };
}

export function PlatformStatsToJSON(json: any): PlatformStats {
    return PlatformStatsToJSONTyped(json, false);
}

export function PlatformStatsToJSONTyped(value?: PlatformStats | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'last_logged_in': LastLoggedInToJSON(value['lastLoggedIn']),
        'referrals': ReferralsSummaryToJSON(value['referrals']),
    };
}

