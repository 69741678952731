import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import PatientEntryForm from '@/components/patient/PatientEntryForm';
import {usePatientService} from '@/actions';
import {Patient} from '@/generated-client';

interface RequestResult {
    success: boolean;
    data?: Patient;
}

const PatientEditScreen: React.FC = () => {
    const patientService = usePatientService();
    const {patientId} = useParams<{patientId: string}>();
    const patientIdInt = parseInt(patientId, 10);

    const handleSuccessfulRequest = (results: RequestResult) => {
        if (!results.success) return;
        patientService.goToPatientsList();
    };

    return (
        <div
            className="medr-layout text-center newpatient-page"
            style={{maxWidth: '700px', margin: '0 auto'}}
        >
            <Row className="medr-layout text-center">
                <Col>
                    <h4>Update Patient Information</h4>
                </Col>
            </Row>

            <PatientEntryForm
                patientId={patientIdInt}
                isSuccessful={handleSuccessfulRequest}
            />
        </div>
    );
};

export default PatientEditScreen;
