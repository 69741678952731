/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { TitleEnum } from './TitleEnum';
import {
    instanceOfTitleEnum,
    TitleEnumFromJSON,
    TitleEnumFromJSONTyped,
    TitleEnumToJSON,
} from './TitleEnum';

/**
 * @type PatientTitle
 * 
 * @export
 */
export type PatientTitle = BlankEnum | TitleEnum;

export function PatientTitleFromJSON(json: any): PatientTitle {
    return PatientTitleFromJSONTyped(json, false);
}

export function PatientTitleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientTitle {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfTitleEnum(json)) {
        return TitleEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function PatientTitleToJSON(json: any): any {
    return PatientTitleToJSONTyped(json, false);
}

export function PatientTitleToJSONTyped(value?: PatientTitle | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfTitleEnum(value)) {
        return TitleEnumToJSON(value as TitleEnum);
    }

    return {};
}

