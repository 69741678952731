import React, {useState, useCallback} from 'react';
import {Form, Button, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useAuthService} from '@/actions';
import {RootState} from '@/reducers';
import {MedrPages} from '@/constants/variables';
import {
    EmailFormField,
    EmailFieldChangeValues,
} from '@/components/app/parts/FormFields';

interface ResetPasswordFormData {
    email: string;
    isValid: boolean;
}

const SendResetPasswordScreen: React.FC = () => {
    const navigate = useNavigate();
    const authService = useAuthService();

    // Check if user is authenticated
    const isAuthenticated = useSelector(
        (state: RootState) => state.auth.isAuthenticated
    );
    if (isAuthenticated) {
        navigate(MedrPages.DASHBOARD.toString());
        return null; // Return null to prevent rendering the rest of the component
    }

    // Form state
    const [unsuccesfulReset, setUnsuccesfulReset] = useState<boolean>(false);
    const [formData, setFormData] = useState<ResetPasswordFormData>({
        email: '',
        isValid: true,
    });

    const {email} = formData;

    const updateEmail = useCallback((values: EmailFieldChangeValues) => {
        setFormData((prevData) => ({
            ...prevData,
            email: values.email,
            isValid: values.isValid,
        }));
    }, []);
    // Handle form submission
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const resetSuccess = await authService.resetPassword(email);
        if (resetSuccess) {
            navigate(MedrPages.PASSWORD_SEND_RESET_SUCCESS.toString());
        } else {
            setUnsuccesfulReset(true);
        }
    };

    return (
        <div
            className="medr-layout text-center reset-password-page"
            style={{
                maxWidth: '380px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <h1>Reset password</h1>
            <Form onSubmit={onSubmit}>
                <div className="text-left py-2">
                    <EmailFormField
                        emailKey="email"
                        email={email}
                        changeValues={updateEmail}
                        emailLabel="Email address"
                    />
                </div>
                {unsuccesfulReset && (
                    <div className="text-danger">
                        Error reseting password. Please check the email entered
                        and try again.
                    </div>
                )}
                <Row className="mt-3">
                    <div className="col-12">
                        <Button
                            type="submit"
                            variant="primary"
                            className="sel-button w80"
                        >
                            Send password reset email
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    );
};

export default SendResetPasswordScreen;
