import React from 'react';
import {Row, Col} from 'react-bootstrap';
import DelegateVerificationForm from '@/components/user/delegates/DelegateVerificationForm';

const DelegateVerification: React.FC = () => {
    return (
        <>
            <Row>
                <Col className="text-center">
                    <h1>Complete delegate user registration</h1>
                </Col>
            </Row>
            <DelegateVerificationForm />
        </>
    );
};

export default DelegateVerification;
