import {Dispatch} from 'redux';
import {NavigateFunction} from 'react-router-dom';
import {
    ReferralsApi,
    ReferralToggleBookedRequest,
    ReferralCreateRequest,
    ReferralToggleDnaRequest,
} from '@/generated-client';
import * as RED from '@reducers/referralReducer';
import {MedrService} from './authService';

export class ReferralService extends MedrService {
    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
    }

    async getClinicianReferralsList(): Promise<boolean> {
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsListRetrieve(),
            RED.dispatchGetClinicianReferralsListRequest,
            RED.dispatchGetClinicianReferralsListSuccess,
            RED.dispatchGetClinicianReferralsListFail
        );
    }

    async getClinicianReferralsListInbound(): Promise<boolean> {
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsListInboundRetrieve(),
            RED.dispatchGetClinicianReferralsListInboundRequest,
            RED.dispatchGetClinicianReferralsListInboundSuccess,
            RED.dispatchGetClinicianReferralsListInboundFail
        );
    }

    async getClinicianReferralsListOutbound(): Promise<boolean> {
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsListOutboundRetrieve(),
            RED.dispatchGetClinicianReferralsListOutboundRequest,
            RED.dispatchGetClinicianReferralsListOutboundSuccess,
            RED.dispatchGetClinicianReferralsListOutboundFail
        );
    }

    async createReferral(inputData: ReferralCreateRequest): Promise<boolean> {
        const payload = {referralCreateRequest: inputData};
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsCreateCreate(payload),
            RED.dispatchCreateReferralRequest,
            RED.dispatchCreateReferralSuccess,
            RED.dispatchCreateReferralFail
        );
    }

    async acceptReferral(refId: number): Promise<boolean> {
        const payload = {refId};
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsAcceptCreate(payload),
            RED.dispatchReferralAcceptRequest,
            RED.dispatchReferralAcceptSuccess,
            RED.dispatchReferralAcceptFail
        );
    }

    async rejectReferral(refId: number): Promise<boolean> {
        const payload = {refId};
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsRejectCreate(payload),
            RED.dispatchReferralRejectRequest,
            RED.dispatchReferralRejectSuccess,
            RED.dispatchReferralRejectFail
        );
    }

    async cancelReferral(refId: number): Promise<boolean> {
        const payload = {refId};
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsDeleteDestroy(payload),
            RED.dispatchCancelReferralRequest,
            RED.dispatchCancelReferralSuccess,
            RED.dispatchCancelReferralFail
        );
    }

    async toggleCompleteReferral(
        refId: number,
        inputData: ReferralToggleBookedRequest
    ): Promise<boolean> {
        const payload = {refId, referralToggleBookedRequest: inputData};
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsCompleteCreate(payload),
            RED.dispatchToggleCompleteReferralRequest,
            RED.dispatchToggleCompleteReferralSuccess,
            RED.dispatchToggleCompleteReferralFail
        );
    }

    async toggleDnaReferral(
        refId: number,
        inputData: ReferralToggleDnaRequest
    ): Promise<boolean> {
        const payload = {refId, referralToggleDnaRequest: inputData};
        const api = new ReferralsApi(this.configuration);
        return this.executeApiCall(
            () => api.referralsDnaCreate(payload),
            RED.dispatchToggleDnaReferralRequest,
            RED.dispatchToggleDnaReferralSuccess,
            RED.dispatchToggleDnaReferralFail
        );
    }
}
