/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';

/**
 * 
 * @export
 * @interface ClinicianProfileDeactivateResponse
 */
export interface ClinicianProfileDeactivateResponse {
    /**
     * 
     * @type {Message}
     * @memberof ClinicianProfileDeactivateResponse
     */
    message: Message;
}

/**
 * Check if a given object implements the ClinicianProfileDeactivateResponse interface.
 */
export function instanceOfClinicianProfileDeactivateResponse(value: object): value is ClinicianProfileDeactivateResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ClinicianProfileDeactivateResponseFromJSON(json: any): ClinicianProfileDeactivateResponse {
    return ClinicianProfileDeactivateResponseFromJSONTyped(json, false);
}

export function ClinicianProfileDeactivateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicianProfileDeactivateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
    };
}

export function ClinicianProfileDeactivateResponseToJSON(json: any): ClinicianProfileDeactivateResponse {
    return ClinicianProfileDeactivateResponseToJSONTyped(json, false);
}

export function ClinicianProfileDeactivateResponseToJSONTyped(value?: ClinicianProfileDeactivateResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': MessageToJSON(value['message']),
    };
}

