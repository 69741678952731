import React, {useRef, useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {Card} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import ClinicianCard from './ClinicianCard';
import Loader from '@components/app/Loader';
import {useMapViewContext} from './MapViewContext';
import {RootState} from '@/reducers';

const ClinicianPanel: React.FC = () => {
    const {clinicians, hoveredClinicianId} = useMapViewContext(); // eslint-disable-line @typescript-eslint/no-unused-vars

    const listRef = useRef<HTMLDivElement | null>(null); // eslint-disable-line @typescript-eslint/no-unused-vars
    const cardRefs = useRef<Record<number, HTMLDivElement | null>>({});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setCardRef = (id: number) => (el: HTMLDivElement | null) => {
        console.log('Param id=', id);
        if (el) {
            cardRefs.current[id] = el;
            console.log(`Ref set for clinician== ${id}`);
            console.log(cardRefs);
        } else {
            delete cardRefs.current[id];
        }

        console.log(
            'All stored cardRefs IDs:',
            Object.keys(cardRefs.current).map(Number),
            cardRefs.current
        );
    };

    const isLoading = useSelector(
        (state: RootState) => state.clinicians.loadingClinicians
    );

    const noClinicianCard = (
        <Card
            className="mb-2 rounded d-flex flex-fill"
            style={{padding: '1rem'}}
        >
            <Card.Title>No Clinicians Available</Card.Title>
            <Card.Subtitle className="text-muted">
                Unfortunately, we couldn't find any clinicians that fit your
                search. Please modify your search criteria and try again.
            </Card.Subtitle>
        </Card>
    );

    const cards =
        clinicians &&
        clinicians.map((clinician) => {
            return (
                <ClinicianCard
                    key={clinician.id}
                    clinician={clinician}
                    refCallback={() => {}}
                />
            );
        });

    return (
        <div style={{overflowY: 'auto'}}>
            {isLoading && <Loader text="Finding clinicians..." />}
            {!isLoading && clinicians.length === 0 && noClinicianCard}
            {!isLoading && cards}
        </div>
    );
};

export default ClinicianPanel;
