/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
    ReferralToJSONTyped,
} from './Referral';

/**
 * 
 * @export
 * @interface ReferralLists
 */
export interface ReferralLists {
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralLists
     */
    open: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralLists
     */
    pending: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralLists
     */
    accepted: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralLists
     */
    booked: Array<Referral>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof ReferralLists
     */
    dna: Array<Referral>;
}

/**
 * Check if a given object implements the ReferralLists interface.
 */
export function instanceOfReferralLists(value: object): value is ReferralLists {
    if (!('open' in value) || value['open'] === undefined) return false;
    if (!('pending' in value) || value['pending'] === undefined) return false;
    if (!('accepted' in value) || value['accepted'] === undefined) return false;
    if (!('booked' in value) || value['booked'] === undefined) return false;
    if (!('dna' in value) || value['dna'] === undefined) return false;
    return true;
}

export function ReferralListsFromJSON(json: any): ReferralLists {
    return ReferralListsFromJSONTyped(json, false);
}

export function ReferralListsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralLists {
    if (json == null) {
        return json;
    }
    return {
        
        'open': ((json['open'] as Array<any>).map(ReferralFromJSON)),
        'pending': ((json['pending'] as Array<any>).map(ReferralFromJSON)),
        'accepted': ((json['accepted'] as Array<any>).map(ReferralFromJSON)),
        'booked': ((json['booked'] as Array<any>).map(ReferralFromJSON)),
        'dna': ((json['dna'] as Array<any>).map(ReferralFromJSON)),
    };
}

export function ReferralListsToJSON(json: any): ReferralLists {
    return ReferralListsToJSONTyped(json, false);
}

export function ReferralListsToJSONTyped(value?: ReferralLists | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'open': ((value['open'] as Array<any>).map(ReferralToJSON)),
        'pending': ((value['pending'] as Array<any>).map(ReferralToJSON)),
        'accepted': ((value['accepted'] as Array<any>).map(ReferralToJSON)),
        'booked': ((value['booked'] as Array<any>).map(ReferralToJSON)),
        'dna': ((value['dna'] as Array<any>).map(ReferralToJSON)),
    };
}

