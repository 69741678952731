/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';

/**
 * 
 * @export
 * @interface RegisterUserResponse
 */
export interface RegisterUserResponse {
    /**
     * 
     * @type {Message}
     * @memberof RegisterUserResponse
     */
    message: Message;
}

/**
 * Check if a given object implements the RegisterUserResponse interface.
 */
export function instanceOfRegisterUserResponse(value: object): value is RegisterUserResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function RegisterUserResponseFromJSON(json: any): RegisterUserResponse {
    return RegisterUserResponseFromJSONTyped(json, false);
}

export function RegisterUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
    };
}

export function RegisterUserResponseToJSON(json: any): RegisterUserResponse {
    return RegisterUserResponseToJSONTyped(json, false);
}

export function RegisterUserResponseToJSONTyped(value?: RegisterUserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': MessageToJSON(value['message']),
    };
}

