import React from 'react';
import {Table} from 'react-bootstrap';
import PaginationBar from '../app/PaginationBar';
import {
    Referral,
    InsuranceAgreement,
    Patient,
    UserProfile,
    ClinicianRoleProfile,
} from '@/generated-client';
import {windowSize} from './PagTableConsts';

export interface SliceIndexes {
    start: number;
    end: number;
}

export type TableItem =
    | Referral
    | InsuranceAgreement
    | Patient
    | UserProfile
    | ClinicianRoleProfile;

interface AdminPagTableProps {
    headers: string[];
    items: TableItem[];
    createItemRows: (item: TableItem, index: number) => JSX.Element;
    sliceIndex: SliceIndexes;
    updateSlice: (slice: SliceIndexes) => void;
    emptyTableMessage: string;
}

const AdminPagTable: React.FC<AdminPagTableProps> = ({
    headers,
    items,
    createItemRows,
    sliceIndex,
    updateSlice,
    emptyTableMessage,
}) => {
    const noReferralsDisplay = (
        <div className="center-box-parent">
            <div className="center-box-child">
                <h4 className="medr-text-inv">{emptyTableMessage}</h4>
            </div>
        </div>
    );
    const itemsLength = !items ? 0 : items.length;
    if (itemsLength === 0) {
        return noReferralsDisplay;
    }

    const itemsSlicedList = items.slice(sliceIndex.start, sliceIndex.end);
    const updateSliceIndices = (startInd: number, endInd: number) => {
        if (startInd === sliceIndex.start && endInd === sliceIndex.end) {
            return;
        }
        updateSlice({start: startInd, end: endInd});
    };
    return (
        <>
            <Table striped bordered hover responsive className="table-sm">
                <thead>
                    {headers.map((header) => (
                        <th key={header}>{header}</th>
                    ))}
                </thead>
                <tbody>
                    {itemsSlicedList.map((item, index) =>
                        createItemRows(item, index)
                    )}
                </tbody>
            </Table>
            {itemsLength > windowSize && (
                <div className="mb-3">
                    <PaginationBar
                        arrayLength={itemsLength}
                        setSliceValues={updateSliceIndices}
                        windowSize={windowSize}
                    />
                </div>
            )}
        </>
    );
};

export default AdminPagTable;
