/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicianRoleProfile } from './ClinicianRoleProfile';
import {
    ClinicianRoleProfileFromJSON,
    ClinicianRoleProfileFromJSONTyped,
    ClinicianRoleProfileToJSON,
    ClinicianRoleProfileToJSONTyped,
} from './ClinicianRoleProfile';
import type { MapAddress } from './MapAddress';
import {
    MapAddressFromJSON,
    MapAddressFromJSONTyped,
    MapAddressToJSON,
    MapAddressToJSONTyped,
} from './MapAddress';
import type { TargetCoordinartes } from './TargetCoordinartes';
import {
    TargetCoordinartesFromJSON,
    TargetCoordinartesFromJSONTyped,
    TargetCoordinartesToJSON,
    TargetCoordinartesToJSONTyped,
} from './TargetCoordinartes';

/**
 * 
 * @export
 * @interface ListCliniciansResponse
 */
export interface ListCliniciansResponse {
    /**
     * 
     * @type {TargetCoordinartes}
     * @memberof ListCliniciansResponse
     */
    targetCoords: TargetCoordinartes;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof ListCliniciansResponse
     */
    clinicianList: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof ListCliniciansResponse
     */
    otherCliniciansList: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof ListCliniciansResponse
     */
    favouriteCliniciansList: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Array<MapAddress>}
     * @memberof ListCliniciansResponse
     */
    addressesList: Array<MapAddress>;
    /**
     * 
     * @type {Array<MapAddress>}
     * @memberof ListCliniciansResponse
     */
    favouriteAddressesList: Array<MapAddress>;
    /**
     * 
     * @type {Array<MapAddress>}
     * @memberof ListCliniciansResponse
     */
    otherAddressesList: Array<MapAddress>;
}

/**
 * Check if a given object implements the ListCliniciansResponse interface.
 */
export function instanceOfListCliniciansResponse(value: object): value is ListCliniciansResponse {
    if (!('targetCoords' in value) || value['targetCoords'] === undefined) return false;
    if (!('clinicianList' in value) || value['clinicianList'] === undefined) return false;
    if (!('otherCliniciansList' in value) || value['otherCliniciansList'] === undefined) return false;
    if (!('favouriteCliniciansList' in value) || value['favouriteCliniciansList'] === undefined) return false;
    if (!('addressesList' in value) || value['addressesList'] === undefined) return false;
    if (!('favouriteAddressesList' in value) || value['favouriteAddressesList'] === undefined) return false;
    if (!('otherAddressesList' in value) || value['otherAddressesList'] === undefined) return false;
    return true;
}

export function ListCliniciansResponseFromJSON(json: any): ListCliniciansResponse {
    return ListCliniciansResponseFromJSONTyped(json, false);
}

export function ListCliniciansResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCliniciansResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'targetCoords': TargetCoordinartesFromJSON(json['targetCoords']),
        'clinicianList': ((json['clinicianList'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'otherCliniciansList': ((json['otherCliniciansList'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'favouriteCliniciansList': ((json['favouriteCliniciansList'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'addressesList': ((json['addressesList'] as Array<any>).map(MapAddressFromJSON)),
        'favouriteAddressesList': ((json['favouriteAddressesList'] as Array<any>).map(MapAddressFromJSON)),
        'otherAddressesList': ((json['otherAddressesList'] as Array<any>).map(MapAddressFromJSON)),
    };
}

export function ListCliniciansResponseToJSON(json: any): ListCliniciansResponse {
    return ListCliniciansResponseToJSONTyped(json, false);
}

export function ListCliniciansResponseToJSONTyped(value?: ListCliniciansResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'targetCoords': TargetCoordinartesToJSON(value['targetCoords']),
        'clinicianList': ((value['clinicianList'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'otherCliniciansList': ((value['otherCliniciansList'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'favouriteCliniciansList': ((value['favouriteCliniciansList'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'addressesList': ((value['addressesList'] as Array<any>).map(MapAddressToJSON)),
        'favouriteAddressesList': ((value['favouriteAddressesList'] as Array<any>).map(MapAddressToJSON)),
        'otherAddressesList': ((value['otherAddressesList'] as Array<any>).map(MapAddressToJSON)),
    };
}

