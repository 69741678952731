import React, {useState} from 'react';
import {Card} from 'react-bootstrap';

interface FAQItem {
    id: number;
    question: string;
    answer: React.ReactNode;
    expanded: boolean;
}

interface FAQProps {
    faq: FAQItem;
    toggleFAQ: (id: number, open: boolean) => void;
}

const answerClassName = 'text-left text-muted mt-2';

const faqs: Omit<FAQItem, 'id' | 'expanded'>[] = [
    {
        question: 'What is MedR?',
        answer: (
            <>
                MedR is a referral solution application that allows healthcare
                providers to refer patients to other healthcare providers.
            </>
        ),
    },
    {
        question: 'How do I sign up for MedR?',
        answer: (
            <>
                To sign up for MedR, you need to create an account on the MedR
                website. Visit the{' '}
                <a
                    className="medr-text-inv"
                    style={{fontSize: '1em'}}
                    href="/register"
                >
                    registration page
                </a>{' '}
                to get started.
            </>
        ),
    },
    {
        question: 'How do I find a specialist?',
        answer: (
            <>
                MedR allows you to search for specialists by name, specialty, or
                insurers accepted. Visit the{' '}
                <a
                    className="medr-text-inv"
                    style={{fontSize: '1em'}}
                    href="/clinicians"
                >
                    clinicians page
                </a>{' '}
                to find a specialist.
            </>
        ),
    },
    {
        question: 'Where can I see my patients?',
        answer: (
            <>
                The{' '}
                <a
                    className="medr-text-inv"
                    style={{fontSize: '1em'}}
                    href="/patients"
                >
                    patients page
                </a>{' '}
                allows you to view your patients and their details. Your
                patients are split into three categories: Added (but not
                referred), Referred, and Accepted.
            </>
        ),
    },
    {
        question: 'Where can I see my referrals?',
        answer: (
            <>
                The{' '}
                <a
                    className="medr-text-inv"
                    style={{fontSize: '1em'}}
                    href="/referrals"
                >
                    referrals page
                </a>{' '}
                allows you to view your inbound and outbound referrals. Your
                referrals are split into four categories: Received, Accepted,
                Sent, and Open.
            </>
        ),
    },
    {
        question: 'What are open referrals?',
        answer: (
            <>
                An open referral is a referral where the referring clinician has
                only specified the specialist role but not the clinician. Any
                registered clinician with the specified role can accept the
                referral.
            </>
        ),
    },
];

const FAQ: React.FC<FAQProps> = ({faq, toggleFAQ}) => {
    const isOpen = faq.expanded;

    return (
        <Card
            className="mb-2 rounded py-2 pl-2"
            key={`faq${faq.id}`}
            onClick={() => toggleFAQ(faq.id, !isOpen)}
            style={{cursor: 'pointer'}}
        >
            <div className="d-flex justify-content-space-between align-items-center">
                <Card.Title className="text-left mb-0 flex-grow-1">
                    {faq.question}
                </Card.Title>
                <div
                    className="d-flex align-items-center flex-shrink-0"
                    style={{width: '30px'}}
                >
                    <i
                        className={`${isOpen ? 'fa-solid fa-minus' : 'fa-solid fa-plus'}`}
                    />
                </div>
            </div>
            {isOpen && (
                <Card.Subtitle className={answerClassName}>
                    {faq.answer}
                </Card.Subtitle>
            )}
        </Card>
    );
};

const FAQScreen: React.FC = () => {
    const [faqsList, setFaqsList] = useState<FAQItem[]>(() =>
        faqs.map((faq, index) => ({...faq, id: index + 1, expanded: false}))
    );

    const toggleFAQ = (id: number, open: boolean) => {
        setFaqsList((prevFaqs) =>
            prevFaqs.map((faq) =>
                faq.id === id ? {...faq, expanded: open} : faq
            )
        );
    };

    return (
        <div className="medr-layout text-center register-user-page">
            <h4>Frequently Asked Questions</h4>
            <p className="medr-text text-left medr-small">
                Find answers to commonly asked questions about the MedR referral
                solution application
            </p>
            {faqsList.map((faq) => (
                <FAQ faq={faq} toggleFAQ={toggleFAQ} key={faq.id} />
            ))}
        </div>
    );
};

export default FAQScreen;
