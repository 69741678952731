import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Navigate, useParams} from 'react-router-dom';
import {useAuthService} from '@/actions';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';

interface RegistrationProgressScreenProps {
    step: number;
}

const RegistrationProgressScreen: React.FC<RegistrationProgressScreenProps> = ({
    step,
}) => {
    const isAuthenticated = useSelector(
        (state: RootState) => state.auth.isAuthenticated
    );

    if (isAuthenticated) return <Navigate to="/dashboard" />;
    const authService = useAuthService();

    const [stepHasFailed, setStepHasFailed] = useState(false);

    const step1 = (
        <>
            <Row className="registration-steps text-center mt-5">
                <Col>
                    <i className="fa-solid fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle" />
                </Col>
            </Row>
            <Row>
                <Col className="mt-5 text-center">
                    <p className="registration-step-info">
                        Please check your email for the verification link.
                    </p>
                </Col>
            </Row>
        </>
    );
    const step2 = stepHasFailed ? (
        <>
            <Row className="registration-steps text-center mt-5">
                <Col>
                    <i className="fa-solid fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle" />
                </Col>
            </Row>
            <Row>
                <Col className="mt-5 text-center text-center">
                    <p className="registration-step-info">
                        Email verification was unsuccessful. The MedR team will
                        review your registration request, which may take up to
                        48 hours. Thank you for your patience.{' '}
                    </p>
                </Col>
            </Row>
        </>
    ) : (
        <>
            <Row className="registration-steps text-center mt-5">
                <Col>
                    <i className="fa-solid fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-solid fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle" />
                </Col>
            </Row>
            <Row>
                <Col className="mt-5 text-center text-center">
                    <p className="registration-step-info">
                        Your email has been successfully verified. Please wait
                        for the MedR team to approve your account, which may
                        take up to 48 hours. Thank you for your patience!{' '}
                    </p>
                </Col>
            </Row>
        </>
    );

    const step3 = (
        <>
            <Row className="registration-steps mt-5">
                <Col>
                    <i className="fa-solid fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-solid fa-check-circle" />
                </Col>
                <Col>
                    <i className="fa-solid fa-check-circle" />
                </Col>
            </Row>
            <Row>
                <Col className="mt-5 text-center">
                    <p className="registration-step-info">
                        Your account has been approved!
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button>Please Login</Button>
                </Col>
            </Row>
        </>
    );

    const s2Success = (value: boolean): void => {
        setStepHasFailed(!value);

        if (value === true) {
            <Navigate to="/registered/verification-pending" />;
        }
    };
    const params = useParams();

    useEffect(() => {
        const checkVerification = async () => {
            const {userHash, timeHash, verificationCode} = params;
            const isSuccess = await authService.verifyUserEmail(
                verificationCode,
                timeHash,
                userHash
            );
            s2Success(isSuccess);
        };

        window.scrollTo(0, 0);
        if (step === 1) checkVerification();
    }, []);

    return (
        <>
            <Row>
                <Col className="text-center">
                    <h1>Registration step {step + 1} of 3</h1>
                </Col>
            </Row>
            {step === 0 && step1}
            {step === 1 && step2}
            {step === 2 && step3}
        </>
    );
};

export default RegistrationProgressScreen;
