/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationAddress
 */
export interface RegistrationAddress {
    /**
     * 
     * @type {string}
     * @memberof RegistrationAddress
     */
    firstLine: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAddress
     */
    postcode: string;
}

/**
 * Check if a given object implements the RegistrationAddress interface.
 */
export function instanceOfRegistrationAddress(value: object): value is RegistrationAddress {
    if (!('firstLine' in value) || value['firstLine'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('postcode' in value) || value['postcode'] === undefined) return false;
    return true;
}

export function RegistrationAddressFromJSON(json: any): RegistrationAddress {
    return RegistrationAddressFromJSONTyped(json, false);
}

export function RegistrationAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'firstLine': json['firstLine'],
        'city': json['city'],
        'postcode': json['postcode'],
    };
}

export function RegistrationAddressToJSON(json: any): RegistrationAddress {
    return RegistrationAddressToJSONTyped(json, false);
}

export function RegistrationAddressToJSONTyped(value?: RegistrationAddress | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'firstLine': value['firstLine'],
        'city': value['city'],
        'postcode': value['postcode'],
    };
}

