import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import ClinicsDisplayBox from '@/components/clinicians/ClinicsDisplayBox';
import ProfileRemoveClinic, {
    MoreProfilesRequired,
} from './ProfileRemoveClinicPopup';
import {useClinicianService} from '@/actions';
import {RootState} from '@/reducers';
import {ClinicianRoleProfile} from '@/generated-client';
import {ClinicFormData} from '@/components/clinicians/ClinicForm.interfaces';

const ProfileAddClinic: React.FC = () => {
    const [showRemovePopup, setShowRemovePopup] = useState(false);
    const [crpToRemove, setCrpToRemove] = useState<null | ClinicianRoleProfile>(
        null
    );

    const clinicianService = useClinicianService();
    const clinicianProfiles = useSelector(
        (state: RootState) => state.profile.clinicianProfiles
    );
    const clinics = clinicianProfiles.map((item) => {
        return {
            id: item.clinic.id,
            clinicName: item.clinic.clinicName,
            centreName: item.clinic.centreName,
            address: {
                firstLine: item.clinic.address.firstLine,
                city: item.clinic.address.city,
                postcode: item.clinic.address.postcode,
            },
        };
    });

    const toggleRemovePopup = () => setShowRemovePopup((prev) => !prev);
    const updateCrpToRemove = (clinicId: number) => {
        const crp = clinicianProfiles.find(
            (item) => item.clinic.id === clinicId
        );
        if (crp) {
            setCrpToRemove(crp);
        }
    };

    const handleAddClinic = async (clinic: ClinicFormData) => {
        await clinicianService.postCreateCrp(clinic);
    };
    const handleEditClinic = async (clinic: ClinicFormData) => {
        await clinicianService.editCrp(clinic);
    };
    const handleRemoveClinic = (clinic: ClinicFormData) => {
        updateCrpToRemove(clinic.id);
        toggleRemovePopup();
    };

    return (
        <>
            {showRemovePopup && clinicianProfiles.length === 1 && (
                <MoreProfilesRequired
                    showPopup={showRemovePopup}
                    handleClose={toggleRemovePopup}
                />
            )}
            {showRemovePopup && clinicianProfiles.length > 1 && (
                <ProfileRemoveClinic
                    userProfileId={clinicianProfiles[0].userProfile.id}
                    clinicId={crpToRemove?.clinic.id}
                    showPopup={showRemovePopup}
                    handleClose={toggleRemovePopup}
                />
            )}
            <Row className="py-1">
                <Col>
                    <ClinicsDisplayBox
                        editable
                        clinics={clinics}
                        addClinic={handleAddClinic}
                        editClinic={handleEditClinic}
                        removeClinic={handleRemoveClinic}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ProfileAddClinic;
