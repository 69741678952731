import React, {useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import DelegatesDisplay from '@/components/user/delegates/DelegatesDisplay';
import RegisterDelegateForm from '@/components/user/delegates/RegisterDelegateForm';

enum DelegagteViews {
    CURRENT = 'current',
    ADD = 'add',
}

const DelegateManagement: React.FC = () => {
    const [view, setView] = useState<DelegagteViews>(DelegagteViews.CURRENT);
    const title = 'Delegate Management';
    const colSizes = {
        sm: 3,
        md: 3,
    };
    return (
        <div
            className="medr-layout text-center profile-page"
            style={{marginLeft: 'auto', marginRight: 'auto'}}
        >
            <h1 className="mb-3">{title}</h1>
            <div className="d-flex justify-content-center">
                <div className="w80">
                    <p className="mb-3 medr-text medr-small max-w-prose">
                        Delegate accounts optimise the referral workflow by
                        empowering administrative staff and designated team
                        members to efficiently process, track, and update
                        referrals, ensuring seamless communication and improved
                        patient care coordination.
                    </p>
                </div>
            </div>

            <Row>
                <Col sm={colSizes.sm} md={colSizes.md}>
                    <Row>
                        <Col>
                            <Button
                                className={`sel-button w100 mb-4 ${
                                    view === DelegagteViews.CURRENT
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => setView(DelegagteViews.CURRENT)}
                            >
                                Delegates
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                className={`sel-button w100 mb-4 ${
                                    view === DelegagteViews.ADD ? 'active' : ''
                                }`}
                                onClick={() => setView(DelegagteViews.ADD)}
                            >
                                Add New
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col>
                    {view === DelegagteViews.CURRENT && <DelegatesDisplay />}
                    {view === DelegagteViews.ADD && <RegisterDelegateForm />}
                </Col>
            </Row>
        </div>
    );
};

export default DelegateManagement;
