/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyDelegateUserRequest
 */
export interface VerifyDelegateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyDelegateUserRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyDelegateUserRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyDelegateUserRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyDelegateUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyDelegateUserRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyDelegateUserRequest
     */
    rePassword: string;
}

/**
 * Check if a given object implements the VerifyDelegateUserRequest interface.
 */
export function instanceOfVerifyDelegateUserRequest(value: object): value is VerifyDelegateUserRequest {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('rePassword' in value) || value['rePassword'] === undefined) return false;
    return true;
}

export function VerifyDelegateUserRequestFromJSON(json: any): VerifyDelegateUserRequest {
    return VerifyDelegateUserRequestFromJSONTyped(json, false);
}

export function VerifyDelegateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyDelegateUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'password': json['password'],
        'rePassword': json['rePassword'],
    };
}

export function VerifyDelegateUserRequestToJSON(json: any): VerifyDelegateUserRequest {
    return VerifyDelegateUserRequestToJSONTyped(json, false);
}

export function VerifyDelegateUserRequestToJSONTyped(value?: VerifyDelegateUserRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'password': value['password'],
        'rePassword': value['rePassword'],
    };
}

