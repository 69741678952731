/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ReferralCreateRequest,
  ReferralCreateResponse,
  ReferralSelectionResponse,
  ReferralToggleBookedRequest,
  ReferralToggleCompleteResponse,
  ReferralToggleDnaRequest,
  ReferralsListForUserResponse,
  ReferralsListInboundForUserResponse,
  ReferralsListMadePendingResponse,
  ReferralsListOutboundForUserResponse,
  ReferralsNotificationsForUserResponse,
} from '../models/index';
import {
    ReferralCreateRequestFromJSON,
    ReferralCreateRequestToJSON,
    ReferralCreateResponseFromJSON,
    ReferralCreateResponseToJSON,
    ReferralSelectionResponseFromJSON,
    ReferralSelectionResponseToJSON,
    ReferralToggleBookedRequestFromJSON,
    ReferralToggleBookedRequestToJSON,
    ReferralToggleCompleteResponseFromJSON,
    ReferralToggleCompleteResponseToJSON,
    ReferralToggleDnaRequestFromJSON,
    ReferralToggleDnaRequestToJSON,
    ReferralsListForUserResponseFromJSON,
    ReferralsListForUserResponseToJSON,
    ReferralsListInboundForUserResponseFromJSON,
    ReferralsListInboundForUserResponseToJSON,
    ReferralsListMadePendingResponseFromJSON,
    ReferralsListMadePendingResponseToJSON,
    ReferralsListOutboundForUserResponseFromJSON,
    ReferralsListOutboundForUserResponseToJSON,
    ReferralsNotificationsForUserResponseFromJSON,
    ReferralsNotificationsForUserResponseToJSON,
} from '../models/index';

export interface ReferralsAcceptCreateRequest {
    refId: number;
}

export interface ReferralsCompleteCreateRequest {
    refId: number;
    referralToggleBookedRequest: ReferralToggleBookedRequest;
}

export interface ReferralsCreateCreateRequest {
    referralCreateRequest: ReferralCreateRequest;
}

export interface ReferralsDeleteDestroyRequest {
    refId: number;
}

export interface ReferralsDnaCreateRequest {
    refId: number;
    referralToggleDnaRequest: ReferralToggleDnaRequest;
}

export interface ReferralsRejectCreateRequest {
    refId: number;
}

/**
 * 
 */
export class ReferralsApi extends runtime.BaseAPI {

    /**
     * Set referral accepted by the target clinician
     */
    async referralsAcceptCreateRaw(requestParameters: ReferralsAcceptCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralSelectionResponse>> {
        if (requestParameters['refId'] == null) {
            throw new runtime.RequiredError(
                'refId',
                'Required parameter "refId" was null or undefined when calling referralsAcceptCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/accept/{ref_id}/`.replace(`{${"ref_id"}}`, encodeURIComponent(String(requestParameters['refId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralSelectionResponseFromJSON(jsonValue));
    }

    /**
     * Set referral accepted by the target clinician
     */
    async referralsAcceptCreate(requestParameters: ReferralsAcceptCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralSelectionResponse> {
        const response = await this.referralsAcceptCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set referral completed by the target clinician
     */
    async referralsCompleteCreateRaw(requestParameters: ReferralsCompleteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralToggleCompleteResponse>> {
        if (requestParameters['refId'] == null) {
            throw new runtime.RequiredError(
                'refId',
                'Required parameter "refId" was null or undefined when calling referralsCompleteCreate().'
            );
        }

        if (requestParameters['referralToggleBookedRequest'] == null) {
            throw new runtime.RequiredError(
                'referralToggleBookedRequest',
                'Required parameter "referralToggleBookedRequest" was null or undefined when calling referralsCompleteCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/complete/{ref_id}/`.replace(`{${"ref_id"}}`, encodeURIComponent(String(requestParameters['refId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToggleBookedRequestToJSON(requestParameters['referralToggleBookedRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralToggleCompleteResponseFromJSON(jsonValue));
    }

    /**
     * Set referral completed by the target clinician
     */
    async referralsCompleteCreate(requestParameters: ReferralsCompleteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralToggleCompleteResponse> {
        const response = await this.referralsCompleteCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates referral using form data
     */
    async referralsCreateCreateRaw(requestParameters: ReferralsCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralCreateResponse>> {
        if (requestParameters['referralCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'referralCreateRequest',
                'Required parameter "referralCreateRequest" was null or undefined when calling referralsCreateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/create/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralCreateRequestToJSON(requestParameters['referralCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralCreateResponseFromJSON(jsonValue));
    }

    /**
     * Creates referral using form data
     */
    async referralsCreateCreate(requestParameters: ReferralsCreateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralCreateResponse> {
        const response = await this.referralsCreateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the referral using the referral uid.
     */
    async referralsDeleteDestroyRaw(requestParameters: ReferralsDeleteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralsListMadePendingResponse>> {
        if (requestParameters['refId'] == null) {
            throw new runtime.RequiredError(
                'refId',
                'Required parameter "refId" was null or undefined when calling referralsDeleteDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/delete/{ref_id}/`.replace(`{${"ref_id"}}`, encodeURIComponent(String(requestParameters['refId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralsListMadePendingResponseFromJSON(jsonValue));
    }

    /**
     * Deletes the referral using the referral uid.
     */
    async referralsDeleteDestroy(requestParameters: ReferralsDeleteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralsListMadePendingResponse> {
        const response = await this.referralsDeleteDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set referral accepted by the target clinician
     */
    async referralsDnaCreateRaw(requestParameters: ReferralsDnaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralToggleCompleteResponse>> {
        if (requestParameters['refId'] == null) {
            throw new runtime.RequiredError(
                'refId',
                'Required parameter "refId" was null or undefined when calling referralsDnaCreate().'
            );
        }

        if (requestParameters['referralToggleDnaRequest'] == null) {
            throw new runtime.RequiredError(
                'referralToggleDnaRequest',
                'Required parameter "referralToggleDnaRequest" was null or undefined when calling referralsDnaCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/dna/{ref_id}/`.replace(`{${"ref_id"}}`, encodeURIComponent(String(requestParameters['refId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToggleDnaRequestToJSON(requestParameters['referralToggleDnaRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralToggleCompleteResponseFromJSON(jsonValue));
    }

    /**
     * Set referral accepted by the target clinician
     */
    async referralsDnaCreate(requestParameters: ReferralsDnaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralToggleCompleteResponse> {
        const response = await this.referralsDnaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns referral lists for the logged in user
     */
    async referralsListInboundRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralsListInboundForUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/list/inbound`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralsListInboundForUserResponseFromJSON(jsonValue));
    }

    /**
     * Returns referral lists for the logged in user
     */
    async referralsListInboundRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralsListInboundForUserResponse> {
        const response = await this.referralsListInboundRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns referral lists for the logged in user
     */
    async referralsListOutboundRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralsListOutboundForUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/list/outbound`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralsListOutboundForUserResponseFromJSON(jsonValue));
    }

    /**
     * Returns referral lists for the logged in user
     */
    async referralsListOutboundRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralsListOutboundForUserResponse> {
        const response = await this.referralsListOutboundRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns referral lists for the logged in user
     */
    async referralsListRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralsListForUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/list/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralsListForUserResponseFromJSON(jsonValue));
    }

    /**
     * Returns referral lists for the logged in user
     */
    async referralsListRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralsListForUserResponse> {
        const response = await this.referralsListRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns referral notifications for the logged in user
     */
    async referralsReferralNotificationsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralsNotificationsForUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/referral-notifications/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralsNotificationsForUserResponseFromJSON(jsonValue));
    }

    /**
     * Returns referral notifications for the logged in user
     */
    async referralsReferralNotificationsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralsNotificationsForUserResponse> {
        const response = await this.referralsReferralNotificationsRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Set referral accepted by the target clinician
     */
    async referralsRejectCreateRaw(requestParameters: ReferralsRejectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralSelectionResponse>> {
        if (requestParameters['refId'] == null) {
            throw new runtime.RequiredError(
                'refId',
                'Required parameter "refId" was null or undefined when calling referralsRejectCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/referrals/reject/{ref_id}/`.replace(`{${"ref_id"}}`, encodeURIComponent(String(requestParameters['refId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralSelectionResponseFromJSON(jsonValue));
    }

    /**
     * Set referral accepted by the target clinician
     */
    async referralsRejectCreate(requestParameters: ReferralsRejectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralSelectionResponse> {
        const response = await this.referralsRejectCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
