/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressTypeEnum } from './AddressTypeEnum';
import {
    AddressTypeEnumFromJSON,
    AddressTypeEnumFromJSONTyped,
    AddressTypeEnumToJSON,
    AddressTypeEnumToJSONTyped,
} from './AddressTypeEnum';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    readonly id: number;
    /**
     * 
     * @type {AddressTypeEnum}
     * @memberof Address
     */
    addressType?: AddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    firstLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postcode: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    readonly longitude: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    readonly latitude: number;
}



/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): value is Address {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('postcode' in value) || value['postcode'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    return true;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'addressType': json['addressType'] == null ? undefined : AddressTypeEnumFromJSON(json['addressType']),
        'firstLine': json['firstLine'] == null ? undefined : json['firstLine'],
        'city': json['city'] == null ? undefined : json['city'],
        'postcode': json['postcode'],
        'longitude': json['longitude'],
        'latitude': json['latitude'],
    };
}

export function AddressToJSON(json: any): Address {
    return AddressToJSONTyped(json, false);
}

export function AddressToJSONTyped(value?: Omit<Address, 'id'|'longitude'|'latitude'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'addressType': AddressTypeEnumToJSON(value['addressType']),
        'firstLine': value['firstLine'],
        'city': value['city'],
        'postcode': value['postcode'],
    };
}

