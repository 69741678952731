/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface DelegateRegistrationInfo
 */
export interface DelegateRegistrationInfo {
    /**
     * 
     * @type {UserProfile}
     * @memberof DelegateRegistrationInfo
     */
    clinician: UserProfile;
    /**
     * 
     * @type {UserProfile}
     * @memberof DelegateRegistrationInfo
     */
    delegate: UserProfile;
}

/**
 * Check if a given object implements the DelegateRegistrationInfo interface.
 */
export function instanceOfDelegateRegistrationInfo(value: object): value is DelegateRegistrationInfo {
    if (!('clinician' in value) || value['clinician'] === undefined) return false;
    if (!('delegate' in value) || value['delegate'] === undefined) return false;
    return true;
}

export function DelegateRegistrationInfoFromJSON(json: any): DelegateRegistrationInfo {
    return DelegateRegistrationInfoFromJSONTyped(json, false);
}

export function DelegateRegistrationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegateRegistrationInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'clinician': UserProfileFromJSON(json['clinician']),
        'delegate': UserProfileFromJSON(json['delegate']),
    };
}

export function DelegateRegistrationInfoToJSON(json: any): DelegateRegistrationInfo {
    return DelegateRegistrationInfoToJSONTyped(json, false);
}

export function DelegateRegistrationInfoToJSONTyped(value?: DelegateRegistrationInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinician': UserProfileToJSON(value['clinician']),
        'delegate': UserProfileToJSON(value['delegate']),
    };
}

