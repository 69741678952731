import {Dispatch} from 'redux';
import * as CONSTS from '@constants/searchConstants';
import * as CLIENT from '@/generated-client';

// setSearchPatientNull
export function dispatchSetPatientNull(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_PATIENT_NULL_ACTION.SUCCESS});
}

// setSearchCrpNull
export function dispatchSetCrpNull(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_CRP_NULL_ACTION.SUCCESS});
}

// setJobRoleNull
export function dispatchSetJobRoleNull(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_JOB_ROLE_NULL_ACTION.SUCCESS});
}

// setClinicianNull
export function dispatchSetClinicianNull(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_CLINICIAN_NULL_ACTION.SUCCESS});
}

// setSearchPatientById
export function dispatchSetSearchPatientByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_PATIENT_ACTION.REQUEST});
}
export function dispatchSetSearchPatientByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientByIdResponse
) {
    dispatch({type: CONSTS.SET_PATIENT_ACTION.SUCCESS, payload});
}
export function dispatchSetSearchPatientByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.SET_PATIENT_ACTION.FAIL, payload: error});
}

// setSearchCrpById
export function dispatchSetSearchCrpByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_CRP_ACTION.REQUEST});
}
export function dispatchSetSearchCrpByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianInfoByIdResponse
) {
    dispatch({type: CONSTS.SET_CRP_ACTION.SUCCESS, payload});
}
export function dispatchSetSearchCrpByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.SET_CRP_ACTION.FAIL, payload: error});
}

// setClinicianById
export function dispatchSetClinicianByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_CLINICIAN_ACTION.REQUEST});
}
export function dispatchSetClinicianByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ClinicianInfoByIdResponse
) {
    dispatch({type: CONSTS.SET_CLINICIAN_ACTION.SUCCESS, payload});
}
export function dispatchSetClinicianByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.SET_CLINICIAN_ACTION.FAIL, payload: error});
}

// setSearchGps
export function dispatchSetSearchGpsRequest(
    dispatch: Dispatch,
    postCode: string,
    lat: number,
    lng: number
) {
    dispatch({
        type: CONSTS.SET_GPS_ACTION.REQUEST,
        payload: {postCode, lat, lng},
    });
}

// setSearchPostcode
export function dispatchSetSearchPostcodeRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.SET_POSTCODE_ACTION.REQUEST});
}
export function dispatchSetSearchPostcodeSuccess(
    dispatch: Dispatch,
    payload: CLIENT.GpsFromPostcodeResponse
) {
    dispatch({type: CONSTS.SET_POSTCODE_ACTION.SUCCESS, payload});
}
export function dispatchSetSearchPostcodeFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.SET_POSTCODE_ACTION.FAIL, payload: error});
}

// getSearchPostcodeData
export function dispatchGetSearchPostcodeDataRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.GET_POSTCODE_ACTION.REQUEST});
}
export function dispatchGetSearchPostcodeDataSuccess(
    dispatch: Dispatch,
    payload: CLIENT.GpsFromPostcodeResponse
) {
    dispatch({type: CONSTS.GET_POSTCODE_ACTION.SUCCESS, payload});
}
export function dispatchGetSearchPostcodeDataFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.GET_POSTCODE_ACTION.FAIL, payload: error});
}
