import React, {useEffect, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {Container} from 'react-bootstrap';
import '../index.css';

import Header from '@components/app/Header';
import Footer from '@components/app/Footer';
import Message from '@components/app/Message';
import {useAuthService, useUserService} from '@/actions';
import {MEDR_APP_VERSION} from '@/Environment';
import {RootState} from '@/reducers';
import {MedrPages} from '@/constants/variables';

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    const authService = useAuthService();
    const userService = useUserService();
    const userProfileData = useSelector(
        (state: RootState) => state.profile.userProfile
    );
    const location = window.location.pathname;
    const checkCurrentAppVersion = async (): Promise<void> => {
        const appData = await authService.getAppVersion();
        const {version} = appData;
        if (version === MEDR_APP_VERSION) {
            if (location === MedrPages.SERVER_DOWN.toString()) {
                authService.goToLoginPage();
            }
            return;
        }
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log('App version mismatch');
                resolve();
            }, 5000);
        });
        window.location.reload();
    };

    const handleCheckAuth = async (): Promise<void> => {
        if (authService.checkAuthToken()) {
            const isAuth = await authService.checkAuth();
            if (isAuth && !userProfileData.id) {
                await userService.loadUserProfile();
            }
        }
    };

    useEffect(() => {
        checkCurrentAppVersion();
    }, []);

    useEffect(() => {
        handleCheckAuth();
    }, []);

    return (
        <>
            <Header />
            <div id="mainContainer" className="py-3 medr-body">
                <Container>
                    <Message />
                    {children}
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default Layout;
