import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useAuthService} from '@/actions';
import NotificationMessage, {
    Variant,
} from '@/components/app/NotificationMessage';

interface ProfileFullDeactivatePopupProps {
    userProfileId: number;
    showModal: boolean;
    handleClose: () => void;
}

const ProfileFullDeactivatePopup: React.FC<ProfileFullDeactivatePopupProps> = ({
    userProfileId,
    showModal,
    handleClose,
}) => {
    const [showRequestSuccess, setShowRequestSuccess] = useState(false);
    const [showRequestError, setShowRequestError] = useState(false);
    const toggleRequestSuccess = () => setShowRequestSuccess((prev) => !prev);
    const toggleRequestError = () => setShowRequestError((prev) => !prev);

    const authService = useAuthService();

    const handleDeactivateAccount = async () => {
        const success = await authService.deactivateAccount(userProfileId);
        if (!success) {
            toggleRequestError();
            setTimeout(() => {
                handleClose();
            }, 5000);
            return;
        }
        handleClose();
        toggleRequestSuccess();
        authService.unauthResponseClear();
        setTimeout(() => {
            authService.goToLandingPage();
        }, 5000);
    };

    const requestErrorText =
        'An error occurred while trying to deactivate your account. Please try again later.';
    const requestSuccessText = 'Your account has been removed successfully.';
    return (
        <>
            <NotificationMessage
                show={showRequestSuccess}
                toggleShow={toggleRequestSuccess}
                variant={Variant.SUCCESS}
                message={requestSuccessText}
            />
            <NotificationMessage
                show={showRequestError}
                toggleShow={toggleRequestError}
                variant={Variant.DANGER}
                message={requestErrorText}
            />
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="medr-inv">
                        Are you sure you would like to deactivate your account?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeactivateAccount}>
                        Deactivate
                    </Button>
                    <Button variant="info" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProfileFullDeactivatePopup;
