/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';

/**
 * 
 * @export
 * @interface ResetUserPasswordSetNewResponse
 */
export interface ResetUserPasswordSetNewResponse {
    /**
     * 
     * @type {Message}
     * @memberof ResetUserPasswordSetNewResponse
     */
    message: Message;
}

/**
 * Check if a given object implements the ResetUserPasswordSetNewResponse interface.
 */
export function instanceOfResetUserPasswordSetNewResponse(value: object): value is ResetUserPasswordSetNewResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ResetUserPasswordSetNewResponseFromJSON(json: any): ResetUserPasswordSetNewResponse {
    return ResetUserPasswordSetNewResponseFromJSONTyped(json, false);
}

export function ResetUserPasswordSetNewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetUserPasswordSetNewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
    };
}

export function ResetUserPasswordSetNewResponseToJSON(json: any): ResetUserPasswordSetNewResponse {
    return ResetUserPasswordSetNewResponseToJSONTyped(json, false);
}

export function ResetUserPasswordSetNewResponseToJSONTyped(value?: ResetUserPasswordSetNewResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': MessageToJSON(value['message']),
    };
}

