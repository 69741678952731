/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceAgreement
 */
export interface InsuranceAgreement {
    /**
     * 
     * @type {number}
     * @memberof InsuranceAgreement
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceAgreement
     */
    policyName?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceAgreement
     */
    provider: string;
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceAgreement
     */
    verified?: boolean;
}

/**
 * Check if a given object implements the InsuranceAgreement interface.
 */
export function instanceOfInsuranceAgreement(value: object): value is InsuranceAgreement {
    if (!('provider' in value) || value['provider'] === undefined) return false;
    return true;
}

export function InsuranceAgreementFromJSON(json: any): InsuranceAgreement {
    return InsuranceAgreementFromJSONTyped(json, false);
}

export function InsuranceAgreementFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceAgreement {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'policyName': json['policyName'] == null ? undefined : json['policyName'],
        'provider': json['provider'],
        'verified': json['verified'] == null ? undefined : json['verified'],
    };
}

export function InsuranceAgreementToJSON(json: any): InsuranceAgreement {
    return InsuranceAgreementToJSONTyped(json, false);
}

export function InsuranceAgreementToJSONTyped(value?: InsuranceAgreement | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'policyName': value['policyName'],
        'provider': value['provider'],
        'verified': value['verified'],
    };
}

