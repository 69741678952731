import ReactGA from 'react-ga4';

enum EnabledDomains {
    MJDATACONSULTANCY = 'mjdataconsultancy',
    MEDR = 'medr',
}
export const initGA = (): void => {
    let google_analytics_key = '';
    if (window.location.origin.includes(EnabledDomains.MJDATACONSULTANCY)) {
        // @ts-expect-error  Avoid error on env var import
        google_analytics_key = import.meta.env.VITE_GOOGLE_ANALYTICS_KEY_MJDC;
    } else if (window.location.origin.includes(EnabledDomains.MEDR)) {
        // @ts-expect-error  Avoid error on env var import
        google_analytics_key = import.meta.env.VITE_GOOGLE_ANALYTICS_KEY_MEDR;
    } else {
        console.log(
            'Google Analytics is not enabled for ',
            window.location.origin
        );
        return;
    }
    ReactGA.initialize(google_analytics_key);
};

export const logPageView = (): void => {
    if (
        !Object.values(EnabledDomains).some((domain) =>
            window.location.origin.includes(domain)
        )
    ) {
        console.log(
            'Google Analytics is disabled for this domain',
            window.location.origin
        );
        return;
    }
    ReactGA.send({hitType: 'pageview', page: window.location.pathname});
};

// const enabledDomains = ["mjdataconsultancy", "medr"];

// export const initGA = () => {
//     let google_analytics_key = "";
//     if (window.location.origin.includes("mjdataconsultancy")) {
//         google_analytics_key = import.meta.env.REACT_APP_GOOGLE_ANALYTICS_KEY_MJDC;
//     } else if (window.location.origin.includes("medr")) {
//         google_analytics_key = import.meta.env.REACT_APP_GOOGLE_ANALYTICS_KEY_MEDR;
//     } else {
//         console.log("Google Analytics is not enabled for ", window.location.origin);
//         return;
//     }
//     ReactGA.initialize(google_analytics_key);
// };

// export const logPageView = () => {
//     if (!enabledDomains.some(domain => window.location.origin.includes(domain))) {
//         console.log("Google Analytics is disabled for this domain", window.location.origin);
//         return;
//     }
//     ReactGA.send({ hitType: "pageview", page: window.location.pathname });
// };
