import * as CONSTS from '@constants/adminConstants';
import {Dispatch} from 'redux';
import * as CLIENT from '@/generated-client';

// addNewAdminUser
export function dispatchAddNewAdminUserRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADD_NEW_ADMIN_ACTION.REQUEST,
    });
}
export function dispatchAddNewAdminUserSuccess(
    dispatch: Dispatch,
    payload: CLIENT.CreateAdminUserResponse
) {
    dispatch({
        type: CONSTS.ADD_NEW_ADMIN_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchAddNewAdminUserFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADD_NEW_ADMIN_ACTION.FAIL,
        payload: error,
    });
}

// getPlatformStats

export function dispatchGetPlatformStatsRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.PLATFORM_STATS_ACTION.REQUEST,
    });
}

export function dispatchGetPlatformStatsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.GetPlatformStatisticsResponse
) {
    dispatch({
        type: CONSTS.PLATFORM_STATS_ACTION.SUCCESS,
        payload,
    });
}

export function dispatchGetPlatformStatsFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.PLATFORM_STATS_ACTION.FAIL,
        payload: error,
    });
}

// getAdminUsersList
export function dispatchGetAdminUsersListRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_USERS_LIST_ACTION.REQUEST,
    });
}

export function dispatchGetAdminUsersListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.UserListForAdminResponse
) {
    dispatch({
        type: CONSTS.ADMIN_USERS_LIST_ACTION.SUCCESS,
        payload,
    });
}

export function dispatchGetAdminUsersListFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADMIN_USERS_LIST_ACTION.FAIL,
        payload: error,
    });
}

// getAdminPatientsList
export function dispatchGetAdminPatientsListRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_PATIENTS_LIST_ACTION.REQUEST,
    });
}

export function dispatchGetAdminPatientsListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientsListForAdminResponse
) {
    dispatch({
        type: CONSTS.ADMIN_PATIENTS_LIST_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetAdminPatientsListFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADMIN_PATIENTS_LIST_ACTION.FAIL,
        payload: error,
    });
}

// getAdminReferralsList
export function dispatchGetAdminReferralsListRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_REFERRALS_LIST_ACTION.REQUEST,
    });
}
export function dispatchGetAdminReferralsListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralsListForAdminResponse
) {
    dispatch({
        type: CONSTS.ADMIN_REFERRALS_LIST_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetAdminReferralsListFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADMIN_REFERRALS_LIST_ACTION.FAIL,
        payload: error,
    });
}

// getAdminClinicansList
export function dispatchGetAdminCliniciansListRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_CLINICIANS_LIST_ACTION.REQUEST,
    });
}
export function dispatchGetAdminCliniciansListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.CliniciansListForAdminResponse
) {
    dispatch({
        type: CONSTS.ADMIN_CLINICIANS_LIST_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetAdminCliniciansListFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADMIN_CLINICIANS_LIST_ACTION.FAIL,
        payload: error,
    });
}

// getAdminInsurers
export function dispatchGetAdminInsurersRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_INSURERS_LIST_ACTION.REQUEST,
    });
}
export function dispatchGetAdminInsurersSuccess(
    dispatch: Dispatch,
    payload: CLIENT.InsurerListForAdminResponse
) {
    dispatch({
        type: CONSTS.ADMIN_INSURERS_LIST_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetAdminInsurersFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADMIN_INSURERS_LIST_ACTION.FAIL,
        payload: error,
    });
}

// verifyInsurer
export function dispatchVerifyInsurerRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_INSURER_VERIFY_ACTION.REQUEST,
    });
}
export function dispatchVerifyInsurerSuccess(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_INSURER_VERIFY_ACTION.SUCCESS,
    });
}
export function dispatchVerifyInsurerFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADMIN_INSURER_VERIFY_ACTION.FAIL,
        payload: error,
    });
}

// setUserVerified
export function dispatchSetUserVerifiedRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.REQUEST,
    });
}
export function dispatchSetUserVerifiedSuccess(
    dispatch: Dispatch,
    payload: CLIENT.SetUserVerificationResponse
) {
    dispatch({
        type: CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchSetUserVerifiedFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.ADMIN_SET_USER_VERIFIED_ACTION.FAIL,
        payload: error,
    });
}

// requestNewFeature
export function dispatchRequestNewFeatureRequest(dispatch: Dispatch) {
    dispatch({
        type: CONSTS.REQUEST_NEW_FEATURE_ACTION.REQUEST,
    });
}
export function dispatchRequestNewFeatureSuccess(
    dispatch: Dispatch,
    payload: CLIENT.RequestNewFeatureResponse
) {
    dispatch({
        type: CONSTS.REQUEST_NEW_FEATURE_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchRequestNewFeatureFailure(
    dispatch: Dispatch,
    error: string
) {
    dispatch({
        type: CONSTS.REQUEST_NEW_FEATURE_ACTION.FAIL,
        payload: error,
    });
}
