/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserProfileRequest
 */
export interface UpdateUserProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequest
     */
    lastName: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateUserProfileRequest
     */
    dob: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequest
     */
    clinicianDescription: string;
}

/**
 * Check if a given object implements the UpdateUserProfileRequest interface.
 */
export function instanceOfUpdateUserProfileRequest(value: object): value is UpdateUserProfileRequest {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('dob' in value) || value['dob'] === undefined) return false;
    if (!('clinicianDescription' in value) || value['clinicianDescription'] === undefined) return false;
    return true;
}

export function UpdateUserProfileRequestFromJSON(json: any): UpdateUserProfileRequest {
    return UpdateUserProfileRequestFromJSONTyped(json, false);
}

export function UpdateUserProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserProfileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'dob': (new Date(json['dob'])),
        'clinicianDescription': json['clinicianDescription'],
    };
}

export function UpdateUserProfileRequestToJSON(json: any): UpdateUserProfileRequest {
    return UpdateUserProfileRequestToJSONTyped(json, false);
}

export function UpdateUserProfileRequestToJSONTyped(value?: UpdateUserProfileRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'dob': ((value['dob']).toISOString().substring(0,10)),
        'clinicianDescription': value['clinicianDescription'],
    };
}

