import React, {useState, useCallback} from 'react';
import FavouriteClinicianStarPopup, {
    FavStarClinician,
} from '../FavClinicianStarPopup';
import './star.css';

interface FavouriteClinicianStarProps {
    clinician: FavStarClinician;
    requestMade?: () => void;
}

const FavouriteClinicianStar: React.FC<FavouriteClinicianStarProps> = ({
    clinician,
    requestMade,
}) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const {isFavourite} = clinician;

    const handleClick = useCallback(
        (
            e:
                | React.MouseEvent<HTMLDivElement>
                | React.KeyboardEvent<HTMLDivElement>
        ) => {
            // Union type
            e.preventDefault();
            e.stopPropagation();
            setShowPopup(true);
        },
        [setShowPopup]
    ); // useCallback to prevent unnecessary re-renders

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            handleClick(e); // Still calls handleClick, but passes the KeyboardEvent
        }
    };

    return (
        <>
            <FavouriteClinicianStarPopup
                clinician={clinician}
                showPopup={showPopup}
                handleClose={() => setShowPopup(false)}
                requestMade={requestMade}
            />
            <div
                className={`${isFavourite ? '' : 'un'}star-button`}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
                aria-label="Star"
            >
                <i className={`${isFavourite ? 'fas' : 'far'} fa-star`} />
            </div>
        </>
    );
};

export default FavouriteClinicianStar;
