import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {getNameString, reformatDateTime} from '@/actions/general';
import {useAdminService, DownloadService} from '@/actions';
import Loader from '@components/app/Loader';
import {Patient} from '@/generated-client';
import {RootState} from '@/reducers';
import AdminPagTable, {SliceIndexes} from './AdminPaginatedTable';
import {initSliceIndex, formatId} from './PagTableConsts';

const AdminPatientTable: React.FC = () => {
    const adminService = useAdminService();
    const [sliceIndices, setSliceIndices] =
        useState<SliceIndexes>(initSliceIndex);

    useEffect(() => {
        const handleGetAdminPatients = async () => {
            await adminService.getAdminPatientsList();
        };
        handleGetAdminPatients();
    }, []);

    const patientsList = useSelector(
        (state: RootState) => state.admin.patientsList
    );

    const isLoading = adminService.requestLoading;
    const noPatientsDisplayString = 'No patients to display at the moment';
    const tableHeaders = ['ID', 'Px Ref', 'Created By', 'Created At'];
    const createRow = (patient: Patient, index: number) => (
        <tr key={`${index}-${patient.id}`}>
            <td>{formatId(patient.id)}</td>
            <td>{patient.patientReference}</td>
            <td>{getNameString(patient.createdBy)}</td>
            <td>{reformatDateTime(patient.createdOn)}</td>
        </tr>
    );

    const onExportClick = () => {
        const downloadService = new DownloadService();
        downloadService.downloadAdminPatientsList();
    };

    return (
        <>
            {isLoading ? (
                <>
                    <div className="gap mt-5" />
                    <Loader text="Loading admin patients list..." />
                </>
            ) : (
                <>
                    <Button
                        variant="info"
                        className="mb-3"
                        onClick={onExportClick}
                    >
                        Download Patients List
                    </Button>
                    <AdminPagTable
                        headers={tableHeaders}
                        items={patientsList}
                        createItemRows={createRow}
                        sliceIndex={sliceIndices}
                        updateSlice={setSliceIndices}
                        emptyTableMessage={noPatientsDisplayString}
                    />
                </>
            )}
        </>
    );
};

export default AdminPatientTable;
