import React from 'react';
import {Spinner} from 'react-bootstrap';

interface LoaderProps {
    inverse?: boolean;
    text?: string;
}

const Loader: React.FC<LoaderProps> = ({
    inverse = true,
    text = 'Loading...',
}) => {
    const color = inverse ? 'white' : '#247986';

    return (
        <div className="text-center">
            <Spinner
                animation="border"
                role="status"
                style={{
                    color: color,
                    height: '100px',
                    width: '100px',
                    margin: 'auto',
                    display: 'block',
                }}
            >
                <span className="sr-only">{text}</span>
            </Spinner>
            <p className="medr-text" style={{color}}>
                {text}
            </p>
        </div>
    );
};

export default Loader;
