import {MedrPages} from './variables';
import {ReferralInboundTabs} from '@/screens/referrals/ReferralInboundScreen';
import {ReferralOutboundTabs} from '@/screens/referrals/ReferralOutboundScreen';
export default class MedrUrlPaths {
    constructor() {}

    registeredVerifyEmailUrl(
        userHash: string,
        timeHash: string,
        verificationCode: string
    ): string {
        return `${MedrPages.REGISTERED_VERIFY_EMAIL}/${userHash}/${timeHash}/${verificationCode}`;
    }

    registerDelegateVerifyEmailUrl(
        userHash: string,
        timeHash: string,
        verificationCode: string
    ): string {
        return `${MedrPages.REGISTER_DELEGATE_VERIFY_EMAIL}/${userHash}/${timeHash}/${verificationCode}`;
    }

    passwordSetNewUrl(
        userHash: string,
        timeHash: string,
        verificationCode: string
    ): string {
        return `${MedrPages.PASSWORD_SET_NEW}/${userHash}/${timeHash}/${verificationCode}`;
    }

    patientsEditUrl(patientId: number): string {
        return `${MedrPages.PATIENTS}/${patientId}/edit`;
    }

    clinicianPageUrl(userProfileId: number): string {
        return `${MedrPages.CLINICIANS}/user/${userProfileId}`;
    }

    rxToClinicianUrl(clinicianId: number): string {
        return `${MedrPages.RX_DIRECT_TO_CLINICIAN}/${clinicianId}/add`;
    }

    rxPatientSelectedUrl(patientId: number): string {
        return `${MedrPages.RX_PATIENT_SELECTED}/${patientId}/add`;
    }

    clinicianGetUrl(userProfileId: number): string {
        return `${MedrPages.CLINICIANS}/user/${userProfileId}`;
    }

    inboundReferralsUrl(referralType: ReferralInboundTabs): string {
        return `${MedrPages.REFERRALS_INBOUND}?referralType=${referralType}`;
    }

    outboundReferralsUrl(referralType: ReferralOutboundTabs): string {
        return `${MedrPages.REFERRALS_INBOUND}?referralType=${referralType}`;
    }
}
