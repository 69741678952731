export const defaultMapLat: number = 51.5072;
export const defaultMapLng: number = -0.1276;

export const localDomain: string = 'http://localhost:3000';

export enum MedrPages {
    // APP ROUTES
    HOME = '/',
    POLICY = '/policy',
    FAQS = '/faqs',
    HELP = '/help',

    // AUTH ROUTES
    LOGIN = '/login',
    REGISTER = '/register',
    REGISTERED_VERIFY_EMAIL = '/register/verify-email',
    REGISTERED_VERIFY_EMAIL_STYLE = '/register/verify-email/:userHash/:timeHash/:verificationCode',
    REGISTER_DELEGATE_VERIFY_EMAIL = '/register-delegate/verify-email',
    REGISTER_DELEGATE_VERIFY_EMAIL_STYLE = '/register-delegate/verify-email/:userHash/:timeHash/:verificationCode',
    REGISTER_DELEGATE_VERIFY_EMAIL_ERROR = '/register-delegate/verify-email-error',
    REGISTERED_PENDING_APPROVAL = '/register/pending-account-approval',
    PASSWORD_SEND_RESET_EMAIL = '/user/send-reset-email',
    PASSWORD_SEND_RESET_SUCCESS = '/user/password-reset-success',
    PASSWORD_SET_NEW = '/user/set-new-password',
    PASSWORD_SET_NEW_STYLE = '/user/set-new-password/:userHash/:timeHash/:verificationCode',
    REGISTERED_CHECK_EMAIL = '/registered/check-email',
    REGISTERED_VERIFICATION_PENDING = '/registered/verification-pending',
    REGISTERED_COMPLETE = '/registered/complete',

    // USER
    DASHBOARD = '/dashboard',
    AUTH_HELP = '/auth/help',
    PROFILE = '/profile',

    // DELEGATES
    DELEGATES = '/delegates',

    // PATIENTS
    PATIENTS = '/patients',
    PATIENTS_ADD = '/patients/add',
    PATIENTS_EDIT_STYLE = '/patients/:patientId/edit',

    // REFERRALS
    REFERRALS = '/referrals',
    REFERRALS_INBOUND = '/referrals/inbound',
    REFERRALS_OUTBOUND = '/referrals/outbound',
    REFERRALS_ADD = '/referral/add',
    RX_DIRECT_TO_CLINICIAN = '/referral/direct-to-clinician',
    RX_DIRECT_TO_CLINICIAN_STYLE = '/referral/direct-to-clinician/:clinicianId/add',
    RX_PATIENT_SELECTED = '/referral/patient-selected',
    RX_PATIENT_SELECTED_STYLE = '/referral/patient-selected/:patientId/add',

    // CLINICIANS
    CLINICIANS = '/clinicians',
    CLINICIANS_UP_GET_STYLE = '/clinicians/user/:userProfileId',

    // ADMIN
    ADMIN_USERS_LIST = '/staff/users',
    ADMIN_CLINICIANS_LIST = '/staff/clinician-roles',
    ADMIN_PATIENTS = '/staff/patients',
    ADMIN_REFERRALS = '/staff/referrals',
    ADMIN_INSURANCE_AGREEMENTS = '/staff/insurers',
    ADMIN_ADD_STAFF = '/staff/add-admin',

    // ADMIN DOWNLOADS
    ADMIN_USERS_DOWNOAD = '/admin/users-list-download',
    ADMIN_CRP_DOWNLOAD = '/admin/crps-list-download',
    ADMIN_PATIENTS_DOWNLOAD = '/admin/patients-list-download',
    ADMIN_REFERRALS_DOWNLOAD = '/admin/referrals-list-download',
    ADMIN_INSURANCE_AGREEMENTS_DOWNLOAD = '/admin/insurers-list-download',

    // CATCH ALL
    NOT_ASSINGED = '/*',
    SERVER_DOWN = '/server-error',
}

export const allowAllPaths = [
    MedrPages.SERVER_DOWN.toString(),
    MedrPages.HOME.toString(),
    MedrPages.HELP.toString(),
    MedrPages.POLICY.toString(),
    MedrPages.FAQS.toString(),
    MedrPages.LOGIN.toString(),
    MedrPages.REGISTER.toString(),
    MedrPages.REGISTERED_CHECK_EMAIL.toString(),
    MedrPages.PASSWORD_SEND_RESET_EMAIL.toString(),
    MedrPages.PASSWORD_SEND_RESET_SUCCESS.toString(),
    MedrPages.PASSWORD_SET_NEW.toString(),
];

export enum UserGroups {
    FULL_ADMIN = 'Full Admin',
    STAFF = 'Staff',
    CLINICIAN = 'Clinician',
    PATIENT = 'Patient',
    DELEGATE = 'Delegate',
}

export const allStaffGroups = [
    UserGroups.FULL_ADMIN.toString(),
    UserGroups.STAFF.toString(),
];

export enum MedrEmails {
    ENQUIRIES = 'enquiries@medr.co.uk',
}
