import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useAuthService} from '@/actions';
import {RootState} from '@/reducers';
import {
    RegistrationContextType,
    RegistrationData,
    RegistrationClinicianContact,
    ExistingInsuranceProvider,
    SelectedJobrole,
} from '@/contexts/RegistrationContext';
import {NewInsuranceProvider} from '@/components/clinicians/InsuranceProviderDisplay/InsuranceProviderForm';
import {JobRole, RegisterUserRequest} from '@/generated-client';
import {MedrPages} from '@/constants/variables';
import {ClinicFormData} from '@/components/clinicians/ClinicForm.interfaces';

function createDefaultRegistrationData(): RegistrationData {
    return {
        isReview: false,
        email: '',
        title: '',
        firstName: '',
        lastName: '',
        password: '',
        rePassword: '',
        jobRoleId: 0,
        jobRoles: [],
        isDoctor: false,
        registrationNumber: '',
        registrationBody: '',
        dob: '',
        clinicianDescription: '',
        clinics: [],
        appointmentsContacts: [],
        existingProviders: [],
        newProviders: [],
    };
}

const useUserRegistration = (): RegistrationContextType => {
    const navigate = useNavigate();
    const authService = useAuthService();
    const [registrationData, setRegistrationData] = useState<RegistrationData>(
        createDefaultRegistrationData()
    );
    const [showError, setShowError] = useState<boolean>(false);
    const toggleShowError = () => setShowError((prevState) => !prevState);

    const resetRegistrationData = () =>
        setRegistrationData(createDefaultRegistrationData());
    const updateRegistrationData = (newData: Partial<RegistrationData>) =>
        setRegistrationData((prevState) => ({...prevState, ...newData}));

    const updateAppContactsOnMainEmailChange = () => {
        const appCons = registrationData.appointmentsContacts;
        if (appCons.some((item) => item.isMain)) {
            const newFiltered = appCons.map((item) =>
                item.isMain ? {...item, email: registrationData.email} : item
            );
            updateRegistrationData({appointmentsContacts: newFiltered});
            return;
        }
        const mainEmailContact = {
            type: 'email',
            email: registrationData.email,
            phoneNumber: '',
            isMain: true,
        };
        const newList = [mainEmailContact, ...appCons];
        updateRegistrationData({appointmentsContacts: newList});
    };

    useEffect(() => {
        updateAppContactsOnMainEmailChange();
    }, [registrationData.email]);

    // step management
    const minStep = 0;
    const maxStep = 3;
    const [activeStep, setActiveStep] = useState(0);
    const setNextStep = () =>
        activeStep < maxStep
            ? setActiveStep((previousStep) => previousStep + 1)
            : null;
    const setPreviousStep = () => {
        if (activeStep > minStep) {
            if (activeStep === maxStep) {
                updateRegistrationData({isReview: false});
            }
            setActiveStep((previousStep) => previousStep - 1);
        }
    };

    // job roles
    const jobRoles = useSelector(
        (state: RootState) => state.clinicians.jobRoles
    );
    const doctorIds = useSelector(
        (state: RootState) => state.clinicians.doctorIds
    );

    const checkIsDoctor = (rolesList) => {
        const roleIds = rolesList.map((jr) => jr.id);
        updateRegistrationData({
            isDoctor: roleIds.some((id) => doctorIds.includes(id)),
        });
    };

    const addJobRole = (jr: SelectedJobrole) => {
        if (!jr || !jr.jobRoleId) return;
        const {jobRoleId} = jr;

        const newRoles = jobRoles.filter((jobr) => jobr.id === jobRoleId);
        if (newRoles.length < 1) return;

        const alreadyAdded = registrationData.jobRoles.some(
            (jobr) => jobr.id === jobRoleId
        );
        if (alreadyAdded) return;

        const newRolesList = [...registrationData.jobRoles, newRoles[0]];
        updateRegistrationData({
            jobRoles: newRolesList,
            registrationBody: newRoles[0].registrationBody,
        });
        checkIsDoctor(newRoles);
    };

    const removeJobRole = (jr: JobRole) => {
        const roles = registrationData.jobRoles.filter(
            (item) => item.id !== jr.id
        );
        updateRegistrationData({jobRoles: roles});
    };

    // clinics
    const addClinic = (clinic: ClinicFormData) => {
        const newListIndexed = [...registrationData.clinics, clinic].map(
            (item, index) => ({
                ...item,
                listInd: index,
            })
        );
        updateRegistrationData({clinics: newListIndexed});
    };

    const editClinic = (clinic: ClinicFormData) => {
        const newList = registrationData.clinics.map((item) =>
            item.listInd === clinic.listInd ? clinic : item
        );
        const newListIndexed = newList.map((item, index) => ({
            ...item,
            listInd: index,
        }));
        updateRegistrationData({clinics: newListIndexed});
    };

    const removeClinic = (e: ClinicFormData) => {
        const newList = registrationData.clinics.filter(
            (item) =>
                !Object.keys(item).every(
                    (key) =>
                        item[key as keyof typeof item] ===
                        e[key as keyof typeof e]
                )
        );
        const newListIndexed = newList.map((item, index) => ({
            ...item,
            listInd: index,
        }));
        updateRegistrationData({clinics: newListIndexed});
    };

    // appointments contacts
    const updateAppointmentContacts = (
        contacts: RegistrationClinicianContact[]
    ) => {
        if (!contacts || contacts.length < 1) {
            updateRegistrationData({
                appointmentsContacts: [
                    {
                        type: 'email',
                        email: registrationData.email,
                        phoneNumber: '',
                        isMain: true,
                    },
                ],
            });
            return;
        }
        updateRegistrationData({appointmentsContacts: contacts});
    };

    // insurance providers
    const updateExisitingProviders = (
        providers: ExistingInsuranceProvider[]
    ) => {
        if (!providers) {
            updateRegistrationData({existingProviders: []});
            return;
        }
        updateRegistrationData({existingProviders: providers});
    };

    const updateNewProviders = (newProviders: NewInsuranceProvider[]) => {
        if (!newProviders) {
            updateRegistrationData({newProviders: []});
            return;
        }
        updateRegistrationData({newProviders});
    };

    useEffect(() => {
        if (activeStep === maxStep) {
            updateRegistrationData({isReview: activeStep === maxStep});
        }
    }, [activeStep]);

    const onSubmit = async () => {
        const {
            email,
            password,
            rePassword,
            title,
            firstName,
            lastName,
            dob,
            jobRoles,
            registrationNumber,
            clinics,
            existingProviders,
            newProviders,
            clinicianDescription,
            appointmentsContacts,
        } = registrationData;
        const jobRoleIds = jobRoles.map((item) => item.id);
        const appCont = appointmentsContacts.map((item) => ({
            type: item.type,
            email: item.email,
            phoneNumber: item.phoneNumber,
        }));
        const requestData: RegisterUserRequest = {
            email,
            password,
            rePassword,
            title,
            firstName,
            lastName,
            dob,
            registrationNumber,
            jobRoleIds,
            clinics,
            existingProviders,
            newProviders,
            clinicianDescription,
            appointmentsContacts: appCont,
        };
        const successful = await authService.register(requestData);
        if (!successful) {
            toggleShowError();
            return;
        }
        navigate(MedrPages.REGISTERED_CHECK_EMAIL);
    };

    return {
        activeStep,
        setNextStep,
        setPreviousStep,
        registrationData,
        resetRegistrationData,
        updateRegistrationData,
        addJobRole,
        removeJobRole,
        addClinic,
        editClinic,
        removeClinic,
        updateAppointmentContacts,
        updateExisitingProviders,
        updateNewProviders,
        onSubmit,
        showError,
        toggleShowError,
    };
};

export default useUserRegistration;
