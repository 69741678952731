import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Tabs, Tab} from 'react-bootstrap';
import PatientCardsDisplay from '@/components/patient/PatientCardsDisplay';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import PatientListTabsDropDown from '@/components/patient/PatientListTabsDropDown';
import {usePatientService} from '@/actions';
import {RootState} from '@/reducers';
import HelpIcon, {GroupsHelpModal} from '@components/app/parts/HelpIcon';

enum PatientListTabs {
    ADDED = 'addedPatients',
    REFERRED = 'referredPatients',
    PENDING = 'pendingPatients',
    ACCEPTED = 'acceptedPatients',
    COMPLETED = 'completedPatients',
}

const tabLabels = {
    [PatientListTabs.ADDED]: 'Added',
    [PatientListTabs.REFERRED]: 'Referred',
    [PatientListTabs.PENDING]: 'Received',
    [PatientListTabs.ACCEPTED]: 'Accepted',
    [PatientListTabs.COMPLETED]: 'Completed',
};

const tabTitles = {
    [PatientListTabs.ADDED]: ['Name', 'DOB', 'Sex'],
    [PatientListTabs.REFERRED]: ['Name', 'DOB', 'Sex'],
    [PatientListTabs.PENDING]: ['Name', 'DOB', 'Sex'],
    [PatientListTabs.ACCEPTED]: ['Name', 'DOB', 'Sex'],
    [PatientListTabs.COMPLETED]: ['Name', 'DOB', 'Sex'],
};

const PatientListScreen: React.FC = () => {
    const added = useSelector(
        (state: RootState) => state.patients.addedPatients
    );
    const referred = useSelector(
        (state: RootState) => state.patients.referredPatients
    );
    const pending = useSelector(
        (state: RootState) => state.patients.pendingPatients
    );
    const accepted = useSelector(
        (state: RootState) => state.patients.acceptedPatients
    );
    const completed = useSelector(
        (state: RootState) => state.patients.completedPatients
    );

    const {isNarrow} = useScreenInfo();
    const [activeTab, setActiveTab] = useState(PatientListTabs.ADDED);
    const [showHelp, setShowHelp] = React.useState(false);
    const toggleHelp = () => setShowHelp((prev) => !prev);

    const patientService = usePatientService();

    useEffect(() => {
        const handleGetPatients = async () =>
            await patientService.getClinicianPatientList();
        handleGetPatients();
    }, []);

    const viewLabels = {
        addedPatients: 'Patients Added',
        referredPatients: 'Patients Referred',
        pendingPatients: 'Patients Received',
        acceptedPatients: 'Patients Accepted',
        completedPatients: 'Patients Complteted',
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const handleTabChange = (tab: PatientListTabs): void => {
        setActiveTab(tab);
    };

    const groupPoints = [
        {
            label: 'Added',
            description: 'Patients you have added but not yet referred.',
        },
        {label: 'Referred', description: 'Patients you have referred.'},
        {
            label: 'Received',
            description:
                'Patients that have been referred to you and have not been accepted or rejected.',
        },
        {
            label: 'Accepted',
            description:
                'Patients from referrals you have accepted but have not booked.',
        },
        {
            label: 'Completed',
            description: "Patients that have been booked, DNA'ed or cancelled.",
        },
    ];

    const helpModal = (
        <GroupsHelpModal
            showHelp={showHelp}
            toggleHelp={toggleHelp}
            title="Patient Group Information"
            summary="Patients are grouped based on their status. The following groups are available:"
            groupPoints={groupPoints}
        />
    );

    const renderContent = useCallback(() => {
        switch (activeTab) {
            case PatientListTabs.ADDED:
                return (
                    <PatientCardsDisplay
                        patientsList={added}
                        headers={tabTitles[PatientListTabs.ADDED]}
                    />
                );
            case PatientListTabs.REFERRED:
                return (
                    <PatientCardsDisplay
                        patientsList={referred}
                        headers={tabTitles[PatientListTabs.REFERRED]}
                    />
                );
            case PatientListTabs.PENDING:
                return (
                    <PatientCardsDisplay
                        patientsList={pending}
                        showSearch={false}
                        headers={tabTitles[PatientListTabs.PENDING]}
                    />
                );
            case PatientListTabs.ACCEPTED:
                return (
                    <PatientCardsDisplay
                        patientsList={accepted}
                        headers={tabTitles[PatientListTabs.ACCEPTED]}
                    />
                );
            case PatientListTabs.COMPLETED:
                return (
                    <PatientCardsDisplay
                        patientsList={completed}
                        headers={tabTitles[PatientListTabs.COMPLETED]}
                    />
                );
            default:
                return null;
        }
    }, [added, referred, pending, accepted, completed, activeTab]);

    if (isNarrow) {
        const viewOptions = Object.entries(tabLabels).map(([key, label]) => ({
            value: key,
            label,
        }));
        return (
            <div className="patient-list">
                {helpModal}
                <h6 className="text-left p-1 d-flex justify-content-between align-items-center">
                    <span>Select patient group</span>
                    <HelpIcon onClick={toggleHelp} />
                </h6>
                <PatientListTabsDropDown
                    viewName={viewLabels[activeTab]}
                    updateView={handleTabChange}
                    viewOptions={viewOptions}
                />
                {renderContent()}
            </div>
        );
    }

    return (
        <div className="nav-tabs-with-help-container">
            {helpModal}
            <div className="patient-list">
                <Tabs
                    defaultActiveKey={PatientListTabs.ADDED}
                    onSelect={(k) => handleTabChange(k as PatientListTabs)}
                    id="custom-tabs"
                    className="custom-tabs mb-3"
                >
                    {Object.entries(tabLabels).map(([key, label]) => (
                        <Tab
                            key={key}
                            eventKey={key}
                            title={label}
                            className="custom-tabs"
                        >
                            {key === activeTab && renderContent()}
                        </Tab>
                    ))}
                </Tabs>
            </div>
            <div className="help-icon-container-navtabs">
                <HelpIcon onClick={toggleHelp} />
            </div>
        </div>
    );
};

export default PatientListScreen;
