/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClinicianInfoByIdResponse,
} from '../models/index';
import {
    ClinicianInfoByIdResponseFromJSON,
    ClinicianInfoByIdResponseToJSON,
} from '../models/index';

export interface UserCrpInformationRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class UserCrpInformationApi extends runtime.BaseAPI {

    /**
     * Returns the clinician profile information using the clinician profile uid.
     */
    async userCrpInformationRetrieveRaw(requestParameters: UserCrpInformationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicianInfoByIdResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userCrpInformationRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/user-crp-information/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicianInfoByIdResponseFromJSON(jsonValue));
    }

    /**
     * Returns the clinician profile information using the clinician profile uid.
     */
    async userCrpInformationRetrieve(requestParameters: UserCrpInformationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicianInfoByIdResponse> {
        const response = await this.userCrpInformationRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
