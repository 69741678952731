import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';
import {UserProfile} from '@/generated-client';

interface UseDelegateUserReturn {
    userProfile: UserProfile | null;
    clinicianUserProfile: UserProfile | null;
}

const useDelegaetUser = (): UseDelegateUserReturn => {
    const userProfile = useSelector((state: RootState) => state.auth.user);
    const clinicianProfiles = useSelector(
        (state: RootState) => state.profile.clinicianProfiles
    );

    const clinicianUserProfile = useMemo(() => {
        return clinicianProfiles?.[0]?.userProfile ?? null;
    }, [clinicianProfiles]);

    return {
        userProfile: userProfile ?? null,
        clinicianUserProfile,
    };
};

export default useDelegaetUser;
