/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InsuranceAgreement } from './InsuranceAgreement';
import {
    InsuranceAgreementFromJSON,
    InsuranceAgreementFromJSONTyped,
    InsuranceAgreementToJSON,
    InsuranceAgreementToJSONTyped,
} from './InsuranceAgreement';

/**
 * 
 * @export
 * @interface InsuranceAgreementListResponse
 */
export interface InsuranceAgreementListResponse {
    /**
     * 
     * @type {Array<InsuranceAgreement>}
     * @memberof InsuranceAgreementListResponse
     */
    insuranceAgreements: Array<InsuranceAgreement>;
}

/**
 * Check if a given object implements the InsuranceAgreementListResponse interface.
 */
export function instanceOfInsuranceAgreementListResponse(value: object): value is InsuranceAgreementListResponse {
    if (!('insuranceAgreements' in value) || value['insuranceAgreements'] === undefined) return false;
    return true;
}

export function InsuranceAgreementListResponseFromJSON(json: any): InsuranceAgreementListResponse {
    return InsuranceAgreementListResponseFromJSONTyped(json, false);
}

export function InsuranceAgreementListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceAgreementListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'insuranceAgreements': ((json['insuranceAgreements'] as Array<any>).map(InsuranceAgreementFromJSON)),
    };
}

export function InsuranceAgreementListResponseToJSON(json: any): InsuranceAgreementListResponse {
    return InsuranceAgreementListResponseToJSONTyped(json, false);
}

export function InsuranceAgreementListResponseToJSONTyped(value?: InsuranceAgreementListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'insuranceAgreements': ((value['insuranceAgreements'] as Array<any>).map(InsuranceAgreementToJSON)),
    };
}

