/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationAppointmentContact
 */
export interface RegistrationAppointmentContact {
    /**
     * 
     * @type {string}
     * @memberof RegistrationAppointmentContact
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAppointmentContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationAppointmentContact
     */
    phoneNumber?: string;
}

/**
 * Check if a given object implements the RegistrationAppointmentContact interface.
 */
export function instanceOfRegistrationAppointmentContact(value: object): value is RegistrationAppointmentContact {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function RegistrationAppointmentContactFromJSON(json: any): RegistrationAppointmentContact {
    return RegistrationAppointmentContactFromJSONTyped(json, false);
}

export function RegistrationAppointmentContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationAppointmentContact {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    };
}

export function RegistrationAppointmentContactToJSON(json: any): RegistrationAppointmentContact {
    return RegistrationAppointmentContactToJSONTyped(json, false);
}

export function RegistrationAppointmentContactToJSONTyped(value?: RegistrationAppointmentContact | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': value['type'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
    };
}

