import React, {
    useState,
    useRef,
    Dispatch,
    SetStateAction,
    useMemo,
} from 'react';
import {debounce} from 'lodash';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {useAuthService} from '@/actions';
import AddressAutoCompleteField, {
    SearchAddressResult,
} from '../app/parts/FormFields/AddressSearch';
import {ClinicFormData} from './ClinicForm.interfaces';
export interface ClinicFormProps {
    clinicData: ClinicFormData;
    updateClinicData: Dispatch<SetStateAction<ClinicFormData>>;
    handleAddClinic: (formData: ClinicFormData) => void;
    isNew?: boolean;
}

const ClinicForm: React.FC<ClinicFormProps> = ({
    clinicData,
    updateClinicData,
    handleAddClinic,
    isNew = true,
}) => {
    const authService = useAuthService();
    const [gpsError, setGpsError] = useState(false);

    const {clinicName, centreName, address} = clinicData;
    const formAddress = {
        firstLine: address.firstLine ? address.firstLine : '',
        city: address.city ? address.city : '',
        postcode: address.postcode ? address.postcode : '',
    };

    const inputRef = useRef<HTMLInputElement>(null);
    const updateParameter = (val: {
        [key: string]: string | SearchAddressResult;
    }) => {
        updateClinicData({
            ...clinicData,
            ...val,
        });
    };
    const onPlaceSelected = (address: SearchAddressResult) => {
        updateParameter({address});
    };

    const checkPostcode = async (newAddress: SearchAddressResult) => {
        const addressValid = await authService.registerCheckClinic(newAddress);
        if (!addressValid || !addressValid.addressValid) {
            setGpsError(true);
        }
        setGpsError(!addressValid.addressValid);
    };

    const debouncedPostcodeCheck = useMemo(
        () =>
            debounce(
                (newAddress: SearchAddressResult) => checkPostcode(newAddress),
                1000
            ),
        [address]
    );

    const updatePostcode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddress = {
            ...formAddress,
            postcode: e.target.value.toUpperCase(),
        };
        updateParameter({address: newAddress});
        debouncedPostcodeCheck(newAddress);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (gpsError) {
            return;
        }
        handleAddClinic(clinicData);
        if (inputRef.current) {
            inputRef.current.value = null;
        }
    };

    return (
        <div
            className="medr-rounded bg-medr-gray px-3 text-left"
            style={{width: '100%'}}
        >
            <Row>
                <Col>
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="clinicName">
                            <Form.Label className="my-0 bluegreen">
                                Clinic Room
                            </Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="Enter clinic name"
                                className="mb-3"
                                value={clinicName}
                                onChange={(e) =>
                                    updateParameter({
                                        clinicName: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="centreName">
                            <Form.Label className="my-0 bluegreen">
                                Hospital/practice name *
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="centreName"
                                placeholder="Enter hospital/practice"
                                className="mb-3"
                                value={centreName}
                                onChange={(e) =>
                                    updateParameter({
                                        centreName: e.target.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <hr />
                        <Form.Group
                            controlId="addressAutocomplete"
                            className="mt-2"
                        >
                            <Form.Label className="my-0 bluegreen">
                                Search Address
                            </Form.Label>
                            <AddressAutoCompleteField
                                inputRef={inputRef}
                                onPlaceSelected={onPlaceSelected}
                            />
                        </Form.Group>
                        <hr />

                        <Form.Group controlId="firstLine">
                            <Form.Label className="my-0 bluegreen">
                                First line *
                            </Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter first line of address"
                                className="mb-3"
                                value={address.firstLine}
                                onChange={(e) =>
                                    updateParameter({
                                        address: {
                                            ...formAddress,
                                            firstLine: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group controlId="city">
                            <Form.Label className="my-0 bluegreen">
                                City *
                            </Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter city"
                                className="mb-3"
                                value={address.city}
                                onChange={(e) =>
                                    updateParameter({
                                        address: {
                                            ...formAddress,
                                            city: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group controlId="postcode">
                            <Form.Label className="my-0 bluegreen">
                                Postcode *
                            </Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter postcode"
                                className="mb-3"
                                value={address.postcode}
                                onChange={updatePostcode}
                                isInvalid={gpsError}
                            />
                            <Form.Control.Feedback type="invalid">
                                Address not found. Please check details.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Row>
                            <Col className="text-center mt-4">
                                <Button
                                    className="sel-button w100"
                                    type="submit"
                                >
                                    {isNew ? 'Add clinic' : 'Update clinic'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ClinicForm;
