/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserLists } from './UserLists';
import {
    UserListsFromJSON,
    UserListsFromJSONTyped,
    UserListsToJSON,
    UserListsToJSONTyped,
} from './UserLists';

/**
 * 
 * @export
 * @interface UserListForAdminResponse
 */
export interface UserListForAdminResponse {
    /**
     * 
     * @type {UserLists}
     * @memberof UserListForAdminResponse
     */
    users: UserLists;
}

/**
 * Check if a given object implements the UserListForAdminResponse interface.
 */
export function instanceOfUserListForAdminResponse(value: object): value is UserListForAdminResponse {
    if (!('users' in value) || value['users'] === undefined) return false;
    return true;
}

export function UserListForAdminResponseFromJSON(json: any): UserListForAdminResponse {
    return UserListForAdminResponseFromJSONTyped(json, false);
}

export function UserListForAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListForAdminResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'users': UserListsFromJSON(json['users']),
    };
}

export function UserListForAdminResponseToJSON(json: any): UserListForAdminResponse {
    return UserListForAdminResponseToJSONTyped(json, false);
}

export function UserListForAdminResponseToJSONTyped(value?: UserListForAdminResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'users': UserListsToJSON(value['users']),
    };
}

