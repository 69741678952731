import React, {useCallback} from 'react';
import {Row, Col} from 'react-bootstrap';
import PatientEntryForm from '@/components/patient/PatientEntryForm';
import {usePatientService} from '@/actions';

interface RequestResult {
    success: boolean;
    // data?: any; // Replace 'any' with a more specific type if available
}

const PatientEntryScreen: React.FC = () => {
    const patientService = usePatientService();
    const handleSuccessfulRequest = useCallback(
        (results: RequestResult) => {
            if (results.success) {
                patientService.goToPatientsList();
            }
        },
        [patientService]
    );

    return (
        <div
            className="medr-layout text-center newpatient-page"
            style={{maxWidth: '700px', margin: '0 auto'}}
        >
            <Row className="medr-layout text-center">
                <Col>
                    <h4>Enter Patient Information</h4>
                </Col>
            </Row>

            <PatientEntryForm isSuccessful={handleSuccessfulRequest} />
        </div>
    );
};

export default PatientEntryScreen;
