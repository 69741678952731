import {Patient} from '@/generated-client';

export interface PatientBarProps {
    patient: Patient;
}

export enum DisplayType {
    Added = 'Added',
    Sent = 'Sent',
    Accepted = 'Accepted',
    Pending = 'Pending',
    Open = 'Open',
    Init = 'Init',
}

export interface ButtonFormatProps {
    toolTipText: string;
    buttonText: string;
    variant: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isDisabled: boolean;
}

export interface UsePatientBarReturn {
    selectedView: DisplayType;
    isOpen: boolean;
    handleClickOpen: () => void;
    permissionCreateReferrals: boolean;
    permissionManageReferrals: boolean;
    onClickMakeReferButton: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    onClickMakeReferOnwardButton: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    onClickUpdateButton: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    onClickDeleteButton: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    onClickSeeReferralsButton: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
}
