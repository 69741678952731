/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendErrorInApplictionInfo
 */
export interface SendErrorInApplictionInfo {
    /**
     * 
     * @type {string}
     * @memberof SendErrorInApplictionInfo
     */
    errorDescription: string;
}

/**
 * Check if a given object implements the SendErrorInApplictionInfo interface.
 */
export function instanceOfSendErrorInApplictionInfo(value: object): value is SendErrorInApplictionInfo {
    if (!('errorDescription' in value) || value['errorDescription'] === undefined) return false;
    return true;
}

export function SendErrorInApplictionInfoFromJSON(json: any): SendErrorInApplictionInfo {
    return SendErrorInApplictionInfoFromJSONTyped(json, false);
}

export function SendErrorInApplictionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendErrorInApplictionInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'errorDescription': json['errorDescription'],
    };
}

export function SendErrorInApplictionInfoToJSON(json: any): SendErrorInApplictionInfo {
    return SendErrorInApplictionInfoToJSONTyped(json, false);
}

export function SendErrorInApplictionInfoToJSONTyped(value?: SendErrorInApplictionInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'errorDescription': value['errorDescription'],
    };
}

