import React from 'react';
import {Form} from 'react-bootstrap';

interface ClinicianFilterInputProps {
    setFilterString: (filterString: string) => void;
}

const ClinicianFilterInput: React.FC<ClinicianFilterInputProps> = ({
    setFilterString,
}) => {
    const updateStrs = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault();
        setFilterString(e.target.value);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <Form className="text-left">
            <Form.Group controlId="cxSearchBar" className="mb-3">
                <Form.Control
                    required
                    type="name"
                    placeholder="Search clinician by name"
                    name="searchStr"
                    onChange={(e) => updateStrs(e)}
                    onKeyDown={handleKeyDown}
                />
            </Form.Group>
        </Form>
    );
};

export default ClinicianFilterInput;
