/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LastLoggedIn
 */
export interface LastLoggedIn {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof LastLoggedIn
     */
    clinicians: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof LastLoggedIn
     */
    delegates: { [key: string]: { [key: string]: number; }; };
}

/**
 * Check if a given object implements the LastLoggedIn interface.
 */
export function instanceOfLastLoggedIn(value: object): value is LastLoggedIn {
    if (!('clinicians' in value) || value['clinicians'] === undefined) return false;
    if (!('delegates' in value) || value['delegates'] === undefined) return false;
    return true;
}

export function LastLoggedInFromJSON(json: any): LastLoggedIn {
    return LastLoggedInFromJSONTyped(json, false);
}

export function LastLoggedInFromJSONTyped(json: any, ignoreDiscriminator: boolean): LastLoggedIn {
    if (json == null) {
        return json;
    }
    return {
        
        'clinicians': json['clinicians'],
        'delegates': json['delegates'],
    };
}

export function LastLoggedInToJSON(json: any): LastLoggedIn {
    return LastLoggedInToJSONTyped(json, false);
}

export function LastLoggedInToJSONTyped(value?: LastLoggedIn | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clinicians': value['clinicians'],
        'delegates': value['delegates'],
    };
}

