import React, {useState} from 'react';
import {Row, Col, Tabs, Tab, Button} from 'react-bootstrap';
import {termsPolicyPath, cookiesPolicyPath} from '@/constants';

type DocumentType = 'terms' | 'cookies';

interface Documents {
    [key: string]: string;
}

interface ButtonText {
    [key: string]: string;
}

const PoliciesScreen: React.FC = () => {
    const [showDocumentType, setShowDocumentType] =
        useState<DocumentType>('terms');
    const documents: Documents = {
        terms: termsPolicyPath,
        cookies: cookiesPolicyPath,
    };
    const buttonText: ButtonText = {
        terms: 'Download terms and conditions',
        cookies: 'Download cookies policy',
    };
    return (
        <div>
            <Row>
                <Col className="text-center">
                    <Tabs
                        defaultActiveKey="terms"
                        id="custom-tabs"
                        className="custom-tabs mb-3"
                        onSelect={(k) => setShowDocumentType(k as DocumentType)}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Tab
                            eventKey="terms"
                            title="Terms and conditions"
                            className="custom-tabs"
                        />
                        <Tab
                            eventKey="cookies"
                            title="Cookies policy"
                            className="custom-tabs"
                        />
                    </Tabs>
                </Col>
            </Row>
            <Row className="text-center">
                <Col className="text-center">
                    <iframe
                        title="terms and condition"
                        src={documents[showDocumentType]}
                        // type="application/pdf"
                        height="500px"
                        width="700px"
                        className="responsive"
                    />
                </Col>
            </Row>
            <Row className="text-center">
                <Col className="test-center">
                    <Button
                        className="w50"
                        variant="info"
                        href={documents[showDocumentType]}
                    >
                        {buttonText[showDocumentType]}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default PoliciesScreen;
