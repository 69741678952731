/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DelegateUserLink } from './DelegateUserLink';
import {
    DelegateUserLinkFromJSON,
    DelegateUserLinkFromJSONTyped,
    DelegateUserLinkToJSON,
    DelegateUserLinkToJSONTyped,
} from './DelegateUserLink';

/**
 * 
 * @export
 * @interface DelegatesListResponse
 */
export interface DelegatesListResponse {
    /**
     * 
     * @type {Array<DelegateUserLink>}
     * @memberof DelegatesListResponse
     */
    delegates: Array<DelegateUserLink>;
    /**
     * 
     * @type {Array<DelegateUserLink>}
     * @memberof DelegatesListResponse
     */
    pendingDelegates: Array<DelegateUserLink>;
}

/**
 * Check if a given object implements the DelegatesListResponse interface.
 */
export function instanceOfDelegatesListResponse(value: object): value is DelegatesListResponse {
    if (!('delegates' in value) || value['delegates'] === undefined) return false;
    if (!('pendingDelegates' in value) || value['pendingDelegates'] === undefined) return false;
    return true;
}

export function DelegatesListResponseFromJSON(json: any): DelegatesListResponse {
    return DelegatesListResponseFromJSONTyped(json, false);
}

export function DelegatesListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegatesListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'delegates': ((json['delegates'] as Array<any>).map(DelegateUserLinkFromJSON)),
        'pendingDelegates': ((json['pendingDelegates'] as Array<any>).map(DelegateUserLinkFromJSON)),
    };
}

export function DelegatesListResponseToJSON(json: any): DelegatesListResponse {
    return DelegatesListResponseToJSONTyped(json, false);
}

export function DelegatesListResponseToJSONTyped(value?: DelegatesListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'delegates': ((value['delegates'] as Array<any>).map(DelegateUserLinkToJSON)),
        'pendingDelegates': ((value['pendingDelegates'] as Array<any>).map(DelegateUserLinkToJSON)),
    };
}

