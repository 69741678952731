import React from 'react';
import {useSelector} from 'react-redux';
import {Modal, Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {MedrPages} from '@/constants/variables';
import {RootState} from '@/reducers';
interface HelpRequestPopupProps {
    showModal: boolean;
    handleClose: () => void;
}

const HelpRequestPopup: React.FC<HelpRequestPopupProps> = ({
    showModal,
    handleClose,
}) => {
    const isAuthenticated = useSelector(
        (state: RootState) => state.auth.isAuthenticated
    );
    const navigate = useNavigate();
    const onClick = (): void => {
        const path = isAuthenticated
            ? MedrPages.DASHBOARD.toString()
            : MedrPages.HOME.toString();
        navigate(path);
        handleClose();
    };
    return (
        <Modal show={showModal} onHide={onClick}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv">
                    Help request sent
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="medr-inv">
                <p>
                    Your request has been sent to the MedR team. We will respond
                    as soon as possible.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" onClick={onClick}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default HelpRequestPopup;
