/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TargetCoordinartes
 */
export interface TargetCoordinartes {
    /**
     * 
     * @type {number}
     * @memberof TargetCoordinartes
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof TargetCoordinartes
     */
    lng: number;
}

/**
 * Check if a given object implements the TargetCoordinartes interface.
 */
export function instanceOfTargetCoordinartes(value: object): value is TargetCoordinartes {
    if (!('lat' in value) || value['lat'] === undefined) return false;
    if (!('lng' in value) || value['lng'] === undefined) return false;
    return true;
}

export function TargetCoordinartesFromJSON(json: any): TargetCoordinartes {
    return TargetCoordinartesFromJSONTyped(json, false);
}

export function TargetCoordinartesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetCoordinartes {
    if (json == null) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        'lng': json['lng'],
    };
}

export function TargetCoordinartesToJSON(json: any): TargetCoordinartes {
    return TargetCoordinartesToJSONTyped(json, false);
}

export function TargetCoordinartesToJSONTyped(value?: TargetCoordinartes | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'lat': value['lat'],
        'lng': value['lng'],
    };
}

