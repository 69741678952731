import React from 'react';
import {Button, Form, Row, Col} from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';

export interface NewInsuranceProvider {
    provider: string;
}

interface NewInsuranceProviderBarProps {
    newInsurance: NewInsuranceProvider;
    removeProvider: (provider: NewInsuranceProvider) => void;
}

const NewInsuranceProviderBar: React.FC<NewInsuranceProviderBarProps> = ({
    newInsurance,
    removeProvider,
}) => {
    if (!newInsurance) {
        return <div>Missing new insurance provider</div>;
    }
    const {provider} = newInsurance;

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        removeProvider(newInsurance);
    };
    return (
        <div
            className="medr-rounded-inv notif-setting-bar d-flex flex-align-items-center px-3"
            style={{justifyContent: 'space-between', width: 'unset'}}
        >
            <div>{provider}</div>
            <div>
                <Button variant="danger" onClick={onClick}>
                    Remove
                </Button>
            </div>
        </div>
    );
};

interface InsuranceProviderFormProps {
    newProviders: NewInsuranceProvider[];
    updateNewProviders: (providers: NewInsuranceProvider[]) => void;
}

const InsuranceProviderForm: React.FC<InsuranceProviderFormProps> = ({
    newProviders,
    updateNewProviders,
}) => {
    const [showForm, setShowForm] = React.useState<boolean>(false);
    const [currentNewProvider, setCurrentNewProvider] =
        React.useState<NewInsuranceProvider>({
            provider: '',
        });

    const toggleShowForm = () => {
        setShowForm((prev) => !prev);
    };

    const addNewProvider = () => {
        const updatedProviders = [...newProviders, currentNewProvider];
        updateNewProviders(updatedProviders);
        setCurrentNewProvider({provider: ''});
    };

    const removeNewProvider = (provider: NewInsuranceProvider) => {
        const updatedProviders = newProviders.filter(
            (newInsurance) => newInsurance.provider !== provider.provider
        );
        updateNewProviders(updatedProviders);
    };

    const addedNewProviders = newProviders
        ? newProviders.map((newInsurance) => {
              return (
                  <NewInsuranceProviderBar
                      key={uuidv4()}
                      newInsurance={newInsurance}
                      removeProvider={() => removeNewProvider(newInsurance)}
                  />
              );
          })
        : null;

    const {provider} = currentNewProvider;

    const clickAddNewProvider = (e: React.MouseEvent) => {
        e.preventDefault();
        if (provider) {
            addNewProvider();
        }
    };

    return (
        <div className="medr-rounded bg-medr-gray mb-4 text-left">
            <Row className="my-1">
                <Col className="text-center">
                    <h4 className="bluegreen">Requested Insurance Providers</h4>
                </Col>
            </Row>
            {newProviders && addedNewProviders}
            <Row className="my-3">
                <Col className="text-center">
                    <Button
                        className="sel-button w80"
                        variant={showForm ? 'danger' : 'info'}
                        onClick={toggleShowForm}
                    >
                        {showForm ? 'Hide form' : 'Add new provider'}
                    </Button>
                </Col>
            </Row>
            {showForm && (
                <Form>
                    <div className="d-flex" style={{alignItems: 'end'}}>
                        <div className="col-md-8">
                            <Form.Group controlId="providerName">
                                <Form.Label className="my-0 bluegreen">
                                    Provider
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="name"
                                    placeholder="Enter insurance provider"
                                    value={provider}
                                    onChange={(e) =>
                                        setCurrentNewProvider({
                                            provider: e.target.value,
                                        })
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Button
                                className="sel-button w80"
                                onClick={clickAddNewProvider}
                            >
                                Save new provider
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};

export default InsuranceProviderForm;
