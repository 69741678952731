import React, {useState} from 'react';
import useUserPermissions from '@hooks/user/useUserPermissions';
import {usePatientService} from '@/actions';
import {Patient} from '@/generated-client';
import {UsePatientBarReturn, DisplayType} from './interfaces';

const usePatientBar = (patient: Patient): UsePatientBarReturn => {
    const patientService = usePatientService();

    const handleGetPatientById = async (pxId: number) =>
        await patientService.getPatientById(pxId);

    const handleDeletePatient = async (pxId: number) =>
        await patientService.deletePatientById(pxId);

    const {permissionCreateReferrals, permissionManageReferrals} =
        useUserPermissions();

    const setPateintView = (referral: Patient): DisplayType | null => {
        if (
            Object.values(DisplayType).includes(
                referral.displayType as DisplayType
            )
        ) {
            const view =
                DisplayType[referral.displayType as keyof typeof DisplayType];
            return view;
        }
        return null;
    };
    const selectedView = setPateintView(patient);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleClickOpen = () => setIsOpen((prev) => !prev);

    // BUTTON FUNCTIONS

    const onClickMakeReferButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        handleGetPatientById(patient.id);
        patientService.goToReferPatient(patient.id);
    };

    const onClickMakeReferOnwardButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        handleGetPatientById(patient.id);
        patientService.goToReferPatient(patient.id);
    };

    const onClickUpdateButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        patientService.goToEditPatient(patient.id);
    };

    const onClickDeleteButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        handleDeletePatient(patient.id);
    };

    const onClickSeeReferralsButton = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();
        patientService.gotToReceivedReferrals();
    };

    return {
        selectedView,
        isOpen,
        handleClickOpen,
        permissionCreateReferrals,
        permissionManageReferrals,
        onClickMakeReferButton,
        onClickMakeReferOnwardButton,
        onClickUpdateButton,
        onClickDeleteButton,
        onClickSeeReferralsButton,
    };
};

export default usePatientBar;
