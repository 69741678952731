import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useClinicianService} from '@/actions';

interface ProfileRemoveClinicProps {
    userProfileId: number;
    clinicId: number;
    showPopup: boolean;
    handleClose: () => void;
}

const ProfileRemoveClinic: React.FC<ProfileRemoveClinicProps> = ({
    userProfileId,
    clinicId,
    showPopup,
    handleClose,
}) => {
    const clinicianService = useClinicianService();

    const handleRequestRemoveCrp = async () => {
        const reqSuccess = await clinicianService.postRequestRemoveCrp({
            clinicId,
            userProfileId,
        });
        if (!reqSuccess) return;
        handleClose();
    };

    const onClick = () => handleRequestRemoveCrp();

    return (
        <Modal show={showPopup} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv" style={{fontSize: '1.1rem'}}>
                    Are you sure you want to remove this clinic?
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={onClick}>
                    Remove Clinic
                </Button>
                <Button variant="info" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfileRemoveClinic;

interface MoreProfilesRequiredProps {
    showPopup: boolean;
    handleClose: () => void;
}

export const MoreProfilesRequired: React.FC<MoreProfilesRequiredProps> = ({
    showPopup,
    handleClose,
}) => {
    return (
        <Modal show={showPopup} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className="medr-inv">
                    Request to remove clinic
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    You must be associated with at least one clinic. Please add
                    your current clinic before removing this one.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
