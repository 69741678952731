/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClinicianRoleProfile } from './ClinicianRoleProfile';
import {
    ClinicianRoleProfileFromJSON,
    ClinicianRoleProfileFromJSONTyped,
    ClinicianRoleProfileToJSON,
    ClinicianRoleProfileToJSONTyped,
} from './ClinicianRoleProfile';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface CliniciansLists
 */
export interface CliniciansLists {
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof CliniciansLists
     */
    staff: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof CliniciansLists
     */
    unverified: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof CliniciansLists
     */
    deactivated: Array<ClinicianRoleProfile>;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof CliniciansLists
     */
    delegates: Array<UserProfile>;
    /**
     * 
     * @type {Array<ClinicianRoleProfile>}
     * @memberof CliniciansLists
     */
    clinicians: Array<ClinicianRoleProfile>;
}

/**
 * Check if a given object implements the CliniciansLists interface.
 */
export function instanceOfCliniciansLists(value: object): value is CliniciansLists {
    if (!('staff' in value) || value['staff'] === undefined) return false;
    if (!('unverified' in value) || value['unverified'] === undefined) return false;
    if (!('deactivated' in value) || value['deactivated'] === undefined) return false;
    if (!('delegates' in value) || value['delegates'] === undefined) return false;
    if (!('clinicians' in value) || value['clinicians'] === undefined) return false;
    return true;
}

export function CliniciansListsFromJSON(json: any): CliniciansLists {
    return CliniciansListsFromJSONTyped(json, false);
}

export function CliniciansListsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CliniciansLists {
    if (json == null) {
        return json;
    }
    return {
        
        'staff': ((json['staff'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'unverified': ((json['unverified'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'deactivated': ((json['deactivated'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
        'delegates': ((json['delegates'] as Array<any>).map(UserProfileFromJSON)),
        'clinicians': ((json['clinicians'] as Array<any>).map(ClinicianRoleProfileFromJSON)),
    };
}

export function CliniciansListsToJSON(json: any): CliniciansLists {
    return CliniciansListsToJSONTyped(json, false);
}

export function CliniciansListsToJSONTyped(value?: CliniciansLists | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'staff': ((value['staff'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'unverified': ((value['unverified'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'deactivated': ((value['deactivated'] as Array<any>).map(ClinicianRoleProfileToJSON)),
        'delegates': ((value['delegates'] as Array<any>).map(UserProfileToJSON)),
        'clinicians': ((value['clinicians'] as Array<any>).map(ClinicianRoleProfileToJSON)),
    };
}

