/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    MessageToJSONTyped,
} from './Message';

/**
 * 
 * @export
 * @interface LogoutUserResponse
 */
export interface LogoutUserResponse {
    /**
     * 
     * @type {Message}
     * @memberof LogoutUserResponse
     */
    message: Message;
}

/**
 * Check if a given object implements the LogoutUserResponse interface.
 */
export function instanceOfLogoutUserResponse(value: object): value is LogoutUserResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function LogoutUserResponseFromJSON(json: any): LogoutUserResponse {
    return LogoutUserResponseFromJSONTyped(json, false);
}

export function LogoutUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogoutUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': MessageFromJSON(json['message']),
    };
}

export function LogoutUserResponseToJSON(json: any): LogoutUserResponse {
    return LogoutUserResponseToJSONTyped(json, false);
}

export function LogoutUserResponseToJSONTyped(value?: LogoutUserResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': MessageToJSON(value['message']),
    };
}

