import {createActionTypes, ActionStatus} from './requestConstant';

export const USER_UPDATE_PASSWORD_ACTION: ActionStatus = createActionTypes(
    'USER_UPDATE_PASSWORD'
);
export const UPDATE_PROFILE_PIC_ACTION: ActionStatus =
    createActionTypes('UPDATE_PROFILE_PIC');

// export const PROFILE_PICTURE_UPLOAD_ACTION: ActionStatus = createActionTypes( 'PROFILE_PICTURE_UPLOAD' );

// // Profile Dispatch States
// export const PROFILE_PICTURE_UPLOAD_REQUEST: string = 'PROFILE_PICTURE_UPLOAD_REQUEST';
// export const PROFILE_PICTURE_UPLOAD_SUCCESS: string = 'PROFILE_PICTURE_UPLOAD_SUCCESS';
// export const PROFILE_PICTURE_UPLOAD_FAIL: string = 'PROFILE_PICTURE_UPLOAD_FAIL';

// old
// export const USER_LOGIN_REQUEST: string = 'USER_LOGIN_REQUEST';
// export const USER_LOGIN_SUCCESS: string = 'USER_LOGIN_SUCCESS';
// export const USER_LOGIN_FAIL: string = 'USER_LOGIN_FAIL';

// export const USER_LOGOUT: string = 'USER_LOGOUT';

// export const USER_REGISTER_REQUEST: string = 'USER_REGISTER_REQUEST';
// export const USER_REGISTER_SUCCESS: string = 'USER_REGISTER_SUCCESS';
// export const USER_REGISTER_FAIL: string = 'USER_REGISTER_FAIL';

// export const USER_DETAILS_REQUEST: string = 'USER_DETAILS_REQUEST';
// export const USER_DETAILS_SUCCESS: string = 'USER_DETAILS_SUCCESS';
// export const USER_DETAILS_FAIL: string = 'USER_DETAILS_FAIL';
// export const USER_DETAILS_RESET: string = 'USER_DETAILS_RESET';

// export const USER_UPDATE_PROFILE_RESET: string = 'USER_UPDATE_PROFILE_RESET';

// export const USER_LIST_REQUEST: string = 'USER_LIST_REQUEST';
// export const USER_LIST_SUCCESS: string = 'USER_LIST_SUCCESS';
// export const USER_LIST_FAIL: string = 'USER_LIST_FAIL';
// export const USER_LIST_RESET: string = 'USER_LIST_RESET';

// export const USER_DELETE_REQUEST: string = 'USER_DELETE_REQUEST';
// export const USER_DELETE_SUCCESS: string = 'USER_DELETE_SUCCESS';
// export const USER_DELETE_FAIL: string = 'USER_DELETE_FAIL';

// export const USER_UPDATE_REQUEST: string = 'USER_UPDATE_REQUEST';
// export const USER_UPDATE_SUCCESS: string = 'USER_UPDATE_SUCCESS';
// export const USER_UPDATE_FAIL: string = 'USER_UPDATE_FAIL';
// export const USER_UPDATE_RESET: string = 'USER_UPDATE_RESET';

// ###############################
// export const USER_UPDATE_PROFILE_REQUEST: string = 'USER_UPDATE_PROFILE_REQUEST';
// export const USER_UPDATE_PROFILE_SUCCESS: string = 'USER_UPDATE_PROFILE_SUCCESS';
// export const USER_UPDATE_PROFILE_FAIL: string = 'USER_UPDATE_PROFILE_FAIL';
