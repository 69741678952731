import {useMemo} from 'react';
import {Icon, LatLngTuple} from 'leaflet';
import iconUrl from './target-marker-icon.png';

interface UseTargetMarkerProps {
    mapCenter: LatLngTuple;
}

interface UseTargetMarkerReturn {
    geocode: LatLngTuple;
    customIcon: Icon;
}

const useTargetMarker = ({
    mapCenter,
}: UseTargetMarkerProps): UseTargetMarkerReturn => {
    const geocode = mapCenter;

    const customIcon = useMemo(
        () =>
            new Icon({
                iconUrl: iconUrl,
                iconSize: [25, 25],
            }),
        []
    );

    return {
        geocode,
        customIcon,
    };
};

export default useTargetMarker;
