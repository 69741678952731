import React, {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Tabs, Tab} from 'react-bootstrap';
import ReferralListDisplay from '@/components/referrals/ReferralListDisplay';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import ReferralListTabsDropDown from '@/components/referrals/ReferralListTabsDropDown';
import useReferralInboundScreen from './hooks';
import {ReferralInboundTabs, tabLabels, tabOptions} from './interface';
import HelpIcon, {GroupsHelpModal} from '@components/app/parts/HelpIcon';

const ReferralsInboundScreen: React.FC = () => {
    const {isNarrow} = useScreenInfo();
    const [searchParams] = useSearchParams();
    const [showHelp, setShowHelp] = React.useState(false);
    const toggleHelp = () => setShowHelp((prev) => !prev);

    let searchTab = ReferralInboundTabs.PENDING;
    const tab = searchParams.get('referralType');
    const tabEnum: ReferralInboundTabs | null = tab
        ? Object.values(ReferralInboundTabs).find((value) => value === tab)
        : null;

    if (tabEnum) {
        searchTab = tabEnum;
    }
    const {
        selectedTab,
        setSelectedTab,
        inboundPending,
        inboundAccepted,
        inboundBooked,
        inboundDna,
        referralsOpen,
    } = useReferralInboundScreen(searchTab);

    const rxListHeaders = {
        [ReferralInboundTabs.PENDING]: [
            'Patient',
            'DOB',
            'Sex',
            'Referrer',
            '',
        ],
        [ReferralInboundTabs.ACCEPTED]: [
            'Patient',
            'DOB',
            'Sex',
            'Referrer',
            '',
        ],
        [ReferralInboundTabs.BOOKED]: ['Patient', 'DOB', 'Sex', 'Referrer', ''],
        [ReferralInboundTabs.DNA]: ['Patient', 'DOB', 'Sex', 'Referrer', ''],
        [ReferralInboundTabs.OPEN]: ['Patient', 'DOB', 'Sex', 'Referrer', ''],
    };

    const renderContent = useCallback(() => {
        switch (selectedTab) {
            case ReferralInboundTabs.PENDING:
                return (
                    <ReferralListDisplay
                        referralsList={inboundPending}
                        headers={rxListHeaders[ReferralInboundTabs.PENDING]}
                    />
                );
            case ReferralInboundTabs.OPEN:
                return (
                    <ReferralListDisplay
                        referralsList={referralsOpen}
                        headers={rxListHeaders[ReferralInboundTabs.OPEN]}
                    />
                );
            case ReferralInboundTabs.ACCEPTED:
                return (
                    <ReferralListDisplay
                        referralsList={inboundAccepted}
                        headers={rxListHeaders[ReferralInboundTabs.ACCEPTED]}
                    />
                );
            case ReferralInboundTabs.BOOKED:
                return (
                    <ReferralListDisplay
                        referralsList={inboundBooked}
                        headers={rxListHeaders[ReferralInboundTabs.BOOKED]}
                    />
                );
            case ReferralInboundTabs.DNA:
                return (
                    <ReferralListDisplay
                        referralsList={inboundDna}
                        headers={rxListHeaders[ReferralInboundTabs.DNA]}
                    />
                );
            default:
                return null;
        }
    }, [
        inboundPending,
        inboundAccepted,
        inboundBooked,
        inboundDna,
        referralsOpen,
        selectedTab,
    ]);

    const groupPoints = [
        {
            label: 'New Referrals',
            description:
                'Referrals that have been received but not yet accepted or declined.',
        },
        {
            label: 'Open Referrals',
            description: 'Referrals that are open to your clinician role.',
        },
        {
            label: 'Accepted',
            description:
                'Referrals that have been accepted but not yet booked.',
        },
        {label: 'Booked', description: 'Referrals that have been booked.'},
        {
            label: 'Did Not Attend (DNA)',
            description:
                'Referrals that have been booked but the patient did not attend or the patient has cancelled.',
        },
    ];
    const helpModal = (
        <GroupsHelpModal
            showHelp={showHelp}
            toggleHelp={toggleHelp}
            title="Referrals Received Information"
            summary="Referrals are grouped based on their status. The
                        following groups are available:"
            groupPoints={groupPoints}
        />
    );

    if (isNarrow) {
        return (
            <div className="patient-list">
                {helpModal}
                <h6 className="text-left p-1 d-flex justify-content-between align-items-center">
                    <span>Select referral group</span>
                    <HelpIcon onClick={toggleHelp} />
                </h6>
                <ReferralListTabsDropDown
                    viewName={tabLabels[selectedTab]}
                    updateView={setSelectedTab}
                    viewOptions={tabOptions}
                />
                {renderContent()}
            </div>
        );
    }

    return (
        <>
            <h1 className="text-center">Referrals Received</h1>
            <div className="nav-tabs-with-help-container">
                {helpModal}
                <div className="referral-list">
                    <Tabs
                        activeKey={selectedTab}
                        onSelect={(k) =>
                            setSelectedTab(k as ReferralInboundTabs)
                        }
                        id="referrals-tab"
                        className="custom-tabs mb-3"
                    >
                        {Object.entries(tabLabels).map(([key, label]) => (
                            <Tab
                                key={key}
                                eventKey={key}
                                title={label}
                                className="custom-tabs"
                            >
                                {key === selectedTab && renderContent()}
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                <div className="help-icon-container-navtabs">
                    <HelpIcon onClick={toggleHelp} />
                </div>
            </div>
        </>
    );
};

export default ReferralsInboundScreen;
