import {useSelector} from 'react-redux';
import {ClinicianRoleProfile} from '@/generated-client';
import {RootState} from '@/reducers';

interface UseUserClinicianProfilesReturn {
    clinicianProfiles: ClinicianRoleProfile[];
    validateClinicianProfiles: () => boolean;
}

const useUserClinicianProfiles = (): UseUserClinicianProfilesReturn => {
    const clinicianProfiles = useSelector(
        (state: RootState) => state.profile.clinicianProfiles
    );

    const validateClinicianProfiles = () => {
        if (!clinicianProfiles) return false;
        if (clinicianProfiles.length === 0) return false;
        return true;
    };

    return {
        clinicianProfiles,
        validateClinicianProfiles,
    };
};

export default useUserClinicianProfiles;
