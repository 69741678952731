/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralsSummary
 */
export interface ReferralsSummary {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ReferralsSummary
     */
    pending: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ReferralsSummary
     */
    open: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ReferralsSummary
     */
    accepted: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ReferralsSummary
     */
    booked: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ReferralsSummary
     */
    dna: { [key: string]: { [key: string]: number; }; };
}

/**
 * Check if a given object implements the ReferralsSummary interface.
 */
export function instanceOfReferralsSummary(value: object): value is ReferralsSummary {
    if (!('pending' in value) || value['pending'] === undefined) return false;
    if (!('open' in value) || value['open'] === undefined) return false;
    if (!('accepted' in value) || value['accepted'] === undefined) return false;
    if (!('booked' in value) || value['booked'] === undefined) return false;
    if (!('dna' in value) || value['dna'] === undefined) return false;
    return true;
}

export function ReferralsSummaryFromJSON(json: any): ReferralsSummary {
    return ReferralsSummaryFromJSONTyped(json, false);
}

export function ReferralsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralsSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'pending': json['pending'],
        'open': json['open'],
        'accepted': json['accepted'],
        'booked': json['booked'],
        'dna': json['dna'],
    };
}

export function ReferralsSummaryToJSON(json: any): ReferralsSummary {
    return ReferralsSummaryToJSONTyped(json, false);
}

export function ReferralsSummaryToJSONTyped(value?: ReferralsSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'pending': value['pending'],
        'open': value['open'],
        'accepted': value['accepted'],
        'booked': value['booked'],
        'dna': value['dna'],
    };
}

