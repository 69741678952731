import React from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ErrorBoundary} from 'react-error-boundary';
import './App.css';
import {MedrPages, localDomain} from './constants/variables';

import LandingPageScreen from '@screens/LandingPageScreen';
import PoliciesScreen from '@screens/app/PoliciesScreen';
import LoginScreen from '@screens/user/LoginScreen';
import SignupScreen from '@screens/user/SignupScreen';
import SendResetPassswordScreen from '@screens/user/SendResetPassword';
import SendResetPassswordScreenSuccess from '@screens/user/SendResetPasswordSuccess';
import PasswordResetFromEmailScreen from '@screens/user/PasswordResetFromEmailScreen';
import RegistrationProgressScreen from '@screens/user/RegistrationProgressScreen';
import DelegateVerification from '@screens/user/DelegateVerification';
import HelpScreen from '@screens/app/HelpScreen';
import Dashboard from '@screens/Dashboard';
import FAQScreen from '@screens/app/FAQScreen';
import DelegateVerificationError from '@screens/user/Delegate/DelegateVerificationError';

import AuthenticatedRoutes from '@components/app/AuthenticatedRoutes';
import AdminRoutes from '@components/app/AdminRoutes';

import ProfileScreen from '@screens/ProfileScreen';
import DelegateManagement from '@screens/user/DelegateManagement';

import PatientEntryScreen from '@screens/patients/PatientEntryScreen';
import PatientListScreen from '@screens/patients/PatientListScreen';
import PatientEditScreen from '@screens/patients/PatientEditScreen';

import {
    ReferralEntryScreen,
    DirectReferralEntryScreen,
    ClinicianReferralEntryScreen,
} from '@screens/referrals/ReferralEntryScreen';
import ReferralsListScreen from '@screens/referrals/ReferralListScreen';
import {ReferralsInboundScreen} from '@screens/referrals/ReferralInboundScreen';
import {ReferralsOutboundScreen} from '@screens/referrals/ReferralOutboundScreen';

import CliniciansMapView from '@screens/clinicians/CliniciansMapView';
import ClinicianScreen from '@screens/clinicians/ClinicianScreen';

import AddAminUserScreen from './screens/admin/AddAdminUser';
import AdminUserListScreen from '@/screens/admin/AdminUserListScreen';
import AdminClinicianListScreen from '@screens/admin/AdminCliniciansListScreen';
import AdminPatientListScreen from '@screens/admin/AdminPatientListScreen';
import AdminReferralsListScreen from '@screens/admin/AdminReferralsListScreen';
import AdminInsurersScreen from '@screens/admin/AdminInsurersListScreen';

import NotFound from '@screens/NotFound';
import ServerDownErrorScreen from '@screens/ServerDownError';

import store from './store';

import Layout from './hocs/Layout';

import ErrorFallback from '@components/app/ErrorFallback';

const RoutesContent: React.FC = () => {
    const appPageRoutes = (
        <>
            <Route
                path={MedrPages.HOME.toString()}
                element={<LandingPageScreen />}
            />
            <Route
                path={MedrPages.POLICY.toString()}
                element={<PoliciesScreen />}
            />
            <Route path={MedrPages.FAQS.toString()} element={<FAQScreen />} />
            <Route
                path={MedrPages.HELP.toString()}
                element={<HelpScreen authenticated={false} />}
            />
        </>
    );

    const authPageRoutes = (
        <>
            <Route
                path={MedrPages.LOGIN.toString()}
                element={<LoginScreen />}
            />
            <Route
                path={MedrPages.REGISTER.toString()}
                element={<SignupScreen />}
            />
            <Route
                path={MedrPages.REGISTERED_VERIFY_EMAIL_STYLE.toString()}
                element={<RegistrationProgressScreen step={1} />}
            />
            <Route
                path={MedrPages.REGISTER_DELEGATE_VERIFY_EMAIL_STYLE.toString()}
                element={<DelegateVerification />}
            />
            <Route
                path={MedrPages.REGISTER_DELEGATE_VERIFY_EMAIL_ERROR.toString()}
                element={<DelegateVerificationError />}
            />
            <Route
                path={MedrPages.REGISTERED_PENDING_APPROVAL.toString()}
                element={<RegistrationProgressScreen step={2} />}
            />
            <Route
                path={MedrPages.PASSWORD_SEND_RESET_EMAIL.toString()}
                element={<SendResetPassswordScreen />}
            />
            <Route
                path={MedrPages.PASSWORD_SEND_RESET_SUCCESS.toString()}
                element={<SendResetPassswordScreenSuccess />}
            />
            <Route
                path={MedrPages.PASSWORD_SET_NEW_STYLE.toString()}
                element={<PasswordResetFromEmailScreen />}
            />
            <Route
                path={MedrPages.REGISTERED_CHECK_EMAIL.toString()}
                element={<RegistrationProgressScreen step={0} />}
            />
            <Route
                path={MedrPages.REGISTERED_VERIFICATION_PENDING.toString()}
                element={<RegistrationProgressScreen step={1} />}
            />
            <Route
                path={MedrPages.REGISTERED_COMPLETE.toString()}
                element={<RegistrationProgressScreen step={2} />}
            />
        </>
    );

    const openRoutes = (
        <>
            {appPageRoutes}
            {authPageRoutes}
        </>
    );

    const userManagementRoutes = (
        <>
            <Route
                path={MedrPages.DASHBOARD.toString()}
                element={<Dashboard />}
            />
            <Route
                path={MedrPages.AUTH_HELP.toString()}
                element={<HelpScreen authenticated />}
            />
            <Route
                path={MedrPages.PROFILE.toString()}
                element={<ProfileScreen />}
            />
        </>
    );

    const delegateRoutes = (
        <Route
            path={MedrPages.DELEGATES.toString()}
            element={<DelegateManagement />}
        />
    );

    const patientRoutes = (
        <>
            <Route
                path={MedrPages.PATIENTS.toString()}
                element={<PatientListScreen />}
            />
            <Route
                path={MedrPages.PATIENTS_ADD.toString()}
                element={<PatientEntryScreen />}
            />
            <Route
                path={MedrPages.PATIENTS_EDIT_STYLE.toString()}
                element={<PatientEditScreen />}
            />
        </>
    );

    const referralRoutes = (
        <>
            <Route
                path={MedrPages.REFERRALS.toString()}
                element={<ReferralsListScreen />}
            />
            <Route
                path={MedrPages.REFERRALS_INBOUND.toString()}
                element={<ReferralsInboundScreen />}
            />
            <Route
                path={MedrPages.REFERRALS_OUTBOUND.toString()}
                element={<ReferralsOutboundScreen />}
            />
            <Route
                path={MedrPages.REFERRALS_ADD.toString()}
                element={<ReferralEntryScreen />}
            />
            <Route
                path={MedrPages.RX_DIRECT_TO_CLINICIAN_STYLE.toString()}
                element={<ClinicianReferralEntryScreen />}
            />
            <Route
                path={MedrPages.RX_PATIENT_SELECTED_STYLE.toString()}
                element={<DirectReferralEntryScreen />}
            />
        </>
    );

    const clinicianRoutes = (
        <>
            <Route
                path={MedrPages.CLINICIANS.toString()}
                element={<CliniciansMapView />}
            />
            <Route
                path={MedrPages.CLINICIANS_UP_GET_STYLE.toString()}
                element={<ClinicianScreen />}
            />
        </>
    );

    const userRoutes = (
        <>
            {userManagementRoutes}
            {delegateRoutes}
            {patientRoutes}
            {referralRoutes}
            {clinicianRoutes}
        </>
    );

    const adminRoutes = (
        <>
            <Route
                path={MedrPages.ADMIN_USERS_LIST.toString()}
                element={<AdminUserListScreen />}
            />
            <Route
                path={MedrPages.ADMIN_CLINICIANS_LIST.toString()}
                element={<AdminClinicianListScreen />}
            />
            <Route
                path={MedrPages.ADMIN_PATIENTS.toString()}
                element={<AdminPatientListScreen />}
            />
            <Route
                path={MedrPages.ADMIN_REFERRALS.toString()}
                element={<AdminReferralsListScreen />}
            />
            <Route
                path={MedrPages.ADMIN_INSURANCE_AGREEMENTS.toString()}
                element={<AdminInsurersScreen />}
            />
            <Route
                path={MedrPages.ADMIN_ADD_STAFF.toString()}
                element={<AddAminUserScreen />}
            />
        </>
    );

    return (
        <Routes>
            {openRoutes}

            <Route element={<AuthenticatedRoutes />}>{userRoutes}</Route>

            <Route element={<AdminRoutes />}>{adminRoutes}</Route>

            <Route
                path={MedrPages.SERVER_DOWN.toString()}
                element={<ServerDownErrorScreen />}
            />
            <Route
                path={MedrPages.NOT_ASSINGED.toString()}
                element={<NotFound />}
            />
        </Routes>
    );
};

const App: React.FC = () => {
    const isLocalhost = window.location.origin === localDomain;
    return (
        <Provider store={store}>
            <Router>
                <Layout>
                    {isLocalhost ? (
                        <RoutesContent />
                    ) : (
                        <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => {
                                console.log('reset after error');
                            }}
                        >
                            <RoutesContent />
                        </ErrorBoundary>
                    )}
                </Layout>
            </Router>
        </Provider>
    );
};

export default App;
