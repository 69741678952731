import React from 'react';
import Select from 'react-select';
import usePatientSearchBar from '@/hooks/patients/usePatientSearchBar';
import {customStyles} from '@/constants/styles';

interface PatientSearchBarProps {
    currentPatientId?: number | null;
    sendPxId: (id: number) => void;
}

const PatientSearchBar: React.FC<PatientSearchBarProps> = ({
    currentPatientId = null,
    sendPxId = () => {},
}) => {
    const {patientsOptions, selectPatientByOption, selectedPatient} =
        usePatientSearchBar(currentPatientId, sendPxId);

    return (
        <Select
            name="patient-search"
            options={patientsOptions}
            value={selectedPatient}
            onChange={(opt) => selectPatientByOption(opt)}
            placeholder="Select patient"
            styles={customStyles}
        />
    );
};

export default PatientSearchBar;
