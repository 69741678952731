import {Dispatch} from 'redux';
import {NavigateFunction} from 'react-router-dom';
import {
    RegisterDelegateUserRequest,
    VerifyDelegateUserRequest,
    UpdateDelegatePermissionRequest,
    DelegateUserLink,
    RegisterDelegateApi,
    GetVerifyDelegateApi,
    VerifyDelegateApi,
    UserApi,
} from '@/generated-client';
import * as RED from '@/reducers/authReducer';
import {MedrService} from './authService';

export class DelegateService extends MedrService {
    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
    }

    async registerDelegateUser(
        inputData: RegisterDelegateUserRequest
    ): Promise<boolean> {
        const payload = {registerDelegateUserRequest: inputData};
        const api = new RegisterDelegateApi(this.configuration);
        return this.executeApiCall(
            () => api.registerDelegateCreate(payload),
            RED.dispatchRegisterDelegateUserRequest,
            RED.dispatchRegisterDelegateUserSuccess,
            RED.dispatchRegisterDelegateUserFail
        );
    }

    async getVerifyDelegateUser(
        code: string,
        time: string,
        user: string
    ): Promise<boolean> {
        const payload = {code, time, user};
        const api = new GetVerifyDelegateApi(this.configuration);
        return this.executeApiCall(
            () => api.getVerifyDelegateUsertimecodeRetrieve(payload),
            RED.dispatchGetVerifyDelegateUserRequest,
            RED.dispatchGetVerifyDelegateUserSuccess,
            RED.dispatchGetVerifyDelegateUserFail
        );
    }

    async verifyDelegateUser(
        data: VerifyDelegateUserRequest
    ): Promise<boolean> {
        const payload = {verifyDelegateUserRequest: data};
        const api = new VerifyDelegateApi(this.configuration);
        return this.executeApiCall(
            () => api.verifyDelegateUpdate(payload),
            RED.dispatchVerifyDelegateUserRequest,
            RED.dispatchVerifyDelegateUserSuccess,
            RED.dispatchVerifyDelegateUserFail
        );
    }

    async getUserDelegateList(): Promise<boolean> {
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userDelegatesRetrieve(),
            RED.dispatchGetUserDelegateListRequest,
            RED.dispatchGetUserDelegateListSuccess,
            RED.dispatchGetUserDelegateListFail
        );
    }

    async updateDelegateUserPermission(
        inputData: UpdateDelegatePermissionRequest
    ): Promise<boolean> {
        const payload = {updateDelegatePermissionRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userDelegateUpdatePermisisonCreate(payload),
            RED.dispatchUpdateDelegateUserPermissionRequest,
            RED.dispatchUpdateDelegateUserPermissionSuccess,
            RED.dispatchUpdateDelegateUserPermissionFail
        );
    }

    async deactivateDelegateUser(delegate: DelegateUserLink): Promise<boolean> {
        const payload = {deactivateDelegateRequest: {delegate}};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userDelegateDeactivateCreate(payload),
            RED.dispatchDeactivateDelegateUserRequest,
            RED.dispatchDeactivateDelegateUserSuccess,
            RED.dispatchDeactivateDelegateUserFail
        );
    }

    async restoreDelegateUser(delegate: DelegateUserLink): Promise<boolean> {
        const payload = {restoreDelegateRequest: {delegate}};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userDelegateRestoreCreate(payload),
            RED.dispatchRestoreDelegateUserRequest,
            RED.dispatchRestoreDelegateUserSuccess,
            RED.dispatchRestoreDelegateUserFail
        );
    }
}
