import {useSelector} from 'react-redux';
import {UserProfile} from '@/generated-client';
import {RootState} from '@/reducers';

interface UseUserProfileReturn {
    userProfile: UserProfile;
}

const useUserProfile = (): UseUserProfileReturn => {
    const userProfile = useSelector((state: RootState) => state.auth.user);

    return {
        userProfile,
    };
};

export default useUserProfile;
