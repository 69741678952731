import {useState} from 'react';

type FilterStr = string;
type SetFilterStr = React.Dispatch<React.SetStateAction<string>>;

const usePatientFilter = (): [FilterStr, SetFilterStr] => {
    const [filterStr, setFilterStr] = useState<FilterStr>('');
    return [filterStr, setFilterStr];
};

export default usePatientFilter;
