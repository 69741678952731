import React, {useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import JobRoleSearchBar from '../../clinicians/JobRoleSearchBar';
import useFilteredClinicians from '@/hooks/clinicians/useFilteredClinicians';
import useCliniciansList from '@hooks/clinicians/useCliniciansList';
import useJobRoles from '@hooks/clinicians/useJobRoles';
import ClinicianFilterInput from '../../clinicians/ClinicianFilterInput';
import MapView from '@/features/map/MapView';
import {MapViewContext, MapViewContextType} from '@features/map/MapViewContext';
import {useMapViewReferralEntry} from '@features/map/useMapView';
import {useReferralEntryContext} from '@contexts/ReferralEntryContext';

const ReferralStepSelectClinician: React.FC = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const {
        patient,
        referralData,
        setTargetClinicianOpen,
        setTargetClinician,
        refreshClinicians,
        setJobRole,
        changeGPS,
    } = useReferralEntryContext();

    const {updateFilterFields} = useFilteredClinicians(referralData.jobRoleId);

    const {
        clinicianList,
        clinicAddresses,
        setFilterString,
        toggleShowOnlyFavourites,
        hoveredClinicianUserProfileIds,
        hoveredAddressIds,
        updateHovered,
        updateMapViewCoords,
    } = useCliniciansList();

    const {getJobRoleById} = useJobRoles();

    const handleJobRoleSelect = (e) => {
        updateFilterFields(e);
        if (e.jobRoleId !== 0) {
            const jr = getJobRoleById(e.jobRoleId);
            setJobRole({...e, jobRole: jr});
            return;
        }
        setJobRole({...e});
    };

    const jrValues = {
        jobRoleId: referralData.jobRoleId,
        selectedJobRole: referralData.selectedJobRole,
        selectedJobSpecialty: referralData.selectedJobSpecialty,
        selectedJobSubSpecialty: referralData.selectedJobSubSpecialty,
    };

    const {address} = patient;
    const {longitude, latitude} = address;

    const {updateShowOnlyFavourites, receivePostcodeSearch, location} =
        useMapViewReferralEntry({
            patientLat: latitude,
            patientLng: longitude,
            handleShowFavourites: toggleShowOnlyFavourites,
        });

    useEffect(() => {
        changeGPS(location.coordinates);
    }, [location.coordinates]);

    const mapViewContextValues: MapViewContextType = {
        clinicians: clinicianList,
        refreshClinicians,
        showReferralButton: false,
        hoveredClinicianUserProfileIds,
        updateHovered,
        hoveredAddressIds,
        clinics: clinicAddresses,
        handleShowFavourites: toggleShowOnlyFavourites,
        onClinicianSelect: setTargetClinician,
        updateMapViewCoords: updateMapViewCoords,
        updateShowOnlyFavourites,
        receivePostcodeSearch,
        location,
    };

    return (
        <>
            <Row className="py-1">
                <Col className="text-center">
                    <h4>Filter clinician by name or role</h4>
                </Col>
            </Row>
            <Row className="mb-4 text-center">
                <Col>
                    <Button
                        variant="info"
                        onClick={setTargetClinicianOpen}
                        disabled={referralData.jobRoleId === 0}
                    >
                        Set open to all suitable clinicians
                    </Button>
                </Col>
            </Row>
            <Row
                className="mb-4 referral-step-select-clinician"
                style={{paddingLeft: '15px'}}
            >
                <Col sm={12} md={6}>
                    <JobRoleSearchBar
                        values={jrValues}
                        changeValues={handleJobRoleSelect}
                        filtered
                    />
                </Col>
                <Col sm={12} md={6}>
                    <ClinicianFilterInput setFilterString={setFilterString} />
                </Col>
            </Row>
            <MapViewContext.Provider value={mapViewContextValues}>
                <MapView />
            </MapViewContext.Provider>
        </>
    );
};

export default ReferralStepSelectClinician;
