import {Dispatch} from 'redux';
import * as CONSTS from '@constants/patientConstants';
import * as CLIENT from '@/generated-client';

// getGpsCoords
export function dispatchGetGpsCoordsRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.GPS_COORDS_ACTION.REQUEST});
}
export function dispatchGetGpsCoordsSuccess(
    dispatch: Dispatch,
    payload: CLIENT.GpsFromPostcodeResponse
) {
    dispatch({type: CONSTS.GPS_COORDS_ACTION.SUCCESS, payload});
}
export function dispatchGetGpsCoordsFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.GPS_COORDS_ACTION.FAIL, payload: error});
}

// getClinicianPatientList
export function dispatchGetClinicianPatientListRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CLINICIAN_PATIENTS_ACTION.REQUEST});
}
export function dispatchGetClinicianPatientListSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientsListForUserResponse
) {
    dispatch({
        type: CONSTS.CLINICIAN_PATIENTS_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetClinicianPatientListFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.CLINICIAN_PATIENTS_ACTION.FAIL, payload: error});
}

// getPxDataById
export function dispatchGetPxDataByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CURRENT_PATIENT_ACTION.REQUEST});
}
export function dispatchGetPxDataByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientByIdResponse
) {
    dispatch({type: CONSTS.CURRENT_PATIENT_ACTION.SUCCESS, payload});
}
export function dispatchGetPxDataByIdFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.CURRENT_PATIENT_ACTION.FAIL, payload: error});
}

// getPatientById
export function dispatchGetPatientByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CURRENT_PATIENT_ACTION.REQUEST});
}
export function dispatchGetPatientByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientByIdResponse
) {
    dispatch({type: CONSTS.CURRENT_PATIENT_ACTION.SUCCESS, payload});
}
export function dispatchGetPatientByIdFail(dispatch: Dispatch, error: string) {
    dispatch({type: CONSTS.CURRENT_PATIENT_ACTION.FAIL, payload: error});
}

// getPatientReferralsById
export function dispatchGetPatientReferralsByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.PATIENT_REFERRALS_ACTION.REQUEST});
}
export function dispatchGetPatientReferralsByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.ReferralsListForPatientResponse
) {
    dispatch({
        type: CONSTS.PATIENT_REFERRALS_ACTION.SUCCESS,
        payload,
    });
}
export function dispatchGetPatientReferralsByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.PATIENT_REFERRALS_ACTION.FAIL, payload: error});
}

// postCreatePatient
export function dispatchPostCreatePatientRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.CREATE_PATIENT_ACTION.REQUEST});
}
export function dispatchPostCreatePatientSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientFormCreateResponse
) {
    dispatch({type: CONSTS.CREATE_PATIENT_ACTION.SUCCESS, payload});
}
export function dispatchPostCreatePatientFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.CREATE_PATIENT_ACTION.FAIL, payload: error});
}

// updatePatientById
export function dispatchUpdatePatientByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.PATIENT_UPDATE_ACTION.REQUEST});
}
export function dispatchUpdatePatientByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientUpdateByIdResponse
) {
    dispatch({type: CONSTS.PATIENT_UPDATE_ACTION.SUCCESS, payload});
}
export function dispatchUpdatePatientByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.PATIENT_UPDATE_ACTION.FAIL, payload: error});
}

// deletePatientById
export function dispatchDeletePatientByIdRequest(dispatch: Dispatch) {
    dispatch({type: CONSTS.PATIENT_DELETE_ACTION.REQUEST});
}
export function dispatchDeletePatientByIdSuccess(
    dispatch: Dispatch,
    payload: CLIENT.PatientsListForUserResponse
) {
    dispatch({type: CONSTS.PATIENT_DELETE_ACTION.SUCCESS, payload});
}
export function dispatchDeletePatientByIdFail(
    dispatch: Dispatch,
    error: string
) {
    dispatch({type: CONSTS.PATIENT_DELETE_ACTION.FAIL, payload: error});
}

// resetCurrentPatient
export function dispatchResetCurrentPatient(dispatch: Dispatch) {
    dispatch({type: CONSTS.CURRENT_PATIENT_ACTION.RESET});
}
