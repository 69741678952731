/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddressTypeEnum } from './AddressTypeEnum';
import {
    AddressTypeEnumFromJSON,
    AddressTypeEnumFromJSONTyped,
    AddressTypeEnumToJSON,
    AddressTypeEnumToJSONTyped,
} from './AddressTypeEnum';

/**
 * 
 * @export
 * @interface MapAddress
 */
export interface MapAddress {
    /**
     * 
     * @type {number}
     * @memberof MapAddress
     */
    readonly id: number;
    /**
     * 
     * @type {AddressTypeEnum}
     * @memberof MapAddress
     */
    addressType?: AddressTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MapAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof MapAddress
     */
    firstLine?: string;
    /**
     * 
     * @type {string}
     * @memberof MapAddress
     */
    postcode: string;
    /**
     * 
     * @type {number}
     * @memberof MapAddress
     */
    readonly latitude: number;
    /**
     * 
     * @type {number}
     * @memberof MapAddress
     */
    readonly longitude: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MapAddress
     */
    userProfileIds: Array<number>;
}



/**
 * Check if a given object implements the MapAddress interface.
 */
export function instanceOfMapAddress(value: object): value is MapAddress {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('postcode' in value) || value['postcode'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('userProfileIds' in value) || value['userProfileIds'] === undefined) return false;
    return true;
}

export function MapAddressFromJSON(json: any): MapAddress {
    return MapAddressFromJSONTyped(json, false);
}

export function MapAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'addressType': json['addressType'] == null ? undefined : AddressTypeEnumFromJSON(json['addressType']),
        'city': json['city'] == null ? undefined : json['city'],
        'firstLine': json['firstLine'] == null ? undefined : json['firstLine'],
        'postcode': json['postcode'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'userProfileIds': json['userProfileIds'],
    };
}

export function MapAddressToJSON(json: any): MapAddress {
    return MapAddressToJSONTyped(json, false);
}

export function MapAddressToJSONTyped(value?: Omit<MapAddress, 'id'|'latitude'|'longitude'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'addressType': AddressTypeEnumToJSON(value['addressType']),
        'city': value['city'],
        'firstLine': value['firstLine'],
        'postcode': value['postcode'],
        'userProfileIds': value['userProfileIds'],
    };
}

