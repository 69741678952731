import React from 'react';
import {Marker} from 'react-leaflet';
import {LatLngTuple} from 'leaflet';
import './map.css';
import 'leaflet/dist/leaflet.css';
import useTargetMarker from './useTargetMarker';
import {defaultMapLat, defaultMapLng} from '@constants/variables';

interface TargetMarkerProps {
    mapCenter: LatLngTuple;
}

const TargetMarker: React.FC<TargetMarkerProps> = ({mapCenter}) => {
    if (
        (mapCenter[0] === 0 && mapCenter[1] === 0) ||
        (mapCenter[0] === defaultMapLat && mapCenter[1] === defaultMapLng)
    ) {
        return null;
    }

    const {geocode, customIcon} = useTargetMarker({mapCenter});

    return <Marker position={geocode} icon={customIcon} />;
};

export default TargetMarker;
