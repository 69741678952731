import {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useUserService} from '@/actions';
import {RootState} from '@/reducers';

interface UseReferralNotificationsReturn {
    nReferrals: number;
}

const useReferralNotifications = (
    isAuthenticated: boolean
): UseReferralNotificationsReturn => {
    const userService = useUserService();
    const referralNotifications = useSelector(
        (state: RootState) => state.profile.referralNotifications
    );
    const [nReferrals, setNReferrals] = useState<number>(0);

    const updateNotifications = useCallback(() => {
        setNReferrals(referralNotifications?.nReferrals ?? 0);
    }, [referralNotifications]);

    useEffect(() => {
        if (isAuthenticated) {
            userService.getReferralNotifications();
        }
    }, [isAuthenticated]); // Only re-run if isAuthenticated changes

    useEffect(() => updateNotifications(), [updateNotifications]);

    return {
        nReferrals,
    };
};

export default useReferralNotifications;
