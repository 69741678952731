import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {Button} from 'react-bootstrap';
import useReferralsContactsDisplay from './useReferralsContactsDisplay';
import {EmailFormField, PhoneFormField} from '../../app/parts/FormFields';
import useScreenInfo from '@/hooks/app/useScreenInfo';
import {RegistrationClinicianContact} from '@contexts/RegistrationContext';

enum ContactIcons {
    PLUS = 'fa-solid fa-plus',
    CROSS = 'fa-solid fa-x',
    EMAIL = 'fa-regular fa-envelope',
    PHONE = 'fa-solid fa-phone-volume',
}

enum TextSizes {
    NARROW = '0.7em',
    WIDE = '1em',
}

interface ReferralContactProps {
    isNarrow?: boolean;
    contact: RegistrationClinicianContact;
    removeContact?: (contact: RegistrationClinicianContact) => void;
}

export const ReferralContact: React.FC<ReferralContactProps> = ({
    contact,
    isNarrow = false,
    removeContact = () => {},
}) => {
    if (!contact) {
        return (
            <div>
                <i className="fa-solid fa-user" />
            </div>
        );
    }
    const {type, email, phoneNumber} = contact;

    const textSize = isNarrow ? TextSizes.NARROW : TextSizes.WIDE;

    const narrowRemoveButton = (
        <Button
            className="rounded-circle p-1"
            variant="danger"
            onClick={() => removeContact(contact)}
            style={{
                width: '30px',
                height: '30px',
                fontSize: textSize,
            }}
        >
            <i className={ContactIcons.CROSS} />
        </Button>
    );

    const wideRemoveButton = (
        <Button
            className="sel-button p-1"
            variant="danger"
            onClick={() => removeContact(contact)}
        >
            Remove
        </Button>
    );

    return (
        <div className="medr-rounded-inv m-1 p-1 notif-setting-bar d-flex justify-content-between align-items-center">
            <div
                className="d-flex align-items-center"
                style={{paddingLeft: '10px', maxWidth: '80%'}}
            >
                <span
                    className="me-2 flex-shrink-0"
                    style={{paddingLeft: '10px', fontSize: textSize}}
                >
                    <i
                        className={
                            type === 'email'
                                ? ContactIcons.EMAIL
                                : ContactIcons.PHONE
                        }
                    />
                </span>
                <span
                    className="text-truncate"
                    style={{paddingLeft: '10px', fontSize: textSize}}
                >
                    {type === 'email' ? email : phoneNumber}
                </span>
            </div>
            {removeContact && !contact.isMain && (
                <div>{isNarrow ? narrowRemoveButton : wideRemoveButton}</div>
            )}
        </div>
    );
};

interface ReferralContactsDisplayProps {
    contacts: RegistrationClinicianContact[];
    updateAppointmentContacts: (
        contacts: RegistrationClinicianContact[]
    ) => void;
    requiredEmails: string[];
}

const ReferralContactsDisplay: React.FC<ReferralContactsDisplayProps> = ({
    contacts,
    updateAppointmentContacts,
    requiredEmails,
}) => {
    const {
        newContacts,
        showForm,
        setShowForm,
        newContactFormData,
        updateNewContactEmail,
        updateNewContactPhoneNumber,
        addNewContact,
        removeNewContact,
    } = useReferralsContactsDisplay({
        contacts,
        updateAppointmentContacts,
        requiredEmails,
    });
    const {isNarrow} = useScreenInfo();

    return (
        <div className="medr-rounded bg-medr-gray bluegreen mt-4">
            <h4 className="bluegreen text-center">Referral Contacts</h4>
            <p className="bluegreen medr-small">
                The email addresses listed below will receive notifications
                regarding the sending, receiving, accepting, or rejecting of
                referrals, based on your notification settings. Additionally,
                you will be alerted about open referrals that meet the specified
                criteria. Any phone numbers provided will be added to your
                contact information.
            </p>
            <p>
                <strong>
                    Please note that at least one email address is required.
                </strong>
            </p>
            {newContacts &&
                newContacts.map((contact) => (
                    <ReferralContact
                        key={uuidv4()}
                        isNarrow={isNarrow}
                        contact={contact}
                        removeContact={removeNewContact}
                    />
                ))}

            <div
                className="row m-1"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                <Button
                    className="sel-button p-1"
                    variant="primary"
                    onClick={() => setShowForm('email')}
                >
                    <i className={ContactIcons.PLUS} />{' '}
                    <i className={ContactIcons.EMAIL} />
                </Button>
                <Button
                    className="sel-button p-1"
                    variant="primary"
                    onClick={() => setShowForm('phoneNumber')}
                >
                    <i className={ContactIcons.PLUS} />{' '}
                    <i className={ContactIcons.PHONE} />
                </Button>
            </div>
            {showForm === 'email' && (
                <div
                    className="mt-3 d-flex add-new-email"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <EmailFormField
                            email={newContactFormData.email}
                            emailKey="email"
                            changeValues={updateNewContactEmail}
                        />
                    </div>
                    <div>
                        <Button className="sel-button" onClick={addNewContact}>
                            Add email
                        </Button>
                    </div>
                </div>
            )}
            {showForm === 'phoneNumber' && (
                <div
                    className="mt-3 d-flex add-new-phone"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="app-contact-disp-phone-left">
                        <PhoneFormField
                            currentPhoneNo={newContactFormData.phoneNumber}
                            nameKey="phoneNumber"
                            changeValues={updateNewContactPhoneNumber}
                        />
                    </div>
                    <div className="app-contact-disp-phone-left">
                        <Button className="sel-button" onClick={addNewContact}>
                            Add phone
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReferralContactsDisplay;
