import React, {useEffect, useState} from 'react';
import './map.css';
import 'leaflet/dist/leaflet.css';
import {MapContainer, TileLayer, useMap, useMapEvents} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import {divIcon, point} from 'leaflet';
import {v4 as uuidv4} from 'uuid';
import ClinicMarker from './ClinicMarker';
import TargetMarker from './TargetMarker';
import Loader from '@components/app/Loader';
import {useMapViewContext} from './MapViewContext';

const Map: React.FC = () => {
    const {
        clinics,
        updateHovered,
        hoveredAddressIds,
        updateMapViewCoords,
        location,
    } = useMapViewContext();

    const [isMapFrozen, setIsMapFrozen] = useState(false);
    const [lastPan, setLastPan] = React.useState(null);

    function FreezeMap() {
        const map = useMap();

        useEffect(() => {
            if (isMapFrozen) {
                console.log('Freeze map');
                map.dragging.disable();
                map.scrollWheelZoom.disable();
                map.doubleClickZoom.disable();
                map.boxZoom.disable();
                map.keyboard.disable();
            } else {
                map.dragging.enable();
                map.scrollWheelZoom.enable();
                map.doubleClickZoom.enable();
                map.boxZoom.enable();
                map.keyboard.enable();
            }
        }, [isMapFrozen]);

        return null;
    }

    const createClusterCustomIcon = function (cluster) {
        // TODO: Add on hover for marker cluster
        const size = 33;

        return divIcon({
            html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
            className: 'custom-marker-cluster',
            iconSize: point(size, size, true),
        });
    };

    function FlyMapTo({isMapFrozen}: {isMapFrozen: boolean}) {
        const map = useMap();
        const currentZoom = map.getZoom();

        useEffect(() => {
            if (isMapFrozen || lastPan === location.mapCenter) return; //Prevent movement if frozen
            setLastPan(location.mapCenter);
            map.flyTo(location.mapCenter, currentZoom);
        }, [location.mapCenter, isMapFrozen]);

        return null;
    }

    function MapEventHandler() {
        const map = useMapEvents({
            moveend: () => {
                const bounds = map.getBounds();
                const northEast = bounds.getNorthEast();
                const southWest = bounds.getSouthWest();

                // console.log('Map moved. New corner coordinates:');
                // console.log('NorthEast:', northEast.lat, northEast.lng);
                // console.log('SouthWest:', southWest.lat, southWest.lng);
                updateMapViewCoords({
                    ne: {lat: northEast.lat, lng: northEast.lng},
                    sw: {lat: southWest.lat, lng: southWest.lng},
                });
            },
        });

        return null;
    }

    // @ts-expect-error Avoid error on env var import
    const jawgAccesToken = import.meta.env.VITE_JAWG_ACCESS_TOKEN;
    if (!jawgAccesToken || jawgAccesToken === '') {
        console.error('Jawg access token not found');
    }

    return (
        <div id="map">
            {location.loaded && !location.error ? (
                <MapContainer center={location.mapCenter} zoom={11}>
                    <TileLayer
                        // attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>"
                        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token={accessToken}"
                        attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        minZoom={0}
                        maxZoom={22}
                        // @ts-expect-error UNSURE
                        accessToken={jawgAccesToken}
                    />
                    <MarkerClusterGroup
                        chunkedLoading
                        iconCreateFunction={createClusterCustomIcon}
                    >
                        {clinics.map((clinic) => {
                            const numberOfClinicians: number =
                                clinic.userProfileIds.length;
                            return (
                                <ClinicMarker
                                    key={uuidv4()}
                                    clinic={clinic}
                                    hoveredAddressIds={hoveredAddressIds}
                                    updateHovered={updateHovered}
                                    setIsMapFrozen={setIsMapFrozen}
                                    numberOfClinicians={numberOfClinicians}
                                />
                            );
                        })}
                    </MarkerClusterGroup>
                    <TargetMarker mapCenter={location.mapCenter} />
                    <FlyMapTo isMapFrozen={isMapFrozen} />
                    <MapEventHandler />
                    <FreezeMap />
                </MapContainer>
            ) : (
                <Loader text="Loading clinician map..." />
            )}
        </div>
    );
};

export default Map;
