import React, {useEffect, useState, useCallback} from 'react';
import {Row, Col, Button} from 'react-bootstrap';

interface PaginationBarProps {
    arrayLength: number;
    setSliceValues: (start: number, end: number) => void;
    windowSize?: number;
}

const PaginationBar: React.FC<PaginationBarProps> = ({
    arrayLength,
    setSliceValues,
    windowSize = 10,
}) => {
    const minIndex = 0;
    const [maxIndex, setMaxIndex] = useState<number>(arrayLength);
    const [window, setWindow] = useState<number>(windowSize);
    const [startSlice, setStartSlice] = useState<number>(minIndex);
    const [endSlice, setEndSlice] = useState<number>(minIndex + window);

    const atStart = minIndex === startSlice;
    const atEnd = maxIndex === endSlice;

    // Update maxIndex and window size when arrayLength changes
    useEffect(() => {
        if (arrayLength < windowSize) {
            setWindow(arrayLength);
            setEndSlice(arrayLength);
        }
        setMaxIndex(arrayLength);
    }, [arrayLength, windowSize]);

    // Validate index range to ensure it stays within bounds
    const validateIndexRange = useCallback(
        (index: number, min: number, max: number): number => {
            return Math.max(min, Math.min(index, max));
        },
        []
    );

    // Move to the start of the array
    const setToStart = useCallback(() => {
        setStartSlice(minIndex);
        setEndSlice(validateIndexRange(minIndex + window, minIndex, maxIndex));
    }, [minIndex, window, maxIndex, validateIndexRange]);

    // Move window backward
    const moveWindowBackward = useCallback(() => {
        const newStart = validateIndexRange(
            startSlice - window,
            minIndex,
            maxIndex - window
        );
        const newEnd = validateIndexRange(
            endSlice - window,
            minIndex + window,
            maxIndex
        );
        setStartSlice(newStart);
        setEndSlice(newEnd);
    }, [startSlice, endSlice, window, minIndex, maxIndex, validateIndexRange]);

    // Move window forward
    const moveWindowForward = useCallback(() => {
        const newStart = validateIndexRange(
            startSlice + window,
            minIndex,
            maxIndex - window
        );
        const newEnd = validateIndexRange(
            endSlice + window,
            minIndex + window,
            maxIndex
        );
        setStartSlice(newStart);
        setEndSlice(newEnd);
    }, [startSlice, endSlice, window, minIndex, maxIndex, validateIndexRange]);

    // Move to the end of the array
    const setToEnd = useCallback(() => {
        setStartSlice(
            validateIndexRange(maxIndex - window, minIndex, maxIndex - window)
        );
        setEndSlice(maxIndex);
    }, [maxIndex, window, minIndex, validateIndexRange]);

    // Notify parent component of slice changes
    useEffect(() => {
        setSliceValues(startSlice, endSlice);
    }, [startSlice, endSlice, setSliceValues]);

    return (
        <Row>
            <Col className="text-center pagination-row">
                <div className="d-flex d-sm-inline-flex justify-content-center align-items-center flex-column flex-sm-row">
                    <Button
                        className="sel-button mx-2 mb-3 mb-sm-0"
                        type="button"
                        variant="primary"
                        onClick={setToStart}
                        disabled={atStart}
                    >
                        Start
                    </Button>
                    <Button
                        className="sel-button mx-2 mb-3 mb-sm-0"
                        type="button"
                        variant="primary"
                        onClick={moveWindowBackward}
                        disabled={atStart}
                    >
                        Previous
                    </Button>
                    <p className="pagination-text my-0 mx-2 mb-3 mb-sm-0">
                        Displaying {startSlice + 1} to {endSlice} (of {maxIndex}
                        )
                    </p>
                    <Button
                        className="sel-button mx-2 mb-3 mb-sm-0"
                        type="button"
                        variant="primary"
                        onClick={moveWindowForward}
                        disabled={atEnd}
                    >
                        Next
                    </Button>
                    <Button
                        className="sel-button mx-2 mb-3 mb-sm-0"
                        type="button"
                        variant="primary"
                        onClick={setToEnd}
                        disabled={atEnd}
                    >
                        End
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default PaginationBar;
