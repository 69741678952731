/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Mr` - Mr
 * * `Mrs` - Mrs
 * * `Ms` - Ms
 * * `Miss` - Miss
 * * `Dr` - Dr
 * * `Prof` - Prof
 * * `` - Unknown
 * @export
 */
export const TitleEnum = {
    Mr: 'Mr',
    Mrs: 'Mrs',
    Ms: 'Ms',
    Miss: 'Miss',
    Dr: 'Dr',
    Prof: 'Prof'
} as const;
export type TitleEnum = typeof TitleEnum[keyof typeof TitleEnum];


export function instanceOfTitleEnum(value: any): boolean {
    for (const key in TitleEnum) {
        if (Object.prototype.hasOwnProperty.call(TitleEnum, key)) {
            if (TitleEnum[key as keyof typeof TitleEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TitleEnumFromJSON(json: any): TitleEnum {
    return TitleEnumFromJSONTyped(json, false);
}

export function TitleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TitleEnum {
    return json as TitleEnum;
}

export function TitleEnumToJSON(value?: TitleEnum | null): any {
    return value as any;
}

export function TitleEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): TitleEnum {
    return value as TitleEnum;
}

