import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import Avatar from 'react-avatar-edit';
import {
    PictureUploadModalProps,
    ProfilePictureDisplayProps,
} from './interfaces';
import {
    usePictureUploadModal,
    useProfilePicture,
    checkProfileUrlString,
} from './hooks';

const PictureUploadModal: React.FC<PictureUploadModalProps> = ({
    show,
    handleClose,
}) => {
    const {
        imageSrc,
        preview,
        bgColor,
        onBeforeFileLoad,
        onCrop,
        onClose,
        saveImage,
    } = usePictureUploadModal(handleClose);

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className="medr-text-inv">
                    Upload Profile Picture
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div className="avatar-container">
                    <Avatar
                        width={400}
                        height={300}
                        onBeforeFileLoad={onBeforeFileLoad}
                        onCrop={onCrop}
                        onClose={onClose}
                        src={imageSrc}
                        shadingColor={bgColor}
                        backgroundColor={bgColor}
                    />
                </div>
                <div className="below-avatar-container">
                    <Button
                        variant="info"
                        onClick={saveImage}
                        disabled={!preview}
                    >
                        Upload
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const ProfilePictureDisplay: React.FC<ProfilePictureDisplayProps> = ({
    userProfile,
}) => {
    const {
        profilePicturePath,
        uploadPicture,
        uploadPictureKeyPress,
        showPicUpload,
        setShowPicUpload,
        editable,
    } = useProfilePicture(userProfile);
    return (
        <div className="col-auto mb-2">
            <div className="profile-picture-container">
                {profilePicturePath ? (
                    <img
                        className="profile-picture"
                        src={checkProfileUrlString(profilePicturePath)}
                        alt="Profile"
                    />
                ) : (
                    <i className="fa-solid fa-user profile-picture-icon" />
                )}
                {editable && (
                    <i
                        className="fa-solid fa-camera  camera-update-icon"
                        onClick={uploadPicture}
                        onKeyDown={uploadPictureKeyPress}
                        role="button"
                        tabIndex={0}
                        aria-label="Edit Profile Picture"
                    />
                )}
                <PictureUploadModal
                    show={showPicUpload}
                    handleClose={() => setShowPicUpload(false)}
                />
            </div>
        </div>
    );
};

export default ProfilePictureDisplay;
