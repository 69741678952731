/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface DelegateUserLink
 */
export interface DelegateUserLink {
    /**
     * 
     * @type {number}
     * @memberof DelegateUserLink
     */
    readonly id: number;
    /**
     * 
     * @type {UserProfile}
     * @memberof DelegateUserLink
     */
    readonly delegateUserProfile: UserProfile;
    /**
     * 
     * @type {UserProfile}
     * @memberof DelegateUserLink
     */
    readonly clinicianUserProfile: UserProfile;
    /**
     * 
     * @type {boolean}
     * @memberof DelegateUserLink
     */
    readonly hasRegistered: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DelegateUserLink
     */
    isVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DelegateUserLink
     */
    permissionViewReferrals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DelegateUserLink
     */
    permissionCreateReferrals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DelegateUserLink
     */
    permissionManageReferrals?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DelegateUserLink
     */
    readonly accountCreatedOn: Date;
}

/**
 * Check if a given object implements the DelegateUserLink interface.
 */
export function instanceOfDelegateUserLink(value: object): value is DelegateUserLink {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('delegateUserProfile' in value) || value['delegateUserProfile'] === undefined) return false;
    if (!('clinicianUserProfile' in value) || value['clinicianUserProfile'] === undefined) return false;
    if (!('hasRegistered' in value) || value['hasRegistered'] === undefined) return false;
    if (!('accountCreatedOn' in value) || value['accountCreatedOn'] === undefined) return false;
    return true;
}

export function DelegateUserLinkFromJSON(json: any): DelegateUserLink {
    return DelegateUserLinkFromJSONTyped(json, false);
}

export function DelegateUserLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegateUserLink {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'delegateUserProfile': UserProfileFromJSON(json['delegateUserProfile']),
        'clinicianUserProfile': UserProfileFromJSON(json['clinicianUserProfile']),
        'hasRegistered': json['hasRegistered'],
        'isVerified': json['isVerified'] == null ? undefined : json['isVerified'],
        'permissionViewReferrals': json['permissionViewReferrals'] == null ? undefined : json['permissionViewReferrals'],
        'permissionCreateReferrals': json['permissionCreateReferrals'] == null ? undefined : json['permissionCreateReferrals'],
        'permissionManageReferrals': json['permissionManageReferrals'] == null ? undefined : json['permissionManageReferrals'],
        'accountCreatedOn': (new Date(json['accountCreatedOn'])),
    };
}

export function DelegateUserLinkToJSON(json: any): DelegateUserLink {
    return DelegateUserLinkToJSONTyped(json, false);
}

export function DelegateUserLinkToJSONTyped(value?: Omit<DelegateUserLink, 'id'|'delegateUserProfile'|'clinicianUserProfile'|'hasRegistered'|'accountCreatedOn'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'isVerified': value['isVerified'],
        'permissionViewReferrals': value['permissionViewReferrals'],
        'permissionCreateReferrals': value['permissionCreateReferrals'],
        'permissionManageReferrals': value['permissionManageReferrals'],
    };
}

