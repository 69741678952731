import React, {useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';
import {getAddressMultiline} from '@/actions/general';
import ClinicForm from './ClinicForm';
import {ClinicFormData} from './ClinicForm.interfaces';

interface ClinicsDisplayCardProps {
    values: ClinicFormData;
    editable: boolean;
    editClinic: (formValues: ClinicFormData) => void;
    removeClinic: (values: ClinicFormData) => void;
}

const ClinicsDisplayCard: React.FC<ClinicsDisplayCardProps> = ({
    values,
    editable,
    editClinic,
    removeClinic,
}) => {
    const [showEditForm, setShowEditForm] = useState<boolean>(false);

    const initData = {
        isNewClinic: false,
        id: values.id,
        clinicName: values.clinicName,
        centreName: values.centreName,
        address: {
            firstLine: values.address.firstLine,
            city: values.address.city,
            postcode: values.address.postcode,
        },
    };
    const [formData, setFormData] = useState<ClinicFormData>(initData);

    const {clinicName, centreName, address} = values;
    const clinicRef = `clinic${uuidv4()}`;

    const displayAddress = (
        <>
            <Col sm={6}>
                <h4>{clinicName}</h4>
                <h6>{centreName}</h6>
            </Col>
            <Col sm={6}>{getAddressMultiline(address)}</Col>
        </>
    );

    const toggleEditForm = () => setShowEditForm((prev) => !prev);

    const sendEditClinic = () => {
        editClinic(formData);
        setShowEditForm(false);
    };

    const editingButtons = (
        <div
            className="row p-3"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                className="col-md-6 mt-1"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="info"
                    className="sel-button p-1"
                    onClick={() => toggleEditForm()}
                >
                    {showEditForm ? 'Discard changes' : 'Edit'}
                </Button>
            </div>
            <div
                className="col-md-6 mt-1"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="danger"
                    className="sel-button p-1"
                    onClick={() => removeClinic(values)}
                >
                    Remove
                </Button>
            </div>
        </div>
    );

    return (
        <div className="medr-rounded-inv mb-2" id={clinicRef}>
            <div
                className="row p-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Row
                    className="p-3 justify-content-center"
                    style={{width: '100%'}}
                >
                    {showEditForm ? (
                        <ClinicForm
                            isNew={false}
                            clinicData={formData}
                            updateClinicData={setFormData}
                            handleAddClinic={sendEditClinic}
                        />
                    ) : (
                        displayAddress
                    )}
                </Row>
            </div>
            {editable && editingButtons}
        </div>
    );
};

export default ClinicsDisplayCard;
