import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useAdminService} from '@/actions';
import {UserProfile} from '@/generated-client';

interface VerifyClinicianPopupProps {
    userInfo: {
        userProfile: UserProfile;
        setVerified: boolean;
    };
    showModal: boolean;
    handleClose: () => void;
}

const VerifyClinicianPopup: React.FC<VerifyClinicianPopupProps> = ({
    userInfo = null,
    showModal,
    handleClose,
}) => {
    const adminService = useAdminService();
    if (!userInfo || !userInfo.userProfile) {
        return null;
    }

    const {userProfile, setVerified} = userInfo;
    const {id} = userProfile;

    const sendVerifcationData = async () => {
        await adminService.setUserVerified(id, setVerified);
        handleClose();
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{color: 'black'}}>
                    Set user to verified!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    <li>Title: {userProfile.title}</li>
                    <li>First Name: {userProfile.firstName}</li>
                    <li>Last Name: {userProfile.lastName}</li>
                    <li>Email: {userProfile.user.email}</li>
                    <li>Job Role: {userProfile.jobRole[0].jobTitle} </li>
                    <li>
                        *** Registration Body:{' '}
                        {userProfile.jobRole[0].registrationBody}
                    </li>
                    <li>
                        *** Registration Number:{' '}
                        {userProfile.registrationNumber}
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={setVerified ? 'primary' : 'danger'}
                    onClick={() => sendVerifcationData()}
                >
                    {setVerified ? 'Verify' : 'Unverify'}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VerifyClinicianPopup;
