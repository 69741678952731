/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDelegatePermissionRequest
 */
export interface UpdateDelegatePermissionRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateDelegatePermissionRequest
     */
    delegateId: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDelegatePermissionRequest
     */
    delegateUserId: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateDelegatePermissionRequest
     */
    permissions: { [key: string]: any; };
}

/**
 * Check if a given object implements the UpdateDelegatePermissionRequest interface.
 */
export function instanceOfUpdateDelegatePermissionRequest(value: object): value is UpdateDelegatePermissionRequest {
    if (!('delegateId' in value) || value['delegateId'] === undefined) return false;
    if (!('delegateUserId' in value) || value['delegateUserId'] === undefined) return false;
    if (!('permissions' in value) || value['permissions'] === undefined) return false;
    return true;
}

export function UpdateDelegatePermissionRequestFromJSON(json: any): UpdateDelegatePermissionRequest {
    return UpdateDelegatePermissionRequestFromJSONTyped(json, false);
}

export function UpdateDelegatePermissionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDelegatePermissionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'delegateId': json['delegateId'],
        'delegateUserId': json['delegateUserId'],
        'permissions': json['permissions'],
    };
}

export function UpdateDelegatePermissionRequestToJSON(json: any): UpdateDelegatePermissionRequest {
    return UpdateDelegatePermissionRequestToJSONTyped(json, false);
}

export function UpdateDelegatePermissionRequestToJSONTyped(value?: UpdateDelegatePermissionRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'delegateId': value['delegateId'],
        'delegateUserId': value['delegateUserId'],
        'permissions': value['permissions'],
    };
}

