/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegistrationInsurance } from './RegistrationInsurance';
import {
    RegistrationInsuranceFromJSON,
    RegistrationInsuranceFromJSONTyped,
    RegistrationInsuranceToJSON,
    RegistrationInsuranceToJSONTyped,
} from './RegistrationInsurance';
import type { RegistrationClinic } from './RegistrationClinic';
import {
    RegistrationClinicFromJSON,
    RegistrationClinicFromJSONTyped,
    RegistrationClinicToJSON,
    RegistrationClinicToJSONTyped,
} from './RegistrationClinic';
import type { RegistrationAppointmentContact } from './RegistrationAppointmentContact';
import {
    RegistrationAppointmentContactFromJSON,
    RegistrationAppointmentContactFromJSONTyped,
    RegistrationAppointmentContactToJSON,
    RegistrationAppointmentContactToJSONTyped,
} from './RegistrationAppointmentContact';

/**
 * 
 * @export
 * @interface RegisterUserRequest
 */
export interface RegisterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    rePassword: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    dob: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    clinicianDescription: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserRequest
     */
    registrationNumber: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof RegisterUserRequest
     */
    jobRoleIds: Array<number>;
    /**
     * 
     * @type {Array<RegistrationAppointmentContact>}
     * @memberof RegisterUserRequest
     */
    appointmentsContacts: Array<RegistrationAppointmentContact>;
    /**
     * 
     * @type {Array<RegistrationClinic>}
     * @memberof RegisterUserRequest
     */
    clinics: Array<RegistrationClinic>;
    /**
     * 
     * @type {Array<RegistrationInsurance>}
     * @memberof RegisterUserRequest
     */
    existingProviders: Array<RegistrationInsurance>;
    /**
     * 
     * @type {Array<RegistrationInsurance>}
     * @memberof RegisterUserRequest
     */
    newProviders: Array<RegistrationInsurance>;
}

/**
 * Check if a given object implements the RegisterUserRequest interface.
 */
export function instanceOfRegisterUserRequest(value: object): value is RegisterUserRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('rePassword' in value) || value['rePassword'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('dob' in value) || value['dob'] === undefined) return false;
    if (!('clinicianDescription' in value) || value['clinicianDescription'] === undefined) return false;
    if (!('registrationNumber' in value) || value['registrationNumber'] === undefined) return false;
    if (!('jobRoleIds' in value) || value['jobRoleIds'] === undefined) return false;
    if (!('appointmentsContacts' in value) || value['appointmentsContacts'] === undefined) return false;
    if (!('clinics' in value) || value['clinics'] === undefined) return false;
    if (!('existingProviders' in value) || value['existingProviders'] === undefined) return false;
    if (!('newProviders' in value) || value['newProviders'] === undefined) return false;
    return true;
}

export function RegisterUserRequestFromJSON(json: any): RegisterUserRequest {
    return RegisterUserRequestFromJSONTyped(json, false);
}

export function RegisterUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'rePassword': json['rePassword'],
        'title': json['title'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'dob': json['dob'],
        'clinicianDescription': json['clinicianDescription'],
        'registrationNumber': json['registrationNumber'],
        'jobRoleIds': json['jobRoleIds'],
        'appointmentsContacts': ((json['appointmentsContacts'] as Array<any>).map(RegistrationAppointmentContactFromJSON)),
        'clinics': ((json['clinics'] as Array<any>).map(RegistrationClinicFromJSON)),
        'existingProviders': ((json['existingProviders'] as Array<any>).map(RegistrationInsuranceFromJSON)),
        'newProviders': ((json['newProviders'] as Array<any>).map(RegistrationInsuranceFromJSON)),
    };
}

export function RegisterUserRequestToJSON(json: any): RegisterUserRequest {
    return RegisterUserRequestToJSONTyped(json, false);
}

export function RegisterUserRequestToJSONTyped(value?: RegisterUserRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'password': value['password'],
        'rePassword': value['rePassword'],
        'title': value['title'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'dob': value['dob'],
        'clinicianDescription': value['clinicianDescription'],
        'registrationNumber': value['registrationNumber'],
        'jobRoleIds': value['jobRoleIds'],
        'appointmentsContacts': ((value['appointmentsContacts'] as Array<any>).map(RegistrationAppointmentContactToJSON)),
        'clinics': ((value['clinics'] as Array<any>).map(RegistrationClinicToJSON)),
        'existingProviders': ((value['existingProviders'] as Array<any>).map(RegistrationInsuranceToJSON)),
        'newProviders': ((value['newProviders'] as Array<any>).map(RegistrationInsuranceToJSON)),
    };
}

