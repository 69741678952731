import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import useInsuranceSelectorGrid from './useInsuranceSelectorGrid';
import Loader from '@components/app/Loader';
import {RootState} from '@/reducers';
import {InsuranceAgreement} from '@/generated-client';

interface InsuranceSelectorGridProps {
    currentAgreements: InsuranceAgreement[];
    updateExisitingProviders: (providers: InsuranceAgreement[]) => void;
}

const InsuranceSelectorGrid: React.FC<InsuranceSelectorGridProps> = ({
    currentAgreements,
    updateExisitingProviders,
}) => {
    const insuranceAgreements = useSelector(
        (state: RootState) => state.clinicians.insuranceAgreements
    );

    const {providers, selectAllProviders, toggleProviderSelected} =
        useInsuranceSelectorGrid({
            insuranceAgreements,
            currentAgreements,
            updateExisitingProviders,
        });

    const loadingView = (
        <>
            <div className="gap mt-5" />
            <Loader text="Loading insurance providers..." />
        </>
    );

    if (!providers.length) {
        // TODO: Add a message to display when there are no insurance providers
        return loadingView;
    }

    return (
        <>
            <Row>
                <Col className="text-center">
                    <Button
                        className="sel-button w80"
                        variant="primary"
                        onClick={selectAllProviders}
                    >
                        Select all insurers
                    </Button>
                </Col>
            </Row>
            <Row className="my-1 insurance-list">
                {providers.map((insurance) => (
                    <Col
                        xs={12}
                        md={4}
                        lg={3}
                        key={`insProv${insurance.id}col`}
                    >
                        <Button
                            key={`insProv${insurance.id}`}
                            className="insurance-check-btn"
                            variant={insurance.selected ? 'info' : 'primary'}
                            onClick={() => toggleProviderSelected(insurance.id)}
                        >
                            {insurance.provider}
                        </Button>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default InsuranceSelectorGrid;
