import React, {useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import InsuranceSelectorGrid from '@components/user/InsuranceSelectorGrid/InsuranceSelectorGrid';
import InsuranceProviderForm from '../../clinicians/InsuranceProviderDisplay/InsuranceProviderForm';
import {useRegistrationContext} from '@contexts/RegistrationContext';

const RegisterInsuranceDetails: React.FC = () => {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const {
        registrationData,
        updateNewProviders,
        updateExisitingProviders,
        setNextStep,
    } = useRegistrationContext(); //  as RegistrationContextType;

    return (
        <div className="insurance-page">
            <Row className="my-3">
                <Col className="text-center">
                    <h4> Add accepted insurances </h4>
                </Col>
            </Row>

            <InsuranceSelectorGrid
                currentAgreements={registrationData.existingProviders}
                updateExisitingProviders={updateExisitingProviders}
            />
            <InsuranceProviderForm
                newProviders={registrationData.newProviders}
                updateNewProviders={updateNewProviders}
            />
            <Row className="my-3">
                <Col className="text-center">
                    <Button
                        className="sel-button w80"
                        variant="primary"
                        onClick={setNextStep}
                    >
                        Next
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default RegisterInsuranceDetails;
