import React from 'react';
import useUserJobRole from '@hooks/user/useUserJobRole';
import DashboardClinician from './user/DashboardClinician';
import DashboardStaff from './user/DashboardStaff';
import DashboardDelegate from './user/DashboardDelegate';

enum UserType {
    Clinician = 'Clinician',
    Staff = 'Staff',
    Delegate = 'Delegate',
}

const Dashboard: React.FC = () => {
    const {userType} = useUserJobRole();

    return (
        <div
            className="medr-layout text-center dashboard-page"
            style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            {userType === UserType.Clinician.toString() && (
                <DashboardClinician />
            )}
            {userType === UserType.Staff.toString() && <DashboardStaff />}
            {userType === UserType.Delegate.toString() && <DashboardDelegate />}
        </div>
    );
};

export default Dashboard;
