import React, {useState, useCallback} from 'react';
import {Form, Button, Row} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router-dom';
import PasswordField from '@components/app/parts/PasswordField';
import {useAuthService, useUserService} from '@/actions';
import {MedrPages} from '@/constants/variables';

interface LoginFormData {
    username: string;
    password: string;
}

const LoginScreen: React.FC = () => {
    const authService = useAuthService();
    const userService = useUserService();
    const [formData, setFormData] = useState<LoginFormData>({
        username: '',
        password: '',
    });
    const [error, setError] = useState<boolean>(false);

    const {username, password} = formData;

    // Redirect if user is already authenticated
    authService.RedirectIfAuthDetailsAvailable();

    // Handle form input changes
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
    }, []);

    // Handle password field updates
    const updateChanges = useCallback((newData: Partial<LoginFormData>) => {
        setFormData((prevData) => ({...prevData, ...newData}));
    }, []);

    // Handle login submission
    const handleLogin = useCallback(
        async (username: string, password: string) => {
            const loginSuccess = await authService.login(username, password);
            setError(!loginSuccess);

            if (!loginSuccess) return;
            userService.refreshConfiguration();

            const loadProfileSuccess = await userService.loadUserProfile();
            if (!loadProfileSuccess) return;

            const referralNotificationSuccess =
                await userService.getReferralNotifications();
            if (!referralNotificationSuccess) return;

            userService.goToDashboardPage();
        },
        [authService]
    );

    // Handle form submission
    const onSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            handleLogin(username, password);
        },
        [username, password, handleLogin]
    );

    return (
        <div
            className="medr-layout text-center login-page"
            style={{
                maxWidth: '380px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            <h1>Sign In</h1>
            <Form onSubmit={onSubmit}>
                <Form.Group className="py-0" controlId="email">
                    <Form.Label className="my-0">Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        name="username"
                        value={username}
                        onChange={onChange}
                        autoComplete="username"
                        isInvalid={error}
                    />
                    <Form.Control.Feedback type="invalid">
                        Email and password combination not found.
                    </Form.Control.Feedback>
                </Form.Group>
                <PasswordField
                    controlId="password"
                    label="Password"
                    password={password}
                    keyLabel="password"
                    changeValues={updateChanges}
                    autoComplete="current-password"
                />
                <div>
                    <p className="medr-text mt-2 medr-small">
                        Forgotten your password?{' '}
                        <Link
                            className="medr-small"
                            style={{fontSize: '1em'}}
                            to={MedrPages.PASSWORD_SEND_RESET_EMAIL}
                        >
                            Reset here
                        </Link>
                    </p>
                </div>
                <Row className="mt-4">
                    <div className="col-12">
                        <Button
                            className="sel-button w100"
                            type="submit"
                            variant="primary"
                        >
                            Sign In
                        </Button>
                    </div>
                </Row>
                <Row className="mt-3">
                    <div className="col-12">
                        <LinkContainer to={MedrPages.REGISTER}>
                            <Button className="sel-button w100" variant="dark">
                                Register
                            </Button>
                        </LinkContainer>
                    </div>
                </Row>
            </Form>
        </div>
    );
};

export default LoginScreen;
