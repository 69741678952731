/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BlankEnum } from './BlankEnum';
import {
    instanceOfBlankEnum,
    BlankEnumFromJSON,
    BlankEnumFromJSONTyped,
    BlankEnumToJSON,
} from './BlankEnum';
import type { StatusEnum } from './StatusEnum';
import {
    instanceOfStatusEnum,
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './StatusEnum';

/**
 * @type ReferralStatus
 * 
 * @export
 */
export type ReferralStatus = BlankEnum | StatusEnum;

export function ReferralStatusFromJSON(json: any): ReferralStatus {
    return ReferralStatusFromJSONTyped(json, false);
}

export function ReferralStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralStatus {
    if (json == null) {
        return json;
    }
    if (instanceOfBlankEnum(json)) {
        return BlankEnumFromJSONTyped(json, true);
    }
    if (instanceOfStatusEnum(json)) {
        return StatusEnumFromJSONTyped(json, true);
    }

    return {} as any;
}

export function ReferralStatusToJSON(json: any): any {
    return ReferralStatusToJSONTyped(json, false);
}

export function ReferralStatusToJSONTyped(value?: ReferralStatus | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfBlankEnum(value)) {
        return BlankEnumToJSON(value as BlankEnum);
    }
    if (instanceOfStatusEnum(value)) {
        return StatusEnumToJSON(value as StatusEnum);
    }

    return {};
}

