import React, {useState, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Button} from 'react-bootstrap';
import PaginationBar from '../app/PaginationBar';
import {ReferralBar} from './ReferralBar';
import useFilterReferralsList from '@hooks/referrals/useFilterReferralsList';
import {useSelector} from 'react-redux';
import Loader from '@components/app/Loader';
import {Referral} from '@/generated-client';
import {RootState} from '@/reducers';
import useScreenInfo, {ScreenSize} from '@/hooks/app/useScreenInfo';

interface ReferralListDisplayProps {
    referralsList: Referral[];
    headers: string[];
}

const ReferralListDisplay: React.FC<ReferralListDisplayProps> = ({
    referralsList,
    headers,
}) => {
    const {screenType} = useScreenInfo();
    const [newestFirst, setNewestFirst] = useState(true);
    const [rxBarsDisplay, setRxBarsDisplay] = useState(null);
    const toggleNewestFirst = () => setNewestFirst((prev) => !prev);
    const nReferralsWindow = 25;
    const start = 0;
    const end = nReferralsWindow;

    const isLoading = useSelector(
        (state: RootState) => state.referrals.loading
    );

    const rxHeaders = (
        <div className="position-relative">
            {screenType > ScreenSize.MEDIUM && (
                <div className="button-container">
                    <Button
                        className="btn-sm medr-toggle-sort-btn"
                        onClick={toggleNewestFirst}
                    >
                        {newestFirst ? 'Set newest first' : 'Set oldest first'}
                    </Button>
                </div>
            )}
            <div className="py-1" style={{marginLeft: '37px'}}>
                <div className="align-items-center p-0 referral-bar referral-bar-header d-none d-md-flex">
                    {headers.map((header, index) => (
                        <div
                            className={`flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-${index + 1}`}
                            key={uuidv4()}
                        >
                            <h4 className="m-0">{header}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const [referrals, updateReferralsSlice, referralsLength] =
        useFilterReferralsList({referralsList, start, end});

    const noReferralsDisplay = (
        <div className="center-box-parent">
            <div className="center-box-child">
                <h4 className="medr-text-inv">
                    No referrals to display at the moment
                </h4>
            </div>
        </div>
    );
    const rxBars = () => {
        if (newestFirst) {
            return referrals.map((referral: Referral) => (
                <div className="p-1" key={`rxD${referral.id}`}>
                    <ReferralBar referral={referral} />
                </div>
            ));
        }

        const pxRev = [...referrals].reverse();
        return pxRev.map((referral: Referral) => (
            <div className="p-1" key={`rxD${referral.id}`}>
                <ReferralBar referral={referral} />
            </div>
        ));
    };

    useEffect(() => {
        setRxBarsDisplay(rxBars());
    }, [referrals, newestFirst]);

    return (
        <>
            {isLoading && (
                <>
                    <div className="gap mt-5" />
                    <Loader text="Loading referrals..." />
                </>
            )}
            {!isLoading && referralsLength === 0 && noReferralsDisplay}
            {!isLoading && referrals.length > 0 && rxHeaders}
            {!isLoading && rxBarsDisplay}
            {!isLoading && referralsLength > nReferralsWindow && (
                <>
                    <hr className="medr-inv" />
                    <PaginationBar
                        arrayLength={referralsLength}
                        setSliceValues={updateReferralsSlice}
                        windowSize={nReferralsWindow}
                    />
                </>
            )}
        </>
    );
};

export default ReferralListDisplay;
