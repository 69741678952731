import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/reducers';

interface ReferralPermissions {
    permissionCreateReferrals: boolean;
    permissionManageReferrals: boolean;
    permissionViewReferrals: boolean;
}

interface UseUserPermissionsReturn extends ReferralPermissions {
    isDelegate: boolean;
}

const useUserPermissions = (): UseUserPermissionsReturn => {
    const isDelegate = useSelector(
        (state: RootState) => state.profile.isDelegate
    );
    const referralPermissions = useSelector(
        (state: RootState) => state.profile.referralPermissions
    );

    const permissions = useMemo(() => {
        if (!referralPermissions) {
            return {
                permissionCreateReferrals: false,
                permissionManageReferrals: false,
                permissionViewReferrals: false,
            };
        }

        return {
            permissionCreateReferrals:
                referralPermissions.permissionCreateReferrals,
            permissionManageReferrals:
                referralPermissions.permissionManageReferrals,
            permissionViewReferrals:
                referralPermissions.permissionViewReferrals,
        };
    }, [referralPermissions]);

    return {isDelegate, ...permissions};
};

export default useUserPermissions;
