/* tslint:disable */
/* eslint-disable */
/**
 * MedR API
 * MedR backend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InsuranceAgreementByIdResponse,
  InsuranceAgreementListResponse,
} from '../models/index';
import {
    InsuranceAgreementByIdResponseFromJSON,
    InsuranceAgreementByIdResponseToJSON,
    InsuranceAgreementListResponseFromJSON,
    InsuranceAgreementListResponseToJSON,
} from '../models/index';

export interface InsuranceAgreementsRetrieveRequest {
    id: number;
}

export interface InsuranceAgreementsVerifyCreateRequest {
    id: number;
}

/**
 * 
 */
export class InsuranceAgreementsApi extends runtime.BaseAPI {

    /**
     * Retrieve a list of insurance policies.
     */
    async insuranceAgreementsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InsuranceAgreementListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/insurance-agreements/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsuranceAgreementListResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a list of insurance policies.
     */
    async insuranceAgreementsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InsuranceAgreementListResponse> {
        const response = await this.insuranceAgreementsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Return insurance policy information using the uid.
     */
    async insuranceAgreementsRetrieveRaw(requestParameters: InsuranceAgreementsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InsuranceAgreementByIdResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling insuranceAgreementsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/insurance-agreements/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsuranceAgreementByIdResponseFromJSON(jsonValue));
    }

    /**
     * Return insurance policy information using the uid.
     */
    async insuranceAgreementsRetrieve(requestParameters: InsuranceAgreementsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InsuranceAgreementByIdResponse> {
        const response = await this.insuranceAgreementsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify insurance agreemnt using the uid.
     */
    async insuranceAgreementsVerifyCreateRaw(requestParameters: InsuranceAgreementsVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling insuranceAgreementsVerifyCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // knoxApiToken authentication
        }

        const response = await this.request({
            path: `/api/v1/insurance-agreements/verify/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify insurance agreemnt using the uid.
     */
    async insuranceAgreementsVerifyCreate(requestParameters: InsuranceAgreementsVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.insuranceAgreementsVerifyCreateRaw(requestParameters, initOverrides);
    }

}
