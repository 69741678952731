import {Dispatch} from 'redux';
import {NavigateFunction} from 'react-router-dom';
import {
    InviteClinicianRequest,
    ClinicianInsuranceUpdateRequest,
    RemoveClinicianProfileRequest,
    ClinicianProfileCreateRequest,
    ListCliniciansRequest,
    ClinicianInfoByIdResponse,
    ClinicianListApi,
    ClinicsApi,
    ClinicianInformationApi,
    UserCrpInformationApi,
    UserApi,
} from '@/generated-client';
import * as CRED from '@/reducers/cliniciansReducer';
import {MedrService} from './authService';
import {ClinicFormData} from '@/components/clinicians/ClinicForm.interfaces';

export class ClinicianService extends MedrService {
    constructor(dispatch: Dispatch, navigate: NavigateFunction) {
        super(dispatch, navigate);
    }

    async getCliniciansList(
        inputData: ListCliniciansRequest
    ): Promise<boolean> {
        const payload = {listCliniciansRequest: inputData};
        const api = new ClinicianListApi(this.configuration);
        return this.executeApiCall(
            () => api.clinicianListCreate(payload),
            CRED.dispatchGetCliniciansListRequest,
            CRED.dispatchGetCliniciansListSuccess,
            CRED.dispatchGetCliniciansListFail
        );
    }

    async getClinicsList(): Promise<boolean> {
        const api = new ClinicsApi(this.configuration);
        return this.executeApiCall(
            () => api.clinicsRetrieve(),
            CRED.dispatchGetClinicsListRequest,
            CRED.dispatchGetClinicsListSuccess,
            CRED.dispatchGetClinicsListFail
        );
    }

    async getClinicianById(crpId: number): Promise<boolean> {
        const payload = {id: crpId};
        const api = new ClinicianInformationApi(this.configuration);
        return this.executeApiCall(
            () => api.clinicianInformationRetrieve(payload),
            CRED.dispatchGetClinicianByIdRequest,
            CRED.dispatchGetClinicianByIdSuccess,
            CRED.dispatchGetClinicianByIdFail
        );
    }

    async getClinicianDataById(
        crpId: number
    ): Promise<ClinicianInfoByIdResponse> {
        const payload = {id: crpId};
        const api = new ClinicianInformationApi(this.configuration);
        return this.executeApiCallReturnObject(
            () => api.clinicianInformationRetrieve(payload),
            CRED.dispatchGetClinicianDataByIdRequest,
            CRED.dispatchGetClinicianDataByIdSuccess,
            CRED.dispatchGetClinicianDataByIdFail
        );
    }

    async getClinicianByUserId(userId: number): Promise<boolean> {
        const payload = {id: userId};
        const api = new UserCrpInformationApi(this.configuration);
        return this.executeApiCall(
            () => api.userCrpInformationRetrieve(payload),
            CRED.dispatchGetClinicianByUserIdRequest,
            CRED.dispatchGetClinicianByUserIdSuccess,
            CRED.dispatchGetClinicianByUserIdFail
        );
    }

    async getCliniciansByUserIds(
        userIds: number[]
    ): Promise<ClinicianInfoByIdResponse[]> {
        if (!userIds || userIds.length === 0) {
            console.warn('No user IDs provided for fetching clinicians.');
            return [];
        }

        const api = new UserCrpInformationApi(this.configuration);

        try {
            console.log('Fetching clinician data for user IDs:', userIds);

            // Fetch clinicians in parallel
            const responses = await Promise.all(
                userIds.map(async (userId) => {
                    try {
                        const response = await api.userCrpInformationRetrieve({
                            id: userId,
                        });

                        console.log(
                            `Response for User ID ${userId}:`,
                            response
                        );

                        if (!response) {
                            console.warn(
                                `API returned no data for User ID ${userId}`
                            );
                            return null;
                        }

                        console.log(
                            `API Response for ${userId}:`,
                            // @ts-expect-error ANAS ADDITION
                            JSON.stringify(response.data, null, 2)
                        );

                        return response; // Return full API response
                    } catch (error) {
                        console.error(
                            `Error fetching clinician for User ID ${userId}:`,
                            error
                        );
                        return null;
                    }
                })
            );

            // Remove failed requests
            const validClinicians = responses.filter((res) => res !== null);
            console.log('Valid Fetched Clinicians:', validClinicians);

            return validClinicians;
        } catch (error) {
            console.error('Error fetching multiple clinicians:', error);
            return [];
        }
    }

    async getClinicianByUserDataId(
        userId: number
    ): Promise<ClinicianInfoByIdResponse> {
        const payload = {id: userId};
        const api = new UserCrpInformationApi(this.configuration);
        return this.executeApiCallReturnObject(
            () => api.userCrpInformationRetrieve(payload),
            CRED.dispatchGetClinicianByUserIdRequest,
            CRED.dispatchGetClinicianByUserIdSuccess,
            CRED.dispatchGetClinicianByUserIdFail
        );
    }

    async postCreateCrp(
        inputData: ClinicianProfileCreateRequest
    ): Promise<boolean> {
        const payload = {clinicianProfileCreateRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userClinicianInformationCreateCreate(payload),
            CRED.dispatchPostCreateCrpRequest,
            CRED.dispatchPostCreateCrpSuccess,
            CRED.dispatchPostCreateCrpFail
        );
    }

    async postRequestRemoveCrp(
        inputData: RemoveClinicianProfileRequest
    ): Promise<boolean> {
        const payload = {removeClinicianProfileRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userClinicianInformationRemoveClinicCreate(payload),
            CRED.dispatchPostRequestRemoveCrpRequest,
            CRED.dispatchPostRequestRemoveCrpSuccess,
            CRED.dispatchPostRequestRemoveCrpFail
        );
    }

    async editCrp(clinic: ClinicFormData): Promise<boolean> {
        const payload = {
            clinicId: clinic.id,
            clinicianProfileUpdateRequest: {
                clinicName: clinic.clinicName,
                centreName: clinic.centreName,
                firstLine: clinic.address.firstLine,
                city: clinic.address.city,
                postcode: clinic.address.postcode,
            },
        };
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userClinicianInformationUpdateUpdate(payload),
            CRED.dispatchEditCrpRequest,
            CRED.dispatchEditCrpSuccess,
            CRED.dispatchEditCrpFail
        );
    }

    async postDeactivateCrp(id: number): Promise<boolean> {
        const payload = {id};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userClinicianInformationDeactivateUpdate(payload),
            CRED.dispatchPostDeactivateCrpRequest,
            CRED.dispatchPostDeactivateCrpSuccess,
            CRED.dispatchPostDeactivateCrpFail
        );
    }

    async updateClinicianInsuranceAgreements(
        inputData: ClinicianInsuranceUpdateRequest
    ): Promise<boolean> {
        const payload = {clinicianInsuranceUpdateRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () =>
                api.userClinicianInformationUpdateInsuranceAgreementsUpdate(
                    payload
                ),
            CRED.dispatchUpdateClinicianInsuranceAgreementsRequest,
            CRED.dispatchUpdateClinicianInsuranceAgreementsSuccess,
            CRED.dispatchUpdateClinicianInsuranceAgreementsFail
        );
    }

    async addClinicianToFavourites(
        clinicianUserProfileId: number
    ): Promise<boolean> {
        const payload = {clinicianUserProfileId};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userFavouriteClinicianAddCreate(payload),
            CRED.dispatchAddClinicianToFavouritesRequest,
            CRED.dispatchAddClinicianToFavouritesSuccess,
            CRED.dispatchAddClinicianToFavouritesFail
        );
    }

    async removeClinicianFromFavourites(
        clinicianUserProfileId: number
    ): Promise<boolean> {
        const payload = {clinicianUserProfileId};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userFavouriteClinicianRemoveDestroy(payload),
            CRED.dispatchRemoveClinicianFromFavouritesRequest,
            CRED.dispatchRemoveClinicianFromFavouritesSuccess,
            CRED.dispatchRemoveClinicianFromFavouritesFail
        );
    }

    async inviteClinicianToMedr(
        inputData: InviteClinicianRequest
    ): Promise<boolean> {
        const payload = {inviteClinicianRequest: inputData};
        const api = new UserApi(this.configuration);
        return this.executeApiCall(
            () => api.userInviteClinicianCreate(payload),
            CRED.dispatchInviteClinicianToMedrRequest,
            CRED.dispatchInviteClinicianToMedrSuccess,
            CRED.dispatchInviteClinicianToMedrFail
        );
    }
}
